import { Observable, Subject } from 'rxjs';
import { MenuEntry } from './entry.schema';

export interface IMenuEvent {
  type: string;
  items: MenuEntry[];
}

export class Menu {
  /**
   * List of item in the menu
   */
  private readonly items: MenuEntry[] = [];
  get menus(): MenuEntry[] {
    return this.items;
  }

  private readonly _change = new Subject<IMenuEvent>();
  get onEvent(): Observable<IMenuEvent> {
    return this._change.asObservable();
  }

  /**
   * Define if the menu can be shown or not
   */
  private _visibility = false;
  get show(): boolean {
    return this._visibility;
  }
  set show(value: boolean) {
    if (value !== this._visibility) {
      this._visibility = value;
      this._change.next(this.createEvent('visibility', this.items));
    }
  }

  /**
   * Add an item to the menu
   * @param item
   */
  addItem(item: MenuEntry) {
    this.items.push(item);
    this._change.next(this.createEvent('add', item));
    this.sortByPriority(this.items);
  }

  private createEvent(type: string, entries: MenuEntry | MenuEntry[]): IMenuEvent {
    entries = (entries instanceof MenuEntry && [entries]) || entries;
    return { type, items: entries as MenuEntry[] };
  }

  private sortByPriority(menu: MenuEntry[]) {
    menu.sort((a, b) => b.priority - a.priority);
  }

  /**
   * Remove an item from the menu
   * @param item
   */
  removeItem(item: MenuEntry): boolean {
    const i = this.items.indexOf(item);
    if (i !== -1) {
      this.items.splice(i, 1);
      this._change.next(this.createEvent('remove', item));
      this.sortByPriority(this.items);
      return true;
    }
    return false;
  }
}
