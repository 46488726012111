import { IonNav } from '@ionic/angular';
import { Tool } from './tool';
import { ToolConfig } from './interface/tool-config';

/**
 * @name ToolsProvider
 * @description
 * The tool provider system
 */
export class ToolsProvider {
  public context: any[] = [];
  public nav: IonNav;
  public tools: Array<Tool>;

  /**
   * @construcor
   * @param {{}[]|{}} context
   * @param {NavController?} nav
   */
  constructor(context: any[] | any, nav?: IonNav) {
    if (!Array.isArray(context)) {
      context = [context];
    }
    this.context = context;
    this.nav = nav;
    this.tools = [];
  }

  /**
   * Test of all objects in the context are of the given instanceof
   * @param {Function} objectType
   * @returns {boolean}
   */
  public contextObjectsAre(objectType: Function): boolean {
    let ok = true;
    for (let i = 0; ok && i < this.context.length; i++) {
      ok = this.context[i] instanceof objectType;
    }
    return ok;
  }

  /**
   * Test that at least one of the objects is of the given instanceof
   * @param {Function} objectType
   * @returns {boolean}
   */
  public atLeastOneContextObjectsIs(objectType: Function): boolean {
    for (let i = 0; i < this.context.length; i++) {
      if (this.context[i] instanceof objectType) return true;
    }
    return false;
  }

  /**
   * Add a tool to this provider
   * @param {Tool} tool
   */
  public addTool(tool: Tool): void {
    this.tools.push(tool);
  }

  /**
   * Execute the tool handler with provider context and nav
   * @param {(any[], NavController) => void} toolHandler
   */
  public executeToolHandler(toolHandler: (context, nav) => void): void {
    toolHandler(this.context, this.nav);
  }

  /**
   * Generate Ionic Action Sheet Buttons
   * @param {boolean} generateCancel
   * @returns {ToolConfig[]}
   */
  public getActionSheetButtons(generateCancel: boolean): ToolConfig[] {
    let tools = this.tools.map(t => {
      let config = t.config();
      config.handler = () => {
        this.executeToolHandler(config.execute);
      };
      return config;
    });
    if (generateCancel) {
      tools.push({
        text: 'Cancel',
        role: 'cancel',
        icon: 'close',
        priority: -100
      });
    }

    tools.sort(function(a, b) {
      return b.priority - a.priority;
    });

    return tools;
  }
}
