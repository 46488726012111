<ion-header>
  <ion-toolbar color="primary ion-text-center">
    <ion-title>
      <span translate>SagaMobile.AssignementRequest.Title</span>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="gn-home padding">
  <ion-card class="gn-home-my-card" (click)="openMyCard()" *ngIf="unitActivity && unitActivity.callcard" #mapCard style="flex: 1">
    <!-- <img src="{{unitActivity.callcard.location.getStaticMapUrl({height: mapHeight(), width: mapWidth(), zoom: 15, color: unitActivity.callcard.color.tinycolor.toHexString()})}}"> -->
    <ion-item #mapLabel>
      <ion-icon [style.color]="unitActivity.callcard.color.tinycolor.toRgbString()" name="pin" slot="start" large></ion-icon>
      <h2>{{ unitActivity.callcard.CreatedUTC | moment: 'LLLL' }}</h2>
      <p style="white-space: initial;">{{ unitActivity.callcard.event.EventText }}</p>
      <p style="white-space: initial;">{{ unitActivity.callcard.location.FormattedLocation }}</p>
    </ion-item>
  </ion-card>

  <ion-button *ngIf="canRefuse" expand="full" fill="clear" (click)="cancel()">
    <span translate>SagaMobile.AssignementRequest.Buttons.Refuse</span>
  </ion-button>
  <ion-button expand="full" (click)="accept()" color="primary" style="height: 65px;">
    <span translate>SagaMobile.AssignementRequest.Buttons.Accept</span>
  </ion-button>
</ion-content>
