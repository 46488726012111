import { MenuEntry } from '@services/menu/entry.schema';
import { Observable, Subject } from 'rxjs';

export enum MenuState {
  active,
  inactive
}

export interface IPermanentMenuEvent {
  item: PermanentEntry;
  event: MouseEvent | TouchEvent;
}

export class PermanentEntry extends MenuEntry {
  protected _state = MenuState.inactive;
  get isActive(): boolean {
    return this._state === MenuState.active;
  }

  protected readonly _onClick = new Subject<IPermanentMenuEvent>();
  get onClick(): Observable<IPermanentMenuEvent> {
    return this._onClick.asObservable();
  }

  public fill: string;

  protected _attention: string = '';
  get attention(): string {
    return this._attention;
  }

  protected _counter = 0;
  get counter(): number {
    return this._counter;
  }

  constructor(item: MenuEntry) {
    super(item.text, item.icon, item.type, item.priority);

    this.isHandler = item.isHandler;
    this.setState(this._state);
  }

  disable() {
    super.disable();
    this._counter = 0;
  }

  click($event: MouseEvent | TouchEvent): void {
    if (this._state === MenuState.inactive) {
      this.setState(MenuState.active);

      this._onClick.next({
        item: this,
        event: $event
      });
    }
  }

  increment(): void {
    this._counter++;
  }

  reset(): void {
    this.setState(MenuState.inactive);
  }

  setState(menuState: MenuState): void {
    const oldMenuState = this._state;
    this._state = menuState;

    switch (menuState) {
      case MenuState.active:
        this.fill = 'solid';
        break;

      case MenuState.inactive:
        this.fill = 'clear';
        break;
    }

    if (oldMenuState !== this._state) {
      this._changed.next();
    }
  }
}
