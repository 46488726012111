// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ResourcePosition_abstract extends SagaObject {
  static $t: string = 'SagaSchema.ResourcePosition';
  $t: string = 'SagaSchema.ResourcePosition';

  public static GetMembers(): string[] {
    return ['MapX', 'MapY', 'CoordEpsgCode', 'ResourceGuid'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ResourcePosition_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public MapX: number = -1.79769313486232e308;
  public MapY: number = -1.79769313486232e308;
  public CoordEpsgCode: string;
  public ResourceGuid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ResourceGuid;
  }
  set ParentObjId(value: any) {
    this.ResourceGuid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
