import { MediaProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { FileAccessTool } from '../../../schema/file-access-tool';
import { Context } from '../../../schema/gallery-access-tool';
import { MediaType } from '../../../schema/media/media-type';
import { AttachmentService } from '../../attachment/attachment.service';

@AddinExecute({
  name: 'FileAccess'
})
@Injectable()
export class FileAccessToolService extends Execute {
  private fileTool = new FileAccessTool();

  constructor(private mediaProvider: MediaProviderService, private attachmentService: AttachmentService, private platform: Platform) {
    super();
  }

  execute(): void {
    if (!this.platform.is('cordova')) {
      this.mediaProvider.register(this.fileTool);
      this.fileTool.executor.subscribe(ctx => this.createMediaMessage(ctx));
    }
  }

  private createMediaMessage(ctx: Context) {
    this.attachmentService.createMediaMessage(ctx.callcard.ObjGuid, MediaType.File);
  }
}
