// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CStampCard_abstract } from './../abstract/SagaSchema/CStampCard_abstract';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { ListCardStatus } from '@models/imported/SagaStateLib/ListCardStatus';
// import {CStampCardFollowupComponent} from '@addins/callcard/components/callCardDetail/callCardDetailFollowup/CStampCard/CStampCardFollowup';
import * as moment from 'moment';

export class CStampCard extends CStampCard_abstract {
  private _component: SagaObject;
  private _status: ListCardStatus;

  get followupComponent(): any {
    return null; // CStampCardFollowupComponent;
  }

  public changed(change: CacheChangedInterface) {
    this._component = change.cache.getObjectByUid(this.ComponentId);
    this._status = change.fetchList.getRow(ListCardStatus, this.Status);
    return super.changed(change);
  }

  get title(): string {
    if (this.StampType === 7) {
      return this._status.Name;
    }

    if (this.StampType === 19) {
      return this.$t + '_' + this.StampType + '_' + this.Status;
    }

    return this.$t + '_' + this.StampType;
  }

  get iconStyle(): any {
    switch (this.StampType) {
      case 7:
        return {
          color: this._status.color.tinycolor.toRgbString()
        };
    }
    return {};
  }

  get icon(): string {
    switch (this.StampType) {
      case 7:
        return 'flag';
      case 8:
        return 'git-merge';
      case 15:
        return 'open';
      case 17:
        return 'arrow-round-up';
      case 19:
        return 'briefcase';
      case 201:
      case 202:
      case 203:
        return 'map';
      case 406:
        return 'calendar';
    }
    return 'document';
  }

  get text() {
    if (this.StampType === 406) {
      return moment(this.EffectiveDateUtc).format('LLL');
    }
    return this.Text;
  }

  get secondaryIcon() {
    switch (this.StampType) {
      case 201:
        return 'add';
      case 202:
        return 'create';
      case 203:
        return 'trash';
    }
    return null;
  }

  get secondaryIconColor() {
    switch (this.StampType) {
      case 201:
        return 'green';
      case 202:
        return 'orange';
      case 203:
        return 'red';
    }
    return null;
  }
}
