import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { PanelContent } from '../../schema/interfaces/panel-content';
import { PanelTool } from '../../schema/interfaces/panel-tool';

@Injectable()
export class ContentLoaderService {
  constructor() {}

  load(container: ViewContainerRef, tool: PanelTool): ComponentRef<PanelContent> {
    container.clear();
    return this.createComponent(container, tool);
  }

  private createComponent(container: ViewContainerRef, tool: PanelTool): ComponentRef<PanelContent> {
    return container.createComponent(tool.content) as ComponentRef<PanelContent>;
  }
}
