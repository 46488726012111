import { Guid } from '@models/guid';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class CachedList<T extends SagaObject> {
  private readonly _refresh = new Subject<Guid>();
  public get $refresh(): Observable<Guid> {
    return this._refresh.asObservable();
  }

  private readonly _cachedItems: T[];
  public get items(): T[] {
    return this._cachedItems;
  }

  private readonly _subs: Subscription[] = [];

  constructor(
    private readonly _cacheService: CacheService,
    private readonly _objectDefinition: new () => T,
    private readonly _filter: (value: T) => boolean
  ) {
    this._cachedItems = this._cacheService.getListByTypeName((_objectDefinition as any).$t).filter(this._filter);
    this._subs.push(
      this._cacheService.$events
        .pipe(filter(cacheEvent => cacheEvent.object.$t === (_objectDefinition as any).$t))
        .subscribe(cacheEvent => this.onCacheChanged(cacheEvent))
    );
  }

  public clear() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private onCacheChanged(cacheEvent: CacheEvent) {
    let mustRefresh = false;
    switch (cacheEvent.action) {
      case CacheAction.modified:
        mustRefresh = this.onCachedObjectModified(cacheEvent.object as T);
        break;

      case CacheAction.removed:
        mustRefresh = this.onCachedObjectRemoved(cacheEvent.object as T);
        break;

      case CacheAction.added:
        mustRefresh = this.onCachedObjectAdded(cacheEvent.object as T);
        break;
    }

    if (mustRefresh === true) {
      this._refresh.next(cacheEvent.object.ObjGuid);
    }
  }

  private onCachedObjectModified(cachedObject: T): boolean {
    const instance: T = this.getInstance(cachedObject);
    const shouldStore: boolean = this._filter(cachedObject);

    if (instance === null && shouldStore) {
      this.onCachedObjectAdded(cachedObject);
      return true;
    } else if (instance !== null && !shouldStore) {
      this.onCachedObjectRemoved(cachedObject);
      return true;
    }

    return false;
  }

  private onCachedObjectRemoved(cachedObject: T): boolean {
    const instance = this.getInstance(cachedObject);
    if (instance !== null) {
      this._cachedItems.splice(this._cachedItems.indexOf(instance), 1);
      return true;
    }
    return false;
  }

  private onCachedObjectAdded(cachedObject: T): boolean {
    const instance = this.getInstance(cachedObject);
    if (instance === null && this._filter(cachedObject)) {
      this._cachedItems.push(cachedObject);
      return true;
    }
    return false;
  }

  private getInstance(object: T): T | null {
    return this._cachedItems.find(cachedObject => cachedObject.ObjGuid === object.ObjGuid) || null;
  }
}
