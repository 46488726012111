import { Injectable } from '@angular/core';
import { Security } from '../security/security.service';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { EventSelectComponent } from '@addins/callcard/components/eventSelect/eventSelect';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { CCaller } from '@models/imported/SagaSchema/CCaller';
import { Zone } from '@models/imported/SagaBase/Zone';

export interface ILocationResponse {
  zone: Zone;
  caller: CCaller;
}

@Injectable()
export class EventService {
  private static _events: Array<MobEvents> = new Array<MobEvents>();

  constructor(private security: Security, private objectFactory: ObjectFactory, private modalCtrl: ModalController) {}

  public get events() {
    return EventService._events;
  }

  async setup() {
    if (!EventService._events) {
      const eventList = await this.security.post<any[]>('api/Event/GetMobileEvents');
      EventService._events = this.objectFactory.createList(eventList);
    }
    return true;
  }

  async getLocationAddress(coordinate: any): Promise<any> {
    const response = await this.security.post<ILocationResponse>('api/Event/GetLocationAddress', coordinate);
    if (response) {
      return {
        caller: this.objectFactory.create(response.caller),
        sector: this.objectFactory.create(response.zone)
      };
    }
    return response;
  }

  pickEvent(currentId?: number): Promise<number> {
    // TODO: @deprecated please use ModalFactoryService instead
    return this.modalCtrl
      .create({
        component: EventSelectComponent,
        componentProps: {
          source: EventService._events,
          id: currentId
        }
      })
      .then(v => v.present().then(() => v.onDidDismiss().then((r?: OverlayEventDetail) => r.data)));
  }
}
