import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { IEventFormatter } from '../../../schema/interfaces/event-formatter';

export class PnEventFormatter implements IEventFormatter {
  constructor() {}

  format(event: CEvent, short: boolean): string {
    return event ? event.EventText : '';
  }
}
