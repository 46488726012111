import { MapModule } from '@addins/core/map';
import { DeploymentModule } from '@addins/deployment';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { DrawingPanelContentComponent } from './components/drawing-panel-content/drawing-panel-content.component';
import { DrawingPanelService } from './services/drawing-panel/drawing-panel.service';
import { DrawingProxyService } from './services/drawing-proxy/drawing-proxy.service';
import { PanelControllerService } from './services/executables/panel-controller/panel-controller.service';

const executables = [PanelControllerService];
const services = [...executables, DrawingPanelService, DrawingProxyService];
const views = [];
const components = [DrawingPanelContentComponent];
const declarations = [...components];

@NgModule({ 
  declarations,
  providers: services,
  imports: [CommonModule, IonicModule, MapModule, DeploymentModule]
})
@Addin({
  name: 'Drawings'
})
export class DrawingModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
