import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { CacheService } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResourceCountService } from '../../services/resource-count/resource-count.service';

@Component({
  selector: 'app-engaged-people-list-button',
  templateUrl: './engaged-people-list-button.component.html',
  styleUrls: ['./engaged-people-list-button.component.scss']
})
export class EngagedPeopleListButtonComponent implements OnInit, OnDestroy {
  @Input()
  callcard: CallCard;
  @Input()
  isMyCallcard: boolean;

  engagedPeople: number;

  private cacheSub: Subscription = null;

  constructor(private resourceCountService: ResourceCountService, private cacheService: CacheService) {}

  ngOnInit() {
    if (this.isMyCallcard) {
      this.refreshEngagedPeople();

      this.cacheSub = this.cacheService
        .listenForChange(CStampProcedure)
        .pipe(filter(cacheEvent => (cacheEvent.object as CStampProcedure).CallCardId === this.callcard.ObjGuid))
        .subscribe(() => this.refreshEngagedPeople());
    }
  }

  private refreshEngagedPeople() {
    this.engagedPeople = this.resourceCountService.getEngagedPeople(this.callcard);
  }

  ngOnDestroy() {
    if (this.cacheSub) {
      this.cacheSub.unsubscribe();
      this.cacheSub = null;
    }
  }

  onEngagedPeopleButtonClicked($event: MouseEvent | TouchEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.resourceCountService.updateEngagedPeople();
  }
}
