// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { Coordinate } from '../../coordinate';
import { IFormattedLocation } from '../../interfaces/IFormattedLocation';
import { ILocation } from '../../interfaces/ILocation';
import { CCallerBase_abstract } from './../abstract/SagaSchema/CCallerBase_abstract';

export class CCallerBase extends CCallerBase_abstract implements ILocation, IFormattedLocation {
  IsFirstCaller: boolean = false;
  FormattedLocation: string[];

  get coordinate(): Coordinate {
    return {
      x: this.MapX,
      y: this.MapY,
      epsg: this.CoordEpsgCode
    };
  }

  get location(): string {
    return this.FormattedLocation.length ? this.FormattedLocation[0] : '';
  }

  static GetMembers(): string[] {
    return ['IsFirstCaller'].concat(CCallerBase_abstract.GetMembers());
  }

  GetMembers(): string[] {
    return CCallerBase.GetMembers();
  }

  get title() {
    return this.$t + '_' + this.LineOwner;
  }

  get icon() {
    return 'call';
  }

  get text() {
    return this.rShort();
  }

  rShort() {
    const name = `${this.Fname} ${this.Lname} ${this.Organization ? `, ${this.Organization}` : ''}`.trim();
    const phone = this.Phone;
    return [name, this.location, phone].filter(s => s).join('\n');
  }
}
