// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ResAddress_abstract extends SagaObject {
  static $t: string = 'SagaSchema.ResAddress';
  $t: string = 'SagaSchema.ResAddress';

  public static GetMembers(): string[] {
    return [
      'ResourceGuid',
      'PlaceName',
      'StreetNumber',
      'Zip',
      'CityName',
      'StateName',
      'CountryName',
      'MapX',
      'MapY',
      'IsConfidential',
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'TypeId',
      'Complement',
      'GeoLocationSearchLabel',
      'LocationMiscData',
      'Normalized'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ResAddress_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ResourceGuid: Guid = GuidTools.Empty;
  public PlaceName: string;
  public StreetNumber: string;
  public Zip: string;
  public CityName: string;
  public StateName: string;
  public CountryName: string;
  public MapX: number = -1.79769313486232e308;
  public MapY: number = -1.79769313486232e308;
  public IsConfidential: boolean = false;
  public AliasName: string;
  public Area1Name: string;
  public Area2Name: string;
  public Area3Name: string;
  public TypeId: number = 0;
  public Complement: string;
  public GeoLocationSearchLabel: string;
  public LocationMiscData: string;
  public Normalized: number = 1;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ResourceGuid;
  }
  set ParentObjId(value: any) {
    this.ResourceGuid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
