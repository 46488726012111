import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AttachmentOption } from '../../schema/attachment-option';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { MediaType } from '../../schema/media/media-type';
import { AttachmentOptionFactoryService } from '../attachment-option-factory/attachment-option-factory.service';
import { CameraBridgeService } from '../camera-bridge/camera-bridge.service';
import { FileReaderService } from '../file-reader/file-reader.service';

@Injectable()
export class FileChooserService {
  private _onFocus = null;
  private _input: HTMLInputElement = null;
  private _timeout = null;

  constructor(
    private _platform: Platform,
    private _cameraBridge: CameraBridgeService,
    private _fileReader: FileReaderService,
    private _attachmentOptionsFactory: AttachmentOptionFactoryService
  ) {}

  choose(mediaType: MediaType): Promise<ICustomFile> {
    const attachmentOption: AttachmentOption = this._attachmentOptionsFactory.create(mediaType);
    return this._platform.is('cordova') ? this.fromNative(attachmentOption) : this.fromInput(attachmentOption);
  }

  private fromNative(attachmentOption: AttachmentOption): Promise<ICustomFile> {
    return attachmentOption.type === MediaType.Video
      ? this._cameraBridge.captureVideo(attachmentOption)
      : this._cameraBridge.getPicture(attachmentOption);
  }

  private fromInput(attachmentOption: AttachmentOption): Promise<ICustomFile> {
    this.reset();
    return new Promise<ICustomFile>((resolve, reject) => {
      const input = this.createInput(resolve);
      this._onFocus = window.onfocus;

      switch (attachmentOption.type) {
        case MediaType.Picture:
          input.setAttribute('accept', 'image/*');
          input.setAttribute('capture', 'camera');
          break;

        case MediaType.Video:
          input.setAttribute('accept', 'video/*');
          input.setAttribute('capture', 'camcorder');
          break;
      }

      window.onfocus = () => this.onFocus();
      input.click();
    });
  }

  private createInput(resolve: (value: ICustomFile | PromiseLike<ICustomFile>) => void): HTMLInputElement {
    if (this._input === null) {
      this._input = document.createElement('input');
      this._input.setAttribute('hidden', '');
      this._input.setAttribute('type', 'file');
      this._input.addEventListener('change', event => this.onChange(event, resolve));
      document.body.appendChild(this._input);
    }
    return this._input;
  }

  private onChange(event: Event, resolve: (value: ICustomFile | PromiseLike<ICustomFile>) => void) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    this.reset(true);

    return this._fileReader.readFromInput(files).then(customFile => resolve(customFile));
  }

  private reset(all = false): void {
    if (all === true) {
      document.body.removeChild(this._input);
      this._input = null;
    }
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  private onFocus() {
    this._timeout = setTimeout(() => this.reset(true), 10000);
    window.onfocus = this._onFocus;
  }
}
