// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ProcStepComplements_abstract extends BaseObject {
  static $t: string = 'SagaSchema.ProcStepComplements';
  $t: string = 'SagaSchema.ProcStepComplements';

  public static GetMembers(): string[] {
    return ['Complements', 'InactivatedProcSteps', 'ProcStepsReOrder', 'Keys', 'Values', 'Item', 'Count'];
  }

  public GetMembers(): string[] {
    return ProcStepComplements_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Complements: any[];
  public InactivatedProcSteps: Guid[];
  public ProcStepsReOrder: Guid[];
  public Keys: Guid[];
  public Values: any[];
  public Item: any;
  public Count: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
