import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { Tool } from '@services/tools';
import { CallcardStampsService } from '../../services/callcard-stamps/callcard-stamps.service';
import { JournalToolsProviderService } from '../../services/journal-tools-provider/journal-tools-provider.service';

@Component({
  selector: 'app-journal-modal',
  templateUrl: './journal-modal.component.html',
  styleUrls: ['./journal-modal.component.scss']
})
export class JournalModalComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  callcard: CallCard;

  private _callcardStamps: List<SagaObject> = null;
  get journalItems(): SagaObject[] {
    return (this._callcardStamps && this._callcardStamps.items) || [];
  }

  get journalTools(): Tool[] {
    return this.journalToolsProvider.tools;
  }

  constructor(
    private modalController: ModalController,
    private callcardStamps: CallcardStampsService,
    private journalToolsProvider: JournalToolsProviderService
  ) {}

  ngOnInit() {
    this.getStamps(true);
  }

  private getStamps(refresh?: boolean) {
    this.callcardStamps.getStamps(this.callcard, refresh).subscribe(list => (this._callcardStamps = list));
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onRefreshClicked() {
    this.getStamps(true);
  }
}
