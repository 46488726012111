import { Injectable } from '@angular/core';
import { IPropertyChange } from '@models/BaseObject';
import { GuidTools } from '@models/guid';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { MyEquipmentService } from '@techwan/ionic-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class MyUnitService {
  private readonly _myUnit = new BehaviorSubject<UnitActivity>(null);

  /**
   * Notify the subscribers about a change in the referred unit.
   * - The object is taken from the cache.
   */
  get $unitChanged(): Observable<UnitActivity> {
    return this._myUnit.asObservable();
  }

  /**
   * Retrieves the unit of the device.
   * This method can return null.
   */
  get mine(): UnitActivity {
    return this._myUnit.value;
  }

  get $change(): Observable<IPropertyChange> {
    return this.mine.$changed;
  }

  get myCardId(): string {
    return (this.mine && this.mine.CallCardID) || '';
  }

  constructor(private cacheService: CacheService, private myEquipmentService: MyEquipmentService) {
    cacheService.state.pipe(filter(cacheState => cacheState === CacheState.ready)).subscribe(() => {
      this.myEquipmentService.myUnit
        .pipe(
          filter(unitActivity => unitActivity !== null),
          first()
        )
        .subscribe(unitActivity => this._myUnit.next(this.cacheService.getObjectByUid(unitActivity.ObjGuid)));
    });

    this.cacheService.$events
      .pipe(filter(change => change.object.$t === UnitActivity.$t && change.action === CacheAction.removed))
      .subscribe(cacheEvent => this.onUnitRemoved(cacheEvent));
  }

  isEngaged(): boolean {
    return GuidTools.isValid(this.myCardId);
  }

  private onUnitRemoved(cacheEvent: CacheEvent): void {
    if (this.mine && cacheEvent.object.ObjGuid === this.mine.ObjGuid) {
      this._myUnit.next(null);
    }
  }
}
