import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FusLocationFormatter } from '@services/location/location-formatters/fus-location-formatter';
import { ProfileService } from '@services/profile/profile.service';
import { ILocationFormatter } from '../ILocationFormatter';
import { CnuLocationFormatter } from '../location-formatters/cnu-location-formatter';
import { CraiolLocationFormatter } from '../location-formatters/craiol-location-formatter';
import { DefaultLocationFormatter } from '../location-formatters/default-location-formatter';
import { MdpLocationFormatter } from '../location-formatters/mdp-location-formatter';
import { PnLocationFormatter } from '../location-formatters/pn-location-formatter';

@Injectable({
  providedIn: 'root'
})
export class LocationFormatterFactoryService {
  private locationFormatter: ILocationFormatter;

  constructor(private profile: ProfileService, private translate: TranslateService) {}

  getFormatter(): ILocationFormatter {
    if (!this.locationFormatter) {
      this.locationFormatter = this.createFormatter(this.profile.current.webConfig.locationFormatter);
    }

    return this.locationFormatter;
  }

  private createFormatter(formatterName: string): ILocationFormatter {
    switch (formatterName) {
      case 'fus':
        return new FusLocationFormatter(this.translate);

      case 'pn':
        return new PnLocationFormatter(this.translate);

      case 'cnu':
        return new CnuLocationFormatter(this.translate);

      case 'craiol':
        return new CraiolLocationFormatter(this.translate);

      case 'mdp':
        return new MdpLocationFormatter(this.translate);

      default:
        return new DefaultLocationFormatter(this.translate);
    }
  }
}
