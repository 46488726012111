import { SingleCallCardModule } from '@addins/core/singlecallcard';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SagaPipeModule } from '@app/pipes/module';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { VideoCapturePlus } from '@ionic-native/video-capture-plus/ngx';
import { IonicModule } from '@ionic/angular';
import { FileUploadModule, UPLOAD_CONFIGURATOR, UploadManagerService } from '@techwan/file-upload';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../modules/shared/shared.module';
import { CameraComponent } from './components/camera/camera.component';
import { MediaMessageAttachmentComponent } from './components/media-message-attachment/media-message-attachment.component';
import { MediaMessageDeploymentComponent } from './components/media-message-deployment/media-message-deployment.component';
import { MediaMessageEditorComponent } from './components/media-message-editor/media-message-editor.component';
import { MediaMessageItemComponent } from './components/media-message-item/media-message-item.component';
import { MediaMessagesListComponent } from './components/media-messages-list/media-messages-list.component';
import { MediaMessagesNewComponent } from './components/media-messages-new/media-messages-new.component';
import { UploadControlComponent } from './components/upload-control/upload-control.component';
import { UploadFeedbackComponent } from './components/upload-feedback/upload-feedback.component';
import { UploadItemComponent } from './components/upload-item/upload-item.component';
import { UPLOAD_FEEDBACK_TOKEN } from './schema/injection-token';
import { MODULE_NAME } from './schema/module.name';
import { AttachmentOptionFactoryService } from './services/attachment-option-factory/attachment-option-factory.service';
import { AttachmentProxyService } from './services/attachment-proxy/attachment-proxy.service';
import { AttachmentService } from './services/attachment/attachment.service';
import { CameraBridgeService } from './services/camera-bridge/camera-bridge.service';
import { CameraProviderService } from './services/camera-provider/camera-provider.service';
import { FileAccessToolService } from './services/executables/file-access-tool/file-access-tool.service';
import { MenuAccessToolService } from './services/executables/menu-access-tool/menu-access-tool.service';
import { MenuAccessService } from './services/executables/menu-access/menu-access.service';
import { PictureAccessToolService } from './services/executables/picture-access-tool/picture-access-tool.service';
import { VideoAccessToolService } from './services/executables/video-access-tool/video-access-tool.service';
import { FileChooserService } from './services/file-chooser/file-chooser.service';
import { FileDelegateService } from './services/file-delegate/file-delegate.service';
import { FileReaderService } from './services/file-reader/file-reader.service';
import { MediaFileService } from './services/media-file/media-file.service';
import { MediaMessageProxyService } from './services/media-message-proxy/media-message-proxy.service';
import { MediaMessagesAvailableService } from './services/media-messages/media-messages-available/media-messages-available.service';
import { MediaMessagesFilterService } from './services/media-messages/media-messages-filter/media-messages-filter.service';
import { MediaMessagesNewService } from './services/media-messages/media-messages-new/media-messages-new.service';
import { MediaMessagesService } from './services/media-messages/media-messages/media-messages.service';
import { MessageMenuAccessItemService } from './services/menu/message-menu-access-item/message-menu-access-item.service';
import { UploadMenuAccessItemService } from './services/menu/upload-menu-access-item/upload-menu-access-item.service';
import { SettingsService } from './services/settings/settings.service';
import { TextMessageService } from './services/text-message/text-message.service';
import { ThumbnailService } from './services/thumbnail/thumbnail.service';
import { UploadConfiguratorService } from './services/upload-configurator/upload-configurator.service';
import { UploadFeedbackService } from './services/upload-feedback/upload-feedback.service';
import { UploadRepositoryService } from './services/upload-repository/upload-repository.service';
import { UploadService } from './services/upload/upload.service';
import { MediaMessagesViewComponent } from './views/media-messages-view/media-messages-view.component';
import { UploadViewComponent } from './views/upload-view/upload-view.component';

const components = [
  CameraComponent,
  MediaMessageEditorComponent,
  MediaMessageAttachmentComponent,
  MediaMessageDeploymentComponent,
  MediaMessageItemComponent,
  MediaMessagesListComponent,
  MediaMessagesNewComponent,
  UploadFeedbackComponent,
  UploadControlComponent,
  UploadItemComponent
];

const views = [MediaMessagesViewComponent, UploadViewComponent];

const declarations = [...components, ...views];

const services = [
  AttachmentOptionFactoryService,
  AttachmentProxyService,
  AttachmentService,
  Camera,
  CameraBridgeService,
  CameraProviderService,
  FileTransfer,
  MediaMessagesAvailableService,
  MediaMessagesNewService,
  MediaMessagesService,
  MediaFileService,
  MessageMenuAccessItemService,
  FileChooserService,
  FileDelegateService,
  FileReaderService,
  MediaMessagesFilterService,
  SettingsService,
  TextMessageService,
  ThumbnailService,
  UploadConfiguratorService,
  UploadMenuAccessItemService,
  UploadRepositoryService,
  UploadService,
  VideoCapturePlus,
  MediaMessageProxyService,
  { provide: UPLOAD_CONFIGURATOR, useExisting: UploadConfiguratorService },
  { provide: UPLOAD_FEEDBACK_TOKEN, useExisting: UploadFeedbackService }
];

const executables = [MenuAccessToolService, PictureAccessToolService, VideoAccessToolService, FileAccessToolService, MenuAccessService];

@Addin({
  name: MODULE_NAME
})
@NgModule({
  declarations: [...declarations],
  providers: [...services, ...executables],
  imports: [IonicModule, FormsModule, CommonModule, SharedModule, SingleCallCardModule, FileUploadModule, SagaPipeModule]
})
export class MediaMessageModule extends AddinModule {
  constructor(
    injector: Injector,
    private uploadManager: UploadManagerService,
    private uploadConfigurator: UploadConfiguratorService,
    private uploadRepository: UploadRepositoryService
  ) {
    super(injector, views, executables);
  }

  load() {
    if (!this.loaded) {
      super.load();
      this.uploadConfigurator.setup().subscribe(() => {
        this.uploadManager.init();
        this.uploadRepository.setup();
      });
    }
  }
}
