import { Component, OnDestroy, OnInit } from '@angular/core';
import { BarcodeFormat, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { ToastColor, ToastDuration, ToastService } from '@services/toast/toast.service';
import { UnitActivityProxyService } from '@services/unit-activity-proxy/unit-activity-proxy.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qr-code-view',
  templateUrl: './qr-code-view.component.html',
  styleUrls: ['./qr-code-view.component.scss']
})
export class QRCodeViewComponent implements OnInit, OnDestroy {
  private unitChangeSub: Subscription = null;

  qrData: string;
  isEngaged: boolean = false;

  constructor(
    private myEquipment: MyEquipmentService,
    private myUnit: MyUnitService,
    private unitActivityProxy: UnitActivityProxyService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.myEquipment.myDevice.subscribe(communicationEquipment => (this.qrData = communicationEquipment.Name)).unsubscribe();

    this.onMyUnitChange();
    this.unitChangeSub = this.myUnit.$change.subscribe(() => this.onMyUnitChange());
  }

  private onMyUnitChange() {
    this.isEngaged = this.myUnit.isEngaged();
  }

  ngOnDestroy(): void {
    this.unitChangeSub.unsubscribe();
  }

  onScanQrCode() {
    BarcodeScanner.isSupported().then(result => {
      if (result.supported) {
        BarcodeScanner.scan({ formats: [BarcodeFormat.QrCode] })
          .then(result => {
            if (result.barcodes.length == 1) {
              const resourceName: string = result.barcodes[0].displayValue;
              this.unitActivityProxy
                .joinUnitActivity(resourceName)
                .toPromise()
                .then(result => {
                  if (!result) {
                    this.toastService.show('Mobile.JoinUnitError', ToastColor.Error, undefined, ToastDuration.Long);
                  }
                });
            }
          })
          .catch(error => console.log(error));
      }
    });
  }
}
