import { Component, Inject, OnInit } from '@angular/core';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { ProfileService } from '@services/profile/profile.service';
import { LayoutService } from '@services/layout/layout.service';
import { ApplicationBase } from '@schema/application-base';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SideMenuService } from '@services/menu/side-menu.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Device } from '@ionic-native/device/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss']
})
export class AppRootComponent extends ApplicationBase implements OnInit {
  get showMenu(): boolean {
    return this.layout.hasSideMenu && this.view.inApp;
  }

  constructor(
    private readonly view: ViewSelectorService,
    private readonly profile: ProfileService,
    private readonly layout: LayoutService,
    platform: Platform,
    protected modalCtrl: ModalController,
    public menuService: SideMenuService,
    statusBar: StatusBar,
    splashScreen: SplashScreen,
    backgroundMode: BackgroundMode,
    protected _menu: MenuController,
    protected device: Device
  ) {
    super(platform, modalCtrl, menuService, statusBar, splashScreen, backgroundMode, _menu, device);
  }

  ngOnInit() {}
}
