<ng-container *ngFor="let wrappedCObject of cObjectWrappers">
  <ion-item>
    <ion-icon name="cube" slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <div *ngIf="wrappedCObject.wrappedParticular.Kind">
        <h2>
          <strong translate>Mobile.CObjectKind</strong>
        </h2>
        <h2>{{ wrappedCObject.wrappedParticular.Kind }}</h2>
      </div>
      <div *ngIf="wrappedCObject.wrappedParticular.Identification">
        <h2>
          <strong translate>Mobile.CObjectIdentification</strong>
        </h2>
        <h2>{{ wrappedCObject.wrappedParticular.Identification }}</h2>
      </div>
      <div *ngIf="wrappedCObject.wrappedParticular.Make">
        <h2>
          <strong translate>Mobile.CObjectMake</strong>
        </h2>
        <h2>{{ wrappedCObject.wrappedParticular.Make }}</h2>
      </div>
      <div *ngIf="wrappedCObject.wrappedParticular.Text">
        <h2>
          <strong translate>Mobile.CObjectText</strong>
        </h2>
        <h2>{{ wrappedCObject.wrappedParticular.Text }}</h2>
      </div>
      <div *ngIf="wrappedCObject.wrappedParticular.Color">
        <h2>
          <strong translate>Mobile.CObjectColor</strong>
        </h2>
        <h2>{{ wrappedCObject.wrappedParticular.Color }}</h2>
      </div>
    </ion-label>
  </ion-item>
</ng-container>
