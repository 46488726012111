import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMessagesNewService } from '../../services/media-messages/media-messages-new/media-messages-new.service';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';

@Component({
  selector: 'app-media-messages-new',
  templateUrl: './media-messages-new.component.html',
  styleUrls: ['./media-messages-new.component.scss']
})
export class MediaMessagesNewComponent implements OnInit, OnDestroy {
  get newMediaMessages(): MediaMessage[] {
    return this._newMediaMessages.mediaMessages;
  }

  get since(): Date {
    return this._newMediaMessages.since;
  }

  constructor(private _newMediaMessages: MediaMessagesNewService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this._newMediaMessages.clear();
  }
}
