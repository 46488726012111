import { ImageOptions } from '@capacitor/camera';
import { VideoCapturePlusOptions } from '@ionic-native/video-capture-plus/ngx';
import { MediaType as SagaDataType } from '@models/imported/SagaBase';
import { MediaType } from './media/media-type';

export class AttachmentOption {
  readonly captureOptions: VideoCapturePlusOptions | ImageOptions = {};

  private _isMobile = false;
  get mobile(): boolean {
    return this._isMobile;
  }

  get type(): MediaType {
    return this._type;
  }

  get mimeType(): string {
    return this._type === MediaType.Picture ? 'base64' : '';
  }

  private _dataType: SagaDataType;
  get dataType(): SagaDataType {
    return this._dataType;
  }

  constructor(private _type: MediaType) {}

  setIsMobile(): void {
    this._isMobile = true;
  }
}
