import { Component } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { ListSettingValue } from '../../schema/list-setting-value';
import { CustomComponentsService } from '../../services/custom-components/custom-components.service';

export class ListSettingWithNames {
  setting: ListSettingValue;
  displayNames: Object;
  readonly: boolean;
}

@Component({
  selector: 'app-settings-editor',
  templateUrl: './settings-editor.component.html',
  styleUrls: ['./settings-editor.component.scss']
})
@AddinView({
  name: 'SettingsEditor'
})
export class SettingsEditorComponent {
  get customComponents() {
    return this.customSettingsComponents.customComponents;
  }

  constructor(private customSettingsComponents: CustomComponentsService) {}
}
