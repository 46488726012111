import { CoreModule } from '@addins/core/core';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaComponentsModule } from '@app/components/module';
import { SagaDirectivesModule } from '@app/directives';
import { SagaServiceModule } from '@app/services';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { StatusItemComponent } from './components/status-item/status-item.component';
import { StatusListComponent } from './components/status-list/status-list.component';
import { StatusModalComponent } from './components/status-modal/status-modal/status-modal.component';
import { TerminateMessageSelectionComponent } from './components/terminate-message-selection/terminate-message-selection.component';
import { IsAssignmentService } from './services/executables/is-assignment/is-assignment.service';
import { StatusAccessMenuService } from './services/executables/status-access-menu/status-access-menu';
import { StatusChangeRequirementsService } from './services/status-change-requirements/status-change-requirements.service';
import { StatusImageService } from './services/status-images/status-image.service';
import { StatusTimeService } from './services/status-time/status-time.service';
import { TerminateMessageModalService } from './services/terminate-message-modal/terminate-message-modal.service';
import { TerminatedMessagesService } from './services/terminated-messages/terminated-messages.service';
import { HorizontalListComponent } from './views/horizontal-list/horizontal-list.component';

const executables = [StatusAccessMenuService, IsAssignmentService];
const services = [
  ...executables,
  StatusChangeRequirementsService,
  StatusTimeService,
  StatusImageService,
  TerminateMessageModalService,
  TerminatedMessagesService
];

const directives = [];
const components = [StatusListComponent, StatusItemComponent, StatusModalComponent, TerminateMessageSelectionComponent];
const views = [HorizontalListComponent];

const declarations = [...components, ...views, ...directives];

@NgModule({
  declarations,
  imports: [IonicModule, CommonModule, SagaDirectivesModule, TranslateModule, SagaServiceModule, SagaComponentsModule, CoreModule],
  exports: declarations,
  providers: services
})
@Addin({
  name: 'Status',
  autoload: false
})
export class StatusModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
