import { PanelToolBase, PanelViewProvider } from '@addins/core/panel';
import { Observable, Subject } from 'rxjs';

export enum PanelEvent {
  toolAdded,
  toolRemoved,
  panelResized
}

export interface IPanelChanged {
  name: PanelEvent;
  tool?: PanelToolBase;
}

export class AdditionalViewTools implements PanelViewProvider {
  private _change = new Subject<IPanelChanged>();
  get $change(): Observable<IPanelChanged> {
    return this._change.asObservable();
  }

  private _panelTools: PanelToolBase[] = [];
  get tools(): PanelToolBase[] {
    return this._panelTools;
  }

  constructor() {}

  add(viewProvider: PanelToolBase) {
    this._panelTools.push(viewProvider);
    this._change.next({ name: PanelEvent.toolAdded, tool: viewProvider });
  }

  remove(viewProvider: PanelToolBase) {
    const index = this._panelTools.indexOf(viewProvider);
    if (index > -1) {
      this._panelTools.splice(index, 1);
      this._change.next({ name: PanelEvent.toolRemoved, tool: viewProvider });
    }
  }

  onResize(): void {
    this._change.next({ name: PanelEvent.panelResized });
  }
}
