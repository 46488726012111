<ion-header translucent>
  <ion-toolbar>
    <ion-title translate>Mobile.LinkVehicle</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item-divider sticky>
      <ion-label class="ion-text-wrap title"
        ><h2>{{ callcardTitle }}</h2></ion-label
      >
    </ion-item-divider>

    <ion-item (click)="onAvailableResourceClicked()">
      <ion-label class="ion-text-wrap">
        <h2><strong translate>Mobile.AvailableResource</strong></h2>
        <h3>{{ selectedVehicle ? selectedVehicle.VehicleCode : ('Mobile.SelectResource' | translate) }}</h3>
      </ion-label>
      <ion-icon slot="end" name="caret-down-outline"></ion-icon>
    </ion-item>

    <ion-item (click)="onPeopleCountClicked()">
      <ion-label>
        <h2><strong translate>Mobile.Engaged</strong></h2>
        <h3>{{ peopleCount ? (peopleCount | number: '2.0-0') : ('Mobile.EnterNumber' | translate) }}</h3>
      </ion-label>
      <ion-icon slot="end" name="caret-down-outline"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-button (click)="onDismiss()" color="danger" translate>Mobile.Cancel</ion-button>
  <ion-button [disabled]="!isValid()" (click)="onSubmit()" color="primary" translate>Mobile.Confirm</ion-button>
</ion-footer>
