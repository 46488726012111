import { Injectable } from '@angular/core';
import { ActionSheetButton, OverlayEventDetail } from '@ionic/core';
import { ActionSheetController } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslateService } from '@ngx-translate/core';
import { Tool } from '@services/tools';

@Injectable()
export class MediaProviderService {
  private readonly _buttons: Tool[] = [];

  public constructor(private readonly _asc: ActionSheetController, private readonly translate: TranslateService) {}

  public count(): number {
    return this._buttons.length;
  }

  public getButtons(): Tool[] {
    return this._buttons;
  }

  public open(callcard: CallCard): Promise<OverlayEventDetail> {
    const buttons = this._buttons.map(t => this.createButton(t));
    return this._asc
      .create({ header: this.translate.instant('Saga.AttachmentsType'), buttons })
      .then(as => as.present().then(() => as.onDidDismiss().then(e => this.execute(callcard, e))));
  }

  public register(button: Tool) {
    this._buttons.push(button);
  }

  private execute(cc: CallCard, e: OverlayEventDetail): OverlayEventDetail {
    if (e.role !== 'backdrop') {
      this._buttons.find(i => i.text === e.role).handler({ callcard: cc });
    }
    return e;
  }

  private createButton(t: Tool): ActionSheetButton {
    return {
      text: this.translate.instant(t.text),
      role: t.text
    };
  }
}
