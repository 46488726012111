import { Injectable } from '@angular/core';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { ResourceProxyService } from '../resource-proxy/resource-proxy.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';

@Injectable()
export class ResourcesProviderService {
  constructor(private readonly _proxy: ResourceProxyService, private readonly _factory: ObjectFactory) {}

  public getVehicles(offset: number, txt: string): Observable<Vehicle[]> {
    return this._proxy.getAvailableResources(offset, txt).pipe(map(i => this._factory.createList(i)));
  }

  public getActors(offset: number, txt: string): Observable<Actor[]> {
    return this._proxy.getActorList(offset, txt).pipe(map(i => this._factory.createList(i)));
  }

  public getEquipments(offset: number, txt: string): Observable<CommunicationEquipment[]> {
    return this._proxy.getEquipmentList(offset, txt).pipe(map(i => this._factory.createList(i)));
  }
}
