<ion-item (click)="enableInteraction && call(caller.Phone)">
  <ion-icon name="call" slot="start" size="large"></ion-icon>
  <ion-label class="ion-text-wrap">
    <h2>
      <strong translate>SagaSchema.CCaller_{{ caller.LineOwner }}</strong>
    </h2>
    <div *ngIf="caller.IsFirstCaller && showIsFirstCaller">
      <h2>
        <strong translate>Mobile.CCaller.IsFirstCaller</strong>
      </h2>
    </div>
    <div *ngIf="caller.PersonQualifierId | caller: 'Qualifier'">
      <h2>
        <strong translate>Mobile.CCaller.Qualifier</strong>
      </h2>
      <h2>{{ caller.PersonQualifierId | caller: 'Qualifier' | translate }}</h2>
    </div>
    <div *ngIf="caller.Phone">
      <h2>
        <strong translate>Mobile.CCaller.Phone</strong>
      </h2>
      <h2>{{ caller.Phone }}</h2>
    </div>
    <div *ngIf="caller.Mobile">
      <h2>
        <strong translate>Mobile.CCaller.Mobile</strong>
      </h2>
      <h2>{{ caller.Mobile }}</h2>
    </div>
    <div *ngIf="caller.name">
      <h2>
        <strong translate>Mobile.CCaller.Name</strong>
      </h2>
      <h2>{{ caller.name }}</h2>
    </div>
    <div *ngIf="caller.Organization">
      <h2>
        <strong translate>Mobile.CCaller.Organization</strong>
      </h2>
      <h2>{{ caller.Organization }}</h2>
    </div>
    <div *ngIf="caller.DateOfBirth">
      <h2>
        <strong translate>Mobile.CCaller.DateOfBirth</strong>
      </h2>
      <h2>{{ caller.DateOfBirth | sagaDate: 'ShortDateFormat' }}</h2>
    </div>
    <div *ngIf="caller.NationalityId | caller: 'Nationality'">
      <h2>
        <strong translate>Mobile.CCaller.Nationality</strong>
      </h2>
      <h2>{{ caller.NationalityId | caller: 'Nationality' | translate }}</h2>
    </div>
    <div *ngIf="caller.location">
      <h2>
        <strong translate>Mobile.CCaller.Location</strong>
      </h2>
      <h2>{{ caller.FormattedLocation }}</h2>
    </div>
    <div *ngIf="caller.Complement">
      <h2>
        <strong translate>Mobile.CCaller.Complement</strong>
      </h2>
      <h2>{{ caller.Complement }}</h2>
    </div>
    <div *ngIf="caller.Narrative">
      <h2>
        <strong translate>Mobile.CCaller.Narrative</strong>
      </h2>
      <h2>{{ caller.Narrative }}</h2>
    </div>
  </ion-label>
</ion-item>
