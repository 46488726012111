import { Injectable } from '@angular/core';
import { CallCardDetailPopoverComponent } from '../../components/callcard-detail-popover/callcard-detail-popover.component';
import { PopoverController } from '@ionic/angular';
import { AddStampButtonsProviderService } from '../add-stamp-buttons-provider/add-stamp-buttons-provider.service';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Injectable()
export class CardStampsPopoverService {
  constructor(private popover: PopoverController, private addStampButtonsProviderService: AddStampButtonsProviderService) {}

  showAddButton(): boolean {
    return this.addStampButtonsProviderService.buttons.length > 0;
  }

  open(event, callcard: CallCard) {
    this.popover
      .create({
        component: CallCardDetailPopoverComponent,
        componentProps: {
          card: callcard
        },
        event
      })
      .then(popover => popover.present());
  }
}
