export class CollapsibleItem {
  private _isVisible: boolean = false;
  collapsed: boolean = true;

  get viewName(): string {
    return this._viewName;
  }

  get title(): string {
    return this._title;
  }

  get position(): number {
    return this._position;
  }

  constructor(protected _title: string, protected _viewName: string, protected _position: number = 1000) {}

  isVisible(): boolean {
    return this._isVisible;
  }

  setVisible(isVisible: boolean) {
    this._isVisible = isVisible;
  }
}
