import {
  ICallcardButtonEvent,
  ICallcardButtonTool,
  ToolColor,
  ToolExpand,
  ToolFill,
  ToolSize,
  ToolType
} from '@addins/core/singlecallcard';
import { Observable, Subject } from 'rxjs';

export class AttachmentsCardButton implements ICallcardButtonTool {
  readonly ATTACHMENTS_TITLE: string = 'Mobile.Attachments';

  private readonly _click = new Subject<ICallcardButtonEvent>();
  get onClick(): Observable<ICallcardButtonEvent> {
    return this._click.asObservable();
  }

  readonly icon: string = 'attach';
  position: number = 1000;
  show: boolean = false;
  text: string = this.ATTACHMENTS_TITLE;
  dontTranslate: boolean = true;
  type: ToolType;

  color: ToolColor;
  expand: ToolExpand;
  fill: ToolFill;
  size: ToolSize;

  click(): void {
    this._click.next();
  }
}
