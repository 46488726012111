import { IAppSetting } from '@addins/core/core';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { SagaSettingsService } from '../saga-settings/saga-settings.service';
import { AppSetting } from '../schema/app-setting';
import { BooleanAppSetting } from '../schema/boolean-app-setting';

enum AppSettings {
  DarkModeEnabled = 'DarkMode',
  LargeFontEnabled = 'LargeFont',
  LargeMenuEnabled = 'LargeMenu'
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingProviderService {
  private appSettings = new Map<string, AppSetting<any>>();

  constructor(private appSettingsService: AppSettingsService, private sagaSettings: SagaSettingsService) {}

  getLargeFont(): Observable<IAppSetting<boolean>> {
    return this.getBooleanSettingWithDefault(AppSettings.LargeFontEnabled, 'SagaMobileWebClient.DefaultLargeFont');
  }

  getLargeMenu(): Observable<IAppSetting<boolean>> {
    return this.getBooleanSettingWithDefault(AppSettings.LargeMenuEnabled, 'SagaMobileWebClient.DefaultLargeMenu');
  }

  private getBooleanSettingWithDefault(name: string, defaultSettingKey: string): Observable<IAppSetting<boolean>> {
    return this.appSettings.has(name)
      ? of(this.appSettings.get(name))
      : this.sagaSettings.$ready.pipe(
          filter(ready => ready),
          first(),
          switchMap(() => {
            const defaultValue: boolean = this.sagaSettings.getValue(defaultSettingKey);
            return of(this.store(name, this.createBooleanSetting(name, defaultValue, true)));
          })
        );
  }

  getDarkModeEnabled(): Observable<IAppSetting<boolean>> {
    return this.getBooleanSetting(AppSettings.DarkModeEnabled, false, true);
  }

  getBooleanSetting(name: string, defaultValue: boolean, useStorage: boolean = true): Observable<IAppSetting<boolean>> {
    return this.appSettings.has(name)
      ? of(this.appSettings.get(name))
      : of(this.store(name, this.createBooleanSetting(name, defaultValue, useStorage)));
  }

  private createBooleanSetting(name: string, defaultValue: boolean, useStorage: boolean): BooleanAppSetting {
    const storageItem: string = localStorage.getItem(name);
    return new BooleanAppSetting(name, storageItem !== null ? storageItem === 'true' : defaultValue, useStorage);
  }

  getSetting<T>(name: string, def: T, useStorage: boolean = false): Observable<AppSetting<T>> {
    return this.appSettings.has(name) ? of(this.appSettings.get(name)) : this.get<T>(name, def, useStorage);
  }

  private get<T>(name: string, def: T, useStorage = false): Observable<AppSetting<T>> {
    return this.appSettings.has(name)
      ? of(this.appSettings.get(name))
      : this.appSettingsService.get(name, def, useStorage).pipe(map(v => this.store(name, new AppSetting<T>(name, v, useStorage))));
  }

  private store<T>(name: string, v: AppSetting<T>): AppSetting<T> {
    this.appSettings.set(name, v);
    return this.appSettings.get(name);
  }
}
