<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button (click)="close()"> <ion-icon slot="icon-only" name="close"></ion-icon> </ion-button>
        </ion-buttons>
        <ion-title>Images</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false" fullscreen="true">
    <ion-slides [options]="slideOpts" #slides  zoom="true">
        <ion-slide *ngFor="let image of images">
            <div class="swiper-zoom-container"><img [src]="image.url | secure | async" alt="Chargement..." height="100vh" /></div>

            <ion-footer *ngIf="image.caption">
                <ion-toolbar color="dark" text-center>
                    <ion-text>{{ image.caption }}</ion-text>
                </ion-toolbar>
            </ion-footer>
        </ion-slide>
    </ion-slides>
</ion-content>

<!--

-->
