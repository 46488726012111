import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@techwan/ionic-core';
import { LoginEvent } from '@techwan/security';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  template: ''
})
export class LoginSetupComponent implements OnInit, OnDestroy {
  protected readonly _subs: Subscription[] = [];

  protected _showLoginForm = false;
  get showLogin(): boolean {
    return this._showLoginForm;
  }

  constructor(protected readonly _authentication: AuthenticationService) {}

  ngOnInit(): void {
    this._subs.push(
      this._authentication.onStateChange
        .pipe(filter(loginEvent => loginEvent === LoginEvent.requireAuthentication))
        .subscribe(() => this.onShowLogin())
    );
  }

  ngOnDestroy() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  protected onShowLogin() {
    this._showLoginForm = true;
  }
}
