import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermanentEntry } from '../../schema/permanent-entry';

@Component({
  selector: 'app-permanent-menu-item',
  templateUrl: './permanent-menu-item.component.html',
  styleUrls: ['./permanent-menu-item.component.scss']
})
export class PermanentMenuItemComponent implements OnInit, OnDestroy {
  @Input()
  item?: PermanentEntry;
  @Input()
  size?: string;

  private itemChangedSub: Subscription = null;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.item) {
      this.itemChangedSub = this.item.onChanged.subscribe(() => this.changeDetector.detectChanges());
    }
  }

  ngOnDestroy(): void {
    if (this.itemChangedSub !== null) {
      this.itemChangedSub.unsubscribe();
      this.itemChangedSub = null;
    }
  }
}
