import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ResourcePosition } from '@models/imported/SagaSchema/ResourcePosition';
import { Guid } from '@models/guid';

export class UnitActivityOnMap {
  public get id(): Guid {
    return this._unit.ObjGuid;
  }

  public get position(): ol.Coordinate {
    return [this._position.MapX, this._position.MapY];
  }

  public get name(): string {
    return `${this._unit.Name} (${this._count})`;
  }

  public get icon(): string {
    return this._icon;
  }

  public get color(): number[] {
    return (this._unit.color && this._unit.color.toArray()) || null;
  }

  public get unit(): UnitActivity {
    return this._unit;
  }

  constructor(
    private readonly _unit: UnitActivity,
    private readonly _position: ResourcePosition,
    private readonly _count: number,
    private readonly _icon: string
  ) {}
}
