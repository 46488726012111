import { SideMenuComponent } from '@addins/core/menus';
import { NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { SagaServiceModule } from '@app/services';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../modules/shared/shared.module';
import { VisualModule } from '../../modules/visual/visual.module';
import { SagaDirectivesModule } from '../directives';
import { AppRootComponent } from './app-root/app-root.component';
import { IconComponent } from './icon/icon.component';
import { RootComponent } from './root/root.component';
import { StatusBarComponent } from './statusBar/statusBar';

const components = [AppRootComponent, SideMenuComponent, StatusBarComponent, RootComponent, IconComponent];

@NgModule({
  declarations: components,
  exports: [...components, SharedModule],
  imports: [SharedModule, IonicModule, TranslateModule, SagaDirectivesModule, SagaServiceModule, VisualModule, SagaPipeModule]
})
export class SagaComponentsModule {}
