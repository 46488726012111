import { Injectable } from '@angular/core';
import { ILocationProvider } from '../../../schema/interfaces/location-provider';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Guid } from '@models/guid';
import { filter } from 'rxjs/operators';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Coordinate } from '@models/coordinate';
import { PositionProxyService } from '../position-proxy/position-proxy.service';
import { ILocationProxy } from '../../../schema/interfaces/location-proxy';

@Injectable()
export class GeolocationProviderService implements ILocationProvider {
  private readonly _options = { maximumAge: 60000, timeout: 120000, enableHighAccuracy: true };
  private readonly _mostRecent = new BehaviorSubject<Geoposition>(null);

  private _watch: Subscription = null;
  private _lastLocation: Coordinate = null;

  protected get value(): Geoposition {
    let v: Geoposition;
    this._mostRecent.subscribe(p => (v = p)).unsubscribe();
    return v;
  }

  constructor(private readonly _geolocation: Geolocation, private readonly _proxy: PositionProxyService) {}

  public get $change(): Observable<Geoposition> {
    return this._mostRecent.asObservable();
  }

  public getCurrent(): Observable<Geoposition> {
    return of(this.value);
  }

  public getProxy(): ILocationProxy {
    return this._proxy;
  }

  public setup(uid: Guid) {
    this._proxy.init(uid);
  }

  public start(): Observable<void> {
    if (this._watch === null) {
      this._watch = this._geolocation
        .watchPosition(this._options)
        .pipe(filter((p: Geoposition) => p.coords !== undefined)) // Filter Out Errors
        .subscribe((position: Geoposition) => {
          this._mostRecent.next(position);

          // invalidate current lastLocation. Will be recomputed on demand by reading currentLocation()
          this._lastLocation = null;
          this._proxy.update(position);
        });
      this._proxy.start();
    }
    return of();
  }

  public stop(): Observable<void> {
    if (this._watch !== null) {
      this._lastLocation = null;
      this._watch.unsubscribe();
      this._watch = null;
    }
    this._proxy.stop();
    return of();
  }
}
