import { Injectable } from '@angular/core';
import { ProfileService } from '@services/profile/profile.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { Subscription } from 'rxjs';
import { SINGLE_CALLCARD_PARTICULARS } from '../../schema/constants';

export interface ICallCardParticularsDelegate {
  onLoadCardParticulars();
  onUnloadCardParticulars();
}

@Injectable()
export class SingleCallcardParticularsControllerService {
  private readonly _callcardParticularsDelegate: ICallCardParticularsDelegate[] = [];

  private _profileReadySubscription: Subscription = null;

  constructor(private readonly _profileService: ProfileService, private readonly _profileReader: ProfileReaderService) {}

  public registerDelegate(delegate: ICallCardParticularsDelegate) {
    this._callcardParticularsDelegate.push(delegate);
    if (this._profileReadySubscription === null) {
      this._profileReadySubscription = this._profileService.ready.subscribe(ready => this.onProfileReady(ready));
    }
  }

  private onProfileReady(ready: boolean) {
    if (ready === true && this.hasCallCardParticulars()) {
      this._callcardParticularsDelegate.forEach(delegate => delegate.onLoadCardParticulars());
    } else if (!ready) {
      this._callcardParticularsDelegate.forEach(delegate => delegate.onUnloadCardParticulars());
    }
  }

  private hasCallCardParticulars(): boolean {
    return this._profileReader.getComponent(this._profileService.current, SINGLE_CALLCARD_PARTICULARS) !== null;
  }
}
