import { Injectable } from '@angular/core';
import { IdToTypeConverter } from '@models/imported/createInstanceById';
import { CStampFree } from '@models/imported/SagaSchema/CStampFree';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Destination } from '../../schema/destination';
import { DestinationProxyService } from '../destination-proxy/destination-proxy.service';
import { LastDestinationService } from '../last-destination/last-destination.service';

@Injectable()
export class DestinationProviderService {
  private selectedDestination: Destination;

  private _services: Destination[] = [];
  get services(): Destination[] {
    return this._services;
  }

  private _state = new BehaviorSubject(false);
  get state(): Observable<boolean> {
    return this._state.asObservable();
  }

  private _current = new BehaviorSubject<Destination>(null);
  get current(): Observable<Destination> {
    return this._current.asObservable();
  }

  set value(value: Destination) {
    if (this.selectedDestination !== value) {
      this.selectedDestination = value;
      this._current.next(value);
    }
  }

  get value(): Destination {
    return this.selectedDestination;
  }

  private _stamp: CStampFree = null;

  get isReady(): boolean {
    let state: boolean;
    this._state.subscribe(newState => (state = newState)).unsubscribe();
    return state;
  }

  constructor(
    private proxy: DestinationProxyService,
    private idToTypeConverter: IdToTypeConverter,
    private factory: ObjectFactory,
    private last: LastDestinationService
  ) {}

  setup(): void {
    this.last.setup();
    this.last.$current.subscribe(
      selectedDestination =>
        (this.value = (selectedDestination && this._services.find(service => selectedDestination.Text === service.Name)) || null)
    );
    this.idToTypeConverter.setConstructorOf(Destination.type, Destination);
    this.proxy.fetchAll().subscribe(fetchedElements => this.parse(fetchedElements));
  }

  select(item: Destination): Observable<CStampFree> {
    return this.proxy.setDestination(item).pipe(map(destination => (this._stamp = this.factory.create(destination))));
  }

  private parse(destinations: Destination[]) {
    this.factory.createList(destinations).forEach(destination => this._services.push(destination));
    this._state.next(true);
  }
}
