import { MyDrawing, PopupProviderService, TacticalDrawingsService } from '@addins/core/map';
import { ToolGroupComponent } from '@addins/core/map/components/tool-group/tool-group.component';
import { AdditionalViewToolsService, Panel, PanelContent } from '@addins/core/panel';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, PopoverController } from '@ionic/angular';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { DrawingManager, DrawingMode, IDrawingResult, MapToolsPanel, ToolBase, ToolGroup } from '@techwan/map-tools';
import { Subscription } from 'rxjs';
import { DrawingPanelService } from '../../services/drawing-panel/drawing-panel.service';
import { DrawingProxyService } from '../../services/drawing-proxy/drawing-proxy.service';

export interface TabButton {
  iconName: string;
  toolsName: string[];
}

@Component({
  selector: 'app-drawing-panel-content',
  templateUrl: './drawing-panel-content.component.html',
  styleUrls: ['./drawing-panel-content.component.scss']
})
export class DrawingPanelContentComponent implements OnInit, OnDestroy, AfterViewInit, PanelContent {
  private readonly ICONS_GROUP_NAME: string = 'icons';
  private readonly EDIT_TOOLS_NAME: string = 'edit';
  private readonly TAB_BUTTONS: TabButton[] = [
    { iconName: 'pencil-outline', toolsName: [this.EDIT_TOOLS_NAME, 'history'] },
    { iconName: 'trail-sign-outline', toolsName: ['deployment'] },
    { iconName: 'shapes-outline', toolsName: ['shapes'] },
    { iconName: 'image-outline', toolsName: [this.ICONS_GROUP_NAME] }
  ];
  get tabButtons(): TabButton[] {
    return this.TAB_BUTTONS;
  }

  private _panel: Panel;

  private _drawingManager: DrawingManager = null;
  private _myDrawing: MyDrawing = null;

  get tools(): MapToolsPanel {
    return this._drawingPanelService.tools;
  }

  private _isLoading = true;
  get isLoading(): boolean {
    return this._isLoading;
  }

  private _subs: Subscription[] = [];

  private _popover = null;
  private _activeToolsName: string[] = [];

  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('content', { static: true }) elementView: ElementRef;

  constructor(
    private _drawingPanelService: DrawingPanelService,
    private _drawingProxyService: DrawingProxyService,
    private _tacticalDrawingsService: TacticalDrawingsService,
    private _myUnitService: MyUnitService,
    private _additionalViewToolsService: AdditionalViewToolsService,
    private _popupProvider: PopupProviderService,
    private _popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    const temp = this._drawingPanelService.setup().subscribe(drawingManager => this.onSetup(drawingManager));

    if (this._drawingManager === null) {
      this._subs.push(temp);
    }
  }

  ngOnDestroy() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }

    this._drawingPanelService.destroy();
    if (this._drawingManager !== null) {
      this._drawingManager.destroy();
    }

    this.resetPopover();

    this.onDrawingPanelActive(false);
  }

  private resetPopover(): void {
    if (this._popover) {
      this._popover.dismiss();
      this._popover = null;
    }
  }

  ngAfterViewInit() {
    this._additionalViewToolsService.onResize();
    this.onDrawingPanelActive(true);
  }

  private onDrawingPanelActive(isActive: boolean) {
    const scaleLine = document.getElementsByClassName('ol-scale-line')[0];
    if (scaleLine) {
      const drawingPanelHeight: string = getComputedStyle(this.elementView.nativeElement).height;
      scaleLine.setAttribute('style', isActive ? `margin-bottom: ${drawingPanelHeight}` : '');
    }
  }

  setPanel(panel: Panel): void {
    this._panel = panel;
  }

  private onSetup(drawingManager: DrawingManager) {
    if (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
    this._drawingManager = drawingManager;
    this._isLoading = false;

    this._drawingManager.current.mode.subscribe(mode => this.lockPopup(mode));
    this._subs.push(this._drawingManager.onResult.subscribe(r => this.onResult(r)));
    this._myDrawing = this._tacticalDrawingsService.getMyDrawings();
    this.refresh();
    this._subs.push(this._myDrawing.$change.subscribe(() => this.refresh()));

    this._subs.push(this.tools.$toolSelectionChanged.subscribe(tool => this.resetPopover()));
    this.getDrawingTools([this.EDIT_TOOLS_NAME]).forEach(tool => {
      this._subs.push(tool.$event.subscribe(toolBase => this.resetPopover()));
    });
  }

  private getToolGroup(groupName: string): ToolGroup {
    return this.tools.groups.find(group => group.name === groupName);
  }

  private lockPopup(drawingMode: DrawingMode): void {
    if (drawingMode === DrawingMode.readonly) {
      this._popupProvider.enable();
    } else {
      this._popupProvider.disable();
    }
  }

  private onResult(drawingResult: IDrawingResult) {
    const currentDrawing = this._myDrawing.current;
    if (!drawingResult.isCancel && currentDrawing !== null && drawingResult.drawings === null) {
      this._drawingProxyService.delete(currentDrawing.ObjGuid).subscribe();
    } else if (!drawingResult.isCancel && (currentDrawing === null || drawingResult.drawings !== currentDrawing.GeometryObjects)) {
      this._drawingProxyService
        .save(this._myUnitService.mine.CallCardID, (currentDrawing && currentDrawing.ObjGuid) || null, drawingResult.drawings)
        .subscribe();
    }
  }

  private refresh() {
    if (this._myDrawing.current !== null && this._myDrawing.current.GeometryObjects) {
      this._drawingManager.current.import(this._myDrawing.current.GeometryObjects);
    }
  }

  onToolGroupClicked(event: any, toolsName: string[]): void {
    this.resetPopover();

    if (toolsName !== this._activeToolsName) {
      this._activeToolsName = toolsName;
      if (toolsName.includes(this.ICONS_GROUP_NAME)) {
        this.activateIconsGroupButton();
      }

      const drawingTools = this.getDrawingTools(toolsName);
      this.showPopover(event, drawingTools);
    }
  }

  private activateIconsGroupButton(): void {
    const iconGroup = this.getToolGroup(this.ICONS_GROUP_NAME);
    if (iconGroup && !iconGroup.tools[0].isActive) {
      iconGroup.tools[0].click();
    }
  }

  private getDrawingTools(groupName: string[]): ToolBase[] {
    const filteredGroups = this.tools.groups.filter(group => groupName.includes(group.name));
    let drawingTools: ToolBase[] = [];
    if (filteredGroups[0].children.length) {
      filteredGroups[0].children.forEach(child => {
        drawingTools = drawingTools.concat(child.tools);
      });
    } else {
      filteredGroups.forEach(group => {
        drawingTools = drawingTools.concat(group.tools);
      });
    }

    return drawingTools;
  }

  private showPopover(event: any, drawingTools: ToolBase[]) {
    this._popoverCtrl
      .create({
        component: ToolGroupComponent,
        componentProps: { tools: drawingTools },
        cssClass: 'drawing-tools-popover',
        event,
        showBackdrop: false
      })
      .then(popover => {
        this._popover = popover;
        popover.onDidDismiss().then(() => (this._activeToolsName = []));
        popover.present();
      });
  }

  hasActiveTool(toolsName: string[]): boolean {
    const drawingTools = this.getDrawingTools(toolsName);
    const activeTool = drawingTools && drawingTools.find(tool => tool.isActive);
    if (activeTool) {
      return true;
    }
    return false;
  }
}
