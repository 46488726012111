import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { TranslateModule } from '@ngx-translate/core';
import { VisualModule } from '../../modules/visual/visual.module';
import { SagaComponentsModule } from '../components/module';
import { SagaPipeModule } from '../pipes/module';

@NgModule({
    imports: [VisualModule, SagaPipeModule, SagaDirectivesModule, TranslateModule, SagaComponentsModule],
    exports: [VisualModule, SagaPipeModule, SagaDirectivesModule, TranslateModule, SagaComponentsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
  export class CommonAddinModule {}
