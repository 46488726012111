import { CurrentCardService } from '@addins/core/core';
import { CollapsibleItemsService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { CFormLink } from '@models/imported/SagaSchema/CFormLink';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { SagaFormsCollapsibleItem } from '../../../schema/saga-forms-collapsible-item';

@AddinExecute({
  name: 'SagaFormsCollapsibleSetup'
})
@Injectable({
  providedIn: 'root'
})
export class SagaFormsCollapsibleService extends Execute {
  private formsCollapsibleItem = new SagaFormsCollapsibleItem();

  constructor(private itemsService: CollapsibleItemsService, private currentCardService: CurrentCardService) {
    super();
  }

  execute() {
    this.currentCardService.$change.subscribe(callcard => this.onCardChanged(callcard));

    this.formsCollapsibleItem.collapsed = false;
    this.itemsService.register(this.formsCollapsibleItem);
  }

  private onCardChanged(callcard: CallCard) {
    this.formsCollapsibleItem.items = callcard ? callcard.component<CFormLink>(CFormLink) : [];
  }
}
