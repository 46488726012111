export const DEFAULT_IMAGE_SIZE = [32, 32];

export enum ResizeMode {
  Normal,
  SquareCrop,
  SquareContain,
  Scale
}

export interface Size {
  width: number;
  height: number;
}

export function calcImageScale(imageUrl: string, width: number, height?: number): Promise<[number, number]> {
  return new Promise<[number, number]>(resolve => {
    const image = new Image();
    image.onload = () => {
      const scaleX = width / image.width;
      let scaleY;
      if (height) {
        scaleY = height / image.height;
      }
      resolve([scaleX, scaleY || scaleX]);
    };
    image.src = imageUrl;
  });
}

export function scaleImage(url: string, weight: number): Promise<string> {
  return new Promise<string>(resolve => {
    const originalScale = calculateResizeScale(url, weight);
    if (originalScale < 1) {
      getCanvasDataUrl(url).then(canvasUrl => {
        const scale = calculateResizeScale(canvasUrl, weight);
        return resizeImage(canvasUrl, scale, ResizeMode.Scale).then(resizeUrl => {
          resolve(resizeUrl);
        });
      });
    } else {
      resolve(url);
    }
  });
}

function calculateResizeScale(url: string, weight: number): number {
  const base64String = url.split(',')[1];
  const byteSize = atob(base64String).length;

  if (byteSize > weight) {
    return weight / byteSize;
  }
  return 1;
}

function getCanvasDataUrl(url): Promise<string> {
  return new Promise<string>(resolve => {
    const image = new Image();
    image.onload = () => {
      const type = url.split(';')[0].split(':')[1];
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);
      resolve(canvas.toDataURL(type));
    };
    image.src = url;
  });
}

function resizeImage(imageUrl: string, size: number, mode: ResizeMode = ResizeMode.Normal): Promise<string> {
  return new Promise<string>(resolve => {
    const image = new Image();
    image.onload = () => {
      const type = imageUrl.split(';')[0].split(':')[1];
      const baseSize = Math[mode === ResizeMode.SquareCrop ? 'min' : 'max'](image.height, image.width);
      if (mode === ResizeMode.Scale) {
        size = Math.sqrt(baseSize * baseSize * size);
        mode = ResizeMode.Normal;
      }
      const scale = size / baseSize;
      if (scale === 1) {
        resolve(imageUrl);
        return;
      }
      const newWidth = Math.round(image.width * scale);
      const newHeight = Math.round(image.height * scale);

      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        oc = document.createElement('canvas'),
        octx = oc.getContext('2d');

      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      canvas.width = newWidth; // destination canvas size
      canvas.height = newHeight;

      let cur: Size = {
        width: Math.floor(image.width * 0.5),
        height: Math.floor(image.height * 0.5)
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(image, 0, 0, cur.width, cur.height);

      let i = 4;
      while (cur.width * 0.5 > newWidth) {
        cur = {
          width: Math.floor(cur.width * 0.5),
          height: Math.floor(cur.height * 0.5)
        };
        octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
        i = i * 2;
      }

      const to = {
        x: 0,
        y: 0,
        width: canvas.width,
        height: canvas.height
      };

      const from = {
        x: 0,
        y: 0,
        width: cur.width,
        height: cur.height
      };

      if (mode !== ResizeMode.Normal) {
        ctx.drawImage(oc, from.x, from.y, from.width, from.height, to.x, to.y, to.width, to.height);

        canvas.height = size;
        canvas.width = size;

        if (mode === ResizeMode.SquareCrop) {
          if (to.width > to.height) {
            from.x = (to.width - size) / 2;
          } else {
            from.y = (to.height - size) / 2;
          }
        } else {
          if (to.width > to.height) {
            to.y = (size - to.height) / 2;
          } else {
            to.x = (size - to.width) / 2;
          }
        }
      }

      ctx.drawImage(oc, from.x, from.y, from.width, from.height, to.x, to.y, to.width, to.height);

      resolve(canvas.toDataURL(type));
    };
    image.src = imageUrl;
  });
}
