import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { HeaderProviderService } from '@addins/listcallcards';

@AddinExecute({
  name: 'EventList'
})
@Injectable()
export class EventListService extends Execute {
  constructor(private readonly _header: HeaderProviderService) {
    super();
  }

  public execute(): void {
    this._header.setView('CreateEvent/EventListAccess');
  }
}
