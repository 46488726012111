import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Destination } from '../../schema/destination';
import { CStampFree } from '@models/imported/SagaSchema/CStampFree';

@Injectable({
  providedIn: 'root'
})
export class DestinationProxyService {
  constructor(private readonly http: HttpClient) {}

  public fetchAll(): Observable<any> {
    return this.http.get('api/Service/GetDestinations');
  }

  public setDestination(item: Destination): Observable<CStampFree> {
    return this.http.post<CStampFree>('api/Service/AddDestination', item);
  }
}
