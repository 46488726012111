import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[showWhen]'
})
export class ShowWhenDirective implements OnInit {
  @Input('showWhen') value: string;

  constructor(private platform: Platform, private e: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const plts: any[] = (this.value || '').split(',');
    if (!plts.some(plt => this.platform.is(plt))) {
      this.renderer.removeChild(this.renderer.parentNode(this.e.nativeElement), this.e.nativeElement);
    }
  }
}
