import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CVehicle } from '@models/imported/SagaSchema/CVehicle';
import { ParticularsCollapsibleItem } from './particulars-collapsible-item';
import { ParticularsPosition } from './particulars-position';

const CVehicleListCollapsibleName = 'Mobile.CVehicleDetails';
const CVehicleListViewName = 'SingleCallcardParticulars/CallcardDetailCVehicle';

export class CVehicleCollapsibleItem extends ParticularsCollapsibleItem {
  constructor() {
    super(CVehicleListCollapsibleName, CVehicleListViewName, ParticularsPosition.CVehicle);
  }

  updateVisibility(callCard: CallCard): void {
    this.setVisible(callCard !== null ? callCard.component<CVehicle>(CVehicle).length > 0 : false);
  }
}
