import { Component } from '@angular/core';
import { SagaSettingsService } from '@services/settings';
import { CallcardComponentCard } from '../callcard-component-card';

@Component({
  selector: 'app-callcard-ccallers-card',
  templateUrl: './callcard-ccallers-card.component.html',
  styleUrls: ['./callcard-ccallers-card.component.scss']
})
export class CallcardCcallersCardComponent extends CallcardComponentCard {
  showAllCallers: boolean = false;

  constructor(private sagaSettings: SagaSettingsService) {
    super();
  }

  ngOnInit(): void {
    this.showAllCallers = this.sagaSettings.getValue('SagaMobileWebClient.ShowAllCallers');
  }
}
