import { MapName } from '@addins/core/core';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Type } from '@angular/core';
import { MapInstancesService } from '@services/map';
import { ExtendedLayer, Feature, Map as SagaMap, Overlay } from '@techwan/mapping';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PopupProviderService } from '../../services/popup-provider/popup-provider.service';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss']
})
export class MapPopupComponent implements OnInit, OnDestroy {
  public readonly items: Type<any>[] = [];

  private _map: SagaMap = null;
  private _close: Subscription = null;
  private _overlay: Overlay;

  private readonly _subs: Subscription[] = [];
  private readonly _listener: ol.EventsListenerFunctionType;

  constructor(
    private readonly _element: ElementRef,
    private readonly _mapInstancesService: MapInstancesService,
    private readonly _popupProviderService: PopupProviderService,
    private readonly _changeDetector: ChangeDetectorRef
  ) {
    this._listener = this.onMapClick.bind(this);
  }

  public ngOnDestroy() {
    if (this._map !== null) {
      this._map.un('singleclick', this._listener);
      this._map = null;
    }
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  public ngOnInit() {
    if (this._mapInstancesService.get(MapName.main) !== null) {
      this.setup();
    } else {
      this._subs.push(this._mapInstancesService.$change.pipe(filter(event => event.name === MapName.main)).subscribe(() => this.setup()));
    }
  }

  private setup() {
    this._map = this._mapInstancesService.get(MapName.main);
    if (this._map !== null) {
      this._overlay = new Overlay(this._map, { element: this._element.nativeElement });
      this._overlay.setName('popup-overlay');
      this._subs.push(this._popupProviderService.enabled.subscribe((enabled: boolean) => this.enable(enabled)));
    }
  }

  private clear(hide?: boolean) {
    this._close.unsubscribe();
    this._close = null;
    this.items.length = 0;

    if (hide === true) {
      this._element.nativeElement.hidden = true;
    }
  }

  private enable(enable: boolean) {
    if (enable) {
      this._map.on('singleclick', this._listener);
    } else {
      this._map.un('singleclick', this._listener);
      this.items.length = 0;
      this._element.nativeElement.hidden = true;
    }
  }

  private onMapClick(event: MapBrowserEvent) {
    this._popupProviderService.content.length = 0;
    this.items.length = 0;
    this._map.forEachFeatureAtPixel(
      event.pixel,
      (feature: Feature, layer: ExtendedLayer) => this._popupProviderService.content.push({ feature, layer }),
      {
        hitTolerance: 6
      }
    );
    if (this._popupProviderService.content.length > 0) {
      this.showPopup(event);
    } else if (this._close !== null) {
      this.clear();
    }
    this._element.nativeElement.hidden = this._popupProviderService.content.length === 0;
  }

  private showPopup(event: MapBrowserEvent) {
    this._changeDetector.detectChanges();
    this._popupProviderService.getComponents(this._popupProviderService.content).forEach(i => this.items.push(i));
    this._popupProviderService.refresh();
    this._changeDetector.detectChanges();

    this._overlay.setPosition(event.coordinate);

    if (this._close === null) {
      this._close = this._popupProviderService.$close.subscribe(() => this.clear(true));
    }
  }
}
