import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { Observable, Subject } from 'rxjs';
import { ITacticalDrawingEvent } from './interfaces/ITacticalDrawingEvent';
import { DrawingEventType } from './event-type';

export class MyCardDrawings {
  private readonly _tacticalSituations: CTacticalSituation[] = [];

  private readonly _event = new Subject<ITacticalDrawingEvent>();
  get $change(): Observable<ITacticalDrawingEvent> {
    return this._event.asObservable();
  }

  get isEmpty(): boolean {
    return this._tacticalSituations.length === 0;
  }

  get tacticalSituations(): CTacticalSituation[] {
    return this._tacticalSituations;
  }

  add(value: CTacticalSituation) {
    this._tacticalSituations.push(value);
    this._event.next({
      target: value,
      type: DrawingEventType.add
    });
  }

  remove(value: CTacticalSituation) {
    const idx = this._tacticalSituations.indexOf(value);
    if (idx > -1) {
      this._event.next({
        target: this._tacticalSituations.splice(idx, 1)[0],
        type: DrawingEventType.remove
      });
    }
  }

  update(value: CTacticalSituation) {
    const idx = this._tacticalSituations.indexOf(value);
    if (idx > -1) {
      this._event.next({
        target: value,
        type: DrawingEventType.update
      });
    }
  }

  clear() {
    if (this._tacticalSituations.length > 0) {
      // Create a copy to avoid iterate on the modified one
      this._tacticalSituations.concat([]).forEach(tacticalSituation => this.remove(tacticalSituation));
    }
  }
}
