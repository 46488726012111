import { Component, OnInit } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { SagaSettingsService } from '@services/settings';
import { Subscription } from 'rxjs';
import { ListBase } from '../../schema/list-base';
import { UnitEditorService } from '../../services/unit-editor/unit-editor.service';

@Component({
  template: ''
})
export abstract class ListBaseComponent<T extends SagaObject> extends ListBase<T> implements OnInit {
  readonly item;

  protected queryLimit: number;

  protected _list: T[];
  protected nextItemQuerySubscription: Subscription;

  searchExpression: string;

  constructor(protected unitEditor: UnitEditorService, protected sagaSettings: SagaSettingsService) {
    super();
    this.queryLimit = this.sagaSettings.getValue('SagaMobileWebClient.ResourceMaxCount');
  }

  ngOnInit(): void {
    this.loadCurrentTabState();
  }

  select(event: any, item: T) {
    event.target.checked ? this.unitEditor['add' + `${this.tabType}`](item) : this.unitEditor['remove' + `${this.tabType}`](item);
  }

  checkOffset() {
    if (!this.nextItemQuerySubscription && !this.isEndOfTheList) {
      const offset = Math.floor(this.list.length / this.queryLimit) * this.queryLimit;
      this.nextItemQuerySubscription = this.getItems(offset, this.searchExpression).subscribe(queriedItems =>
        this.setList(queriedItems, offset)
      );
    }
  }

  protected parse(res: T[], reset: boolean) {
    this.setList(res, 0);
  }

  setList(items: T[], offset: number) {
    super.parse(items, offset === 0);
    this.saveCurrentTabState(this.searchExpression, offset);

    if (this.nextItemQuerySubscription) {
      this.nextItemQuerySubscription.unsubscribe();
      this.nextItemQuerySubscription = null;
    }

    // Add the items to the virtual list and check them if required.
    this.list
      .slice(offset, this.queryLimit)
      .forEach(item => (item.checked = (this._list.find(j => j.ObjGuid === item.item.ObjGuid) || null) !== null));
  }

  protected loadCurrentTabState() {
    const reloadState = this.unitEditor.editor.tabsStates.find(state => state.type === this.tabType);

    if (reloadState) {
      this.searchExpression = reloadState.searchText;
      super.parse(reloadState.actualList as any, reloadState.offset === 0, true);
      this.list
        .slice(0, reloadState.offset ? reloadState.offset : this.queryLimit)
        .forEach(item => (item.checked = (this._list.find(j => j.ObjGuid === item.item.ObjGuid) || null) !== null));
    }
  }

  protected saveCurrentTabState(searchText: string, offset: number) {
    const elementIndex = this.unitEditor.editor.tabsStates.findIndex(state => state.type === this.tabType);
    this.unitEditor.saveTabActualState({ type: this.tabType, searchText: searchText, offset: offset, actualList: this.list }, elementIndex);
  }
}
