// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:22
import { Vehicle_abstract } from './../abstract/SagaSchema/Vehicle_abstract';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { ListVehicleType } from '@models/imported/SagaSchema/ListObjects/ListVehicleType';

export class Vehicle extends Vehicle_abstract {
  static globalIcon: string = 'md-directions_car';

  private _status: ListResourceStatus;
  private _type: ListVehicleType;

  get color() {
    return this.status && this.status.color;
  }

  get status(): ListResourceStatus {
    return this._status;
  }

  get type(): ListVehicleType {
    return this._type;
  }

  get className() {
    return this.$t.replace(/\./g, '_') + ' ' + 'Type_' + this.type.Id;
  }

  private updateStatus(fetchList: FetchListService) {
    let row = fetchList.getRow(ListResourceStatus, this.Status);
    if (row != null) {
      this._status = row;
    }
  }

  private updateType(fetchList: FetchListService) {
    let row = fetchList.getRow(ListVehicleType, this.VehicleTypeId);
    if (row != null) {
      this._type = row;
    }
  }

  update(fetchList: FetchListService) {
    this.updateStatus(fetchList);
    this.updateType(fetchList);
  }

  public changed(change: CacheChangedInterface) {
    super.changed(change);
    this.update(change.fetchList);
  }

  rShort(): string {
    return ((this.type ? this.type.Description : '') + ' - ' + this.CallCode).trim();
  }

  rTypeOrCallCode(): string {
    return this.type ? this.type.Description : this.CallCode;
  }

  get icon(): string {
    return this.type ? this.type.icon : Vehicle.globalIcon;
  }
}
