import { MapName } from '@addins/core/core';
import { ViewLoaderService } from '@addins/core/menus';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Coordinate } from '@models/coordinate';
import { ResourcePositionLight } from '@models/imported/SagaBase/ResourcePositionLight';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { LocalizableObjectLayer, Map } from '@techwan/mapping';
import { filter, first, map } from 'rxjs/operators';
import { ObjectFactory } from '../../object-factory/object-factory.service';
import { Security } from '../../security/security.service';
import { AppSettingsService, SagaSettingsService } from '../../settings';
import { StaticMapOption, coordToPosition, getStaticMapUrl, transform, transformOL } from '../helpers';
import { MapInstancesService } from '../map-instances/map-instances.service';
import { MapInstanceEvent, MapInstanceEventType } from '../schema/interfaces/map-instance-event';

export let MoreItemsType = {
  Checkbox: 'checkbox',
  Line: '',
  Component: 'component'
};

export interface MoreItem {
  title?: string;
  handler?: Function;
  icon?: string;
  context?: any;
  keepOnClick?: boolean;
  checked?: boolean;
  type?: string;
  component?: any;
  hidden?: boolean;
}

export interface ButtonItem {
  handler?: Function;
  icon?: string;
  context?: any;
  color?: any;
  hidden?: boolean;
}

export interface ItineraryOption {
  viability?: boolean;
  projection?: string;
  territoryCode?: number;
}

export interface ILayerData {
  layers: string;
  name: string;
}

export interface EpsgProjection {
  str: string;
  code: number;
}

/**
 * @description
 * Service for managing the mapping system
 */
@Injectable()
export class MapService {
  private _myLocation: LocalizableObjectLayer;
  positionOptions = { maximumAge: 60000, timeout: 120000, enableHighAccuracy: true };

  moveToPosition: boolean = false;

  get map(): Map {
    return this.map_;
  }

  /**
   * @deprecated PLease consider using MyLocationService instead
   */
  get currentGeoposition(): Geoposition {
    return this.lastPosition;
  }

  /**
   * TODO: Move this to a specific service because "more items" is only used in specific map's views, not all (#32025).
   */
  get moreItems() {
    return this._moreItems;
  }

  get buttonItems() {
    return this._buttonItems;
  }
  private onNewPosition: EventEmitter<Geoposition>;
  private lastPosition: Geoposition;

  private map_: Map;

  private _moreItems: MoreItem[] = [];
  private _buttonItems: ButtonItem[] = [];

  private itemsLayer: LocalizableObjectLayer;

  constructor(
    private security: Security,
    private objectFactory: ObjectFactory,
    private settings: AppSettingsService,
    private appSettings: SagaSettingsService,
    private maps: MapInstancesService,
    private viewLoader: ViewLoaderService,
    private viewSelector: ViewSelectorService
  ) {
    this.maps.$change.pipe(filter(e => e.name === MapName.main)).subscribe(e => this.$onMapChanged(e));
    this.onNewPosition = new EventEmitter<Geoposition>();

    if (!environment.production) {
      this.settings.addSettingsEntry(
        {
          type: 'checkbox',
          text: 'Bing',
          get value() {
            return !!localStorage.getItem('bing-layer');
          },
          set value(bing: boolean) {
            bing ? localStorage.setItem('bing-layer', 'true') : localStorage.removeItem('bing');
          }
        },
        'Saga.Map'
      );
    }
  }

  private $onMapChanged(e: MapInstanceEvent): void {
    this.setMap(e.type === MapInstanceEventType.removed ? null : e.target);
  }

  private setMap(map: Map): void {
    this.map_ = map;
    if (map) {
      this._myLocation = map.createLocalizableObjectLayer();
      this.itemsLayer = map.createLocalizableObjectLayer({
        cluster: true,
        updateWhileInteracting: false,
        updateWhileAnimating: false
      });
      this.itemsLayer.setZIndex(1);
      this.itemsLayer.setExplodeMode(0);
      this.itemsLayer.setExplodeMaxResolution(Infinity);
    } else {
      this.itemsLayer = null;
      this._myLocation = null;
    }
  }

  showMap(mapName: string = MapName.main): Promise<Map> {
    let returnPromise;

    const sagaMap = this.maps.get(mapName);

    if (sagaMap === null) {
      let mapAddedResolve: any = null;
      returnPromise = new Promise<Map>(resolve => (mapAddedResolve = resolve));

      this.maps.$change
        .pipe(
          filter(
            (mapInstanceEvent: MapInstanceEvent) =>
              mapInstanceEvent.name === mapName && mapInstanceEvent.type === MapInstanceEventType.added
          ),
          map((mapInstanceEvent: MapInstanceEvent) => mapInstanceEvent.target),
          first()
        )
        .subscribe(map => mapAddedResolve(map));

      this.viewLoader.show(this.viewSelector.getMap().element);
    } else {
      returnPromise = Promise.resolve(sagaMap);
    }

    return returnPromise;
  }

  static coordToPosition(coordinate: Coordinate): ol.Coordinate {
    return coordToPosition(coordinate);
  }

  static getStaticMapUrl(coordinate: Coordinate, options: StaticMapOption) {
    return getStaticMapUrl(coordinate, options);
  }

  addMoreItems(item: MoreItem): () => void {
    this.moreItems.push(item);
    return () => {
      const i = this.moreItems.indexOf(item);
      if (i !== -1) {
        this.moreItems.splice(i, 1);
      }
    };
  }

  addButtonItems(item: ButtonItem): () => void {
    this.buttonItems.push(item);
    return () => {
      const i = this.buttonItems.indexOf(item);
      if (i !== -1) {
        this.buttonItems.splice(i, 1);
      }
    };
  }

  private transformOL(position: ol.Coordinate, fromProjection: any, toProjection: any): ol.Coordinate {
    return transformOL(position, fromProjection, toProjection);
  }

  transform(coordinate: Coordinate, epsg: string): Coordinate {
    return transform(coordinate, epsg);
  }

  async getResourceProximity(position: Coordinate, radius: number): Promise<Array<ResourcePositionLight>> {
    try {
      return await this.security
        .post<Array<ResourcePositionLight>>('api/UnitActivity/GetRessourceByProximity', {
          y: position.y,
          x: position.x,
          radius
        })
        .then(result => this.objectFactory.createList(result || [], ResourcePositionLight) as Array<ResourcePositionLight>);
    } catch (ex) {
      console.error(ex);
    }
    return null;
  }

  convertToView(position: ol.Coordinate, fromProjection: string = this.getSystemEpsg().str): ol.Coordinate {
    return this.transformOL(position, fromProjection, this.map_.getView().getProjection());
  }

  getEpsgProj(value: number | string): string {
    return (value === null && this.getSystemEpsg().str) || (('' + value).toUpperCase().indexOf('EPSG') > -1 ? '' : 'EPSG:') + value;
  }

  getSystemEpsg(): EpsgProjection {
    const epsgStr = this.appSettings.getValue('System.GeoCoordinates');
    const epsgCode = epsgStr !== null && parseInt(/\d+/.exec(epsgStr)[0], 10);
    return {
      str: epsgStr,
      code: epsgCode
    };
  }
}
