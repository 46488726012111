import { CustomComponent, ModalButton } from '@addins/core/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { List } from '@models/list';
import { CacheService } from '@services/cache/cache.service';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent implements OnInit, OnDestroy, CustomComponent<void> {
  readonly cardId: string;
  readonly useCustomData: boolean = false;
  title: string;

  private attachmentsList: List<CAttachment>;
  get attachments(): CAttachment[] {
    return this.attachmentsList.items;
  }

  private filterRemover: Function = null;

  constructor(private cache: CacheService) {}

  ngOnInit() {
    this.attachmentsList = this.cache.getListOf(CAttachment);
    this.filterRemover = this.attachmentsList.addFilter(attachment => attachment.CallCardId == this.cardId);
  }

  ngOnDestroy() {
    this.filterRemover();
    this.cache.unregisterListOf(CAttachment, this.attachmentsList);
    this.attachmentsList = null;
  }

  getResult(button: ModalButton): void {}
}
