<div class="cache-loader" *ngIf="callcard.externalSearchs.Far && callcard.externalSearchs.Far.isSearching">
    <ion-spinner></ion-spinner>
    <div class="cache-loader-label" translate>SagaMobile.Sip.Searching</div>
</div>
<ion-list class="sip-list" *ngIf="callcard.externalSearchs.Far && callcard.externalSearchs.Far.results">
    <ion-item class="sip-entry" style="white-space: normal" *ngFor="let result of callcard.externalSearchs.Far.results; let i = index" (click)="openResult(result)">
        <ion-icon name="document" slot="start" [ngClass]="{'flashing': result.isAuthority}"></ion-icon>
        <ion-label>
            <div *ngFor="let entryName of result.entriesName"><b>{{entryName}}:</b>&nbsp;{{result.entries[entryName]}}</div>
        </ion-label>
    </ion-item>
</ion-list>
