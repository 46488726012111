import { CommonAddinModule } from '@addins/common.module';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../modules/shared/shared.module';
import { CoreModule } from './core';
import { FileModule } from './file';
import { HomeModule } from './home';
import { LoginModule } from './login';
import { MapModule } from './map';
import { MenusModule } from './menus';
import { PanelModule } from './panel';
import { SettingsModule } from './settings';
import { SingleCallCardModule } from './singlecallcard';
import { StatusModule } from './status';
import { UnitActivityModule } from './unit-activity';

const components = [];

const providers = [];

/**
 * This addin is a special one with all core dependencies. It will auto load
 */

@NgModule({  
  declarations: [...components],
  providers,
  imports: [
    CommonModule,
    CoreModule,
    FileModule,
    HomeModule,
    StatusModule,
    MapModule,
    LoginModule,
    SingleCallCardModule,
    PanelModule,
    MenusModule,
    UnitActivityModule,
    SharedModule,
    IonicModule,
    SettingsModule,
    CommonAddinModule
  ],
  exports: [
    CoreModule,
    FileModule,
    StatusModule,
    MapModule,
    LoginModule,
    SingleCallCardModule,
    PanelModule,
    MenusModule,
    UnitActivityModule,
    ...components
  ]
})
@Addin({
  name: 'Core',
  require: ['Home', 'Status', 'AppLogin', 'Map', 'singlecallcard', 'UnitActivity', 'Settings', 'File'],
  autoload: true
})
export class CoreAddinModule extends AddinModule {
  constructor(public readonly injector: Injector) {
    super(injector, components, providers);
  }
}
