import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalizationService } from '@services/globalization/globalization.service';
import { List } from '@models/list';
import { TranslationItem } from '../../schema/TranslationItem';
import { ModalController } from '@ionic/angular';

interface Translation {
  key: string;
  value: string;
}

@Component({
  styleUrls: ['translation-list.component.scss'],
  templateUrl: 'translation-list.component.html'
})
export class TranslationListComponent {
  private readonly _translations: List<Translation> = new List<Translation>();
  get translations(): Translation[] {
    return this._translations.items;
  }

  showOnlyNull: boolean = true;
  lang: string;
  search: string;

  constructor(private globalization: GlobalizationService, private translate: TranslateService, readonly modalCtrl: ModalController) {
    this.translate.onTranslationChange.subscribe(() => {
      this.updateList();
    });

    this.translate.onLangChange.subscribe(() => {
      this.updateList();
    });

    this._translations.addFilter(translation => typeof translation.value !== 'object' || (this.showOnlyNull && translation.value === null));
    this._translations.addFilter(translation => !this.showOnlyNull || translation.value === null);
    this._translations.addFilter(
      translation =>
        !this.search ||
        translation.key.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
        (translation.value && translation.value.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    );
  }

  onSearch(event?) {
    this.search = (event && event.target.value) || this.search;
    this._translations.refresh();
  }

  async translateItem(item: TranslationItem) {
    await this.globalization.showTranslateModal(item.key);
    this.updateList();
  }

  updateList() {
    this.lang = this.translate.currentLang;
    const translations = this.globalization.getMergedTranslations();
    const keys = Object.keys(translations);
    this._translations.source = keys.map(key => new TranslationItem(key, translations[key]));
  }

  async ionViewWillEnter() {
    this.updateList();
  }

  download() {
    this.globalization.exportCSV();
  }
}
