import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings/settings.service';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Guid } from '@models/guid';
import { ILocationProxy } from '../../../schema/interfaces/location-proxy';
import { LocationProxyEvent } from '../../../schema/enum/location-proxy-event';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class PositionProxyService implements ILocationProxy {
  protected gpsStatusSetting: boolean = true;
  protected _interval: any = null;
  protected lastPositionSend: number = null;
  protected sendPositionTimeout: number = null;
  protected lastPosition: Geoposition = null;

  protected _resourceUid: Guid = null;

  protected _lastError: any = null;

  protected readonly _state = new BehaviorSubject<LocationProxyEvent>(null);
  public get $event(): Observable<LocationProxyEvent> {
    return this._state.asObservable();
  }

  protected readonly _isRunning = new BehaviorSubject(false);
  public get $state(): Observable<boolean> {
    return this._isRunning.asObservable();
  }

  public get isRunning(): boolean {
    let v: boolean;
    this._isRunning.subscribe(val => (v = val)).unsubscribe();
    return v;
  }

  protected readonly _epsg = 4326;
  protected readonly _empty: Coordinate = { x: 0, y: 0, epsg: `EPSG:${this._epsg}` };
  public get empty(): Coordinate {
    return this._empty;
  }

  constructor(private readonly _settings: SettingsService, private readonly http: HttpClient) {}

  public init(uid: Guid): void {
    this.sendPositionTimeout = this._settings.getPositionInterval();
    this.gpsStatusSetting = this._settings.getGpsStatus();
    this._resourceUid = uid;
  }

  /**
   * Flag the service as started if it is allowed to be started.
   */
  public start(): void {
    if (this.gpsStatusSetting && this._interval === null) {
      this._isRunning.next(true);
    }

    // If the position is known along with the resource id, we start the timer.
    if (this.gpsStatusSetting && this._resourceUid !== null && this.lastPosition !== null) {
      this.updatePosition();
    }
  }

  public stop(): void {
    clearTimeout(this._interval);
    this._interval = null;
    this._isRunning.next(false);
  }

  public update(position: Geoposition) {
    this.lastPosition = position;
    if (position && position !== this.lastPosition && this.isRunning) {
      this.updatePosition();
    }
  }

  private error(err: any, caught: Observable<string>): Observable<string> {
    this._lastError = err;
    this._state.next(LocationProxyEvent.error);
    return of(null);
  }

  private setResourcePosition(position: Coordinate) {
    this._state.next(LocationProxyEvent.sending);
    return this.http
      .post('api/Location/ForVehicle', { resourceUid: this._resourceUid, coordX: position.x, coordY: position.y }, { responseType: 'text' })
      .pipe(
        tap(() => this._state.next(LocationProxyEvent.idle)),
        catchError((err: any, caught: Observable<string>) => this.error(err, caught))
      );
  }

  private transform(p: Geoposition = null): Coordinate {
    return p !== null ? { x: p.coords.longitude, y: p.coords.latitude, epsg: this.empty.epsg } : this.empty;
  }

  private updatePosition() {
    if (this._interval !== null) {
      return;
    }
    const p = this.transform(this.lastPosition);
    const isValid = p && p.x !== 0 && p.y !== 0;

    // Send the position if it is valid and more recent than the last sent position.
    if (isValid && this.lastPositionSend !== this.lastPosition.timestamp) {
      this.setResourcePosition(p).subscribe(() => (this.lastPositionSend = this.lastPosition.timestamp));
    }

    // Starts the timer.
    if (isValid) {
      this._interval = setTimeout(
        () => {
          this._interval = null;
          this.updatePosition();
        },
        this.sendPositionTimeout !== null ? this.sendPositionTimeout * 1000 : 0
      );
    }
  }
}
