// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @18/04/2023 14:55:52
import { Guid, GuidTools } from '@models/guid';
import { SagaObject } from '../../SagaBase/SagaObject';

export abstract class CFormLink_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CFormLink';
  $t: string = 'SagaSchema.CFormLink';

  public static GetMembers(): string[] {
    return ['CallCardId', 'FormValueUid', 'FormName', 'FormIdentifier'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CFormLink_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public FormValueUid: Guid = GuidTools.Empty;
  public FormName: string;
  public FormIdentifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
