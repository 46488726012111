import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// regexp to match translation keys between curly brackets
const TRANSLATE_KEY_REGEXP: RegExp = /\{(.*?)\}/g;

@Pipe({
  name: 'translateKeys'
})
export class TranslateKeysPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    const matches: string[] = value && value.match(TRANSLATE_KEY_REGEXP);
    let translated: string = value;

    if (matches) {
      matches.forEach(match => {
        const key: string = match.substring(1, match.length - 1); // remove curly brackets
        translated = translated.replace(match, this.translate.instant(key));
      });
    }

    return translated;
  }
}
