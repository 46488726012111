import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { IdToTypeConverter } from '@models/imported/createInstanceById';
import { registerModelFactory } from '../model-factory.service';

export function ModelService(type: typeof SagaObject) {
  return target => {
    IdToTypeConverter.setCustomConstructor(type);
    registerModelFactory(target, type);
  };
}
