// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ResStatus_Phone_abstract extends BaseObject {
  static $t: string = 'SagaBase.Objects.ResStatus_Phone';
  $t: string = 'SagaBase.Objects.ResStatus_Phone';

  public static GetMembers(): string[] {
    return [
      'resStatusConst',
      'Name',
      'ParentValue',
      'Children',
      'ForceAvailability',
      'NAction',
      'FGColor',
      'BGColor',
      'OverrideBGColor',
      'OBGColor',
      'Timeout',
      'TimeoutMessage',
      'ImageKey',
      'AcceptRequest',
      'NegateRequest',
      'UiKey',
      'DeviceType'
    ];
  }

  public GetMembers(): string[] {
    return ResStatus_Phone_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public resStatusConst: number = 0;
  public Name: string;
  public ParentValue: number = 0;
  public Children: number = 0;
  public ForceAvailability: number = 0;
  public NAction: number = 0;
  public FGColor: string;
  public BGColor: string;
  public OverrideBGColor: boolean = false;
  public OBGColor: string;
  public Timeout: number = 0;
  public TimeoutMessage: string;
  public ImageKey: string;
  public AcceptRequest: number = 0;
  public NegateRequest: number = 0;
  public UiKey: string;
  public DeviceType: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
