import { ListSettingValue } from '@addins/core/settings/schema/list-setting-value';
import { LocationSettings } from '@addins/core/settings/schema/location-settings';
import { SettingValue } from '@addins/core/settings/schema/setting-value';
import { Injectable } from '@angular/core';
import { SagaSettingsService } from '@services/settings';

@Injectable()
export class LocationSettingsManagerService {
  private _locationSettings: LocationSettings = null;
  get settings(): LocationSettings {
    if (this._locationSettings === null) {
      this.setup();
    }
    return this._locationSettings;
  }

  constructor(private sagaSettings: SagaSettingsService) {}

  private setup() {
    const _serverUpdateSettings = this.readSettings('GPSServerUpdatePeriods');
    const _distanceUpdateFilter = this.readSettings('GPSDistanceFilters');
    this._locationSettings = new LocationSettings(
      this.createGpsFilterSetting('GPSDistanceFilter', _distanceUpdateFilter),
      this.createGpsFilterSetting('GPSServerUpdate', _serverUpdateSettings)
    );
  }

  private createGpsFilterSetting(name: string, values: number[]): ListSettingValue {
    const val = parseInt(this.sagaSettings.getValue(`SagaMobileWebClient.${name}`), 10);
    const selected = new SettingValue(`${val}`, val);
    const list = new ListSettingValue(
      name,
      values.map(i => (i === val ? selected : new SettingValue(`${i}`, i)))
    );
    if (list.values.indexOf(selected) === -1) {
      list.values.unshift(selected);
    }
    list.select(selected);
    return list;
  }

  private readSettings(name: string) {
    return this.sagaSettings
      .getValue(`SagaMobileWebClient.${name}`)
      .split(',')
      .map(i => parseInt(i, 10));
  }
}
