import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeSetupService } from './services/executables/qr-code-setup/qr-code-setup.service';
import { QRCodeViewComponent } from './views/qr-code-view/qr-code-view.component';

const views = [QRCodeViewComponent];

const components = [...views];

const executables = [QRCodeSetupService];

const providers = [...executables];

@Addin({
  name: 'QuickResponseCode'
})
@NgModule({
  declarations: [...components],
  entryComponents: components,
  providers,
  imports: [CommonModule, IonicModule, TranslateModule, SagaDirectivesModule, SagaPipeModule, QRCodeModule]
})
export class QuickResponseCodeModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
