import { Component, ElementRef, OnInit } from '@angular/core';
import { PopupBaseComponent, PopupProviderService } from '@addins/core/map';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';

@Component({
  selector: 'app-callcard-popup',
  templateUrl: './callcard-popup.component.html',
  styleUrls: ['./callcard-popup.component.scss']
})
export class CallcardPopupComponent extends PopupBaseComponent implements OnInit {
  public card?: CallCard = null;

  constructor(_popup: PopupProviderService, _e: ElementRef, private readonly _cache: CacheService) {
    super(_popup, _e);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.card = this._cache.getObjectByUid(this._popup.content[0].feature.get('id')) as CallCard;
  }

  protected refresh() {}
}
