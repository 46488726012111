<ng-container *ngFor="let item of items">
  <ion-button
    *ngIf="isPrimary || item.onlyPrimary === undefined || !item.onlyPrimary"
    slot="icon-only"
    [ngStyle]="item.style"
    [style.display]="item.hidden ? 'none' : ''"
    (click)="itemClicked($event, item)"
  >
    <ion-icon [name]="item.iconName"></ion-icon>
  </ion-button>
</ng-container>
