// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class BroadcastMessageArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.BroadcastMessageArgs';
  $t: string = 'SagaBase.BroadcastMessageArgs';

  public static GetMembers(): string[] {
    return ['ClientSessionGuid', 'Reason', 'Msg', 'Data', 'SiteOnlyBroadcast'];
  }

  public GetMembers(): string[] {
    return BroadcastMessageArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ClientSessionGuid: Guid = GuidTools.Empty;
  public Reason: SagaBase.BroadcastMessageReason = SagaBase.BroadcastMessageReason.None;
  public Msg: string;
  public Data: any;
  public SiteOnlyBroadcast: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
