import { Injectable, Injector, Provider, Type } from '@angular/core';
import { BaseObject } from '@models/BaseObject';
import { IModelService } from './schema/interfaces/model-service';
import { IObjectChange } from './schema/interfaces/object-change';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { IdToTypeConverter } from '@models/imported/createInstanceById';

const __services: Map<Type<BaseObject>, Type<IModelService>[]> = new Map();

function getServices(object: Type<BaseObject>): Type<IModelService>[] {
  return __services.has(object) ? __services.get(object) : [];
}

export function registerModelFactory(service: Type<IModelService>, object: Type<BaseObject>) {
  const storage = __services.has(object) ? __services.get(object) : [];
  if (!__services.has(object)) {
    __services.set(object, storage);
  }
  storage.push(service);
}

@Injectable()
export class ModelFactoryService {
  constructor(private readonly _injector: Injector) {}

  public registerModelWithFactory(object: typeof SagaObject, provider: Type<IModelService>) {
    IdToTypeConverter.setCustomConstructor(object);
    registerModelFactory(provider, object);
  }

  public hasService(type: SagaObject): boolean {
    return (
      type
        .getObjectPath()
        .map(t => getServices(t))
        .reduce((a, b) => a.concat(b)).length > 0
    );
  }

  public callChanged(change: IObjectChange) {
    change.target.getObjectPath().forEach(type =>
      getServices(type).forEach(serviceFactory => {
        const service = this._injector.get(serviceFactory);
        if (service) {
          service.change({ action: change.action, target: change.target });
        }
      })
    );
  }
}
