import { Component } from '@angular/core';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { SagaFormsUrlProviderService } from '../../services/saga-forms-url-provider/saga-forms-url-provider.service';

@Component({
  selector: 'app-saga-forms-view',
  templateUrl: './saga-forms-view.component.html',
  styleUrls: ['./saga-forms-view.component.scss']
})
export class SagaFormsViewComponent {
  constructor(private sagaFormsUrlProviderService: SagaFormsUrlProviderService, private windowOpener: WindowOpenerService) {}

  onOpenForms() {
    const url: string = this.sagaFormsUrlProviderService.formsBaseUrl;
    this.windowOpener.system(url);
  }
}
