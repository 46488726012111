// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { EventText } from '../../SagaSchema/EventText';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class EventText2_abstract extends EventText {
  static $t: string = 'SagaSchema.EventText2';
  $t: string = 'SagaSchema.EventText2';

  public static GetMembers(): string[] {
    return ['EvtText2Id'].concat(EventText.GetMembers());
  }

  public GetMembers(): string[] {
    return EventText2_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public EvtText2Id: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.EvtText2Id;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
