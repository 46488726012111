import { CurrentCardService } from '@addins/core/core';
import { MyLocationService } from '@addins/core/map';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Coordinate } from '@models/coordinate';
import { CreateEventResultEnum, StatusChangeResult } from '@models/imported/SagaBase';
import { Area } from '@models/imported/SagaBase/Area';
import { User } from '@models/imported/SagaBase/User';
import { Zone } from '@models/imported/SagaBase/Zone';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CCaller } from '@models/imported/SagaSchema/CCaller';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { TranslateService } from '@ngx-translate/core';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { CacheService } from '@services/cache/cache.service';
import { EventService, ILocationResponse } from '@services/event/event';
import { MapService } from '@services/map';
import { ModalService } from '@services/modal/modal.service';
import { NavService } from '@services/navigation/navigation.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { SectorService } from '@services/sector/sector.service';
import { Security } from '@services/security/security.service';
import { AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { NoteEditorComponent } from '../../components/note-editor/note-editor.component';
import { EventsProviderService } from '../../services/events-provider/events-provider.service';

/**
 * @description
 * The callcard creation component
 */
@Component({
  templateUrl: 'callcard-create.component.html',
  selector: 'callcard-create'
})
@AddinView({
  name: 'CardCreate',
  description: 'Use to create a new callcard'
})
export class CallcardCreateComponent implements OnInit {
  user: User;
  notes: string;
  caller: CCaller;
  location: CLocation = null;
  zone: Zone = null;

  currentEventText: string;

  private waitingForNewCallcardTimeoutInSec = 10;
  private waitingForNewCallcardTimeout;

  selectedEventId: number;

  constructor(
    private translate: TranslateService,
    private security: Security,
    private modal: ModalService,
    private sectorService: SectorService,
    private alertCtrl: AlertController,
    private nav: NavService,
    private cache: CacheService,
    private objectFactory: ObjectFactory,
    private map: MapService,
    private eventsService: EventService,
    private modalCtrl: ModalController,
    private association: AssociationSecurity,
    private readonly myLocation: MyLocationService,
    private readonly _provider: EventsProviderService,
    private readonly _card: CurrentCardService
  ) {
    this.modal.listenToModalView(/* this.viewCtrl as ViewController */);

    this.getLocationAddress();
    this.loadMobEvents();
  }

  get unitActivity(): UnitActivity {
    return this.security.getData('UnitActivity');
  }

  ngOnInit() {
    this.user = this.security.loggedUser as User;
  }

  translateInstant(key: string): string {
    return this.translate.instant(key);
  }

  private clearAll() {
    this.caller = null;
    this.location = null;
  }

  private async setUserArea() {
    this.clearAll();
    this.caller = this.objectFactory.create(CCaller);
    this.zone = this.objectFactory.create(Zone) as Zone;
    return await this.sectorService.getUserAreaZone().then((userArea: Area) => {
      if (userArea !== null) {
        this.zone.Id = userArea.Id;
        this.zone.Name = userArea.ZoneName;
      }
      return userArea;
    });
  }

  async getLocationAddress() {
    await this.modal.presentLoading('SagaMobile.CallCardCreation.GetLocationAddress');

    let position: Coordinate;

    try {
      position = await this.myLocation.getCurrent().toPromise();
    } catch (err) {
      console.error(err);
      this.setUserArea();
      return this.modal.dismissLoading();
    }

    try {
      const result = await this.security.post<ILocationResponse | boolean>('api/Event/GetLocationAddress', {
        y: position.y,
        x: position.x,
        epsg: position.epsg
      });

      this.location = this.objectFactory.create(CLocation);

      if (result !== false) {
        this.caller = this.objectFactory.create((result as ILocationResponse).caller);
        this.zone = this.objectFactory.create((result as ILocationResponse).zone);

        this.location.CenterX = this.caller.MapX;
        this.location.CenterY = this.caller.MapY;
        this.location.PlaceName = this.caller.PlaceName;
        this.location.CityName = this.caller.CityName;
        this.location.Zip = this.caller.Zip;
        this.location.StreetNumber = this.caller.StreetNumber;
        this.location.Normalized = this.caller.Normalized;
        this.location.GeoSectorId = this.caller.GeoSectorId;
        this.location.CoordEpsgCode = this.caller.CoordEpsgCode;
      } else {
        await this.setUserArea();
        this.caller.MapX = position.x;
        this.caller.MapY = position.y;
        this.caller.CoordEpsgCode = position.epsg;
      }

      const device: CommunicationEquipment = this.association.getDevice();

      this.caller.Phone = device.PhoneNumber;
    } catch (err) {
      console.log(err);
      this.setUserArea();
      this.caller.MapX = position.x;
      this.caller.MapY = position.y;
      this.caller.CoordEpsgCode = position.epsg;
    }

    await this.modal.dismissLoading();
  }

  public createCard(): Promise<any> {
    return this.unitActivity.callcard ? this.askToDisengage() : this.execCreateCard(false);
  }

  private askToDisengage(): Promise<any> {
    const trParam = {
      event: this.unitActivity.callcard.event.text,
      location: this.unitActivity.callcard.location.FormattedLocation
    };

    return this.alertCtrl
      .create({
        header: this.translate.instant('SagaMobile.Callcard.ConfirmDisengage.Title', trParam),
        message: this.translate.instant('SagaMobile.Callcard.ConfirmDisengage.Text', trParam),
        buttons: [
          {
            text: this.translate.instant('SagaMobile.Callcard.ConfirmDisengage.Cancel'),
            handler: () => {}
          },
          {
            text: this.translate.instant('SagaMobile.Callcard.ConfirmDisengage.Disengage'),
            handler: () => this.execCreateCard(true)
          }
        ]
      })
      .then(m => m.present());
  }

  private openNewCard(callcard) {
    this.modal.dismissLoading();
    this.nav.get().popToRoot();
    this._card.select(callcard);
  }

  private execCreateCard(disengage: boolean): Promise<any> {
    return this.modal.presentLoading('SagaMobile.CallCardCreation.Creating').then(() => {
      // Let API side to generate a new guid.
      delete this.caller.ObjGuid;
      const unitActivity = this.unitActivity;
      this._provider
        .createCard({
          eventId: this.selectedEventId,
          unitActivityUid: unitActivity.ObjGuid,
          resourceStatus: 16,
          intermediateStatus: [4, 8],
          notes: this.notes,
          caller: this.caller,
          zone: this.zone,
          disengage
        })
        .subscribe(res => this.parse(res));
    });
  }

  private parse(result): Promise<void> {
    if (result.createEventResult === CreateEventResultEnum.OK && result.statusChangeResult === StatusChangeResult.OK) {
      const cachedCallcard = this.cache.getObjectByUid(result.createdCallcardUid);
      if (cachedCallcard) {
        this.openNewCard(cachedCallcard);
      } else {
        let cacheSub: Subscription = this.cache.listenForChange(CallCard).subscribe(event => {
          const newCallCard: CallCard = event.object as CallCard;
          if (newCallCard.ObjGuid === result.createdCallcardUid) {
            cacheSub.unsubscribe();
            if (this.waitingForNewCallcardTimeout) {
              clearTimeout(this.waitingForNewCallcardTimeout);
              this.waitingForNewCallcardTimeout = null;
            }
            this.openNewCard(newCallCard);
          }
        });

        this.waitingForNewCallcardTimeout = setTimeout(() => {
          this.modal.dismissLoading();
          this.nav.get().popToRoot();
          this.waitingForNewCallcardTimeout = null;

          cacheSub.unsubscribe();
          cacheSub = null;
        }, this.waitingForNewCallcardTimeoutInSec * 1000);
      }
    } else {
      return this.modal.dismissLoading().then(() => {
        if (result.createEventResult === CreateEventResultEnum.AlreadyEngaged) {
          return this.askToDisengage();
        }
      });
    }
  }

  loadMobEvents() {
    // Auto-select the event item if there is only one item
    if (this.eventsService.events.length == 1) {
      this.setEvent(this.eventsService.events[0]);
    }
  }

  canSave(): boolean {
    return this.selectedEventId > 0 && this.caller != null && this.zone != null;
  }

  get events() {
    return this.eventsService.events;
  }

  async selectEvent() {
    try {
      return await this.eventsService.pickEvent(this.selectedEventId).then(selectEventId => {
        if (selectEventId) {
          for (let i = 0; i < this.eventsService.events.length; i++) {
            if (selectEventId === this.eventsService.events[i].EvtEventId) {
              this.setEvent(this.eventsService.events[i]);
            }
          }
        }
      });
    } catch (e) {
      // No event selected
    }
  }

  async writeNotes(maxLength: number = 4000) {
    return this.modalCtrl
      .create({
        component: NoteEditorComponent,
        componentProps: { text: this.notes, maxlength: maxLength }
      })
      .then(m => {
        m.onDidDismiss().then(res => {
          const data = res.data;
          if (data !== undefined) {
            this.notes = data;
          }
        });
        m.present();
      });
  }

  private setEvent(event: MobEvents) {
    this.selectedEventId = event.EvtEventId;
    this.currentEventText = event.EvtText;
  }
}
