<ion-item (click)="onClicked()">
  <ion-icon slot="start" [icon]="mediaTypeIcon" size="large"></ion-icon>
  <ion-label class="ion-text-wrap">
    <h3 *ngIf="attachment.Text">
      <strong>{{ attachment.Text | translateKeys }}</strong>
    </h3>
    <h3>
      {{ attachment.Title }}
    </h3>
  </ion-label>
</ion-item>
