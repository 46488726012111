import { CardTitleFormatterService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ResourceCountService } from '../../services/resource-count/resource-count.service';

@Component({
  selector: 'app-disengagement-setup-modal',
  templateUrl: './disengagement-setup-modal.component.html',
  styleUrls: ['./disengagement-setup-modal.component.scss']
})
export class DisengagementSetupModalComponent implements OnInit {
  @Input()
  callcard: CallCard;

  callcardTitle: string;
  peopleCount: number;

  constructor(
    private modalController: ModalController,
    private titleFormatter: CardTitleFormatterService,
    private resourceCount: ResourceCountService
  ) {}

  ngOnInit() {
    this.callcardTitle = this.titleFormatter.format(this.callcard);
    this.peopleCount = this.resourceCount.getEngagedPeople(this.callcard);
  }

  onSubmit() {
    this.modalController.dismiss({ peopleCount: this.peopleCount });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onPeopleCountClicked() {
    this.resourceCount
      .selectEngagedPeople()
      .then(value => (this.peopleCount = value))
      .catch(err => err);
  }
}
