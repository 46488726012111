import { ListBaseComponent } from '@addins/unit-custom-creation/src/components/list-base-component/list-base-component';
import { Component, OnInit, Output } from '@angular/core';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { SagaSettingsService } from '@services/settings';
import { EventEmitter } from 'events';
import { ListItemComponent } from '../../components/list-item/list-item.component';
import { ResourcesProviderService } from '../../services/resources-provider/resources-provider.service';
import { UnitEditorService } from '../../services/unit-editor/unit-editor.service';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: '../../abstract-template/tab-list.template.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent extends ListBaseComponent<Vehicle> implements OnInit {
  @Output() saveQueriedList = new EventEmitter();
  public readonly item = ListItemComponent;

  constructor(
    private readonly resourcesProvider: ResourcesProviderService,
    protected unitEditor: UnitEditorService,
    protected sagaSettings: SagaSettingsService
  ) {
    super(unitEditor, sagaSettings);
    this.tabType = 'Vehicle';
  }

  public ngOnInit() {
    // Set the initial value of the list.
    this._list = this.unitEditor.editor.vehicles;
    super.ngOnInit();
  }

  public getItems(offset: number, txt: string) {
    return this.resourcesProvider.getVehicles(offset, (this.searchExpression = txt));
  }
}
