import {
  ICallcardButtonEvent,
  ICallcardButtonTool,
  ToolColor,
  ToolExpand,
  ToolFill,
  ToolSize,
  ToolType
} from '@addins/core/singlecallcard';
import { Observable, Subject } from 'rxjs';

export class JournalCardButton implements ICallcardButtonTool {
  private readonly _click = new Subject<ICallcardButtonEvent>();
  get onClick(): Observable<ICallcardButtonEvent> {
    return this._click.asObservable();
  }

  readonly icon: string = 'reader-outline';
  position: number = 900;
  show: boolean = true;
  text: string = 'Mobile.Journal';
  dontTranslate: boolean = false;
  type: ToolType;

  color: ToolColor;
  expand: ToolExpand;
  fill: ToolFill;
  size: ToolSize;

  click(): void {
    this._click.next();
  }
}
