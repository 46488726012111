<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>{{ title }}</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label position="stacked" class="ion-text-right"> {{ text.length }} / {{ textMaxLength }} </ion-label>
      <ion-textarea #textAreaMessage [maxlength]="textMaxLength" [autoGrow]="true" [(ngModel)]="text"> </ion-textarea>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-buttons>
    <ion-fab-button (click)="onDismiss()">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-fab-button>
    <ion-fab-button (click)="onSubmit()" [disabled]="!text">
      <ion-icon name="checkmark"></ion-icon>
    </ion-fab-button>
  </ion-buttons>
</ion-footer>
