// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class RouteArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.RouteArgs';
  $t: string = 'SagaBase.RouteArgs';

  public static GetMembers(): string[] {
    return [
      'PathType',
      'Waypoints',
      'DistanceUnit',
      'RouteMode',
      'RouteOptimazation',
      'TrafficUsage',
      'Culture',
      'ElementId',
      'ProjCode',
      'RoutingRequest'
    ];
  }

  public GetMembers(): string[] {
    return RouteArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public PathType: SagaBase.RoutePathType;
  public Waypoints: any[];
  public DistanceUnit: SagaBase.RouteDistanceUnit;
  public RouteMode: SagaBase.RouteMode;
  public RouteOptimazation: SagaBase.RouteOptimization;
  public TrafficUsage: SagaBase.TrafficUsage;
  public Culture: string;
  public ElementId: Guid = GuidTools.Empty;
  public ProjCode: string;
  public RoutingRequest: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
