import { Inject, Injectable } from '@angular/core';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Guid } from '@models/guid';
import { BackgroundLocation, LocationConfig, LocationState } from '@techwan/ionic-plugin-location';
import { BehaviorSubject, from, fromEvent, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LOCATION_CONFIG, TOKEN_PROVIDER } from '../../../schema/injection-token';
import { LocationConfigurationProvider } from '../../../schema/interfaces/location-configuration-provider';
import { ILocationProvider } from '../../../schema/interfaces/location-provider';
import { ILocationProxy } from '../../../schema/interfaces/location-proxy';
import { TokenProvider } from '../../../schema/interfaces/token-provider';

export interface NativeLocation {
  timestamp: string;
  velocity: number;
  altitudeAccuracy: number;
  accuracy: number;
  heading: string;
  altitude: number;
  latitude: number;
  longitude: number;
}

@Injectable()
export class BackgroundLocationService implements ILocationProvider {
  private _uid: Guid;

  private _subs: Subscription[] = [];

  private _current = new BehaviorSubject<Geoposition>(null);
  get $change(): Observable<Geoposition> {
    return this._current.asObservable();
  }
  constructor(
    private _geolocation: Geolocation,
    @Inject(TOKEN_PROVIDER)
    private _token: TokenProvider,
    @Inject(LOCATION_CONFIG)
    private _config: LocationConfigurationProvider
  ) {}

  /**
   * This is used on a mobile device when the application is ran on a browser
   */
  getCurrent(): Observable<Geoposition> {
    return from(this._geolocation.getCurrentPosition({ timeout: 40000, enableHighAccuracy: true }));
  }

  getProxy(): ILocationProxy {
    return null;
  }

  setup(uid: Guid) {
    this._uid = uid;
  }

  start(): Observable<any> {
    this.init();
    return from(this.configure().then(() => BackgroundLocation.start({ id: this._uid })));
  }

  private init() {
    this._subs.push(this._token.$change.subscribe(token => BackgroundLocation.setToken({ token })));
    this._subs.push(
      fromEvent(document, 'newLocation')
        .pipe(map((event: any) => event.data))
        .subscribe(event => this._current.next(event))
    );
    this._subs.push(this._config.$change.subscribe(config => this.configure(config)));
  }

  private configure(config?: LocationConfig): Promise<any> {
    return BackgroundLocation.configure(config ? config : this._config.getCurrent());
  }

  state(): Observable<LocationState> {
    return from(BackgroundLocation.state()).pipe(map((state: LocationState) => state));
  }

  stop(): Observable<any> {
    while (this._subs.length !== 0) {
      this._subs.pop().unsubscribe();
    }
    return from(BackgroundLocation.stop());
  }
}
