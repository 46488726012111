import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { ResourcePosition } from '@models/imported/SagaSchema/ResourcePosition';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { StatusService } from '@services/status/status.service';
import { Guid } from '@techwan/ionic-core/lib/schema/Guid';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UnitActivityOnMap } from '../../schema/unit-activity-on-map';

export interface UnitResources {
  unit: UnitActivity;
  resources: SagaObject[];
}

export interface MobilePositionResponse {
  positions: ResourcePosition[];
  units: UnitActivity[];
  countByUnit: { [key: string]: number };
  icons: { [key: string]: string };
}

@Injectable({
  providedIn: 'root'
})
export class UnitResourceProxyService {
  constructor(private readonly _http: HttpClient, private readonly factory: ObjectFactory, private readonly _status: StatusService) {}

  public getResourcesForUnit(unitActivityUid: Guid): Observable<UnitResources> {
    // TODO : Create a function that will transform the resources in a Dict instead of List (Guid as key)
    return this._http
      .get<UnitResources>(`api/UnitActivity/ResourceForUnit?unitUid=${unitActivityUid}`)
      .pipe(tap(r => (r.resources = r.resources !== null ? this.factory.createList(Object.values(r.resources)) : [])));
  }

  public getMobilePositions(): Observable<UnitActivityOnMap[]> {
    return this._http.get<MobilePositionResponse>('api/UnitActivity/MobilePositions').pipe(map(r => this.parse(r)));
  }

  private parse(r: MobilePositionResponse): UnitActivityOnMap[] {
    const list = this._status.getAllResourceStatus();
    return r.units.map(u => {
      const unit = this.factory.create(u, UnitActivity) as UnitActivity;
      unit.status = list.find(i => unit.Status === i.Value);
      return new UnitActivityOnMap(
        unit,
        r.positions.find(i => i.ResourceGuid === u.PositionProviderUId),
        r.countByUnit[u.ObjGuid],
        r.icons[u.PositionProviderUId]
      );
    });
  }
}
