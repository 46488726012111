// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { IdToTypeConverter } from '@models/imported/createInstanceById';

export abstract class Actor_abstract extends SagaObject {
  static $t: string = 'SagaSchema.Actor';
  $t: string = 'SagaSchema.Actor';

  public static GetMembers(): string[] {
    return [
      'Name',
      'LastName',
      'FirstName',
      'VehicleUID',
      'Radio',
      'Info',
      'Attributes',
      'Comment',
      'Service',
      'X',
      'Y',
      'Status',
      'ActivityUid',
      'ActivityStatus',
      'ActivityCounter',
      'CallCardID',
      'Rank',
      'RankId',
      'TitleId',
      'FunctionId',
      'Function',
      'LanguageConst',
      'RegistrationNumber',
      'Qualifications',
      'Initials',
      'GPSMode',
      'ResRadioCallCode',
      'ImageName',
      'DisplayInDirectory',
      'ResResourceImpId',
      'FreeField1',
      'FreeField2',
      'FreeField3',
      'FreeField4',
      'ActiveFromUTC',
      'InactiveFromUTC',
      'BackInServiceUTC',
      'RetiredUTC',
      'DefaultSchedule'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Actor_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ActiveFromUTC = this.ActiveFromUTC && new Date(this.ActiveFromUTC);
    this.InactiveFromUTC = this.InactiveFromUTC && new Date(this.InactiveFromUTC);
    this.BackInServiceUTC = this.BackInServiceUTC && new Date(this.BackInServiceUTC);
    this.RetiredUTC = this.RetiredUTC && new Date(this.RetiredUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.PhoneNumber',
      'SagaSchema.ResourcePosition',
      'SagaSchema.ResAddress',
      'SagaSchema.ResVideo',
      'SagaSchema.ResComplement'
    ];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Name: string = '';
  public LastName: string = '';
  public FirstName: string = '';
  public VehicleUID: Guid = GuidTools.Empty;
  public Radio: number;
  public Info: string = '';
  public Attributes: string;
  public Comment: string = '';
  public Service: Guid = GuidTools.Empty;
  public X: number = -1.79769313486232e308;
  public Y: number = -1.79769313486232e308;
  public Status: number = 0;
  public ActivityUid: Guid = GuidTools.Empty;
  public ActivityStatus: SagaBase.Interfaces.ActivityStatusType = SagaBase.Interfaces.ActivityStatusType.Available;
  public ActivityCounter: number = 0;
  public CallCardID: Guid = GuidTools.Empty;
  public Rank: string;
  public RankId: number;
  public TitleId: number;
  public FunctionId: number;
  public Function: string;
  public LanguageConst: string;
  public RegistrationNumber: string;
  public Qualifications: string;
  public Initials: string;
  public GPSMode: number = 1;
  public ResRadioCallCode: string;
  public ImageName: string;
  public DisplayInDirectory: boolean = false;
  public ResResourceImpId: number = 0;
  public FreeField1: string;
  public FreeField2: string;
  public FreeField3: string;
  public FreeField4: string;
  public ActiveFromUTC: Date = new Date();
  public InactiveFromUTC: Date = new Date();
  public BackInServiceUTC: Date = new Date();
  public RetiredUTC: Date = new Date();
  public DefaultSchedule: SagaBase.DefaultScheduleType = SagaBase.DefaultScheduleType.Available;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
