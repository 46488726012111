import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Guid } from '@models/guid';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UnitResourceProviderService } from '../../services/unit-resource-provider/unit-resource-provider.service';

@Directive({
  selector: '[appLocalizeUnit]'
})
export class LocalizeUnitDirective implements OnInit, OnDestroy {
  @Input()
  public readonly appLocalizeUnit?: Guid;

  private _sub: Subscription = null;

  constructor(private unitResourceProvider: UnitResourceProviderService, private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.appLocalizeUnit !== '') {
      this.refresh();
      this._sub = this.unitResourceProvider.$unitPositionChangedEvent
        .pipe(filter(unitId => unitId === this.appLocalizeUnit))
        .subscribe(() => this.refresh());
    }
  }

  ngOnDestroy() {
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  private refresh() {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'display',
      this.unitResourceProvider.getPositionFor(this.appLocalizeUnit) !== null ? 'initial' : 'none'
    );
  }
}
