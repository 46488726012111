import { Injectable } from '@angular/core';
import { IModelService, IObjectChange } from '@services/model-factory';
import { CallCardCVP } from '../../models/CallCardCVP';
import { CardInfoFormatterService } from '@addins/core/core';

@Injectable()
export class CallCardCVPModelFactoryService implements IModelService<CallCardCVP> {
  constructor(private readonly _cardInfoFormatterService: CardInfoFormatterService) {}

  public change(change: IObjectChange<CallCardCVP>) {
    this.apply(change.target);
  }

  public apply(callcard: CallCardCVP) {
    callcard.longText = this._cardInfoFormatterService.format(callcard);
    callcard.shortText = this._cardInfoFormatterService.format(callcard, true)[0];
  }
}
