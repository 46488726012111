import { Injectable } from '@angular/core';
import { IWebLayout } from '@models/profile';
import { ProfileService } from '@services/profile/profile.service';

@Injectable()
export class LayoutService {
  get hasSideMenu(): boolean {
    return this.layout && this.layout.menu;
  }

  get hasSecondPage(): boolean {
    return this.layout && this.layout['second-page'];
  }

  get layout(): IWebLayout {
    return this.profile.current && this.profile.current.webLayout;
  }

  constructor(private profile: ProfileService) {}

  entryPage(): string {
    return this.layout && this.layout['entry-page'];
  }

  mainLayout(): string[] {
    return (this.layout && this.layout['main-layout'] && this.layout['main-layout'].split('|')) || [];
  }

  mainLayoutHasView(name: string): boolean {
    const layout = this.mainLayout();
    const rx = new RegExp(name);
    return (layout.find(i => i.match(rx) !== null) || null) !== null;
  }
}
