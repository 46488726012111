// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { CPatientLocation } from '../../SagaSchema/CPatientLocation';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CPatientDestinationLocation_abstract extends CPatientLocation {
  static $t: string = 'SagaSchema.CPatientDestinationLocation';
  $t: string = 'SagaSchema.CPatientDestinationLocation';

  public static GetMembers(): string[] {
    return [].concat(CPatientLocation.GetMembers());
  }

  public GetMembers(): string[] {
    return CPatientDestinationLocation_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
