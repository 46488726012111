// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CCaller_abstract } from './../abstract/SagaSchema/CCaller_abstract';

export class CCaller extends CCaller_abstract {
  get name(): string {
    return [this.Lname, this.Fname].filter(name => name).join(', ');
  }

  hasInfo(): boolean {
    return !!(this.name || this.Phone);
  }
}
