import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { VisualModule } from '../../../../../modules/visual/visual.module';
import { AlertCounterComponent } from './components/alert-counter/alert-counter.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { CallcardCcallerCardComponent } from './components/saga-components/callcard-ccaller-card/callcard-ccaller-card.component';
import { CallcardCcallersCardComponent } from './components/saga-components/callcard-ccallers-card/callcard-ccallers-card.component';
import { CallcardCeventsCardComponent } from './components/saga-components/callcard-cevents-card/callcard-cevents-card.component';
import { CallcardClocationsCardComponent } from './components/saga-components/callcard-clocations-card/callcard-clocations-card.component';
import { CallcardCnotesCardComponent } from './components/saga-components/callcard-cnotes-card/callcard-cnotes-card.component';
import { CallcardInfoCardComponent } from './components/saga-components/callcard-info-card/callcard-info-card.component';
import { XmlTopicsComponent } from './components/saga-components/xml-topics/xml-topics.component';
import { SimpleModalComponent } from './components/simple-modal/simple-modal.component';
import { AlertDirective } from './directives/alert/alert.directive';
import { CallerPipe } from './pipes/caller.pipe';
import { CardInfoFormatterService } from './services/card-info-formatter/card-info-formatter.service';
import { CardTitleFormatterService } from './services/card-title-formatter/card-title-formatter.service';
import { CurrentCardService } from './services/current-card/current-card.service';
import { EventFormatterService } from './services/event-formatter/event-formatter.service';
import { HttpErrorParsingService } from './services/http-error-parsing/http-error-parsing.service';
import { LanguageService } from './services/language/language.service';
import { BackgroundLocationService } from './services/location-provider/background-location/background-location.service';
import { GeolocationProviderService } from './services/location-provider/geolocation-provider/geolocation-provider.service';
import { LocationProviderService } from './services/location-provider/location-provider.service';
import { NavigatorLocationService } from './services/location-provider/navigator-location/navigator-location.service';
import { PositionProxyService } from './services/location-provider/position-proxy/position-proxy.service';
import { ModalFactoryService } from './services/modal-factory/modal-factory.service';
import { MyCardService } from './services/my-card/my-card.service';
import { NativeTranslationsService } from './services/native-translations/native-translations.service';
import { SettingsService } from './services/settings/settings.service';
import { SoundService } from './services/sound/sound.service';
import { StaticTranslationsService } from './services/static-translations/static-translations.service';

const providers = [
  MyCardService,
  LanguageService,
  SettingsService,
  CurrentCardService,
  ModalFactoryService,
  PositionProxyService,
  HttpErrorParsingService,
  LocationProviderService,
  CardInfoFormatterService,
  CardTitleFormatterService,
  EventFormatterService,
  NavigatorLocationService,
  BackgroundLocationService,
  NativeTranslationsService,
  StaticTranslationsService,
  SoundService,
  GeolocationProviderService
];

const directives = [AlertDirective];

const pipes = [CallerPipe];

const components = [SimpleModalComponent, CustomModalComponent, AlertCounterComponent];

const declarations = [
  XmlTopicsComponent,
  ModalHeaderComponent,
  ModalFooterComponent,
  CallcardInfoCardComponent,
  CallcardCnotesCardComponent,
  CallcardCeventsCardComponent,
  CallcardCcallersCardComponent,
  CallcardClocationsCardComponent,
  CallcardCcallerCardComponent,
  ...components,
  ...directives,
  ...pipes
];

@NgModule({
  declarations,
  providers,
  imports: [CommonModule, IonicModule, TranslateModule, VisualModule, SagaPipeModule],
  exports: declarations
})
export class CoreModule {}
