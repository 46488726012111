import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDatePipe } from '@app/pipes/saga-date/saga-date.pipe';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { OfflineMapSettingsComponent } from './components/offline-map-settings/offline-map-settings.component';
import { OfflineMapSetupService } from './services/executables/offline-map-setup/offline-map-setup.service';

const views = [];

const services = [Network];

const executables = [OfflineMapSetupService];

const pipes = [SagaDatePipe];

const providers = [...services, ...executables, ...pipes];

const components = [...views, OfflineMapSettingsComponent];

const directives = [];

@Addin({
  name: 'OfflineMap'
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class OfflineMapModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
