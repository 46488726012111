import { ISettingProvider, SETTING_PROVIDER } from '@addins/core/core';
import { UnitSelectorService } from '@addins/core/unit-activity';
import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { ModalService } from '@services/modal/modal.service';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { MyEquipmentService, MyEquipmentState } from '@techwan/ionic-core';
import { CommunicationEquipmentAbstract } from '@techwan/ionic-core/lib/schema/imported/abstract/SagaSchema/CommunicationEquipment_abstract';
import { UnitActivityAbstract } from '@techwan/ionic-core/lib/schema/imported/abstract/SagaSchema/UnitActivity_abstract';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DeviceComponent } from '../../components/device/device.component';
import { InitializationState, InitializerService } from '../../services/initializer/initializer.service';

@Injectable({
  providedIn: 'root'
})
export class LoginHandlerService {
  /**
   * Subscription to the initializer service which will notify us only if it's required
   */
  private readonly _subs: Subscription[] = [];

  private _modal: HTMLIonModalElement = null;

  private _promise: Promise<void> = null;

  constructor(
    private readonly initializer: InitializerService,
    private readonly myEquipmentService: MyEquipmentService,
    private readonly modal: ModalService,
    private readonly modalCtrl: ModalController,
    private readonly unitSelector: UnitSelectorService,
    private readonly association: AssociationSecurity,
    private readonly claims: RefreshTokenStorageService,
    @Inject(SETTING_PROVIDER)
    private readonly _settings: ISettingProvider
  ) {}

  start() {
    this.stop();
    this._subs.push(this.initializer.ready.subscribe(state => this.onStateChanged(state)));
    this._subs.push(
      this.initializer.ready.pipe(filter(state => state === InitializationState.requireAction)).subscribe(() => this.onRequireAction())
    );
  }

  stop() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  private onStateChanged(state: InitializationState) {
    switch (state) {
      case InitializationState.setup:
        this._subs.splice(0, 1)[0].unsubscribe();
        break;

      case InitializationState.authenticated:
        this.association.loginData = this.claims.read().login;
        break;
    }
  }

  /**
   * Initializer service will notify this method only when at least one of these data are missing:
   * - device (aka CommunicationEquipment)
   * - unit activity (aka UnitActivity)
   */
  private onRequireAction() {
    this._subs.pop().unsubscribe();
    this._subs.push(
      this.myEquipmentService.stateChanged.pipe(filter(state => state === MyEquipmentState.ready)).subscribe(() => this.onEquipmentReady())
    );
  }

  private onEquipmentReady() {
    let device: CommunicationEquipmentAbstract = null;
    let unit: UnitActivityAbstract = null;
    this.myEquipmentService.myDevice.subscribe(d => (device = d)).unsubscribe();
    this.myEquipmentService.myUnit.subscribe(u => (unit = u)).unsubscribe();
    if (device === null) {
      this.onNoDevice();
    } else if (unit === null) {
      // Device is already known
      this.onNoUnit(device.Identifier);
    } else {
      // Resets the selection if required.
      this.unitSelector.reset();
      // Stop listening to device event
      this._subs.pop().unsubscribe();
    }
    if (device && this._promise) {
      this._promise
        .then(() => this._modal.dismiss())
        .then(() => {
          this._modal = null;
          this._promise = null;
        });
    }
  }

  private onNoDevice() {
    // No device, present UI
    if (this._promise === null) {
      // TODO: @deprecated please use ModalFactoryService instead
      this._promise = this.modal
        .dismissLoading()
        .then(() => this.modalCtrl.create({ component: DeviceComponent }).then(m => (this._modal = m).present()));
    }
  }

  private onNoUnit(deviceIdentifier: string) {
    const unitCreationBootType: number = this._settings.getValue('SagaMobileWebClient.UnitCreationBootType');
    //TODO: we will need an enum for this
    // 0: wait for unit created by control center,
    // 2: custom unit creation,
    // 4: auto unit creation by backend
    if (unitCreationBootType === 0 || unitCreationBootType === 4) {
      this.unitSelector.setCanCreate(null);
      return;
    }
    this.unitSelector.setCanCreate(deviceIdentifier);
  }
}
