// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CEventBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CEventBase';
  $t: string = 'SagaSchema.CEventBase';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'Complement',
      'EventCode',
      'EventId',
      'EventType',
      'Priority',
      'DecisionTreeRootType',
      'DecisionTreeData',
      'NodeTag',
      'ProcedureId',
      'EventText',
      'DispositionCode',
      'IsSubsequent',
      'FieldGeneric1',
      'FieldGeneric2',
      'FieldGeneric3',
      'XmlTopics',
      'Agencies'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CEventBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public Complement: string = '';
  public EventCode: string = '';
  public EventId: number;
  public EventType: number = 0;
  public Priority: number = 0;
  public DecisionTreeRootType: SagaBase.EventNodeType = SagaBase.EventNodeType.Unknown;
  public DecisionTreeData: string = '';
  public NodeTag: string = '';
  public ProcedureId: number = 0;
  public EventText: string = '';
  public DispositionCode: number;
  public IsSubsequent: boolean = false;
  public FieldGeneric1: string;
  public FieldGeneric2: string;
  public FieldGeneric3: string;
  public XmlTopics: string;
  public Agencies: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
