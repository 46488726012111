import { DrawingTool } from '@techwan/map-tools';

export enum DeploymentToolName {
  add = 'add-tool',
  remove = 'remove-tool',
  move = 'move-tool'
}

export class DeploymentTool extends DrawingTool {
  constructor(name: string, image: string) {
    super(name, image);
  }
}
