import { InjectionToken } from '@angular/core';
import { IAlertProvider } from './interfaces/alert-provider';
import { LocationConfigurationProvider } from './interfaces/location-configuration-provider';
import { LocationProviderErrorReporter } from './interfaces/location-provider-error-reporter';
import { INativeTranslationsProvider } from './interfaces/native-translations-provider';
import { RequestLocalization } from './interfaces/request-localization';
import { ISettingProvider } from './interfaces/settings-provider';
import { IStaticTranslationsProxy } from './interfaces/static-translation-proxy';
import { TokenProvider } from './interfaces/token-provider';

export const PROVIDER_ERROR_REPORTER = new InjectionToken<LocationProviderErrorReporter>('LocationProviderFallback');

export const TOKEN_PROVIDER = new InjectionToken<TokenProvider>('TokenProvider');

export const LOCATION_CONFIG = new InjectionToken<LocationConfigurationProvider>('LocationConfiguration');

export const REQUEST_LOCALIZE = new InjectionToken<RequestLocalization>('Localizer');

export const SETTING_PROVIDER = new InjectionToken<ISettingProvider>('SettingsProvider');

export const STATIC_TRANSLATION_TOKEN = new InjectionToken<IStaticTranslationsProxy>('StaticTranslationsProxy');

export const NATIVE_TRANSLATION_TOKEN = new InjectionToken<INativeTranslationsProvider>('NativeTranslationsProvider');

export const ALERT_PROVIDER_TOKEN = new InjectionToken<IAlertProvider>('AlertProvider');
