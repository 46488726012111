import { Injectable, Type } from '@angular/core';
import { IUnitSelector } from '@addins/core/unit-activity';
import { CustomCreationModalComponent } from '../../components/custom-creation-modal/custom-creation-modal.component';

@Injectable()
export class CustomUnitCreationService implements IUnitSelector {
  public get create(): Type<any> {
    return CustomCreationModalComponent;
  }

  constructor() {}
}
