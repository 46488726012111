import { Injectable } from '@angular/core';
import { LoginConfigService } from '@services/login-config/login-config.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { AddinResolver, AddinService } from '@techwan/ionic-core';
import { LoginTypes } from '@techwan/security';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProfileReaderService } from '../profile-reader/profile-reader.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ViewSelectorService {
  /**
   * Cached views
   */
  private _views = new Map<string, AddinResolver>();

  /**
   * Current view which is shown
   */
  private _currentView: AddinResolver;

  /**
   * Event notifier for view changed event
   */
  private _viewChanged: BehaviorSubject<AddinResolver>;
  get viewChanged(): Observable<AddinResolver> {
    return this._viewChanged.asObservable();
  }

  private _sub: Subscription = null;

  private _inApp = false;
  get inApp(): boolean {
    return this._inApp;
  }

  get ready(): Observable<boolean> {
    return this._loginConfig.ready;
  }

  constructor(
    private _addinService: AddinService,
    private _profileService: ProfileService,
    private _profileReader: ProfileReaderService,
    private _loginConfig: LoginConfigService,
    private _viewConfiguration: ViewConfigurationService
  ) {
    this._currentView = this.getView(this._viewConfiguration.getBootName());
    this._viewChanged = new BehaviorSubject<AddinResolver>(this._currentView);
  }

  getCurrent(): AddinResolver {
    return this._currentView;
  }

  getHome(): AddinResolver {
    return this.getView(this._profileReader.getMainViewName(this._profileService.current) || this._viewConfiguration.getHomeName());
  }

  getLogin(): AddinResolver {
    return this.getView(this._viewConfiguration.getLoginName(LoginTypes[this._loginConfig.loginConfig.LoginType]));
  }

  getMap(): AddinResolver {
    return this.getView(this._viewConfiguration.getMapName());
  }

  getView(path: string): AddinResolver {
    if (!this._views.has(path)) {
      this._views.set(path, this.loadView(path));
    }
    return this._views.get(path);
  }

  goHome() {
    this._inApp = true;
    this.changeView(this.getHome());
  }

  goLogin() {
    this.changeView(this.getLogin());
  }

  goBoot() {
    this.changeView(this.getView(this._viewConfiguration.getBootName()));
  }

  setup(): void {
    let ready: boolean;
    this._loginConfig.ready.subscribe(r => (ready = r)).unsubscribe();
    if (ready) {
      this.$init();
    } else {
      this._sub = this._loginConfig.ready.pipe(filter(r => r)).subscribe(() => this.$init());
    }
  }

  private changeView(view) {
    const last = this._currentView;
    this._currentView = view;
    if (last !== view) {
      this._viewChanged.next(this._currentView);
    }
  }

  private loadView(name: string): AddinResolver {
    return this._addinService.resolveAddinView(name);
  }

  private $init() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = null;
    }
    this.changeView(this.getLogin());
  }
}
