import { ILocation } from '@models/interfaces/ILocation';
import { DefaultLocationFormatter } from './default-location-formatter';

export class CraiolLocationFormatter extends DefaultLocationFormatter {
  format(location: ILocation): string[] {
    const area13: string = [location.Area3Name, location.Area1Name].filter(s => s).join(' - ');
    const city: string = [location.Zip, location.CityName].filter(s => s).join(', ');

    return [area13, location.PlaceName, location.Area2Name, city].filter(s => s);
  }
}
