import { Type } from '@angular/core';
import { ToolBase } from '@techwan/map-tools';
import { Observable } from 'rxjs';
import { PanelToolBase } from '../panel-tools/panel-tool-base';
import { PanelContent } from './panel-content';

export interface PropertyChanged {
  name: string;
  oldValue: any;
  instance: PanelToolBase;
}

export enum PanelAction {
  toggleDismiss
}

export interface PanelTool {
  position: number;
  action?: PanelAction;
  groupName?: string;

  /**
   * Button for activating content
   */
  button?: ToolBase;

  /**
   * Target component to be shown inside the panel's content
   */

  content?: Type<PanelContent>;

  readonly $changed: Observable<PropertyChanged>;
  readonly $click: Observable<Event>;
  readonly isActive: boolean;
  isVisible: boolean;

  click(e: Event): void;

  reset(): void;
}
