import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { StatusParameters } from '../status-proxy/status-proxy.service';

export interface IStatusChangeRequirement {
  preChangeRequirement(status: ListResourceStatus, callcard?: CallCard): Promise<boolean>;
  postChangeRequirement(status: ListResourceStatus, callcard?: CallCard): Promise<boolean>;
  getStatusParameters(): StatusParameters;
}

@Injectable({
  providedIn: 'root'
})
export class StatusChangeRequirementsService {
  private _requirements: IStatusChangeRequirement[] = [];
  get requirements(): IStatusChangeRequirement[] {
    return this._requirements;
  }

  constructor() {}

  addRequirement(requirement: IStatusChangeRequirement) {
    this._requirements.push(requirement);
  }
}
