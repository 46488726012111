import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectoryEntry, Entry, File, FileEntry } from '@ionic-native/file/ngx';
import { Security } from '@services/security/security.service';

@Injectable()
export class SagaFile extends File {
  constructor(private readonly http: HttpClient) {
    super();
    this.dataDirectory = 'data/';
  }

  private static isBrowser(): boolean {
    return (window as any).device.platform === 'browser';
  }

  // Non-overloaded methods
  createFile(path: string, fileName: string, replace: boolean): Promise<FileEntry> {
    return Promise.resolve(null);
  }

  writeExistingFile(path: string, fileName: string, text: string | Blob): Promise<void> {
    return Promise.resolve(null);
  }

  checkDir(path: string, dir: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  createDir(path: string, dirName: string, replace: boolean): Promise<DirectoryEntry> {
    return Promise.resolve(null);
  }

  copyFile(path: string, fileName: string, newPath: string, newFileName: string): Promise<Entry> {
    return Promise.resolve(null);
  }

  checkFile(path: string, file: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  readAsText(path: string, file: string, security?: Security): Promise<string> {
    if (SagaFile.isBrowser()) {
      return this.http.get<string>(path.replace(this.dataDirectory, '') + file).toPromise();
    }
    return super.readAsText(path, file);
  }
}
