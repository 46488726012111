import { Component, OnInit } from '@angular/core';
import { CallcardDetailButtonsComponent } from '../callcard-detail-buttons/callcard-detail-buttons.component';
import { LocalizePositionService, MyLocationService } from '@addins/core/map';
import { ButtonsProviderService } from '../../services/buttons-provider/buttons-provider.service';
import { SagaSettingsService } from '@services/settings';

@Component({
  selector: 'app-callcard-detail-buttons-v',
  templateUrl: './callcard-detail-buttons-v.component.html',
  styleUrls: ['./callcard-detail-buttons-v.component.scss']
})
export class CallcardDetailButtonsVComponent extends CallcardDetailButtonsComponent implements OnInit {
  constructor(
    _localizePosition: LocalizePositionService,
    _buttonsProvider: ButtonsProviderService,
    _myLocationService: MyLocationService,
    _settingsService: SagaSettingsService
  ) {
    super(_localizePosition, _buttonsProvider, _myLocationService, _settingsService);
  }

  ngOnInit() {}
}
