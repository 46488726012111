import { Injectable } from '@angular/core';
import { ILocationProvider, LocationProviderErrorReporter, ILocationProxy } from '@addins/core/core';
import { from, Observable, of } from 'rxjs';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from '@models/guid';

@Injectable()
export class NoProviderService implements ILocationProvider, LocationProviderErrorReporter {
  private _text: string = null;

  constructor(private readonly _toast: ToastController, private readonly _translate: TranslateService) {}

  /// ILocationProvider begins

  readonly $change: Observable<Geoposition>;

  public getCurrent(): Observable<Geoposition> {
    return of(null);
  }

  public getProxy(): ILocationProxy {
    return null;
  }

  public unknownProvider(): Observable<void> {
    if (this._text === null) {
      this._text = this._translate.instant('NoLocationProvider');
    }
    return from(
      this._toast
        .create({ message: this._text, duration: 4000, position: 'bottom' })
        .then(toast => toast.present())
        .then(() => null)
    );
  }

  public start(): Observable<any> {
    return of();
  }

  public stop(): Observable<any> {
    return of();
  }

  public setup(uid: Guid) {}

  /// ILocationProvider ends

  // LocationProviderErrorReporter begins

  public report(error: any) {
    console.error(error);
    this._toast.create({ message: `Geolocation : ${error.message}`, duration: 4000 }).then(toast => toast.present());
  }

  // LocationProviderErrorReporter ends
}
