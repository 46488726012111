import { AttachmentService, MediaType } from '@addins/media';
import { Component, Input } from '@angular/core';
import { AlertInput } from '@ionic/angular';
import { Task } from '@models/imported/SagaSchema/Task';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { TasksProviderService } from '../../services/tasks-provider/tasks-provider.service';

interface AttachmentOptionLabel {
  type: MediaType;
  label: string;
}

const AttachmentOptionLabels: AttachmentOptionLabel[] = [
  { type: MediaType.Picture, label: 'Mobile.AddPhoto' },
  { type: MediaType.Video, label: 'Mobile.AddVideo' },
  { type: MediaType.File, label: 'Mobile.AddImage' }
];

@Component({
  selector: 'app-task-add-attachment-button',
  templateUrl: './task-add-attachment-button.component.html',
  styleUrls: ['./task-add-attachment-button.component.scss']
})
export class TaskAddAttachmentButton {
  @Input()
  task: Task;

  get isMyTask(): boolean {
    return this.tasksProvider.myTask && this.tasksProvider.myTask.ObjGuid == this.task.ObjGuid;
  }

  constructor(
    private translatedAlert: TranslatedAlertService,
    private tasksProvider: TasksProviderService,
    private attachmentService: AttachmentService
  ) {}

  showAddAttachment() {
    const radioInputs = this.createRadioInputs();
    radioInputs[0].checked = true;

    this.translatedAlert.show({
      header: 'Mobile.AddAttachment',
      inputs: radioInputs,
      buttons: [
        { text: 'Mobile.Cancel', value: false },
        {
          text: 'Mobile.Confirm',
          value: true,
          handler: attachmentOptionLabel => this.createAttachment(attachmentOptionLabel.type)
        }
      ]
    });
  }

  private createRadioInputs(): AlertInput[] {
    return AttachmentOptionLabels.map(optionLabel => ({
      type: 'radio',
      label: optionLabel.label,
      value: optionLabel
    }));
  }

  private createAttachment(mediaType: MediaType) {
    this.attachmentService.createAttachment(this.task.ObjGuid, mediaType);
  }
}
