// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class RbacAccessObject_abstract extends PersistentObject {
  static $t: string = 'SagaBase.RBAC.RbacAccessObject';
  $t: string = 'SagaBase.RBAC.RbacAccessObject';

  public static GetMembers(): string[] {
    return [
      'SessionUid',
      'ObjectId',
      'ObjectQualifier',
      'ObjectName',
      'isCreate',
      'OperationId',
      'OperationName',
      'isRead',
      'isUpdate',
      'isDelete',
      'isExport',
      'PermissionId',
      'PermissionName'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return RbacAccessObject_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SessionUid: Guid = GuidTools.Empty;
  public ObjectId: number = 0;
  public ObjectQualifier: string;
  public ObjectName: string;
  public isCreate: boolean = false;
  public OperationId: number = 0;
  public OperationName: string;
  public isRead: boolean = false;
  public isUpdate: boolean = false;
  public isDelete: boolean = false;
  public isExport: boolean = false;
  public PermissionId: number = 0;
  public PermissionName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
