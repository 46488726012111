import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CallcardStampsService } from '../../services/callcard-stamps/callcard-stamps.service';

@Component({
  selector: 'app-journal-list',
  templateUrl: './journal-list.component.html',
  styleUrls: ['./journal-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JournalListComponent implements OnDestroy {
  @Input()
  journalItems: SagaObject[];
  @Input()
  title?: string;

  constructor(private callcardStamps: CallcardStampsService) {}

  isNew(stamp: SagaObject): boolean {
    return this.callcardStamps.isNew(stamp);
  }

  ngOnDestroy(): void {
    this.callcardStamps.markRead();
  }
}
