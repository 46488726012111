import { Guid } from '@models/guid';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';

const TYPE = 'TW.Saga.Mobile.Common.CustomDestination';

export class Destination extends SagaObject {
  public static type = TYPE;
  public readonly $t = TYPE;

  public CoordX: number;
  public CoordY: number;
  public ResAddressUid: Guid;
  public Name: string;

  public static GetMembers(): string[] {
    return ['CoordX', 'CoordY', 'ResAddressUid', 'Name'];
  }

  public GetMembers(): string[] {
    return Destination.GetMembers();
  }
}
