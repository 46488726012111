import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SagaSettingsService } from '@services/settings';
import { Initializer } from '@services/initializer/initializer.service';
import { Security } from '@services/security/security.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { AuthenticationService } from '@techwan/ionic-core';
/**
 * @name RequestDispatchComponent
 * @description
 *
 */
@Component({
  templateUrl: 'request-dispatch.component.html',
  styleUrls: ['request-dispatch.component.scss']
})
export class RequestDispatchComponent {
  @ViewChild('mapCard') mapCard: ElementRef;
  @ViewChild('mapLabel') mapLabel: ElementRef;
  canRefuse: boolean = false;

  get unitActivity() {
    return this.security.getData('UnitActivity');
  }

  constructor(
    private security: Security,
    private viewCtrl: ModalController,
    private appSettings: SagaSettingsService,
    private initializer: Initializer,
    private unitActivityService: UnitActivityService,
    private readonly auth: AuthenticationService
  ) {
    this.initializer.setupBefore(auth).then(() => {
      setTimeout(() => {
        this.canRefuse = this.appSettings.getValue('SagaMobileWebClient.AllowEngagementRefuse');
      });
    });
  }

  mapHeight() {
    return (this.mapCard && this.mapLabel && this.mapCard.nativeElement.offsetHeight - this.mapLabel.nativeElement.offsetHeight) || 0;
  }

  mapWidth() {
    return (this.mapCard && this.mapCard.nativeElement.offsetWidth) || 0;
  }

  async cancel() {
    await this.unitActivityService.cancelDispatch();
    await this.viewCtrl.dismiss();
  }

  async accept() {
    await this.unitActivityService.acceptDispatch();
    await this.viewCtrl.dismiss(true);
  }

  openMyCard() {}
}
