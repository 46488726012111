import { Injectable } from '@angular/core';
import { Security } from '@services/security/security.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';

@AddinExecute({
  name: 'Login',
  description: 'Provide a was to automatically remove the login data from the security service on disconnection.'
})
@Injectable()
export class LoginService extends Execute {
  private unregister: Function;

  constructor(private readonly security: Security) {
    super();
  }

  public execute(): void {
    this.unregister = this.security.onLogout(() => this.security.deleteSecurityData('login'));
  }

  public destroy(): void {
    this.unregister();
  }
}
