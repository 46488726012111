import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { AttachmentItemComponent } from './components/attachment-item/attachment-item.component';
import { AttachmentModalComponent } from './components/attachment-modal/attachment-modal.component';
import { SINGLECALLCARD_ATTACHMENTS } from './schema/constants';
import { CardButtonControllerService } from './services/card-button-controller/card-button-controller.service';
import { CardButtonService } from './services/executables/card-button/card-button.service';

const views = [];

const executables = [CardButtonService];

const providers = [CardButtonControllerService, ...executables];

const components = [AttachmentModalComponent, AttachmentItemComponent, ...views];

const directives = [];

@Addin({
  name: SINGLECALLCARD_ATTACHMENTS
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule, IonicModule, SagaPipeModule]
})
export class SingleCallcardAttachmentsModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
