import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { UnitSelectorService } from '@addins/core/unit-activity';
import { CustomUnitCreationService } from '../../custom-unit-creation/custom-unit-creation.service';

@AddinExecute({
  name: 'WaitingRoomAccess'
})
@Injectable()
export class WaitingRoomAccessService extends Execute {
  constructor(private _unitSelector: UnitSelectorService, private _customUnitCreation: CustomUnitCreationService) {
    super();
  }

  execute(): void {
    // register to the event of the button and create a modal to show the process
    this._unitSelector.useCustom(this._customUnitCreation);
  }
}
