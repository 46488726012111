<ion-header>
  <ion-toolbar color="primary" hideBackButton="true">
    <ion-title><span translate>SagaMobile.PickerCoordinatePicker</span></ion-title>
    <ion-buttons slot="end">
      <ion-button slot="icon-only" (click)="cancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="coordinate-picker-content" padding>
  <ion-card #currentCard *ngIf="unitActivity && unitActivity.callcard">
    <!--<img src="{{unitActivity.callcard.location.getStaticMapUrl({height: getHeight(currentCard, currentLabel), width: currentCard.el.offsetWidth, zoom: 15, color: unitActivity.callcard.color.tinycolor.toHexString()})}}">-->
    <ion-item #currentLabel>
      <ion-icon [style.color]="unitActivity.callcard.color.tinycolor.toRgbString()" name="pin" slot="start" large></ion-icon>
      <h2>{{ unitActivity.callcard.CreatedUTC | moment: 'LLLL' }}</h2>
      <p style="white-space: initial;">{{ unitActivity.callcard.event.EventText }}</p>
      <p style="white-space: initial;">{{ unitActivity.callcard.location.FormattedLocation }}</p>
    </ion-item>
  </ion-card>
  <div class="transfer-separator">
    <div class="transfer-arrow">
      <ion-icon name="ios-arrow-down"></ion-icon>
      <ion-icon name="ios-arrow-down"></ion-icon>
    </div>
    <span translate>SagaMobile.CallCard.ChangeLocation</span>
    <div class="transfer-arrow">
      <ion-icon name="ios-arrow-down"></ion-icon>
      <ion-icon name="ios-arrow-down"></ion-icon>
    </div>
  </div>
  <ion-card #newCard>
    <!--<img src="{{staticMapUrl({height: getHeight(newCard, newLabel), width: newCard.offsetWidth})}}">-->
    <ion-item #newLabel>
      <ion-icon name="pin" slot="start" large></ion-icon>
      <p style="white-space: initial;">{{ caller.FormattedLocation }}</p>
    </ion-item>
  </ion-card>
  <ion-button large (click)="changeLocation()"><span translate>SagaMobile.CallCard.ChangeLocation</span></ion-button>
</ion-content>
