<div class="login-page">
    <div class="logo-wrapper"><img class="login-back-image" src="assets/logo.png" /></div>
    <div class="login-error" *ngIf="error"><span [translate]="error"></span></div>

    <form [formGroup]="loginForm" (ngSubmit)="associate()" novalidate>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <ion-item>
                <ion-label><span translate>static.login.device.{{isEmei ? 'emei':'name'}}</span></ion-label>
                <ion-input type="text" #deviceInput formControlName="device"></ion-input>
            </ion-item>
        </ion-list>

        <ion-button fill="clear" class="login-button" type="submit" color="primary" [disabled]="!deviceInput.value">
            <span translate>static.login.associate</span>
        </ion-button>
    </form>

    <div class="login-settings">
        <ion-button class="login-settings" slot="end" fill="clear" (click)="settings.open()">
            <ion-icon slot="icon-only" size="large" name="settings"></ion-icon>
        </ion-button>
    </div>
</div>
