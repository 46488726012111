import { Injectable } from '@angular/core';
import { PersistentObject } from '@models/imported/SagaBase/PersistentObject';
import * as tinycolor from 'tinycolor2';
import { SagaSettingsService } from '../settings';

export interface CssToStyleOption {
  overrideStyle?: any;
  name?: string;
  force?: boolean;
}

@Injectable()
export class StylesService {
  private defaultStyleExtract = {
    color: {
      transform(style) {
        const c = tinycolor(style).toRgb();
        return [c.r, c.g, c.b, c.a];
      }
    },
    'font-family': {
      to: 'font'
    },
    'font-size': {
      to: 'size',
      transform(style) {
        return parseInt(style);
      }
    },
    content: {
      to: 'text',
      transform(style) {
        return style.substring(1, style.length - 1);
      }
    }
  };

  private element: HTMLElement;
  private cache: any = {};
  private body: HTMLElement;

  private viewportData = {
    width: 'device-width',
    'initial-scale': 0.6,
    'minimum-scale': 0.4,
    'maximum-scale': 1.0,
    'user-scalable': false
  };

  constructor(private appSettings: SagaSettingsService) {
    this.element = document.createElement('i');
    this.body = document.querySelector('html');
    this.loadSizes();
    Object.assign(this.element.style, {
      position: 'absolute',
      top: '20000px',
      left: '20000px'
    });

    document.body.appendChild(this.element);
  }

  viewportDataToString(): string {
    const elements = [];
    for (const name in this.viewportData) {
      let value = this.viewportData[name];
      if (typeof value == 'boolean') {
        value = value ? 'yes' : 'no';
      }
      elements.push(`${name}=${value}`);
    }
    return elements.join(', ');
  }

  applyViewportData() {
    const viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', this.viewportDataToString());
  }

  applyFontSize(size: number) {
    if (size < 20) {
      size = 20;
    }
    if (size > 100) {
      size = 100;
    }
    this.body.style.fontSize = size + '%';
  }

  applyScale(size: number) {
    if (size < 50) {
      size = 50;
    }
    if (size > 200) {
      size = 200;
    }
    /*this.viewportData['initial-scale'] = size;
        this.applyViewportData();*/
    this.body.style.scale = `${size / 100}`;
  }

  storeSizes() {
    localStorage.setItem('font-size', parseFloat(this.body.style.fontSize) + '');
    localStorage.setItem('scale', parseFloat(this.body.style.scale) + '');
  }

  loadSizes() {
    this.applyFontSize(parseFloat(localStorage.getItem('font-size')) || 62);
    this.applyScale(parseFloat(localStorage.getItem('scale')) || 100);
  }

  get fontSize() {
    return parseFloat(this.body.style.fontSize);
  }

  set fontSize(fontSize: number) {
    this.applyFontSize(fontSize);
    this.storeSizes();
  }

  get scale(): number {
    return parseInt(this.body.style.scale);
  }

  set scale(scale: number) {
    this.applyScale(scale);
    this.storeSizes();
  }

  cssToStyle(className: string, options: CssToStyleOption = {}) {
    const named = className + (options.name || '');
    if (options.force || !this.cache[named]) {
      this.element.className = className;
      const styleExtract = options.overrideStyle || this.defaultStyleExtract;
      const properties = Object.keys(styleExtract);
      const computedStyle = window.getComputedStyle(this.element, ':before');
      const originalStyles = {};
      properties.forEach(key => {
        originalStyles[key] = computedStyle[key];
      });
      const styles = {};
      for (let name in originalStyles) {
        let style = originalStyles[name];
        const conf = styleExtract[name];
        if (conf.to) {
          name = conf.to;
        }
        if (conf.transform) {
          style = conf.transform(style);
        }
        styles[name] = style;
      }
      this.cache[named] = styles;
    }
    return this.cache[named];
  }

  objectIconStyle(obj: PersistentObject, force: boolean = false) {
    return (
      (obj &&
        this.cssToStyle(obj.className, {
          force
        })) ||
      {}
    );
  }

  private parseIconKey(key) {
    let keyData = key.split('.')[1];
    keyData = keyData.split('/');
    return {
      key,
      objectTypeName: keyData[0].replace(/_/g, '.'),
      listName: keyData[1] && keyData[2] && keyData[1].replace(/_/g, '.'),
      fieldName: keyData[1] && keyData[2] && keyData[2].replace(/_/g, '.'),
      className: keyData[1] && !keyData[2] && keyData[1].split('&').map(c => c.trim())
    };
  }

  private applyIconsForTypes(config, typesIcon) {
    for (const value in typesIcon) {
      const glyph = typesIcon[value];
      this.applyGlyphIcon(config, glyph, value);
    }
  }

  private applyGlyphIcon(config, glyph, value) {
    const clearName = config.objectTypeName.replace(/\./g, '_');
    let selector = '.' + clearName;
    let id = clearName;
    if (config.fieldName && value != -1) {
      const clearClass = config.fieldName.replace(/\./g, '_');
      selector += '.' + clearClass + '_' + value;
      id += '_' + clearClass + '_' + value;
    }

    if (config.className) {
      const classId = '.' + config.className.join('.');
      selector += classId;
      id += classId;
    }

    const css = {};

    css['i' + selector] = {
      'font-family': glyph.family + ' !important'
    };

    css['i' + selector + ':before'] = {
      'font-family': glyph.family + ' !important',
      content: '"' + glyph.content + '" !important'
    };

    this.addCustomStyle(id, css);
  }

  addCustomStyle(id, styles) {
    let element = document.getElementById('saga-custom-style-' + id);
    if (!element) {
      const firstChild = document.head ? document.head.firstElementChild : null;
      element = document.createElement('style');
      element.setAttribute('id', 'saga-custom-style-' + id);
      element.setAttribute('class', 'saga-custom-style');

      document.head.insertBefore(element, firstChild);
    }

    let styleStr = '';
    for (const className in styles) {
      const properties = styles[className];
      let propertiesStr = '';
      for (const propertyName in properties) {
        const propertyValue = properties[propertyName];
        propertiesStr += propertyName + ':' + propertyValue + ';';
      }
      styleStr += className + '{' + propertiesStr + '}';
    }

    element.innerHTML = '';
    element.appendChild(document.createTextNode(styleStr));
  }

  loadAppSettingsIcon() {
    const settingsPrefix = 'Icons.';
    const values = this.appSettings.getAll();
    for (const key in values) {
      const value = values[key];
      if (key.indexOf(settingsPrefix) === 0 && value !== null && typeof value === 'object') {
        const config = this.parseIconKey(key);
        try {
          this.applyIconsForTypes(config, value || {});
        } catch (e) {}
      }
    }
  }
}
