import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { DestinationSelectComponent } from './components/destination-select/destination-select.component';
import { SelectionToolComponent } from './components/selection-tool/selection-tool.component';
import { DestinationProviderService } from './services/destination-provider/destination-provider.service';
import { DestinationProxyService } from './services/destination-proxy/destination-proxy.service';
import { ButtonProviderService } from './services/executables/button-provider/button-provider.service';
import { LastDestinationService } from './services/last-destination/last-destination.service';

const executables = [ButtonProviderService];

const components = [DestinationSelectComponent, SelectionToolComponent];
const views = [];

const services = [...executables, DestinationProviderService, DestinationProxyService, LastDestinationService];

@Addin({
  name: 'SingleCallcard-Destination',
  require: ['Core', 'Ext-Itinerary']
})
@NgModule({
  declarations: [...components, ...views],
  providers: services,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SingleCallcardDestinationModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
