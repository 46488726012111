// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @19.06.2017 15:21:14
import { ListVehicleType_abstract } from './../../abstract/SagaSchema/ListObjects/ListVehicleType_abstract';
export class ListVehicleType extends ListVehicleType_abstract {
  get icon() {
    if (this.Icon) {
      return 'data:image/png;base64,' + this.Icon;
    } else {
      return 'md-directions_car';
    }
  }
}
