import { Injectable } from '@angular/core';
import { CCallerBase } from '@models/imported/SagaSchema/CCallerBase';
import { CacheAction } from '@services/cache/cacheEvent';
import { LocationFormatterFactoryService } from '@services/location/location-formatter-factory/location-formatter-factory.service';
import { IModelService, IObjectChange, ModelService } from '@services/model-factory';

@ModelService(CCallerBase)
@Injectable()
export class CCallerBaseModelService implements IModelService<CCallerBase> {
  constructor(private locationFormatter: LocationFormatterFactoryService) {}

  change(change: IObjectChange<CCallerBase>) {
    if (change.action === CacheAction.removed) {
      return;
    }

    const caller: CCallerBase = change.target;
    caller.FormattedLocation = this.locationFormatter.getFormatter().format(caller);
  }
}
