<ion-menu contentId="main-content" *ngIf="mainMenu && mainMenu.show && mainMenu.menus.length > 1" [swipeGesture]="menuService.showMenu" type="overlay" menuId="mainMenu" class="saga-menu">
  <ion-header>
    <ion-toolbar color="primary">
      <img slot="start" alt="" src="assets/logo.png" />
      <ion-title class="with-subtitle">
        <h2>{{name}}</h2>
        <p class="menu-footer">{{getLoginUser()}}</p>
      </ion-title>
      <ion-buttons slot="end">
        <ion-menu-toggle>
          <ion-button (click)="openSettings()" color="light" fill="clear"><ion-icon slot="icon-only"  name="settings"></ion-icon></ion-button>
        </ion-menu-toggle>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="menus">
      <ion-list lines="none" class="menu-entries">
        <ion-item *ngFor="let menu of mainMenu.menus" [style.display]="menu.hidden() ? 'none':''"
                  (click)="viewSelected(menu)">
          <ion-icon [name]="menu.icon" slot="start"></ion-icon>
          <span translate>{{menu.text}}</span>
        </ion-item>
      </ion-list>
      <ion-list lines="none" class="menu-bottom">
        <ion-item (click)="logout()" color="danger">
          <ion-icon name="log-out" slot="start"></ion-icon>
          <span translate>SagaMobile.Disconnect</span>
        </ion-item>
      </ion-list>
    </div>
  </ion-content>
</ion-menu>
