import { Color } from '@models/color';
import { CacheService } from '@services/cache/cache.service';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { Observable, Subject } from 'rxjs';

export interface ServicesProviderInterface {
  cache: CacheService;
  fetchList: FetchListService;
}

export interface IPropertyChange {
  properties: string[];
  oldValues: any[];
}

export abstract class BaseObject {
  static globalIcon: string = 'error';

  protected readonly _changed = new Subject<IPropertyChange>();
  get $changed(): Observable<IPropertyChange> {
    return this._changed.asObservable();
  }

  hasCacheChanged: boolean = false;
  hasDatabaseChanged: boolean = false;

  get icon() {
    return BaseObject.globalIcon;
  }

  get secondaryIcon() {
    return null;
  }

  get secondaryIconColor() {
    return null;
  }

  get color(): Color {
    return null;
  }

  getObjectPath() {
    const path = [];
    let current = Object.getPrototypeOf(this);
    while (current && current.constructor) {
      if ((current.constructor as any).$t) {
        path.unshift(current.constructor);
        current = Object.getPrototypeOf(current);
      } else {
        current = null;
      }
    }
    return path;
  }

  onDataBaseChanged() {
    this.hasDatabaseChanged = true;
  }
}
