import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[maxWidthOnCount]'
})
export class MaxWidthOnCountDirective {
  private _currentCount?: number = null;

  @Input('maxWidthOnCount')
  set elementsCount(value: number) {
    if (this._currentCount === null || this._currentCount !== value) {
      this._currentCount = value;
      this.updateWidth();
    }
  }

  constructor(private e: ElementRef, private renderer: Renderer2) {}

  private updateWidth() {
    const elementsWidth = 100 / this._currentCount;
    this.renderer.setStyle(this.e.nativeElement, 'max-width', elementsWidth + '%');
  }
}
