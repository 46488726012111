import { CustomComponent, ModalButton } from '@addins/core/core';
import { UPLOAD_FEEDBACK_TOKEN } from '../../schema/injection-token';
import { IUploadFeedback } from '../../schema/interfaces/IUploadFeedback';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-feedback',
  templateUrl: './upload-feedback.component.html',
  styleUrls: ['./upload-feedback.component.scss']
})
export class UploadFeedbackComponent implements OnInit, CustomComponent<string> {
  public get useCustomData(): boolean {
    return false;
  }

  get statusText(): string {
    return this.uploadFeedbackService.getStatus();
  }

  constructor(@Inject(UPLOAD_FEEDBACK_TOKEN) private readonly uploadFeedbackService: IUploadFeedback) {}

  ngOnInit() {}

  public getResult(button: ModalButton): string {
    return '';
  }
}
