<aside>
  <ion-buttons class="top">
    <app-permanent-menu-item *ngFor="let menu of menus" [item]="menu" [size]="menuIconSize"></app-permanent-menu-item>
  </ion-buttons>

  <ion-buttons class="bottom">
    <ion-button
      *ngIf="statusViewTool && statusViewTool.isVisible"
      (click)="statusViewTool.button.click($event)"
      fill="clear"
      color="primary"
    >
      <ion-icon [name]="statusViewTool.button.icon" [size]="menuIconSize"></ion-icon>
    </ion-button>
  </ion-buttons>
</aside>
