import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileStorageService {
  constructor(private readonly http: HttpClient) {}

  save(profile: any): Observable<boolean> {
    return of(false);
  }
}
