import proj from 'ol/proj';
import { StaticMapOption } from './schema/interfaces/static-map-options';
import { Coordinate } from '@models/coordinate';

export { StaticMapOption };

function fromProjection(coordinate: Coordinate): string {
  return /^epsg:/i.test(coordinate.epsg) ? coordinate.epsg.toUpperCase() : 'EPSG:' + coordinate.epsg;
}

export function transformOL(position: ol.Coordinate, fromProj: any, toProjection: any): ol.Coordinate {
  const from = proj.get(fromProj);
  if (!from) {
    console.error('unknown projection: ', fromProj);
    return;
  }

  const to = proj.get(toProjection);
  if (!to) {
    console.error('unknown projection: ', toProjection);
    return;
  }

  return proj.transform(position, from as any, to as any);
}

export function transform(coordinate: Coordinate, epsg: string): Coordinate {
  const fromProj: any = fromProjection(coordinate);
  const toProj: any = epsg;
  const pos = [coordinate.x, coordinate.y];
  const newPos = transformOL(pos as ol.Coordinate, fromProj, toProj);
  if (newPos) {
    return {
      x: newPos[0],
      y: newPos[1],
      epsg: epsg
    };
  }
}

export function coordToPosition(coordinate: Coordinate): ol.Coordinate {
  const fromProj: any = fromProjection(coordinate);
  const toProj: any = 'EPSG:4326';
  const from = proj.get(fromProj);
  const to = proj.get(toProj);

  if (!from) {
    console.error('unknown projection: ', fromProj);
    return;
  }

  if (!to) {
    console.error('unknown projection: ', toProj);
    return;
  }

  let pos = [coordinate.x, coordinate.y];
  return proj.transform(<ol.Coordinate>pos, <any>from, <any>to);
}

export function getStaticMapUrl(coordinate: Coordinate, options: StaticMapOption): string {
  options = Object.assign(
    {
      height: 400,
      width: 640,
      zoom: 17,
      color: 'black'
    },
    options
  );
  let api_key, api_id, staticUrl;
  api_id = 'FtuE1HjPpablJmlJ2zeA';
  api_key = 'eJa6dATXCiXtiRNE3fc8Ww';
  staticUrl =
    'https://image.maps.api.here.com/mia/1.6/mapview?app_id=%ID%&app_code=%KEY%&lat=%LAT%&lon=%LONG%&vt=2&z=%ZOOM%&h=%HEIGHT%&w=%WIDTH%';
  /*
  //// @ifdef GOOGLESTATIC
  //    api_key = 'AIzaSyCrXDwlVGPsDmdcKnbAzpIFUZ5UjTmXUQU';
  //    staticUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=%LAT%,%LONG%&zoom=%ZOOM%&key=%KEY%&size=%WIDTH%x%HEIGHT%&markers=color:%COLOR%%7C%LAT%,%LONG%';
  //// @endif
  //// @ifndef GOOGLESTATIC
  //    staticUrl = "/OSM/staticmap.php?center=%LAT%,%LONG%&zoom=%ZOOM%&size=%WIDTH%x%HEIGHT%";
  //    api_key = '';
  //// @endif
  //// @if DEBUG
  //    staticUrl = "http://staticmap.openstreetmap.de/staticmap.php?center=%LAT%,%LONG%&zoom=%ZOOM%&size=%WIDTH%x%HEIGHT%";
  //    // staticUrl = "http://tyler-demo.herokuapp.com/?lat=%LAT%&lon=%LONG%&width=%WIDTH%&height=%HEIGHT%&zoom=%ZOOM%";
  //    api_key = '';
  //// @endif
  */

  let position = coordToPosition(coordinate);
  return staticUrl
    .replace(/%LONG%/g, <any>position[0])
    .replace(/%LAT%/g, <any>position[1])
    .replace(/%WIDTH%/g, options.width.toFixed(0))
    .replace(/%HEIGHT%/g, options.height.toFixed(0))
    .replace(/%ZOOM%/g, options.zoom.toFixed(0))
    .replace(/%COLOR%/g, options.color.replace('#', '0x'))
    .replace(/%KEY%/g, api_key)
    .replace(/%ID%/g, api_id);
}
