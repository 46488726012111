import { Observable, Subject } from 'rxjs';
import { ICallcardCustomSelectTool, ToolColor, ToolExpand, ToolFill, ToolSize, ToolType } from './interfaces/ICallcardButtonTool';
import { IGuidSelection } from './interfaces/guid-selection';

export abstract class CallcardGuidSelectTool implements ICallcardCustomSelectTool<IGuidSelection> {
  color: ToolColor;
  expand: ToolExpand;
  fill: ToolFill = ToolFill.clear;
  size: ToolSize;
  isDisabled: boolean;

  get type(): ToolType {
    return ToolType.select;
  }

  position: number;

  protected _values: any[];
  abstract get values();

  protected readonly _onClick = new Subject<IGuidSelection>();
  get onChange(): Observable<IGuidSelection> {
    return this._onClick.asObservable();
  }

  protected constructor(public text: string, public icon: string, public show = true) {}

  abstract change(value: any);
}
