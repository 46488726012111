import { Actor } from '@models/imported/SagaSchema/Actor';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { ListItem } from './list-item';

export type UnitTabType = Vehicle | Actor | CommunicationEquipment;

export interface ITabState {
  type: string;
  searchText: string;
  offset: number;
  actualList: ListItem<UnitTabType>[];
}

export class UnitEditor {
  public readonly vehicles: Vehicle[] = [];
  public readonly actors: Actor[] = [];
  public readonly equipments: CommunicationEquipment[] = [];
  public readonly tabsStates: ITabState[] = [];

  public name: string = '';

  public mainResource: SagaObject = null;

  public agent: Actor = null;

  public position: SagaObject = null;

  public communications: CommunicationEquipment = null;
}
