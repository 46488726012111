import { CommonAddinModule } from '@addins/common.module';
import { CoreModule } from '@addins/core/core';
import { HomeModule } from '@addins/core/home';
import { Injector, NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule, IonicCoreModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { VisualModule } from '../../../../modules/visual/visual.module';
import { CallcardDetailBaseComponent } from './components/callcard-detail-base/callcard-detail-base.component';
import { CallcardDetailButtonsHComponent } from './components/callcard-detail-buttons-h/callcard-detail-buttons-h.component';
import { CallcardDetailButtonsVComponent } from './components/callcard-detail-buttons-v/callcard-detail-buttons-v.component';
import { CallcardDetailButtonsComponent } from './components/callcard-detail-buttons/callcard-detail-buttons.component';
import { CallcardDetailCardComponent } from './components/callcard-detail-card/callcard-detail-card.component';
import { CallcardDetailCollapsibleComponent } from './components/callcard-detail-collapsible/callcard-detail-collapsible.component';
import { CallcardDetailContentComponent } from './components/callcard-detail-content/callcard-detail-content.component';
import { CallCardDetailFollowupComponent } from './components/callcard-detail-followup/callcard-detail-followup.component';
import { CallCardDetailHomeComponent } from './components/callcard-detail-home/callcard-detail-home.component';
import { CallcardDetailInfoComponent } from './components/callcard-detail-info/callcard-detail-info.component';
import { CallcardDetailMediaComponent } from './components/callcard-detail-media/callcard-detail-media.component';
import { CallCardDetailPopoverComponent } from './components/callcard-detail-popover/callcard-detail-popover.component';
import { CallcardMediaButtonsComponent } from './components/callcard-media-buttons/callcard-media-buttons.component';
import { CallcardPopupComponent } from './components/callcard-popup/callcard-popup.component';
import { CallcardLocationDirective } from './directives/location/callcard-location.directive';
import { CallcardTitleDirective } from './directives/title/callcard-title.directive';
import { EventPipe } from './pipes/event.pipe';
import { SINGLECALLCARD } from './schema/constants';
import { AddStampButtonsProviderService } from './services/add-stamp-buttons-provider/add-stamp-buttons-provider.service';
import { CallcardMenuControllerService } from './services/callcard-menu-controller/callcard-menu-controller.service';
import { CardPageService } from './services/card-page/card-page.service';
import { CardStampsPopoverService } from './services/card-stamps-popover/card-stamps-popover.service';
import { CardsProviderService } from './services/cards-provider/cards-provider.service';
import { CollapsibleItemsService } from './services/collapsible-items/collapsible-items.service';
import { CallcardMenuSetupService } from './services/executables/callcard-menu-setup/callcard-menu-setup.service';
import { CallcardOpenerService } from './services/executables/callcard-opener/callcard-opener.service';
import { CardPopupService } from './services/executables/popup/card-popup.service';
import { MediaProviderService } from './services/media-provider/media-provider.service';
import { ProfileParserService } from './services/profile-parser/profile-parser.service';
import { FullViewComponent } from './views/full-view/full-view.component';
import { LayoutViewComponent } from './views/layout-view/layout-view.component';
import { PageDetailViewComponent } from './views/page-view/page-detail-view.component';
import { CallCardSlideDetailViewComponent } from './views/slide-view/slide-detail-view.component';

const views = [PageDetailViewComponent, CallCardSlideDetailViewComponent, LayoutViewComponent, FullViewComponent];

const directives = [CallcardLocationDirective, CallcardTitleDirective];

const pipes = [EventPipe];

const components = [
  CallcardDetailBaseComponent,
  CallcardDetailButtonsHComponent,
  CallcardDetailButtonsVComponent,
  CallcardDetailContentComponent,
  CallCardDetailFollowupComponent,
  CallCardDetailHomeComponent,
  CallCardDetailPopoverComponent,
  CallcardDetailInfoComponent,
  CallcardDetailButtonsComponent,
  CallcardDetailCardComponent,
  CallcardDetailMediaComponent,
  CallcardDetailCollapsibleComponent,
  CallcardPopupComponent,
  CallcardMediaButtonsComponent,
  ...views
];

const executables = [CallcardMenuSetupService, CardPopupService, CallcardOpenerService];

const providers = [
  CardPageService,
  CardsProviderService,
  MediaProviderService,
  CardStampsPopoverService,
  CollapsibleItemsService,
  CallcardMenuControllerService,
  ProfileParserService,
  AddStampButtonsProviderService,
  ...executables
];

@NgModule({
  declarations: [components, ...directives, ...pipes],
  providers,
  imports: [CoreModule, IonicModule, SharedModule, SagaPipeModule, VisualModule, IonicCoreModule, CommonAddinModule, HomeModule],
  exports: [CallcardMediaButtonsComponent]
})
@Addin({
  name: SINGLECALLCARD,
  description: 'This module is used to manipulate a single card'
})
export class SingleCallCardModule extends AddinModule {
  constructor(public readonly injector: Injector) {
    super(injector, components, executables);
  }
}
