import { MyLocationService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { IFile } from '@ionic-native/file';
import { Coordinates } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { FileDescriptor } from '@models/imported/SagaSchema/FileDescriptor';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { SagaSettingsService } from '@services/settings';
import { UploadManagerService } from '@techwan/file-upload';
import { Observable } from 'rxjs';
import { IAttachmentQuery } from '../../schema/interfaces/IAttachmentQuery';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { IMakeVersionQuery } from '../../schema/interfaces/IMakeVersionQuery';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';
import { SagaFileUploadItem } from '../../schema/saga-file-upload-item';
import { AttachmentProxyService } from '../attachment-proxy/attachment-proxy.service';
import { UploadFeedbackService } from '../upload-feedback/upload-feedback.service';
import { UploadRepositoryService } from '../upload-repository/upload-repository.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private _settings: SagaSettingsService,
    private _platform: Platform,
    private _uploadRepository: UploadRepositoryService,
    private _uploadManager: UploadManagerService,
    private _attachmentProxy: AttachmentProxyService,
    private _uploadFeedback: UploadFeedbackService,
    private _user: RefreshTokenStorageService,
    private _myLocation: MyLocationService
  ) {}

  upload(customFile: ICustomFile, isMediaMessage: boolean = false): Promise<boolean> {
    return this._uploadFeedback
      .show()
      .then(() => this.moveFile(customFile))
      .then(customFile => this.createFileDescriptor(customFile))
      .then(fileDescriptor => this.createUploadItem(customFile, fileDescriptor))
      .then(uploadItem => this.createAttachment(customFile, uploadItem, isMediaMessage).then(() => uploadItem))
      .then(uploadItem => this.queueUploadItem(uploadItem))
      .then(uploadItem => this.startFileUpload(uploadItem))
      .catch(err => {
        console.error(err);
        this._uploadFeedback.onError();
        return false;
      });
  }

  private moveFile(customFile: ICustomFile): Promise<ICustomFile> {
    return this._platform.is('cordova') ? this._uploadRepository.moveFrom(customFile) : Promise.resolve(customFile);
  }

  private createFileDescriptor(customFile: ICustomFile): Promise<FileDescriptor> {
    this._uploadFeedback.onMakeVersion();
    return this._attachmentProxy.makeVersion(this.createMakeVersionArgs(customFile)).toPromise();
  }

  private createMakeVersionArgs(customFile: ICustomFile): IMakeVersionQuery {
    return {
      Owner: this._user.read().login.Claims.Visibility[0],
      Name: customFile.file.name,
      Size: customFile.file.size,
      Hash: null
    };
  }

  private createUploadItem(customFile: ICustomFile, fileDescriptor: FileDescriptor): SagaFileEntryUploadItem {
    return new SagaFileEntryUploadItem(customFile.file as IFile, fileDescriptor, customFile.parent);
  }

  private queueUploadItem(uploadItem: SagaFileUploadItem): SagaFileUploadItem {
    this._uploadManager.queue(uploadItem, false);
    return uploadItem;
  }

  private createAttachment(customFile: ICustomFile, uploadItem: SagaFileUploadItem, isMediaMessage: boolean): Promise<any> {
    this._uploadFeedback.onCreate();

    const attachmentData: IAttachmentQuery = this.createAttachmentArgs(customFile, uploadItem);
    const createAttachmentObs: Observable<any> = isMediaMessage
      ? this._attachmentProxy.createMediaMessage(attachmentData)
      : this._attachmentProxy.createAttachment(attachmentData);

    return createAttachmentObs.toPromise();
  }

  private createAttachmentArgs(customFile: ICustomFile, uploadItem: SagaFileUploadItem): IAttachmentQuery {
    const coordinates: Coordinates = this._myLocation.mostRecent.coords;
    return {
      parentId: customFile.parent,
      dataType: customFile.dataType,
      filename: customFile.file.name,
      size: customFile.file.size,
      text: customFile.text,
      title: customFile.file.name,
      thumbnail: customFile.thumbnail,
      url: uploadItem.url,
      x: coordinates.longitude,
      y: coordinates.latitude,
      z: 0
    };
  }

  private startFileUpload(uploadItem: SagaFileUploadItem): Promise<boolean> {
    const maxFileSize: number = this._settings.getValue('SagaMobileWebClient.MaxFileSizeBeforeConfirmUpload');
    if (uploadItem.size <= maxFileSize) {
      uploadItem.start();
      return this._uploadFeedback.dismiss();
    } else {
      return this._uploadFeedback.confirmBigFileUpload().then(confirmed => {
        if (confirmed) {
          uploadItem.start();
        }
        return confirmed;
      });
    }
  }
}
