import { SingleCallCardModule } from '@addins/core/singlecallcard';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { VisualModule } from '../../../../modules/visual/visual.module';
import { CallcardDetailCAnimalComponent } from './components/callcard-detail-canimal/callcard-detail-canimal.component';
import { CallcardDetailCObjectComponent } from './components/callcard-detail-cobject/callcard-detail-cobject.component';
import { CallcardDetailCPersonPCEComponent } from './components/callcard-detail-cperson-pce/callcard-detail-cperson-pce.component';
import { CallcardDetailCPersonRegularComponent } from './components/callcard-detail-cperson-regular/callcard-detail-cperson-regular.component';
import { CallcardDetailCPersonComponent } from './components/callcard-detail-cperson/callcard-detail-cperson.component';
import { CallcardDetailCVehicleComponent } from './components/callcard-detail-cvehicle/callcard-detail-cvehicle.component';
import { CallcardDetailParticularsComponent } from './components/callcard-detail-particulars/callcard-detail-particulars.component';
import { SINGLE_CALLCARD_PARTICULARS } from './schema/constants';
import { SinglecallcardDetailCardService } from './services/executables/singlecallcard-detail-card/singlecallcard-detail-card.service';
import { SingleCallcardParticularsControllerService } from './services/single-callcard-particular-controller/single-callcard-particulars-controller.service';

const views = [
  CallcardDetailCPersonRegularComponent,
  CallcardDetailCVehicleComponent,
  CallcardDetailCPersonPCEComponent,
  CallcardDetailCAnimalComponent,
  CallcardDetailCObjectComponent
];

const components = [CallcardDetailParticularsComponent, CallcardDetailCPersonComponent, ...views];

const directives = [];

const executables = [SinglecallcardDetailCardService];

const providers = [SingleCallcardParticularsControllerService, ...executables];

@Addin({
  name: SINGLE_CALLCARD_PARTICULARS
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [IonicModule, CommonModule, SingleCallCardModule, VisualModule, SagaDirectivesModule, TranslateModule, SagaPipeModule]
})
export class SinglecallcardParticularsModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
