import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { TranslationListComponent } from '../../../components/translation-list/translation-list.component';

@AddinExecute({
  name: 'MenuAccessTool'
})
@Injectable()
export class MenuAccessToolService extends Execute {
  private menuEntry = new MenuEntry('static.translations', 'flag', TranslationListComponent);

  constructor(private sideMenu: SideMenuService) {
    super();
  }

  execute(): void {
    this.sideMenu.addMenuEntry(this.menuEntry);
  }
}
