import { Component, Input } from '@angular/core';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'list-name-selector.component.html'
})
export class ListNameSelectorComponent {
  constructor(private modalCtrl: ModalController) {}

  @Input() listNames: ListNameValue[];
  @Input() title: string;
  @Input() displayField: (listName: ListNameValue) => string;

  close(listName?: ListNameValue) {
    this.modalCtrl.dismiss(listName);
  }

  getDisplayField(listName: ListNameValue) {
    if (this.displayField) {
      return this.displayField(listName);
    } else {
      return listName.DisplayValue;
    }
  }
}
