import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PopupBaseComponent, PopupProviderService } from '@addins/core/map';
import { CacheService } from '@services/cache/cache.service';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { IChildAwareResource } from '../../schema/interfaces/IChildAwareResource';
import { UnitResourceProviderService } from '../../services/unit-resource-provider/unit-resource-provider.service';
import { filter } from 'rxjs/operators';
import { CacheEvent } from '@services/cache/cacheEvent';

@Component({
  selector: 'app-unit-activity-popup',
  templateUrl: './unit-activity-popup.component.html',
  styleUrls: ['./unit-activity-popup.component.scss']
})
export class UnitActivityPopupComponent extends PopupBaseComponent implements OnInit, OnDestroy {
  public unit?: UnitActivity = null;
  private _guid?: string = null;

  public resources: IChildAwareResource[];

  constructor(
    _popup: PopupProviderService,
    _e: ElementRef,
    private readonly _cache: CacheService,
    private readonly unitResources: UnitResourceProviderService
  ) {
    super(_popup, _e);
  }

  /**
   * This method is called as soon as this component is rendered.
   * See also: MapPopupComponent
   */
  public ngOnInit() {
    super.ngOnInit();

    // Fetch the unit which has been linked to the feature by its id.
    this.init();

    // Listen for updates on the unit.
    this._subs.push(this._cache.$events.pipe(filter(e => e.object === this.unit)).subscribe(e => this.update(e)));
    this._subs.push(this._popup.$change.subscribe(() => this.init()));
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this._guid = null;
  }

  /**
   * This method is called when the popup is shown and when it has changed.
   */
  protected init() {
    this.unit = this._popup.content.length > 0 ? this._cache.getObjectByUid(this._popup.content[0].feature.get('id')) : null;

    if (this.unit instanceof UnitActivity && this.unit.ObjGuid !== this._guid) {
      this._guid = this.unit.ObjGuid;
      this.refresh();
    }
  }

  protected refresh() {
    this.unitResources.getResourcesFor(this.unit.ObjGuid).subscribe(res => (this.resources = res));
  }

  protected update(e: CacheEvent) {
    if ((e.object as UnitActivity).CallCardID !== this._guid) {
      this._guid = null;
      this.close(this._e.nativeElement);
    }
  }
}
