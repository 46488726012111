import { Injectable } from '@angular/core';
import { StatusChangeResult as BaseStatusChangeResult, StatusItemAction } from '@models/imported/SagaBase';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { ImagesSettingsService } from '@services/settings';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { StatusService } from '@services/status/status.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StatusChangeResult } from '../../schema/interfaces/status-change-result';
import { StatusImageService } from '../status-images/status-image.service';
import { StatusTimeService } from '../status-time/status-time.service';

@Injectable()
export class StatusListService {
  private readonly _ready = new BehaviorSubject(false);
  get $ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  private _mobileStatuses: ListResourceStatus[] = [];
  get mobileStatuses(): ListResourceStatus[] {
    return this._mobileStatuses;
  }

  private _myUnit: UnitActivity = null;
  get myUnit(): UnitActivity {
    return this._myUnit;
  }

  private readonly _change = new Subject<StatusChangeResult>();
  get $change(): Observable<StatusChangeResult> {
    return this._change.asObservable();
  }

  get isReady(): boolean {
    return this._ready.value;
  }

  private _sub: Subscription = null;

  private _status: ListResourceStatus = null;

  constructor(
    private statusService: StatusService,
    private myEquipmentService: MyEquipmentService,
    private cacheService: CacheService,
    private imagesSettingsService: ImagesSettingsService,
    private statusImageService: StatusImageService,
    private statusTimeService: StatusTimeService
  ) {
    combineLatest([
      this.myEquipmentService.myUnit.pipe(filter(myUnit => myUnit !== null)),
      this.cacheService.state.pipe(map(cacheState => cacheState === CacheState.ready))
    ]).subscribe(myUnit => this.onInit(myUnit));
  }

  change(status: ListResourceStatus, statusParameters?: StatusParameters) {
    this._change.next({ inProgress: true });
    this.statusService
      .changeStatus(status, this.myUnit.callcard, statusParameters)
      .then(result => this.parse(result))
      .catch(error => this.handleError(error));
  }

  private parse(res: BaseStatusChangeResult) {
    this._change.next({ inProgress: false, res });
  }

  private handleError(error) {
    this._change.next({ inProgress: false, error });
  }

  // Rename or refactor this part
  private onInit([myUnit, isBooted]) {
    const isReady = this.isReady;
    if (isBooted && !isReady) {
      this.setup(myUnit);
    } else if (isReady) {
      this.cleanup();
    }
  }

  private cleanup() {
    this._myUnit = null;
    this._status = null;
    this._sub.unsubscribe();
    this._sub = null;
    this._ready.next(false);
  }

  private setup(unit) {
    this._myUnit = this.cacheService.getObjectByUid((unit as UnitActivity).ObjGuid);
    this._status = this._myUnit.status;
    this._sub = this._myUnit.$changed.subscribe(() => this.refresh());
    this._mobileStatuses = this.statusService.getAllResourceStatus({ mobile: true });
    this.loadImage();
    this._ready.next(true);
  }

  private loadImage(): void {
    const defaultStatusImage = this.imagesSettingsService.get('StatusDefault') || null;
    this.statusImageService.clear();
    if (defaultStatusImage !== null) {
      this.statusImageService.setDefault(defaultStatusImage);
    }
    this._mobileStatuses.forEach(status => {
      const statusImage = this.imagesSettingsService.get(`Status_${status.Value}`) || null;
      if (statusImage !== null) {
        this.statusImageService.set(status, statusImage);
      }
    });
  }

  private refresh() {
    const statusChanged: boolean = this._myUnit && this._status !== this._myUnit.status;
    if (statusChanged) {
      const previousStatus: ListResourceStatus = this._status;
      const isRequestAssignmentStatus: ListResourceStatus = this.statusService.getStatusByAction(StatusItemAction.isRequestAssignment);

      this._status = this._myUnit.status;
      if (this.isAssignment(this._status) && previousStatus !== isRequestAssignmentStatus) {
        this.statusTimeService.reset();
      }
    }
  }

  private isAssignment(status: ListResourceStatus): boolean {
    return (
      status === this.statusService.getStatusByAction(StatusItemAction.isRequestAssignment) ||
      status === this.statusService.getStatusByAction(StatusItemAction.isAssignement) ||
      status === this.statusService.getStatusByAction(StatusItemAction.isReassignment)
    );
  }
}
