import { CurrentCardService } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { CObject } from '@models/imported/SagaSchema/CObject';
import { CacheService } from '@services/cache/cache.service';
import { AddinView } from '@techwan/ionic-core';
import { CardParticularsWrapper } from '../../schema/card-particulars-wrapper';
import { CallcardDetailParticularsComponent } from '../callcard-detail-particulars/callcard-detail-particulars.component';

@Component({
  selector: 'app-callcard-detail-cobject',
  templateUrl: './callcard-detail-cobject.component.html',
  styleUrls: ['./callcard-detail-cobject.component.scss']
})
@AddinView({
  name: 'CallcardDetailCObject'
})
export class CallcardDetailCObjectComponent extends CallcardDetailParticularsComponent<CObject> implements OnInit {
  readonly cObjectWrappers: CardParticularsWrapper<CObject>[] = [];

  constructor(_currentCardService: CurrentCardService, _cacheService: CacheService) {
    super(_currentCardService, _cacheService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.callcard) {
      this.createWrappersOf(CObject).forEach(cObject => this.cObjectWrappers.push(cObject));
      this._cacheSubscriptions.push(this.getFilteredCacheSubscriptionForClassName(CObject.$t));
    }
  }

  protected onAdd(particular: CObject) {
    this.cObjectWrappers.push(new CardParticularsWrapper<CObject>(particular));
  }

  protected onRemove(particular: CObject) {
    const existingWrapper = this.cObjectWrappers.find(wrapper => wrapper.wrappedParticular === particular) || null;
    if (existingWrapper !== null) {
      this.cObjectWrappers.splice(this.cObjectWrappers.indexOf(existingWrapper), 1);
    }
  }
}
