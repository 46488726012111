<ion-item>
  <ion-icon name="warning" slot="start" size="large"></ion-icon>
  <ion-label class="ion-text-wrap">
    <h2 *ngFor="let item of rLong">{{ item }}</h2>
  </ion-label>
</ion-item>

<ion-accordion-group *ngIf="callcard.event.XmlTopicsQuestions">
  <ion-accordion toggleIconSlot="start">
    <ion-item slot="header" color="light">
      <ion-label>
        <strong translate>Mobile.CXMLTopics</strong>
      </ion-label>
    </ion-item>
    <div slot="content">
      <app-xml-topics [xmlTopics]="callcard.event.XmlTopics"></app-xml-topics>
    </div>
  </ion-accordion>
</ion-accordion-group>
