// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:22
import { CommunicationEquipment_abstract } from './../abstract/SagaSchema/CommunicationEquipment_abstract';
import { ActivityStatusType } from '@models/imported/SagaBase/Interfaces';
import { Color } from '@models/color';
import { CacheChangedInterface } from '@services/cache/cache.service';
export class CommunicationEquipment extends CommunicationEquipment_abstract {
  private _color: Color;

  static formatImei(imei: string) {
    const regex = /^(\d{2})(\d{6})(\d{6})(\d)$/;
    if (regex.test(imei)) {
      return imei.replace(regex, '$1-$2-$3-$4');
    }
    return imei;
  }

  get fullCallsign(): string {
    let suffix = this.Description || '';
    suffix = suffix.trim();
    if (suffix.length > 0) {
      suffix = ' / ' + suffix;
    }
    return this.Name + suffix;
  }

  get status() {
    return null;
  }

  get name() {
    return this.Identifier || this.rShort();
  }

  get text() {
    return this.rShort();
  }

  get icon() {
    return 'phone-portrait';
  }

  get color() {
    return this._color;
  }

  rShort() {
    let suffix = this.Description || '';
    suffix = suffix.trim();
    if (suffix.length > 0) {
      suffix = ' / ' + suffix;
    }
    return this.Name + suffix;
  }

  changed(changed: CacheChangedInterface) {
    switch (this.ActivityStatus) {
      case ActivityStatusType.Unavailable:
        this._color = new Color('black');
        break;
      case ActivityStatusType.Available:
        this._color = new Color('green');
        break;
      case ActivityStatusType.Active:
        this._color = new Color('blue');
        break;
    }
  }
}
