<ion-item
  *ngIf="!horizontal"
  class="status-color"
  [class.my-status]="isMyStatus"
  [class.allowed]="isAllowed"
  (click)="onStatusClicked($event)"
>
  <ion-label class="ion-text-center">
    <h2 [style.font-size]="statusNameFontSize">{{ statusName | translate }}</h2>
    <h2 [style.font-size]="statusTimeFontSize">{{ getTime() }}</h2>
  </ion-label>
</ion-item>

<div
  *ngIf="horizontal"
  class="status-item status-color"
  [class.my-status]="isMyStatus"
  [class.allowed]="isAllowed"
  [class.horizontal]="horizontal"
  (click)="onStatusClicked($event)"
>
  <div class="status-name small-items" [translate]="statusName" [style.font-size]="statusNameFontSize"></div>
  <div>
    <app-icon *ngIf="showIcon" [icon]="statusIcon" maxWidth="48px"></app-icon>
  </div>
  <div class="status-time small-items" [style.font-size]="statusTimeFontSize">{{ getTime() }}</div>
</div>
