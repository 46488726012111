import { Injectable } from '@angular/core';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction } from '@services/cache/cacheEvent';
import { IModelService, IObjectChange } from '@services/model-factory';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';

@Injectable({
  providedIn: 'root'
})
export class UnitActivityMdpModelService implements IModelService<UnitActivityMDP> {
  constructor(private cache: CacheService) {}

  change(change: IObjectChange<UnitActivityMDP>) {
    const unitMdp: UnitActivityMDP = change.target;

    if (change.action === CacheAction.added && unitMdp.task) {
      // add unit was called after modify unit at bootcache creation
      unitMdp.TaskID = unitMdp.task.ObjGuid;
    }
    unitMdp.task = this.cache.getObjectByUid(unitMdp.TaskID);

    unitMdp.changed(null);
  }
}
