import { Injectable } from '@angular/core';
import { INativePreferences } from '@app/services';
import { ApplicationPreferences } from '@techwan/ionic-plugin-preferences';
import { Observable, Subject } from 'rxjs';

interface Listener {
  subject: Subject<any>;
  observable?: Observable<any>;
}

@Injectable()
export class NativePreferencesService implements INativePreferences {
  private readonly _listeners = new Map<string, Listener[]>();

  constructor() {}

  show(): Promise<void> {
    return ApplicationPreferences.show();
  }

  fetch(key: string, withValues?: boolean): Promise<any> {
    return ApplicationPreferences.fetch({
      name: key,
      withValues: withValues
    });
  }

  store(key: string, value: any): Promise<any> {
    return ApplicationPreferences.store({ name: key, value: value });
  }

  listenTo(name: string): Observable<any> {
    const subject: Listener = { subject: new Subject() };
    if (!this._listeners.has(name)) {
      this._listeners.set(name, [subject]);
      ApplicationPreferences.addListener(name, evt => this._listeners.get(name).forEach(item => item.subject.next(evt)));
    } else {
      this._listeners.get(name).push(subject);
    }
    return (subject.observable = subject.subject.asObservable());
  }

  stopListeningTo(name: string, target?: Observable<any>): Promise<any> {
    return this._listeners.has(name)
      ? ApplicationPreferences.stopListeningTo({ key: name }).then(() => {
          if (target) {
            this.remove(this._listeners.get(name), target);
          }
          if (this._listeners.get(name).length === 0) {
            this._listeners.delete(name);
          }
        })
      : Promise.resolve();
  }

  private remove(list: Listener[], target: Observable<any>) {
    const item = list.find(i => i.observable === target);
    if (item) {
      list.splice(list.indexOf(item), 1);
    }
  }

  removeListeners(): Promise<void> {
    return ApplicationPreferences.removeAllListeners();
  }
}
