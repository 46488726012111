<ion-item-group>
  <ion-item-divider>
    <ion-label translate>Mobile.GenericSettings</ion-label>
  </ion-item-divider>

  <ion-item>
    <ion-label translate="Mobile.DarkModeEnabled" (click)="darkModeToggle.checked = !darkModeToggle.checked"></ion-label>
    <ion-toggle #darkModeToggle [checked]="darkMode" (ionChange)="onDarkModeChange($event)"></ion-toggle>
  </ion-item>

  <ion-item>
    <ion-label translate="Mobile.LargeFontEnabled" (click)="largeFontEnabledToggle.checked = !largeFontEnabledToggle.checked"></ion-label>
    <ion-toggle #largeFontEnabledToggle [checked]="largeFontEnabled" (ionChange)="onLargeFontEnabledChange($event)"></ion-toggle>
  </ion-item>

  <ion-item lines="none">
    <ion-label translate="Mobile.LargeMenuEnabled" (click)="largeMenuEnabledToggle.checked = !largeMenuEnabledToggle.checked"></ion-label>
    <ion-toggle #largeMenuEnabledToggle [checked]="largeMenuEnabled" (ionChange)="onLargeMenuEnabledChange($event)"></ion-toggle>
  </ion-item>
</ion-item-group>
