import { CurrentCardService } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { MediaProviderService } from '../../services/media-provider/media-provider.service';

@Component({
  selector: 'app-callcard-detail-media',
  templateUrl: './callcard-detail-media.component.html',
  styleUrls: ['./callcard-detail-media.component.scss']
})
export class CallcardDetailMediaComponent implements OnInit {
  private _provider: ToolsProvider = null;

  get tools(): Tool[] {
    return (this._provider && this._provider.tools) || [];
  }

  get hasItems(): boolean {
    return this.mediaProvider.count() > 0;
  }

  constructor(
    private current: CurrentCardService,
    private toolsProvider: ToolsProviderService,
    private mediaProvider: MediaProviderService
  ) {}

  ngOnInit() {
    this._provider = this.toolsProvider.getToolsForContext(CallCard);
  }

  open(event): void {
    this.mediaProvider.open(this.current.current);
  }
}
