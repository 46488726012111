import { Component, ComponentRef, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomComponent } from '../../schema/interfaces/custom-component';
import { ModalButton } from '../../schema/interfaces/modal-button';
import { ModalButtonImpl } from '../../schema/modal-button-impl';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit, OnDestroy {
  readonly title: string;
  readonly buttons: ModalButtonImpl[];

  /**
   * Custom content
   */
  readonly content: Type<any>;
  readonly data?: any;

  @ViewChild('content', { read: ViewContainerRef, static: true })
  private _content: ViewContainerRef;

  private readonly _subs: Subscription[] = [];
  private _component: CustomComponent<any> = null;

  constructor(private readonly _modal: ModalController) {}

  ngOnInit() {
    if (this._content && this.content) {
      this.setValues(this._content.createComponent(this.content));
    }

    if (this.buttons) {
      this.buttons.forEach(b => this._subs.push(b.$event.subscribe(() => this.onEvent(b))));
    }
  }

  ngOnDestroy() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private setValues(comp: ComponentRef<any>) {
    this._component = comp.instance;
    if (this.data) {
      Object.keys(this.data).forEach(k => (comp.instance[k] = this.data[k]));
    }
  }

  private onEvent(button: ModalButton) {
    this._modal.dismiss(this._component.useCustomData ? this._component.getResult(button) : button.action);
  }
}
