// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @28.08.2017 15:22:23
import { ResourcePositionLight_abstract } from './../abstract/SagaBase/ResourcePositionLight_abstract';
import { Coordinate } from '../../coordinate';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { ActivityStatusType } from './Interfaces/index';
import { ResourceType } from './index';

export class ResourcePositionLight extends ResourcePositionLight_abstract {
  get coordinate(): Coordinate {
    return {
      x: this.X,
      y: this.Y,
      epsg: this.CoordEpsgCode
    };
  }

  private _status: ListResourceStatus;

  get status(): ListResourceStatus {
    return this._status;
  }

  set status(status: ListResourceStatus) {
    this._status = status;
    this.ActivityStatus = status.Value;
  }

  changed(changed: CacheChangedInterface) {
    let row = changed.fetchList.getRow(ListResourceStatus, this.ActivityStatus);
    if (row != null) {
      this._status = row;
    }
  }

  public getOLCoordinate(): ol.Coordinate {
    return <ol.Coordinate>(this.coordinate.x ? [this.coordinate.x, this.coordinate.y] : null);
  }

  rShort(): string {
    return this.Name;
  }

  // get color():Color {
  //     if(!this._color) {
  //         this._color = new Color(this.FColor);
  //     }
  //     return this._color;
  // }

  get className() {
    return (
      this.$t.replace(/\./g, '_') +
      ' ' +
      (this.ActivityStatus == ActivityStatusType.Active ? 'Activity' : '') +
      ' ResourceType_' +
      ResourceType[this.ResTypeConst]
    );
  }
}
