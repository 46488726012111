import { Injectable } from '@angular/core';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';

@Injectable()
export class StatusImageService {
  private readonly _icons = new Map<ListResourceStatus, string>();

  private _default: string = null;

  constructor() {}

  public clear() {
    this._icons.clear();
  }

  public get(item: ListResourceStatus): string {
    return this._icons.get(item) || this._default;
  }

  public isEmpty(): boolean {
    return this._icons.size === 0;
  }

  public set(item: ListResourceStatus, image: string) {
    this._icons.set(item, image);
  }

  public setDefault(value: string) {
    this._default = value;
  }
}
