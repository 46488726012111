import { CurrentCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ICallcardButtonEvent,
  ICallcardButtonTool,
  ICallcardSelectTool,
  ICallcardTool,
  ToolType
} from '../../schema/interfaces/ICallcardButtonTool';

@Injectable({
  providedIn: 'root'
})
export class ButtonsProviderService {
  private _buttons: ICallcardTool[] = [];
  get buttons(): ICallcardTool[] {
    return this._buttons;
  }

  constructor(private readonly current: CurrentCardService) {}

  add(button: ICallcardTool): Observable<ICallcardButtonEvent> {
    this._buttons.push(button);
    this._buttons.sort((a, b) => a.position - b.position);

    switch (button.type) {
      case ToolType.button:
        return (button as ICallcardButtonTool).onClick.pipe(tap((event: ICallcardButtonEvent) => (event.target = this.current.current)));
      case ToolType.select:
        return (button as ICallcardSelectTool).onChange.pipe(tap((event: ICallcardButtonEvent) => (event.target = this.current.current)));
    }
  }

  remove(button: ICallcardTool): void {
    const idx = this._buttons.indexOf(button);
    if (idx > -1) {
      this._buttons.splice(idx, 1);
    }
  }
}
