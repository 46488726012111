import { PopupProviderService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { DeploymentPopupComponent } from '../../components/deployment-popup/deployment-popup.component';

export enum DeploymenPointOperation {
  added,
  modified,
  removed,
  callcardChanged
}

export interface DeploymentPointEvent {
  operation: DeploymenPointOperation;
  deploymentPoint: CDeploymentPoint;
}

@Injectable()
export class DeploymentPointsProviderService {
  private readonly _cardDeployments: CDeploymentPoint[] = [];
  get cardDeployments() {
    return this._cardDeployments;
  }

  private readonly _change = new Subject<DeploymentPointEvent>();
  get $change(): Observable<DeploymentPointEvent> {
    return this._change.asObservable();
  }

  private _cacheSub: Subscription = null;

  private _myCallcardId: string = null;

  constructor(private _cache: CacheService, private _myUnit: MyUnitService, private _popup: PopupProviderService) {}

  setup(): void {
    this._popup.addComponent(CDeploymentPoint, DeploymentPopupComponent);
    if (this._cache.isReady()) {
      this.onCacheReady();
    } else {
      this._cache.state
        .pipe(
          filter(cacheState => cacheState === CacheState.ready),
          first()
        )
        .subscribe(() => this.onCacheReady());
    }
  }

  private onCacheReady(): void {
    this._myUnit.$change.pipe(filter(() => this._myCallcardId !== this._myUnit.myCardId)).subscribe(() => this.onCallcardChanged());
    this.onCallcardChanged();
  }

  private onCallcardChanged(): void {
    this._myCallcardId = this._myUnit.myCardId;

    if (this._myUnit.isEngaged()) {
      if (this._cacheSub === null) {
        this._cacheSub = this._cache
          .listenForChange(CDeploymentPoint)
          .pipe(filter(cacheEvent => this.isForMyCard(cacheEvent.object as CDeploymentPoint)))
          .subscribe(cacheEvent => this.onCacheEvent(cacheEvent));
      }

      this.getMyCardDeployments();
    } else if (!this._myUnit.isEngaged() && this._cacheSub !== null) {
      this._cacheSub.unsubscribe();
      this._cacheSub = null;
      this._cardDeployments.length = 0;
    }

    this._change.next({ operation: DeploymenPointOperation.callcardChanged, deploymentPoint: null });
  }

  private isForMyCard(deploymentPoint: CDeploymentPoint): boolean {
    return deploymentPoint.CallCardId === this._myUnit.myCardId;
  }

  private isMine(deploymentPoint: CDeploymentPoint): boolean {
    return deploymentPoint.UnitUids && deploymentPoint.UnitUids.indexOf(this._myUnit.mine.ObjGuid) > -1;
  }

  private getMyCardDeployments(): void {
    this._cardDeployments.length = 0;
    const myCardDeploymentPoints = this._cache
      .getListByType(CDeploymentPoint)
      .filter((deploymentPoint: CDeploymentPoint) => this.shouldAddTheDeploymentPoint(deploymentPoint));
    if (myCardDeploymentPoints && myCardDeploymentPoints.length > 0) {
      myCardDeploymentPoints.forEach(deploymentPoint => this._cardDeployments.push(deploymentPoint));
    }
  }

  private onCacheEvent(cacheEvent: CacheEvent): void {
    const deploymentPoint = cacheEvent.object as CDeploymentPoint;

    switch (cacheEvent.action) {
      case CacheAction.added:
        this.onDeploymentPointAdded(deploymentPoint);
        break;

      case CacheAction.modified:
        this.onDeploymentPointModified(deploymentPoint);
        break;

      case CacheAction.removed:
        this.onDeploymentPointRemoved(deploymentPoint);
        break;
    }
  }

  private onDeploymentPointAdded(deploymentPoint: CDeploymentPoint) {
    if (this.shouldAddTheDeploymentPoint(deploymentPoint)) {
      this._cardDeployments.push(deploymentPoint);
      this._change.next({ operation: DeploymenPointOperation.added, deploymentPoint });
    }
  }

  private onDeploymentPointModified(deploymentPoint: CDeploymentPoint) {
    let operation: DeploymenPointOperation = null;

    if (this.shouldRemoveTheDeploymentPoint(deploymentPoint)) {
      this._cardDeployments.splice(this._cardDeployments.indexOf(deploymentPoint), 1);
      operation = DeploymenPointOperation.removed;
    } else if (this.shouldAddTheDeploymentPoint(deploymentPoint)) {
      this._cardDeployments.push(deploymentPoint);
      operation = DeploymenPointOperation.added;
    } else if (!this.isMine(deploymentPoint)) {
      operation = DeploymenPointOperation.modified;
    }

    if (operation !== null) {
      this._change.next({ operation, deploymentPoint });
    }
  }

  private shouldRemoveTheDeploymentPoint(deploymentPoint: CDeploymentPoint): boolean {
    return this._cardDeployments.indexOf(deploymentPoint) > -1 && this.isMine(deploymentPoint);
  }

  private shouldAddTheDeploymentPoint(deploymentPoint: CDeploymentPoint): boolean {
    return this._cardDeployments.indexOf(deploymentPoint) === -1 && this.isForMyCard(deploymentPoint) && !this.isMine(deploymentPoint);
  }

  private onDeploymentPointRemoved(deploymentPoint: CDeploymentPoint) {
    const index = this._cardDeployments.indexOf(deploymentPoint);
    if (index > -1 && this.isForMyCard(deploymentPoint)) {
      this._cardDeployments.splice(index, 1);
      this._change.next({ operation: DeploymenPointOperation.removed, deploymentPoint });
    }
  }
}
