<ng-container (click)="enableInteraction && mapClick()" class="with-map" *ngIf="callcard.location">
  <ion-item>
    <ion-icon name="location" slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h2 *ngFor="let detail of callcard.location.FormattedLocation">{{ detail }}</h2>
      <h2 *ngIf="callcard.location.Complement">{{ callcard.location.Complement }}</h2>
      <h2 *ngIf="callcard.location.Narrative">{{ callcard.location.Narrative }}</h2>
    </ion-label>
  </ion-item>
</ng-container>
