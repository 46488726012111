// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import * as SagaBase from '../../../SagaBase';
import { TimerBase } from '../../../SagaBase/Objects/TimerBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TimerSchedule_abstract extends TimerBase {
  static $t: string = 'SagaBase.Objects.TimerSchedule';
  $t: string = 'SagaBase.Objects.TimerSchedule';

  public static GetMembers(): string[] {
    return [
      'StartDateUtc',
      'EndDateUtc',
      'IsActive',
      'WeekDays',
      'DayRecurence',
      'WeekRecurence',
      'Occurence',
      'FrequencyMode',
      'Dates'
    ].concat(TimerBase.GetMembers());
  }

  public GetMembers(): string[] {
    return TimerSchedule_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.StartDateUtc = this.StartDateUtc && new Date(this.StartDateUtc);
    this.EndDateUtc = this.EndDateUtc && new Date(this.EndDateUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public StartDateUtc: Date = new Date();
  public EndDateUtc: Date = new Date();
  public IsActive: boolean = false;
  public WeekDays: string;
  public DayRecurence: number = 0;
  public WeekRecurence: number = 0;
  public Occurence: SagaBase.FrequencyOccurence = SagaBase.FrequencyOccurence.Daily;
  public FrequencyMode: SagaBase.FrequencyModes = SagaBase.FrequencyModes.Recurence;
  public Dates: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
