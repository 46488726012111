import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { PopupProviderService } from '@addins/core/map';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CallcardPopupComponent } from '../../../components/callcard-popup/callcard-popup.component';

@AddinExecute({
  name: 'CardMapPopup'
})
@Injectable()
export class CardPopupService extends Execute {
  public constructor(private readonly _popup: PopupProviderService) {
    super();
  }

  public execute(): void {
    this._popup.addComponent(CallCard, CallcardPopupComponent);
  }
}
