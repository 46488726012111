import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { IStaticTranslationsProxy, StaticTranslation } from '@addins/core/core';

@Injectable()
export class TranslationApiService implements TranslateLoader, IStaticTranslationsProxy {
  constructor(private readonly http: HttpClient) {}

  getTranslations(lang: string): Observable<any> {
    const params = new HttpParams({ fromObject: { lang } });

    return this.http.get<string>(`api/Profile/GetMobileTranslations`, { params });
  }

  save(key: string, value: string, lang: string): Observable<void> {
    return this.http.post<void>('api/Profile/SaveTranslationWithParam', {
      key: key.toLowerCase(),
      translation: value,
      lang,
      param1: 'Mobile'
    });
  }

  getTranslation(lang: string): Observable<any> {
    return this.getTranslations(lang).pipe(catchError(err => of({})));
  }

  setup(lang: string): Observable<StaticTranslation> {
    return this.http.get<StaticTranslation>('api/Profile/GetStaticTranslations');
  }
}
