import { CurrentCardService, MyCardService } from '@addins/core/core';
import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { EngagementCardButton } from '../../schema/engagement-card-button';
import { EngagementService } from '../engagement/engagement.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementCardButtonService {
  private engagementCardButton: EngagementCardButton;
  private canReassign: boolean = true;

  constructor(
    private buttonsProvider: ButtonsProviderService,
    private currentCardService: CurrentCardService,
    private myCardService: MyCardService,
    private engagementService: EngagementService
  ) {}

  setup(canReassign: boolean) {
    this.canReassign = canReassign;
    this.engagementCardButton = new EngagementCardButton();
    this.engagementCardButton.onClick.subscribe(() => this.onEngageButtonClicked());
    this.buttonsProvider.add(this.engagementCardButton);

    this.currentCardService.$change.subscribe(callcard => this.onCardChanged());
    this.myCardService.$change.subscribe(callcard => this.onCardChanged());
  }

  private onEngageButtonClicked() {
    this.engagementService.engage(this.currentCardService.current);
  }

  private onCardChanged() {
    const currentCard: CallCard = this.currentCardService.current;
    const showButton = currentCard !== null && (this.canReassign || !!!this.myCardService.myCard);

    this.engagementCardButton.show = showButton;

    if (showButton) {
      this.engagementCardButton.show = currentCard.ObjGuid !== this.myCardService.myCardId;
    }
  }
}
