import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  private times = {
    h: 1,
    m: 60,
    s: 60,
    ms: 1000
  };

  transform(value: number, currentUnit: string = 'ms', precision: number = 2): any {
    let currentUnitIndex = Object.keys(this.times).indexOf(currentUnit);
    while (currentUnitIndex > 0 && value / this.times[currentUnit] >= 1) {
      value = value / this.times[currentUnit];
      currentUnitIndex = Object.keys(this.times).indexOf(currentUnit);
      if (currentUnitIndex > 0) {
        currentUnit = Object.keys(this.times)[currentUnitIndex - 1];
      }
    }
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision) + currentUnit;
  }
}
