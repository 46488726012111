import { Injectable } from '@angular/core';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@AddinExecute({
  name: 'my-deployment'
})
@Injectable()
export class MyDeploymentService extends Execute {
  private readonly _$myDeploymentPoint = new BehaviorSubject<CDeploymentPoint>(null);
  get $myDeploymentPoint(): Observable<CDeploymentPoint> {
    return this._$myDeploymentPoint.asObservable();
  }

  get mine(): CDeploymentPoint {
    return this._$myDeploymentPoint.value;
  }

  private _cacheEventSub: Subscription = null;
  private _myCallcardId: string = null;

  constructor(private _cache: CacheService, private _myUnit: MyUnitService) {
    super();
  }

  execute(): void {
    if (this._cache.isReady()) {
      this.setup();
    } else {
      let cacheStateSub: Subscription = this._cache.state.pipe(filter(cacheState => cacheState === CacheState.ready)).subscribe(() => {
        this.setup();

        cacheStateSub.unsubscribe();
        cacheStateSub = null;
      });
    }
  }

  private setup(): void {
    this._myUnit.$change.pipe(filter(() => this._myCallcardId !== this._myUnit.myCardId)).subscribe(() => this.onCallcardChanged());
    this.onCallcardChanged();
  }

  private onCallcardChanged(): void {
    this._myCallcardId = this._myUnit.myCardId;

    if (this._myUnit.isEngaged()) {
      if (this._cacheEventSub === null) {
        this._cacheEventSub = this._cache
          .listenForChange(CDeploymentPoint)
          .pipe(filter(cacheEvent => this.isForMyCard(cacheEvent.object as CDeploymentPoint) || cacheEvent.object === this.mine))
          .subscribe(cacheEvent => this.onCacheEvent(cacheEvent));
      }
      this.getMyDeployment();
    } else if (!this._myUnit.isEngaged() && this._cacheEventSub !== null) {
      this._cacheEventSub.unsubscribe();
      this._cacheEventSub = null;
      this._$myDeploymentPoint.next(null);
    }
  }

  private isForMyCard(deploymentPoint: CDeploymentPoint): boolean {
    return deploymentPoint.CallCardId === this._myUnit.myCardId;
  }

  private onCacheEvent(cacheEvent: CacheEvent): void {
    const deploymentPoint = cacheEvent.object as CDeploymentPoint;

    if (this.isMine(deploymentPoint)) {
      if (cacheEvent.action === CacheAction.added || cacheEvent.action === CacheAction.modified) {
        this._$myDeploymentPoint.next(deploymentPoint);
      } else if (cacheEvent.action === CacheAction.removed) {
        this._$myDeploymentPoint.next(null);
      }
    } else if (deploymentPoint === this.mine) {
      this._$myDeploymentPoint.next(null);
    }
  }

  private isMine(deploymentPoint: CDeploymentPoint): boolean {
    return (deploymentPoint.UnitUids && deploymentPoint.UnitUids.indexOf(this._myUnit.mine.ObjGuid) > -1) || false;
  }

  private getMyDeployment(): void {
    const myOldDeploymentPoint = this.mine;
    const myNewDeploymentPoint =
      this._cache.getListByType(CDeploymentPoint).find((deploymentPoint: CDeploymentPoint) => this.isMine(deploymentPoint)) || null;
    if (myOldDeploymentPoint !== myNewDeploymentPoint) {
      this._$myDeploymentPoint.next(myNewDeploymentPoint);
    }
  }
}
