import { IPermanentMenuEvent } from '@addins/core/menus';
import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { Task } from '@models/imported/SagaSchema/Task';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';
import { PlannedMissionDetailsMenuEntry } from '../../schema/menu/planned-mission-details-menu-entry';
import { CurrentTaskService } from '../current-task/current-task.service';

@Injectable({
  providedIn: 'root'
})
export class MissionDetailsMenuControllerService {
  private plannedMissionDetailsMenuEntry: PlannedMissionDetailsMenuEntry = null;
  private unitChangeSubscription: Subscription = null;

  private get myTask(): Task {
    return (this.myUnit.mine as UnitActivityMDP)?.task;
  }

  constructor(private myUnit: MyUnitService, private currentTask: CurrentTaskService) {}

  setup(plannedMissionDetailsMenuEntry: PlannedMissionDetailsMenuEntry): void {
    this.plannedMissionDetailsMenuEntry = plannedMissionDetailsMenuEntry;
    this.plannedMissionDetailsMenuEntry.onClick.subscribe(menuEvent => this.onClicked(menuEvent));

    this.myUnit.$unitChanged.subscribe(unit => {
      if (unit) {
        this.unitChangeSubscription = this.myUnit.$change.subscribe(() => this.onMyUnitUpdate());
        this.onMyUnitUpdate();
      } else if (!unit && this.unitChangeSubscription) {
        this.unitChangeSubscription.unsubscribe();
        this.unitChangeSubscription = null;
      }
    });
  }

  private onClicked(menuEvent: IPermanentMenuEvent): void {
    if (menuEvent.item.isActive) {
      this.currentTask.select(this.myTask);
    }
  }

  private onMyUnitUpdate() {
    if (this.isEngaged()) {
      this.plannedMissionDetailsMenuEntry.enable();
    } else {
      this.plannedMissionDetailsMenuEntry.disable();
    }
  }

  private isEngaged() {
    return this.myTask && GuidTools.isValid(this.myTask.ObjGuid);
  }
}
