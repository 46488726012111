import { AfterContentChecked, Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PanelContentComponent } from '../panel-content/panel-content.component';

@Component({
  template: ''
})
export class PanelContentBaseComponent implements AfterContentChecked {
  protected _panel: PanelContentComponent;

  private readonly _ready = new Subject<void>();
  public get ready(): Observable<void> {
    return this._ready.asObservable();
  }

  constructor() {}

  public ngAfterContentChecked() {
    this._ready.next();

    if (this._panel) {
      this._panel.resize();
    }
  }

  public setPanel(panel: PanelContentComponent): void {
    this._panel = panel;
  }
}
