// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { CStamp } from '../../SagaSchema/CStamp';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CStampResource_abstract extends CStamp {
  static $t: string = 'SagaSchema.CStampResource';
  $t: string = 'SagaSchema.CStampResource';

  public static GetMembers(): string[] {
    return [
      'VoiceChannel',
      'Departure',
      'Status',
      'ElementType',
      'ElementID',
      'ElementName',
      'Text',
      'Data',
      'ServiceObjId',
      'ProcStepUid',
      'ParentObjectUId',
      'Acknowledge',
      'SourceIdentifier'
    ].concat(CStamp.GetMembers());
  }

  public GetMembers(): string[] {
    return CStampResource_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public VoiceChannel: string;
  public Departure: number = 0;
  public Status: number = 0;
  public ElementType: number = 0;
  public ElementID: Guid = GuidTools.Empty;
  public ElementName: string = '';
  public Text: string = '';
  public Data: string = '';
  public ServiceObjId: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public ParentObjectUId: Guid = GuidTools.Empty;
  public Acknowledge: boolean = false;
  public SourceIdentifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
