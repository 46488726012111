import { Injectable } from '@angular/core';
import { ImagesSettingsService } from '@services/settings';
import { HistoryGroup, MapToolsPanel } from '@techwan/map-tools';
import { DeploymentGroup } from '../../schema/deployment-group';
import { DeploymentTool, DeploymentToolName } from '../../schema/deployment-tool';

@Injectable()
export class DeploymentPanelGroupService {
  constructor(private readonly images: ImagesSettingsService) {}

  public createPanelGroup(panel: MapToolsPanel): DeploymentGroup {
    const after = panel.getGroupByType(HistoryGroup);
    const group = new DeploymentGroup();
    group.addTool(new DeploymentTool(DeploymentToolName.add, this.images.get('DeploymentAdd')));
    group.addTool(new DeploymentTool(DeploymentToolName.remove, this.images.get('DeploymentDel')));
    group.addTool(new DeploymentTool(DeploymentToolName.move, this.images.get('DeploymentMove')));
    panel.addGroupAfter(after, group);
    return group;
  }
}
