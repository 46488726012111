import { ILocalizeRequest } from '@addins/core/map';
import { Coordinate } from '@models/coordinate';
import { CallcardButtonTool } from './callcard-button-tool';

export class LocalizeButtonTool extends CallcardButtonTool<ILocalizeRequest> {
  constructor(protected coordinates: Coordinate[], text: string, icon: string, position: number) {
    super(text, icon, position);
  }

  click() {
    this._onClick.next({ coordinates: this.coordinates, target: null });
  }
}
