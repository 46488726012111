import { Component, Input, OnInit } from '@angular/core';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { ImagesSettingsService } from '@services/settings';
import { UploadCacheService } from '@techwan/file-upload';
import { FileDownloadState } from '../../schema/file-download-state';
import { MediaFile } from '../../schema/media-file';
import { SagaFileUploadItem } from '../../schema/saga-file-upload-item';
import { MediaFileService } from '../../services/media-file/media-file.service';

const AttachmentPicture = {
  LocalAttachment: 'LocalAttachment',
  DownloadAttachment: 'DownloadAttachment',
  UploadAttachment: 'UploadAttachment'
};

@Component({
  selector: 'app-media-message-attachment',
  templateUrl: './media-message-attachment.component.html',
  styleUrls: ['./media-message-attachment.component.scss']
})
export class MediaMessageAttachmentComponent implements OnInit {
  @Input() mediaMessage: MediaMessage;

  thumbnail: string = null;

  private mediaFile: MediaFile = null;
  private downloadError: string = null;

  constructor(
    private imagesService: ImagesSettingsService,
    private uploadCache: UploadCacheService,
    private mediaFileService: MediaFileService
  ) {}

  ngOnInit() {
    const mediaUrl = this.mediaMessage.Url;
    this.mediaFile = this.mediaFileService.getMediaFile(mediaUrl);
    this.thumbnail = this.getThumbnail();
  }

  private getThumbnail(): string {
    if (this.mediaMessage instanceof CMediaMessage) {
      return this.mediaMessage.MediaPreview.toString();
    } else {
      return this.imagesService.get('PictureIcon');
    }
  }

  isInitializing(): boolean {
    return this.mediaFile && this.mediaFile.getState() === FileDownloadState.starting;
  }

  isDownloading(): boolean {
    return this.mediaFile && this.mediaFile.getState() === FileDownloadState.downloading;
  }

  getProgress(): number {
    return this.mediaFile && this.mediaFile.getProgress();
  }

  hasError(): boolean {
    return this.downloadError !== null;
  }

  getError(): string {
    return this.downloadError || '';
  }

  getMediaFileStatusImage(): string {
    const mediaUrl: string = this.mediaMessage.Url;
    if (mediaUrl !== null) {
      let statusImage: string = null;

      if (this.getFileFromUploadCache(mediaUrl) !== null) {
        statusImage = AttachmentPicture.UploadAttachment;
      } else {
        const mediaFile: MediaFile = this.mediaFileService.getMediaFile(mediaUrl);

        if (mediaFile !== null && mediaFile.isDownloaded()) {
          statusImage = AttachmentPicture.LocalAttachment;
        } else {
          statusImage = AttachmentPicture.DownloadAttachment;
        }
      }

      return this.imagesService.get(statusImage);
    }
  }

  private getFileFromUploadCache(url: string): SagaFileUploadItem {
    return (this.uploadCache.uploadItems.find((uploadItem: SagaFileUploadItem) => uploadItem.url === url) as SagaFileUploadItem) || null;
  }

  open() {
    const mediaUrl = this.mediaMessage.Url;
    this.mediaFile = this.mediaFileService.getMediaFile(mediaUrl);
    if (this.mediaFile === null) {
      this.downloadError = null;
      this.mediaFileService.downloadMediaFile(mediaUrl, this.getLocalFileName(this.mediaMessage)).subscribe(
        mediaFile => this.onDownloadProgress(mediaFile),
        error => {
          this.downloadError = 'Mobile.FileDownloadError';
        }
      );
    } else if (this.mediaFile.isDownloaded()) {
      this.mediaFileService.openMediaFile(this.mediaFile);
    }
  }

  private getLocalFileName(mediaMessage: MediaMessage): string {
    return `${mediaMessage.ObjGuid}_${mediaMessage.Caption}`;
  }

  private onDownloadProgress(mediaFile: MediaFile) {
    this.mediaFile = mediaFile;
    if (mediaFile.isDownloaded()) {
      this.mediaFileService.openMediaFile(mediaFile);
    }
  }
}
