import { Injectable } from '@angular/core';
import { Task } from '@models/imported/SagaSchema/Task';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentTaskService {
  private readonly _current = new BehaviorSubject<Task>(null);
  get $change(): Observable<Task> {
    return this._current.asObservable();
  }

  get current(): Task {
    return this._current.value;
  }

  constructor() {}

  select(task: Task): void {
    if (task !== this.current) {
      this._current.next(task);
    }
  }
}
