import { ListSettingValue } from '@addins/core/settings/schema/list-setting-value';
import { SettingValue } from '@addins/core/settings/schema/setting-value';
import { ListSettingWithNames } from '@addins/core/settings/views/settings-editor/settings-editor.component';
import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExternalItineraryProviderService } from '../../services/external-itinerary-provider/external-itinerary-provider.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-external-itinerary-settings',
  templateUrl: './external-itinerary-settings.component.html',
  styleUrls: ['./external-itinerary-settings.component.scss']
})
export class ExternalItinerarySettingsComponent implements OnInit, OnDestroy {
  private readonly NO_EXT_ITN_PROVIDER: string = 'None';

  private extItineraryChangedSub: Subscription;

  private _extItineraryProviderListSettings: ListSettingWithNames;
  get extItineraryProviderListSettings(): ListSettingWithNames {
    return this._extItineraryProviderListSettings;
  }

  constructor(private externalItineraryProvider: ExternalItineraryProviderService) {}

  ngOnInit() {
    this._extItineraryProviderListSettings = this.createExtItineraryProviderListSettings();
    this.extItineraryChangedSub = this._extItineraryProviderListSettings.setting.$change.subscribe((settingValue: SettingValue) =>
      this.handleExtItineraryProviderChanged(settingValue.value)
    );
  }

  private createExtItineraryProviderListSettings(): ListSettingWithNames {
    const titleCasePipe = new TitleCasePipe();
    const settingValues: SettingValue[] = this.getExtItineraryProviderValues().map(
      name => new SettingValue(titleCasePipe.transform(name), name)
    );
    const listSettingValue: ListSettingValue = new ListSettingValue('Mobile.DefaultExtItineraryProvider', settingValues);

    let savedProvider: SettingValue = settingValues.filter(
      setting => setting.value === this.externalItineraryProvider.currentItineraryProvider.current
    )[0];
    listSettingValue.select(savedProvider);

    return {
      setting: listSettingValue,
      displayNames: { [this.NO_EXT_ITN_PROVIDER]: 'Mobile.NoDefaultExtItineraryProvider' },
      readonly: false
    };
  }

  private getExtItineraryProviderValues(): string[] {
    const configuredProviders = this.externalItineraryProvider.getProviders();
    return [this.NO_EXT_ITN_PROVIDER, ...configuredProviders];
  }

  private handleExtItineraryProviderChanged(providerName: string) {
    if (this.externalItineraryProvider.currentItineraryProvider.current !== providerName) {
      this.externalItineraryProvider.currentItineraryProvider.setValue(providerName);
    }
  }

  ngOnDestroy() {
    this.extItineraryChangedSub.unsubscribe();
  }
}
