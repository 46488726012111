import { Directive, ElementRef, Host, Input } from '@angular/core';
import { IonIcon } from '@ionic/angular';
import { IconData, IconService, IconType } from '@services/icon/icon.service';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'ion-icon[icon]' })
export class IconDirective {
  private _otherElement: HTMLElement;

  private _icon: string;
  @Input() set icon(icon: string) {
    this._icon = icon;
    const iconData = this.iconService.parse(icon);

    this.updateIcon(iconData);
  }

  private _color;
  @Input() set color(value) {
    this._color = value;
    this.updateColor();
  }

  constructor(private iconService: IconService, @Host() private ionIcon: IonIcon, private elementRef: ElementRef<HTMLElement>) {}

  private updateIcon(iconData: IconData) {
    this.elementRef.nativeElement.hidden = false;
    const parent = this.elementRef.nativeElement.parentElement;
    if (this._otherElement) {
      parent.removeChild(this._otherElement);
    }

    if (this.ionIcon) {
      switch (iconData.type) {
        case IconType.Ionic:
          this.ionIcon.name = this.icon;
          break;

        case IconType.Svg:
          this.ionIcon.src = this.icon;
          break;

        case IconType.Material:
          this.ionIcon.name = '';
          this._otherElement = document.createElement('i');
          this._otherElement.classList.add('material-icons');
          this._otherElement.innerHTML = iconData.name;
          this._otherElement.style.margin = '12px 32px 12px 0px';

          this.elementRef.nativeElement.hidden = true;

          if (this.ionIcon.size) {
            this._otherElement.classList.add(this.ionIcon.size);
          }

          parent.insertBefore(this._otherElement, this.elementRef.nativeElement);
          break;

        case IconType.Base64:
        case IconType.Url:
          this.ionIcon.name = '';
          Object.assign(this.elementRef.nativeElement.style, {
            backgroundImage: `url('${this._icon}')`,
            backgroundSize: 'cover'
          });
          break;
      }

      this.updateColor();
    }
  }

  private updateColor() {
    if (this._color) {
      if (this._otherElement) {
        this._otherElement.style.color = this._color;
      }

      this.elementRef.nativeElement.style.color = this._color;
    }
  }
}
