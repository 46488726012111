import { CurrentCardService, MyCardService } from '@addins/core/core';
import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { AlertInput } from '@ionic/core';
import { Guid } from '@models/guid';
import { FormTemplate } from '@models/imported/SagaSchema/FormTemplate';
import { ModalService } from '@services/modal/modal.service';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { Observable, from } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { SagaFormsCardButton } from '../../schema/saga-forms-card-button';
import { SagaFormsProxyService } from '../saga-forms-proxy/saga-forms-proxy.service';
import { SagaFormsUrlProviderService } from '../saga-forms-url-provider/saga-forms-url-provider.service';

@Injectable({
  providedIn: 'root'
})
export class SagaFormsCardButtonService {
  private sagaFormsCardButton: SagaFormsCardButton;

  constructor(
    private buttonProvider: ButtonsProviderService,
    private currentCardService: CurrentCardService,
    private myCardService: MyCardService,
    private modalService: ModalService,
    private sagaFormsProxyService: SagaFormsProxyService,
    private translatedAlertService: TranslatedAlertService,
    private sagaFormsUrlProviderService: SagaFormsUrlProviderService,
    private windowOpener: WindowOpenerService
  ) {}

  setup() {
    this.sagaFormsCardButton = new SagaFormsCardButton();
    this.buttonProvider.add(this.sagaFormsCardButton);
    this.sagaFormsCardButton.onClick.subscribe(() => this.onFormsCardButtonClick());

    this.currentCardService.$change.subscribe(() => this.onCardChanged());
    this.myCardService.$change.subscribe(() => this.onCardChanged());
  }

  private onCardChanged() {
    const showButton: boolean =
      this.myCardService.myCard !== null &&
      this.currentCardService.current !== null &&
      this.currentCardService.current.ObjGuid === this.myCardService.myCardId;
    this.sagaFormsCardButton.show = showButton;
  }

  private onFormsCardButtonClick() {
    this.getFormTemplates()
      .pipe(
        map(formTemplates =>
          formTemplates
            .filter(formTemplate => !formTemplate.IsStandalone)
            .sort((a, b) => {
              return a.Name.localeCompare(b.Name);
            })
        )
      )
      .subscribe(formTemplates => {
        if (formTemplates.length > 0) {
          this.showFormTemplates(formTemplates).then(selectedFormTemplateUid => this.openCreateForm(selectedFormTemplateUid));
        } else {
          this.showNoFormTemplatesAvailable();
        }
      });
  }

  private getFormTemplates(): Observable<FormTemplate[]> {
    return from(this.modalService.presentLoading()).pipe(
      switchMap(() => this.sagaFormsProxyService.getFormTemplates()),
      finalize(() => this.modalService.dismissLoading())
    );
  }

  private showFormTemplates(formTemplates: FormTemplate[]): Promise<Guid> {
    const radioInputs = this.createFormTemplatesRadioInputs(formTemplates);
    radioInputs[0].checked = true;
    return new Promise<Guid>(resolve => {
      this.translatedAlertService.show({
        header: 'Mobile.Forms.SelectTemplate',
        inputs: radioInputs,
        buttons: [
          { text: 'Mobile.Cancel', value: false },
          {
            text: 'Mobile.Confirm',
            value: true,
            handler(selectedFormTemplateUid) {
              resolve(selectedFormTemplateUid);
            }
          }
        ]
      });
    });
  }

  private openCreateForm(formTemplateUid: Guid) {
    const url: string = this.sagaFormsUrlProviderService.getLinkedFormUrl(formTemplateUid, this.myCardService.myCardId);
    this.windowOpener.system(url);
  }

  private showNoFormTemplatesAvailable() {
    this.translatedAlertService.show({
      header: 'Mobile.Error',
      message: 'Mobile.Forms.TemplateNotAvailable',
      buttons: [{ text: 'Mobile.Close', value: true }]
    });
  }

  private createFormTemplatesRadioInputs(formTemplates: FormTemplate[]): AlertInput[] {
    return formTemplates.map(formTemplate => ({
      type: 'radio',
      label: formTemplate.Name,
      value: formTemplate.ObjGuid
    }));
  }
}
