import { Injectable } from '@angular/core';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { AuthenticationService } from '@techwan/ionic-core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { EventService } from '../event/event';
import { FetchListService } from '../fetchlist/fetchlist.service';
import { ProfileService } from '../profile/profile.service';
import { PushService } from '../push';
import { SectorService } from '../sector/sector.service';
import { SagaSettingsService } from '../settings';
import { StylesService } from '../styles/styles.service';
import { ViewSelectorService } from '../view-selector/view-selector.service';

@Injectable()
export class Initializer {
  private _setupBeforePromise: Promise<any>;

  onSetupBefore = new Subject<void>();

  private _onComplete = new BehaviorSubject<boolean>(false);
  get $onComplete(): Observable<boolean> {
    return this._onComplete.asObservable();
  }

  get isCompleted(): boolean {
    return this._onComplete.value;
  }

  private _initSteps = new Subject<Promise<any>[]>();
  get $init(): Observable<Promise<any>[]> {
    return this._initSteps.asObservable();
  }

  constructor(
    private _cache: CacheService,
    private _fetchList: FetchListService,
    private _push: PushService,
    private _appSettings: SagaSettingsService,
    private _styles: StylesService,
    private _eventService: EventService,
    private _sectorService: SectorService,
    private _profile: ProfileService,
    private _viewSelector: ViewSelectorService,
    private _translatedAlertService: TranslatedAlertService
  ) {}

  setupBefore(auth: AuthenticationService): Promise<void> {
    const list: Promise<any>[] = [];
    if (!this._setupBeforePromise) {
      this._profile.setup(auth.configuration.userProfile);
      this._initSteps.next(list);
      this._setupBeforePromise = Promise.all(
        list.concat([this._appSettings.setup().toPromise(), this._fetchList.setup().toPromise(), this._eventService.setup()])
      );
    }

    this._setupBeforePromise.then(() => this.onSetupBefore.next());

    return this._setupBeforePromise;
  }

  setup(): Promise<void> {
    this._viewSelector.goHome();
    this._push.initialize();
    return Promise.all([this._cache.boot().toPromise(), this._sectorService.setup()])
      .then(results => results.reduce((success, currentResult) => success && currentResult, true))
      .then(success => {
        if (success) {
          this._styles.loadAppSettingsIcon();
          this._onComplete.next(true);
        } else {
          this._translatedAlertService
            .show({
              message: 'SagaMobile.Initialize.Error',
              backdropDismiss: false,
              buttons: [
                {
                  text: 'SagaMobile.Initialize.Retry',
                  value: true
                }
              ]
            })
            .then(() => location.reload());
        }
      });
  }

  setDown() {
    this._onComplete.next(false);
    this._viewSelector.goLogin();
    this._push.stop();
    this._cache.clear();
  }
}
