// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Pflau_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Objects.Pflau';
  $t: string = 'SagaBase.Objects.Pflau';

  public static GetMembers(): string[] {
    return ['PflauUid', 'CreatedUtc', 'ModifiedUtc', 'CallcardUid', 'PhoneNumber', 'CoordX', 'CoordY', 'Radius', 'CoordEpsgCode'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return Pflau_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.CreatedUtc = this.CreatedUtc && new Date(this.CreatedUtc);
    this.ModifiedUtc = this.ModifiedUtc && new Date(this.ModifiedUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public PflauUid: Guid = GuidTools.Empty;
  public CreatedUtc: Date = new Date();
  public ModifiedUtc: Date = new Date();
  public CallcardUid: Guid = GuidTools.Empty;
  public PhoneNumber: string;
  public CoordX: number = 0;
  public CoordY: number = 0;
  public Radius: number = 0;
  public CoordEpsgCode: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.PflauUid;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
