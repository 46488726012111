import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { Logger } from '../logger';
import { AppSettingsService } from '../settings/app-settings/app-settings.service';
import { Observable, of, throwError, zip } from 'rxjs';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { User } from '@models/imported/SagaBase/User';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';

export enum AddBaseUrl {
  None,
  Api,
  Update
}

export interface RequestOptions {
  url: string;
  method?: string;
  addBaseUrl?: AddBaseUrl;
  params?: HttpParams;
  headers?: HttpHeaders;
  data?: any;
  responseType?: any;
}

export interface SecurityInterface {}

/**
 * @description
 * Service for managing security for the app
 */
@Injectable()
export class Security {
  constructor(
    public location: Location,
    private http: HttpClient,
    private settings: AppSettingsService,
    private permissions: AndroidPermissions,
    private platform: Platform,
    private readonly claims: RefreshTokenStorageService
  ) {
    this.loadSecurityData();
    this.init();
  }

  get PERSMISSION() {
    return this.permissions.PERMISSION;
  }
  private token_key: string = 'saga-token';
  private security_data_key: string = 'saga_security_data';
  private private_key: string = '821947hd743923874fgcb1o237864cb';
  private loginHandler: Function;
  private header_token_name: string = 'SagaSecTok';
  private onLogoutHandlers: Function[] = [];

  public loggedUser: User;

  private token: string;

  private lastError: string;

  private savedData: {} = {};

  public data: {} = {};

  public dataListeners: {} = {};

  private customHeaders: any = {};

  static encryptData(data: any, key: string): string {
    const dataString = JSON.stringify(data);
    const encryptData = CryptoJS.AES.encrypt(dataString, key);
    return encryptData.toString();
  }

  static decryptData(data: string, key: string): any {
    const decryptData = CryptoJS.AES.decrypt(data, key);
    const decryptString = decryptData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptString);
  }

  static urlSearchParamsToObject(params: Map<any, any>) {
    const arrayParam = Array.from(params);
    const paramsObject = {};
    for (const param in arrayParam) {
      paramsObject[param[0]] = param[1];
    }
    return paramsObject;
  }

  async init() {
    // TODO Find a solution to support authuser from environement.
    if (!environment.production && environment.profile === 'PN') {
      const settingsEntry: any = {
        text: 'AUTH-USER',
        type: 'input'
      };

      settingsEntry._value = await this.settings.get('auth-user', '', true).toPromise();

      Object.defineProperty(settingsEntry, 'value', {
        set: value => {
          settingsEntry._value = value;
          this.settings.set('auth-user', value, true);
        },
        get: () => settingsEntry._value
      });

      this.settings.addSettingsEntry(settingsEntry);
    }
  }

  /**
   * This method will be removed in the next major release.
   * @deprecated PLease prefer using http interceptor provided by techwan/ionic-core instead of using this method.
   * @param headers Headers value to inject into the request
   */
  getHeaders(headers: any | HttpHeaders = new HttpHeaders()): HttpHeaders {
    if (!(headers instanceof HttpHeaders)) {
      headers = new HttpHeaders(headers);
    }
    // Add device information for the API
    headers = headers.append('CLIENT-TYPE', 'mobile');
    headers = headers.append('DEVICE-TYPE', this.platform.platforms().join(','));

    if (!environment.production && environment.profile === 'PN') {
      const authUser = this.settings.getSync('auth-user', '');
      if (authUser) {
        headers = headers.append('AUTH-USER', authUser);
      }
    }

    for (const key in this.customHeaders) {
      if (this.customHeaders.hasOwnProperty(key)) {
        headers = headers.append(key, this.customHeaders[key]);
      }
    }

    return headers;
  }

  public getUserZone() {
    return this.get(`api/Gis/GetSector?siteId=${this.loggedUser.SiteId}`);
  }

  public getData(name: string, defaultValue: any = null) {
    return this.data[name] !== undefined ? this.data[name] : defaultValue;
  }

  public setData(name: string, value: any) {
    const previous = this.data[name];
    this.data[name] = value;
    if (this.dataListeners[name]) {
      this.dataListeners[name].forEach(handler => {
        handler(value, previous);
      });
    }
  }

  public onDataChange(key: string, handler: Function): () => void {
    if (!this.dataListeners[key]) {
      this.dataListeners[key] = [];
    }
    this.dataListeners[key].push(handler);

    return () => {
      const i = this.dataListeners[key].indexOf(handler);
      if (i !== 0) {
        this.dataListeners[key].splice(i, 1);
      }
    };
  }

  public getDataLength(): number {
    let size = 0,
      key;
    for (key in this.savedData) {
      if (this.savedData.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }

  private storeSecurityData() {
    localStorage.setItem(this.security_data_key, Security.encryptData(this.savedData, this.private_key));
  }

  clearSecurityData() {
    localStorage.setItem(this.security_data_key, '');
  }

  private loadSecurityData() {
    try {
      this.savedData = Security.decryptData(localStorage.getItem(this.security_data_key), this.private_key);
      Logger.debug(`Security data loaded correctly (got ${this.getDataLength()} items)`, 'Security');
    } catch (e) {
      this.savedData = {};
    }
  }

  public addSecurityData(name: string, data: any): void {
    this.savedData[name] = data;
    this.storeSecurityData();
  }

  public getSecurityData<T = any>(name: string): T {
    return this.savedData[name];
  }

  public deleteSecurityData(name: string): void {
    delete this.savedData[name];
    this.storeSecurityData();
  }

  public async requestPin(phone: string) {
    return false;
  }

  public async validatePin(pin: string) {
    return 'dwedwe';
  }

  public addCustomHeader(key: string, value: string) {
    this.customHeaders[key] = value;
  }

  public addBaseUrl(url: string, type: AddBaseUrl = AddBaseUrl.Api): Observable<string> {
    let baseUrl: Observable<string> = null;
    if (type !== AddBaseUrl.None) {
      switch (type) {
        case AddBaseUrl.Api:
          baseUrl = this.settings.apiBaseUrl();
          break;

        case AddBaseUrl.Update:
          baseUrl = this.settings.updateBaseUrl();
          break;
      }
    }

    return baseUrl !== null ? baseUrl.pipe(map(res => (res || '') + url)) : of(url);
  }

  public addTokenToHeader(headers?: Headers | any): HttpHeaders | any {
    if (headers instanceof HttpHeaders) {
      headers = headers.append(this.header_token_name, this.getToken());
    } else {
      headers[this.header_token_name] = this.getToken();
    }
    return headers;
  }

  public observableRequest<T>(
    methodOrOptions: string | RequestOptions,
    url?: string,
    data?: any,
    addBaseUrl: AddBaseUrl = AddBaseUrl.Api,
    search?: HttpParams,
    headers?: HttpHeaders
  ): Observable<T> {
    return this.internalRequest(methodOrOptions, url, data, addBaseUrl, search, headers);
  }

  public request<T>(
    methodOrOptions: string | RequestOptions,
    url?: string,
    data?: any,
    addBaseUrl: AddBaseUrl = AddBaseUrl.Api,
    search?: HttpParams,
    headers?: HttpHeaders
  ): Promise<T> {
    return new Promise((resolve, reject) =>
      this.internalRequest<T>(methodOrOptions, url, data, addBaseUrl, search, headers).subscribe(resolve, reject)
    );
  }

  private internalRequest<T>(
    methodOrOptions: string | RequestOptions,
    url?: string,
    data?: any,
    addBaseUrl: AddBaseUrl = AddBaseUrl.Api,
    params?: HttpParams,
    headers?: HttpHeaders
  ): Observable<T> {
    const options: RequestOptions = {
      url: '',
      method: 'get',
      addBaseUrl: AddBaseUrl.Api
    };
    // Build options
    Object.assign(
      options,
      typeof methodOrOptions !== 'string'
        ? methodOrOptions
        : {
            method: methodOrOptions,
            url,
            data,
            addBaseUrl,
            params,
            headers
          }
    );
    options.headers = this.getHeaders(options.headers);

    if ('params' in options && !(options.params instanceof HttpParams)) {
      options.params = new HttpParams({ fromObject: options.params });
    }

    return zip(this.settings.apiKey(), this.addBaseUrl(options.url, addBaseUrl)).pipe(
      switchMap(([key, url]) => {
        const method = options.method;
        delete options.method;
        delete options.url;
        if (key) {
          options.headers = options.headers.append('APIKEY', key);
        }

        return method.toUpperCase() === 'POST' ? this.http.post<T>(url, options.data, options) : this.http.get<T>(url, options);
      }),
      catchError(error => this.httpError(error))
    );
  }

  private httpError(error): Observable<any> {
    if (error.status === 401) {
      console.log('Token is no longer valid ...');
    }
    return throwError(error);
  }

  public async post<T>(urlOrOptions: string | RequestOptions, data?: any) {
    const options: RequestOptions = {
      url: ''
    };
    if (typeof urlOrOptions !== 'string') {
      Object.assign(options, urlOrOptions, {
        method: 'post'
      });
    } else {
      Object.assign(options, {
        method: 'post',
        url: urlOrOptions,
        data
      });
    }
    return await this.request<T>(options);
  }

  public getObservable(urlOrOptions: string | RequestOptions, data: any = {}): Observable<HttpResponse<any> | Response> {
    const options: RequestOptions = {
      url: ''
    };
    if (typeof urlOrOptions !== 'string') {
      Object.assign(options, urlOrOptions, {
        method: 'get'
      });
    } else {
      const search = new URLSearchParams();
      for (const key in data) {
        search.set(key, data[key]);
      }
      Object.assign(options, {
        method: 'get',
        url: urlOrOptions,
        search
      });
    }
    return this.internalRequest(options, null, data, AddBaseUrl.Api, null, null);
  }

  public async get<T = any>(urlOrOptions: string | RequestOptions, data: any = {}) {
    const options: RequestOptions = {
      url: ''
    };
    if (typeof urlOrOptions !== 'string') {
      Object.assign(options, urlOrOptions, {
        method: 'get'
      });
    } else {
      Object.assign(options, {
        method: 'get',
        url: urlOrOptions,
        params: data
      });
    }
    return await this.request<T>(options);
  }

  public registerLoginHandler(handler: Function) {
    this.loginHandler = handler;
  }

  public getLastError(clear: boolean) {
    const error = this.lastError;
    if (clear) {
      this.lastError = null;
    }
    return error;
  }

  public setLastError(error: string) {
    this.lastError = error;
  }

  public setToken(token: string, storeToLocal: boolean = true): void {
    if (storeToLocal) {
      localStorage.setItem(this.token_key, token);
    } else {
      localStorage.removeItem(this.token_key);
    }
    this.token = token;
  }

  public async getUser() {
    let user = null;
    try {
      user = this.get('api/Authentication/GetUser');
    } catch (err) {
      console.log('An error occured');
    }

    return user;
  }

  public onLogout(handler: Function): Function {
    this.onLogoutHandlers.push(handler);

    return () => {
      const i = this.onLogoutHandlers.indexOf(handler);
      if (i != -1) {
        this.onLogoutHandlers.splice(i, 1);
      }
    };
  }

  public getToken(): string {
    return this.claims.read().login.TokenString;
  }

  public redirectToLogin(): void {
    if (this.loginHandler) {
      this.loginHandler(true);
    }
  }

  public redirectToHome(): void {
    if (this.loginHandler) {
      this.loginHandler(false);
    }
  }

  async permission(name: string) {
    return new Promise<void>((resolve, reject) => {
      this.permissions.checkPermission(name).then(
        result => {
          if (result && result.hasPermission) {
            resolve();
          } else {
            this.permissions.requestPermission(name).then(
              success => resolve(),
              err => reject('Permission not granted')
            );
          }
        },
        err =>
          this.permissions.requestPermission(name).then(
            success => resolve(),
            err => reject('Permission not granted')
          )
      );
    });
  }

  public setUserInfo(obj: SagaObject) {
    const login = this.loggedUser as User;
    obj.UserID = login.UserID;
    obj.UserName = login.UserName;
    obj.Owner = login.Owner;
    obj.OwnerName = login.OwnerName;
  }
}
