import { CurrentCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Guid, GuidTools } from '@models/guid';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Observable, Subject, Subscription } from 'rxjs';
import { CachedList } from '../../schema/cached-list';
import { UnitListFetchingService } from '../unit-list-fetching/unit-list-fetching.service';

@Injectable({
  providedIn: 'root'
})
export class CallcardUnitsProviderService {
  private cachedUnits: CachedList<UnitActivity> = null;
  get units(): UnitActivity[] {
    return this.cachedUnits ? this.cachedUnits.items : [];
  }

  private unitsRefreshSub: Subscription = null;

  private _unitChangedEvent = new Subject<Guid>();
  get $unitChangedEvent(): Observable<Guid> {
    return this._unitChangedEvent.asObservable();
  }

  constructor(private currentCardService: CurrentCardService, private unitListFetchingService: UnitListFetchingService) {
    this.currentCardService.$change.subscribe(callcard => this.onCallcardChanged(callcard));
  }

  private onCallcardChanged(callcard: CallCard) {
    this.clearUnitsCachedList();
    if (callcard) {
      this.createUnitsCachedList();
    }
  }

  private clearUnitsCachedList() {
    if (this.cachedUnits !== null) {
      this.unitsRefreshSub.unsubscribe();
      this.unitsRefreshSub = null;

      this.cachedUnits.clear();
      this.cachedUnits = null;
    }
  }

  private createUnitsCachedList() {
    this.cachedUnits = this.unitListFetchingService.createFilteredUnitActivitiesFromCache(unitActivity => this.isForMyCard(unitActivity));
    this.unitsRefreshSub = this.cachedUnits.$refresh.subscribe((guid: Guid) => this.onUnitChanged(guid));
  }

  private isForMyCard(unitActivity: UnitActivity): boolean {
    return (
      GuidTools.isValid(unitActivity.CallCardID) &&
      this.currentCardService.current &&
      unitActivity.CallCardID === this.currentCardService.current.ObjGuid
    );
  }

  private onUnitChanged(guid: Guid) {
    this._unitChangedEvent.next(guid);
  }
}
