// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 13:39:25
export enum LockResult {
  LockOk = 0,
  LockNOk = 1,
  LockTimeout = 2
}
export enum Locking {
  Lock = 0,
  Unlock = 1
}
export enum ShiftCoverage {
  None = 0,
  PartialSingle = 1,
  PartialMultiple = 2,
  FullSingle = 3,
  FullMultiple = 4
}
export enum StampProviderOperation {
  Add = 0,
  Update = 1,
  Remove = 2
}
export enum ActivityStatusType {
  Unavailable = 0,
  Available = 1,
  Active = 2
}
export enum CommunicationTraceType {
  Phone = 0,
  Radio = 1,
  Pager = 2,
  Fax = 3,
  SMS = 4,
  Mail = 5
}
export enum DataType {
  None = 0,
  Picture = 1,
  Audio = 2,
  Video = 3,
  File = 4,
  Uri = 5,
  Link = 6,
  Share = 7
}
