import { Component, Inject, Optional } from '@angular/core';
import { IonNav, NavController } from '@ionic/angular';
import { NavService } from '@services/navigation/navigation.service';
import { StatusBarItem, StatusBarService } from '@services/status-bar/status-bar.service';

@Component({
  selector: '[status-bar]',
  templateUrl: 'statusBar.html'
})
export class StatusBarComponent {
  isPrimary = !this.nav || this.navService.isPrimary(this.nav);

  get items(): StatusBarItem[] {
    return this.statusBarService.getItems();
  }

  constructor(
    private statusBarService: StatusBarService,
    private navService: NavService,
    private navCtrl: NavController,
    @Optional() @Inject(IonNav) private nav: IonNav
  ) {}

  itemClicked(event: Event, item: StatusBarItem) {
    if (item.handler) {
      item.handler(event, this.navService.get());
    }
  }
}
