import { Directive, ElementRef, Input } from '@angular/core';
import { EnvironmentService } from '@services/environment/environment.service';
import { GlobalizationService } from '@services/globalization/globalization.service';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[translate]'
})
export class TranslateDirective {
  @Input('translate')
  public key: string;

  constructor(
    private readonly el: ElementRef,
    private readonly globalization: GlobalizationService,
    private readonly env: EnvironmentService
  ) {
    if (env.devMode) {
      fromEvent(el.nativeElement, 'contextmenu').subscribe(e => this.handleEvent(e));
      fromEvent(el.nativeElement, 'taphold').subscribe(e => this.handleEvent(e));
    }
  }

  private handleEvent(event): void {
    let key = this.key;
    if (!key) {
      key = this.el.nativeElement.childNodes.length > 0 ? this.el.nativeElement.childNodes[0].originalContent : null;
    }

    if (key) {
      event.preventDefault();
      event.stopPropagation();
      this.showTranslateModal(key);
    }
  }

  private showTranslateModal(key: string): void {
    this.globalization.showTranslateModal(key);
  }
}
