import { LocationProviderService } from '@addins/core/core';
import { Events } from '@services/events/events.service';
import { ADDIN_VIEW_EVENT_KEY } from '@techwan/ionic-core';

export class HomeBase {
  constructor(protected readonly eventService: Events, protected readonly _positionProvider: LocationProviderService) {}

  openView(name: string) {
    this.eventService.publish(ADDIN_VIEW_EVENT_KEY, name);
  }
}
