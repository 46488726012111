import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { PCE_DESCRIPTION_VALUE } from './constants';
import { ParticularsCollapsibleItem } from './particulars-collapsible-item';
import { ParticularsPosition } from './particulars-position';

const CPersonListCollapsibleName = 'Mobile.CPersonPCEDetails';
const CPersonListViewName = 'SingleCallcardParticulars/CallcardDetailCPersonPCE';

export class CPersonPceCollapsibleItem extends ParticularsCollapsibleItem {
  constructor() {
    super(CPersonListCollapsibleName, CPersonListViewName, ParticularsPosition.CPersonPce);
  }

  updateVisibility(callCard: CallCard): void {
    this.setVisible(
      callCard !== null ? callCard.component<CPerson>(CPerson).filter(p => p.Description === PCE_DESCRIPTION_VALUE).length > 0 : false
    );
  }
}
