<ion-card>
  <ion-card-header>
    <ion-card-title [translate]="title"></ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p [translate]="message"></p>
    <ion-button (click)="close(okButton.value)" [translate]="okButton.text"></ion-button>
    <ion-button (click)="close(cancelButton.value)" [translate]="cancelButton.text"></ion-button>
  </ion-card-content>
</ion-card>
