import { CachedList, UnitListFetchingService } from '@addins/core/unit-activity';
import { Injectable } from '@angular/core';
import { Guid, GuidTools } from '@models/guid';
import { Task } from '@models/imported/SagaSchema/Task';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Observable, Subject, Subscription } from 'rxjs';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';
import { CurrentTaskService } from '../current-task/current-task.service';

@Injectable({
  providedIn: 'root'
})
export class TaskUnitsProviderService {
  private cachedUnits: CachedList<UnitActivity> = null;
  get units(): UnitActivity[] {
    return this.cachedUnits ? this.cachedUnits.items : [];
  }

  private unitsRefreshSub: Subscription = null;

  private _unitChangedEvent = new Subject<Guid>();
  get $unitChangedEvent(): Observable<Guid> {
    return this._unitChangedEvent.asObservable();
  }

  constructor(private currentTaskService: CurrentTaskService, private unitListFetchingService: UnitListFetchingService) {
    this.currentTaskService.$change.subscribe(task => this.onTaskChanged(task));
  }

  private onTaskChanged(task: Task) {
    this.clearUnitsCachedList();
    if (task) {
      this.createUnitsCachedList();
    }
  }

  private clearUnitsCachedList() {
    if (this.cachedUnits !== null) {
      this.unitsRefreshSub.unsubscribe();
      this.unitsRefreshSub = null;

      this.cachedUnits.clear();
      this.cachedUnits = null;
    }
  }

  private createUnitsCachedList() {
    this.cachedUnits = this.unitListFetchingService.createFilteredUnitActivitiesFromCache(unitActivity =>
      this.isForMyTask(unitActivity as UnitActivityMDP)
    );
    this.unitsRefreshSub = this.cachedUnits.$refresh.subscribe((guid: Guid) => this.onUnitChanged(guid));
  }

  private isForMyTask(unitActivity: UnitActivityMDP): boolean {
    return (
      GuidTools.isValid(unitActivity.TaskID) &&
      this.currentTaskService.current &&
      unitActivity.TaskID === this.currentTaskService.current.ObjGuid
    );
  }

  private onUnitChanged(guid: Guid) {
    this._unitChangedEvent.next(guid);
  }
}
