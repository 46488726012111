import Feature from 'ol/feature';
import { SelectInteraction } from '@techwan/mapping';
import { Observable, Subject } from 'rxjs';

export interface IMoveEvent {
  feature: Feature;
  newCoordinate: ol.Coordinate;
}

export class MoveInteraction extends SelectInteraction {
  private readonly _event = new Subject<IMoveEvent>();
  get $event(): Observable<IMoveEvent> {
    return this._event.asObservable();
  }

  constructor(filter?: ol.SelectFilterFunction) {
    super({ multi: false, filter });

    this.on('select', this.onFeatureSelected);
  }

  destroy() {
    this.un('select', this.onFeatureSelected);
    this.getFeatures().clear();
  }

  private onFeatureSelected(event: ol.interaction.Select.Event) {
    if (event.selected.length === 0 && event.deselected.length > 0) {
      const feature = event.deselected[0];
      this._event.next({ feature, newCoordinate: event.mapBrowserEvent.coordinate });
    }
  }
}
