<div *ngIf="show" class="callcard-list-banner">
  <app-callcard-list-banner-item
    *ngIf="myCard && myCard.myCard"
    [callcard]="myCard.myCard"
    [current]="current"
    [class.my-card]="true"
    [class.selected]="current.current === myCard.myCard"
    (click)="current.toggle(myCard.myCard)"
  ></app-callcard-list-banner-item>
  <div class="list">
    <app-callcard-list-banner-item
      *ngFor="let c of cards.items"
      [callcard]="c"
      [current]="current"
      [class.ion-hide]="myCard.myCard === c"
      [class.selected]="current.current === c"
      [class.my-area]="inMyArea(c)"
      [class.my-region]="inMyRegion(c)"
      (click)="current.toggle(c)"
    >
    </app-callcard-list-banner-item>
  </div>
</div>
