import { Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';

function isDefined(value: any): boolean {
  return typeof value !== 'undefined' && value !== null;
}

@Injectable()
export class SagaTranslateParser extends TranslateParser {
  templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  public interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  /**
   * Retrieve the value of a key. If the complete key is not found in the dictionary, the parser go through the
   * dictionary attributes.
   * @param target
   * @param key
   */
  getValue(target: any, key: string): any {
    let value = SagaTranslateParser.interalGet(target, key);
    return 'undefined' === typeof value ? SagaTranslateParser.interalGet(target, key.toLowerCase()) : value;
  }

  private static interalGet(target: any, key: string): any {
    if ('string' === typeof target[key]) {
      return target[key];
    }

    let keys = key.split('.');
    key = '';
    do {
      let completeKey = keys.join('.');
      key += keys.shift();
      if (isDefined(target) && isDefined(target[completeKey])) {
        target = target[completeKey];
        keys = [];
      } else if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        key = '';
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += '.';
      }
    } while (keys.length);

    return target;
  }

  private interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      let r = this.getValue(params, b);
      return isDefined(r) ? r : substring;
    });
  }
}
