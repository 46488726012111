// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { UserProfile_abstract } from './../abstract/SagaBase/UserProfile_abstract';
import { IWebConfig, IWebLayout, IWebProfile } from '@models/profile';

export class UserProfile extends UserProfile_abstract {
  modules: string[];
  admin: boolean;
  webConfig: IWebConfig;
  webProfile: IWebProfile;
  webLayout: IWebLayout;
}
