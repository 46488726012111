import { ILocation } from '@models/interfaces/ILocation';
import { DefaultLocationFormatter } from './default-location-formatter';

export class PnLocationFormatter extends DefaultLocationFormatter {
  format(location: ILocation): string[] {
    const streetName = `${location.StreetNumber} ${location.PlaceName}`.trim();
    const city = `${location.Zip} ${location.CityName}`.trim();
    const complement = `${location.Complement}`.trim();

    return [[streetName, city, complement].filter(s => s).join(', ')];
  }
}
