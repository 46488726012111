import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface ILeavingDrawingEdition {
  okButton: { text: string; value: number };
  cancelButton: { text: string; value: number };
  title: string;
  message: string;
}

@Component({
  templateUrl: './leaving-drawing-edition.component.html',
  styleUrls: ['./leaving-drawing-edition.component.scss']
})
export class LeavingDrawingEditionComponent implements OnInit, ILeavingDrawingEdition {
  okButton: { text: string; value: number };
  cancelButton: { text: string; value: number };
  title: string;
  message: string;

  constructor(private _modal: ModalController) {}

  ngOnInit() {}

  close(value: number): Promise<boolean> {
    return this._modal.dismiss(value === this.okButton.value);
  }
}
