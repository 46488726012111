import { Directive, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { RowsComponent } from '../rows/rows.component';

@Directive({
  selector: '[appTopLayoutSplitterActivator]'
})
export class TopLayoutSplitterActivatorDirective implements OnInit, OnDestroy {
  constructor(@Optional() @Inject(RowsComponent) private rows?: RowsComponent) {}

  ngOnInit() {
    if (this.rows) {
      this.rows.setTopLayoutSplitterNeeded(true);
    }
  }

  ngOnDestroy() {
    if (this.rows) {
      this.rows.setTopLayoutSplitterNeeded(false);
    }
  }
}
