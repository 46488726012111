// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:40
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ScheduleDatePeriod_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.ScheduleDatePeriod';
  $t: string = 'SagaBase.Defines.ScheduleDatePeriod';

  public static GetMembers(): string[] {
    return ['BeginUtc', 'EndUtc', 'AvailabilityPourcent'];
  }

  public GetMembers(): string[] {
    return ScheduleDatePeriod_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.BeginUtc = this.BeginUtc && new Date(this.BeginUtc);
    this.EndUtc = this.EndUtc && new Date(this.EndUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public BeginUtc: Date = new Date();
  public EndUtc: Date = new Date();
  public AvailabilityPourcent: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
