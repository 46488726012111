<ion-list class="popover-page" no-lines style="margin: 0">
    <ng-container *ngFor="let item of moreItems" >
        <div *ngIf="!item.hidden" [ngSwitch]="item.type">
            <component-loader *ngSwitchCase="'component'" [component]="item.component"></component-loader>
            <ion-item *ngSwitchCase="'checkbox'">
                <ion-label><span [translate]="item.title"></span></ion-label>
                <ion-checkbox [checked]="item.checked" [(ngModel)]="item.checked" (ngModelChange)="clickItem(item)"></ion-checkbox>
            </ion-item>
            <ion-item *ngSwitchDefault (click)="clickItem(item)">
                <ion-icon *ngIf="item.icon" [name]="item.icon" item-start></ion-icon>
                <span [translate]="item.title"></span>
            </ion-item>
        </div>
    </ng-container>

</ion-list>
