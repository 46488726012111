// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class MobileMessageArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.MobileMessageArgs';
  $t: string = 'SagaBase.Defines.MobileMessageArgs';

  public static GetMembers(): string[] {
    return [
      'UpdatedMessage',
      'Status',
      'TargetNames',
      'Targets',
      'DeviceType',
      'ParentMessageUid',
      'SiteId',
      'SiteName',
      'Message',
      'UserId',
      'UserName',
      'ParentUId',
      'CoordX',
      'CoordY'
    ];
  }

  public GetMembers(): string[] {
    return MobileMessageArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public UpdatedMessage: any;
  public Status: SagaBase.MediaMessageStatus = SagaBase.MediaMessageStatus.Info;
  public TargetNames: string;
  public Targets: string;
  public DeviceType: SagaBase.DeviceTypes = SagaBase.DeviceTypes.NA;
  public ParentMessageUid: Guid = GuidTools.Empty;
  public SiteId: number = 0;
  public SiteName: string;
  public Message: any;
  public UserId: number = 0;
  public UserName: string;
  public ParentUId: Guid = GuidTools.Empty;
  public CoordX: number;
  public CoordY: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
