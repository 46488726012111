import { CurrentCardService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { CacheService } from '@services/cache/cache.service';
import { CardParticularsWrapper } from '../../schema/card-particulars-wrapper';
import { PCE_DESCRIPTION_VALUE } from '../../schema/constants';
import { CallcardDetailParticularsComponent } from '../callcard-detail-particulars/callcard-detail-particulars.component';

@Component({
  selector: 'app-callcard-detail-cperson',
  templateUrl: './callcard-detail-cperson.component.html',
  styleUrls: ['./callcard-detail-cperson.component.scss']
})
export class CallcardDetailCPersonComponent extends CallcardDetailParticularsComponent<CPerson> implements OnInit {
  @Input()
  displayPCE: boolean = false;

  private readonly _cPersonWrappers: CardParticularsWrapper<CPerson>[] = [];
  get cPersonWrappers(): CardParticularsWrapper<CPerson>[] {
    return this._cPersonWrappers;
  }

  constructor(protected readonly _currentCardService: CurrentCardService, protected readonly _cacheService: CacheService) {
    super(_currentCardService, _cacheService);
  }

  protected onAdd(cPerson: CPerson) {
    if (this.displayPCE === this.isPCE(cPerson)) {
      this._cPersonWrappers.push(new CardParticularsWrapper<CPerson>(cPerson));
    }
  }

  protected onRemove(cPerson: CPerson) {
    if (this.displayPCE === this.isPCE(cPerson)) {
      const wrapperToDelete = this._cPersonWrappers.find(personWrapper => personWrapper.wrappedParticular === cPerson);
      this._cPersonWrappers.splice(this._cPersonWrappers.indexOf(wrapperToDelete), 1);
    }
  }

  isPCE(cPerson: CPerson): boolean {
    return cPerson.Description === PCE_DESCRIPTION_VALUE;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.callcard) {
      this.createWrappersOf(CPerson)
        .filter(wrapper => this.displayPCE === this.isPCE(wrapper.wrappedParticular))
        .forEach(personWrapper => {
          this._cPersonWrappers.push(personWrapper);
        });
      this._cacheSubscriptions.push(this.getFilteredCacheSubscriptionForClassName(CPerson.$t));
    }
  }
}
