import { Component, OnInit } from '@angular/core';
import { ViewBaseComponent } from '../../components/view-base/view-base.component';
import { DocumentService } from '../../services/document/document.service';

@Component({
  selector: 'app-full-view',
  templateUrl: './full-view.component.html',
  styleUrls: ['./full-view.component.scss']
})
export class FullViewComponent extends ViewBaseComponent implements OnInit {
  constructor(_documentService: DocumentService) {
    super(_documentService);
  }
}
