import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../modules/shared/shared.module';
import { SagaDirectivesModule } from './directives';

import { RootComponent } from './components/root/root.component';

const routes: Routes = [{ path: '', component: RootComponent }];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    IonicModule,
    SagaDirectivesModule
  ],
  exports: [RouterModule, SagaDirectivesModule]
})
export class AppRoutingModule {}
