import { Directive, Input, Host, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { NavService } from '@services/navigation/navigation.service';

@Directive({ selector: 'ion-nav[register]' })
export class NavDirective implements OnInit {
  @Input('register') _name: string;
  get name(): string {
    return this._name ? this._name : undefined;
  }
  constructor(private navService: NavService, @Host() private nav: IonNav) {}

  ngOnInit(): any {
    this.navService.register(this.nav, this.name);
  }
}
