import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PanelContentBaseComponent } from './components/panel-content-base/panel-content-base.component';
import { PanelContentComponent } from './components/panel-content/panel-content.component';
import { PanelPinComponent } from './components/panel-pin/panel-pin.component';
import { ContentLoaderService } from './services/content-loader/content-loader.service';
import { AdditionalViewToolsService } from './services/panel-factory/additional-view-tools.service';

const components = [PanelContentComponent, PanelPinComponent, PanelContentBaseComponent];

const providers = [AdditionalViewToolsService, ContentLoaderService];

@NgModule({
  declarations: [PanelContentComponent, PanelPinComponent, PanelContentBaseComponent],
  imports: [CommonModule, IonicModule],
  exports: [PanelContentComponent, PanelPinComponent],
  providers
})
export class PanelModule {}
