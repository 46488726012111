import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomComponentsService {
  private _customComponents: Type<any>[] = [];
  get customComponents(): Type<any>[] {
    return this._customComponents;
  }

  constructor() {}

  add(customComponent: Type<any>): void {
    this._customComponents.push(customComponent);
  }
}
