import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Area } from '@models/imported/SagaBase/Area';
import { Zone } from '@models/imported/SagaBase/Zone';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectorProxyService {
  constructor(private readonly proxy: HttpClient, private readonly claims: RefreshTokenStorageService) {}

  getAllParents(): Observable<Zone[]> {
    const params = new HttpParams().append('siteId', String(this.getSiteId()));
    return this.proxy.get<Zone[]>('api/Gis/GetAllParentSectors', { params });
  }

  getAreaBySectorId(): Observable<Area> {
    const params = new HttpParams().append('siteId', String(this.getSiteId()));
    return this.proxy.get<Area>('api/Gis/GetAreaBySectorId', { params });
  }

  getSiteId(): number {
    return this.claims.read().login.SagaUserAuth.SiteId;
  }
}
