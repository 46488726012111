<ion-item-group>
  <ion-item-divider>
    <ion-label translate>Mobile.OfflineMap</ion-label>
  </ion-item-divider>

  <ion-item [disabled]="isDownloadOverWifiSettingDisabled">
    <ion-label translate (click)="downloadOverWifiOnlyToggle.checked = !downloadOverWifiOnlyToggle.checked"
      >Mobile.MapOfflineDownloadOverWifiOnly</ion-label
    >
    <ion-toggle
      #downloadOverWifiOnlyToggle
      [checked]="downloadOverWifiOnly"
      (ionChange)="onDownloadOverWifiOnlyChange($event)"
    ></ion-toggle>
  </ion-item>

  <ion-item (click)="onOfflineMapClicked()" lines="none">
    <ion-icon slot="end" name="cloud-download-outline" color="primary"></ion-icon>
    <ion-label>{{ offlineMapButtonText | translate: offlineMapButtonTextParam }}</ion-label>
  </ion-item>

  <ion-progress-bar *ngIf="hasProgress" [value]="progress"></ion-progress-bar>

  <ion-item *ngIf="isDownloadStarted" lines="none">
    <ion-col>
      <ion-button expand="block" fill="clear" (click)="onPause()">{{ pauseButtonText | translate }}</ion-button>
    </ion-col>
    <ion-col>
      <ion-button expand="block" fill="clear" (click)="onCancel()" translate>Mobile.Cancel</ion-button>
    </ion-col>
  </ion-item>
</ion-item-group>
