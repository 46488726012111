import { PanelContent, PanelContentBaseComponent } from '@addins/core/panel';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Output } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StatusChangeResult } from '../../schema/interfaces/status-change-result';
import { StatusChangeRequirementsService } from '../../services/status-change-requirements/status-change-requirements.service';
import { StatusListService } from '../../services/status-list/status-list.service';

@Component({
  selector: 'app-status-list',
  templateUrl: './status-list.component.html',
  styleUrls: ['./status-list.component.scss'],
  providers: [StatusListService]
})
export class StatusListComponent extends PanelContentBaseComponent implements OnInit, OnDestroy, PanelContent {
  get myUnit(): UnitActivity {
    return this._statusListService.myUnit;
  }

  protected _sub: Subscription = null;

  @Output()
  get $change(): Observable<StatusChangeResult> {
    return this._statusListService.$change;
  }

  get statuses(): ListResourceStatus[] {
    return this._statusListService.mobileStatuses;
  }

  constructor(
    protected readonly _statusListService: StatusListService,
    protected readonly _statusChangeRequirementsService: StatusChangeRequirementsService,
    protected readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  change(status: ListResourceStatus) {
    this._statusChangeRequirementsService.getRequestParameter(status).then(
      requestParameter => this._statusListService.change(status, requestParameter),
      err => err
    );
  }

  ngOnInit() {
    this._sub = this._statusListService.$ready.pipe(filter(isReady => isReady)).subscribe(() => this.onInit());
    if (this.myUnit !== null) {
      this.clean();
    }
  }

  ngOnDestroy() {
    this.clean();
  }

  protected onInit() {
    this.clean();
    this._changeDetectorRef.detectChanges();
  }

  private clean() {
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }
}
