// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @24.08.2017 14:37:35
import { MobEvents_abstract } from './../../../../../abstract/TW/Saga/Core/Base/Mobile/MobEvents_abstract';
import { SearchResult } from '@models/imported/SagaBase/PersistentObject';

export class MobEvents extends MobEvents_abstract {
  get text() {
    return this.EvtText;
  }

  get id() {
    return this.EvtEventId;
  }

  search(term: string): SearchResult {
    let path = null;
    let searchTerm = term.toLocaleLowerCase();
    if (this.EvtText.toLowerCase().includes(searchTerm)) {
      path = 'EvtText';
    }
    return new SearchResult(term, this, path);
  }
}
