import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { MomentService } from '@techwan/ionic-core';
import { ICardInfoFormatter } from '../../schema/interfaces/card-info-formatter';
import { DefaultCardInfoFormatter } from '../../schema/default-card-info-formatter';

@Injectable()
export class CardInfoFormatterService {
  private _formatter: ICardInfoFormatter;

  constructor(private readonly moment: MomentService) {
    this._formatter = new DefaultCardInfoFormatter(this.moment);
  }

  public override(formatter: ICardInfoFormatter) {
    this._formatter = formatter;
  }

  public format(card: CallCard, short = false): string[] {
    return this._formatter.format(card, short);
  }
}
