import { Injectable } from '@angular/core';

export enum IconType {
  Base64,
  Ionic,
  Material,
  Svg,
  Url
}

export interface IconData {
  type: IconType;
  name: string;
  font: string;
}

@Injectable()
export class IconService {
  constructor() {}

  parse(iconName: string): IconData {
    let type: IconType = IconType.Ionic;
    let name: string = iconName ? iconName.trim() : '';
    let font: string = '';

    if (iconName.endsWith('.svg')) {
      type = IconType.Svg;
    } else if (iconName.startsWith('data:')) {
      type = IconType.Base64;
    } else if (iconName.includes('/')) {
      type = IconType.Url;
    } else if (iconName.startsWith('md-')) {
      type = IconType.Material;
      name = iconName.replace(/^md-/, '').trim();
      font = 'Material Icons';
    }

    return {
      type,
      name,
      font
    };
  }
}
