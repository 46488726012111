<ion-item [lines]="lines" [class]="cssClass">
  <ion-label class="ion-text-wrap">
    <h2>
      <strong>{{ callcardTitle }}</strong>
    </h2>

    <app-callcard-list-location *ngIf="callcard.location" [location]="callcard.location"></app-callcard-list-location>

    <app-callcard-list-custom-content
      *ngFor="let customComponent of customComponents"
      [customComponent]="customComponent"
      [callcard]="callcard"
      [isMyCallcard]="isMyCallcard"
    ></app-callcard-list-custom-content>
  </ion-label>

  <app-callcard-list-custom-content
    *ngFor="let customComponent of customSlotComponents"
    [customComponent]="customComponent"
    [slot]="customComponent.slot"
    [callcard]="callcard"
    [isMyCallcard]="isMyCallcard"
  ></app-callcard-list-custom-content>

  <ion-icon slot="end" name="location" (click)="onLocalize($event)" [size]="locationIconSize"></ion-icon>
</ion-item>
