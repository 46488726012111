// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { EventEmitter } from '@angular/core';
import { ExternalSearchResult } from '@models/ExternalSearchResult';
import { SearchResult } from '@models/imported/SagaBase/PersistentObject';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { CNote } from '@models/imported/SagaSchema/CNote';
import { CStamp } from '@models/imported/SagaSchema/CStamp';
import { ListCardStatus } from '@models/imported/SagaStateLib/ListCardStatus';
import { List } from '@models/list';
import * as moment from 'moment';
import { Color } from '../../color';
import { CallCard_abstract } from './../abstract/SagaSchema/CallCard_abstract';
import { CCaller } from './CCaller';
import { CEvent } from './CEvent';
import { CLocation } from './CLocation';

export class CallCard extends CallCard_abstract {
  static globalIcon: string = 'md-insert_drive_file';

  private _stamps: List<CStamp> = new List<CStamp>();
  private _status: ListCardStatus = null;

  private _page: number = 0;
  private _callers: CCaller[] = [];

  firstCaller: CCaller = null;

  refreshStamps = new EventEmitter<void>();

  lastStampsLoading: Date;

  private _isStampLoading: boolean;
  get isStampLoading(): boolean {
    return this._isStampLoading;
  }

  set isStampLoading(value: boolean) {
    this._isStampLoading = value;

    if (!value) {
      this.hasDatabaseChanged = false;
    }
  }

  externalSearchs: { Far?: ExternalSearchResult } = {
    Far: new ExternalSearchResult()
  };

  get icon() {
    return CallCard.globalIcon;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  get status(): ListCardStatus {
    return this._status;
  }

  set status(status: ListCardStatus) {
    this._status = status;
  }

  constructor() {
    super();
    this.stamps.addSort((a, b) => b.CreatedUTC.getTime() - a.CreatedUTC.getTime());
  }

  getAttachments(): CAttachment[] {
    return this._stamps.source.filter(stamp => {
      return stamp instanceof CAttachment;
    }) as any[];
  }

  loadStamps(stamps: CStamp[]) {
    this.lastStampsLoading = new Date();
    stamps.forEach(stamp => {
      stamp.isNew = !this._stamps.source.find(previous => previous.ObjGuid === stamp.ObjGuid);
    });
    this._stamps.source = stamps;
  }

  markRead() {
    this._stamps.source.forEach(stamp => (stamp.isNew = false));
  }

  get colorArray(): number[] {
    return this.status && this.status.color && this.status.color.toArray();
  }

  get color(): Color {
    return this.status && this.status.color;
  }

  get colorRgb(): string {
    return this.status && this.status.color && this.status.color.tinycolor.toRgbString();
  }

  get location(): CLocation {
    return this.component<any>('SagaSchema.CLocation').length ? this.component<any>('SagaSchema.CLocation')[0] : null;
  }

  get event(): CEvent {
    return this.component<any>('SagaSchema.CEvent').length
      ? this.component<any>('SagaSchema.CEvent').find(event => event.IsSubsequent === false)
      : null;
  }

  get callers(): CCaller[] {
    return this._callers;
  }

  set callers(callers: CCaller[]) {
    this._callers = callers;
  }

  get caller(): CCaller {
    return this.firstCaller
      ? this.firstCaller
      : this.component<any>('SagaSchema.CCaller').length
      ? this.component<any>('SagaSchema.CCaller')[0]
      : null;
  }

  get note(): CNote {
    return this.component<any>(CNote).length ? this.component<any>(CNote)[0] : null;
  }

  get stamps(): List<CStamp> {
    return this._stamps;
  }

  rShort(): string {
    return moment(this.CreatedUTC).format('LL') + ' | ' + this.event.EventText;
  }

  rLong(): string[] {
    return [moment(this.CreatedUTC).format('LLL') + ' | ' + this.event.EventText];
  }

  search(term: string): SearchResult {
    let path = null;
    const searchTerm = term.toLocaleLowerCase();
    if (this.event.EventText.toLowerCase().includes(searchTerm)) {
      path = 'event';
    } else if (this.location.text.toLowerCase().includes(searchTerm)) {
      path = 'location';
    }
    return new SearchResult(term, this, path);
  }
}
