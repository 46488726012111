import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { MapSettingsService } from '../../map-settings/map-settings.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { MODULE_NAME } from '@addins/core/map/schema';
import { filter, first } from 'rxjs/operators';
import { ProfileService } from '@services/profile/profile.service';

@AddinExecute({
  name: 'settings-loader'
})
@Injectable()
export class SettingsLoaderService extends Execute {
  constructor(
    private mapSettingsService: MapSettingsService,
    private profileReaderService: ProfileReaderService,
    private profileService: ProfileService
  ) {
    super();
  }

  execute(): void {
    this.profileService.ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.mapSettingsService.showScaleChoice = this.profileReaderService.getComponentConfig(MODULE_NAME, 'scaleChoice', true);
      });
  }
}
