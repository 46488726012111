import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IAppConfig, ISagaEnvironment } from '@environments/saga-environment';

export const ENVIRONMENT_VALUE = new InjectionToken<ISagaEnvironment>('SagaEnvironment');

@Injectable()
export class EnvironmentService {
  get devMode(): boolean {
    return !this.env.production;
  }

  get prodMode(): boolean {
    return this.env.production;
  }

  constructor(@Inject(ENVIRONMENT_VALUE) private readonly env: ISagaEnvironment) {}

  getConfig(): IAppConfig {
    return this.get('config');
  }

  getAddin<T>(name: string): T {
    const p = this.get('addinParameters');
    return (p && p[name]) || {};
  }

  get(name: string) {
    return this.env[name];
  }
}
