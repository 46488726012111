// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { Coordinate } from '../../coordinate';
import { IFormattedLocation } from '../../interfaces/IFormattedLocation';
import { ILocation } from '../../interfaces/ILocation';
import { CLocationBase_abstract } from './../abstract/SagaSchema/CLocationBase_abstract';

export class CLocationBase extends CLocationBase_abstract implements ILocation, IFormattedLocation {
  FormattedLocation: string[];

  get text(): string {
    return this.FormattedLocation.length ? this.FormattedLocation[0] : '';
  }

  get icon() {
    return 'home';
  }

  get coordinate(): Coordinate {
    return {
      x: this.CenterX,
      y: this.CenterY,
      epsg: this.CoordEpsgCode
    };
  }

  set coordinate(coordinate: Coordinate) {
    this.CenterX = coordinate.x;
    this.CenterY = coordinate.y;
    this.CoordEpsgCode = coordinate.epsg;
  }
}
