<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title style="line-height: 1em;">
      <span>{{ currentCallCard?.rShort() }}</span
      ><br />
      <span style="font-size: 0.6em">{{ currentCallCard?.CreatedUTC | moment: 'L LT' }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-segment color="light" [(ngModel)]="page">
      <ion-segment-button [value]="0">
        <ion-icon name="information-circle"></ion-icon>
      </ion-segment-button>
      <ion-segment-button [value]="1">
        <ion-icon name="list"></ion-icon>
        <ion-icon *ngIf="currentCallCard.hasDatabaseChanged" name="alert" class="changed" color="danger"></ion-icon>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content>
  <callcard-detail-content *ngIf="currentCallCard" [callcard]="currentCallCard"></callcard-detail-content>
</ion-content>
<ion-tab-bar>
  <ion-tab-button (click)="openMap()">
    <ion-icon name="map"></ion-icon>
  </ion-tab-button>
  <ion-tab-button *ngIf="page == 1 && stamps.showAddButton()" (click)="stamps.open($event, currentCallCard)">
    <ion-icon name="add"></ion-icon>
  </ion-tab-button>
  <ion-tab-button *ngIf="page == 1 && hasMedia" (click)="addPicture()">
    <ion-icon name="camera"></ion-icon>
  </ion-tab-button>
  <ion-tab-button *ngIf="page == 1" (click)="refreshStamp()">
    <ion-icon *ngIf="!currentCallCard.isStampLoading" name="refresh"></ion-icon>
    <ion-spinner *ngIf="currentCallCard.isStampLoading"></ion-spinner>
  </ion-tab-button>
</ion-tab-bar>
