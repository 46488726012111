// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class UserProfile_abstract extends PersistentObject {
  static $t: string = 'SagaBase.UserProfile';
  $t: string = 'SagaBase.UserProfile';

  public static GetMembers(): string[] {
    return [
      'ApplicationProfileId',
      'ApplicationId',
      'ApplicationProfileAlternateId',
      'Name',
      'glblProfileid',
      'Locked',
      'BootList',
      'Components',
      'Layout',
      'AlternateLayout',
      'CustomTabs',
      'WebProfile',
      'WebLayout',
      'WebConfig',
      'WebUserAppProfile',
      'IsHidden'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return UserProfile_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ApplicationProfileId: number = 0;
  public ApplicationId: string;
  public ApplicationProfileAlternateId: number = 0;
  public Name: string;
  public glblProfileid: number;
  public Locked: number = 0;
  public BootList: string;
  public Components: string;
  public Layout: string;
  public AlternateLayout: string;
  public CustomTabs: string;
  public WebProfile: string;
  public WebLayout: string;
  public WebConfig: string;
  public WebUserAppProfile: string;
  public IsHidden: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.ApplicationProfileId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
