import { ReportService } from '@addins/core/followup-stamps-tools';
import { AddStampButtonsProviderService, AddStampTool } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CAnimal } from '@models/imported/SagaSchema/CAnimal';
import { CObject } from '@models/imported/SagaSchema/CObject';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { CVehicle } from '@models/imported/SagaSchema/CVehicle';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@services/events/events.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { ADDIN_VIEW_EVENT_KEY, AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';

@AddinExecute({
  name: 'Tools',
  description: 'Provide some tools for PN'
})
@Injectable()
export class PnToolProviderService extends Execute {
  private _openCardTool: Tool;
  private _sub: Subscription;

  // Add Stamp tools
  private _addFreetextTool: AddStampTool;
  private _addPerson0Tool: AddStampTool;
  private _addPerson1Tool: AddStampTool;
  private _addVehicleTool: AddStampTool;
  private _addAnimalTool: AddStampTool;
  private _addObjectTool: AddStampTool;
  private _addCroTool: AddStampTool;

  constructor(
    private toolsProvider: ToolsProviderService,
    private events: Events,
    private reportService: ReportService,
    private addStampButtonsProviderService: AddStampButtonsProviderService,
    private profileReader: ProfileReaderService,
    private translate: TranslateService
  ) {
    super();
  }

  init(): void {
    this._openCardTool = Tool.createTool({
      text: this.translate.instant('Mobile.OpenCard'),
      icon: 'document',
      execute: async context => this.openCard(context[0])
    });

    this.createAddStampTools();
  }

  private openCard(card: CallCard) {
    this.events.publish(ADDIN_VIEW_EVENT_KEY, 'singlecallcard/PageView', {
      card
    });
  }

  private createAddStampTools() {
    this._addFreetextTool = new AddStampTool('SagaSchema.CStampFreeText', callcard => this.reportService.addOrEditFreetext(callcard));
    this._addPerson0Tool = new AddStampTool('SagaSchema.CStampParticulars_2', callcard => this.reportService.add(callcard, CPerson));
    this._addPerson1Tool = new AddStampTool('SagaSchema.CStampParticulars_2_1', callcard =>
      this.reportService.add(callcard, CPerson, {
        type: 1
      })
    );
    this._addVehicleTool = new AddStampTool('SagaSchema.CStampParticulars_3', callcard => this.reportService.add(callcard, CVehicle));
    this._addAnimalTool = new AddStampTool('SagaSchema.CStampParticulars_0', callcard => this.reportService.add(callcard, CAnimal));
    this._addObjectTool = new AddStampTool('SagaSchema.CStampParticulars_1', callcard => this.reportService.add(callcard, CObject));
    this._addCroTool = new AddStampTool('CStampResource_1_1024', callcard => this.reportService.addOrEditCro(callcard));
  }

  execute(): void {
    this._sub = this.toolsProvider.register(provider => this.provideTool(provider));
    const addStampTools: boolean = this.profileReader.getComponentConfig('PnAddin', 'addStampTools', false);
    if (addStampTools) {
      this.addStampTools();
    }
  }

  private provideTool(provider: ToolsProvider): void {
    const object = provider.context[0];
    if (object instanceof CallCard) {
      provider.addTool(this._openCardTool);
    }
  }

  private addStampTools() {
    this.addStampButtonsProviderService.addButton(this._addFreetextTool);
    this.addStampButtonsProviderService.addButton(this._addPerson0Tool);
    this.addStampButtonsProviderService.addButton(this._addPerson1Tool);
    this.addStampButtonsProviderService.addButton(this._addVehicleTool);
    this.addStampButtonsProviderService.addButton(this._addAnimalTool);
    this.addStampButtonsProviderService.addButton(this._addObjectTool);
    this.addStampButtonsProviderService.addButton(this._addCroTool);
  }

  destroy() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }
}
