<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>SagaSchema.CMediaMessage</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label position="stacked" class="ion-text-right"> {{ text.length }} / {{ MAX_TEXT_LENGTH }} </ion-label>
      <ion-textarea #textAreaMessage [maxlength]="MAX_TEXT_LENGTH" [autoGrow]="true" [(ngModel)]="text"> </ion-textarea>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-buttons>
    <ion-fab-button (click)="close()">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-fab-button>
    <ion-fab-button (click)="createMessage(text)" [disabled]="!text">
      <ion-icon name="checkmark"></ion-icon>
    </ion-fab-button>
  </ion-buttons>
</ion-footer>
