import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Tile from 'ol/layer/tile';
import { IonToggle } from '@ionic/angular';

@Component({
  selector: 'app-overlay-layer-select',
  templateUrl: './overlay-layer-select.component.html',
  styleUrls: ['./overlay-layer-select.component.scss']
})
export class OverlayLayerSelectComponent implements OnInit {
  @Input()
  item: Tile;

  @ViewChild(IonToggle, { static: true })
  private _toggle: IonToggle;

  constructor() {}

  ngOnInit() {}

  toggle(e: MouseEvent): void {
    this._toggle.checked = !this.item.getVisible();
  }

  onChange($event: CustomEvent): void {
    this.item.setVisible($event.detail.checked);
  }
}
