import { Component, OnInit } from '@angular/core';
import { HeaderProviderService } from '../../services/header-provider/header-provider.service';

@Component({
  selector: 'app-callcard-list-header',
  templateUrl: './callcard-list-header.component.html',
  styleUrls: ['./callcard-list-header.component.scss']
})
export class CallcardListHeaderComponent implements OnInit {
  public get view(): string {
    return this._provider.getView();
  }

  constructor(private readonly _provider: HeaderProviderService) {}

  ngOnInit() {}
}
