// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CStampParticulars_abstract } from './../abstract/SagaSchema/CStampParticulars_abstract';
import { ParticularsStampType } from '@models/imported/SagaBase';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

const propertyNames = {};
propertyNames[ParticularsStampType.CAnimal] = ['Race', 'Identification', 'Proprietor', 'Text'];
propertyNames[ParticularsStampType.CObject] = ['Kind', 'Identification', 'Make', 'Text'];
propertyNames[ParticularsStampType.CPerson] = [
  'LastName',
  'FirstName',
  'PersonQualifier',
  'DateOfBirth',
  'Nationality',
  'Gender',
  'Address',
  'Location',
  'FieldGeneric1',
  'Phone',
  'Kind',
  'Age',
  'Build',
  'Height',
  'EyeColor',
  'HairColor',
  'Dress',
  'Particulars',
  'Text'
];
propertyNames[ParticularsStampType.CVehicle] = [
  'Registration',
  'RunningDirection',
  'Country',
  'Make',
  'Model',
  'Color',
  'Kind',
  'LinkedDescription',
  'Remarks'
];

export class CStampParticulars extends CStampParticulars_abstract {
  static propertyNames = propertyNames;
  private _values: any;

  get values() {
    return this._values;
  }

  get followupComponent(): any {
    return null; // CStampParticularsFollowupComponent;
  }

  get title() {
    return this.$t + '_' + this.ParticularsType;
  }

  get typeName(): string {
    let typeName = '';
    switch (this.ParticularsType) {
      case ParticularsStampType.CVehicle:
        typeName = 'CVehicle';
        break;
      case ParticularsStampType.CAnimal:
        typeName = 'CAnimal';
        break;
      case ParticularsStampType.CPerson:
        typeName = 'CPerson';
        break;
      case ParticularsStampType.CObject:
        typeName = 'CObject';
        break;
    }
    return typeName;
  }

  get icon(): string {
    let iconName = '';
    switch (this.ParticularsType) {
      case 0:
        iconName = 'paw';
        break;
      case 1:
        iconName = 'briefcase';
        break;
      case 2:
        iconName = 'person';
        break;
      case 3:
        iconName = 'car';
        break;
    }
    return iconName;
  }

  public changed(change: CacheChangedInterface) {
    super.changed(change);

    const properties = CStampParticulars.propertyNames[this.ParticularsType];
    const rawValues = this.Values.split('|');
    const values = {};
    for (let i = 0; i < properties.length; i++) {
      const name = properties[i];
      if (rawValues[i]) {
        values[name] = rawValues[i];
      }
    }
    this._values = values;
  }

  get text() {
    return this.rShort();
  }

  rShort() {
    return Object.keys(this.values)
      .map(key => this.values[key])
      .filter(s => s)
      .join('\n');
  }
}
