import { ComponentRef, Injectable } from '@angular/core';

/**
 * @name HomeCard
 * @description
 * Object representing an Home Card
 */
export class HomeCard {
  public type: any;
  public cmpRef: ComponentRef<any>;

  private _priorityChangeHandlers: Function[] = [];
  private _priority: number = 0;
  get priority(): number {
    return this._priority;
  }
  set priority(value: number) {
    this._priority = value;
    this._priorityChangeHandlers.forEach(h => h(value));
  }

  /**
   * @constructor
   * @param {any} type The component type to use
   * @param {number} ?priority The priority of card (default 0)
   */
  constructor(type: any, priority: number = 0) {
    this.type = type;
    this._priority = priority;
  }

  /**
   * Register a priority change handler
   * @param {Function} handler to call when the priority change
   * @returns {Function} unregister function
   */
  public onPriorityChange(handler: Function): Function {
    this._priorityChangeHandlers.push(handler);
    return (): void => {
      this.removePriorityChange(handler);
    };
  }

  /**
   * Unregister a priority change handler, or all if none is given
   * @param {Function?} handler The handler to remove
   */
  public removePriorityChange(handler?: Function): void {
    if (!handler) {
      this._priorityChangeHandlers = [];
    } else {
      var i = this._priorityChangeHandlers.indexOf(handler);
      if (i != -1) {
        this._priorityChangeHandlers.splice(i, 1);
      }
    }
  }
}

/**
 * @name HomeService
 * @description
 * Home service manage the Home Card displaying in the HomeView
 */
@Injectable()
export class HomeService {
  public cards: Array<HomeCard> = [];

  /**
   * Sort card by priority
   */
  public sort() {
    this.cards.sort(function(a, b) {
      return b.priority - a.priority;
    });
  }

  /**
   * Register a home card to display in the home view
   * @param {HomeCard} card The Home card to register
   * @returns {Function} Function to unregister the card
   */

  public registerHomeCard(card: HomeCard): Function {
    let unregisterPriorityChange = card.onPriorityChange(() => {
      this.sort();
    });
    this.cards.push(card);
    this.sort();
    return () => {
      let i = this.cards.indexOf(card);
      if (i != -1) {
        this.cards.splice(i, 1);
        unregisterPriorityChange();
        this.sort();
      }
    };
  }
}
