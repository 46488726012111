// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ProcedureAction_abstract extends BaseObject {
  static $t: string = 'SagaActionLib.ProcedureAction';
  $t: string = 'SagaActionLib.ProcedureAction';

  public static GetMembers(): string[] {
    return ['Type', 'IncludesMessage', 'HasExecution', 'HasLoading', 'EditorInterface', 'ConfigurationInterface', 'Context'];
  }

  public GetMembers(): string[] {
    return ProcedureAction_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Type: string;
  public IncludesMessage: boolean = false;
  public HasExecution: boolean = false;
  public HasLoading: boolean = false;
  public EditorInterface: any;
  public ConfigurationInterface: any;
  public Context: any;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
