import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';

@Component({
  templateUrl: 'attachmentViewer.html'
})
export class AttachmentViewerComponent {
  attachment: CAttachment;

  constructor(navParams: NavParams, private viewCtrl: ModalController) {
    this.attachment = navParams.get('attachement');
  }

  close() {
    this.viewCtrl.dismiss();
  }
}
