// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { CDeploymentPoint_abstract } from './../abstract/SagaSchema/CDeploymentPoint_abstract';
import { CacheChangedInterface } from '@services/cache/cache.service';

export class CDeploymentPoint extends CDeploymentPoint_abstract {
  public changed(change: CacheChangedInterface) {
    this._changed.next();
  }

  public clone(): CDeploymentPoint {
    const clone = new CDeploymentPoint();
    clone.SecurityClearance = this.SecurityClearance;
    clone.ObjGuid = this.ObjGuid;
    clone.Owner = this.Owner;
    clone.CreatedUTC = this.CreatedUTC;
    clone.EffectiveDateUtc = this.EffectiveDateUtc;
    clone.ModifiedUTC = this.ModifiedUTC;
    clone.Deleted = this.Deleted;
    clone.UserID = this.UserID;
    clone.UserName = this.UserName;
    clone.OwnerName = this.OwnerName;
    clone.TopMostUid = this.TopMostUid;

    clone.CallCardId = this.CallCardId;
    clone.CoordEpsgCode = this.CoordEpsgCode;
    clone.Text = this.Text;
    clone.Name = this.Name;
    clone.UnitUids = this.UnitUids;
    clone.UnitLabels = this.UnitLabels;
    clone.NotifyUnits = this.NotifyUnits;
    clone.CenterX = this.CenterX;
    clone.CenterY = this.CenterY;
    clone.CenterZ = this.CenterZ;
    return clone;
  }
}
