// Automatically generated from TW.Saga.Shared.MetadataLib, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListStatusCollection_abstract extends PersistentObject {
  static $t: string = 'SagaStateLib.ListStatusCollection';
  $t: string = 'SagaStateLib.ListStatusCollection';

  public static GetMembers(): string[] {
    return ['StatusType', 'LowByte', 'DefaultValue', 'MergeText', 'ListNameSpace'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ListStatusCollection_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public StatusType: string;
  public LowByte: number = 0;
  public DefaultValue: number = 0;
  public MergeText: number = 0;
  public ListNameSpace: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
