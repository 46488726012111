import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormTemplate } from '@models/imported/SagaSchema/FormTemplate';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SagaFormsProxyService {
  constructor(private httpClient: HttpClient, private objectFactory: ObjectFactory) {}

  getFormTemplates(): Observable<FormTemplate[]> {
    return this.httpClient.get<FormTemplate[]>('api/formsapi/templatelist').pipe(map(items => this.parseFormTemplate(items)));
  }

  private parseFormTemplate(templates: FormTemplate[]): FormTemplate[] {
    let formTemplates: FormTemplate[] = [];
    if (templates) {
      templates.map(template => this.objectFactory.create(template, FormTemplate.$t)).forEach(template => formTemplates.push(template));
    }
    return formTemplates;
  }
}
