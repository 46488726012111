<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button slot="icon-only"  (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            Unités sélectionnnées
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <ion-list>
        <ion-item *ngFor="let vehicle of vehicles" (click)="loadInfo(vehicle)">
            <ion-icon name="car" slot="start" ></ion-icon>
            <h2>{{vehicle.Name}}</h2>
            <!--<p>{{vehicle.type && vehicle.type.Description}}</p>-->
        </ion-item>
    </ion-list>
</ion-content>
