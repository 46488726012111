import { Component, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  templateUrl: 'loadingBar.html',
  selector: 'loading-bar'
})
export class LoadingBarComponent {
  @ViewChild('content')
  content: ElementRef;

  @Input()
  percent = 0;
  constructor() {}

  public getWidth() {
    return this.percent;
  }
}
