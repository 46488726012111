<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>SagaMobile.User.SiteSelector</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="padding">
  <div *ngIf="!departements" class="loading">
    <ion-spinner></ion-spinner>
  </div>

  <ion-list>
    <ion-item-group *ngFor="let departement of departements">
      <ion-item-divider (click)="departement.open = !departement.open">
        <ion-icon slot="start" [name]="departement.open ? 'caret-down-outline' : 'caret-forward-outline'"></ion-icon>
        <ion-label>{{ departement.code }}</ion-label>
      </ion-item-divider>

      <ng-container *ngIf="departement.open">
        <ion-item *ngFor="let site of departement.sites" (click)="selectSite(site)">
          {{ site.geosectorname }}
        </ion-item>
      </ng-container>
    </ion-item-group>
  </ion-list>
</ion-content>
