import { ListVehicleType } from '@models/imported/SagaSchema/ListObjects/ListVehicleType';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { CacheChangedInterface } from '@services/cache/cache.service';

export interface IExtendedProperties {
  type?: number;
  subType?: number;
  modeOfTransport?: number;
  category?: number;
}

export class ListVehicleTypePn extends ListVehicleType {
  properties: IExtendedProperties = {};

  propertyCount: number = 0;

  changed(change: CacheChangedInterface) {
    super.changed(change);

    this.properties = JSON.parse(this.Type);
    if (!this.properties) {
      this.properties = {
        type: null,
        subType: null,
        modeOfTransport: null,
        category: null
      };
    }

    this.propertyCount = Object.keys(this.properties).filter(property => this.properties[property]).length;
  }

  match(properties: IExtendedProperties): boolean {
    const typeProperties = Object.keys(this.properties).filter(name => this.properties[name]);
    return !typeProperties.find(property => properties[property] !== this.properties[property]);
  }
}
