import { AdditionalViewToolsService } from '@addins/core/panel';
import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { StatusPanelTool } from '../../../schema/status-panel-tool';

@AddinExecute({
  name: 'Menu',
  description: 'Add a menu entry for open the status selector panel'
})
@Injectable()
export class StatusAccessMenuService extends Execute {
  private statusPanelTool = new StatusPanelTool();

  constructor(private additionalViewToolsService: AdditionalViewToolsService) {
    super();
  }

  init() {
    this.statusPanelTool.isVisible = true;
    this.additionalViewToolsService.addTool(this.statusPanelTool);
  }

  disable(): void {
    this.statusPanelTool.isVisible = false;
    this.additionalViewToolsService.removeTool(this.statusPanelTool);
  }

  execute(): void {}

  destroy(): void {}
}
