// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmTransmitter_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.AlarmTransmitter';
  $t: string = 'SagaSchema.AlarmTransmitter';

  public static GetMembers(): string[] {
    return [
      'TransmitterUId',
      'Id',
      'TransmitterType',
      'TransmitterTypeName',
      'AlarmStatusValue',
      'HandlingAgency',
      'CustomerUId',
      'Identifier',
      'IdentifierAlt',
      'Name',
      'CustomerName',
      'MiscInfo',
      'IsDeleted',
      'CommissioningInfo',
      'Owner',
      'OwnerName',
      'ModifiedUTC',
      'CreatedUTC'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmTransmitter_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ModifiedUTC = this.ModifiedUTC && new Date(this.ModifiedUTC);
    this.CreatedUTC = this.CreatedUTC && new Date(this.CreatedUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.AlarmFile', 'SagaSchema.AlarmDetector'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TransmitterUId: Guid = GuidTools.Empty;
  public Id: number = 0;
  public TransmitterType: number = 0;
  public TransmitterTypeName: string;
  public AlarmStatusValue: number = 0;
  public HandlingAgency: number;
  public CustomerUId: Guid = GuidTools.Empty;
  public Identifier: string;
  public IdentifierAlt: string;
  public Name: string;
  public CustomerName: string;
  public MiscInfo: string;
  public IsDeleted: boolean = false;
  public CommissioningInfo: string;
  public Owner: number = 0;
  public OwnerName: string;
  public ModifiedUTC: Date = new Date();
  public CreatedUTC: Date = new Date();

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.TransmitterUId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
