import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { ToolsProvider, ToolsProviderService } from '@services/tools';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { MediaMessageTool } from '../../../schema/media-message-tool';
import { TextMessageService } from '../../text-message/text-message.service';
import { MediaProviderService } from '@addins/core/singlecallcard';

@AddinExecute({
  name: 'MenuProvider'
})
@Injectable()
export class MenuAccessToolService extends Execute {
  private _provider: ToolsProvider;

  private readonly _tool = new MediaMessageTool();

  constructor(
    private readonly _toolsProvider: ToolsProviderService,
    private readonly _textMessage: TextMessageService,
    private readonly _mediaProvider: MediaProviderService
  ) {
    super();
  }

  public execute(): void {
    this._mediaProvider.register(this._tool);
  }

  public init(): void {
    super.init();
    this._tool.executor.subscribe(() => this._textMessage.open());
    this._toolsProvider.register(p => this.provide(p));
  }

  private provide(provider: ToolsProvider): void {
    const [ctx] = provider.context;
    if (ctx === CallCard) {
      this._provider = provider;
      provider.addTool(this._tool);
    }
  }
}
