import { Injectable } from '@angular/core';
import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { SagaLayerManager } from '@techwan/ionic-core/map';
import { TacticalSituationLayer } from '@techwan/mapping';
import { DEFAULT_IMAGE_SIZE } from '@utils/image';
import Group from 'ol/layer/group';

@Injectable()
export class DrawingTocManagerService {
  constructor() {}

  createLayer(sagaLayerManager: SagaLayerManager, destinationGroup: Group, tacticalSituation: CTacticalSituation): TacticalSituationLayer {
    const tacticalSituationLayer = sagaLayerManager.createDrawingLayerIn(destinationGroup);
    tacticalSituationLayer.set('name', 'MyDrawings');
    tacticalSituationLayer.setId(tacticalSituation.ObjGuid);
    tacticalSituationLayer.set('iconSize', DEFAULT_IMAGE_SIZE);
    tacticalSituationLayer.loadGeometry(tacticalSituation.GeometryObjects);
    return tacticalSituationLayer;
  }
}
