import { Injectable } from '@angular/core';
import { IIconSize, IValueChangeEvent, IValueProvider, ValueChange } from '@techwan/map-tools';
import { Observable, Subject } from 'rxjs';
import Size from 'ol/Size';

@Injectable()
export class AttributeValueProviderService implements IValueProvider {
  private readonly _change = new Subject<IValueChangeEvent>();
  public get $change(): Observable<IValueChangeEvent> {
    return this._change.asObservable();
  }

  private _size: Size;
  public set size(value: Size) {
    const old = this._size;
    this._size = value;
    if (old !== this._size) {
      this._change.next({ value, old, name: ValueChange.iconSize });
    }
  }
  public get size(): Size {
    return this._size;
  }

  public constructor() {}

  public getSizes(): IIconSize[] {
    return [
      {
        value: 32,
        name: 'SagaMobile.Icons.Little'
      },
      {
        value: 48,
        name: 'SagaMobile.Icons.Medium',
        selected: true
      },
      {
        value: 64,
        name: 'SagaMobile.Icons.Large'
      }
    ];
  }
}
