import { CallCardModule } from '@addins/callcard';
import { CoreAddinModule } from '@addins/core';
import { ALERT_PROVIDER_TOKEN } from '@addins/core/core';
import { FollowupStampsToolsModule } from '@addins/core/followup-stamps-tools';
import { SiteSelectorService } from '@addins/core/login';
import { UnitActivityModule } from '@addins/core/unit-activity';
import { CreateEventModule, EventFactoryService } from '@addins/create-event';
import { CVPModule } from '@addins/cvp';
import { DeploymentModule } from '@addins/deployment';
import { DocumentsModule } from '@addins/documents';
import { DrawingModule } from '@addins/drawings';
import { EngagementModule } from '@addins/engagement';
import { ExternalItineraryModule } from '@addins/external-itinerary';
import { InAppItineraryModule } from '@addins/inapp-itinerary';
import { ListCallCardsModule } from '@addins/listcallcards';
import { MediaMessageModule } from '@addins/media';
import { OfflineMapModule } from '@addins/offline-map';
import { PlannedMissionsModule } from '@addins/planned-missions';
import { PnModule } from '@addins/pn/pn.module';
import { QuickResponseCodeModule } from '@addins/quick-response-code';
import { ResourceCountModule } from '@addins/resource-count';
import { SagaFormsModule } from '@addins/saga-forms';
import { SingleCallcardAttachmentsModule } from '@addins/singlecallcard-attachments';
import { SingleCallcardDestinationModule } from '@addins/singlecallcard-destination';
import { SingleCallcardJournalModule } from '@addins/singlecallcard-journal/src/module';
import { SinglecallcardParticularsModule } from '@addins/singlecallcard-particulars';
import { TranslationModule } from '@addins/translations';
import { UnitCustomCreation } from '@addins/unit-custom-creation';
import { Vd144Module } from '@addins/vd144';
import { Provider } from '@angular/core';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { CallCardDispatch } from '@models/imported/SagaSchema/CallCardDispatch';
import { CALLCARD_TYPE_INJECTION_TOKEN } from '@services/callcard/injector';
import { ENVIRONMENT_VALUE } from '@services/environment/environment.service';
import { ModalService } from '@services/modal/modal.service';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import {
  CLAIMS_PROVIDER,
  DeviceTypes,
  HTTP_HEADER_INJECTION_TOKEN,
  IAuthParameters,
  IHttpHeaderConfig,
  IonicCoreModule,
  MODAL_SERVICE,
  SITE_SELECTOR
} from '@techwan/ionic-core';
import { LoginModule } from '@techwan/ionic-core/login';

const HEADERS: IHttpHeaderConfig = {
  deviceType: DeviceTypes.MobileWeb
};

const modalProvider = {
  provide: MODAL_SERVICE,
  useFactory: (modalService: ModalService) => modalService,
  deps: [ModalService]
};

const httpHeaderProvider = {
  provide: HTTP_HEADER_INJECTION_TOKEN,
  useFactory: (platform: Platform) => {
    HEADERS.platform = platform.platforms().join(',');
    return HEADERS;
  },
  deps: [Platform]
};

const siteSelectorProvider = {
  provide: SITE_SELECTOR,
  useFactory: (selector: SiteSelectorService) => selector,
  deps: [SiteSelectorService]
};

const authParameters: IAuthParameters = {
  deviceType: DeviceTypes.MobileWeb,
  doNotUseTokenCookie: true
};

const claimsProvider: Provider = {
  provide: CLAIMS_PROVIDER,
  useFactory: (claims: RefreshTokenStorageService) => claims,
  deps: [RefreshTokenStorageService]
};

export const ADDIN_MODULES = [
  LoginModule,
  CoreAddinModule,
  DocumentsModule,
  ListCallCardsModule,
  CallCardModule,
  UnitActivityModule,
  PnModule,
  Vd144Module,
  CVPModule,
  EngagementModule,
  ResourceCountModule,
  PlannedMissionsModule,
  SagaFormsModule,
  InAppItineraryModule,
  ExternalItineraryModule,
  OfflineMapModule,
  SingleCallcardDestinationModule,
  TranslationModule,
  QuickResponseCodeModule,
  MediaMessageModule,
  DeploymentModule,
  DrawingModule,
  CreateEventModule,
  FollowupStampsToolsModule,
  SingleCallcardAttachmentsModule,
  SinglecallcardParticularsModule,
  SingleCallcardJournalModule,
  UnitCustomCreation,
  IonicCoreModule.forRoot({ authParameters, siteSelectorProvider, modalProvider, httpHeaderProvider, claimsProvider })
];

export const ADDIN_PROVIDERS = [
  ModalService,
  { provide: ENVIRONMENT_VALUE, useValue: environment },
  { provide: CALLCARD_TYPE_INJECTION_TOKEN, useValue: CallCardDispatch },
  { provide: ALERT_PROVIDER_TOKEN, useExisting: EventFactoryService }
];
