// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CompUnit_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.CompUnit';
  $t: string = 'SagaSchema.CompUnit';

  public static GetMembers(): string[] {
    return [
      'GeoAreaId',
      'GeoAreaImpId',
      'GeoAreaTypeConst',
      'TreeTypeId',
      'Name',
      'Parent_geoAreaId',
      'Abbreviation',
      'MiscInfo',
      'MapObjectId',
      'CoordX',
      'CoordY',
      'SiteId',
      'Stack_Top',
      'LFT',
      'RGT',
      'ZonePolice',
      'Deleted'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CompUnit_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GeoAreaId: number = 0;
  public GeoAreaImpId: string;
  public GeoAreaTypeConst: number = 0;
  public TreeTypeId: number = 0;
  public Name: string = '';
  public Parent_geoAreaId: number;
  public Abbreviation: string;
  public MiscInfo: string;
  public MapObjectId: number = 0;
  public CoordX: number = 0;
  public CoordY: number = 0;
  public SiteId: number = 0;
  public Stack_Top: number = 0;
  public LFT: number = 0;
  public RGT: number = 0;
  public ZonePolice: boolean = false;
  public Deleted: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
