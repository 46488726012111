import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { capitalizeFirstLetter } from '@utils/string';
import { getRangedPercent } from '@utils/number';

export enum ResizeMode {
  Both = 'Both',
  OnlyPrevious = 'OnlyPrevious',
  OnlyNext = 'OnlyNext'
}

export enum SplitMode {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

@Component({
  selector: 'app-view-splitter',
  templateUrl: './view-splitter.component.html',
  styleUrls: ['./view-splitter.component.scss']
})
export class ViewSplitterComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly stopBind = this.stop.bind(this);
  private readonly moveBind = this.move.bind(this);

  current: number = 0;

  resizeLinePosition: { left?: string; top?: string } = null;

  @ViewChild('resizeElement') resizeElement: ElementRef<HTMLDivElement>;

  @Input() splitMode: SplitMode = SplitMode.Horizontal;
  @Input() resizeMode: ResizeMode = ResizeMode.Both;

  @HostBinding('class.horizontal') get horizontal() {
    return this.splitMode === SplitMode.Horizontal;
  }
  @HostBinding('class.vertical') get vertical() {
    return this.splitMode === SplitMode.Vertical;
  }

  get direction() {
    return this.splitMode.toLowerCase();
  }

  get propertySize() {
    return this.splitMode === SplitMode.Horizontal ? 'Height' : 'Width';
  }

  get propertyPosition() {
    return this.splitMode === SplitMode.Horizontal ? 'top' : 'left';
  }

  get propertyClient() {
    return this.splitMode === SplitMode.Horizontal ? 'clientY' : 'clientX';
  }

  private _previousMin: number = null;
  @Input() set previousMin(value: number) {
    this.updateStyle('previous', 'min');
    this._previousMin = parseInt(value as any, 10);
  }
  get previousMin(): number {
    return this._previousMin;
  }

  private _previousMax: number = null;
  @Input() set previousMax(value: number) {
    this.updateStyle('previous', 'max');
    this._previousMax = parseInt(value as any, 10);
  }
  get previousMax(): number {
    return this._previousMax;
  }

  private _nextMin: number = null;
  @Input() set nextMin(value: number) {
    this.updateStyle('next', 'min');
    this._nextMin = parseInt(value as any, 10);
  }
  get nextMin(): number {
    return this._nextMin;
  }

  private _nextMax: number = null;
  @Input() set nextMax(value: number) {
    this.updateStyle('next', 'max');
    this._nextMax = parseInt(value as any, 10);
  }
  get nextMax(): number {
    return this._nextMax;
  }

  private _initialHeight: number = null;
  @Input()
  public set initialHeight(value: number) {
    this._initialHeight = value;
  }
  public get initialHeight(): number {
    return this._initialHeight;
  }

  private previousElement: HTMLElement;
  private nextElement: HTMLElement;

  constructor(
    private readonly _element: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly _cd: ChangeDetectorRef
  ) {}

  private updateStyle(name: string, type: string) {
    const elementName = name.toLowerCase() + 'Element';
    const property = name.toLowerCase() + capitalizeFirstLetter(type);

    if (this[elementName]) {
      if (typeof this[property] === 'number') {
        this.renderer.setStyle(this[elementName], `${type}${this.propertySize}`, this[property] + '%');
      } else {
        this.renderer.removeStyle(this[elementName], `${type}${this.propertySize}`);
      }
    }
  }

  ngOnInit() {
    this.previousElement = this._element.nativeElement.previousElementSibling as HTMLElement;
    this.nextElement = this._element.nativeElement.nextElementSibling as HTMLElement;
    this.updateStyle('previous', 'min');
    this.updateStyle('previous', 'max');
    this.updateStyle('next', 'min');
    this.updateStyle('next', 'max');

    // Fix the initial height of the element
    if (this.initialHeight !== null) {
      this.renderer.setStyle(this.previousElement, this.propertySize.toLowerCase(), `${this.initialHeight}%`);
    }
  }

  ngAfterViewInit() {
    const element = this.resizeElement.nativeElement;
    element.parentElement.removeChild(element);
    this._element.nativeElement.parentElement.appendChild(element);
  }

  public start(event: MouseEvent | TouchEvent) {
    const evt = event instanceof TouchEvent ? event.touches[0] : event;
    document.addEventListener(event instanceof TouchEvent ? 'touchmove' : 'mousemove', this.moveBind);
    document.addEventListener(event instanceof TouchEvent ? 'touchend' : 'mouseup', this.stopBind);

    this.current = evt[this.propertyClient];
    this.applyResizeLinePosition(0);
  }

  private stop(event: MouseEvent | TouchEvent) {
    const evt = event instanceof TouchEvent ? event.changedTouches[0] : event;
    document.removeEventListener(event instanceof TouchEvent ? 'touchmove' : 'mousemove', this.moveBind);
    document.removeEventListener(event instanceof TouchEvent ? 'touchend' : 'mouseup', this.stopBind);

    const delta = evt[this.propertyClient] - this.current;
    this.applyDeltaPx(delta);
    this.resizeLinePosition = null;
    if (event instanceof TouchEvent) {
      this._cd.detectChanges();
    }
  }

  private applyDeltaPx(delta) {
    const parentSize = this._element.nativeElement.parentElement[`client${this.propertySize}`];

    if (this.resizeMode === ResizeMode.Both || this.resizeMode === ResizeMode.OnlyPrevious) {
      const previousSize = this.previousElement[`client${this.propertySize}`];
      const newPreviousSize = previousSize + delta;
      const previousSizePercent = (newPreviousSize / parentSize) * 100;

      this.renderer.setStyle(
        this.previousElement,
        this.propertySize.toLocaleLowerCase(),
        `${getRangedPercent(previousSizePercent, this.nextMin, this.nextMax)}%`
      );
    }

    if (this.resizeMode === ResizeMode.Both || this.resizeMode === ResizeMode.OnlyNext) {
      const nextSize = this.nextElement[`client${this.propertySize}`];
      const newNextSize = nextSize - delta;
      const nexSizePercent = (newNextSize / parentSize) * 100;

      const drawingContainerElement = document.getElementById('drawing-container');
      if (drawingContainerElement) {
        drawingContainerElement.classList.remove('container-class');
      }

      this.renderer.setStyle(
        this.nextElement,
        this.propertySize.toLocaleLowerCase(),
        `${getRangedPercent(nexSizePercent, this.nextMin, this.nextMax)}%`
      );
    }
  }

  private applyResizeLinePosition(delta) {
    const parentPosition = this._element.nativeElement.parentElement.getClientRects()[0][this.propertyPosition];
    const currentPosition = this._element.nativeElement.getClientRects()[0][this.propertyPosition];
    const position = currentPosition - parentPosition + delta;

    this.resizeLinePosition = {};
    this.resizeLinePosition[this.propertyPosition] = position + 'px';
  }

  private move(event: MouseEvent | TouchEvent) {
    const evt = event instanceof TouchEvent ? event.touches[0] : event;
    const delta = evt[this.propertyClient] - this.current;
    this.applyResizeLinePosition(delta);
    if (event instanceof TouchEvent) {
      this._cd.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.previousElement) {
      this.renderer.removeStyle(this.previousElement, `min${this.propertySize}`);
      this.renderer.removeStyle(this.previousElement, `max${this.propertySize}`);
      this.renderer.removeStyle(this.previousElement, this.propertySize.toLocaleLowerCase());
    }
    if (this.nextElement) {
      this.renderer.removeStyle(this.nextElement, `min${this.propertySize}`);
      this.renderer.removeStyle(this.nextElement, `max${this.propertySize}`);
      this.renderer.removeStyle(this.nextElement, this.propertySize.toLocaleLowerCase());
    }
  }
}
