import { Injectable } from '@angular/core';
import { ProfileService } from '@services/profile/profile.service';
import { LoginTypes } from '@techwan/security';

const GenericHome = 'Home/generic';
const GenericLogin = 'AppLogin/GenericLogin';
const BootView = 'AppLogin/Starting';

@Injectable({
  providedIn: 'root'
})
export class ViewConfigurationService {
  constructor(private readonly profile: ProfileService) {}

  getHomeName(): string {
    return GenericHome;
  }

  getLoginName(type: LoginTypes): string {
    switch (type) {
      case LoginTypes.SagaUsernamePassword:
      case LoginTypes.TrustedUser:
      case LoginTypes.TrustedUserAndRole:
        return GenericLogin;
      default:
        throw new Error(`Not supported login method ${LoginTypes[type]}`);
    }
  }

  getMapName(): string {
    return this.profile.current && this.profile.current.webLayout && this.profile.current.webLayout.map;
  }

  getBootName(): string {
    return BootView;
  }
}
