import { IonNav } from '@ionic/angular';
import { Injectable } from '@angular/core';
export interface StatusBarItem {
  id: string;
  iconName: string;
  description?: string;
  hidden?: boolean;
  style?: any;
  handler?: (event?: Event, navCtrl?: IonNav) => void;
  onlyPrimary?: boolean;
}

@Injectable()
export class StatusBarService {
  private items: StatusBarItem[];

  constructor() {
    this.items = [];
  }

  getItems(): StatusBarItem[] {
    return this.items;
  }

  getItem(id: string): StatusBarItem {
    return this.items.find(i => {
      return i.id == id;
    });
  }

  addItem(item: StatusBarItem): void {
    this.items.push(item);
  }

  removeItem(item: StatusBarItem | string): void {
    if (typeof item === 'string') {
      item = this.getItem(item);
    }

    if (item) {
      let index = this.items.indexOf(item);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    }
  }
}
