// Automatically generated from TW.Saga.Shared.MetadataLib, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @19.06.2017 18:53:46
import { ListResourceStatus_abstract } from './../abstract/SagaStateLib/ListResourceStatus_abstract';
import { Color } from '@models/color';

export class ListResourceStatus extends ListResourceStatus_abstract {
  private _color: Color;
  private _displayColor: string;

  public get color(): Color {
    if (!this._color) {
      this._color = new Color(this.FColor);
    }
    return this._color;
  }

  public get displayColor(): string {
    return this.color.toRgbString();
  }
}
