// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class MediaMessage_abstract extends SagaObject {
  static $t: string = 'SagaSchema.MediaMessage';
  $t: string = 'SagaSchema.MediaMessage';

  public static GetMembers(): string[] {
    return [
      'ConfirmationAction',
      'MediaData',
      'MediaPreview',
      'MediaType',
      'Caption',
      'Text',
      'SharedToUserId',
      'CoordX',
      'CoordY',
      'CoordZ',
      'CoordEpsgCode',
      'UploadedOnDatabase',
      'DestinationUserName',
      'DestinationUserId',
      'Url',
      'FileName',
      'Pinned',
      'Status',
      'DeviceType',
      'Acknowledged',
      'AcknowledgedBy',
      'AcknowledgedByName',
      'ParentMessageUid'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return MediaMessage_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ConfirmationAction: string;
  public MediaData: any[];
  public MediaPreview: any[];
  public MediaType: SagaBase.MediaType = SagaBase.MediaType.Text;
  public Caption: string;
  public Text: string;
  public SharedToUserId: number = 0;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public CoordZ: number = 0;
  public CoordEpsgCode: string;
  public UploadedOnDatabase: boolean = false;
  public DestinationUserName: string;
  public DestinationUserId: string;
  public Url: string;
  public FileName: string;
  public Pinned: boolean = false;
  public Status: SagaBase.MediaMessageStatus = SagaBase.MediaMessageStatus.Info;
  public DeviceType: SagaBase.DeviceTypes = SagaBase.DeviceTypes.NA;
  public Acknowledged: boolean = false;
  public AcknowledgedBy: number;
  public AcknowledgedByName: string;
  public ParentMessageUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
