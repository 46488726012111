import { PopupBaseComponent, PopupProviderService } from '@addins/core/map';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertButton, AlertInput } from '@ionic/core/dist/types/components/alert/alert-interface';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@services/cache/cache.service';
import { DeploymentProxyService } from '../../services/deployment-proxy/deployment-proxy.service';
import { UnitDeploymentService } from '../../services/unit-deployment/unit-deployment.service';

@Component({
  selector: 'app-deployment-popup',
  templateUrl: './deployment-popup.component.html',
  styleUrls: ['./deployment-popup.component.scss']
})
export class DeploymentPopupComponent extends PopupBaseComponent implements OnInit {
  private deploymentPoint: CDeploymentPoint = null;
  private addedUnits: number = 0;

  private alertTitle: string = null;
  private alertInputs: AlertInput[];
  private cancelButton: AlertButton;
  private saveButton: AlertButton;
  private alertButtons: AlertButton[];

  private alert: HTMLIonAlertElement;

  constructor(
    popupProvider: PopupProviderService,
    elementRef: ElementRef,
    private cacheService: CacheService,
    private unitDeploymentService: UnitDeploymentService,
    private deploymentProxyService: DeploymentProxyService,
    private alertController: AlertController,
    private translate: TranslateService
  ) {
    super(popupProvider, elementRef);
  }

  ngOnInit() {
    this.cancelButton = { text: this.translate.instant('Mobile.Cancel'), role: 'cancel', handler: () => this.onCancel() };
    this.saveButton = { text: this.translate.instant('Mobile.Save'), handler: unitIds => this.onSave(unitIds) };

    this.refresh();
    this._subs.push(this.deploymentPoint.$changed.subscribe(change => this.onDeploymentPointChange(change)));

    this.alertController
      .create({
        header: this.alertTitle,
        inputs: this.alertInputs,
        buttons: this.alertButtons
      })
      .then(alert => {
        this.alert = alert;
        alert.present();
      });
  }

  protected refresh(): void {
    this.deploymentPoint = this._popup.content.length ? this.cacheService.getObjectByUid(this._popup.content[0].feature.get('id')) : null;
    if (this.deploymentPoint === null) {
      this.onCancel();
    }
    const unitIds: string[] = (this.deploymentPoint.UnitUids && this.deploymentPoint.UnitUids.split(',').filter(uid => uid)) || [];

    this.createAlertTitle(unitIds);
    this.createAlertInputs(unitIds);
    this.createAlertButtons();
  }

  private createAlertTitle(unitIds: string[]) {
    this.alertTitle = unitIds.length ? `${this.deploymentPoint.Name} (${unitIds.length})` : `${this.deploymentPoint.Name}`;
  }

  private createAlertInputs(unitIds: string[]) {
    this.alertInputs = [];

    let engagedUnits: UnitActivity[] = [];

    unitIds
      .map(unit => this.cacheService.getObjectByUid(unit))
      .filter(unit => unit instanceof UnitActivity)
      .forEach((unit: UnitActivity) => {
        this.alertInputs.push({ type: 'checkbox', label: unit.Name, value: unit.ObjGuid, checked: true, disabled: true });
        engagedUnits.push(unit);
      });

    let availableUnits: UnitActivity[] = this.unitDeploymentService
      .getUnitForCard(this.deploymentPoint.CallCardId)
      .filter(unit => !engagedUnits.includes(unit));
    availableUnits.forEach((unit: UnitActivity) => {
      this.alertInputs.push({
        type: 'checkbox',
        label: unit.Name,
        value: unit.ObjGuid,
        handler: alertInput => this.onUnitSelectionChanged(alertInput)
      });
    });
  }

  private onUnitSelectionChanged(alertInput: AlertInput) {
    alertInput.checked ? this.addedUnits++ : this.addedUnits--;
    this.createAlertButtons();
    this.alert.buttons = this.alertButtons;
  }

  private createAlertButtons() {
    this.alertButtons = this.addedUnits ? [this.cancelButton, this.saveButton] : [this.cancelButton];
  }

  private onCancel() {
    this._popup.close();
  }

  private onSave(unitIds: string[]) {
    this.deploymentProxyService.save({ updates: this.unitDeploymentService.deployUnitsOn(this.deploymentPoint, unitIds) }).subscribe();
    this._popup.close();
  }

  private onDeploymentPointChange(change) {
    this.addedUnits = 0;
    this.refresh();

    this.alert.header = this.alertTitle;
    this.alert.inputs = this.alertInputs;
    this.alert.buttons = this.alertButtons;
  }
}
