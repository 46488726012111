// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class AddAttachmentArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.AddAttachmentArgs';
  $t: string = 'SagaBase.Defines.AddAttachmentArgs';

  public static GetMembers(): string[] {
    return [
      'data',
      'dataType',
      'parentId',
      'title',
      'text',
      'userName',
      'userId',
      'owner',
      'coordX',
      'coordY',
      'coordZ',
      'ownerName',
      'generateStamp',
      'saveOnDatabase'
    ];
  }

  public GetMembers(): string[] {
    return AddAttachmentArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public data: any[];
  public dataType: number = 0;
  public parentId: Guid = GuidTools.Empty;
  public title: string;
  public text: string;
  public userName: string;
  public userId: number = 0;
  public owner: number = 0;
  public coordX: number = 0;
  public coordY: number = 0;
  public coordZ: number = 0;
  public ownerName: string;
  public generateStamp: boolean = false;
  public saveOnDatabase: boolean = true;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
