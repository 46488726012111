// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmStateChangedBuffer_abstract extends SagaObject {
  static $t: string = 'SagaBase.AlarmStateChangedBuffer';
  $t: string = 'SagaBase.AlarmStateChangedBuffer';

  public static GetMembers(): string[] {
    return [
      'FileUid',
      'CallcardType',
      'AlarmState',
      'AlarmInfo',
      'IsAcknowledged',
      'AlarmLocationText',
      'AlarmTestText',
      'EventText',
      'SerializedInfo'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmStateChangedBuffer_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public FileUid: Guid = GuidTools.Empty;
  public CallcardType: number = 0;
  public AlarmState: SagaBase.AlarmState = SagaBase.AlarmState.Alarm;
  public AlarmInfo: string;
  public IsAcknowledged: boolean = false;
  public AlarmLocationText: string;
  public AlarmTestText: string;
  public EventText: string;
  public SerializedInfo: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
