// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import * as SagaBase from '../../../SagaBase';
import { TimerBase } from '../../../SagaBase/Objects/TimerBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TimerItem_abstract extends TimerBase {
  static $t: string = 'SagaBase.Objects.TimerItem';
  $t: string = 'SagaBase.Objects.TimerItem';

  public static GetMembers(): string[] {
    return ['TimerDefinitionId', 'Repeat', 'RepeatUid', 'DueDateTimeUTC', 'Status', 'Priority'].concat(TimerBase.GetMembers());
  }

  public GetMembers(): string[] {
    return TimerItem_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DueDateTimeUTC = this.DueDateTimeUTC && new Date(this.DueDateTimeUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TimerDefinitionId: number;
  public Repeat: boolean = false;
  public RepeatUid: Guid = GuidTools.Empty;
  public DueDateTimeUTC: Date = new Date();
  public Status: SagaBase.Defines.TimerItemStatus = SagaBase.Defines.TimerItemStatus.Created;
  public Priority: SagaBase.Defines.TimerPriority = SagaBase.Defines.TimerPriority.High;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
