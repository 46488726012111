import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { ImagesSettingsService } from '@services/settings';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { MediaType } from '../../schema/media/media-type';
import { FileChooserService } from '../../services/file-chooser/file-chooser.service';

@Component({
  templateUrl: 'camera.component.html',
  styleUrls: ['camera.component.scss']
})
export class CameraComponent implements OnInit, AfterViewInit {
  previewImg: string | SafeUrl = null;
  text: string = '';
  readonly type: MediaType;

  private _selectedFile: ICustomFile = null;
  get selectedFile() {
    return this._selectedFile;
  }

  constructor(
    private _modalController: ModalController,
    private _navParams: NavParams,
    private _platform: Platform,
    private _imageSettings: ImagesSettingsService,
    private _fileChooser: FileChooserService,
    private _domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const imageUrl = this._navParams.get('imageUrl');
    if (imageUrl) {
      this.previewImg = imageUrl;
      // Popover has been temporarily removed due to iOS specific feature.
    }
  }

  ngAfterViewInit(): void {
    this.onChoose();
  }

  onChoose(): void {
    this._fileChooser.choose(this.type).then(
      customFile => {
        this._selectedFile = customFile;
        this.showPreview(this._selectedFile);
      },
      err => {
        if (this._selectedFile === null) {
          this.onCancel();
        }
      }
    );
  }

  private showPreview(customFile: ICustomFile) {
    let imageUrl: string;
    if (this.type === MediaType.Video) {
      imageUrl = this._imageSettings.get('VideoAttachments');
    } else {
      imageUrl = customFile.url;
    }

    if (this._platform.is('ios')) {
      this.previewImg = this._domSanitizer.bypassSecurityTrustUrl(imageUrl);
    } else {
      this.previewImg = imageUrl;
    }
  }

  onCancel() {
    return this._modalController.dismiss(null);
  }

  onAccept() {
    if (this._selectedFile) {
      this._selectedFile.text = this.text;
    }
    return this._modalController.dismiss(this._selectedFile);
  }
}
