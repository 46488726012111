import { Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';

@Component({
  selector: 'app-callcard-detail-cperson-pce',
  templateUrl: './callcard-detail-cperson-pce.component.html',
  styleUrls: ['./callcard-detail-cperson-pce.component.scss']
})
@AddinView({
  name: 'CallcardDetailCPersonPCE'
})
export class CallcardDetailCPersonPCEComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
