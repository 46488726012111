import { LocalizePositionService } from '@addins/core/map';
import { ButtonsProviderService, CardsProviderService, ICallcardButtonEvent } from '@addins/core/singlecallcard';
import { StartItineraryService } from '@addins/external-itinerary';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter } from 'rxjs/operators';
import { DestinationSelectComponent } from '../../../components/destination-select/destination-select.component';
import { LocalizeTool } from '../../../schema/localize-tool';
import { NavigateToTool } from '../../../schema/navigate-to-tool';
import { DestinationProviderService } from '../../destination-provider/destination-provider.service';

@AddinExecute({
  name: 'NavigateTo'
})
@Injectable()
export class ButtonProviderService extends Execute {
  private navigateTool: NavigateToTool;
  private localizeTool: LocalizeTool;

  constructor(
    private cardsProvider: CardsProviderService,
    private destinationsProvider: DestinationProviderService,
    private buttonsProvider: ButtonsProviderService,
    private localizePosition: LocalizePositionService,
    private startItineraryService: StartItineraryService
  ) {
    super();
  }

  init() {
    super.init();
    this.navigateTool = new NavigateToTool();
    this.localizeTool = new LocalizeTool();
    this.destinationsProvider.setup();
  }

  execute(): void {
    this.destinationsProvider.state.pipe(filter(e => e)).subscribe(() => this.ready());
  }

  private ready() {
    this.cardsProvider.add(DestinationSelectComponent);
    this.buttonsProvider.add(this.navigateTool).subscribe(e => this.navigate(e));
    this.buttonsProvider.add(this.localizeTool).subscribe(e => this.localize(e));
    this.refresh();
    this.destinationsProvider.current.subscribe(() => this.refresh());
  }

  private refresh() {
    this.localizeTool.show = this.destinationsProvider.value !== null;
    this.navigateTool.show = this.destinationsProvider.value !== null;
  }

  private navigate(e: ICallcardButtonEvent) {
    const destination: Coordinate = {
      epsg: e.target.location.coordinate.epsg,
      x: this.destinationsProvider.value.CoordX,
      y: this.destinationsProvider.value.CoordY
    };
    this.startItineraryService.navigateTo(destination);
  }

  private localize(event: ICallcardButtonEvent) {
    this.localizePosition.localize({
      target: event.target,
      coordinates: [
        {
          x: this.destinationsProvider.value.CoordX,
          y: this.destinationsProvider.value.CoordY,
          epsg: null
        }
      ],
      zoom: 1
    });
  }
}
