import { ILocation } from '@models/interfaces/ILocation';
import { DefaultLocationFormatter } from './default-location-formatter';

export class MdpLocationFormatter extends DefaultLocationFormatter {
  format(location: ILocation): string[] {
    const line1: string = location.AliasName;
    const line2: string = [location.PlaceName, location.StreetNumber, location.CityName].filter(s => s).join(', ');

    return [line1, line2].filter(s => s);
  }
}
