// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../../SagaBase/SagaObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Wakeup_abstract extends SagaObject {
  static $t: string = 'SagaBase.Objects.Wakeup';
  $t: string = 'SagaBase.Objects.Wakeup';

  public static GetMembers(): string[] {
    return [
      'WakeupEvent',
      'Dismissed',
      'Status',
      'SagaObjectGuid',
      'Period',
      'WakeupTimeStart',
      'WakeupTimeEnd',
      'WeekDays',
      'Months',
      'Years',
      'Type',
      'Rule'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Wakeup_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.Period = this.Period && new Date(this.Period);
    this.WakeupTimeStart = this.WakeupTimeStart && new Date(this.WakeupTimeStart);
    this.WakeupTimeEnd = this.WakeupTimeEnd && new Date(this.WakeupTimeEnd);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public WakeupEvent: Guid = GuidTools.Empty;
  public Dismissed: boolean = false;
  public Status: SagaBase.Defines.ScheduleStatus = SagaBase.Defines.ScheduleStatus.On;
  public SagaObjectGuid: Guid = GuidTools.Empty;
  public Period: Date = new Date();
  public WakeupTimeStart: Date = new Date();
  public WakeupTimeEnd: Date = new Date();
  public WeekDays: string;
  public Months: string;
  public Years: string;
  public Type: SagaBase.Defines.ScheduleType = SagaBase.Defines.ScheduleType.Recurrent;
  public Rule: SagaBase.Defines.ScheduleRule = SagaBase.Defines.ScheduleRule.Once;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
