// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Area_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Area';
  $t: string = 'SagaBase.Area';

  public static GetMembers(): string[] {
    return [
      'Id',
      'ImportId',
      'Name',
      'GeoTypeConst',
      'ParentId',
      'ZoneId',
      'ZoneName',
      'ZoneAbbreviation',
      'CoordX',
      'CoordY',
      'Deleted',
      'SiteId',
      'StackTop',
      'TreeTypeId',
      'LFT',
      'MapObjectId',
      'MiscInfo',
      'ZonePolice',
      'RGT'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Area_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public ImportId: string;
  public Name: string;
  public GeoTypeConst: number = 0;
  public ParentId: number;
  public ZoneId: number = 0;
  public ZoneName: string;
  public ZoneAbbreviation: string;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public Deleted: boolean = false;
  public SiteId: number = 0;
  public StackTop: number = 0;
  public TreeTypeId: number = 0;
  public LFT: number = 0;
  public MapObjectId: number = 0;
  public MiscInfo: string;
  public ZonePolice: boolean = false;
  public RGT: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
