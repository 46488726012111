// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmFile_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.AlarmFile';
  $t: string = 'SagaSchema.AlarmFile';

  public static GetMembers(): string[] {
    return [
      'FileUId',
      'Id',
      'TransmitterUId',
      'ContactGroupGuid',
      'Identifier',
      'Name',
      'MiscInfo',
      'Url',
      'AdminInfo',
      'EventId',
      'EventGroupUId',
      'Deleted',
      'CoordX',
      'CoordY',
      'GeoLocationId',
      'Normalized',
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'TypeId',
      'CityName',
      'Complement',
      'PlaceName',
      'StreetNumber',
      'Zip',
      'GeoLocationText',
      'GeoLocationSearchLabel',
      'LocationMiscData',
      'GeoLocationType'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmFile_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.AlarmContact', 'SagaSchema.AlarmUriDocument'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public FileUId: Guid = GuidTools.Empty;
  public Id: number = 0;
  public TransmitterUId: Guid = GuidTools.Empty;
  public ContactGroupGuid: Guid = GuidTools.Empty;
  public Identifier: string;
  public Name: string;
  public MiscInfo: string;
  public Url: string;
  public AdminInfo: string;
  public EventId: number;
  public EventGroupUId: Guid = GuidTools.Empty;
  public Deleted: boolean = false;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public GeoLocationId: number;
  public Normalized: number = 0;
  public AliasName: string;
  public Area1Name: string;
  public Area2Name: string;
  public Area3Name: string;
  public TypeId: number = 0;
  public CityName: string;
  public Complement: string;
  public PlaceName: string;
  public StreetNumber: string;
  public Zip: string;
  public GeoLocationText: string;
  public GeoLocationSearchLabel: string;
  public LocationMiscData: string;
  public GeoLocationType: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.FileUId;
  }
  get ParentObjId(): any {
    return this.TransmitterUId;
  }
  set ParentObjId(value: any) {
    this.TransmitterUId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
