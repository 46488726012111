/**
 * Log levels enumeration
 */
export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
  LOG = 4
}

/**
 * List of levels named
 * @type {string[]}
 * @static
 */
export const LEVELS_NAMED = ['error', 'warn', 'info', 'debug', 'log'];

/**
 * List of levels icons
 * @type {string[]}
 * @static
 */
export const LEVELS_ICONS = ['fa fa-exclamation-circle', 'fa fa-exclamation-triangle', 'fa fa-info-circle', 'fa fa-bug', 'fa fa-list'];
