import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { SagaFormsViewComponent } from '../../../views/saga-forms-view/saga-forms-view.component';
import { SagaFormsCardButtonService } from '../../saga-forms-card-button/saga-forms-card-button.service';
import { SagaFormsToastService } from '../../saga-forms-toast/saga-forms-toast.service';

@AddinExecute({
  name: 'SagaFormsSetup'
})
@Injectable({
  providedIn: 'root'
})
export class SagaFormsSetupService extends Execute {
  private sagaFormsMenuEntry: MenuEntry = null;

  constructor(
    private sideMenu: SideMenuService,
    private sagaFormsCardButtonService: SagaFormsCardButtonService,
    private sagaFormsToastService: SagaFormsToastService
  ) {
    super();
  }

  execute() {
    this.sagaFormsMenuEntry = new MenuEntry('SagaForms', 'assets/icons/saga-forms/saga-forms.svg', SagaFormsViewComponent, 800);
    this.sideMenu.addMenuEntry(this.sagaFormsMenuEntry);

    this.sagaFormsCardButtonService.setup();
    this.sagaFormsToastService.setup();
  }
}
