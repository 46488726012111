import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { ResourcePositionLight } from '@models/imported/SagaBase/ResourcePositionLight';
import { Security } from '@services/security/security.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  templateUrl: 'equipmentPhoneSelect.html'
})
export class EquipmentPhoneSelectComponent {
  public equipments: CommunicationEquipment[] = [];
  public vehicle: ResourcePositionLight;

  constructor(private viewCtrl: ModalController, private navParams: NavParams, private security: Security, private callNumber: CallNumber) {
    this.vehicle = this.navParams.get('vehicle') as ResourcePositionLight;
    this.equipments = this.navParams.get('infos') as CommunicationEquipment[];
  }

  close() {
    this.viewCtrl.dismiss();
  }

  async call(equipment: CommunicationEquipment) {
    try {
      await this.security.permission(this.security.PERSMISSION.CALL_PHONE);
      this.callNumber
        .callNumber(equipment.PhoneNumber, true)
        .then(() => console.log('Launched dialer!'))
        .catch(() => console.log('Error launching dialer'));
    } catch (ex) {
      console.error(ex);
    }
  }
}
