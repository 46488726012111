import { Injectable } from '@angular/core';
import { ILocation } from '@models/interfaces/ILocation';
import { MapToolsService, ToggleTool, ToolBase, ToolName } from '@techwan/map-tools';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ILocalizeRequest } from '../../schema/interfaces/ILocalizeRequest';
import { LocalizePositionService } from '../localize-position/localize-position.service';
import { MapShowAddressService } from '../map-show-address/map-show-address.service';
import { MyDeploymentLayerService } from '../my-deployment-layer/my-deployment-layer.service';
import { MyLocationService } from '../my-location/my-location.service';
import { PositionLayerService } from '../position-layer/position-layer.service';
import { MyCardButtonsControllerService } from './my-card-buttons-controller/my-card-buttons-controller.service';

@Injectable()
export class InMapPanelService {
  private _routeButton: ToggleTool = null;
  get routeButton(): ToggleTool {
    return this._routeButton;
  }

  private _fullscreenButton: ToggleTool = null;
  get fullscreenButton(): ToggleTool {
    return this._fullscreenButton;
  }

  private _toggleDeployButton: ToggleTool = null;
  get toggleDeployButton(): ToggleTool {
    return this._toggleDeployButton;
  }

  private removeLocationButton: ToolBase = null;
  private localizeLocationButton: ToolBase = null;
  private followMeTool: ToggleTool;

  private readonly subs: Subscription[] = [];

  constructor(
    private mapToolsService: MapToolsService,
    private myCardButtonsController: MyCardButtonsControllerService,
    private positionLayerService: PositionLayerService,
    private localizePositionService: LocalizePositionService,
    private myLocationService: MyLocationService,
    private myDeploymentLayer: MyDeploymentLayerService,
    private mapShowAddress: MapShowAddressService
  ) {}

  setup() {
    this.mapToolsService.setup();
    this.myCardButtonsController.init();
    this.initButtons();

    this.subs.push(
      this.localizePositionService.listenForLocation(
        (this.mapToolsService.getTool(ToolName.LocalizeMe) as ToolBase).$event.pipe(
          switchMap(() => this.createLocalizeRequestForMyPosition())
        )
      )
    );
    this.subs.push(
      this.localizePositionService.listenForLocation(
        (this.mapToolsService.getTool(ToolName.ZoomMe) as ToolBase).$event.pipe(switchMap(() => this.createLocalizeRequestForMyPosition(1)))
      )
    );

    this.positionLayerService.setFollowMe(this.followMeTool.isActive);
    this.subs.push(
      this.followMeTool.$event.subscribe(tool => {
        this.positionLayerService.setFollowMe(tool.isActive);
      })
    );

    this.subs.push(this.mapShowAddress.$event.subscribe(address => this.onShowAddress(address)));
    this.subs.push(this.removeLocationButton.$event.subscribe(() => this.onRemoveLocation()));
    this.subs.push(
      this.localizeLocationButton.$event.subscribe(() =>
        this.localizePositionService.localize({ coordinates: [this.mapShowAddress.currentAddress.coordinate], target: null })
      )
    );

    this.setupToggleDeployButton();

    this.hideButton(this.routeButton);
  }

  private initButtons() {
    this._routeButton = this.mapToolsService.getTool(ToolName.DrawAndRemoveRoute) as ToggleTool;
    this._fullscreenButton = this.mapToolsService.getTool(ToolName.Fullscreen) as ToggleTool;
    this._toggleDeployButton = this.mapToolsService.getTool(ToolName.ToggleDeployment) as ToggleTool;
    this.removeLocationButton = this.mapToolsService.getTool(ToolName.RemoveLocation) as ToolBase;
    this.localizeLocationButton = this.mapToolsService.getTool(ToolName.LocalizeLocation) as ToolBase;
    this.followMeTool = this.mapToolsService.getTool(ToolName.FollowMe) as ToggleTool;
  }

  private setupToggleDeployButton() {
    this.hideButton(this._toggleDeployButton);
    this.subs.push(
      this._toggleDeployButton.$event.subscribe(toggleDeployButton =>
        this.myDeploymentLayer.showDeploymentPoint(toggleDeployButton.isActive)
      )
    );
    this.subs.push(
      this.myDeploymentLayer.$active.subscribe(isActive =>
        isActive ? this.showButton(this._toggleDeployButton) : this.hideButton(this._toggleDeployButton)
      )
    );
    this.subs.push(this.myDeploymentLayer.$visible.subscribe(isVisible => this._toggleDeployButton.setActive(isVisible)));
  }

  private onShowAddress(address: ILocation) {
    if (address !== null) {
      this.showButton(this.removeLocationButton);
      this.showButton(this.localizeLocationButton);
    } else {
      this.hideButton(this.removeLocationButton);
      this.hideButton(this.localizeLocationButton);
    }
  }

  private onRemoveLocation() {
    this.mapShowAddress.clear();
  }

  setDown() {
    this.myCardButtonsController.uninit();
    this.mapToolsService.cleanup();

    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }

  hideButton(tool: ToolBase) {
    this.mapToolsService.hideTool(tool);
  }

  showButton(tool: ToolBase) {
    this.mapToolsService.showTool(tool);
  }

  private createLocalizeRequestForMyPosition(zoom: number = -1): Observable<ILocalizeRequest> {
    return this.myLocationService.getMyLocation().pipe(
      map(coordinate => {
        return { coordinates: [coordinate], target: null, zoom };
      })
    );
  }
}
