// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:28:09
/* tslint:disable */
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CAnimalBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CAnimalBase';
  $t: string = 'SagaSchema.CAnimalBase';

  public static GetMembers(): string[] {
    return [
      'Text',
      'CallCardId',
      'TraceId',
      'Race',
      'Kind',
      'Color',
      'Name',
      'Identification',
      'Proprietor',
      'Description',
      'TraceState'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CAnimalBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Text: string = '';
  public CallCardId: Guid = GuidTools.Empty;
  public TraceId: Guid = GuidTools.Empty;
  public Race: string = '';
  public Kind: string = '';
  public Color: string = '';
  public Name: string = '';
  public Identification: string = '';
  public Proprietor: string = '';
  public Description: number = 0;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
