import { Component, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-custom-settings',
  templateUrl: './custom-settings.component.html',
  styleUrls: ['./custom-settings.component.scss']
})
export class CustomSettingsComponent implements OnInit, OnDestroy {
  @Input()
  component: Type<any>;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private viewContainer: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  constructor() {}

  ngOnInit() {
    this.componentRef = this.viewContainer.createComponent(this.component);
  }

  ngOnDestroy() {
    this.componentRef.destroy();
  }
}
