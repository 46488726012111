import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { ListPriority } from '@models/imported/SagaSchema/ListObjects/ListPriority';
import { TranslateService } from '@ngx-translate/core';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { IEventFormatter } from '../../../schema/interfaces/event-formatter';

export class FusEventFormatter implements IEventFormatter {
  constructor(private fetchList: FetchListService, private translate: TranslateService) {}

  format(event: CEvent, short: boolean): string {
    if (short) {
      return event.EventText;
    } else {
      const priority: string = this.fetchList.getRowValue(ListPriority, event.Priority.toString(), 'DisplayValue');
      const priorityText: string = `${this.translate.instant('Priority')}: ${priority}`;

      const eventItems: string[] = [priorityText, event.EventText, event.NodeTag, event.FieldGeneric1, event.Complement].filter(
        eventItem => eventItem
      );

      return eventItems.join('\r\n');
    }
  }
}
