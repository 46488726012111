import { Injectable } from '@angular/core';
import { UserProfile } from '@models/imported/SagaBase/UserProfile';
import { AddinService } from '@techwan/ionic-core';
import { UserProfile as CoreUserProfile } from '@techwan/ionic-core/lib/schema/imported/SagaBase/UserProfile';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { ProfileStorageService } from '../profile-storage/profile-storage.service';

/**
 * @description
 * The service for managing Saga profile
 */
@Injectable()
export class ProfileService {
  private _userProfile: UserProfile;
  get current(): UserProfile {
    return this._userProfile;
  }

  private _ready = new BehaviorSubject(false);
  get ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  constructor(private proxy: ProfileStorageService, private factory: ObjectFactory, private addins: AddinService) {}

  isReady(): boolean {
    return this._ready.value;
  }

  setup(profile: CoreUserProfile) {
    this._userProfile = this.factory.create(profile, UserProfile);
    if (this.current.WebConfig) {
      this.current.webConfig = JSON.parse(this.current.WebConfig);
    }
    if (this.current.WebProfile) {
      this.current.webProfile = JSON.parse(this.current.WebProfile);
    }
    if (this.current.WebLayout) {
      this.current.webLayout = JSON.parse(this.current.WebLayout);
    }
    if (this._userProfile.webProfile && this._userProfile.webProfile.components) {
      this.load();
    }
    this._ready.next(true);
  }

  /**
   * Save the profile of the logged user
   */
  saveProfile(profile: UserProfile): Observable<boolean> {
    return this.proxy.save(profile);
  }

  /**
   * Add an addin in the current profile
   */
  addAddin(module: string): Observable<boolean> {
    const index = this.current.modules.indexOf(module);
    if (index === -1) {
      this.current.modules.push(module);
    }
    return this.saveProfile(this.current);
  }

  /**
   * Remove an addin in the current profile
   */
  removeAddin(module: string): Observable<boolean> {
    const index = this.current.modules.indexOf(module);
    if (index !== -1) {
      this.current.modules.splice(index, 1);
    }

    return this.saveProfile(this.current);
  }

  private load() {
    const list = this.addins.getAll();
    const names = this._userProfile.webProfile.components.map(component => component.name);
    list.filter(item => names.indexOf(item.name) > -1).forEach(item => item.load());
  }
}
