import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { ICardTitleFormatter } from '../../../schema/interfaces/card-title-formatter';

export class CnuTitleFormatter implements ICardTitleFormatter {
  format(callcard: CallCard): string {
    const event: CEvent = callcard.event;
    const eventName: string = event ? [event.EventText, event.NodeTag].filter(item => item).join(' ') : '';
    return `${callcard.rLong()[0]} - ${eventName}`;
  }
}
