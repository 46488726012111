import { ICardInfoFormatter } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslateService } from '@ngx-translate/core';
import { SagaSettingsService } from '@services/settings';
import { MomentService } from '@techwan/ionic-core';

@Injectable()
export class CardInfoCVPFormatterService implements ICardInfoFormatter {
  constructor(
    private _translateService: TranslateService,
    private _momentService: MomentService,
    private _sagaSettingsService: SagaSettingsService
  ) {}

  format(card: CallCard, short: boolean = false): string[] {
    return short === true ? [this.short(card)] : this.long(card);
  }

  protected short(card: CallCard): string {
    return `${this._translateService.instant('Mobile.CardNumberTitle')}${card.Id}`;
  }

  protected long(card: CallCard): string[] {
    return [
      [
        `${this._translateService.instant('Mobile.CardNumberTitle')}${card.Id}`,
        `${this._momentService.formatDate(card.CreatedUTC, this._sagaSettingsService.getValue('SagaMobileWebClient.SubmittedDateFormat'))}`
      ]
        .filter(text => text)
        .join(' - '),
      [`${this._translateService.instant(card.status.Name)}`].filter(text => text).join(' - ')
    ];
  }
}
