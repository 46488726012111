import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavParams, IonSlides, ModalController } from '@ionic/angular';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { ModalService } from '@services/modal/modal.service';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { CallcardService } from '@services/callcard/callcard.service';

@Component({
  templateUrl: 'report.component.html',
  styleUrls: ['report.component.scss']
})
export class ReportComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  reportForm: FormGroup;
  object: SagaObject | any;
  definition: any;
  reportName: string;

  index = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected navParams: NavParams,
    protected modalService: ModalService,
    protected viewCtrl: ModalController,
    protected translatedAlertService: TranslatedAlertService,
    private callcardService: CallcardService
  ) {
    this.setObjectClass(this.navParams.get('object'), this.navParams.get('initialization'));
  }

  loadFormData() {}

  setObjectClass(constructor: any, initialization: any) {
    if (constructor instanceof SagaObject) {
      this.object = constructor;
    } else {
      this.object = new constructor();
      Object.assign(this.object, initialization || {});
    }
    this.reportName = this.object.title;

    if (this.object.constructor.formsProperties) {
      this.generateGroups(this.object.constructor.formsProperties);
    }
  }

  ngOnInit() {
    this.loadFormData();
  }

  slideChange() {
    this.slides.getActiveIndex().then(i => (this.index = i));
  }

  protected generateGroups(definition: any) {
    const formGroup = {};
    this.definition = definition;
    for (const groupName in definition) {
      if (definition.hasOwnProperty(groupName)) {
        const groupDef = definition[groupName];
        const formGroupDef = {};
        groupDef.forEach(name => (formGroupDef[name] = (this.object && this.object[name]) || ''));
        formGroup[groupName] = this.formBuilder.group(formGroupDef);
      }
    }

    this.reportForm = this.formBuilder.group(formGroup);
  }

  public dismiss() {
    this.viewCtrl.dismiss();
  }

  public async submitData() {
    const grouped = this.reportForm.getRawValue();
    for (const groupName in grouped) {
      if (grouped.hasOwnProperty(groupName)) {
        const groupValues = grouped[groupName];
        Object.assign(this.object, groupValues);
      }
    }
    const callcard: CallCard = this.navParams.get('CallCard') || (this.object as any).callcard;

    this.object.ParentObjId = callcard.ObjGuid;
    this.object.Owner = callcard.Owner;
    this.object.OwnerName = callcard.OwnerName;

    await this.modalService.presentLoading('SagaMobile.SubmitReport');
    let result;
    try {
      if (this.navParams.get('update')) {
        result = await this.callcardService.updateParticularsStamp(this.object);
      } else {
        result = await this.callcardService.addParticularsStamp(this.object);
      }
    } catch (error) {
      result = null;
    }

    await this.modalService.dismissLoading();

    if (result) {
      await this.viewCtrl.dismiss();
      if (callcard) {
        callcard.refreshStamps.emit();
      }
    } else {
      await this.translatedAlertService.show({
        message: 'Saga.ErrorOnSave',
        buttons: [{ text: 'Saga.Ok', value: true }]
      });
    }
  }

  hasGroups(): boolean {
    return this.reportForm && this.reportForm.controls[Object.keys(this.reportForm.controls)[0]] instanceof FormGroup;
  }

  getGroupsName() {
    return this.reportForm ? Object.keys(this.reportForm.controls) : [];
  }

  getInputsOfGroupName(groupName: string) {
    return this.reportForm && this.reportForm.controls[groupName]
      ? Object.keys((this.reportForm.controls[groupName] as any).controls as FormGroup)
      : [];
  }
}
