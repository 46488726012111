// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListArchiveType_abstract extends PersistentObject {
  static $t: string = 'SagaBase.ListArchiveType';
  $t: string = 'SagaBase.ListArchiveType';

  public static GetMembers(): string[] {
    return ['IfNew', 'Value', 'MandatoryStamps', 'Name', 'ApplyToCC', 'ApplyToTask', 'DefaultCreationType', 'DefaultRegistredType'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return ListArchiveType_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public IfNew: boolean = false;
  public Value: any;
  public MandatoryStamps: string;
  public Name: string;
  public ApplyToCC: boolean = true;
  public ApplyToTask: boolean = true;
  public DefaultCreationType: boolean = false;
  public DefaultRegistredType: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return null;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
