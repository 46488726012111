import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ModalService {
  private loading: HTMLIonLoadingElement;
  private present: Promise<void>;

  currentModalViewCtrl: LoadingController;

  constructor(private loadingCtrl: LoadingController, private translate: TranslateService, private platform: Platform) {}

  listenToModalView(viewCtrl?: LoadingController) {
    let backup;
    /*
        viewCtrl.didEnter.subscribe(() => {
            backup = this.currentModalViewCtrl;
            this.currentModalViewCtrl = viewCtrl;
        });

        viewCtrl.didLeave.subscribe(() => {
            this.currentModalViewCtrl = backup;
        });

         */
  }

  cancelBackButtonForModal(modal: HTMLIonModalElement) {
    const sub = this.platform.backButton.subscribeWithPriority(10000, () => {});
    modal.onDidDismiss().then(() => sub.unsubscribe());
  }

  presentLoading(text: string = 'static.loading'): Promise<any> {
    if (!this.loading) {
      return this.loadingCtrl
        .create({
          message: this.translate.instant(text)
        })
        .then(m => {
          this.loading = m;
          return (this.present = m.present());
        });
    } else {
      this.changeText(text);
      return this.present;
    }
  }

  changeText(text: string) {
    if (this.loading) {
      this.loading.message = this.translate.instant(text);
    }
  }

  dismissLoading(): Promise<any> {
    return this.loading
      ? this.loading.dismiss().then(() => {
          this.loading = null;
          this.present = null;
        })
      : Promise.resolve();
  }
}
