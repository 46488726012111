<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>SagaMobile.Notes</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon slot="icon-only" size="large" name="close-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" (click)="save()">
        <ion-icon slot="icon-only" name="checkmark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div layout="column" style="height: 100%">
    <textarea [(ngModel)]="notes" [maxlength]="maxlength"></textarea>
  </div>
</ion-content>
