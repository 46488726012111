<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="close()"><ion-icon slot="icon-only" name="close"></ion-icon></ion-button>
    </ion-buttons>
    <ion-title>
      <span translate>SagaMobile.UA.UnitActivityEditor</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="searchTemplate()"><ion-icon slot="icon-only" name="search"></ion-icon></ion-button>
      <ion-button [disabled]="!valid" (click)="save()"><ion-icon slot="icon-only" name="checkmark"></ion-icon></ion-button>
    </ion-buttons>
    <ion-buttons slot="end"><div status-bar></div></ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-segment color="light" [(ngModel)]="view">
      <ion-segment-button value="equipments">
        <span translate>SagaMobile.UA.Radios</span>
      </ion-segment-button>
      <ion-segment-button value="info">
        <span translate>SagaMobile.UA.Infos</span>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content class="padding">
  <div [ngSwitch]="view">
    <div *ngSwitchCase="'equipments'">
      <ion-card *ngFor="let radio of object.radios; let i = index">
        <ion-card-header>
          <ion-card-title><span translate>Saga.Equipment</span> {{ i + 1 }}</ion-card-title>
          <ion-button
            class="remove-radio"
            fill="clear"
            *ngIf="object.radios.length > 1 || object.radios[0].Identifier"
            color="danger"
            (click)="removeRadio(radio)"
            ><ion-icon name="trash"></ion-icon
          ></ion-button>
        </ion-card-header>
        <ion-item (click)="setPrincipalRadio(radio)" [disabled]="object.ungrouped">
          <ion-label><span translate>CommunicationEquipment.Principal</span></ion-label>
          <ion-radio-group [value]="radio.Principal" slot="end">
            <ion-radio mode="ios" value="true"></ion-radio>
          </ion-radio-group>
        </ion-item>
        <ion-item [style.color]="!radio.Principal || callsignValid ? '' : 'red'">
          <ion-label><span translate>CommunicationEquipment.Name</span></ion-label>
          <ion-input type="text" [(ngModel)]="radio.Name" (ngModelChange)="callsignChanged()"></ion-input>
          <ion-buttons slot="end">
            <ion-button fill="clear" *ngIf="radio.Name" (click)="radio.Name = null; callsignChanged()">
              <ion-icon name="close"></ion-icon>
            </ion-button>
            <ion-button
              fill="clear"
              [disabled]="!radio.Name || radio.Name.length < callsignMinCharToSearch"
              (click)="search(radio, radio.Name)"
            >
              <ion-icon name="search"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item>
          <ion-label><span translate>CommunicationEquipment.Description</span></ion-label>
          <ion-input type="text" [(ngModel)]="radio.Description" (ionChange)="callsignChanged()"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label><span translate>CommunicationEquipment.Type</span></ion-label>
          <ion-select [(ngModel)]="radio.CommunicationEquipmentType" (ionChange)="communicationTypeChanged(radio)">
            <ion-select-option
              *ngFor="let communicationEquipmentType of communicationEquipmentTypes"
              [value]="communicationEquipmentType.value"
            >
              <span [translate]="communicationEquipmentType.name"></span>
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>CommunicationEquipment.Identifier</span></ion-label>
          <ion-select
            [disabled]="radio.CommunicationEquipmentType === 0"
            [(ngModel)]="radio.Identifier"
            (ionChange)="identifierChanged(radio)"
          >
            <ion-select-option
              [disabled]="identifier.associate && identifier.associate !== radio"
              *ngFor="let identifier of getRfgiList(radio)"
              [value]="identifier.DisplayValue"
            >
              {{ identifier.properties.ImeiAlias || identifier.DisplayValue }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-card>
      <ion-button (click)="addRadio()" expand="block"><span translate>Saga.Add</span></ion-button>
    </div>

    <div *ngSwitchCase="'info'">
      <ion-card>
        <ion-card-header>
          <ion-card-title><span translate>Saga.UA.Create.Informations</span></ion-card-title>
        </ion-card-header>
        <ion-item>
          <ion-label><span translate>ListName.Types</span></ion-label>
          <ion-select [(ngModel)]="object.properties.type" (ngModelChange)="typeChanged()">
            <ion-select-option *ngFor="let type of types" [value]="type.ListNameValueId">{{ type.DisplayValue }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>ListName.SubTypes</span></ion-label>
          <ion-select [disabled]="!object.properties.type" [(ngModel)]="object.properties.subType" (ngModelChange)="changed()">
            <ion-select-option *ngFor="let subtype of subTypes[this.object.properties.type]" [value]="subtype.ListNameValueId">{{
              subtype.DisplayValue
            }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>ListName.ModeOfTransport</span></ion-label>
          <ion-select [(ngModel)]="object.properties.modeOfTransport" (ngModelChange)="changed()">
            <ion-select-option *ngFor="let modeOfTransport of modeOfTransports" [value]="modeOfTransport.ListNameValueId">{{
              modeOfTransport.DisplayValue
            }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>ListName.Category</span></ion-label>
          <ion-select [(ngModel)]="object.properties.category" (ngModelChange)="changed()">
            <ion-select-option *ngFor="let category of categories" [value]="category.ListNameValueId">{{
              category.DisplayValue
            }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Saga.StartDate</span></ion-label>
          <ion-datetime [(ngModel)]="object.startDate" displayFormat="DD/MM/YYYY HH:mm" (ionChange)="updateValid()"></ion-datetime>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Saga.EndDate</span></ion-label>
          <ion-datetime [(ngModel)]="object.endDate" displayFormat="DD/MM/YYYY HH:mm" (ionChange)="updateValid()"></ion-datetime>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Saga.RadioSector</span></ion-label>
          <ion-select [(ngModel)]="object.properties.secteurRadio" (ngModelChange)="updateValid()">
            <ion-select-option *ngFor="let radioZone of radiosZone" [value]="radioZone.Id">{{ radioZone.Name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Saga.GlobalFilter</span></ion-label>
          <ion-select [(ngModel)]="object.properties.poleTraitement[0]" (ngModelChange)="updateValid()">
            <ion-select-option *ngFor="let globalFilter of globalFilterList" [value]="globalFilter.ListNameValueId">{{
              globalFilter.DisplayValue
            }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Saga.Immat</span></ion-label>
          <ion-input [(ngModel)]="object.registration" type="text" (ionChange)="updateValid()"></ion-input>
          <ion-button fill="clear" slot="end" [disabled]="!object.registration" (click)="searchRegistration()">
            <ion-icon name="search"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title><span translate>Saga.UA.Create.Composition</span></ion-card-title>
        </ion-card-header>
        <ion-item>
          <ion-label><span translate>Resource.nbOfficial</span></ion-label>
          <ion-input type="text" [(ngModel)]="object.properties.nbOfficial" (ionChange)="updateValid()"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Resource.nbHighRankingOfficer</span></ion-label>
          <ion-input type="text" [(ngModel)]="object.properties.nbHighRankingOfficer" (ionChange)="updateValid()"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Resource.nbWomen</span></ion-label>
          <ion-input type="text" [(ngModel)]="object.properties.nbWoman" (ionChange)="updateValid()"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Resource.other</span></ion-label>
          <ion-input type="text" [(ngModel)]="object.properties.other" (ionChange)="updateValid()"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label><span translate>Resource.embeddedDevices</span></ion-label>
          <ion-textarea [(ngModel)]="object.properties.embededDevices" (ionChange)="updateValid()"></ion-textarea>
        </ion-item>
      </ion-card>
    </div>
  </div>
</ion-content>
