// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { QuestionState } from '../../SagaBase/QuestionState';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ChoiceQuestion_abstract extends QuestionState {
  static $t: string = 'SagaBase.ChoiceQuestion';
  $t: string = 'SagaBase.ChoiceQuestion';

  public static GetMembers(): string[] {
    return ['Choices', 'State'].concat(QuestionState.GetMembers());
  }

  public GetMembers(): string[] {
    return ChoiceQuestion_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Choices: any[];
  public State: number = -1;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
