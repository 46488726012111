import { PermanentEntryToggle } from '@addins/core/menus';
import { MenuEntry } from '@services/menu/entry.schema';

export class AccessMenuTool extends PermanentEntryToggle {
  public constructor() {
    super(new MenuEntry('cardList', 'list', null));
    this.disable();
  }

  public setCounter(value: number): void {
    const count = this._counter;
    this._counter = value;
    if (count !== this._counter) {
      this._changed.next();
    }
  }
}
