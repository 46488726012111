import { CurrentCardService, MyCardService } from '@addins/core/core';
import { IPermanentMenuEvent } from '@addins/core/menus';
import { Injectable } from '@angular/core';
import { Initializer } from '@services/initializer/initializer.service';
import { LayoutService } from '@services/layout/layout.service';
import { SideMenuService } from '@services/menu/side-menu.service';
import { SagaSettingsService } from '@services/settings';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter, first } from 'rxjs/operators';
import { CallcardMenuEntry } from '../../../schema/callcard-menu-entry';
import { CallcardMenuControllerService } from '../../callcard-menu-controller/callcard-menu-controller.service';

@AddinExecute({
  name: 'CallcardMenuSetup'
})
@Injectable()
export class CallcardMenuSetupService extends Execute {
  private _callcardMenuEntry: CallcardMenuEntry = new CallcardMenuEntry();
  get menuEntry(): CallcardMenuEntry {
    return this._callcardMenuEntry;
  }

  constructor(
    private sideMenu: SideMenuService,
    private currentCard: CurrentCardService,
    private callcardMenuController: CallcardMenuControllerService,
    private myCard: MyCardService,
    private layout: LayoutService,
    private initializer: Initializer,
    private sagaSettings: SagaSettingsService
  ) {
    super();
  }

  execute(): void {
    this.initializer.onSetupBefore.subscribe(() => this.onCompleted());
  }

  private onCompleted() {
    this.sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.setupCallcardMenu();
      });
  }

  private setupCallcardMenu() {
    const canToggleCardMenu: boolean = this.sagaSettings.getValue('SagaMobileWebClient.CanToggleCardMenu');
    this._callcardMenuEntry.setToggleable(canToggleCardMenu);

    if (this.layout.layout.home === 'Home/generic') {
      this.sideMenu.addMenuEntry(this._callcardMenuEntry);
      this._callcardMenuEntry.onClick.subscribe(menuEvent => this.onClicked(menuEvent));
      this.callcardMenuController.setup(this._callcardMenuEntry);
    }
  }

  private onClicked(menuEvent: IPermanentMenuEvent): void {
    if (!menuEvent.item.isActive) {
      this.currentCard.reset();
    } else {
      this.currentCard.select(this.myCard.myCard);
    }
  }
}
