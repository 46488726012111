// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class GeoSectorArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.GeoSectorArgs';
  $t: string = 'SagaBase.Defines.GeoSectorArgs';

  public static GetMembers(): string[] {
    return ['ModificationUtc', 'DirectMode', 'GeoSectorList', 'AssociationList'];
  }

  public GetMembers(): string[] {
    return GeoSectorArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ModificationUtc = this.ModificationUtc && new Date(this.ModificationUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ModificationUtc: Date = new Date();
  public DirectMode: boolean = false;
  public GeoSectorList: any[];
  public AssociationList: any[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
