import { Injectable } from '@angular/core';
import { CurrentCardService } from '@addins/core/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { FullViewMenuTool } from '../../schema/menu/full-view-menu-tool';
import { MenuState, PermanentEntry } from '@addins/core/menus';

@Injectable()
export class MenuEntryControllerService {
  private _menuEntry: PermanentEntry = null;

  constructor(private readonly _currentCardService: CurrentCardService) {}

  public setup(entry: FullViewMenuTool) {
    this._menuEntry = entry;
    this._menuEntry.onChanged.subscribe(() => this.onMenuEntryChanged());
    this._currentCardService.$change.subscribe(card => this.onCurrentCardChanged(card));
  }

  private onCurrentCardChanged(card: CallCard) {
    if (card !== null) {
      this._menuEntry.setState(MenuState.inactive);
    }
  }

  private onMenuEntryChanged() {
    if (this._menuEntry.isActive && this._currentCardService.current !== null) {
      this._currentCardService.select(null);
    }
  }
}
