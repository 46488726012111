import { CustomComponentsService } from '@addins/listcallcards';
import { RESOURCE_COUNT_ADDIN_NAME } from '@addins/resource-count';
import { Injectable } from '@angular/core';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { StatusChangeRequirementsService } from '@services/status/status-change-requirements/status-change-requirements.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter, first } from 'rxjs/operators';
import { EngagementListButtonComponent } from '../../../components/engagement-list-button/engagement-list-button.component';
import { ENGAGEMENT_ADDIN_NAME } from '../../../schema/engagement-addin-name';
import { EngagementCardButtonService } from '../../engagement-card-button/engagement-card-button.service';
import { EngagementRequirementService } from '../../engagement-requirement/engagement-requirement.service';
import { EngagementService } from '../../engagement/engagement.service';

@AddinExecute({
  name: 'EngagementSetup'
})
@Injectable({
  providedIn: 'root'
})
export class EngagementSetupService extends Execute {
  constructor(
    private engagementCardButton: EngagementCardButtonService,
    private customComponents: CustomComponentsService,
    private profileService: ProfileService,
    private profileReader: ProfileReaderService,
    private statusChangeRequirements: StatusChangeRequirementsService,
    private engagementRequirement: EngagementRequirementService,
    private engagementService: EngagementService
  ) {
    super();
  }

  execute() {
    this.profileService.ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.addEngagementRequirement();
        const canReassign: boolean = this.profileReader.getComponentConfig(ENGAGEMENT_ADDIN_NAME, 'canReassign', true);
        this.engagementService.setup(canReassign);
        this.engagementCardButton.setup(canReassign);
        this.customComponents.add({ slot: 'end', component: EngagementListButtonComponent });
      });
  }

  private addEngagementRequirement() {
    if (!this.hasResourceCount()) {
      this.statusChangeRequirements.addRequirement(this.engagementRequirement);
    }
  }

  private hasResourceCount(): boolean {
    return !!this.profileReader.getComponent(this.profileService.current, RESOURCE_COUNT_ADDIN_NAME);
  }
}
