<ion-list>
  <ion-item-divider sticky>
    <ion-label class="ion-text-wrap title"
      ><h2>
        <strong>{{ title }}</strong>
      </h2></ion-label
    >
  </ion-item-divider>
  <app-attachment-item *ngFor="let attachment of attachments" [attachment]="attachment"></app-attachment-item>
</ion-list>
