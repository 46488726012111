import { Component, Input } from '@angular/core';
import { SelectBoxTool, ToolBase } from '@techwan/map-tools';

enum ToolType {
  button,
  selectBox
}

@Component({
  selector: 'app-tool-group',
  templateUrl: './tool-group.component.html',
  styleUrls: ['./tool-group.component.scss']
})
export class ToolGroupComponent {
  @Input() tools: ToolBase[];

  selectBox = ToolType.selectBox;
  button = ToolType.button;

  constructor() {}

  getType(toolBase: ToolBase): ToolType {
    if (toolBase instanceof SelectBoxTool) {
      return this.selectBox;
    } else {
      return this.button;
    }
  }

  toolAsSelectBox(tool: ToolBase): SelectBoxTool {
    return tool as SelectBoxTool;
  }
}
