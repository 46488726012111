import { Pipe, PipeTransform } from '@angular/core';
import { ListProjectTypes } from '@models/imported/SagaSchema/ListObjects/ListProjectTypes';
import { FetchListService } from '@services/fetchlist/fetchlist.service';

@Pipe({ name: 'projectType' })
export class ProjectTypePipe implements PipeTransform {
  constructor(private fetchList: FetchListService) {}

  transform(projectType: number): string {
    return this.fetchList.getRowValue(ListProjectTypes, projectType);
  }
}
