import { Injectable } from '@angular/core';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { CallCardListFiltersService } from '../../callcard-list-filters/callcard-list-filters.service';
import { PriorityFilterService } from '../../priority-filter/priority-filter.service';

@AddinExecute({
  name: 'CustomFiltersSetupService',
  description: 'Setup callcards list custom filters'
})
@Injectable()
export class CustomFiltersSetupService extends Execute {
  constructor(
    private profileReader: ProfileReaderService,
    private callcardListFilters: CallCardListFiltersService,
    private priorityFilter: PriorityFilterService
  ) {
    super();
  }

  execute() {
    const filters: string[] = this.profileReader.getComponentConfig('listcallcards', 'filters', []);
    if (filters.indexOf('Priority') >= 0) {
      this.priorityFilter.setup();
      this.callcardListFilters.addFilter(this.priorityFilter);
    }
  }
}
