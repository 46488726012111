export class QueuingConnectionState {
  constructor(public key: string, public isConnected: boolean, public isRegistered: boolean) {}
}

export const QueuingClientConnectionState = {
  Initialized: new QueuingConnectionState('Initialized', false, false),
  Disconnected: new QueuingConnectionState('Disconnected', false, false),
  Reconnecting: new QueuingConnectionState('Reconnecting', false, false),
  Connected: new QueuingConnectionState('Connected', true, false),
  Registered: new QueuingConnectionState('Registered', true, true)
};
