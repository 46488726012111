import { Component, OnInit, Type } from '@angular/core';
import { CurrentCardService } from '@addins/core/core';
import { NavParams } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { AddinView } from '@techwan/ionic-core';
import { CardsProviderService } from '../../services/cards-provider/cards-provider.service';

@AddinView({
  name: 'FullView'
})
@Component({
  selector: 'app-full-view',
  templateUrl: './full-view.component.html',
  styleUrls: ['./full-view.component.scss']
})
export class FullViewComponent implements OnInit {
  public currentCard: CallCard = null;

  public get customContent(): Type<any>[] {
    return this._cardsProvider.cards;
  }

  constructor(
    private readonly _currentCardService: CurrentCardService,
    private readonly _navParamService: NavParams,
    private readonly _cardsProvider: CardsProviderService
  ) {}

  ngOnInit() {
    this.currentCard = (this._navParamService.data && this._navParamService.data.card) || this._currentCardService.current;
  }
}
