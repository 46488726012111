<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span translate>SagaMobile.Menu.CreateFPC</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scroll-no-padding>
  <div layout="column">
    <ion-card>
      <ion-card-header class="event-header" color="primary">
        <ion-card-title translate="SagaMobile.Event"></ion-card-title>
      </ion-card-header>
      <ion-item (click)="selectEvent()" button class="multiselect">
        <span translate>{{ currentEventText }}</span>
        <ion-button slot="end" fill="clear" translate>Change</ion-button>
      </ion-item>
    </ion-card>
    <ion-card>
      <ion-card-header class="event-header" color="primary">
        <ion-card-title translate="SagaSchema.CNote"></ion-card-title>
      </ion-card-header>
      <ion-item (click)="writeNotes(4000)">
        <p *ngIf="!notes" style="font-style: italic; opacity: 0.6"><span translate="SagaMobile.Notes"></span></p>
        <p *ngIf="notes" style="white-space: pre-line;">{{ notes }}</p>
      </ion-item>
    </ion-card>
    <ion-card flex>
      <ion-item color="primary">
        <ion-card-title color="light" translate="SagaSchema.CLocation"></ion-card-title>
        <ion-button color="light" fill="clear" (click)="getLocationAddress()" slot="end"
          ><ion-icon slot="icon-only" name="refresh" size="large"></ion-icon
        ></ion-button>
      </ion-item>
      <ion-card-content>
        <!-- <img src="{{location.getStaticMapUrl({ zoom: 20})}}" *ngIf="location"> -->
        <ion-item *ngIf="!location">
          <p style="white-space: initial;"><span translate="SagaMobile.NoAddressFound"></span></p>
        </ion-item>
        <ion-item>
          <p style="white-space: initial;" *ngIf="location">{{ location.FormattedLocation }}</p>
        </ion-item>
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-header class="zone-header" ion-text color="primary">
        <ion-card-title translate="SagaMobile.Owner"></ion-card-title>
      </ion-card-header>
      <ion-item>
        <p style="white-space: initial;" *ngIf="zone">{{ zone.Name }}</p>
      </ion-item>
    </ion-card>

    <ion-button (click)="createCard()" [disabled]="!canSave()"><span translate="SagaMobile.CreateCard.Button"></span></ion-button>
  </div>
</ion-content>
