<ion-header *ngIf="myTask">
  <ion-item-divider>
    <ion-label><strong translate>Mobile.MyCardTitle</strong></ion-label>
  </ion-item-divider>
  <app-task-list-item [task]="myTask" [isMyTask]="true" (click)="openTaskDetails(myTask)"></app-task-list-item>
</ion-header>

<ion-content>
  <ion-list *ngIf="tasks">
    <ion-item-divider sticky>
      <ion-label><strong translate>Mobile.OthersCardTitle</strong></ion-label>
    </ion-item-divider>

    <ng-container *ngFor="let task of tasks.items">
      <app-task-list-item [task]="task" (click)="openTaskDetails(task)"></app-task-list-item>
    </ng-container>
  </ion-list>
</ion-content>
