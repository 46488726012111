<ion-header translucent>
  <ion-toolbar>
    <ion-title translate>Mobile.AvailableResource</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()" translate>Mobile.Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar
    #searchbar
    placeholder="{{ 'Mobile.Filter' | translate }}"
    (ionInput)="onSearch($event.target.value)"
    clearIcon="close-circle"
  ></ion-searchbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let item of items" (click)="onSelect(item)">
      <ion-label class="ion-text-wrap">{{ item.VehicleCode }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
