// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:40
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class AlarmStateChangeRequest_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.AlarmStateChangeRequest';
  $t: string = 'SagaBase.Defines.AlarmStateChangeRequest';

  public static GetMembers(): string[] {
    return [
      'ManagerUid',
      'Components',
      'StateChanges',
      'UserId',
      'UserName',
      'DeferredDate',
      'ActivateAlarmId',
      'RequestSource',
      'IsInitialRequestDefferedInServiceTime',
      'Comment',
      'TriggeringObject',
      'RequestSourceXML'
    ];
  }

  public GetMembers(): string[] {
    return AlarmStateChangeRequest_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DeferredDate = this.DeferredDate && new Date(this.DeferredDate);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ManagerUid: Guid = GuidTools.Empty;
  public Components: any[];
  public StateChanges: any[][];
  public UserId: number = 0;
  public UserName: string;
  public DeferredDate: Date = new Date();
  public ActivateAlarmId: Guid = GuidTools.Empty;
  public RequestSource: string;
  public IsInitialRequestDefferedInServiceTime: boolean = false;
  public Comment: string;
  public TriggeringObject: any;
  public RequestSourceXML: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
