import { Injectable } from '@angular/core';
import { transform } from '@services/map';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { Destination } from '../../schema/destination';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';

@Injectable({
  providedIn: 'root'
})
export class SygicProviderService implements INavigationProvider {
  private readonly _epsg = '4326';

  constructor(private readonly window: WindowOpenerService) {}

  public navigate(destination: Destination): Window {
    if (destination.location.epsg !== this._epsg) {
      destination.location = transform(destination.location, `EPSG:${this._epsg}`);
    }
    return this.window.system(`com.sygic.aura://coordinate%7C${destination.location.x}%7C${destination.location.y}%7Cdrive`);
  }
}
