// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Service_abstract extends SagaObject {
  static $t: string = 'SagaSchema.Service';
  $t: string = 'SagaSchema.Service';

  public static GetMembers(): string[] {
    return [
      'ServiceId',
      'Name',
      'Description',
      'ServiceCategoryId',
      'svcTypeConst',
      'MapLayerName',
      'AllowedContents',
      'IsBridgingObject',
      'ProposedForMobilisation',
      'ProposedForEngagement',
      'ProposedForUnits',
      'Sectorized',
      'DefaultSchedule',
      'ServiceImpName',
      'SectorTreeTypes'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Service_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ServiceId: number = 0;
  public Name: string;
  public Description: string;
  public ServiceCategoryId: number;
  public svcTypeConst: number;
  public MapLayerName: string;
  public AllowedContents: number = 383;
  public IsBridgingObject: boolean = false;
  public ProposedForMobilisation: boolean = false;
  public ProposedForEngagement: boolean = true;
  public ProposedForUnits: boolean = true;
  public Sectorized: boolean = false;
  public DefaultSchedule: SagaBase.DefaultScheduleType = SagaBase.DefaultScheduleType.UnDefined;
  public ServiceImpName: string;
  public SectorTreeTypes: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
