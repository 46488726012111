import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CCaller } from '@models/imported/SagaSchema/CCaller';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { DbService } from '@services/database/database.service';
import { getStaticMapUrl } from '@services/map';
import { ModalService } from '@services/modal/modal.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Security } from '@services/security/security.service';

@Component({
  templateUrl: 'coordinate-picker.component.html',
  styleUrls: ['coordinate-picker.component.scss']
})
export class CoordinatePickerComponent {
  caller: CCaller;

  constructor(
    private navParams: NavParams,
    private viewCtrl: ModalController,
    private security: Security,
    private db: DbService,
    private modal: ModalService,
    private objectFactory: ObjectFactory
  ) {
    this.caller = this.navParams.get('caller');
  }

  get unitActivity() {
    return this.security.getData('UnitActivity');
  }

  staticMapUrl(options): string {
    return getStaticMapUrl(
      this.caller.coordinate,
      Object.assign(
        {
          height: 150,
          zoom: 15
        },
        options
      )
    );
  }

  getHeight(card, label, header?: HTMLLIElement) {
    return card.offsetHeight - label.getNativeElement().offsetHeight - (header ? header.offsetHeight : 0);
  }

  async changeLocation() {
    let location: CLocation = this.objectFactory.create(this.unitActivity.callcard.location);
    location.copyFromCaller(this.caller);
    await this.modal.presentLoading('SagaMobile.CallCard.ChangeLocationPending');
    await this.db.updateObject(location);
    await this.modal.dismissLoading();
    this.viewCtrl.dismiss();
  }

  cancel() {
    this.viewCtrl.dismiss();
  }
}
