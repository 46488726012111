import {
  ICallcardButtonEvent,
  ICallcardButtonTool,
  ToolColor,
  ToolExpand,
  ToolFill,
  ToolSize,
  ToolType
} from '@addins/core/singlecallcard';
import { Observable, Subject } from 'rxjs';

export class SagaFormsCardButton implements ICallcardButtonTool {
  readonly CREATE_FORM_TITLE: string = 'Mobile.CreateForm';

  private readonly _click = new Subject<ICallcardButtonEvent>();
  get onClick(): Observable<ICallcardButtonEvent> {
    return this._click.asObservable();
  }

  readonly icon: string = 'assets/icons/saga-forms/saga-forms.svg';
  position: number = 200;
  show: boolean;
  text: string = this.CREATE_FORM_TITLE;
  dontTranslate: boolean = false;
  type: ToolType;

  color: ToolColor;
  expand: ToolExpand;
  fill: ToolFill;
  size: ToolSize;

  click(): void {
    this._click.next();
  }
}
