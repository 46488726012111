<div class="login-back-image-wrapper">
  <img class="login-back-image" src="assets/logo.png" alt="logo" />
</div>
<p [translate]="loadingText"></p>
<ion-button id="loading-alert-button" *ngIf="canCreateAlert" expand="block" [appAlert]="count">
  <span translate>Mobile.Alert</span>
  <app-alert-counter #count></app-alert-counter>
</ion-button>
<ion-button *ngIf="canCreateUnit" expand="block" (click)="onCreateUnit()">
  <span translate>static.login.unit.Create</span>
</ion-button>
