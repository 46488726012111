// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @18/04/2023 14:56:21
import { SagaObject } from '../../SagaBase/SagaObject';

export abstract class FormTemplate_abstract extends SagaObject {
  static $t: string = 'SagaSchema.FormTemplate';
  $t: string = 'SagaSchema.FormTemplate';

  public static GetMembers(): string[] {
    return ['Name', 'Layout', 'IsStandalone'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return FormTemplate_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Name: string;
  public Layout: string;
  public IsStandalone: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
