import { Injectable } from '@angular/core';
import { StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { IStatusChangeRequirement } from '@services/status/status-change-requirements/status-change-requirements.service';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { StatusService } from '@services/status/status.service';
import { ItineraryProviderService } from '../itinerary-provider/itinerary-provider.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementRequirementService implements IStatusChangeRequirement {
  constructor(private statusService: StatusService, private itineraryProvider: ItineraryProviderService) {}

  preChangeRequirement(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    return Promise.resolve(true);
  }

  postChangeRequirement(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    if (this.isAssignment(newStatus)) {
      return this.itineraryProvider
        .navigateTo(callcard.location, false)
        .then(() => true)
        .catch(() => false);
    } else {
      return Promise.resolve(true);
    }
  }

  private isAssignment(status: ListResourceStatus): boolean {
    return (
      status === this.statusService.getStatusByAction(StatusItemAction.isAssignement) ||
      status === this.statusService.getStatusByAction(StatusItemAction.isReassignment)
    );
  }

  getStatusParameters(): StatusParameters {
    return {};
  }
}
