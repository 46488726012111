import { Subscription, timer } from 'rxjs';
import { Renderer2 } from '@angular/core';

export class CountDown {
  private readonly _delta = 100;
  private _remaining: number;

  private _timer: Subscription = null;

  private _target: any = null;
  private _parent: any = null;
  private _renderer: Renderer2 = null;

  public start(parent: any, target: any, renderer: Renderer2, total: number) {
    this._remaining = total;
    this._parent = parent;
    this._target = target;
    this._renderer = renderer;
    this.init();
    this.wait();
  }

  public stop(internal?: boolean) {
    if (this._parent === null) {
      return;
    }

    if (internal === true) {
      this._target.innerText = '0s';
    } else {
      this._timer.unsubscribe();
      this._renderer.removeClass(this._target, 'active');
      this._parent = null;
      this._target = null;
    }
  }

  private init() {
    const zIndex = parseInt(getComputedStyle(this._parent).zIndex, 10);
    this._renderer.addClass(this._target, 'active');
    if (zIndex) {
      this._renderer.setStyle(this._target, 'zIndex', zIndex + 1);
    }
  }

  private wait() {
    this._target.innerText = `${this.refresh(this._remaining / 1000)}s`;
    this._timer = timer(this._delta).subscribe(() => {
      this._remaining -= this._delta;
      if (this._remaining > 0) {
        this.wait();
      } else {
        this.stop(true);
      }
    });
  }

  private refresh(count: number) {
    return count.toFixed(1);
  }
}
