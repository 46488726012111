import { MapName } from '@addins/core/core';
import { FeatureStyleService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { Task } from '@models/imported/SagaSchema/Task';
import { MapInstancesService } from '@services/map';
import { LocalizableObjectLayer, ObjectFeature, Map as SagaMap } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TasksProviderService } from '../tasks-provider/tasks-provider.service';

@Injectable({
  providedIn: 'root'
})
export class TasksLayerService {
  private sagaMap: SagaMap = null;
  private tasksLayer: LocalizableObjectLayer = null;
  private myTaskLayer: LocalizableObjectLayer = null;

  private tasksRefreshSub: Subscription = null;

  constructor(
    private tasksProvider: TasksProviderService,
    private featureStyle: FeatureStyleService,
    private mapInstances: MapInstancesService
  ) {}

  init() {
    this.mapInstances.$change
      .pipe(filter(mapEvent => mapEvent.name === MapName.main))
      .subscribe(mapEvent => this.onMapChanged(mapEvent.target));
  }

  private onMapChanged(sagaMap: SagaMap) {
    if (sagaMap && this.sagaMap === null) {
      this.sagaMap = sagaMap;
      this.setup();
    } else if (!sagaMap) {
      this.cleanup();
      this.sagaMap = null;
    }
  }

  private setup() {
    this.createTaskLayers();

    this.tasksRefreshSub = this.tasksProvider.tasks.onRefresh.subscribe(() => this.updateTasks());
    this.updateTasks();
  }

  private createTaskLayers() {
    this.tasksLayer = this.sagaMap.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false
    });

    this.myTaskLayer = this.sagaMap.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      zIndex: 1
    });
  }

  private cleanup() {
    if (this.tasksRefreshSub) {
      this.tasksRefreshSub.unsubscribe();
      this.tasksRefreshSub = null;
    }

    this.clearTaskLayers();
    this.tasksLayer = null;
    this.myTaskLayer = null;
  }

  private clearTaskLayers() {
    this.tasksLayer.clearObjects();
    this.myTaskLayer.clearObjects();
  }

  private updateTasks() {
    const map = this.mapInstances.get(MapName.main);
    if (map !== null) {
      this.clearTaskLayers();

      this.tasksProvider.tasks.items.forEach(task => this.addTaskToLayer(this.tasksLayer, task));

      const myTask: Task = this.tasksProvider.myTask;
      if (myTask) {
        this.addTaskToLayer(this.myTaskLayer, myTask, true);
      }
    }
  }

  private addTaskToLayer(layer: LocalizableObjectLayer, task: Task, isMyTask: boolean = false) {
    let isTaskAddedToLayer: boolean = false;

    if (task.location) {
      const coordinate = task.location.getOLCoordinate();
      if (coordinate) {
        const feature: ObjectFeature = layer.addObject(task.ObjGuid, coordinate, 'EPSG:' + task.location.coordinate.epsg);
        if (feature) {
          feature.setData('object', task);
          feature.set('id', task.ObjGuid);
          this.featureStyle.applyCardStyle(feature, task, task.Name, 'black', isMyTask);
          isTaskAddedToLayer = true;
        }
      }
    }

    if (!isTaskAddedToLayer) {
      console.warn(`Task could not be added to the map (id: ${task.ObjGuid})`);
    }
  }
}
