import { Injectable } from '@angular/core';
import { AlertController, AlertInput } from '@ionic/angular';
import { AlertButton, AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

export interface TranslatedAlertButton extends AlertButton {
  value: boolean;
}

export interface TranslatedAlertOptions extends AlertOptions {
  message?: string;
  buttons?: TranslatedAlertButton[];
}

@Injectable({
  providedIn: 'root'
})
export class TranslatedAlertService {
  private _defaultButtons: TranslatedAlertButton[] = [
    {
      text: 'Mobile.Cancel',
      role: 'cancel',
      value: false
    },
    {
      text: 'Mobile.Accept',
      value: true
    }
  ];

  constructor(private alertController: AlertController, private translate: TranslateService) {}

  /**
   * Shows an alert with translated header, message and buttons
   * @param options AlertOptions
   * @returns a promise that resolves with the value provided in the TranslatedAlertButton
   *  when the alert is dismissed if no handler was provided, otherwise the handler is called
   */
  show(options: TranslatedAlertOptions): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      options = Object.assign(options, {
        header: options.header ? this.translate.instant(options.header) : undefined,
        message: options.message ? this.translate.instant(options.message) : undefined,
        inputs: options.inputs ? this.translateInputLabels(options.inputs) : undefined,
        buttons: this.createButtons(options.buttons, resolve)
      });

      this.alertController.create(options).then(ionAlert => ionAlert.present());
    });
  }

  private createButtons(buttons: TranslatedAlertButton[], resolve: (value?: boolean | PromiseLike<boolean>) => void): AlertButton[] {
    return (buttons || this._defaultButtons).map(button => {
      return {
        text: this.translate.instant(button.text),
        role: button.role,
        handler: button.handler ? button.handler : () => resolve(!!button.value)
      };
    });
  }

  private translateInputLabels(inputs: AlertInput[]): AlertInput[] {
    return inputs.map(input => {
      input.label = input.label ? this.translate.instant(input.label) : undefined;
      return input;
    });
  }
}
