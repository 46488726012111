<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-menu-toggle menu="start" slot="start" sagaMenu>
            <ion-button fill="clear" color="light"><ion-icon slot="icon-only"  size="large" name="menu"></ion-icon></ion-button>
        </ion-menu-toggle>
        <ion-title>
            <span translate="SagaMobile.DocumentsList"></span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <loading-bar *ngIf="isLoading"></loading-bar>
    <app-document-list *ngIf="documents" [documents]="documents"></app-document-list>
    <div class="error" *ngIf="hasError">
        <h1 translate>{{error.type}}</h1>
        <p translate>{{error.message}}</p>
        <p translate>{{error.error}}</p>
    </div>
    <div *ngIf="isEmpty" translate>Mobile.NoDocuments</div>
</ion-content>
