<ion-header *ngIf="!isTablet">
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-menu-toggle menu="start" slot="start" sagamenu [hidden]="isTablet">
            <ion-button fill="clear" color="light"><ion-icon slot="icon-only"  size="large" name="menu"></ion-icon></ion-button>
        </ion-menu-toggle>
        <ion-title>
            <span translate>SagaMobile.Map</span>
        </ion-title>
        <ion-buttons slot="end">
            <div status-bar></div>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
