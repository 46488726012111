// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class glblScheduleTemplates_abstract extends SagaObject {
  static $t: string = 'SagaSchema.glblScheduleTemplates';
  $t: string = 'SagaSchema.glblScheduleTemplates';

  public static GetMembers(): string[] {
    return [
      'glblScheduleTemplates_Uid',
      'glblScheduleTemplates_StartTime',
      'glblScheduleTemplates_EndTime',
      'glblScheduleTemplates_DayName',
      'glblScheduleTemplates_Active',
      'glblScheduleTemplates_Day',
      'glblScheduleTemplates_Id',
      'glblScheduleTemplates_glblScheduleDescriptionUid',
      'glblScheduleTemplates_glblScheduleDescriptionName'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return glblScheduleTemplates_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.glblScheduleTemplates_StartTime = this.glblScheduleTemplates_StartTime && new Date(this.glblScheduleTemplates_StartTime);
    this.glblScheduleTemplates_EndTime = this.glblScheduleTemplates_EndTime && new Date(this.glblScheduleTemplates_EndTime);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public glblScheduleTemplates_Uid: Guid = GuidTools.Empty;
  public glblScheduleTemplates_StartTime: Date = new Date();
  public glblScheduleTemplates_EndTime: Date = new Date();
  public glblScheduleTemplates_DayName: string;
  public glblScheduleTemplates_Active: boolean = false;
  public glblScheduleTemplates_Day: number = 0;
  public glblScheduleTemplates_Id: number = 0;
  public glblScheduleTemplates_glblScheduleDescriptionUid: Guid = GuidTools.Empty;
  public glblScheduleTemplates_glblScheduleDescriptionName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.glblScheduleTemplates_Uid;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
