import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileEntry } from '@ionic-native/file';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileBridgeService } from '../file-bridge/file-bridge.service';
import { FileSystemService } from '../file-system/file-system.service';

interface Range {
  start: number;
  end: number;
}

interface FileWithChunks {
  url: string;
  fileEntry: FileEntry;
  fileSize: number;
  ranges: Range[];

  isCanceled: boolean;

  onProgress: (done: number, total: number) => any;

  resolve: any;
  reject: any;
}

const DEFAULT_CHUNK_SIZE: number = 200000;

@Injectable()
export class FileDownloadService {
  private fileQueue: FileWithChunks[] = [];

  private _isPaused: boolean = false;
  get isPaused(): boolean {
    return this._isPaused;
  }
  private isDownloading: boolean = false;

  private downloadChunkSub: Subscription = null;

  constructor(private httpClient: HttpClient, private fileSystemService: FileSystemService, private fileBridgeService: FileBridgeService) {}

  download(url: string): Observable<HttpEvent<HttpProgressEvent>> {
    const getOptions: any = {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events'
    };

    return this.httpClient.get<HttpProgressEvent>(url, getOptions);
  }

  downloadFile(url: string, fileName: string, onProgress: (done: number, total: number) => any): Promise<FileEntry> {
    return new Promise((resolve, reject) =>
      this.getFileSize(url)
        .then(fileSize => {
          onProgress(0, fileSize);
          const ranges: Range[] = this.prepareRanges(fileSize);
          this.fileSystemService
            .getFileFromRoot(fileName, true)
            .then((file: FileEntry) => this.fileBridgeService.getEntry(file.nativeURL))
            .then((file: FileEntry) => this.fileBridgeService.writeFile(file, '').then(() => file))
            .then((file: FileEntry) => {
              if (ranges.length) {
                this.fileQueue.push({ url, fileEntry: file, fileSize, ranges, isCanceled: false, onProgress, resolve, reject });
                this.downloadFileWithChunks();
              } else {
                onProgress(fileSize, fileSize);
                resolve(file);
              }
            })
            .catch(reason => reject(reason));
        })
        .catch(reason => reject(reason))
    );
  }

  getFileSize(url: string): Promise<number> {
    return this.httpClient
      .head(url, {
        observe: 'response'
      })
      .toPromise()
      .then((event: HttpResponse<any>) => parseInt(event.headers.get('content-length'), 10));
  }

  private prepareRanges(fileSize: number, chunkSize: number = DEFAULT_CHUNK_SIZE): Range[] {
    const hasNotWholeChunk: boolean = fileSize % chunkSize != 0;
    const wholeChunks: number = Math.floor(fileSize / chunkSize);
    let ranges: Range[] = [];

    for (let chunk: number = 0; chunk < wholeChunks; chunk++) {
      const chunkStart: number = chunk * chunkSize;
      const chunkEnd: number = chunkStart + chunkSize - 1;
      ranges.push({ start: chunkStart, end: chunkEnd });
    }

    if (hasNotWholeChunk) {
      const lastChunkStart: number = wholeChunks * chunkSize;
      ranges.push({ start: lastChunkStart, end: fileSize - 1 });
    }

    return ranges;
  }

  private downloadFileWithChunks() {
    this._isPaused = false;

    if (!this.isDownloading) {
      this.downloadFileChunks();
    }
  }

  private downloadFileChunks() {
    if (!this._isPaused) {
      const fileWithChunks: FileWithChunks = this.fileQueue[0];
      const ranges: Range[] = fileWithChunks.ranges;
      if (ranges.length) {
        this.isDownloading = true;
        const range: Range = ranges[0];
        this.downloadChunkSub = this.downloadFileChunk(fileWithChunks.url, range)
          .pipe(
            catchError(error => {
              this.onError(error);
              return throwError(error);
            })
          )
          .subscribe((httpEvent: HttpProgressEvent | HttpResponse<Blob>) => this.onHttpEvent(httpEvent, fileWithChunks, range));
      } else {
        this.onFileFinished();
      }
    }
  }

  private downloadFileChunk(url: string, range: Range): Observable<HttpEvent<any>> {
    return this.httpClient.get(url, {
      observe: 'events',
      responseType: 'blob',
      reportProgress: true,
      headers: { range: `bytes=${range.start}-${range.end}` }
    });
  }

  private onError(error: any) {
    this.isDownloading = false;

    this.fileQueue[0].reject(error);
    this.fileQueue.shift();
  }

  private onHttpEvent(httpEvent: HttpProgressEvent | HttpResponse<Blob>, fileWithChunks: FileWithChunks, range: Range) {
    if (httpEvent.type === HttpEventType.Response) {
      this.onFileChunkDownloaded(fileWithChunks, range, httpEvent.body);
    } else if (httpEvent.type === HttpEventType.DownloadProgress) {
      this.onProgress(fileWithChunks, range.start + httpEvent.loaded);
    }
  }

  private onProgress(fileWithChunks: FileWithChunks, done: number) {
    if (!fileWithChunks.isCanceled) {
      fileWithChunks.onProgress(done, fileWithChunks.fileSize);
    }
  }

  private onFileChunkDownloaded(fileWithChunks: FileWithChunks, range: Range, blob: Blob) {
    this.downloadChunkSub = null;

    this.fileBridgeService
      .writeFile(fileWithChunks.fileEntry, blob, true)
      .then(() => {
        this.onProgress(fileWithChunks, range.end + 1);
        fileWithChunks.ranges.shift();

        if (fileWithChunks.ranges.length === 0) {
          fileWithChunks.resolve(fileWithChunks.fileEntry);
        } else if (fileWithChunks.isCanceled) {
          this.handleFileCancel(fileWithChunks);
        }

        if (!this.isPaused) {
          if (fileWithChunks.ranges.length) {
            this.downloadFileChunks();
          } else {
            this.onFileFinished();
          }
        }
      })
      .catch(reason => this.onError(reason));
  }

  private onFileFinished() {
    this.fileQueue.shift();
    if (this.fileQueue.length) {
      this.downloadFileChunks();
    } else {
      this.isDownloading = false;
    }
  }

  pause() {
    if (this.isDownloading && !this.isPaused) {
      if (this.downloadChunkSub) {
        this.downloadChunkSub.unsubscribe();
        this.downloadChunkSub = null;
      }

      this._isPaused = true;
    }
  }

  start() {
    if (this._isPaused) {
      this._isPaused = false;
      this.downloadFileChunks();
    }
  }

  cancel() {
    if (this.isDownloading) {
      const fileWithChunks: FileWithChunks = this.fileQueue[0];
      fileWithChunks.isCanceled = true;

      if (this.downloadChunkSub || this._isPaused) {
        if (this.downloadChunkSub) {
          this.downloadChunkSub.unsubscribe();
          this.downloadChunkSub = null;
        }

        this.handleFileCancel(fileWithChunks);
        this.onFileFinished();
      }
    }
  }

  private handleFileCancel(fileWithChunks: FileWithChunks) {
    fileWithChunks.ranges.length = 0;
    this.fileBridgeService
      .remove(fileWithChunks.fileEntry.nativeURL)
      .catch(() => {})
      .finally(() => fileWithChunks.reject({ cancelled: true }));
  }
}
