// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmDetector_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.AlarmDetector';
  $t: string = 'SagaSchema.AlarmDetector';

  public static GetMembers(): string[] {
    return [
      'DetectorUId',
      'Id',
      'TransmitterUId',
      'DetectorType',
      'DetectorTypeName',
      'FileUId',
      'Identifier',
      'AlarmStatusValue',
      'MiscInfo',
      'IsDeleted',
      'LocationComplement',
      'ProcedureInfo',
      'FileName',
      'FileLocation',
      'ProviderAlarmId',
      'OutOfServiceInfo',
      'ModifiedUTC',
      'CreatedUTC'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmDetector_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ModifiedUTC = this.ModifiedUTC && new Date(this.ModifiedUTC);
    this.CreatedUTC = this.CreatedUTC && new Date(this.CreatedUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public DetectorUId: Guid = GuidTools.Empty;
  public Id: number = 0;
  public TransmitterUId: Guid = GuidTools.Empty;
  public DetectorType: number = 0;
  public DetectorTypeName: string;
  public FileUId: Guid = GuidTools.Empty;
  public Identifier: string;
  public AlarmStatusValue: number = 0;
  public MiscInfo: string;
  public IsDeleted: boolean = false;
  public LocationComplement: string;
  public ProcedureInfo: string;
  public FileName: string;
  public FileLocation: string;
  public ProviderAlarmId: string;
  public OutOfServiceInfo: string;
  public ModifiedUTC: Date = new Date();
  public CreatedUTC: Date = new Date();

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.DetectorUId;
  }
  get ParentObjId(): any {
    return this.TransmitterUId;
  }
  set ParentObjId(value: any) {
    this.TransmitterUId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
