// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { CStamp_abstract } from './../abstract/SagaSchema/CStamp_abstract';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheChangedInterface } from '@services/cache/cache.service';
export class CStamp extends CStamp_abstract {
  callcard: CallCard;

  isExpanded: boolean = false;

  UserName = '';

  isNew: boolean = false;

  public changed(change: CacheChangedInterface) {
    super.changed(change);

    this.callcard = change.cache.getObjectByUid(this.ParentObjId);
  }

  get followupComponent(): any {
    return null;
  }
}
