import { CardInfoFormatterService } from '@addins/core/core';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { ModelFactoryService } from '@services/model-factory';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { CallCardCVP } from './models/CallCardCVP';
import { CallCardCVPModelFactoryService } from './services/callcard-cvp-model-factory/call-card-cvp-model-factory.service';
import { CardInfoCVPFormatterService } from './services/card-info-cvp-formatter/card-info-cvp-formatter.service';

const views = [];

const components = [...views];

const directives = [];

const executables = [];

const providers = [CallCardCVPModelFactoryService, CardInfoCVPFormatterService, ...executables];

@Addin({
  name: 'CVP'
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule]
})
export class CVPModule extends AddinModule {
  constructor(
    private _modelFactory: ModelFactoryService,
    private _cardInfoFormatter: CardInfoFormatterService,
    private _cardInfoCvpFormatter: CardInfoCVPFormatterService,
    injector: Injector
  ) {
    super(injector, views, executables);
  }

  load() {
    super.load();

    this._modelFactory.registerModelWithFactory(CallCardCVP, CallCardCVPModelFactoryService);

    this._cardInfoFormatter.override(this._cardInfoCvpFormatter);
  }
}
