// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @9/1/2021 5:14:52 PM
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListPersonQualifiers_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.ListObjects.ListPersonQualifiers';
  $t: string = 'SagaSchema.ListObjects.ListPersonQualifiers';

  public static GetMembers(): string[] {
    return ['PersonQualifierId', 'PersonQualifier', 'Description', 'Deleted', 'Name', 'Value'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ListPersonQualifiers_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public PersonQualifierId: number = 0;
  public PersonQualifier: string;
  public Description: string;
  public Deleted: boolean = false;
  public Name: string;
  public Value: any;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
