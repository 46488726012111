<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-title><span translate>SagaMobile.PickerCoordinatePicker</span></ion-title>
        <ion-buttons slot="end">
            <ion-button slot="icon-only" (click)="cancel()"><ion-icon name="close"></ion-icon></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="coordinate-picker-content" padding>
    <ion-list>
        <ion-item *ngFor="let object of objects" (click)="selectObject(object)">
            <i slot="start" [class]="object.className"></i>
            {{object.rShort()}}
        </ion-item>
    </ion-list>
</ion-content>
