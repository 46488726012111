import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CardsProviderService {
  private readonly _cards: Type<any>[] = [];
  get cards(): Type<any>[] {
    return this._cards;
  }

  constructor() {}

  add(component: Type<any>): void {
    this._cards.push(component);
  }

  remove(component: Type<any>): void {
    const componentIndex = this._cards.indexOf(component);
    if (componentIndex > -1) {
      this._cards.splice(componentIndex, 1);
    }
  }
}
