import { SettingOpenerService } from '@addins/core/settings';
import { UnitSelectorService } from '@addins/core/unit-activity';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionSheetController, AlertController, ModalController, Platform } from '@ionic/angular';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { TranslateService } from '@ngx-translate/core';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { Initializer } from '@services/initializer/initializer.service';
import { ListNameService } from '@services/list-name/list-name.service';
import { ModalService } from '@services/modal/modal.service';
import { SagaToastController } from '@services/overrides/SagaToast';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { Security } from '@services/security/security.service';
import { SagaSettingsService } from '@services/settings';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { AddinService, AddinView, DeviceService, ISetupDeviceResponse, SetupDeviceCode } from '@techwan/ionic-core';

/**
 * @description
 */
@Component({
  templateUrl: 'device.component.html',
  styleUrls: ['device.component.scss']
})
@AddinView({
  name: 'Device'
})
export class DeviceComponent {
  @ViewChild('deviceInput') input;

  loginForm: FormGroup;
  error?: string;
  private _isEmei: boolean = false;

  get isEmei() {
    return this._isEmei;
  }

  set isEmei(isEmei: boolean) {
    this._isEmei = isEmei;
    if (this.loginForm) {
      const control = this.loginForm.get('device');
      isEmei ? control.disable() : control.enable();
    }
  }

  constructor(
    private platform: Platform,
    public association: AssociationSecurity,
    private security: Security,
    private modalService: ModalService,
    private readonly formBuilder: FormBuilder,
    private initializer: Initializer,
    private appSettings: SagaSettingsService,
    private toastCtrl: SagaToastController,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private unitActivityService: UnitActivityService,
    private addins: AddinService,
    private readonly modalCtrl: ModalController,
    private listNameService: ListNameService,
    private actionSheetController: ActionSheetController,
    private device: DeviceService,
    private readonly unitSelector: UnitSelectorService,
    private readonly claims: RefreshTokenStorageService,
    public readonly settings: SettingOpenerService
  ) {
    this.loginForm = formBuilder.group({
      device: ['', Validators.required]
    });
  }

  private async initialize() {
    this.isEmei = false;
    if (this.platform.is('cordova')) {
      try {
        this.isEmei = this.appSettings.getValue('SagaMobileWebClient.UseCodeEMEI');
        if (this.isEmei) {
          await this.security.permission(this.security.PERSMISSION.READ_PHONE_STATE);
          await this.security.permission(this.security.PERSMISSION.GET_ACCOUNTS);
        } else {
          this.isEmei = false;
          this.modalService.dismissLoading();
        }
      } catch (e) {
        this.toastCtrl
          .create({
            message: 'static.login.device.getemeierror',
            duration: 3000
          })
          .then(t => t.present());
        console.error(e);
        this.modalService.dismissLoading();
      }
    }
  }

  private setImei(imei: string) {
    this.input.value = imei;
    this.associate(true);
  }

  associate(isImei = false) {
    this.modalService.presentLoading('static.login.device.associating');
    this.device.setup(this.loginForm.value.device).subscribe(r => this.handleResponse(r));
  }

  async ionViewWillEnter() {
    this.initialize();
  }

  async checkActivity() {
    const response = await this.association.retreiveUnitActivity();
    if (response.success && response.activity) {
      this.modalCtrl.dismiss(response.activity);
    }
  }

  private handleResponse(device?: ISetupDeviceResponse) {
    this.modalService.dismissLoading();
    if (device && device.error === SetupDeviceCode.NotLinked) {
      this.unitSelector.setup(this.loginForm.value.device).subscribe(u => this.parse(u));
      this.association.setDevice(this.loginForm.value.device);
    } else if (device === null || device.error !== SetupDeviceCode.Success) {
      this.error = this.parseError(device);
    } else if (device && device.error === SetupDeviceCode.Success) {
      this.association.setDevice(this.loginForm.value.device);
      this.modalCtrl.dismiss();
    } else {
      this.modalCtrl.dismiss();
    }
    return;
  }

  private parse(unit: UnitActivity) {
    if (!unit) {
      this.alertCtrl
        .create({
          message: this.translate.instant('SagaMobile.UA.Associating.Error'),
          buttons: [
            {
              text: this.translate.instant('SagaMobile.UA.Associating.Error.OK'),
              handler: () => {}
            }
          ]
        })
        .then(a => a.present());
    } else {
      this.associate();
    }
  }

  private parseError(error?: ISetupDeviceResponse): string {
    const prefix = 'SetupDeviceError.';
    if (error && error.error === SetupDeviceCode.Unauthorized) {
      this.claims.clear();
    }
    return error === null ? `${prefix}NoDevice` : `${prefix}${SetupDeviceCode[error.error]}`;
  }
}
