import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

function fetchWorkaround(reg: RegExp) {
  const originalFetch = (window as any).fetch;

  (window as any).fetch = (...args) => {
    const [url] = args;

    if (typeof url === 'string' && url.match(reg)) {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.setRequestHeader('Access-Control-Allow-Origin', '*');
        req.addEventListener('load', () => {
          resolve({ status: 0, ok: true, text: () => req.responseText });
        });
        req.addEventListener('error', reject);
        req.send();
      });
    } else {
      return originalFetch(...args);
    }
  };
}

if (environment.fetchToGet) {
  fetchWorkaround(environment.fetchToGet);
}

document.title = environment.config.name;

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
