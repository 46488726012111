// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../../SagaBase/SagaObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TimerBase_abstract extends SagaObject {
  static $t: string = 'SagaBase.Objects.TimerBase';
  $t: string = 'SagaBase.Objects.TimerBase';

  public static GetMembers(): string[] {
    return ['ApplicationName', 'ObjectUid', 'Message', 'Type', 'Description', 'Attributes'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return TimerBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ApplicationName: string;
  public ObjectUid: Guid = GuidTools.Empty;
  public Message: string;
  public Type: SagaBase.Defines.TimerItemType;
  public Description: string;
  public Attributes: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
