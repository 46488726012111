import { CurrentCardService } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { CAnimal } from '@models/imported/SagaSchema/CAnimal';
import { CacheService } from '@services/cache/cache.service';
import { AddinView } from '@techwan/ionic-core';
import { CardParticularsWrapper } from '../../schema/card-particulars-wrapper';
import { CallcardDetailParticularsComponent } from '../callcard-detail-particulars/callcard-detail-particulars.component';

@Component({
  selector: 'app-callcard-detail-canimal',
  templateUrl: './callcard-detail-canimal.component.html',
  styleUrls: ['./callcard-detail-canimal.component.scss']
})
@AddinView({
  name: 'CallcardDetailCAnimal'
})
export class CallcardDetailCAnimalComponent extends CallcardDetailParticularsComponent<CAnimal> implements OnInit {
  readonly cAnimalWrappers: CardParticularsWrapper<CAnimal>[] = [];

  constructor(_currentCardService: CurrentCardService, _cacheService: CacheService) {
    super(_currentCardService, _cacheService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.callcard) {
      this.createWrappersOf(CAnimal).forEach(wrapper => this.cAnimalWrappers.push(wrapper));
      this._cacheSubscriptions.push(this.getFilteredCacheSubscriptionForClassName(CAnimal.$t));
    }
  }

  protected onAdd(particular: CAnimal) {
    this.cAnimalWrappers.push(new CardParticularsWrapper<CAnimal>(particular));
  }

  protected onRemove(particular: CAnimal) {
    const existingWrapper = this.cAnimalWrappers.find(wrapper => wrapper.wrappedParticular === particular) || null;
    if (existingWrapper !== null) {
      this.cAnimalWrappers.splice(this.cAnimalWrappers.indexOf(existingWrapper), 1);
    }
  }
}
