import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { IEventFormatter } from '../../../schema/interfaces/event-formatter';

export class DefaultEventFormatter implements IEventFormatter {
  format(event: CEvent, short: boolean): string {
    if (short) {
      return event.EventText;
    } else {
      const firstLine = [event.EventText, event.FieldGeneric1].filter(string => string).join(' - ');
      return [firstLine, event.Complement].filter(string => string).join('\r\n');
    }
  }
}
