import { CommonAddinModule } from '@addins/common.module';
import { UnitActivityAddinName } from '@addins/core/core';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule, UNIT_POLLING_MODAL } from '@techwan/ionic-core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { DeviceSelectorComponent } from './components/deviceSelector/deviceSelector';
import { UnitActivityCollapsibleViewComponent } from './components/unit-activity-collapsible-view/unit-activity-collapsible-view.component';
import { UnitActivityEditorComponent } from './components/unit-activity-editor/unit-activity-editor.component';
import { UnitActivityListComponent } from './components/unit-activity-list/unit-activity-list.component';
import { UnitActivityPopupComponent } from './components/unit-activity-popup/unit-activity-popup.component';
import { UnitActivitySelectorComponent } from './components/unit-activity-selector/unit-activity-selector.component';
import { UnitResourcesComponent } from './components/unit-resources/unit-resources.component';
import { VehicleSelectorComponent } from './components/vehicleSelector/vehicleSelector';
import { VehicleSelectorSettingComponent } from './components/vehicleSelectorSetting/vehicleSelectorSetting';
import { LocalizeUnitDirective } from './directives/localize-unit/localize-unit.directive';
import { CallcardUnitsProviderService } from './services/callcard-units-provider/callcard-units-provider.service';
import { LayerControllerService } from './services/executables/layer-controller/layer-controller.service';
import { UnitActivityAddinService } from './services/executables/unit-activity-addin.service';
import { UnitCollapsibleService } from './services/executables/unit-collapsible/unit-collapsible.service';
import { UnitPopupService } from './services/executables/unit-popup/unit-popup.service';
import { UnitListFetchingService } from './services/unit-list-fetching/unit-list-fetching.service';
import { UnitPollingModalService } from './services/unit-polling-configuration/unit-polling-configuration.service';
import { UnitResourceProviderService } from './services/unit-resource-provider/unit-resource-provider.service';
import { UnitSelectorService } from './services/unit-selector/unit-selector.service';
import { UnitsLayerService } from './services/units-layer/units-layer.service';
import { UnitActivityListViewComponent } from './views/unit-activity-list-view/unit-activity-list-view.component';
import { UnitActivityViewComponent } from './views/unit-activity-view/unit-activity-view.component';

const views = [UnitActivityViewComponent, UnitActivityListViewComponent];

const components = [
  DeviceSelectorComponent,
  VehicleSelectorComponent,
  UnitActivityEditorComponent,
  VehicleSelectorSettingComponent,
  UnitActivityPopupComponent,
  UnitActivitySelectorComponent,
  UnitActivityListComponent,
  UnitActivityCollapsibleViewComponent,
  UnitResourcesComponent,
  ...views
];

const directives = [LocalizeUnitDirective];

const declarations = [...components, ...directives];

const executes = [UnitActivityAddinService, UnitCollapsibleService, LayerControllerService, UnitPopupService];

const providers = [
  CallcardUnitsProviderService,
  UnitSelectorService,
  UnitListFetchingService,
  UnitsLayerService,
  UnitResourceProviderService,
  UnitPollingModalService,
  ...executes,
  { provide: UNIT_POLLING_MODAL, useExisting: UnitPollingModalService }
];

@NgModule({
  declarations,
  imports: [BrowserModule, SharedModule, IonicModule, CommonAddinModule],
  exports: declarations,
  providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@Addin({
  name: UnitActivityAddinName,
  autoload: true
})
export class UnitActivityModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executes);
  }
}
