import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

/**
 * @name VehicleSelectorSetting
 * @description
 *
 */
@Component({
  templateUrl: 'vehicleSelectorSetting.html'
})
export class VehicleSelectorSettingComponent {
  options: { extended: boolean };

  constructor(navParams: NavParams) {
    this.options = navParams.data.options;
  }
}
