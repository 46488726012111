import { Initializer } from '@services/initializer/initializer.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export abstract class CompletionHandler {
  private _onComplete: Subscription = null;

  constructor(protected initializer: Initializer) {
    if (!initializer.isCompleted) {
      this._onComplete = initializer.$onComplete.pipe(filter(isCompleted => isCompleted === true)).subscribe(() => {
        this._onComplete.unsubscribe();
        this._onComplete = null;
        this.$onComplete();
      });
    } else {
      this.$onComplete();
    }
  }

  protected abstract $onComplete();
}
