import { CommonAddinModule } from '@addins/common.module';
import { UnitSelectorService } from '@addins/core/unit-activity';
import { PnToolProviderService } from '@addins/pn/services/pn-tool-provider/pn-tool-provider.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IdToTypeConverter } from '@models/imported/createInstanceById';
import { ListVehicleType } from '@models/imported/SagaSchema/ListObjects/ListVehicleType';
import { FetchListProxyService } from '@services/fetchlist/fetchlist-proxy.service';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { ListNameSelectorComponent } from './components/list-name-selector/list-name-selector.component';
import { UnitActivityEditorComponent } from './components/unit-activity-editor/unit-activity-editor.component';
import { UnitTemplateSelectorComponent } from './components/unit-template-selector/unit-template-selector.component';
import { ListVehicleTypePn } from './list-vehicle-type-pn';
import { UnitActivitySelectorService } from './services/unit-activity-selector/unit-activity-selector.service';

const declarations = [UnitActivityEditorComponent, ListNameSelectorComponent, UnitTemplateSelectorComponent];
const providers = [UnitActivitySelectorService, FetchListProxyService, PnToolProviderService];

@NgModule({
  declarations,
  imports: [CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule],
  exports: [CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule],
  providers
})
@Addin({
  name: 'PnAddin'
})
export class PnModule extends AddinModule {
  constructor(injector: Injector, private readonly idToTypeConverter: IdToTypeConverter) {
    super(injector, declarations, providers);
  }

  load() {
    super.load();

    this.idToTypeConverter.setConstructorOf(ListVehicleType.$t, ListVehicleTypePn);
    this.injector.get(UnitSelectorService).useCustom(this.injector.get(UnitActivitySelectorService));

    window.addEventListener('beforeunload', e => {
      const confirmationMessage = 'Prevent app exit';

      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    });
  }
}
