import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { CacheService } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResourceCountService } from '../../services/resource-count/resource-count.service';

@Component({
  selector: 'app-resource-count-list',
  templateUrl: './resource-count-list.component.html',
  styleUrls: ['./resource-count-list.component.scss']
})
export class ResourceCountListComponent implements OnInit, OnDestroy {
  @Input()
  callcard: CallCard;
  @Input()
  isMyCallcard: boolean;

  resourceCount: number;

  private cacheSub: Subscription = null;

  constructor(private resourceCountService: ResourceCountService, private cacheService: CacheService) {}

  ngOnInit() {
    this.refreshResourceCount();

    this.cacheSub = this.cacheService
      .listenForChange(CStampProcedure)
      .pipe(filter(cacheEvent => (cacheEvent.object as CStampProcedure).CallCardId === this.callcard.ObjGuid))
      .subscribe(() => this.refreshResourceCount());
  }

  private refreshResourceCount() {
    this.resourceCount = this.resourceCountService.getResourceCount(this.callcard);
  }

  ngOnDestroy() {
    this.cacheSub.unsubscribe();
  }
}
