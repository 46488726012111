import { Component, Input, OnInit } from '@angular/core';
import { LoginSetupService } from '@techwan/ionic-core';
import { RbacProfile } from '@techwan/ionic-core/lib/schema/imported/SagaBase/RBAC/RbacProfile';
import { IRbacRole } from '@techwan/security';
import { NameOrderService } from '../../services/name-order/name-order.service';

@Component({
  selector: 'app-login-config-selector',
  templateUrl: './login-config-selector.component.html',
  styleUrls: ['./login-config-selector.component.scss'],
  providers: [NameOrderService]
})
export class LoginConfigSelectorComponent implements OnInit {
  @Input()
  values: IRbacRole[] | RbacProfile[];

  constructor(private readonly order: NameOrderService, private readonly setup: LoginSetupService) {}

  ngOnInit() {
    this.values = this.order.order(this.values, 'Name');
  }

  select(item: IRbacRole | RbacProfile) {
    this.setup.selectItem(item);
  }
}
