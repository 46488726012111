// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AppSettingsSetting_abstract extends PersistentObject {
  static $t: string = 'SagaBase.AppSettingsSetting';
  $t: string = 'SagaBase.AppSettingsSetting';

  public static GetMembers(): string[] {
    return [
      'SchemaId',
      'SettingName',
      'ApplicationName',
      'ValueType',
      'ValidValuesList',
      'Example',
      'HelpText',
      'OverridesConfigFile',
      'ModifiableByClient',
      'ScopeType',
      'SettingId',
      'ScopeId',
      'Value'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AppSettingsSetting_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SchemaId: number = 0;
  public SettingName: string;
  public ApplicationName: string;
  public ValueType: string = 'Undefined';
  public ValidValuesList: string;
  public Example: string;
  public HelpText: string;
  public OverridesConfigFile: boolean = true;
  public ModifiableByClient: boolean = false;
  public ScopeType: number = 0;
  public SettingId: number = 0;
  public ScopeId: number = 0;
  public Value: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.SettingId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
