<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>callcard.eventselect</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon name="close-outline" size="large"></ion-icon>
      </ion-button>
      <ion-button (click)="save()" [disabled]="!canSave()">
        <ion-icon name="checkmark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div layout="column" style="height: 100%">
    <ion-list flex style="overflow: auto;">
      <ion-radio-group [value]="currentElement" (ionChange)="radioGroupChange($event)">
        <ion-item *ngFor="let element of elements" class="event">
          <ion-label>{{element.text}}</ion-label>
          <ion-radio [value]="element.id"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <ion-searchbar
      #searchBar
      placeholder="{{'static.search' | translate}}"
      (ionInput)="filterElements($event.target.value)"
      showCancelButton="true"
    ></ion-searchbar>
  </div>
</ion-content>
