import Feature from 'ol/feature';
import { DrawInteraction } from '@techwan/mapping';
import { Observable, Subject } from 'rxjs';

export class AddInteraction extends DrawInteraction {
  private readonly _event = new Subject<Feature>();
  get $event(): Observable<Feature> {
    return this._event.asObservable();
  }

  constructor(options) {
    super(options);
    this.on('drawend', this.onDrawEnd);
  }

  destroy() {
    this.un('drawend', this.onDrawEnd);
  }

  private onDrawEnd(event: ol.interaction.Draw.Event) {
    setTimeout(() => this._event.next(event.feature));
  }
}
