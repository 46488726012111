import { Component, Input, OnInit } from '@angular/core';
import { LoginTypes } from '@techwan/security';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent implements OnInit {
  @Input()
  loginType: LoginTypes;

  readonly sagaUsernamePassword = LoginTypes.SagaUsernamePassword;
  readonly trustedUser = LoginTypes.TrustedUser;
  readonly trustedUserAndRole = LoginTypes.TrustedUserAndRole;

  ngOnInit() {}
}
