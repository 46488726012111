<div class="status-line" *ngIf="myUnit">
  <ng-container *ngFor="let status of statuses; let last = last">
    <app-status-item
      [myUnit]="myUnit"
      [status]="status"
      (onClick)="change(status)"
      [horizontal]="true"
      class="horizontal"
      [maxWidthOnCount]="statuses.length"
    ></app-status-item>
    <div class="status-separator" *ngIf="!last"></div>
  </ng-container>
</div>
<div class="list-separator"></div>
