// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmStamp_abstract extends SagaObject {
  static $t: string = 'SagaSchema.AlarmStamp';
  $t: string = 'SagaSchema.AlarmStamp';

  public static GetMembers(): string[] {
    return [
      'RequestSource',
      'FileLocation',
      'StampType',
      'AlarmTestUid',
      'TransmitterUId',
      'DetectorId',
      'ErrorMessage',
      'Identifier',
      'MiscInfo',
      'DetectorTypeName',
      'Status',
      'IncomingAlarmEvent',
      'RequestSourceXML'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmStamp_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public RequestSource: string;
  public FileLocation: string;
  public StampType: SagaBase.AlarmStampType = SagaBase.AlarmStampType.TransmitterStateStamp;
  public AlarmTestUid: Guid = GuidTools.Empty;
  public TransmitterUId: Guid = GuidTools.Empty;
  public DetectorId: number;
  public ErrorMessage: string;
  public Identifier: string;
  public MiscInfo: string;
  public DetectorTypeName: string;
  public Status: SagaBase.AlarmStatus = SagaBase.AlarmStatus.Hidden;
  public IncomingAlarmEvent: SagaBase.AlarmEvent = SagaBase.AlarmEvent.Alarm;
  public RequestSourceXML: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
