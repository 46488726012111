/**
 * @name TraceFile
 * @description
 * A trace file description
 */
export class TraceFile {
  public path: string;
  public filename: string;
  public line: number;

  constructor(path: string, line: number) {
    this.path = path;
    let matches = path.match(/\/([^\/]*)$/);
    if (matches && matches.length > 0) {
      this.filename = matches[matches.length - 1];
    } else {
      this.filename = path;
    }

    this.line = line || 0;
  }
}
