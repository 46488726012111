<ion-item-group>
  <ion-item-divider>
    <ion-label translate>Mobile.Connectivity</ion-label>
  </ion-item-divider>

  <ion-item (click)="onReload()" lines="none">
    <ion-label translate>SagaMobile.Reload</ion-label>
    <ion-icon slot="end" name="sync-outline" color="primary"></ion-icon>
  </ion-item>
  <ion-item (click)="logout()" lines="none">
    <ion-label translate>Mobile.Logout</ion-label>
    <ion-icon slot="end" name="log-out-outline" color="primary"></ion-icon>
  </ion-item>
</ion-item-group>
