import { Component, Input, OnInit } from '@angular/core';
import { IIconSize, SelectBoxOption, SelectBoxTool } from '@techwan/map-tools';
import { AttributeValueProviderService } from '../../services/attribute-value-provider/attribute-value-provider.service';

@Component({
  selector: 'app-select-box-tool',
  templateUrl: './select-box-tool.component.html',
  styleUrls: ['./select-box-tool.component.scss']
})
export class SelectBoxToolComponent implements OnInit {
  @Input()
  public readonly item: SelectBoxTool;

  constructor(private readonly _provider: AttributeValueProviderService) {}

  public ngOnInit() {
    if (this.item) {
      this.item.$event.subscribe(() => this.onValueChanged());
      if (this.item.options.length === 0) {
        this.fillOptions(this._provider.getSizes())
      };
    }
  }

  private onValueChanged(): void {
    const size = parseInt(this.item.option.value, 10);
    this._provider.size = [size, size];
  }

  private fillOptions(sizes: IIconSize[]): void {
    const selection = sizes.find(s => s.selected);
    sizes.forEach((v: IIconSize) => this.item.options.push(new SelectBoxOption(`${v.value}`, v.name)));
    this.item.selection = selection ? this.item.options.find(o => parseInt(o.value, 10) === selection.value) : this.item.options[0];
  }
}
