<div class="message-item" [class.anchor-left]="isMine" [class.anchor-right]="!isMine" [class.global]="isGlobal" [class.user]="isForMyUnit">
  <app-media-message-deployment *ngIf="isDeployment" [mediaMessage]="mediaMessage"></app-media-message-deployment>
  <app-media-message-attachment *ngIf="hasAttachment" [mediaMessage]="mediaMessage"></app-media-message-attachment>

  <p class="upload-error"></p>
  <p class="second-line">
    <span *ngIf="mediaMessage.UserName" style="display: block">{{ mediaMessage.UserName }} :</span>
    <span *ngIf="!isGlobal" style="display: block">{{ eventText }}</span>
    <span *ngIf="mediaMessage.Caption" style="display: inline">{{ mediaMessage.Caption | translateKeys }}</span>
    <span *ngIf="mediaMessage.Text" style="display: block" class="message-text">{{ mediaMessage.Text | translateKeys }}</span>
  </p>

  <span class="message-date">{{ mediaMessage.CreatedUTC | sagaDate }}</span>
</div>
<hr class="clear" />
