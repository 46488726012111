import { Injectable } from '@angular/core';
import { CameraSource, ImageOptions } from '@capacitor/camera';
import { Device } from '@ionic-native/device/ngx';
import { VideoCapturePlusOptions } from '@ionic-native/video-capture-plus/ngx';
import { Platform } from '@ionic/angular';
import { SagaSettingsService } from '@services/settings';
import { AttachmentOption } from '../../schema/attachment-option';
import { MediaType } from '../../schema/media/media-type';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class AttachmentOptionFactoryService {
  constructor(
    private _sagaSettingsService: SagaSettingsService,
    private _settingsService: SettingsService,
    private _device: Device,
    private _platform: Platform
  ) {}

  create(mediaType: MediaType): AttachmentOption {
    const attachmentOption = new AttachmentOption(mediaType);
    if (this._platform.is('cordova')) {
      attachmentOption.setIsMobile();
    }
    this.setCaptureOptions(attachmentOption);
    return attachmentOption;
  }

  setCaptureOptions(attachmentOption: AttachmentOption): void {
    const captureOptions = this.getDefaultCaptureOptions(attachmentOption.type);
    Object.assign(attachmentOption.captureOptions, captureOptions);

    const customSettings = this.getCustomSettings(attachmentOption.type);
    if (customSettings != null) {
      Object.assign(attachmentOption.captureOptions, JSON.parse(customSettings));
    }
  }

  private getCustomSettings(mediaType: MediaType): any {
    const sagaSettingName = mediaType === MediaType.Picture ? 'PictureOptions' : mediaType === MediaType.Video ? 'VideoOptions' : null;
    const customSettings = (sagaSettingName !== null && this._sagaSettingsService.getValue(sagaSettingName)) || null;
    return customSettings;
  }

  private getDefaultCaptureOptions(mediaType: MediaType): VideoCapturePlusOptions | ImageOptions {
    switch (mediaType) {
      case MediaType.File:
      case MediaType.Picture:
        const cameraOptions = this._settingsService.createCameraOptions(this.getCameraSource(mediaType));
        if (this._device.platform === 'Android') {
          cameraOptions.width = 1024;
          cameraOptions.quality = 50;
        }
        return cameraOptions;

      case MediaType.Video:
        return this._settingsService.createVideoOptions(60, true, false);
    }
  }

  private getCameraSource(mediaType: MediaType): CameraSource {
    return mediaType === MediaType.File ? CameraSource.Photos : CameraSource.Camera;
  }
}
