import { Injectable } from '@angular/core';
import { IModelService, IObjectChange } from '@services/model-factory';
import { CallCardVd144 } from '../../../models/CallCardVd144';
import { CardInfoFormatterService } from '@addins/core/core';

@Injectable()
export class CallcardVd144ModelService implements IModelService<CallCardVd144> {
  constructor(private readonly _info: CardInfoFormatterService) {}

  public change(change: IObjectChange<CallCardVd144>) {
    this.apply(change.target);
  }

  public apply(object: CallCardVd144) {
    object.longText = this._info.format(object);
    object.shortText = this._info.format(object, true)[0];
  }
}
