import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CCaller } from '@models/imported/SagaSchema/CCaller';
import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { Security } from '@services/security/security.service';
import { ExternalSearchResult } from '@models/ExternalSearchResult';

export let ExternalSearchInterface = {
  Far: 'Far'
};
interface IExternalSearchResult {
  Result: any;
  ErrorMessage: string;
}

@Injectable()
export class ExternalService {
  constructor(private security: Security) {}

  private isAuthority(text: string): boolean {
    return text.search(/inscription(.+)autorit/i) >= 0;
  }

  async search(name: string, callcard: CallCard) {
    if (!callcard.externalSearchs[name]) {
      callcard.externalSearchs[name] = new ExternalSearchResult();
    }
    callcard.externalSearchs[name].reset();
    callcard.externalSearchs[name].isSearching = true;
    const requestParams = {
      interfaceName: name,
      cardGuid: callcard.ObjGuid,
      callers: callcard.component(CCaller),
      locations: callcard.component(CLocation),
      events: callcard.component(CEvent),
      userId: this.security.loggedUser.UserID
    };

    const result = await this.security.post<IExternalSearchResult>({
      url: 'api/ExternalService/SearchWithFilter',
      data: requestParams
    });

    callcard.externalSearchs[name].isSearching = false;
    // Parse results
    if (result.Result !== null) {
      callcard.externalSearchs[name].results = result.Result;
      callcard.externalSearchs[name].errorMessage = result.ErrorMessage;
      callcard.externalSearchs[name].results.forEach(item => {
        const inputs = item.Name.split('-');
        const results = {};

        item.isAuthority = false;
        inputs.forEach(inputStr => {
          const components = inputStr.split(':');
          results[components[0].trim()] = components[1].trim();

          item.isAuthority = item.isAuthority || this.isAuthority(inputStr);
        });

        item.entries = results;
        item.entriesName = Object.keys(results);
      });
    }
  }
}
