import { Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';

@Component({
  selector: 'app-callcard-detail-cperson-regular',
  templateUrl: './callcard-detail-cperson-regular.component.html',
  styleUrls: ['./callcard-detail-cperson-regular.component.scss']
})
@AddinView({
  name: 'CallcardDetailCPersonRegular'
})
export class CallcardDetailCPersonRegularComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
