import { Component, OnInit } from '@angular/core';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { SagaSettingsService } from '@services/settings';
import { Observable } from 'rxjs';
import { ListItemComponent } from '../../components/list-item/list-item.component';
import { ResourcesProviderService } from '../../services/resources-provider/resources-provider.service';
import { UnitEditorService } from '../../services/unit-editor/unit-editor.service';
import { ListBaseComponent } from '../list-base-component/list-base-component';

@Component({
  selector: 'app-internal-resource-list',
  templateUrl: '../../abstract-template/tab-list.template.html',
  styleUrls: ['./internal-resource-list.component.scss']
})
export class InternalResourceListComponent extends ListBaseComponent<Actor> implements OnInit {
  public readonly item = ListItemComponent;

  constructor(
    private resourcesProvider: ResourcesProviderService,
    protected unitEditor: UnitEditorService,
    protected sagaSettings: SagaSettingsService
  ) {
    super(unitEditor, sagaSettings);
    this.tabType = 'Actor';
  }

  public getItems(offset: number, txt: string): Observable<Actor[]> {
    return this.resourcesProvider.getActors(offset, (this.searchExpression = txt));
  }

  public ngOnInit() {
    this._list = this.unitEditor.editor.actors;
    super.ngOnInit();
  }
}
