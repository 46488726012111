import { Component } from '@angular/core';
import { MapService, MoreItem } from '@services/map';
import { PopoverController } from '@ionic/angular';

@Component({
  templateUrl: 'map-more-popover.component.html',
  styleUrls: ['map-more-popover.component.scss']
})
export class MapMorePopoverComponent {
  get moreItems(): MoreItem[] {
    return this.mapService.moreItems;
  }

  constructor(private mapService: MapService, private viewCtrl: PopoverController) {}

  clickItem(item: MoreItem) {
    if (!item.keepOnClick) {
      this.viewCtrl.dismiss();
    }
    item.handler.call(item.context);
  }
}
