import { ItineraryStepGeometry, Map, transformExtent, transformGeometry } from '@techwan/mapping';

export class ItineraryStep {
  constructor(data: any, epsg: number) {
    this.length = Math.round(data.attributes.length);
    this.time = 60 * data.attributes.time;
    this.text = data.attributes.text;
    this.type = data.attributes.maneuverType;
    this.epsg = epsg;

    this.compressedGeometry = data.compressedGeometry;
    this.geometry = new ItineraryStepGeometry(ItineraryStep.uncompressGeometry(this.compressedGeometry));
  }
  length: number;
  time: number;
  text: string;
  compressedGeometry: string;
  type: string;
  geometry: any;
  epsg: number;

  static uncompressGeometry(compressed: string) {
    const points = [];
    let xDiffPrev = 0,
      yDiffPrev = 0,
      x,
      y,
      strings,
      coefficient;

    // Split the string into an array on the + and - characters
    strings = compressed.match(/((\+|\-)[^\+\-]+)/g);

    // The first value is the coefficient in base 32
    coefficient = parseInt(strings[0], 32);

    for (let j = 1; j < strings.length; j += 2) {
      // j is the offset for the x value
      // Convert the value from base 32 and add the previous x value
      x = parseInt(strings[j], 32) + xDiffPrev;
      xDiffPrev = x;

      // j+1 is the offset for the y value
      // Convert the value from base 32 and add the previous y value
      y = parseInt(strings[j + 1], 32) + yDiffPrev;
      yDiffPrev = y;

      points.push([x / coefficient, y / coefficient]);
    }

    return points;
  }

  transform(map: Map): ItineraryStepGeometry {
    return transformGeometry(this.geometry.clone(), this.epsg, map.getView().getProjection());
  }

  fit(map: Map) {
    const extent = transformExtent(this.geometry.getExtent(), this.epsg, map.getView().getProjection());
    map.getView().fit(extent, { duration: 600 });
  }
}
