import { CallCardSlideDetailViewComponent } from '@addins/core/singlecallcard';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ToastButton } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { NavService } from '@services/navigation/navigation.service';
import { SagaToastController } from '@services/overrides/SagaToast';
import { Security } from '@services/security/security.service';
import { ExternalSearchInterface, ExternalService } from '../../../services/external/external.service';

const externalServiceFactory = (security: Security) => new ExternalService(security);
const externalSearchProvider = { provide: ExternalService, useFactory: externalServiceFactory, deps: [Security] };

/**
 * @name CallCardSipComponent
 * @description
 *
 */
@Component({
  templateUrl: 'callCardSip.html',
  selector: 'callcard-sip',
  providers: [externalSearchProvider]
})
export class CallCardSipComponent implements OnInit, OnDestroy {
  @Input() callcard: CallCard;

  results: any[];

  private isShowing: boolean = false;

  private currentToast: HTMLIonToastElement;

  constructor(
    private external: ExternalService,
    private inAppBrowser: InAppBrowser,
    private backgroundMode: BackgroundMode,
    private toastCtrl: SagaToastController,
    private nav: NavService,
    private device: Device
  ) {}

  openResult(result) {
    if (this.device.platform !== 'browser') {
      this.inAppBrowser
        .create(result.Value, '_self', {
          location: 'no'
        })
        .show();
    } else {
      this.inAppBrowser
        .create(result.Value, '_blank', {
          location: 'yes'
        })
        .show();
    }
  }

  async requestExternalFar() {
    await this.external.search(ExternalSearchInterface.Far, this.callcard);

    if (window.hasOwnProperty('cordova') && this.backgroundMode.isActive()) {
      //Show notification
    } else {
      this.nav
        .get()
        .getActive()
        .then(async a => {
          const currentViewInstance = a.component as CallCardSlideDetailViewComponent;
          if (!(currentViewInstance instanceof CallCardSlideDetailViewComponent) || currentViewInstance.page != 2) {
            let duration = 5000;
            if (this.currentToast) {
              await this.currentToast.dismiss(true);
            }
            this.toastCtrl
              .create({
                message: 'SagaMobile.Sip.Received',
                buttons: [
                  {
                    text: 'Show',
                    role: 'cancel'
                  } as ToastButton
                ]
              })
              .then(t => {
                this.currentToast = t;
                let timeoutHandle = setTimeout(() => {
                  this.currentToast.dismiss(true);
                }, duration);
                this.currentToast.onDidDismiss().then(closedByTimeout => {
                  if (closedByTimeout) return;
                  clearTimeout(timeoutHandle);

                  if (currentViewInstance instanceof CallCardSlideDetailViewComponent) {
                    currentViewInstance.load(this.callcard, 2);
                  } else {
                    this.nav.get().push(CallCardSlideDetailViewComponent, { card: this.callcard, page: 2 });
                  }
                  this.currentToast = null;
                });
                this.currentToast.present();
              });
          }
        });
    }

    if (this.isShowing) {
      setTimeout(() => (this.callcard.externalSearchs.Far.read = true));
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.isShowing = true;
      this.callcard.externalSearchs.Far.read = true;
      if (this.callcard.externalSearchs.Far.needSearch()) {
        this.requestExternalFar();
      }
    });
  }

  ngOnDestroy() {
    if (this.callcard.externalSearchs.Far && !this.callcard.externalSearchs.Far.isSearching && this.callcard.externalSearchs.Far.results) {
      setTimeout(() => {
        this.callcard.externalSearchs.Far.reset();
      });
    }
    this.isShowing = false;
  }
}
