import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { MediaMessageStatus } from '@models/imported/SagaBase';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';

@Injectable()
export class MediaMessagesFilterService {
  constructor(private _myUnit: MyUnitService, private _refreshTokenStorage: RefreshTokenStorageService) {}

  isVisibleForMe(mediaMessage: MediaMessage): boolean {
    return this.isMyCards(mediaMessage) || this.isForMyUnit(mediaMessage) || this.isGlobal(mediaMessage);
  }

  private isMyCards(mediaMessage: MediaMessage): boolean {
    const myCardId = this._myUnit.mine && this._myUnit.mine.CallCardID;
    return GuidTools.isValid(myCardId) && mediaMessage.ParentObjId === myCardId;
  }

  isForMyUnit(mediaMessage: MediaMessage): boolean {
    return (
      this._myUnit.mine &&
      mediaMessage.DestinationUserId &&
      mediaMessage.DestinationUserId.split(',').indexOf(this._myUnit.mine.ObjGuid) !== -1
    );
  }

  isGlobal(mediaMessage: MediaMessage): boolean {
    return !(mediaMessage instanceof CMediaMessage) && !mediaMessage.DestinationUserId;
  }

  isMine(mediaMessage: MediaMessage): boolean {
    const login = this._refreshTokenStorage.read().login;
    return mediaMessage.UserID === login.SagaUserID;
  }

  isDeployment(mediaMessage: MediaMessage): boolean {
    return mediaMessage.Status === MediaMessageStatus.Destination || mediaMessage.Status === MediaMessageStatus.DestinationRemove;
  }

  hasAttachment(mediaMessage: MediaMessage): boolean {
    return mediaMessage.Url && true;
  }
}
