import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { CardLoaderComponent } from './components/cardLoader/cardLoader';
import { ComponentLoaderComponent } from './components/component-loader/component-loader.component';
import { LoadingBarComponent } from './components/loadingBar/loadingBar';
import { ShowMoreButtonComponent } from './components/show-more-button/show-more-button.component';
import { ViewSplitterComponent } from './components/view-splitter/view-splitter.component';

const components = [LoadingBarComponent, CardLoaderComponent, ComponentLoaderComponent, ViewSplitterComponent, ShowMoreButtonComponent];

@NgModule({
  declarations: components,
  imports: [IonicModule, CommonModule, SagaPipeModule],
  providers: [],
  exports: components
})
export class VisualModule {}
