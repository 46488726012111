<ng-template #loading>
  <ion-spinner></ion-spinner>
  <ion-label translate="SagaMobile.Cache.Loading"></ion-label>
</ng-template>
<div *ngIf="unit; else loading">
  <div>
    <ion-label>{{ unit.Name }}</ion-label>
  </div>
  <div [style.font-size]="unitStatusFontSize">
    <ion-label>{{ unitStatusName | translate }}</ion-label> <ion-label *ngIf="showEventName"> - {{ eventName }}</ion-label>
  </div>
</div>
