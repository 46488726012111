import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { AttachmentsCardButton } from '../../../schema/attachments-card-button';
import { SINGLECALLCARD_ATTACHMENTS } from '../../../schema/constants';
import { CardButtonControllerService } from '../../card-button-controller/card-button-controller.service';

@AddinExecute({
  name: 'CardButton'
})
@Injectable()
export class CardButtonService extends Execute {
  private _attachmentCardButton: AttachmentsCardButton = null;

  constructor(
    private readonly _buttonProvider: ButtonsProviderService,
    private readonly _profileService: ProfileService,
    private readonly _profileReader: ProfileReaderService,
    private readonly _cardButtonController: CardButtonControllerService
  ) {
    super();
  }

  public execute() {
    if (this._profileService.isReady()) {
      this.onProfileReady(true);
    } else {
      this._profileService.ready.subscribe(r => this.onProfileReady(r));
    }
  }

  private onProfileReady(ready: boolean) {
    const component =
      ready === true && this._attachmentCardButton === null
        ? this._profileReader.getComponent(this._profileService.current, SINGLECALLCARD_ATTACHMENTS)
        : null;

    if (component !== null && this._attachmentCardButton === null) {
      this._attachmentCardButton = new AttachmentsCardButton();
      this._cardButtonController.setup(this._attachmentCardButton);
      this._buttonProvider.add(this._attachmentCardButton);
    } else if (ready === false && this._attachmentCardButton !== null) {
      this._buttonProvider.remove(this._attachmentCardButton);
      this._cardButtonController.cleanup();
      this._attachmentCardButton = null;
    }
  }
}
