import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActionSheetController, IonList } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CallcardService } from '@services/callcard/callcard.service';
import { ToolsProviderService } from '@services/tools';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProfileService } from '@services/profile/profile.service';
import { AddinService } from '@techwan/ionic-core';

/**
 * @description
 *
 */
@Component({
  templateUrl: 'callcard-detail-followup.component.html',
  styleUrls: ['./callcard-detail-followup.component.scss'],
  selector: 'callcard-followup',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallCardDetailFollowupComponent implements OnInit, OnDestroy, AfterViewChecked {
  /**
   * Callcard item to refer to.
   * type: {CallCard}
   */
  @Input() callcard: CallCard;

  @ViewChild(IonList) feedList: IonList;
  @ViewChild('timeLine') timeLine: ElementRef;

  subscriber: Subscription = null;
  destroyed: boolean = false;

  constructor(
    private callcardService: CallcardService,
    private toolsProvider: ToolsProviderService,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private profile: ProfileService,
    private addins: AddinService,
    private injector: Injector
  ) {}

  private _toolIcons: { [id: string]: string } = {};
  getStampToolIcon(stamp): string {
    if (this._toolIcons[stamp.ObjGuid] === undefined) {
      this._toolIcons[stamp.ObjGuid] = null;

      const toolsProvider = this.toolsProvider.getToolsForContext(stamp);
      if (toolsProvider.tools.length > 0) {
        this._toolIcons[stamp.ObjGuid] = toolsProvider.tools.length > 1 ? 'more' : toolsProvider.tools[0].icon;
      }
    }
    return this._toolIcons[stamp.ObjGuid];
  }

  startRefresh() {
    this.doRefresh();
  }

  stampClick(stamp) {
    const toolsProvider = this.toolsProvider.getToolsForContext(stamp);
    if (toolsProvider.tools.length === 1) {
      toolsProvider.tools[0].execute([stamp]);
    } else {
      const buttons = toolsProvider.getActionSheetButtons(true);
      if (buttons.length > 1) {
        this.actionSheetCtrl
          .create({
            header: this.translate.instant(toolsProvider[0].$t),
            buttons
          })
          .then(actionSheet => actionSheet.present());
      }
    }
  }

  expandStamp(stamp) {
    stamp.isExpanded = !stamp.isExpanded;
  }

  ngOnInit() {
    // Find base component
    if (!this.callcard.lastStampsLoading && !this.callcard.isStampLoading) {
      this._toolIcons = {};
      this.doRefresh();
    }

    this.subscriber = this.callcard.refreshStamps.subscribe(() => this.doRefresh());
  }

  ngOnDestroy() {
    if (this.subscriber !== null) {
      this.subscriber.unsubscribe();
      this.subscriber = null;
    }
    this.destroyed = true;
    this.callcard.markRead();
  }

  async doRefresh() {
    if (!this.callcard.isStampLoading) {
      this.callcard.isStampLoading = true;
      this.cd.detectChanges();
      await this.callcardService.loadStamps(this.callcard);
      if (!this.destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.timeLine) {
      this.renderer.setStyle(this.timeLine.nativeElement, 'height', (this.feedList as any).el.lastElementChild.offsetTop + 'px');
    }
  }
}
