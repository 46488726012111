<div class="container-class" #content>
  <ion-tabs *ngIf="!isLoading; else loading">
    <ion-tab-bar slot="bottom">
      <ion-tab-button
        *ngFor="let tab of tabButtons"
        [class.active]="hasActiveTool(tab.toolsName)"
        (click)="onToolGroupClicked($event, tab.toolsName)"
      >
        <ion-icon [name]="tab.iconName"></ion-icon>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
  <ng-template #loading><p translate>Loading...</p></ng-template>
</div>
