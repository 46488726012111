import { MapName } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { MapInstancesService } from '@services/map';
import { Map } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ILayerController } from '../../schema/interfaces/ILayerController';

@Injectable()
export class LayerControllerService {
  private readonly _layers: ILayerController[] = [];
  get layers(): ILayerController[] {
    return this._layers.slice();
  }

  private get mainMap(): Map {
    return this.mapInstances.get(MapName.main);
  }

  private _sub: Subscription = null;

  constructor(private mapInstances: MapInstancesService) {}

  addController(layerController: ILayerController): ILayerController {
    this._layers.push(layerController);

    if (this._sub === null) {
      this._sub = this.mapInstances.$change
        .pipe(filter(mapEvent => mapEvent.name === MapName.main))
        .subscribe(mapEvent => this.onMapChanged(mapEvent.target));
    }

    if (this.mainMap) {
      layerController.mapDidChange(this.mainMap);
    }

    return layerController;
  }

  private onMapChanged(map: Map) {
    this._layers.forEach(layer => layer.mapDidChange(map));
  }

  removeController(layerController: ILayerController): ILayerController {
    const index = this._layers.indexOf(layerController);
    return index !== -1 ? this._layers.splice(index, 1)[0] : null;
  }
}
