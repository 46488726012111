import { IAppSetting } from '@addins/core/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SagaDate, SagaDatePipe } from '@app/pipes/saga-date/saga-date.pipe';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AppSettingProviderService, SagaSettingsService } from '@services/settings';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { Subscription } from 'rxjs';
import { MapDownloadEvent, MapDownloadService, MapDownloadState } from '../../services/map-download/map-download.service';

enum OfflineMapButtonTextKey {
  StartDownload = 'Mobile.MapOfflineDownload',
  Downloading = 'Mobile.MapOfflineDownloading',
  Unzipping = 'Mobile.MapOfflineUnzipping',
  MapAvailable = 'Mobile.MapOfflineAvailable'
}
enum PauseButtonTextKey {
  Pause = 'Mobile.Pause',
  Resume = 'Mobile.Resume'
}

@Component({
  selector: 'app-offline-map-settings',
  templateUrl: './offline-map-settings.component.html',
  styleUrls: ['./offline-map-settings.component.scss']
})
export class OfflineMapSettingsComponent implements OnInit, OnDestroy {
  offlineMapButtonText: string = OfflineMapButtonTextKey.StartDownload;
  offlineMapButtonTextParam: any = {};

  pauseButtonText: string = PauseButtonTextKey.Pause;

  hasProgress: boolean = false;
  progress: number;

  isDownloadStarted: boolean = false;

  isDownloadOverWifiSettingDisabled: boolean = false;

  private get mapDownloadState(): MapDownloadState {
    return this.mapDownloadService.state;
  }

  private _downloadOverWifiOnly: IAppSetting<boolean> = null;
  get downloadOverWifiOnly() {
    return this._downloadOverWifiOnly.current;
  }

  private mapDownloadEventSub: Subscription = null;

  private readonly canDownloadFromState: MapDownloadState[] = [
    MapDownloadState.ready,
    MapDownloadState.downloadFailed,
    MapDownloadState.unzipFailed,
    MapDownloadState.mapAvailable
  ];

  constructor(
    private mapDownloadService: MapDownloadService,
    private network: Network,
    private alert: TranslatedAlertService,
    private sagaDate: SagaDatePipe,
    private appSettingProvider: AppSettingProviderService,
    private sagaSettings: SagaSettingsService
  ) {}

  ngOnInit() {
    this.appSettingProvider
      .getBooleanSetting('MapOfflineDownloadOverWifiOnly', true, true)
      .subscribe(appSetting => (this._downloadOverWifiOnly = appSetting));
    this.isDownloadOverWifiSettingDisabled = this.sagaSettings.getValue('SagaMobileWebClient.MapOfflineForceDownloadOverWifiOnly');

    this.mapDownloadEventSub = this.mapDownloadService.$event.subscribe(event => this.onMapDownloadEvent(event));
  }

  ngOnDestroy() {
    if (this.mapDownloadEventSub) {
      this.mapDownloadEventSub.unsubscribe();
      this.mapDownloadEventSub = null;
    }
  }

  private onMapDownloadEvent(event: MapDownloadEvent) {
    this.progress = event.progress;
    this.hasProgress = typeof event.progress === 'number';

    this.setUITexts(event);

    this.isDownloadStarted = event.state === MapDownloadState.downloading || event.state === MapDownloadState.downloadPaused;
  }

  private setUITexts(event: MapDownloadEvent) {
    this.offlineMapButtonTextParam = {};

    switch (event.state) {
      case MapDownloadState.ready:
        this.offlineMapButtonText = OfflineMapButtonTextKey.StartDownload;
        break;

      case MapDownloadState.downloading:
        this.offlineMapButtonText = OfflineMapButtonTextKey.Downloading;
        this.pauseButtonText = PauseButtonTextKey.Pause;
        break;

      case MapDownloadState.downloadPaused:
        this.offlineMapButtonText = OfflineMapButtonTextKey.Downloading;
        this.pauseButtonText = PauseButtonTextKey.Resume;
        break;

      case MapDownloadState.unzipping:
        this.offlineMapButtonText = OfflineMapButtonTextKey.Unzipping;
        break;

      case MapDownloadState.mapAvailable:
        this.offlineMapButtonText = OfflineMapButtonTextKey.MapAvailable;
        this.offlineMapButtonTextParam = { date: this.sagaDate.transform(event.date, SagaDate.SHORT) };
        break;
    }
  }

  onDownloadOverWifiOnlyChange($event) {
    this._downloadOverWifiOnly.setValue($event.detail.checked);
  }

  onOfflineMapClicked() {
    if (this.canDownloadFromState.indexOf(this.mapDownloadState) !== -1) {
      if (!this.downloadOverWifiOnly || this.network.type === this.network.Connection.WIFI) {
        this.mapDownloadService.downloadMap();
      } else {
        this.alert.show({
          header: 'Mobile.NoWifi',
          message: 'Mobile.CheckWifiAndRetry',
          buttons: [{ text: 'Mobile.Close', value: true }]
        });
      }
    }
  }

  onPause() {
    if (this.mapDownloadState === MapDownloadState.downloading) {
      this.mapDownloadService.pause();
    } else if (this.mapDownloadState === MapDownloadState.downloadPaused) {
      this.mapDownloadService.start();
    }
  }

  onCancel() {
    if (this.isDownloadStarted) {
      const wasDownloading: boolean = this.mapDownloadState === MapDownloadState.downloading;
      if (wasDownloading) {
        this.mapDownloadService.pause();
      }

      this.alert
        .show({
          header: 'Mobile.ConfirmRequest',
          message: 'Mobile.CancelOfflineMapDownload',
          buttons: [
            { text: 'Mobile.No', value: false },
            { text: 'Mobile.Yes', value: true }
          ],
          backdropDismiss: false
        })
        .then(cancel => {
          if (cancel) {
            this.mapDownloadService.cancel();
          } else if (wasDownloading) {
            this.mapDownloadService.start();
          }
        });
    }
  }
}
