// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:30:40
import { CAnimalHistory_abstract } from './../../abstract/SagaSchema/History/CAnimalHistory_abstract';
import { CAnimal } from '@models/imported/SagaSchema/CAnimal';
import { HistoryTextFormatter } from '@utils/formatter/history-text-formatter';

export class CAnimalHistory extends CAnimalHistory_abstract {
  get text() {
    return HistoryTextFormatter.format(this, CAnimal.formsProperties);
  }
}
