import { Injectable } from '@angular/core';
import { IRefreshTokenStorage, IRefreshTokenStoreItem } from '@techwan/security';
import { IClaimsProvider } from '@techwan/ionic-core';
import { BehaviorSubject, Observable } from 'rxjs';

const ITEM_NAME = '_';

@Injectable()
export class RefreshTokenStorageService implements IRefreshTokenStorage, IClaimsProvider {
  private readonly _event = new BehaviorSubject<string>(null);
  public get $value(): Observable<string> {
    return this._event.asObservable();
  }

  constructor() {}

  clear(): any {
    sessionStorage.removeItem(ITEM_NAME);
    this._event.next(null);
  }

  hasRefreshToken(): boolean {
    const item = this.read();
    return item !== null;
  }

  read(): IRefreshTokenStoreItem {
    try {
      return JSON.parse(sessionStorage.getItem(ITEM_NAME));
    } catch (e) {
      return null;
    }
  }

  write(login: IRefreshTokenStoreItem): any {
    sessionStorage.setItem(ITEM_NAME, JSON.stringify(login));
    this._event.next(login !== null && login.login ? login.login.TokenString : null);
  }

  getToken(): string {
    const value = this.read();
    return (value && value.login.TokenString) || null;
  }
}
