import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TerminatedMessagesService {
  private readonly _endpoint = 'api/Status/GetTerminationMessages';
  private readonly _terminationMessages: string[] = [];

  constructor(private readonly _httpClient: HttpClient) {}

  getMessages(): Observable<string[]> {
    return this._terminationMessages.length === 0
      ? this._httpClient.get<string[]>(this._endpoint).pipe(map(messages => this.storeMessages(messages)))
      : of(this._terminationMessages);
  }

  private storeMessages(messages: string[]): string[] {
    messages.forEach(message => this._terminationMessages.push(message));
    return this._terminationMessages;
  }
}
