// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class StateTimerDefinition_abstract extends PersistentObject {
  static $t: string = 'SagaTimers.StateTimerDefinition';
  $t: string = 'SagaTimers.StateTimerDefinition';

  public static GetMembers(): string[] {
    return [
      'TimerDefinitionId',
      'IsActive',
      'Description',
      'StartTriggerCondition',
      'AllowDisable',
      'Audit',
      'ApplyOnSubmitOnly',
      'SingleInstance',
      'Operator',
      'Priority',
      'TimeOutMessage',
      'Repeat',
      'SiteId',
      'Duration',
      'StartValue',
      'StopValue',
      'NegateStopValue',
      'StartObject',
      'StartTriggerProperty',
      'StartTimerProperty',
      'StopObject',
      'StopTriggerProperty',
      'IsAnimated',
      'Width',
      'Height',
      'Label',
      'Flash',
      'AutoHide',
      'FillColor'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return StateTimerDefinition_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TimerDefinitionId: number = 0;
  public IsActive: boolean = true;
  public Description: string;
  public StartTriggerCondition: string;
  public AllowDisable: boolean = false;
  public Audit: SagaBase.Defines.AuditBehaviour = SagaBase.Defines.AuditBehaviour.None;
  public ApplyOnSubmitOnly: boolean = false;
  public SingleInstance: boolean = false;
  public Operator: SagaBase.Defines.CompareOperator = SagaBase.Defines.CompareOperator.logicalAnd;
  public Priority: SagaBase.Defines.TimerPriority = SagaBase.Defines.TimerPriority.Undefined;
  public TimeOutMessage: string;
  public Repeat: boolean = false;
  public SiteId: number;
  public Duration: number = 0;
  public StartValue: number = 0;
  public StopValue: number = 0;
  public NegateStopValue: boolean = false;
  public StartObject: string = '';
  public StartTriggerProperty: string = '';
  public StartTimerProperty: string = '';
  public StopObject: string = '';
  public StopTriggerProperty: string = '';
  public IsAnimated: boolean = false;
  public Width: number;
  public Height: number;
  public Label: string = '';
  public Flash: boolean = true;
  public AutoHide: boolean = true;
  public FillColor: string = '';

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
