<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span translate>SagaMobile.UA.Title</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="gn-home padding">
  <div flex>
    <ion-card>
      <ion-card-header><span translate>SagaMobile.UA.StatusAvailable</span></ion-card-header>
      <ion-item *ngFor="let status of getStatus()" (click)="sendStatus(status)">
        <div slot="start" class="unit-status" [style.backgroundColor]="status.color.tinycolor.toRgbString()"></div>
        <ion-label [translate]="status.Name"></ion-label>
      </ion-item>
      <ion-item *ngIf="getStatus().length == 0" class="ion-text-center">
        <span translate style="font-style: italic;">SagaMobile.UA.NoStatusAvailable</span>
      </ion-item>
    </ion-card>
  </div>
</ion-content>
<ion-tab-bar *ngIf="canEdit() || canDelete()">
  <ion-tab-button *ngIf="canEdit()" (click)="edit()">
    <ion-icon name="create"></ion-icon>
  </ion-tab-button>
  <ion-tab-button *ngIf="canDelete()" (click)="delete()">
    <ion-icon name="trash" color="danger"></ion-icon>
  </ion-tab-button>
</ion-tab-bar>
