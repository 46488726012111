<div *ngIf="callcard">
  <ion-row class="ion-align-items-center ion-justify-content-around">
    <ion-chip class="chip-rounded-icon" *ngIf="unitActivity" (click)="openMyUnit()" color="primary">
      <ion-icon name="car" [style.backgroundColor]="unitActivity.status && unitActivity.status.displayColor"></ion-icon>
      <ion-label *ngIf="unitActivity.status" [translate]="unitActivity.status.Name"></ion-label>
    </ion-chip>
  </ion-row>

  <app-callcard-detail-info [callcard]="callcard"></app-callcard-detail-info>
  <app-callcard-ccallers-card [callcard]="callcard"></app-callcard-ccallers-card>
</div>
