// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import * as SagaSchema from '../../SagaSchema';
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ComJobOverview_abstract extends SagaObject {
  static $t: string = 'SagaSchema.ComJobOverview';
  $t: string = 'SagaSchema.ComJobOverview';

  public static GetMembers(): string[] {
    return [
      'CallCardGuid',
      'ProcStepUid',
      'ComJobUid',
      'ComJobPhoneResourceCount',
      'HostName',
      'Description',
      'JobOverviewType',
      'IsArchived',
      'JobOverviewStatus',
      'SourceIdentifier'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ComJobOverview_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardGuid: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public ComJobUid: Guid = GuidTools.Empty;
  public ComJobPhoneResourceCount: number = 0;
  public HostName: string = '';
  public Description: string;
  public JobOverviewType: SagaSchema.ComJobOverviewType = SagaSchema.ComJobOverviewType.Real;
  public IsArchived: boolean = false;
  public JobOverviewStatus: SagaSchema.ComJobOverviewStatus = SagaSchema.ComJobOverviewStatus.Creation;
  public SourceIdentifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardGuid;
  }
  set ParentObjId(value: any) {
    this.CallCardGuid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
