import { Injectable } from '@angular/core';
import { MediaMessagesService, MessageEvent, MessageEventType } from '../media-messages/media-messages.service';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { SoundService } from '@addins/core/core';
import { MediaMessagesFilterService } from '../media-messages-filter/media-messages-filter.service';
import { Subscription } from 'rxjs';

@Injectable()
export class MediaMessagesNewService {
  private _sub: Subscription = null;

  private readonly _mediaMessages: MediaMessage[] = [];
  get mediaMessages(): MediaMessage[] {
    return this._mediaMessages;
  }

  private _since: Date = null;
  get since(): Date {
    return this._since;
  }

  constructor(
    private _mediaMessagesService: MediaMessagesService,
    private _mediaMessagesFilter: MediaMessagesFilterService,
    private _soundService: SoundService
  ) {}

  clear() {
    this._mediaMessages.length = 0;
  }

  has(mediaMessage: MediaMessage): boolean {
    return this._mediaMessages.indexOf(mediaMessage) >= 0;
  }

  setup() {
    this._sub = this._mediaMessagesService.$event.subscribe(event => this.onMessageEvent(event));
  }

  cleanup() {
    this.clear();
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  private onMessageEvent(event: MessageEvent) {
    const mediaMessage = event.target;

    switch (event.type) {
      case MessageEventType.removed:
        const idx = this._mediaMessages.indexOf(mediaMessage);
        if (idx >= 0) {
          this._mediaMessages.splice(idx, 1);
        }
        break;

      case MessageEventType.added:
        if (this._mediaMessagesFilter.isVisibleForMe(mediaMessage)) {
          if (this._mediaMessages.length === 0) {
            this._since = new Date();
          }

          this._mediaMessages.splice(0, 0, mediaMessage);
          this._soundService.playNotification();
        }
    }
  }
}
