import { Injectable } from '@angular/core';
import { IDocument } from '../../interfaces';
import { SagaSettingsService } from '@services/settings';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';

@Injectable()
export class BrowserDownloaderService {
  private _documentUrl: string = null;

  constructor(private readonly _windowOpener: WindowOpenerService, private readonly _sagaSettingsService: SagaSettingsService) {}

  public download(document: IDocument) {
    if (this._documentUrl === null) {
      this._documentUrl = this._sagaSettingsService.getValue('SagaMobileWebClient.DocumentsUrl');
    }
    this._windowOpener.open(`${this._documentUrl}/${document.url}`, null);
  }
}
