import { Component, OnInit } from '@angular/core';
import { LoginExecutorService } from '@techwan/ionic-core/login';
import { IAuthParams, LoginService } from '@techwan/security';

/**
 * @description
 *
 */
@Component({
  selector: 'app-trusted-user',
  templateUrl: 'trusted-user-login.component.html',
  styleUrls: ['trusted-user-login.component.scss'],
  providers: [LoginExecutorService]
})
export class TrustedUserLoginComponent implements OnInit {
  user: string = '';

  private _error = '';
  get errorText(): string {
    return this.loginExecutor.error || this._error;
  }

  constructor(private loginExecutor: LoginExecutorService, private loginService: LoginService) {}

  ngOnInit() {
    this.user = this.loginService.loginType.UserName;
    if (!this.user) {
      this._error = 'NoTrustedUser';
    }
  }

  loginClicked() {
    const params: IAuthParams = {
      username: this.user,
      WithMessageQueuing: false
    };
    this.loginExecutor.login(params).subscribe();
  }
}
