import { ModalController, Platform } from '@ionic/angular';
import { SettingsComponent } from '../../components/settings/settings.component';
import { NativePreferencesService } from '../native-preferences/native-preferences.service';
import { Injectable } from '@angular/core';
import { ISettingOpener } from '@addins/core/menus';

@Injectable()
export class SettingOpenerService implements ISettingOpener {
  public constructor(
    protected readonly modalController: ModalController,
    protected readonly platform: Platform,
    protected readonly preferences: NativePreferencesService
  ) {}

  public open(): Promise<void> {
    return this.platform.is('cordova')
      ? this.preferences.show()
      : // TODO: @deprecated please use ModalFactoryService instead
        this.modalController.create({ component: SettingsComponent }).then(s => s.present());
  }
}
