import { Input, Directive } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Directive()
export class CallcardComponentCard {
  @Input() callcard: CallCard = null;
  @Input() enableInteraction: boolean = true;

  showMore: boolean = false;
}
