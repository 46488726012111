// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ProcStepComplement_abstract extends BaseObject {
  static $t: string = 'SagaSchema.ProcStepComplement';
  $t: string = 'SagaSchema.ProcStepComplement';

  public static GetMembers(): string[] {
    return ['Inactivated', 'Order', 'Url', 'Description'];
  }

  public GetMembers(): string[] {
    return ProcStepComplement_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Inactivated: boolean = false;
  public Order: number = 0;
  public Url: string;
  public Description: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
