// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObjectManager } from '../../SagaBase/SagaObjectManager';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmTest_abstract extends SagaObjectManager {
  static $t: string = 'SagaSchema.AlarmTest';
  $t: string = 'SagaSchema.AlarmTest';

  public static GetMembers(): string[] {
    return [
      'TransmitterUId',
      'DetectorUId',
      'Status',
      'Archived',
      'Description',
      'AlarmType',
      'DeferredDateUTC',
      'DeferredInstructions',
      'DeferredInfo'
    ].concat(SagaObjectManager.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmTest_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DeferredDateUTC = this.DeferredDateUTC && new Date(this.DeferredDateUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.AlarmStamp',
      'SagaSchema.AlarmTestStamp',
      'SagaSchema.CCaller',
      'SagaSchema.History.CCallerHistory',
      'SagaBase.PhoneCall',
      'SagaSchema.CStampCard',
      'SagaSchema.CNote',
      'SagaSchema.History.CNoteHistory',
      'SagaSchema.CFreeText',
      'SagaSchema.CStampFreeText'
    ];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TransmitterUId: Guid = GuidTools.Empty;
  public DetectorUId: Guid = GuidTools.Empty;
  public Status: SagaBase.AlarmTestStatus = SagaBase.AlarmTestStatus.Active;
  public Archived: boolean = false;
  public Description: string;
  public AlarmType: SagaBase.AlarmType;
  public DeferredDateUTC: Date = new Date();
  public DeferredInstructions: any;
  public DeferredInfo: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
