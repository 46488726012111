import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ProfileService } from '@services/profile/profile.service';
import { AddinExecute, AddinService, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@AddinExecute({
  name: 'SettingsMenu',
  description: 'Add a menu entry to open the settings editor'
})
@Injectable()
export class SettingsMenuService extends Execute {
  private menuEntry: MenuEntry;

  private profileReadySub: Subscription = null;

  constructor(private menuService: SideMenuService, private addins: AddinService, private profile: ProfileService) {
    super();
  }

  execute(): void {
    if (this.profile.isReady()) {
      this.createMenu();
    } else {
      this.profileReadySub = this.profile.ready.pipe(filter(state => state)).subscribe(() => this.createMenu());
    }
  }

  destroy(): void {
    this.clear();
    if (this.menuEntry) {
      this.menuService.removeMenuEntry(this.menuEntry);
      this.menuEntry = null;
    }
  }

  private createMenu(): void {
    this.clear();
    this.menuEntry = new MenuEntry('settings', 'cog', this.addins.resolveAddinView('Settings/SettingsEditor').element, -1);
    this.menuService.addMenuEntry(this.menuEntry);
  }

  private clear(): void {
    if (this.profileReadySub) {
      this.profileReadySub.unsubscribe();
      this.profileReadySub = null;
    }
  }
}
