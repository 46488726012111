import { IAppSetting } from '@addins/core/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListPriority } from '@models/imported/SagaSchema/ListObjects/ListPriority';
import { TranslateService } from '@ngx-translate/core';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { AppSettingProviderService } from '@services/settings';
import { AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { PriorityFilterService } from '../../services/priority-filter/priority-filter.service';

interface PriorityCheckbox {
  label: string;
  value: number;
  checked: boolean;
}

@AddinView({
  name: 'CardListPriorityFilter'
})
@Component({
  selector: 'app-callcard-list-priority-filter',
  templateUrl: './callcard-list-priority-filter.component.html',
  styleUrls: ['./callcard-list-priority-filter.component.scss']
})
export class CallcardListPriorityFilterComponent implements OnInit, OnDestroy {
  private prioritiesToFilter: IAppSetting<number[]> = null;
  private onSaveSubscription: Subscription = null;

  priorityItems: PriorityCheckbox[] = [];

  constructor(
    private priorityFilterService: PriorityFilterService,
    private fetchListService: FetchListService,
    private translate: TranslateService,
    private appSettingProvider: AppSettingProviderService
  ) {}

  ngOnInit() {
    this.appSettingProvider.getSetting('PrioritiesToFilter', [], true).subscribe(appSetting => {
      this.prioritiesToFilter = appSetting;
      this.createPriorityCheckboxes();
      this.onSaveSubscription = this.priorityFilterService.$onSave.subscribe(() => this.onFilterSave());
    });
  }

  private createPriorityCheckboxes() {
    const labelPrefix: string = this.translate.instant('Priority');
    const currentPrioritiesToFilter: number[] = this.prioritiesToFilter.current;
    this.priorityItems = this.fetchListService.getAllRows(ListPriority).map(priority => {
      return {
        label: `${labelPrefix}: ${priority.DisplayValue}`,
        checked: currentPrioritiesToFilter.includes(priority.Value),
        value: priority.Value
      };
    });
  }

  private onFilterSave() {
    const selectedPriorities: number[] = this.priorityItems.filter(item => item.checked).map(item => item.value);
    this.prioritiesToFilter.setValue(selectedPriorities);
  }

  ngOnDestroy() {
    this.onSaveSubscription?.unsubscribe();
  }
}
