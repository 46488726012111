import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Map as SagaMap } from '@techwan/mapping/lib/src/main/map';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { GroupName as GrpName, TacticalSituationLayer } from '@techwan/mapping';
import { GroupName, SagaLayerManager } from '@techwan/ionic-core/map';
import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { LayoutService } from '@services/layout/layout.service';
import { ProfileService } from '@services/profile/profile.service';
import { MapInstanceEventType, MapInstancesService } from '@services/map';
import { TacticalDrawingsService } from '../../tactical-drawings/tactical-drawings.service';
import { DrawingTocManagerService } from '../../drawing-toc-manager/drawing-toc-manager.service';
import { MyDrawing } from '../../../schema/my-drawing';
import { MyCardDrawings } from '../../../schema/my-card-drawings';
import { DrawingEventType } from '../../../schema/event-type';
import { ITacticalDrawingEvent } from '../../../schema/interfaces/ITacticalDrawingEvent';
import { MapName } from '@addins/core/core';

@AddinExecute({
  name: 'drawings-toc'
})
@Injectable()
export class DrawingTocItemService extends Execute {
  private _myDrawingLayer: MyDrawing = null;

  private _myCardDrawings: MyCardDrawings = null;

  private _sub: Subscription = null;

  private _sagaLayerManager: SagaLayerManager = null;

  private get map(): SagaMap {
    return this._maps.get(MapName.main);
  }

  constructor(
    private readonly _provider: TacticalDrawingsService,
    private readonly _drawings: DrawingTocManagerService,
    private readonly _maps: MapInstancesService,
    private readonly _layout: LayoutService,
    private readonly _profile: ProfileService
  ) {
    super();
  }

  execute(): void {
    this._sub = this._profile.ready.pipe(filter(r => r)).subscribe(() => this.ready());
  }

  private isEnabled(): boolean {
    return this._layout.layout.map && this._layout.layout.map === 'Map/Full';
  }

  private ready() {
    const enabled = this.isEnabled();

    if (this._sub !== null) {
      this._sub.unsubscribe();
    }

    if (enabled) {
      this._myDrawingLayer = this._provider.getMyDrawings();
      this._myCardDrawings = this._provider.getDrawingsOfMyCard();
    }

    if (enabled && this.map) {
      this.setup();
    } else if (enabled) {
      this._sub = this._maps.$change
        .pipe(filter(event => event.name === MapName.main && event.type === MapInstanceEventType.added))
        .subscribe(() => this.setup());
    }
  }

  private setup() {
    this._sagaLayerManager = this.map.getLayerManager() as SagaLayerManager;

    this._myDrawingLayer.$change.subscribe(i => this.refreshMyDrawings(i));
    this._myCardDrawings.$change.subscribe(i => this.refreshMyCardDrawings(i));

    const groupDrawings = this._sagaLayerManager.getLayerGroup(GrpName.drawingLayers as any);

    this._myCardDrawings.tacticalSituations.forEach(tacticalDrawing => {
      this._drawings.createLayer(this._sagaLayerManager, groupDrawings, tacticalDrawing);
    });
  }

  private refreshMyCardDrawings(event: ITacticalDrawingEvent) {
    const drawingLayersGroup = this._sagaLayerManager.getLayerGroup(GrpName.drawingLayers as any);
    const drawingLayers = drawingLayersGroup.getLayers() as ol.Collection<TacticalSituationLayer>;
    switch (event.type) {
      case DrawingEventType.add:
        this._drawings.createLayer(this._sagaLayerManager, drawingLayersGroup, event.target);
        break;

      case DrawingEventType.remove:
        drawingLayers.remove(this.find(drawingLayers, event.target));
        break;

      case DrawingEventType.update:
        this.find(drawingLayers, event.target).loadGeometry(event.target.GeometryObjects);
        break;
    }
  }

  private refreshMyDrawings(tacticalSituation: CTacticalSituation) {
    const editDrawingGroup = this._sagaLayerManager.getLayerGroup(GroupName.editDrawing);
    const drawingLayers = editDrawingGroup.getLayers();
    const currentLayer = drawingLayers.getArray().find(layer => layer instanceof TacticalSituationLayer) || null;

    if (tacticalSituation === null && currentLayer) {
      drawingLayers.remove(currentLayer);
    } else if (currentLayer === null && tacticalSituation !== null) {
      this._drawings.createLayer(this._sagaLayerManager, editDrawingGroup, tacticalSituation);
    } else if (tacticalSituation && currentLayer) {
      (currentLayer as TacticalSituationLayer).loadGeometry(tacticalSituation.GeometryObjects);
    }
  }

  private find(drawings: ol.Collection<TacticalSituationLayer>, tacticalSituation: CTacticalSituation): TacticalSituationLayer {
    return drawings.getArray().find((layer: TacticalSituationLayer) => layer.getId() === tacticalSituation.ObjGuid) || null;
  }
}
