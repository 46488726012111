// Automatically generated from TW.Saga.Shared.MetadataLib, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @19.06.2017 18:53:46
import { ListCardStatus_abstract } from './../abstract/SagaStateLib/ListCardStatus_abstract';
import { Color } from '@models/color';

export class ListCardStatus extends ListCardStatus_abstract {
  private _color: Color;

  get color(): Color {
    if (!this._color) {
      this._color = new Color(this.FColor);
    }
    return this._color;
  }
}
