import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CollapsibleItem } from '../../schema/collapsible-item';
import { CollapsibleItemsService } from '../../services/collapsible-items/collapsible-items.service';

@Component({
  selector: 'app-callcard-detail-collapsible',
  templateUrl: './callcard-detail-collapsible.component.html',
  styleUrls: ['./callcard-detail-collapsible.component.scss']
})
export class CallcardDetailCollapsibleComponent implements OnInit {
  @Input()
  callcard: CallCard;

  private _items: CollapsibleItem[] = [];
  get items(): CollapsibleItem[] {
    return this._items;
  }

  openedItems: string[] = [];

  constructor(private itemsService: CollapsibleItemsService) {}

  ngOnInit() {
    this.itemsService.items.forEach(collapsibleItem => this._items.push(collapsibleItem));
    this.openedItems = this._items.filter(collapsibleItem => !collapsibleItem.collapsed).map(collapsibleItem => collapsibleItem.title);
  }
}
