import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CurrentCardService } from '@addins/core/core';

@Component({
  selector: 'app-callcard-list-banner-item',
  templateUrl: './callcard-list-banner-item.component.html',
  styleUrls: ['./callcard-list-banner-item.component.scss']
})
export class CallcardListBannerItemComponent implements OnInit {
  @Input()
  public callcard?: CallCard;

  @Input()
  public current?: CurrentCardService;

  public get color(): string {
    if (this.callcard) {
      if (this.current && this.current.current && this.current.current === this.callcard) {
        return 'white';
      } else {
        return this.callcard.colorRgb;
      }
    }
    return '';
  }
  constructor() {}

  ngOnInit() {}
}
