import { StaticTranslationsService } from '@addins/core/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { INativePreferences, NATIVE_PREFERENCE_TOKEN } from '@app/services';
import { Platform } from '@ionic/angular';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { AppSettingsService } from '@services/settings';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { LoginStorageService } from '@techwan/ionic-core/login';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { LoginStateService } from '../login-state/login-state.service';

@Injectable()
export class BootService {
  private APP_BOOL_SETTINGS: Object = {
    ClearLocalStorage: this.clearLocalStorage,
    ClearLoginDataStorage: this.clearLoginData
  };

  protected _error: HttpErrorResponse = null;

  get error(): HttpErrorResponse {
    return this._error;
  }

  constructor(
    private loginState: LoginStateService,
    private viewSelector: ViewSelectorService,
    private staticTranslations: StaticTranslationsService,
    private platform: Platform,
    private appSettings: AppSettingsService,
    private loginStorage: LoginStorageService,
    private refreshTokenStorage: RefreshTokenStorageService,
    @Optional()
    @Inject(NATIVE_PREFERENCE_TOKEN)
    private nativePreferences?: INativePreferences
  ) {}

  setup(): Observable<boolean> {
    return this.staticTranslations.loadLocalTranslations().pipe(
      switchMap(() => {
        return this.getUrl().pipe(
          tap(url => {
            this.init(url);
          }),
          switchMap(url => this.setupStaticTranslations(url))
        );
      })
    );
  }

  private getUrl(): Observable<string> {
    return this.appSettings.baseUrl();
  }

  private init(url: string) {
    if (this.platform.is('cordova')) {
      zip(...Object.keys(this.APP_BOOL_SETTINGS).map(settingName => this.nativePreferences.fetch(settingName))).subscribe(settings =>
        this.handleSettings(settings, url)
      );
    } else {
      this.start();
    }
  }

  private start() {
    this.loginState.start();
  }

  private setupStaticTranslations(url: string): Observable<boolean> {
    return !this.platform.is('cordova') || this.isValidUrl(url)
      ? this.staticTranslations.loadStaticTranslations().pipe(
          retry(3),
          catchError(err => throwError((this._error = err))),
          tap(() => this.viewSelector.setup()),
          map(() => true)
        )
      : of(false);
  }

  private isValidUrl(url: string): boolean {
    const notValid = ['', 'http://', 'https://'];
    return notValid.indexOf(url) === -1;
  }

  private handleSettings(settings: any, url: string) {
    const settingNames = Object.keys(this.APP_BOOL_SETTINGS);
    settings.forEach((setting, index) => {
      const settingValue = setting[settingNames[index]];
      const isSettingOn: boolean = settingValue === '1' || settingValue === 'true';
      if (isSettingOn) {
        this.handleBoolSetting(settingNames[index]);
      }
    });

    this.initPlatform(url);
  }

  private handleBoolSetting(settingName: string) {
    this.APP_BOOL_SETTINGS[settingName].call(this);
  }

  private clearLocalStorage() {
    localStorage.clear();
    this.nativePreferences.store('ClearLocalStorage', '0');
  }

  private clearLoginData() {
    this.loginStorage.removeSagaUsername();
    this.loginStorage.removeRefreshToken();
    this.refreshTokenStorage.clear();
    this.nativePreferences.store('ClearLoginDataStorage', '0');
  }

  private initPlatform(url: string) {
    if (!this.isValidUrl(url)) {
      this.nativePreferences.show();
    } else {
      this.start();
    }

    this.nativePreferences.listenTo('ServerURL').subscribe(() => {
      this.nativePreferences.removeListeners();
      window.location.reload();
    });
  }
}
