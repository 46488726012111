// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class resResourceSchedules_abstract extends SagaObject {
  static $t: string = 'SagaSchema.resResourceSchedules';
  $t: string = 'SagaSchema.resResourceSchedules';

  public static GetMembers(): string[] {
    return [
      'glblSchedules_Uid',
      'glblSchedules_Id',
      'glblSchedules_StartTime',
      'glblSchedules_EndTime',
      'glblSchedules_Day',
      'glblSchedules_DayName',
      'glblSchedules_MiscInfo',
      'resResources_Uid',
      'resResources_Name',
      'resResources_CompanyName',
      'resResources_LastName',
      'resResources_FirstName',
      'glblScheduleDescriptions_Name',
      'glblScheduleDescriptions_Description',
      'resResourceSchedules_glblScheduleUid',
      'resResources_Id',
      'resResources_MiscText',
      'resResources_Ownerid'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return resResourceSchedules_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.glblSchedules_StartTime = this.glblSchedules_StartTime && new Date(this.glblSchedules_StartTime);
    this.glblSchedules_EndTime = this.glblSchedules_EndTime && new Date(this.glblSchedules_EndTime);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public glblSchedules_Uid: Guid = GuidTools.Empty;
  public glblSchedules_Id: number = 0;
  public glblSchedules_StartTime: Date = new Date();
  public glblSchedules_EndTime: Date = new Date();
  public glblSchedules_Day: number = 0;
  public glblSchedules_DayName: string;
  public glblSchedules_MiscInfo: string = '';
  public resResources_Uid: Guid = GuidTools.Empty;
  public resResources_Name: string;
  public resResources_CompanyName: string;
  public resResources_LastName: string;
  public resResources_FirstName: string;
  public glblScheduleDescriptions_Name: string;
  public glblScheduleDescriptions_Description: string;
  public resResourceSchedules_glblScheduleUid: Guid = GuidTools.Empty;
  public resResources_Id: number = 0;
  public resResources_MiscText: string = '';
  public resResources_Ownerid: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.glblSchedules_Uid;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
