import { SideMenuService } from '@services/menu/side-menu.service';
import { MenuEntry } from '@services/menu/entry.schema';
import { Security } from '@services/security/security.service';
import { Injectable } from '@angular/core';
import { CurrentCardService, LocationProviderService, UnitActivityAddinName, UnitActivityViewName } from '@addins/core/core';
import { Guid } from '@models/guid';
import { MyEquipmentService } from '@techwan/ionic-core';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';

@Injectable()
export class MenuService {
  private _setup = false;

  private readonly sendCoordinateEntry: MenuEntry;
  private readonly activityViewEntry: MenuEntry;
  private readonly myCardOpener: MenuEntry;

  constructor(
    private readonly _menuService: SideMenuService,
    private readonly _locationProvider: LocationProviderService,
    private readonly _card: CurrentCardService,
    private readonly _security: Security,
    private readonly _me: MyEquipmentService,
    private readonly _viewSelector: ViewSelectorService
  ) {
    let device: Guid;
    this._me.myDevice.subscribe(d => (device = d.ObjGuid)).unsubscribe();
    this.sendCoordinateEntry = new MenuEntry('SendCoordinate', 'locate', () =>
      this._locationProvider.running ? this._locationProvider.stop() : this._locationProvider.start(device)
    );
    this.sendCoordinateEntry.isHandler = true;

    this.myCardOpener = new MenuEntry('MyCard', 'card', () => {
      if (this._security.getData('UnitActivity') && this._security.getData('UnitActivity').callcard) {
        this._card.select(this._security.getData('UnitActivity').callcard);
      }
    });
    this.myCardOpener.isHandler = true;
    this.myCardOpener.hidden = () => !this._security.getData('UnitActivity') || !this._security.getData('UnitActivity').callcard;

    this.activityViewEntry = new MenuEntry(
      'UnitView',
      'car',
      this._viewSelector.getView(`${UnitActivityAddinName}/${UnitActivityViewName}`).element
    );
  }

  public setup(): void {
    if (!this._setup) {
      this._menuService.addMenuEntry(this.sendCoordinateEntry);
      this._menuService.addMenuEntry(this.activityViewEntry);
      this._menuService.addMenuEntry(this.myCardOpener);
      this._setup = true;
    }
  }

  public destroy(): void {
    this._menuService.removeMenuEntry(this.sendCoordinateEntry);
    this._menuService.removeMenuEntry(this.activityViewEntry);
    this._menuService.removeMenuEntry(this.myCardOpener);
  }
}
