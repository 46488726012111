<ion-buttons class="button-container">
  <ion-button
    *ngFor="let button of callcardToolButtons"
    [class.ion-hide]="!button.show"
    [maxWidthOnCount]="buttonsCount"
    [class.compact-button]="compactButtons"
    fill="clear"
    class="tools"
    (click)="button.click()"
  >
    <ion-icon [icon]="button.icon" slot="start" size="large"></ion-icon>
    <span>{{ button.dontTranslate ? button.text : (button.text | translate) }}</span>
  </ion-button>
</ion-buttons>
