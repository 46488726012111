<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title style="line-height: 1em;">
      <span>{{ currentCallCard && currentCallCard.event.EventText }}</span
      ><br />
      <span style="font-size: 0.6em">{{ currentCallCard && (currentCallCard.CreatedUTC | moment: 'L LT') }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="stamps.open($event, currentCallCard)"><ion-icon slot="icon-only" name="add"></ion-icon></ion-button>
      <ion-button hideWhen="tablet" (click)="openMap()"><ion-icon slot="icon-only" name="map"></ion-icon></ion-button>
      <ion-button *ngIf="addPicture" (click)="addPicture.execute()"><ion-icon slot="icon-only" name="camera"></ion-icon></ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-segment color="light" [(ngModel)]="page">
      <ion-segment-button [value]="0">
        <ion-icon name="information-circle"></ion-icon>
      </ion-segment-button>
      <ion-segment-button [value]="1">
        <ion-icon name="list"></ion-icon>
      </ion-segment-button>
      <ion-segment-button [value]="2" style="display: flex;align-items: center;justify-content: center">
        <ion-icon name="search"></ion-icon>
        <span translate>SagaMobile.Sip.Label</span>
        <ion-badge
          color="danger"
          style="position: absolute;right: 10px;"
          *ngIf="currentCallCard && currentCallCard.externalSearchs.Far.getUnreadCount()"
          >{{ currentCallCard.externalSearchs.Far.getUnreadCount() }}</ion-badge
        >
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-slides
    pager="false"
    [options]="options"
    class="callcard-detail-slides"
    (ionSlideWillChange)="slideWillChanged()"
    (ionSlideDidChange)="slideChanged()"
  >
    <ion-slide *ngFor="let index of indexes">
      <callcard-detail-content flex layout="column" [callcard]="callcards.items[index]"></callcard-detail-content>
    </ion-slide>
  </ion-slides>
  <div class="callcard-detail-pager" #pager>{{ currentIndex + 1 }} / {{ callcards.items.length }}</div>
</ion-content>
