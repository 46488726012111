<ion-footer>
  <ion-buttons>
    <ion-button
      *ngFor="let b of buttons"
      [class.hidden]="b.isHidden"
      [translate]="b.text"
      (click)="b.onClicked()"
      [fill]="b.fill"
      [color]="b.color"
      [disabled]="b.isDisabled"
    ></ion-button>
  </ion-buttons>
</ion-footer>
