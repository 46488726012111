<app-permanent-menu></app-permanent-menu>
<section #fullHeight class="content">
  <app-top-layout *ngIf="mainLayout && mainLayout.length" [views]="mainLayout"></app-top-layout>
  <app-view-splitter
    *ngIf="topLayoutSplitterNeeded"
    resizeMode="OnlyPrevious"
    [previousMin]="topLayoutMinHeight"
    [previousMax]="topLayoutMaxHeight"
    [initialHeight]="45"
  ></app-view-splitter>
  <ion-nav flex #view class="main-content"></ion-nav>
  <app-view-splitter
    *ngIf="panelContentComponent.active && panelContentComponent.active.hasViewSplitter"
    resizeMode="OnlyNext"
    nextMin="15"
  ></app-view-splitter>
  <app-panel-content #panelContentComponent [fullHeight]="fullHeight"></app-panel-content>
</section>
