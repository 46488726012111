import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IDocumentListResponse } from '../../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentProxyService {
  constructor(private readonly _http: HttpClient) {}

  public getDocuments(): Observable<string[]> {
    return this._http.get<IDocumentListResponse>('api/Documents/List').pipe(map(res => this.parse(res)));
  }

  private parse(data: IDocumentListResponse): string[] {
    if (data.error === 0) {
      return data.documents;
    }
    throw data;
  }
}
