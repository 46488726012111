import { Injectable } from '@angular/core';
import { ProcedureStampType } from '@models/imported/SagaBase';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { CStampUnitActivity } from '@models/imported/SagaSchema/CStampUnitActivity';

const MOBILIZATION_KEY_BASE: string = 'Mobile.Mobilization';
const MOBILIZATION_JOB_STATUS: string = `${MOBILIZATION_KEY_BASE}.JobStatus`;
const MOBILIZATION_DESCRIPTION: string = `${MOBILIZATION_KEY_BASE}.Description`;
const MOBILIZATION_JOB_TYPE: string = `${MOBILIZATION_KEY_BASE}.JobType`;

const EXPORT_INFO_KEY: string = 'EagleExportInfo';
const EXPORT_INFO_DEPARTURE: string = 'SelectedDepartureName';
const EXPORT_INFO_DEPARTURE_NAME: string = `Mobile.${EXPORT_INFO_KEY}.${EXPORT_INFO_DEPARTURE}`;

@Injectable({
  providedIn: 'root'
})
export class StampTextProviderService {
  constructor() {}

  getStampText(item: SagaObject): string {
    let stampText: string = item.text;

    switch (item.$t) {
      case CStampProcedure.$t: {
        stampText = this.getStampProcedureText(item as CStampProcedure);
        break;
      }

      case CStampUnitActivity.$t:
        {
          stampText = this.getUnitResources((item as CStampUnitActivity).UnitContentString);
        }
        break;
    }

    return stampText;
  }

  private getStampProcedureText(item: CStampProcedure): string {
    switch (item.StampType) {
      case ProcedureStampType.IsCommunication:
        return item.ServiceName;

      case ProcedureStampType.IsMobilization:
        return this.getMobilizationInfo(item.text);

      default:
        return item.text;
    }
  }

  private getDocument(xmlString: string): Document {
    return new DOMParser().parseFromString(xmlString, 'text/xml');
  }

  private getMobilizationInfo(xml: string): string {
    const mobilization: Document = this.getDocument(xml);
    const jobStatus: string = mobilization.getElementsByTagName('JobStatus')[0].innerHTML;
    const description: string = mobilization.getElementsByTagName('Description')[0].innerHTML;
    const jobType: string = mobilization.getElementsByTagName('JobType')[0].innerHTML;

    return [
      `{${MOBILIZATION_JOB_STATUS}}: {${MOBILIZATION_JOB_STATUS}.${jobStatus}}`,
      `{${MOBILIZATION_DESCRIPTION}}: ${description}`,
      `{${MOBILIZATION_JOB_TYPE}}: {${MOBILIZATION_JOB_TYPE}.${jobType}}`
    ].join('\r\n');
  }

  private getUnitResources(unitContentString: string): string {
    const unitContent: Document = this.getDocument(unitContentString);
    let unitResources: Element[] = Array.from(
      unitContent
        .getElementsByTagName('UnitActivityContent')[0]
        .getElementsByTagName('ContentList')[0]
        .getElementsByTagName('UnitActivityContentResource')
    );

    return unitResources.map(unitResource => unitResource.getAttribute('Name')).join(',\r\n');
  }
}
