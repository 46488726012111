import { Injectable } from '@angular/core';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { DOCUMENT_ADDIN } from '../../../schema/constants';
import { BaseMenuEntry } from '../../../schema/document-menu-entry/base-view-entry';
import { DocumentFullViewEntry } from '../../../schema/document-menu-entry/document-full-view-entry';

@AddinExecute({
  name: 'DocumentsMenu',
  description: 'Add a menu entry for open the documents list'
})
@Injectable()
export class DocumentsMenuService extends Execute {
  /**
   * The menu entry for the documents list
   */
  private menuEntry: BaseMenuEntry = null;

  constructor(
    private profileService: ProfileService,
    private profileReaderService: ProfileReaderService,
    private sideMenuService: SideMenuService
  ) {
    super();
  }

  execute(): void {
    if (this.profileService.isReady()) {
      this.onProfileReady(true);
    } else {
      this.profileService.ready.subscribe(r => this.onProfileReady(r));
    }
  }

  destroy(): void {
    if (this.menuEntry !== null) {
      this.sideMenuService.removeMenuEntry(this.menuEntry);
      this.menuEntry = null;
    }
  }

  private onProfileReady(ready: boolean) {
    const component = ready === true ? this.profileReaderService.getComponent(this.profileService.current, DOCUMENT_ADDIN) : null;

    if (component !== null) {
      this.menuEntry = new DocumentFullViewEntry();
      this.sideMenuService.addMenuEntry(this.menuEntry);
    } else {
      this.destroy();
    }
  }
}
