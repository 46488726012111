// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TaskFrequency_abstract extends SagaObject {
  static $t: string = 'SagaSchema.TaskFrequency';
  $t: string = 'SagaSchema.TaskFrequency';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'StartDateUtc',
      'EndDateUtc',
      'WeekDays',
      'DayRecurence',
      'WeekRecurence',
      'Occurence',
      'FrequencyMode',
      'Dates'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return TaskFrequency_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.StartDateUtc = this.StartDateUtc && new Date(this.StartDateUtc);
    this.EndDateUtc = this.EndDateUtc && new Date(this.EndDateUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public StartDateUtc: Date = new Date();
  public EndDateUtc: Date = new Date();
  public WeekDays: string;
  public DayRecurence: number = 1;
  public WeekRecurence: number = 1;
  public Occurence: SagaBase.FrequencyOccurence = SagaBase.FrequencyOccurence.Daily;
  public FrequencyMode: SagaBase.FrequencyModes = SagaBase.FrequencyModes.Recurence;
  public Dates: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
