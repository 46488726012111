export class CancellationTokenSource {
  private _isCanceled = false;
  constructor(private callback?: () => {}) {}

  isCancellationRequested() {
    return this._isCanceled;
  }

  cancel() {
    this._isCanceled = true;

    if (typeof this.callback === 'function') {
      this.callback();
    }
  }
}
