<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span translate="Mobile.Map"></span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <lib-map-element [mapOptions]="mapOptions"></lib-map-element>
  <div class="fab-buttons" slot="fixed">
    <ng-container *ngFor="let button of buttonItems">
      <ion-fab-button size="small" *ngIf="!button.hidden" (click)="button.handler.call(button.context)">
        <ion-icon color="light" [style.color]="button.color" [name]="button.icon"></ion-icon>
      </ion-fab-button>
    </ng-container>
    <ion-fab-button size="small" (click)="openMoreItems($event)"><ion-icon name="ellipsis-horizontal-outline"></ion-icon></ion-fab-button>
  </div>
</ion-content>
