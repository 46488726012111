import { Component } from '@angular/core';
import { Task } from '@models/imported/SagaSchema/Task';
import { CurrentTaskService } from '../../services/current-task/current-task.service';

@Component({
  selector: 'app-planned-mission-details-view',
  templateUrl: './planned-mission-details-view.component.html',
  styleUrls: ['./planned-mission-details-view.component.scss']
})
export class PlannedMissionDetailsViewComponent {
  get task(): Task {
    return this.currentTask.current;
  }

  constructor(private currentTask: CurrentTaskService) {}
}
