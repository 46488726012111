import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LayoutService } from '@services/layout/layout.service';

@Component({
  template: ''
})
export class MapFullViewBaseComponent {
  public largeMenu: boolean = true;

  protected _isTablet: boolean = null;

  public get isTablet(): boolean {
    if (this._isTablet === null) {
      this._isTablet = this.platform.is('tablet') && !this._layout.mainLayoutHasView('Status/');
    }
    return this._isTablet;
  }

  constructor(protected readonly platform: Platform, protected readonly _layout: LayoutService) {}

  public toggle() {
    this.largeMenu = !this.largeMenu;
  }
}
