import { INativeTranslationsProvider } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { NativeTranslations } from '@techwan/ionic-plugin-translations';
import { from, Observable } from 'rxjs';

@Injectable()
export class NativeTranslationsService implements INativeTranslationsProvider {
  constructor() {}

  setup(): Observable<{ [p: string]: string }> {
    return from(NativeTranslations.getAll()) as Observable<any>;
  }
}
