import { Injectable } from '@angular/core';

@Injectable()
export class NameOrderService {
  constructor() {}

  public order(list: any[], attr: string) {
    return list.sort((a, b) => (a[attr] > b[attr] ? 1 : a[attr] === b[attr] ? 0 : -1));
  }
}
