// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../../SagaBase/SagaObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class MapSymbol_abstract extends SagaObject {
  static $t: string = 'SagaBase.Objects.MapSymbol';
  $t: string = 'SagaBase.Objects.MapSymbol';

  public static GetMembers(): string[] {
    return ['Description', 'FileName', 'X', 'Y', 'VideoProviderDB', 'VideoUri', 'TypeId'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return MapSymbol_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Description: string = '';
  public FileName: string = '';
  public X: number = -1.79769313486232e308;
  public Y: number = -1.79769313486232e308;
  public VideoProviderDB: number = 0;
  public VideoUri: string;
  public TypeId: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
