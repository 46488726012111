import { MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { SagaSettingsService } from '@services/settings';
import { MapToolsService, ToggleTool, ToolBase, ToolGroup, ToolName } from '@techwan/map-tools';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ILocalizeRequest } from '../../../schema/interfaces/ILocalizeRequest';
import { LocalizePositionService } from '../../localize-position/localize-position.service';
import { MyLocationService } from '../../my-location/my-location.service';

class LocalizeMyCardTool extends ToolBase {
  constructor() {
    super('LocalizeMyCard', ['pin']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MyCardButtonsControllerService {
  private localizeMyCardTool: ToolBase;
  private localizeMeAndMyCardTool: ToggleTool;
  private followMeTool: ToggleTool;

  private isLocalizeMeAndMyCardToggleable: boolean = false;

  private subs: Subscription[] = [];
  private myLocationSub: Subscription;

  constructor(
    private mapTools: MapToolsService,
    private sagaSettings: SagaSettingsService,
    private myCard: MyCardService,
    private myLocationService: MyLocationService,
    private localizePositionService: LocalizePositionService
  ) {}

  init() {
    this.isLocalizeMeAndMyCardToggleable = this.sagaSettings.getValue('SagaMobileWebClient.LocalizeMeAndMyCardToggleable');

    this.createButtons();

    this.subs.push(this.myCard.$change.subscribe(myCard => this.onMyCardChanged(myCard)));

    this.subs.push(
      this.localizeMyCardTool.$event.subscribe(() =>
        this.localizePositionService.localize({ coordinates: [this.myCard.myCard.location.coordinate], target: null })
      )
    );

    if (this.isLocalizeMeAndMyCardToggleable) {
      this.followMeTool = this.mapTools.getTool(ToolName.FollowMe) as ToggleTool;

      this.subs.push(
        this.localizeMeAndMyCardTool.$event.subscribe(tool => {
          if (tool.isActive) {
            if (this.followMeTool.isActive) {
              this.followMeTool.click();
            }
            this.myLocationSub = this.myLocationService.$change.subscribe(coordinate =>
              this.createLocalizeRequestForMeAndMyCard().subscribe(localizeRequest =>
                this.localizePositionService.localize(localizeRequest)
              )
            );
          } else {
            this.myLocationSub?.unsubscribe();
          }
        })
      );

      this.subs.push(
        this.followMeTool.$event.subscribe(tool => {
          if (tool.isActive && this.localizeMeAndMyCardTool.isActive) {
            this.localizeMeAndMyCardTool.click();
          }
        })
      );
    } else {
      this.subs.push(
        this.localizePositionService.listenForLocation(
          this.localizeMeAndMyCardTool.$event.pipe(switchMap(() => this.createLocalizeRequestForMeAndMyCard()))
        )
      );
    }
  }

  private createButtons() {
    const localizeToolsGroup: ToolGroup = this.mapTools.getToolGroups().find(group => group.name == ToolName.LocalizationGroupName);

    this.localizeMyCardTool = new LocalizeMyCardTool();
    localizeToolsGroup.addTool(this.localizeMyCardTool);

    let localizeMeAndMyCardToolImages: string[];
    if (this.isLocalizeMeAndMyCardToggleable) {
      localizeMeAndMyCardToolImages = [
        'assets/icons/localize-icons/person-callcard-off.svg',
        'assets/icons/localize-icons/person-callcard-on.svg'
      ];
    } else {
      localizeMeAndMyCardToolImages = ['assets/icons/localize-icons/person-callcard.svg'];
    }

    this.localizeMeAndMyCardTool = new ToggleTool('LocalizeMeAndMyCard', localizeMeAndMyCardToolImages);
    localizeToolsGroup.addTool(this.localizeMeAndMyCardTool);
  }

  private onMyCardChanged(myCard: CallCard) {
    if (myCard === null) {
      this.mapTools.hideTool(this.localizeMyCardTool);
      this.mapTools.hideTool(this.localizeMeAndMyCardTool);
    } else {
      this.mapTools.showTool(this.localizeMyCardTool);
      this.mapTools.showTool(this.localizeMeAndMyCardTool);
    }
  }

  private createLocalizeRequestForMeAndMyCard(): Observable<ILocalizeRequest> {
    const myCardLocation = this.myCard.myCard.location.coordinate;
    return this.createLocalizeRequestForMyPosition().pipe(tap(localizeRequest => localizeRequest.coordinates.push(myCardLocation)));
  }

  private createLocalizeRequestForMyPosition(zoom: number = -1): Observable<ILocalizeRequest> {
    return this.myLocationService.getMyLocation().pipe(
      map(coordinate => {
        return { coordinates: [coordinate], target: null, zoom };
      })
    );
  }

  uninit() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }
}
