<ion-accordion-group *ngIf="callcard && callcard.callers.length" value="first">
  <ion-accordion toggleIconSlot="start" value="first">
    <ion-item slot="header" color="light">
      <ion-label>
        <strong translate>Mobile.CCaller</strong>
      </ion-label>
    </ion-item>
    <div slot="content">
      <ng-container *ngFor="let caller of callcard.callers">
        <app-callcard-ccaller-card
          *ngIf="showAllCallers || caller.IsFirstCaller"
          [caller]="caller"
          [enableInteraction]="enableInteraction"
        ></app-callcard-ccaller-card>
      </ng-container>
    </div>
  </ion-accordion>
</ion-accordion-group>
