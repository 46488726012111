/**
 * Created by LKonrad on 20.06.2017.
 */
import { EventEmitter, Inject, Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AtomicOperation } from '@models/imported/SagaBase/Defines/AtomicOperation';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@services/logger';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { IRefreshTokenStoreItem } from '@techwan/security';
import { Subscription } from 'rxjs';
import { CacheService } from '../../../cache/cache.service';
import { AppSettingsService, SagaSettingsService } from '../../../settings';
import { StatusBarItem, StatusBarService } from '../../../status-bar/status-bar.service';
import { IPushConfiguration, PUSH_CONFIGURATION_TOKEN } from '../../config/injector';
import { QueuingContext } from './queuing-context/queuing-context';
import { QueuingClientConnectionState } from './queuing-context/queuing-context-state';

// Copied from SampleMessages.SampleMessageCommands, TW.Saga.Core.Messaging @ 21.06.2017
enum SampleMessageCommands {
  Ping = 0,
  Pong = 1,
  BootStrap = 2,
  BootStrapConfirmation = 3,
  KeepAlive = 4,
  SagaMessage = 5,
  CustomMessage = 6,
  SystemMessage = 7,
  Refresh = 8
}

class SampleMessages {
  Command: SampleMessageCommands;
  ops: Array<AtomicOperation>;
  SubCommand: string;
  Label: string;
}

class CustomMessage extends SampleMessages {
  subCommand: string;
  payload: any;
}

export enum ConnectionStatus {
  Expired,
  Established,
  Lost,
  Closed
}

@Injectable({
  providedIn: 'root'
})
export class PushService {
  private statusItem: StatusBarItem;

  private queuingContext: QueuingContext;
  private queuingContextSubs: Subscription[] = [];
  private eventListeners: {} = {};
  private onStatus = new EventEmitter<ConnectionStatus>();
  private _status: ConnectionStatus = ConnectionStatus.Closed;

  get isConnected(): boolean {
    return this.queuingContext && this.queuingContext.isConnected();
  }

  get status(): ConnectionStatus {
    return this._status;
  }

  set status(value: ConnectionStatus) {
    this._status = value;
    let color;

    switch (value) {
      case ConnectionStatus.Closed:
      case ConnectionStatus.Expired:
        color = 'red';
        break;
      case ConnectionStatus.Lost:
        color = 'orange';
        break;
      case ConnectionStatus.Established:
        color = 'lightgreen';
        break;
    }

    if (color) {
      this.statusItem.style.color = color;
    } else {
      this.statusItem.style.color = null;
    }

    this.onStatus.emit(this._status);
  }

  constructor(
    private cache: CacheService,
    private settings: AppSettingsService,
    private alertCtrl: AlertController,
    private zone: NgZone,
    private statusBarService: StatusBarService,
    private sagaSettings: SagaSettingsService,
    @Inject(PUSH_CONFIGURATION_TOKEN)
    private config: IPushConfiguration,
    private refreshTokenStorage: RefreshTokenStorageService,
    private translate: TranslateService
  ) {
    this.statusItem = {
      id: 'push-connection',
      iconName: 'radio-button-on',
      style: {
        color: 'red'
      },
      onlyPrimary: true
    };
    this.statusBarService.addItem(this.statusItem);

    if (!config.isProd()) {
      const settingsEntry: any = {
        text: 'Push',
        type: 'input'
      };

      settingsEntry._value = this.settings.getSync('push', '');

      Object.defineProperty(settingsEntry, 'value', {
        set: value => {
          settingsEntry._value = value;
          this.settings.set('push', value, true);
        },
        get: () => settingsEntry._value
      });

      this.settings.addSettingsEntry(settingsEntry);
    }
  }

  listenPushCustomEvents(messageKey: string, handler: Function) {
    if (!this.eventListeners[messageKey]) {
      this.eventListeners[messageKey] = [];
    }

    this.eventListeners[messageKey].push(handler);
  }

  initialize() {
    if (!this.queuingContext) {
      this.createQueuingContext();
    }

    this.start();
  }

  private createQueuingContext() {
    const pushBaseUrl = `${this.config.getUrl()}/signalr`;
    const messageHandler = (data: any) => {
      if (data.Command !== undefined) {
        const msg: SampleMessages = data as SampleMessages;

        if (msg.Command === SampleMessageCommands.SagaMessage) {
          if (msg.ops && msg.ops.length > 0) {
            setTimeout(() => this.cache.pushMessageReceived(msg.ops));
          }
        } else if (msg.Command === SampleMessageCommands.CustomMessage) {
          const customMsg = msg as CustomMessage;
          const listeners = this.eventListeners[customMsg.subCommand];
          if (listeners) {
            listeners.forEach(handler => handler(customMsg.payload));
          }
        } else if (msg.Command === SampleMessageCommands.SystemMessage) {
          // $rootScope.$broadcast('saga.cache.systemMessage', data);
        } else if (msg.Command === SampleMessageCommands.Refresh) {
          const object = this.cache.getObjectByUid(msg.Label);
          if (object) {
            object.onDataBaseChanged();
          }
        }
      }
    };

    const maxPendingReconnectInSeconds: number = this.sagaSettings.getValue(
      'SagaMobileWebClient.WebSocket.MaximumPendingReconnectInSeconds'
    );
    const retryDelay: number = this.sagaSettings.getValue('SagaMobileWebClient.WebSocket.RetryDelay');

    this.queuingContext = new QueuingContext(pushBaseUrl, 'Push', maxPendingReconnectInSeconds, retryDelay);

    this.queuingContextSubs.push(
      this.queuingContext.state.subscribe(state => {
        this.zone.run(() => {
          switch (state) {
            case QueuingClientConnectionState.Connected:
              this.status = ConnectionStatus.Established;
              break;
            case QueuingClientConnectionState.Reconnecting:
              this.status = ConnectionStatus.Lost;
              break;
            case QueuingClientConnectionState.Disconnected:
              this.status = ConnectionStatus.Closed;
              break;
          }
        });
      })
    );

    this.queuingContextSubs.push(
      this.queuingContext.messages.subscribe((data: any) => {
        this.zone.run(() => {
          try {
            messageHandler(JSON.parse(data.body));
          } catch (ex) {
            Logger.error(`The message could not be deserialized (${ex.message}).`);
          }
        });
      })
    );

    this.queuingContextSubs.push(
      this.queuingContext.queueDeleted.subscribe(() => {
        this.status = ConnectionStatus.Expired;
        this.showSessionExpired();
      })
    );

    this.queuingContextSubs.push(
      this.queuingContext.sessionExpired.subscribe(() => {
        this.status = ConnectionStatus.Expired;

        const refreshToken: IRefreshTokenStoreItem = this.refreshTokenStorage.read();
        if (refreshToken && refreshToken.login && this.isTokenExpired(refreshToken.login.RefreshTokenValidUntilUtc)) {
          this.refreshTokenStorage.clear();
        }
      })
    );

    this.queuingContextSubs.push(
      this.queuingContext.tokenExpired.subscribe(() => {
        this.showSessionExpired();
      })
    );

    this.queuingContextSubs.push(this.queuingContext.onError.subscribe(() => this.onConnectionError()));

    this.queuingContextSubs.push(
      this.refreshTokenStorage.$value.subscribe(() => this.queuingContext.onRefreshTokenChanged(this.refreshTokenStorage.read()))
    );
  }

  private isTokenExpired(refreshTokenValidUntilUtc: string): boolean {
    return new Date(refreshTokenValidUntilUtc) < new Date();
  }

  private showSessionExpired() {
    this.translate.get(['SagaMobile.Push.SessionExpired', 'SagaMobile.Push.Exit']).subscribe(
      translatedKeys => {
        this.alertCtrl
          .create({
            message: translatedKeys['SagaMobile.Push.SessionExpired'],
            buttons: [
              {
                text: translatedKeys['SagaMobile.Push.Exit'],
                handler: () => location.reload()
              }
            ]
          })
          .then(ionAlert => ionAlert.present());
      },
      translateError => {
        Logger.error(translateError, 'PushService');
      }
    );
  }

  private onConnectionError() {
    this.translate.get(['SagaMobile.Push.Disconnected', 'SagaMobile.Push.Continue', 'SagaMobile.Push.Reload']).subscribe(
      translatedKeys => {
        this.alertCtrl
          .create({
            message: translatedKeys['SagaMobile.Push.Disconnected'],
            buttons: [
              {
                text: translatedKeys['SagaMobile.Push.Continue'],
                handler: () => {}
              },
              {
                text: translatedKeys['SagaMobile.Push.Reload'],
                handler: () => location.reload()
              }
            ],
            backdropDismiss: false
          })
          .then(ionAlert => ionAlert.present());
      },
      translateError => {
        Logger.error(translateError, 'PushService');
      }
    );
  }

  private start() {
    if (this.queuingContext) {
      this.zone.runOutsideAngular(() => {
        this.queuingContext.startAsync().subscribe(() => this.queuingContext.registerClientAsync());
      });
    }
  }

  stop(): Promise<void> {
    return new Promise(resolve => {
      this.zone.runOutsideAngular(() => {
        if (this.queuingContext) {
          this.queuingContext
            .stopAsync()
            .toPromise()
            .then(() => resolve());
        } else {
          resolve();
        }
      });
    });
  }

  uninitialize(): Promise<void> {
    if (this.queuingContext) {
      while (this.queuingContextSubs.length > 0) {
        this.queuingContextSubs.pop().unsubscribe();
      }
      return this.stop().finally(() => (this.queuingContext = null));
    } else {
      return Promise.resolve();
    }
  }
}
