import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PanelToolBase } from '../..';
import { Panel } from '../../schema/interfaces/Panel';
import { AdditionalViewContext } from '../../schema/panel-context/additional-view-context';
import { AdditionalViewTools, IPanelChanged } from '../../schema/panel-tools/additional-view-tools';
import { ContentLoaderService } from '../content-loader/content-loader.service';

@Injectable()
export class AdditionalViewToolsService {
  private _additionalViewTools: AdditionalViewTools = new AdditionalViewTools();

  get $change(): Observable<IPanelChanged> {
    return this._additionalViewTools.$change;
  }

  constructor(private _contentLoader: ContentLoaderService) {}

  addTool(panelTool: PanelToolBase) {
    this._additionalViewTools.add(panelTool);
  }

  removeTool(panelTool: PanelToolBase) {
    this._additionalViewTools.remove(panelTool);
  }

  hasTools(groupName?: string): boolean {
    if (!groupName) {
      return this._additionalViewTools.tools.length > 0;
    }
    return this.getTools(groupName).length > 0;
  }

  getTools(groupName?: string): PanelToolBase[] {
    if (!groupName) {
      return this._additionalViewTools.tools;
    }
    return this._additionalViewTools.tools.filter(tool => tool.groupName === groupName);
  }

  onResize() {
    this._additionalViewTools.onResize();
  }

  createContextWith(panel: Panel): AdditionalViewContext {
    const additionalViewContext = new AdditionalViewContext(panel, this._additionalViewTools);
    additionalViewContext.init();
    additionalViewContext.setContentLoader(this._contentLoader);
    return additionalViewContext;
  }
}
