import { Injectable } from '@angular/core';
import { DirectoryEntry, Entry, FileEntry, IFile, LocalFileSystem } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';

interface Window extends LocalFileSystem {}
declare const window: Window;

@Injectable()
export class FileBridgeService {
  constructor(private platform: Platform) {}

  getEntry(url: string): Promise<FileEntry | DirectoryEntry> {
    return this.resolveLocalFileSystemURL(url).then(entry => entry as FileEntry | DirectoryEntry);
  }

  private resolveLocalFileSystemURL(url: string): Promise<Entry> {
    return new Promise((resolve, reject) => window.resolveLocalFileSystemURL(url, resolve, reject));
  }

  getFile(file: FileEntry): Promise<IFile> {
    return new Promise<IFile>((resolve, reject) => file.file((f: IFile) => resolve(f), reject));
  }

  getFileFromDirectory(directoryEntry: DirectoryEntry, fileName: string): Promise<File> {
    return new Promise<any>((resolve, reject) => directoryEntry.getFile(fileName, {}, resolve, reject));
  }

  moveTo(directoryEntry: DirectoryEntry, from: string, newName: string): Promise<Entry> {
    return this.getEntry(from).then(
      fileEntry => new Promise<Entry>((resolve, reject) => fileEntry.moveTo(directoryEntry, newName, resolve, reject))
    );
  }

  remove(fileName: string): Promise<void> {
    return this.getEntry(fileName).then(
      fileEntry => new Promise<void>((resolve, reject) => fileEntry.remove(resolve, reject))
    );
  }

  removeRecursively(directoryName: string): Promise<void> {
    return this.getEntry(directoryName).then(
      (directoryEntry: DirectoryEntry) => new Promise<void>((resolve, reject) => directoryEntry.removeRecursively(resolve, reject))
    );
  }

  getFileFrom(url: string, file: string, create: boolean = false): Promise<FileEntry> {
    return new Promise((resolve, reject) =>
      this.getEntry(url).then((dir: DirectoryEntry) => dir.getFile(file, { create: create, exclusive: false }, resolve, reject))
    );
  }

  readFileAsString(fileEntry: FileEntry): Promise<string> {
    return new Promise((resolve, reject) => {
      fileEntry.file(file => {
        const reader = this.createFileReader();

        reader.onerror = reject;
        reader.onloadend = function(this) {
          return resolve(this.result as string);
        };

        reader.readAsText(file);
      }, reject);
    });
  }

  readFileAsBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const isCordova = this.platform.is('cordova');
      const reader = this.createFileReader();

      const onLoad = isCordova
        ? function(this) {
            return resolve(this.result as ArrayBuffer);
          }
        : fileError => resolve(fileError.target.result);
      reader.onerror = reject;
      reader.onload = onLoad;

      reader.readAsArrayBuffer(file);
    });
  }

  readFileAsDataUrl(fileEntry: FileEntry): Promise<string> {
    return new Promise((resolve, reject) => {
      fileEntry.file(file => {
        const reader = this.createFileReader();

        reader.onerror = reject;
        reader.onloadend = function(this) {
          return resolve(this.result as string);
        };

        reader.readAsDataURL(file);
      }, reject);
    });
  }

  private createFileReader(): FileReader {
    return new FileReader();
  }

  writeFile(entry: FileEntry, content: string | Blob | ArrayBuffer, isAppend: boolean = false): Promise<ProgressEvent> {
    return new Promise((resolve, reject) => {
      entry.createWriter(writer => {
        writer.onwriteend = resolve;
        writer.onerror = reject;
        if (isAppend) {
          writer.seek(writer.length);
        }
        writer.write(content);
      }, reject);
    });
  }
}
