import { Guid } from '@models/guid';
import { FileDescriptor } from '@models/imported/SagaSchema/FileDescriptor';
import { FileUploadItem } from '@techwan/file-upload';

export class SagaFileUploadItem extends FileUploadItem {
  get versionToken(): string {
    return this._fileDescriptor.VersionToken;
  }

  get version(): number {
    return this._fileDescriptor.Version;
  }

  get descriptorName(): string {
    return this._fileDescriptor.Name;
  }

  get url(): string {
    return this._fileDescriptor.Url;
  }

  get parentId(): Guid {
    return this.callcardId;
  }

  constructor(_file: File, private _fileDescriptor: FileDescriptor, private callcardId: Guid) {
    super(_file, _fileDescriptor.ObjGuid);
  }
}
