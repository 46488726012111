import { CardTitleFormatterService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { ResourceCountService } from '../../services/resource-count/resource-count.service';
import { SearchVehicleModalComponent } from '../search-vehicle-modal/search-vehicle-modal.component';

@Component({
  selector: 'app-engagement-setup-modal',
  templateUrl: './engagement-setup-modal.component.html',
  styleUrls: ['./engagement-setup-modal.component.scss']
})
export class EngagementSetupModalComponent implements OnInit {
  @Input()
  callcard: CallCard;

  callcardTitle: string;
  peopleCount: number;
  selectedVehicle: Vehicle;

  constructor(
    private modalController: ModalController,
    private titleFormatter: CardTitleFormatterService,
    private resourceCount: ResourceCountService
  ) {}

  ngOnInit() {
    this.callcardTitle = this.titleFormatter.format(this.callcard);
  }

  onSubmit() {
    if (this.isValid()) {
      this.modalController.dismiss({ peopleCount: this.peopleCount, vehicle: this.selectedVehicle });
    }
  }

  isValid(): boolean {
    return !!this.selectedVehicle && !!this.peopleCount;
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onPeopleCountClicked() {
    this.resourceCount
      .selectEngagedPeople()
      .then(value => (this.peopleCount = value))
      .catch(err => err);
  }

  onAvailableResourceClicked() {
    this.modalController.create({ component: SearchVehicleModalComponent }).then(modal =>
      modal.present().then(() =>
        modal.onDidDismiss().then(result => {
          if (result.data) {
            this.selectedVehicle = result.data;
          }
        })
      )
    );
  }
}
