import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { BehaviorSubject, Observable } from 'rxjs';

export class MyDrawing {
  private _item: CTacticalSituation = null;
  private readonly _event = new BehaviorSubject<CTacticalSituation>(null);

  public get current(): CTacticalSituation {
    let current: CTacticalSituation;
    this._event.subscribe(i => (current = i)).unsubscribe();
    return current;
  }

  public get $change(): Observable<CTacticalSituation> {
    return this._event.asObservable();
  }

  public set item(value: CTacticalSituation) {
    const old = this._item;
    this._item = value;
    if (old !== this._item) {
      this._event.next(this._item);
    }
  }

  public refresh() {
    this._event.next(this._item);
  }
}
