<ion-header *ngIf="previewImg">
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>SagaSchema.CAttachment</span>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="primary" no-border-top>
    <ion-item class="attachment-image-title" color="light">
      <ion-label>Titre</ion-label>
      <ion-input type="text" [(ngModel)]="text"></ion-input>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content class="camera-followup-content">
  <div class="content">
    <div flex>
      <div *ngIf="previewImg" class="camera-wrapper">
        <div class="image-container">
          <img [src]="previewImg" />
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer no-border>
  <div class="image-toolbar">
    <ion-fab-button size="small" (click)="onCancel()"><ion-icon name="arrow-back"></ion-icon></ion-fab-button>
    <ng-container *ngIf="selectedFile">
      <ion-fab-button (click)="onAccept()"><ion-icon name="checkmark"></ion-icon></ion-fab-button>
    </ng-container>
    <ion-fab-button size="small" (click)="onChoose()"><ion-icon name="refresh"></ion-icon></ion-fab-button>
  </div>
</ion-footer>
