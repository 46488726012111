import { CurrentCardService, LocationProviderService, UnitActivityAddinName, UnitActivityViewName } from '@addins/core/core';
import { SettingOpenerService } from '@addins/core/settings';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { IonItemSliding, Platform } from '@ionic/angular';
import { StatusItemAction } from '@models/imported/SagaBase';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { Events } from '@services/events/events.service';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { CompletionHandlerDelegate, ICompletionDelegate } from '@services/initializer/completion-handler-delegate';
import { Initializer } from '@services/initializer/initializer.service';
import { LogoutService } from '@services/logout/logout.service';
import { SideMenuService } from '@services/menu/side-menu.service';
import { NavService } from '@services/navigation/navigation.service';
import { Security } from '@services/security/security.service';
import { StatusService } from '@services/status/status.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { ADDIN_VIEW_EVENT_KEY, AddinView, MyEquipmentService } from '@techwan/ionic-core';
import { HomeBase } from '../../schema/HomeBase';
import { HomePageService } from '../../services/home.service/home.service';
import { MenuService } from '../../services/menu/menu.service';

/**
 * @description
 */
@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  providers: [LogoutService, HomePageService]
})
@AddinView({
  name: 'Home'
})
export class HomeComponent extends HomeBase implements ICompletionDelegate, OnInit {
  status: boolean;
  available: ListResourceStatus;
  actions: ListResourceStatus[] = [];
  booted: boolean = false;
  AppConfig;

  /**
   * Color of sending position button
   */
  color: string = 'primary';

  /**
   * Define layout of the buttons on the home
   */
  readonly buttonsLayout: string;

  private _completion: CompletionHandlerDelegate;

  get unitActivity(): UnitActivity {
    return this.booted && this.security.getData('UnitActivity');
  }

  constructor(
    private menuService: SideMenuService,
    private security: Security,
    private readonly user: LogoutService,
    private nav: NavService,
    private readonly _home: HomePageService,
    protected readonly initializer: Initializer,
    private fetchList: FetchListService,
    private statusService: StatusService,
    private cd: ChangeDetectorRef,
    private platform: Platform,
    private el: ElementRef,
    protected readonly events: Events,
    private readonly _card: CurrentCardService,
    private readonly view: ViewConfigurationService,
    private readonly menu: MenuService,
    private readonly settings: SettingOpenerService,
    protected readonly _positionProvider: LocationProviderService,
    private readonly _me: MyEquipmentService,
    private readonly _viewSelector: ViewSelectorService
  ) {
    super(events, _positionProvider);
    this._completion = new CompletionHandlerDelegate(initializer, this);
    this.buttonsLayout = this.platform.is('tablet') ? 'column' : 'row';
    this.AppConfig = environment.config;
    this.menu.setup();
  }

  actionsFiltered() {
    return this.actions.filter(i => this.canChangeStatus(i));
  }

  public ngOnInit() {
    this._home.setup();
  }

  openMyUnit() {
    this.nav.get().push(this._viewSelector.getView(`${UnitActivityAddinName}/${UnitActivityViewName}`).element);
  }

  openMyCard() {
    this._card.select(this.unitActivity.callcard);
  }

  canChangeStatus(item: ListResourceStatus) {
    // tslint:disable-next-line:no-bitwise
    return this.unitActivity.status.Children & item.Value;
  }

  async changeStatus(item: ListResourceStatus, sliding: IonItemSliding) {
    sliding.close();
    await this.statusService.changeStatus(item, this.unitActivity.callcard, {}, 'SagaMobile.UA.ChangeStatus');
  }

  openMap() {
    this.events.publish(ADDIN_VIEW_EVENT_KEY, this.view.getMapName());
  }

  sendPosition() {
    let uid: string;
    this._me.myDevice.subscribe(d => (uid = d.ObjGuid)).unsubscribe();
    this._positionProvider.running ? this._positionProvider.stop() : this._positionProvider.start(uid);
    this.setColor();
    this.cd.detectChanges();
  }

  isSendingPosition() {
    return this._positionProvider.running;
  }

  showSettings(): Promise<void> {
    return this.settings.open();
  }

  logout() {
    this.user.logout();
  }

  ionViewWillEnter() {
    // Prevent Ionic error while closing side menu
    if (this.el.nativeElement.querySelector('ion-menu-toggle') === null) {
      this.menuService.showMenu = false;
    }
  }

  ionViewWillLeave() {
    this.menuService.showMenu = true;
  }

  $onComplete() {
    this.booted = true;
    this.actions = [
      this.fetchList.getRow(ListResourceStatus, 16), // OnSite
      this.statusService.getStatusByAction(StatusItemAction.isTerminateAction)
    ];
  }

  private setColor() {
    this.color = this._positionProvider.running ? 'danger' : 'primary';
  }
}
