// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class UserAccessReturnArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.UserAccessReturnArgs';
  $t: string = 'SagaBase.Defines.UserAccessReturnArgs';

  public static GetMembers(): string[] {
    return ['Error', 'ErrorMessage', 'User', 'AssignedRoles', 'DeAssignedRoles'];
  }

  public GetMembers(): string[] {
    return UserAccessReturnArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Error: SagaBase.Defines.UserAccessError = SagaBase.Defines.UserAccessError.None;
  public ErrorMessage: string;
  public User: any;
  public AssignedRoles: any[];
  public DeAssignedRoles: any[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
