import { Component, OnInit } from '@angular/core';
import { CustomComponent, ModalButton, MyCardService } from '@addins/core/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent implements OnInit, CustomComponent<string> {
  private _card: CallCard = null;
  public get card(): CallCard {
    return this._card;
  }

  public get useCustomData(): boolean {
    return false;
  }

  constructor(private readonly _myCard: MyCardService) {}

  public ngOnInit() {
    if (this._card === null) {
      this._card = this._myCard.myCard;
    }
  }

  public getResult(button: ModalButton): string {
    return '';
  }
}
