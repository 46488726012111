// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import * as SagaBase from '../../../SagaBase';
import { QueryPart } from '../../../SagaBase/Objects/QueryPart';
import { Guid, GuidTools } from '@models/guid';

export abstract class QueryGroupCondition_abstract extends QueryPart {
  static $t: string = 'SagaBase.Objects.QueryGroupCondition';
  $t: string = 'SagaBase.Objects.QueryGroupCondition';

  public static GetMembers(): string[] {
    return ['GroupLogic', 'Conditions', 'EnableChildClone'].concat(QueryPart.GetMembers());
  }

  public GetMembers(): string[] {
    return QueryGroupCondition_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GroupLogic: SagaBase.Objects.GroupLogicType = SagaBase.Objects.GroupLogicType.choice;
  public Conditions: any[];
  public EnableChildClone: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
