import { Injectable } from '@angular/core';
import { UnitEditor } from '../../schema/UnitEditor';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { MyEquipmentService } from '@techwan/ionic-core';

@Injectable()
export class UnitEditorService {
  private readonly _editor = new UnitEditor();
  public get editor(): UnitEditor {
    return this._editor;
  }

  constructor(private readonly me: MyEquipmentService) {
    const device = new CommunicationEquipment();
    me.myDevice
      .subscribe(d => {
        device.Name = d.Name;
        device.ObjGuid = d.ObjGuid;
      })
      .unsubscribe();
    this._editor.equipments.push(device);
  }

  saveTabActualState(obj: any, index: number) {
    index !== -1 ? (this._editor.tabsStates[index] = obj) : this._editor.tabsStates.push(obj);
  }

  addVehicle(item: Vehicle) {
    this._editor.vehicles.push(item);
  }

  removeVehicle(item: Vehicle) {
    const obj = this._editor.vehicles.find(i => i.ObjGuid === item.ObjGuid);
    this._editor.vehicles.splice(this._editor.vehicles.indexOf(obj), 1);
  }

  addActor(item: Actor) {
    this._editor.actors.push(item);
  }

  removeActor(item: Actor) {
    const obj = this._editor.actors.find(i => i.ObjGuid === item.ObjGuid);
    this._editor.actors.splice(this._editor.actors.indexOf(obj), 1);
  }

  addEquipment(item: CommunicationEquipment) {
    this._editor.equipments.push(item);
  }

  removeEquipment(item: CommunicationEquipment) {
    const obj = this._editor.equipments.find(i => i.ObjGuid === item.ObjGuid);
    this._editor.equipments.splice(this._editor.equipments.indexOf(obj), 1);
  }
}
