import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuEntry } from '@services/menu/entry.schema';
import { MenuState, PermanentEntry } from '../../schema/permanent-entry';
import { PermanentMenuService } from '../permanent-menu/permanent-menu.service';

@Injectable()
export class SideMenuControllerService {
  private readonly _subs: Subscription[] = [];

  private _currentlyActive: MenuEntry = null;

  constructor(private readonly _permanentMenu: PermanentMenuService) {}

  public setup() {
    this._permanentMenu.getEntries().forEach(i => this._subs.push(i.onChanged.subscribe(() => this.onButtonChanged(i))));
    this._subs.push(this._permanentMenu.$clean.subscribe(() => this.cleanUp()));
  }

  private cleanUp() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private onButtonChanged(button: PermanentEntry) {
    if (button.isActive && this._currentlyActive !== button) {
      this._permanentMenu
        .getEntries()
        .filter(i => i !== button)
        .forEach(i => i.setState(MenuState.inactive));
      this._currentlyActive = button;
    }
  }
}
