<ion-list *ngIf="!hasSelectedMessage()">
  <ion-item *ngFor="let message of terminationMessages" (click)="onMessageSelected(message)">{{ message }}</ion-item>
</ion-list>
<ion-card [hidden]="!hasSelectedMessage()">
  <ion-card-header>
    <ion-card-subtitle>
      {{ terminationMessage }}
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-textarea #textArea rows="6" [(ngModel)]="additionalText"></ion-textarea>
  </ion-card-content>
</ion-card>
