import { ViewLoaderService } from '@addins/core/menus';
import { Component, OnInit } from '@angular/core';
import { Task } from '@models/imported/SagaSchema/Task';
import { List } from '@models/list';
import { AddinView } from '@techwan/ionic-core';
import { CurrentTaskService } from '../../services/current-task/current-task.service';
import { TasksProviderService } from '../../services/tasks-provider/tasks-provider.service';
import { PlannedMissionDetailsViewComponent } from '../planned-mission-details-view/planned-mission-details-view.component';

@Component({
  selector: 'app-planned-missions-view',
  templateUrl: './planned-missions-view.component.html',
  styleUrls: ['./planned-missions-view.component.scss']
})
@AddinView({
  name: 'PlannedMissionsList',
  description: 'Display Planned missions list'
})
export class PlannedMissionsViewComponent implements OnInit {
  tasks: List<Task> = null;

  get myTask(): Task {
    return this.tasksProvider.myTask;
  }

  constructor(
    private tasksProvider: TasksProviderService,
    private viewLoader: ViewLoaderService,
    private currentTask: CurrentTaskService
  ) {}

  ngOnInit() {
    this.tasks = this.tasksProvider.tasks;
  }

  openTaskDetails(selectedTask: Task) {
    this.currentTask.select(selectedTask);
    this.viewLoader.show(PlannedMissionDetailsViewComponent);
  }
}
