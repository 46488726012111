// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 13:39:25
export enum ComJobOverviewStatus {
  Incomplete = 1,
  Creation = 2,
  Running = 4,
  Finished = 8,
  Stopped = 16,
  Waiting = 32
}
export enum ComJobOverviewType {
  Test = 1,
  Exercise = 2,
  Real = 3,
  Information = 4,
  PeriodicTesting = 5
}

import * as ListObjects from './ListObjects';
export { ListObjects };

import * as History from './History';
export { History };
