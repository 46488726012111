import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export abstract class FilteredListBase<T> {
  items: T[] = [];

  protected delayTimerSubscription: Subscription = null;
  protected searchDelay: number = 500; // in ms
  protected searchedCharactersThreshold: number = 3;

  search(searchText: string) {
    this.clearPendingSearch();

    if (searchText.length < this.searchedCharactersThreshold) {
      this.items.length = 0;
      return;
    }

    this.delayTimerSubscription = timer(this.searchDelay)
      .pipe(switchMap(() => this.getItems(searchText, 0)))
      .subscribe(queriedItems => this.parseQueryResult(queriedItems, true));
  }

  clearPendingSearch() {
    if (this.delayTimerSubscription !== null) {
      this.delayTimerSubscription.unsubscribe();
      this.delayTimerSubscription = null;
    }
  }

  protected abstract getItems(query: string, offset: number, limit?: number): Observable<T[]>;

  protected parseQueryResult(items: T[], clear: boolean) {
    if (clear) {
      this.items.length = 0;
    }

    items.forEach(item => this.items.push(item));
    this.delayTimerSubscription = null;
  }
}
