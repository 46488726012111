import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CallCardDetailFollowupComponent } from '../callcard-detail-followup/callcard-detail-followup.component';
import { CardPageService } from '@addins/core/singlecallcard/services/card-page/card-page.service';

/**
 * @description
 * A detail view  of a callcard
 */
@Component({
  templateUrl: 'callcard-detail-content.component.html',
  styleUrls: ['./callcard-detail-content.component.scss'],
  selector: 'callcard-detail-content'
})
export class CallcardDetailContentComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild(CallCardDetailFollowupComponent) followup: CallCardDetailFollowupComponent;

  @Input() callcard: CallCard;

  get page(): number {
    return this._pages.getPage(this.callcard) || 0;
  }

  constructor(private _pages: CardPageService) {}

  ngOnInit() {}

  refresh() {
    if (this.followup) {
      this.followup.startRefresh();
    }
  }
}
