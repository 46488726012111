<ion-header>
    <ion-toolbar color="primary">
        <ion-title>
            <span [translate]="reportName"></span> - <span [translate]="slides && getGroupsName()[index]"></span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button slot="icon-only" (click)="dismiss()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
            <ion-button slot="icon-only" (click)="submitData()">
                <ion-icon name="checkmark"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="report-content padding">
    <form [formGroup]="reportForm" style="height: 100%" class="report-form">
        <ion-slides pager="true" #slides [options]="{speed: 400}" (ionSlideDidChange)="slideChange()">
            <ion-slide *ngFor="let groupName of getGroupsName()" [formGroupName]="groupName">
                <ion-list inset>
                    <ion-item *ngFor="let inputName of getInputsOfGroupName(groupName)">
                        <ion-label floating>{{'SagaSchema.CStampParticulars.' + inputName | translate}}</ion-label>
                        <ion-input type="text" [formControlName]="inputName"></ion-input>
                    </ion-item>
                </ion-list>
            </ion-slide>
        </ion-slides>
    </form>
</ion-content>
