import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { JournalItemComponent } from './components/journal-item/journal-item.component';
import { JournalListComponent } from './components/journal-list/journal-list.component';
import { JournalModalComponent } from './components/journal-modal/journal-modal.component';
import { CallcardStampsService } from './services/callcard-stamps/callcard-stamps.service';
import { JournalCardButtonService } from './services/executables/journal-card-button/journal-card-button.service';
import { JournalToolsProviderService } from './services/journal-tools-provider/journal-tools-provider.service';
import { StampNameProviderService } from './services/stamp-name-provider/stamp-name-provider.service';
import { StampTextProviderService } from './services/stamp-text-provider/stamp-text-provider.service';
import { StampsProxyService } from './services/stamps-proxy/stamps-proxy.service';

const views = [JournalModalComponent, JournalListComponent, JournalItemComponent];

const executables = [JournalCardButtonService];

const services = [
  CallcardStampsService,
  StampsProxyService,
  StampNameProviderService,
  StampTextProviderService,
  JournalToolsProviderService
];

const providers = [...executables, ...services];

const components = [...views];

const directives = [];

@Addin({
  name: 'SingleCallcardJournal'
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule, IonicModule, SagaPipeModule, TranslateModule]
})
export class SingleCallcardJournalModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
