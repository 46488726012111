// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:40
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class SagaSystemMessage_abstract extends BaseObject {
  static $t: string = 'SagaBase.Objects.SagaSystemMessage';
  $t: string = 'SagaBase.Objects.SagaSystemMessage';

  public static GetMembers(): string[] {
    return ['CreationDate', 'MessageDescription'];
  }

  public GetMembers(): string[] {
    return SagaSystemMessage_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.CreationDate = this.CreationDate && new Date(this.CreationDate);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CreationDate: Date = new Date();
  public MessageDescription: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
