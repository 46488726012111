// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmInfo_abstract extends PersistentObject {
  static $t: string = 'SagaBase.AlarmInfo';
  $t: string = 'SagaBase.AlarmInfo';

  public static GetMembers(): string[] {
    return [
      'Owner',
      'TransmitterX',
      'TransmitterY',
      'TransmitterUId',
      'TransmitterId',
      'TransmitterIdentifier',
      'TransmitterName',
      'TransmitterTypeId',
      'TransmitterTypeName',
      'TransmitterTypeAbbreviation',
      'TransmitterStatus',
      'TransmitterGeoLocationTypeId',
      'TransmitterLocationId',
      'TransmitterGeoLocationId',
      'TransmitterLocationText',
      'TransmitterLocationXML',
      'TransmitterLocationComplement',
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'GeoLocationSearchLabel',
      'TransmitterLocationTypeId',
      'TransmitterLocationMiscData',
      'TansmitterCityName',
      'TansmitterZip',
      'TansmitterStreetNumber',
      'TansmitterPlaceName',
      'DetectorUId',
      'DetectorId',
      'DetectorIdentifier',
      'DetectorTypeId',
      'DetectorTypeName',
      'DetectorInfo',
      'DetectorStatus',
      'FileUId',
      'FileId',
      'FileIdentifier',
      'FileName',
      'FileURL',
      'FileInfo',
      'EventId',
      'Priority',
      'NodeTag',
      'NodeType',
      'EventText',
      'DispositionCode',
      'IsExternalAdress',
      'KeysBunch',
      'KeysMandatory'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmInfo_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Owner: number;
  public TransmitterX: number = 0;
  public TransmitterY: number = 0;
  public TransmitterUId: Guid = GuidTools.Empty;
  public TransmitterId: number = 0;
  public TransmitterIdentifier: string;
  public TransmitterName: string;
  public TransmitterTypeId: number = 0;
  public TransmitterTypeName: string;
  public TransmitterTypeAbbreviation: string;
  public TransmitterStatus: number = 0;
  public TransmitterGeoLocationTypeId: number;
  public TransmitterLocationId: number;
  public TransmitterGeoLocationId: number;
  public TransmitterLocationText: string;
  public TransmitterLocationXML: string;
  public TransmitterLocationComplement: string;
  public AliasName: string;
  public Area1Name: string;
  public Area2Name: string;
  public Area3Name: string;
  public GeoLocationSearchLabel: string;
  public TransmitterLocationTypeId: number;
  public TransmitterLocationMiscData: string;
  public TansmitterCityName: string;
  public TansmitterZip: string;
  public TansmitterStreetNumber: string;
  public TansmitterPlaceName: string;
  public DetectorUId: Guid = GuidTools.Empty;
  public DetectorId: number = 0;
  public DetectorIdentifier: string;
  public DetectorTypeId: number = 0;
  public DetectorTypeName: string;
  public DetectorInfo: string;
  public DetectorStatus: number = 0;
  public FileUId: Guid = GuidTools.Empty;
  public FileId: number = 0;
  public FileIdentifier: string;
  public FileName: string;
  public FileURL: string;
  public FileInfo: string;
  public EventId: number = 0;
  public Priority: number = 0;
  public NodeTag: string;
  public NodeType: SagaBase.EventNodeType = SagaBase.EventNodeType.TerminalNode;
  public EventText: string = '';
  public DispositionCode: number = 0;
  public IsExternalAdress: boolean = false;
  public KeysBunch: boolean = false;
  public KeysMandatory: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
