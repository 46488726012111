import { Injectable } from '@angular/core';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CStampUnitActivity } from '@models/imported/SagaSchema/CStampUnitActivity';
import { filter } from 'rxjs/operators';
import { List } from '@models/list';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Guid } from '@models/guid';

@Injectable()
export class LastDestinationService {
  private _list: List<CStampUnitActivity> = null;

  private readonly _current = new BehaviorSubject<CStampUnitActivity>(null);
  get $current(): Observable<CStampUnitActivity> {
    return this._current.asObservable();
  }

  private _mcaCallcardId: Guid = null;

  constructor(private _myUnit: MyUnitService, private _cache: CacheService) {}

  setup() {
    if (this._cache.isReady()) {
      this.readFromCache();
    } else {
      let cacheStateSub: Subscription = this._cache.state.pipe(filter(cacheState => cacheState === CacheState.ready)).subscribe(() => {
        this.readFromCache();

        cacheStateSub.unsubscribe();
        cacheStateSub = null;
      });
    }
  }

  private readFromCache() {
    const stampUnitActivites = (this._list = this._cache.getListOf<CStampUnitActivity>(CStampUnitActivity));

    // Prepare the list
    stampUnitActivites.addFilter(stampUnitActivity => this._myUnit.myCardId === stampUnitActivity.CallCardId);
    stampUnitActivites.addSort((a, b) => b.CreatedUTC.getTime() - a.CreatedUTC.getTime());

    // Put the currently selected object to the correct value.
    this.initCurrent();

    // Ensure that the currently selected item will always be reflecting the real selection.
    stampUnitActivites.onRefresh.subscribe(() => this.initCurrent());
    this._myUnit.$change.pipe(filter(() => this._mcaCallcardId !== this._myUnit.myCardId)).subscribe(() => this.refresh());
  }

  private initCurrent() {
    const selectedDestination = this._list.items.find(item => item.Text && item.Text !== '');
    this._current.next(selectedDestination ? selectedDestination : null);
  }

  private refresh() {
    const callcardId = (this._myUnit && this._myUnit.myCardId) || null;
    if (callcardId !== null && callcardId !== this._mcaCallcardId) {
      this._mcaCallcardId = callcardId;
      this._list.refresh();
    }
  }
}
