import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ModalController } from '@ionic/angular';
import { EventService } from '@services/event/event';
import { ModalService } from '@services/modal/modal.service';
import { SagaToastController } from '@services/overrides/SagaToast';
import { Security } from '@services/security/security.service';
import { Tool, ToolsProviderService } from '@services/tools';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { CoordinatePickerComponent } from '../../components/coordinate-picker/coordinate-picker.component';

/**
 * @name PickerService
 */
@AddinExecute({
  name: 'Picker',
  description: 'Add a picker listener'
})
@Injectable()
export class PickerService extends Execute {
  pickCoordinate: Tool;
  private unsubscriber: Subscription = null;

  constructor(
    private eventService: EventService,
    private modal: ModalService,
    private toolsProvider: ToolsProviderService,
    private modalCtrl: ModalController,
    private toastCtrl: SagaToastController,
    private security: Security
  ) {
    super();
  }

  /**
   * @override
   */
  public init() {
    this.pickCoordinate = Tool.createTool({
      text: 'SagaMobile.PickCoordinate',
      icon: 'pin',
      execute: async context => {
        let coordinate = context[0];
        await this.modal.presentLoading('SagaMobile.Georeverse.Pending');
        let response = await this.eventService.getLocationAddress(coordinate);
        await this.modal.dismissLoading();
        if (response) {
          // TODO: @deprecated please use ModalFactoryService instead
          this.modalCtrl
            .create({
              component: CoordinatePickerComponent,
              componentProps: response
            })
            .then(m => m.present());
        } else {
          this.toastCtrl
            .create({
              message: 'SagaMobile.Georeverse.NoLocation',
              duration: 3000
            })
            .then(t => t.present());
        }
      }
    });
  }

  /**
   * @override
   */
  public execute(): void {
    if (canExecute()) {
      this.unsubscriber = this.toolsProvider.register(provider => {
        let coordinate = provider.context[0];
        if (this.security.getData('UnitActivity').callcard && coordinate && coordinate.x && coordinate.y && coordinate.epsg) {
          provider.addTool(this.pickCoordinate);
        }
      });
    }
  }

  /**
   * @override
   */
  public destroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.unsubscribe();
      this.unsubscriber = null;
    }
  }
}

function canExecute(): boolean {
  return (
    environment && environment.addinParameters && environment.addinParameters.map && environment.addinParameters.map.provideCoordinatePicker
  );
}
