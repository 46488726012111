import { Injectable } from '@angular/core';
import { UnitEditor } from '../../schema/UnitEditor';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface CreateUnitRequest {
  vehicles: string[];
  actors: string[];
  equipments: string[];

  unitName: string;

  mainResource?: string;
  leader?: string;
  communications?: string;
  position?: string;
}

@Injectable()
export class UnitCreationService {
  constructor(private readonly _http: HttpClient) {}

  public create(editor: UnitEditor): Observable<boolean> {
    const data: CreateUnitRequest = {
      vehicles: editor.vehicles.map(v => v.ObjGuid),
      actors: editor.actors.map(i => i.ObjGuid),
      equipments: editor.equipments.map(i => i.ObjGuid),
      unitName: editor.name
    };
    if (editor.mainResource !== null) {
      data.mainResource = editor.mainResource.ObjGuid;
    }
    if (editor.agent !== null) {
      data.leader = editor.agent.ObjGuid;
    }
    if (editor.communications !== null) {
      data.communications = editor.communications.ObjGuid;
    }
    if (editor.position !== null) {
      data.position = editor.position.ObjGuid;
    }
    return this._http
      .post<string>('api/UnitActivity/Create', data, { responseType: 'text' as 'json' })
      .pipe(map(res => res === '"OK"'));
  }
}
