import { Injectable } from '@angular/core';
import { IDocument, IDocumentGroup, DocumentType } from '../../interfaces';
import { AppSettingsService } from '@services/settings';
import { from, Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { DocumentProxyService } from '../document-proxy/document-proxy.service';

@Injectable()
export class DocumentService {
  private readonly _documents: string[] = [];

  private _baseUrl: string;

  private _ready = false;

  private readonly ready: Observable<void>;

  constructor(private readonly _documentProxyService: DocumentProxyService, private readonly settings: AppSettingsService) {
    this.ready = from(this.settings.apiBaseUrl()).pipe(
      map(base => {
        this._baseUrl = base + 'documents/';
        this._baseUrl && (this._baseUrl += this._baseUrl.lastIndexOf('/') !== this._baseUrl.length - 1 ? '/' : '');
        this._ready = true;
      })
    );
  }

  public get(): Observable<string[] | any> {
    const commError = !this._ready;
    const ret = !this._ready
      ? this.ready.pipe(concatMap(() => this._documentProxyService.getDocuments()))
      : this._documentProxyService.getDocuments();

    return ret.pipe(
      tap(res => res.forEach(i => this._documents.push(i))),
      catchError(e => this.handleError(commError, e))
    );
  }

  public getGroups(names: string[]): IDocumentGroup[] {
    const _groups: IDocumentGroup[] = [];

    names.sort().forEach((name: string) => {
      const l = name.charAt(0).toLowerCase();
      const group = _groups.find(g => g.name === l) || { name: l, documents: [] };

      if (_groups.indexOf(group) === -1) {
        _groups.push(group);
      }
      group.documents.push(this.createDocument(name));
    });

    // Sort groups in alphabetic order
    _groups.sort((a, b) => a.name.localeCompare(b.name));

    return _groups;
  }

  private handleError(communicationError: boolean, error: any): any {
    const e = error;
    if (communicationError) {
      error = {
        error: this.getErrorMessage(e),
        message: e.message,
        type: 'Mobile.ServerError'
      };
    }

    return throwError(error);
  }

  private getErrorMessage(error): string {
    switch (error.status) {
      case 404:
        return 'Not found';
      case 500:
        return 'Internal server error';
    }
  }

  private createDocument(name: string): IDocument {
    const doc: IDocument = {
      name,
      loading: false,
      type: DocumentType.EXTERNAL,
      url: this._baseUrl + name
    };

    this.setIcon(doc);

    return doc;
  }

  private setIcon(doc: IDocument) {
    const parts = doc.name.split('.');
    switch (parts[parts.length - 1]) {
      case 'pdf':
        doc.iconClass = 'fa fa-file-pdf-o';
        break;

      case 'png':
        doc.iconName = 'image';
        break;

      case 'mov':
      case 'mp4':
      case 'mpg':
        doc.iconName = 'camera';
        break;

      default:
        doc.iconClass = 'fa fa-globe';
        break;
    }
  }
}
