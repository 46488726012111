// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CStampUnitActivity_abstract } from './../abstract/SagaSchema/CStampUnitActivity_abstract';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { UnitActivityStampType } from '@models/imported/SagaBase';
import { Color } from '@models/color';

export class CStampUnitActivity extends CStampUnitActivity_abstract {
  private static deferredColor: Color = new Color('pink');
  protected _status: ListResourceStatus;

  static getIconByType(stampType: UnitActivityStampType): string {
    switch (stampType) {
      case UnitActivityStampType.ResourceLeader:
        return 'alert';
      default:
        return 'people';
    }
  }

  get icon(): string {
    return CStampUnitActivity.getIconByType(this.StampType);
  }

  get iconStyle(): any {
    return {
      color: this.color && this.color.toRgbString()
    };
  }

  get color() {
    switch (this.StampType) {
      case UnitActivityStampType.Status:
        return this._status && this._status.color;
      case UnitActivityStampType.DeferredAssign:
        return CStampUnitActivity.deferredColor;
    }
  }

  get title() {
    switch (this.StampType) {
      case UnitActivityStampType.Status:
        return this._status && this._status.Name;
      default:
        return 'UnitActivityStampType.' + UnitActivityStampType[this.StampType];
    }
  }

  changed(change: CacheChangedInterface) {
    super.changed(change);

    this._status = change.fetchList.getRow(ListResourceStatus, this.Status);
  }
}
