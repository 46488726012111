// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import * as SagaBase from '../../SagaBase';
import { ProcedureActionContextConfigurationBase } from '../../SagaActionLib/ProcedureActionContextConfigurationBase';
import * as SagaActionLib from '../../SagaActionLib';
import { Guid, GuidTools } from '@models/guid';

export abstract class ProcedureActionContext_abstract extends ProcedureActionContextConfigurationBase {
  static $t: string = 'SagaActionLib.ProcedureActionContext';
  $t: string = 'SagaActionLib.ProcedureActionContext';

  public static GetMembers(): string[] {
    return [
      'CurrentCard',
      'CurrentUser',
      'StepGuid',
      'StepGroup',
      'SelectionList',
      'SelectedList',
      'Message',
      'ActionCondition',
      'ItemSelect',
      'DeselectExecutedItems',
      'Ordering',
      'DialogId',
      'FilterOptions',
      'ListUnsectorized',
      'DeploymentPointUid',
      'DeploymentPointName'
    ].concat(ProcedureActionContextConfigurationBase.GetMembers());
  }

  public GetMembers(): string[] {
    return ProcedureActionContext_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CurrentCard: any;
  public CurrentUser: any;
  public StepGuid: Guid = GuidTools.Empty;
  public StepGroup: number;
  public SelectionList: any[];
  public SelectedList: any[];
  public Message: any;
  public ActionCondition: any;
  public ItemSelect: boolean = false;
  public DeselectExecutedItems: boolean = true;
  public Ordering: SagaBase.OrderingCategory = SagaBase.OrderingCategory.ByDistance;
  public DialogId: number;
  public FilterOptions: number = 0;
  public ListUnsectorized: boolean = false;
  public DeploymentPointUid: Guid = GuidTools.Empty;
  public DeploymentPointName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
