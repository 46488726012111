import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { ListItem } from '../../schema/list-item';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { IVirtualItem } from '../../schema/virtual-item';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ListCheckBoxItemEvent<T> {
  event: CustomEvent;
  item: ListItem<T>;
}

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent<T extends SagaObject> implements IVirtualItem<ListItem<T>> {
  @Input()
  readonly item: ListItem<T>;

  @Output()
  readonly $change = new EventEmitter<ListCheckBoxItemEvent<T>>();

  get label(): string {
    const item = this.item.item;
    if (item instanceof Vehicle) {
      return item.VehicleCode;
    } else if (item instanceof Actor) {
      return `${item.Name} ${item.RegistrationNumber}`;
    } else if (item instanceof CommunicationEquipment) {
      return item.Name;
    }
    return '';
  }

  get id(): string {
    return this.item.item.ObjGuid;
  }

  private readonly _height = new BehaviorSubject<number>(0);
  get $height(): Observable<number> {
    return this._height.asObservable();
  }

  constructor() {}

  select(event: CustomEvent) {
    this.$change.emit({ event, item: this.item });
  }
}
