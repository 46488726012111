import { AddInteraction, DeploymentPointsLayer, MoveInteraction, PopupProviderService, RemoveInteraction } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { MapToolsPanel } from '@techwan/map-tools';
import { DefaultsStyle, Feature, GeometryType, Image, Map as SagaMap, Style } from '@techwan/mapping';
import { DeploymentFeatureFactoryService } from '../../../core/map/services/deployment-feature-factory/deployment-feature-factory.service';
import { DeploymentManager } from '../../schema/deployment-manager';
import { DeploymentOperationType } from '../../schema/deployment-operation-type';
import { IDeploymentDataProvider } from '../../schema/interfaces/IDeploymentDataProvider';
import { IDeploymentOperation } from '../../schema/interfaces/IDeploymentOperation';
import { DeploymentPanelGroupService } from '../deployment-panel-group/deployment-panel-group.service';
import { DeploymentParsingService } from '../deployment-parsing/deployment-parsing.service';
import { DeploymentProxyService } from '../deployment-proxy/deployment-proxy.service';

@Injectable()
export class DeploymentManagerService implements IDeploymentDataProvider {
  private readonly CONFIRM_TITLE: string = 'Mobile.Deployment.ConfirmTitle';

  private _deploymentManager: DeploymentManager = null;

  constructor(
    private _translatedAlert: TranslatedAlertService,
    private _deploymentParser: DeploymentParsingService,
    private _deploymentPanelGroup: DeploymentPanelGroupService,
    private _deploymentProxy: DeploymentProxyService,
    private _deploymentFeatureFactory: DeploymentFeatureFactoryService,
    private _popupProvider: PopupProviderService
  ) {}

  setup(map: SagaMap, panel: MapToolsPanel) {
    const group = this._deploymentPanelGroup.createPanelGroup(panel);
    this._deploymentFeatureFactory.setup().subscribe(() => {
      this._deploymentManager = new DeploymentManager(map, group, this);
      this._deploymentManager.setup();
      this._deploymentManager.$event.subscribe(deploymentOperation => this.onDeploymentOperation(deploymentOperation));
      this._deploymentManager.$edition.subscribe(e => this.onEdition(e));
    });
  }

  private onDeploymentOperation(deploymentOperation: IDeploymentOperation) {
    switch (deploymentOperation.type) {
      case DeploymentOperationType.update:
        this._deploymentProxy.save(this._deploymentParser.forUpdate(deploymentOperation.feature)).subscribe();
        break;

      case DeploymentOperationType.add:
        this._deploymentProxy.save(this._deploymentParser.forAdd(deploymentOperation.feature)).subscribe();
        break;

      case DeploymentOperationType.remove:
        this._deploymentProxy.remove(this._deploymentParser.forRemoval(deploymentOperation.feature)).subscribe();
        break;
    }
  }

  destroy() {
    if (this._deploymentManager !== null) {
      this._deploymentManager.destroy();
      this._deploymentManager = null;
    }
  }

  createLayer(): DeploymentPointsLayer {
    return new DeploymentPointsLayer();
  }

  createAddInteraction(layer: DeploymentPointsLayer): AddInteraction {
    return new AddInteraction({
      source: layer.getSource(),
      style: this.imageStyle(),
      type: Image.DRAWING_GEOMETRY_TYPE,
      activeGeometry: GeometryType.IMAGE,
      featureFunction: (geometry, map) => this._deploymentFeatureFactory.fromGeometry(geometry, map)
    });
  }

  private imageStyle(): Style {
    return Feature.buildStyles({
      fillColor: DefaultsStyle.fillColor,
      strokeColor: DefaultsStyle.strokeColor,
      lineDash: DefaultsStyle.lineDashStyle
    }).pop();
  }

  createRemoveInteraction(): RemoveInteraction {
    return new RemoveInteraction(this.isDeploymentPoint);
  }

  createMoveInteraction(): MoveInteraction {
    return new MoveInteraction(this.isDeploymentPoint);
  }

  private isDeploymentPoint(feature: ol.Feature): boolean {
    const isDeploymentPoint = feature.get('isDeploymentPoint');
    return isDeploymentPoint && true;
  }

  confirmAdd(): Promise<boolean> {
    return this._translatedAlert.show({
      header: this.CONFIRM_TITLE,
      message: 'Mobile.Deployment.ConfirmAdd'
    });
  }

  confirmRemoval(): Promise<boolean> {
    return this._translatedAlert.show({
      header: this.CONFIRM_TITLE,
      message: 'Mobile.Deployment.ConfirmRemove'
    });
  }

  confirmMove(): Promise<boolean> {
    return this._translatedAlert.show({
      header: this.CONFIRM_TITLE,
      message: 'Mobile.Deployment.ConfirmMove'
    });
  }

  private onEdition(edition: boolean): void {
    if (edition) {
      this._popupProvider.disable();
    } else {
      this._popupProvider.enable();
    }
  }
}
