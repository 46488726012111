import { Map as SagaMap } from '@techwan/mapping';

export enum MapInstanceEventType {
  added,
  removed,
  changed
}

export interface MapInstanceEvent {
  name: string;
  type: MapInstanceEventType;
  target: SagaMap;
  old?: SagaMap;
}
