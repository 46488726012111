// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CommTarget_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.CommTarget';
  $t: string = 'SagaBase.Defines.CommTarget';

  public static GetMembers(): string[] {
    return ['Number', 'Uid', 'ServiceUId', 'ServiceName', 'ProcStepUid', 'Name', 'Protocol'];
  }

  public GetMembers(): string[] {
    return CommTarget_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Number: string;
  public Uid: Guid = GuidTools.Empty;
  public ServiceUId: Guid = GuidTools.Empty;
  public ServiceName: string;
  public ProcStepUid: Guid = GuidTools.Empty;
  public Name: string;
  public Protocol: SagaBase.Defines.ComProtocol = SagaBase.Defines.ComProtocol.None;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
