import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { AuthenticationService, MyEquipmentService, MyEquipmentState } from '@techwan/ionic-core';
import { LoginEvent, LoginException } from '@techwan/security';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class LoginStateService {
  private _eqListener: Subscription = null;

  private _error: LoginException | HttpErrorResponse = null;
  get error(): LoginException | HttpErrorResponse {
    return this._error;
  }

  private _text = new BehaviorSubject<string>('');
  get text(): Observable<string> {
    return this._text.asObservable();
  }

  constructor(
    private auth: AuthenticationService,
    private myEquipment: MyEquipmentService,
    private claims: RefreshTokenStorageService,
    private translatedAlert: TranslatedAlertService
  ) {}

  start(): void {
    this.auth.onStateChange.pipe(filter(e => e !== LoginEvent.erroneous)).subscribe(e => this.$onStateChanged(e));
    this.auth.onLoginError.subscribe(e => this.$onError(e));
  }

  private $onError(error: LoginException | HttpErrorResponse) {
    this._error = error;
    this.$onStateChanged(LoginEvent.erroneous);
  }

  private $onStateChanged(loginEvent: LoginEvent) {
    if (loginEvent === LoginEvent.authenticated && this._eqListener === null) {
      this._eqListener = this.myEquipment.stateChanged.subscribe(state => this.$onDeviceStateChanged(state));
    }

    if (loginEvent === LoginEvent.erroneous) {
      this.translatedAlert
        .show({
          message: 'SagaMobile.Login.ErroneousMessage',
          buttons: [
            {
              text: 'SagaMobile.Login.ErroneousButton',
              value: true
            }
          ]
        })
        .then(() => {
          location.reload();
        });
    }

    this._text.next(`LoginEvent.${LoginEvent[loginEvent]}`);
  }

  private $onDeviceStateChanged(d: MyEquipmentState) {
    if (d === MyEquipmentState.erroneous) {
      this._error = this.myEquipment.lastError;
      if (this._error && (this._error.status === 401 || (this._error.status === 400 && this._error.error === 'MissingResResourceUid'))) {
        this.claims.clear();
      }
    }
    this._text.next(`MyEquipmentState.${MyEquipmentState[d]}`);
  }
}
