import { EventFormatterService } from '@addins/core/core';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GuidTools } from '@models/guid';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { SagaSettingsService } from '@services/settings';
import { MyEquipmentService } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent implements OnInit, OnDestroy {
  private callCard: CallCard = null;
  private headerFormat: string = null;

  private subs: Subscription[] = [];

  private _unit: UnitActivity = null;
  get unit() {
    return this._unit;
  }

  unitStatusName: string = null;
  unitStatusFontSize: string = null;

  showEventName: boolean = false;
  get eventName() {
    return this.eventFormatter.format(this.callCard.event, true);
  }

  constructor(
    private myEquipmentService: MyEquipmentService,
    private changeDetector: ChangeDetectorRef,
    private cacheService: CacheService,
    private settingsService: SagaSettingsService,
    private eventFormatter: EventFormatterService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.cacheService.state.pipe(filter(cacheState => cacheState === CacheState.ready)).subscribe(() => this.onCacheReady())
    );
  }

  private onCacheReady() {
    this.clear();

    this.headerFormat = this.settingsService.getValue('SagaMobileWebClient.HeaderTitle.FormatEnum') || 'Default';
    this.unitStatusFontSize = this.settingsService.getValue('SagaMobileWebClient.HeaderStatusTextSize');

    // Due to the fact that myUnit is a BehaviorSubject, subscription is immediately called.
    this.subs.push(
      this.myEquipmentService.myUnit.subscribe(unit =>
        this.refreshUnit(unit !== null ? this.cacheService.getObjectByUid(unit.ObjGuid) : unit)
      )
    );

    // Refresh the unit's card when required
    this.subs.push(this._unit.$changed.subscribe(() => this.onUnitUpdate()));
  }

  private clear() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }

  private refreshUnit(unitActivity?: UnitActivity) {
    this._unit = unitActivity;
    if (this._unit === null) {
      this.callCard = null;
      this.clear();
      this.changeDetector.detectChanges();
    } else {
      this.onUnitUpdate();
    }
  }

  private onUnitUpdate() {
    this.updateCallCard();

    this.unitStatusName = `Mobile.${this.unit.status.Name}`;
    this.showEventName = this.headerFormat === 'Default' && this.callCard !== null;
  }

  private updateCallCard() {
    if (GuidTools.isValid(this._unit.CallCardID)) {
      const isDifferentCallcard: boolean = this.callCard === null || this.callCard.ObjGuid !== this._unit.CallCardID;
      if (isDifferentCallcard) {
        this.callCard = this.cacheService.getObjectByUid(this._unit.CallCardID);
      }
    } else {
      this.callCard = null;
    }
  }

  ngOnDestroy() {
    this.clear();
  }
}
