import { Injectable } from '@angular/core';
import { UploadCacheService, UploadManagerService } from '@techwan/file-upload';
import { AccessMenuTool } from '../../../schema/menu/access-menu-tool';
import { MenuAccessItemService } from '../menu-access-item/menu-access-item.service';

@Injectable()
export class UploadMenuAccessItemService extends MenuAccessItemService {
  constructor(private uploadCache: UploadCacheService, private uploadManager: UploadManagerService) {
    super();
  }

  setup(item: AccessMenuTool) {
    this.cleanup();
    this._entry = item || null;
    this._sub = this.uploadManager.$event.subscribe(() => this.onUploadEvent());
  }

  private onUploadEvent() {
    const count = this.uploadCache.getUploadItemsCount();
    this._entry.setCounter(count);

    if (count === 0) {
      this._entry.disable();
    } else {
      this._entry.enable();
    }
  }
}
