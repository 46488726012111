// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:30:54
import * as SagaBase from '../../../SagaBase';
import { CPatientBase } from '../../../SagaSchema/CPatientBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CPatientHistory_abstract extends CPatientBase {
  static $t: string = 'SagaSchema.History.CPatientHistory';
  $t: string = 'SagaSchema.History.CPatientHistory';

  public static GetMembers(): string[] {
    return ['StampOperation', 'SourceUid', 'SourceIdentifier', 'CoordX', 'CoordY'].concat(CPatientBase.GetMembers());
  }

  public GetMembers(): string[] {
    return CPatientHistory_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.CPatientLocation', 'SagaSchema.CPatientDestinationLocation'];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public StampOperation: SagaBase.Interfaces.StampProviderOperation = SagaBase.Interfaces.StampProviderOperation.Add;
  public SourceUid: Guid = GuidTools.Empty;
  public SourceIdentifier: string;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
