import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { ViewBaseComponent } from '../../components/view-base/view-base.component';
import { DocumentService } from '../../services/document/document.service';

/**
 * @description
 * A list of available documents
 */
@Component({
  templateUrl: 'page-view.component.html',
  styleUrls: ['page-view.component.scss']
})
@AddinView({
  name: 'DocumentsList',
  title: 'Mobile.Documents',
  description: 'Used to show documents list',
  icon: 'Mobile.Images.HomeDocuments',
  position: 500
})
export class PageViewComponent extends ViewBaseComponent implements OnInit, OnDestroy {
  constructor(_documentService: DocumentService) {
    super(_documentService);
  }
}
