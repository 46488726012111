import { Component, Input, OnInit, Optional } from '@angular/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Platform } from '@ionic/angular';
import { CCaller } from '@models/imported/SagaSchema/CCaller';
import { Security } from '@services/security/security.service';
import { SagaSettingsService } from '@services/settings';

@Component({
  selector: 'app-callcard-ccaller-card',
  templateUrl: './callcard-ccaller-card.component.html',
  styleUrls: ['./callcard-ccaller-card.component.scss']
})
export class CallcardCcallerCardComponent implements OnInit {
  @Input() enableInteraction: boolean = true;
  @Input() caller: CCaller;

  showIsFirstCaller: boolean = true;

  constructor(
    private platform: Platform,
    private security: Security,
    private sagaSettings: SagaSettingsService,
    @Optional() private callNumber?: CallNumber
  ) {}

  ngOnInit(): void {
    this.showIsFirstCaller = this.sagaSettings.getValue('SagaMobileWebClient.ShowIsFirstCaller');
  }

  icon(collapsed: boolean) {
    return collapsed ? 'add-outline' : 'remove-outline';
  }

  /**
   * Call a phone number via the native
   */
  async call(phoneNumber: string) {
    if (this.enableInteraction && phoneNumber) {
      if (this.platform.is('cordova')) {
        try {
          await this.security.permission(this.security.PERSMISSION.CALL_PHONE);
          this.callNumber
            .callNumber(phoneNumber, true)
            .then(() => console.log('Launched dialer!'))
            .catch(() => console.log('Error launching dialer'));
        } catch (e) {
          window.location.href = `tel: ${phoneNumber}`;
        }
      } else {
        window.location.href = `tel: ${phoneNumber}`;
      }
    }
  }
}
