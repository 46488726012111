// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @8/30/2021 2:23:07 PM
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CLocationBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CLocationBase';
  $t: string = 'SagaSchema.CLocationBase';

  public static GetMembers(): string[] {
    return [
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'CallCardId',
      'GeoLocationText',
      'GeoLocationXML',
      'GeoLocationId',
      'GeoLocationType',
      'GeoLocationSearchLabel',
      'Normalized',
      'CenterX',
      'CenterY',
      'CityName',
      'Complement',
      'Narrative',
      'PlaceName',
      'PlaceNameX',
      'StreetNumber',
      'TypeId',
      'LocationMiscData',
      'Zip',
      'GeoSectorInvalidated',
      'GeoSectorId',
      'GeoSectorName',
      'TraceState',
      'TraceId',
      'CoordEpsgCode'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CLocationBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public AliasName: string = '';
  public Area1Name: string = '';
  public Area2Name: string = '';
  public Area3Name: string;
  public CallCardId: Guid = GuidTools.Empty;
  public GeoLocationText: string;
  public GeoLocationXML: string;
  public GeoLocationId: number;
  public GeoLocationType: number;
  public GeoLocationSearchLabel: string;
  public Normalized: number = 0;
  public CenterX: number = -1.79769313486232e308;
  public CenterY: number = -1.79769313486232e308;
  public CityName: string = '';
  public Complement: string = '';
  public Narrative: string;
  public PlaceName: string = '';
  public PlaceNameX: string;
  public StreetNumber: string = '';
  public TypeId: number = -1;
  public LocationMiscData: string;
  public Zip: string = '';
  public GeoSectorInvalidated: boolean = false;
  public GeoSectorId: number;
  public GeoSectorName: string;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public TraceId: Guid = GuidTools.Empty;
  public CoordEpsgCode: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
