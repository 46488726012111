import { Injectable } from '@angular/core';
import { Map, TacticalSituationLayer } from '@techwan/mapping';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { List } from '@models/list';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { CacheService } from '@services/cache/cache.service';
import { MapService, MoreItem, MoreItemsType } from '@services/map';
import { filter } from 'rxjs/operators';
import { CallCardDispatch } from '@models/imported/SagaSchema/CallCardDispatch';
import { ILayerController } from '../../schema/interfaces/ILayerController';

@Injectable()
export class DrawingLayerService implements ILayerController {
  get unitActivity() {
    return this.association.getUnitActivity();
  }

  tacticalSituations: List<CTacticalSituation>;
  layers: { [key: string]: TacticalSituationLayer } = {};

  moreItem: MoreItem;

  constructor(private cache: CacheService, private association: AssociationSecurity, private mapService: MapService) {
    this.tacticalSituations = this.cache.getListOf<CTacticalSituation>(CTacticalSituation);
    this.tacticalSituations.addFilter(situation => {
      return (
        this.association.getUnitActivity() &&
        this.association.getUnitActivity().callcard &&
        this.association.getUnitActivity().callcard.ObjGuid === situation.CallCardId
      );
    });

    this.cache.$events
      .pipe(
        filter(
          event => event.object.$t === CallCard.$t || event.object.$t === CallCardDispatch.$t || event.object.$t === CTacticalSituation.$t
        )
      )
      .subscribe(() => this.updateLayers());

    this.moreItem = {
      title: 'SagaMobile.Map.ShowTactical',
      context: this,
      checked: false,
      type: MoreItemsType.Checkbox,
      handler: () => {
        this.updateLayers();
      }
    };
    this.mapService.addMoreItems(this.moreItem);
  }

  mapDidChange(map: Map) {
    this.layers = {};
    if (map) {
      this.updateLayers();
    }
  }

  private updateLayers() {
    if (this.mapService.map && this.moreItem.checked) {
      // Add or update
      this.tacticalSituations.refresh();
      this.tacticalSituations.items.forEach(drawing => {
        if (!this.layers[drawing.ObjGuid]) {
          this.layers[drawing.ObjGuid] = this.mapService.map.createTacticalSituationLayer(drawing.GeometryObjects);
        } else {
          this.layers[drawing.ObjGuid].loadGeometry(drawing.GeometryObjects);
        }

        /*
				 * TODO: Replace static transformation by dynamic.
                (<TacticalSituationLayer>this.layers[drawing.ObjGuid])
					.getSource()
					.getFeatures()
					.forEach(f => f
						.getGeometry()
						.transform('EPSG:2154', this.mapService.map.getView().getProjection())
					)
					*/
      });

      // Remove old
      const validGuids = this.tacticalSituations.items.map(s => s.ObjGuid);
      Object.keys(this.layers).forEach(guid => {
        if (validGuids.indexOf(guid) === -1) {
          this.mapService.map.removeLayer(this.layers[guid]);
          delete this.layers[guid];
        }
      });
    } else if (!this.moreItem.checked) {
      Object.keys(this.layers).forEach(guid => {
        this.mapService.map.removeLayer(this.layers[guid]);
      });
      this.layers = {};
    }
  }
}
