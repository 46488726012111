import { Component, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { Initializer } from '@services/initializer/initializer.service';
import { Subscription } from 'rxjs';
import { CallCardsProviderService } from '../../services/callcards-provider/callcards-provider.service';

@Component({
  template: ''
})
export class CallCardListBaseComponent implements OnInit, OnDestroy {
  cards: List<CallCard>;

  protected readonly _subs: Subscription[] = [];

  constructor(protected readonly _initializer: Initializer, protected readonly _cardsProvider: CallCardsProviderService) {}

  ngOnInit() {
    this._subs.push(this._initializer.$onComplete.subscribe(c => this.onComplete(c)));
  }

  protected onComplete(isComplete: boolean): void {
    this.cards = isComplete === true ? this._cardsProvider.getCards() : null;
  }

  ngOnDestroy() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }
}
