import { Injectable } from '@angular/core';
import { StatusChangeResult, StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { ModalService } from '@services/modal/modal.service';
import { ToastColor, ToastDuration, ToastService } from '@services/toast/toast.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Observable } from 'rxjs';
import { FetchListService } from '../fetchlist/fetchlist.service';
import { StatusChangeRequirementsService } from './status-change-requirements/status-change-requirements.service';
import { StatusParameters, StatusProxyService } from './status-proxy/status-proxy.service';

export interface GetAllResourceStatusOptions {
  mobile?: boolean;
  currentStatus?: ListResourceStatus;
}

const ValueIsEmpty = 0;

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  get $ready(): Observable<boolean> {
    return this.fetchList.$ready;
  }

  constructor(
    private fetchList: FetchListService,
    private modalService: ModalService,
    private statusProxy: StatusProxyService,
    private myUnitService: MyUnitService,
    private statusChangeRequirements: StatusChangeRequirementsService,
    private toastService: ToastService
  ) {}

  changeStatus(
    newStatus: ListResourceStatus,
    callcard?: CallCard,
    statusParameters: StatusParameters = {},
    loadingIndicatorText?: string,
    showLoadingIndicator: boolean = true
  ): Promise<StatusChangeResult> {
    return this.executePreChangeRequirements(newStatus, callcard).then(confirmed => {
      if (confirmed) {
        if (callcard) {
          Object.assign(statusParameters, { callCardUid: callcard.ObjGuid });
        }
        Object.assign(statusParameters, this.getChangeRequirementsParameters());

        return this.setStatus(newStatus, statusParameters, loadingIndicatorText, showLoadingIndicator).then(statusChangeResult => {
          if (statusChangeResult === StatusChangeResult.OK) {
            return this.executePostChangeRequirements(newStatus, callcard).then(() => statusChangeResult);
          } else {
            return Promise.resolve(statusChangeResult);
          }
        });
      } else {
        return Promise.resolve(StatusChangeResult.MessageNotSent);
      }
    });
  }

  private executePreChangeRequirements(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    if (this.statusChangeRequirements.requirements.length) {
      return this.statusChangeRequirements.requirements.reduce((current, next) => {
        return current.then(canContinue => (canContinue && next.preChangeRequirement(newStatus, callcard)) || Promise.resolve(false));
      }, Promise.resolve(true));
    } else {
      return Promise.resolve(true);
    }
  }

  private getChangeRequirementsParameters(): StatusParameters {
    const statusParameters: StatusParameters = {};
    this.statusChangeRequirements.requirements.forEach(requirement => Object.assign(statusParameters, requirement.getStatusParameters()));
    return statusParameters;
  }

  private setStatus(
    newStatus: ListResourceStatus,
    statusParameters: StatusParameters,
    loadingIndicatorText?: string,
    showLoadingIndicator: boolean = true
  ): Promise<StatusChangeResult> {
    return (showLoadingIndicator ? this.modalService.presentLoading(loadingIndicatorText) : Promise.resolve()).then(() =>
      this.statusProxy
        .setActivityStatus(this.myUnitService.mine.ObjGuid, newStatus.Value, statusParameters)
        .toPromise()
        .then(statusChangeResult => {
          if (statusChangeResult === StatusChangeResult.OK) {
            this.toastService.show('Mobile.StatusChangeSuccess', ToastColor.Success);
          } else {
            this.toastService.show(
              'Mobile.StatusChangeFailed',
              ToastColor.Error,
              {
                statusChangeResult: StatusChangeResult[statusChangeResult]
              },
              ToastDuration.Long
            );
          }
          return statusChangeResult;
        })
        .finally(() => (showLoadingIndicator ? this.modalService.dismissLoading() : Promise.resolve()))
    );
  }

  private executePostChangeRequirements(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    if (this.statusChangeRequirements.requirements.length) {
      return this.statusChangeRequirements.requirements.reduce((current, next) => {
        return current.then(() => next.postChangeRequirement(newStatus, callcard));
      }, Promise.resolve(true));
    } else {
      return Promise.resolve(true);
    }
  }

  getStatusByAction(action: StatusItemAction): ListResourceStatus {
    return this.getResourceStatuses().find(resourceStatus => resourceStatus.hasAction(action)) || null;
  }

  private getResourceStatuses(): ListResourceStatus[] {
    return this.fetchList.getAllRows(ListResourceStatus) as ListResourceStatus[];
  }

  getTerminateStatus(): ListResourceStatus {
    return this.getResourceStatuses().find(resourceStatus => this.isTerminateStatus(resourceStatus)) || null;
  }

  getAllResourceStatus(options: GetAllResourceStatusOptions = {}) {
    options = Object.assign({ mobile: false, currentStatus: null }, options);
    const resourceStatuses = this.getResourceStatuses();
    return resourceStatuses.filter((resourceStatus: ListResourceStatus) => this.canTransitTo(options, resourceStatus));
  }

  private canTransitTo(options: GetAllResourceStatusOptions, resourceStatus: ListResourceStatus) {
    return this.isMobile(options, resourceStatus) && this.isChildOf(options, resourceStatus);
  }

  private isChildOf(options: GetAllResourceStatusOptions, resourceStatus: ListResourceStatus) {
    return !options.currentStatus || options.currentStatus.Children & resourceStatus.Value;
  }

  private isMobile(options: GetAllResourceStatusOptions, resourceStatus: ListResourceStatus) {
    return !options.mobile || resourceStatus.MobileFlags;
  }

  private isTerminateStatus(resourceStatus: ListResourceStatus): boolean {
    return (
      this.isDefined(resourceStatus, 'AcceptRequest') &&
      this.isDefined(resourceStatus, 'NegateRequest') &&
      resourceStatus.AcceptRequest !== ValueIsEmpty &&
      resourceStatus.NegateRequest !== ValueIsEmpty
    );
  }

  private isDefined(resourceStatus: ListResourceStatus, attribute: string): boolean {
    return attribute in resourceStatus;
  }
}
