import { Component, Input, OnInit } from '@angular/core';
import { UploadManagerService } from '@techwan/file-upload';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';

@Component({
  selector: 'app-upload-item',
  templateUrl: './upload-item.component.html',
  styleUrls: ['./upload-item.component.scss']
})
export class UploadItemComponent implements OnInit {
  @Input()
  uploadItem: SagaFileEntryUploadItem;

  iconName: string;
  fileSizeInMb: string;

  constructor(private uploadManagerService: UploadManagerService) {}

  ngOnInit() {
    this.iconName = this.uploadItem.file.type.includes('image') ? 'image-outline' : 'document-outline';
    this.fileSizeInMb = `${(this.uploadItem.file.size / (1024 * 1024)).toFixed(3)} MB`;
  }

  getProgress(): number {
    return this.uploadItem.flowFile !== null ? +this.uploadItem.getProgress().toFixed(0) : 0;
  }

  canStartOrPause(): boolean {
    return this.uploadItem.isReady && (!this.uploadManagerService.isUploading() || this.uploadItem.isUploading());
  }

  onStartPause() {
    this.uploadItem.isUploading() ? this.uploadItem.pause() : this.uploadItem.start();
  }

  onDelete() {
    this.uploadItem.cancel();
  }
}
