import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CStampCard } from '@models/imported/SagaSchema/CStampCard';
import { CStampFreeText } from '@models/imported/SagaSchema/CStampFreeText';
import { CStampParticulars } from '@models/imported/SagaSchema/CStampParticulars';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { CStampUnitActivity } from '@models/imported/SagaSchema/CStampUnitActivity';
import { CPersonHistory } from '@models/imported/SagaSchema/History/CPersonHistory';

@Injectable({
  providedIn: 'root'
})
export class StampNameProviderService {
  constructor() {}

  getStampName(item: SagaObject): string {
    const stampNameSuffix: string = this.getStampNameSuffix(item);

    return `Mobile.${item.$t}` + (stampNameSuffix ? `_${stampNameSuffix}` : '');
  }

  private getStampNameSuffix(item: SagaObject): string {
    let stampNameSuffix: string;

    switch (item.$t) {
      case CPersonHistory.$t: {
        const personHistory: CPersonHistory = item as CPersonHistory;
        stampNameSuffix = `${personHistory.Description}`;
        break;
      }

      case CStampCard.$t: {
        const stampCard: CStampCard = item as CStampCard;
        stampNameSuffix = `${stampCard.StampType}_${stampCard.Status}`; // StampManagerType
        break;
      }

      case CStampFreeText.$t: {
        const stampFreeText: CStampFreeText = item as CStampFreeText;
        stampNameSuffix = `${stampFreeText.StampType}`;
        break;
      }

      case CStampParticulars.$t: {
        const stampParticulars: CStampParticulars = item as CStampParticulars;
        stampNameSuffix = `${stampParticulars.ParticularsType}`; // ParticularsStampType
        break;
      }

      case CStampProcedure.$t: {
        const stampProcedure: CStampProcedure = item as CStampProcedure;
        stampNameSuffix = `${stampProcedure.StampType}`; // ProcedureStampType
        break;
      }

      case CStampUnitActivity.$t: {
        const stampUnitActivity: CStampUnitActivity = item as CStampUnitActivity;
        stampNameSuffix = `${stampUnitActivity.StampType}_${stampUnitActivity.Status}`;
        break;
      }
    }

    return stampNameSuffix;
  }
}
