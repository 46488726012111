import { ILocation } from '@models/interfaces/ILocation';
import { DefaultLocationFormatter } from './default-location-formatter';

export class FusLocationFormatter extends DefaultLocationFormatter {
  format(location: ILocation): string[] {
    this.miscellaneousData = this.parseMiscData(location.LocationMiscData);

    const miscDataFields: string[] = [
      this.getAttributeValue('REFHIGHWAYS_SNAME'),
      this.getAttributeValue('REFTRACKS_SNAME'),
      this.getAttributeValue('SFULLTEXT')
    ];
    const street: string = [location.PlaceName, location.StreetNumber].filter(s => s).join(' ');
    const city: string = [location.Zip, location.CityName].filter(s => s).join(' ');
    const areaName: string = location.Area1Name ? `(${location.Area1Name})` : '';
    const digiCode: string = this.getDigiCode(location);

    const line1: string = [...miscDataFields, location.AliasName, street, city, areaName, digiCode].filter(s => s).join(', ');

    const coordinates: string = [location.coordinate.x, location.coordinate.y].filter(s => s).join(', ');
    const line2: string = coordinates ? `${this.translate.instant('Mobile.Coordinates')}: ${coordinates}` : null;

    return [line1, line2].filter(s => s);
  }
}
