<ion-header>
  <ion-toolbar color="primary ion-text-center">
    <!-- @if PROFILE != 'GNBROWSER' -->
    <ion-menu-toggle menu="start" slot="start" sagaMenu>
      <ion-button fill="clear" color="light"><ion-icon slot="icon-only" size="large" name="menu"></ion-icon></ion-button>
    </ion-menu-toggle>
    <!-- @endif -->
    <ion-title>
      <span translate>client.{{ AppConfig.name | lowercase }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen scrollY="false" class="gn-home padding" layout="column">
  <div slot="fixed">
    <ion-chip class="chip-rounded-icon" *ngIf="unitActivity" (click)="openMyUnit()" color="primary">
      <ion-icon name="car" [style.backgroundColor]="unitActivity.status && unitActivity.status.displayColor"></ion-icon>
      <ion-label *ngIf="unitActivity.status" translate="{{ unitActivity.status.Name }}"></ion-label>
    </ion-chip>

    <ion-chip *ngIf="!unitActivity">
      <ion-spinner></ion-spinner>
      <ion-label translate="SagaMobile.Cache.Loading"></ion-label>
    </ion-chip>
  </div>

  <ion-card class="gn-home-my-card with-map" (click)="openMyCard()" *ngIf="unitActivity && unitActivity.callcard" slot="fixed">
    <ion-card-header class="my-card-header" ion-text color="primary"><span translate>SagaMobile.MyCard</span></ion-card-header>
    <ion-item>
      <ion-icon [style.color]="unitActivity.callcard.color.tinycolor.toRgbString()" name="pin" slot="start" size="large"></ion-icon>
      <ion-label>
        <h2>{{ unitActivity.callcard.CreatedUTC | moment: 'LLLL' }}</h2>
        <p style="white-space: initial;">{{ unitActivity.callcard.event.EventText }}</p>
        <p style="white-space: initial;">{{ unitActivity.callcard.location.FormattedLocation }}</p>
      </ion-label>
    </ion-item>
  </ion-card>
  <ion-card class="gn-home-my-card" *ngIf="!unitActivity" slot="fixed">
    <div class="cache-loader">
      <ion-spinner></ion-spinner>
      <div class="cache-loader-label" translate>SagaMobile.Cache.Loading</div>
    </div>
  </ion-card>
  <div class="gn-home-buttons" flex slot="fixed">
    <div [ngClass]="buttonsLayout" flex>
      <ion-button flex tappable (click)="openView('Callcard/CardsList')" class="gn-home-button">
        <ion-icon name="list"></ion-icon>
        <div translate="SagaMobile.CallCards.List"></div>
      </ion-button>
      <ion-button hideWhen="tablet" flex tappable (click)="openMap()" class="gn-home-button">
        <ion-icon name="map"></ion-icon>
        <div translate="SagaMobile.Map"></div>
      </ion-button>
    </div>
    <div [ngClass]="buttonsLayout" flex>
      <ion-button flex tappable class="gn-home-button" (click)="openView('Callcard/CardCreate')">
        <ion-icon name="add"></ion-icon>
        <span translate>SagaMobile.Menu.CreateFPC</span>
      </ion-button>
      <ion-button flex tappable class="gn-home-button" [color]="color" (click)="sendPosition()">
        <ion-icon name="locate"></ion-icon><br />
        <span translate *ngIf="!isSendingPosition()">SagaMobile.Menu.Position.Send</span>
        <span translate style="line-height: 2em;white-space: normal;" *ngIf="isSendingPosition()">SagaMobile.Menu.Position.Stop</span>
      </ion-button>
    </div>
    <div class="gn-options-row" layout="row">
      <ion-button flex tappable fill="outline" (click)="showSettings()" class="gn-home-button"
        ><ion-icon slot="icon-only" name="settings"></ion-icon
      ></ion-button>
      <ion-button flex tappable expand="full" color="danger" (click)="logout()" class="gn-home-button"
        ><ion-icon slot="icon-only" name="log-out"></ion-icon
      ></ion-button>
    </div>
  </div>
</ion-content>
