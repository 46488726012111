import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '../../services/document/document.service';
import { Subscription } from 'rxjs';
import { List } from '@models/list';

@Component({
  template: ''
})
export class ViewBaseComponent implements OnInit, OnDestroy {
  public error = null;

  /**
   * Displays a loading bas in the view to show current state
   */
  public isLoading = false;

  public readonly documents: List<string> = new List<string>([]);

  private _sub: Subscription = null;

  public get hasError(): boolean {
    return this.error !== null;
  }

  public get isEmpty(): boolean {
    return this.documents.items.length === 0 && !this.isLoading && this.error === null;
  }

  constructor(protected readonly _documentService: DocumentService) {}

  public ngOnDestroy() {
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  public ngOnInit() {
    this.isLoading = true;
    this._sub = this._documentService.get().subscribe((res: string[]) => {
      this.isLoading = false;
      this.documents.source = res;
      this._sub = null;
    }, this.handleError.bind(this));
  }

  /**
   * Display an error in the content of the view
   */
  private handleError(res) {
    this.isLoading = false;
    this.error = res;
    this._sub = null;
  }
}
