import { PanelToolBase, PanelToolGroupNames } from '@addins/core/panel';
import { ToggleTool } from '@techwan/map-tools';
import { DrawingPanelContentComponent } from '../components/drawing-panel-content/drawing-panel-content.component';

export class DrawingPanelTool extends PanelToolBase {
  readonly groupName: string = PanelToolGroupNames.MapToolGroup;
  readonly button: ToggleTool = new ToggleTool('drawing-tool-button', ['create']);
  readonly content = DrawingPanelContentComponent;
  hasViewSplitter: boolean = false;
}
