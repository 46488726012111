import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnDestroy } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CacheService } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: 'object-chooser.component.html',
  styleUrls: ['object-chooser.component.scss']
})
export class ObjectChooserComponent implements OnDestroy {
  private readonly sub: Subscription;

  objects: SagaObject[];

  constructor(private navParams: NavParams, private modal: ModalController, private cache: CacheService) {
    this.objects = this.navParams.get('objects');
    this.sub = this.cache.onChange.subscribe(() => {
      this.objects = this.objects.filter(o => this.cache.getObjectByUid(o.ObjGuid));
    });
  }

  selectObject(object: SagaObject) {
    this.modal.dismiss(object);
  }

  cancel() {
    this.modal.dismiss();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
