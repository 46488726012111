import { Subscription } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { Execute, AddinExecute, AddinService } from '@techwan/ionic-core';
import { IonNav } from '@ionic/angular';
import { ProfileService } from '@services/profile/profile.service';
import { NavService } from '@services/navigation/navigation.service';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CurrentCardService } from '@addins/core/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileParserService } from '../../profile-parser/profile-parser.service';
import { ViewLoaderService } from '@addins/core/menus';

@AddinExecute({
  name: 'CallcardOpener',
  description: 'Provide a tool and event to open a CallCard'
})
@Injectable()
export class CallcardOpenerService extends Execute {
  private readonly _subs: Subscription[] = [];

  /**
   * An open card tool
   */
  private openCardTool: Tool;

  private _view: Type<any> = null;

  constructor(
    private readonly _currentCardService: CurrentCardService,
    private readonly _navService: NavService,
    private readonly _addinService: AddinService,
    private readonly _profileService: ProfileService,
    private readonly _toolsProviderService: ToolsProviderService,
    private readonly _translator: TranslateService,
    private readonly _profileParser: ProfileParserService,
    private readonly _viewLoader: ViewLoaderService
  ) {
    super();
  }

  public destroy(): void {
    this.disable();
  }

  public execute(): void {
    this._profileService.ready.subscribe(r => this.setup(r));
  }

  /**
   * It is called when someone needs to open a card
   */
  private openCard(card: CallCard): Promise<void> {
    return card === null
      ? Promise.resolve()
      : this._navService.getViewOpen(this._view).then(cvi => {
          const nav: IonNav = this._navService.get();
          const page = 0;

          // change this to load profile
          if (cvi) {
            cvi.load(card, page);
            return Promise.resolve();
          } else if (this._view !== null) {
            this._viewLoader.show(this._view, { card, page });
          }
        });
  }

  private createTool() {
    this.openCardTool = Tool.createTool({
      text: this._translator.instant('Mobile.OpenCard'),
      icon: 'open',
      priority: 100,
      execute: context => this.openCard(context[0])
    });
  }

  private disable() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private setup(ready: boolean): string {
    if (ready === false) {
      return;
    }

    this._view = this._profileParser.getCardView() || this._addinService.defaultView || null;
    if (this._view !== null && this._subs.length === 0) {
      this.createTool();
      this._subs.push(this._currentCardService.$change.subscribe(c => this.openCard(c)));
      this._subs.push(this._toolsProviderService.register(p => this.onTool(p)));
    } else {
      this.disable();
    }
  }

  /**
   * This is called when someone needs tools from a context
   */
  private onTool(provider: ToolsProvider): void {
    if (provider.context.length === 1 && provider.contextObjectsAre(CallCard)) {
      provider.addTool(this.openCardTool);
    }
  }
}
