import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-counter',
  templateUrl: './alert-counter.component.html',
  styleUrls: ['./alert-counter.component.scss']
})
export class AlertCounterComponent implements OnInit {
  constructor(public readonly e: ElementRef) {}

  ngOnInit() {}
}
