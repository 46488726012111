// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class GeoInfoResultArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.GeoInfoResultArgs';
  $t: string = 'SagaBase.GeoInfoResultArgs';

  public static GetMembers(): string[] {
    return ['Result', 'Error', 'ResultList', 'ErrorMessage', 'Sector', 'ExternalCityId', 'OriginalErrorCode'];
  }

  public GetMembers(): string[] {
    return GeoInfoResultArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Result: SagaBase.GeoResultCode = SagaBase.GeoResultCode.None;
  public Error: SagaBase.GeoErrorCode = SagaBase.GeoErrorCode.None;
  public ResultList: any[];
  public ErrorMessage: string;
  public Sector: any;
  public ExternalCityId: string;
  public OriginalErrorCode: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
