import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CAnimal } from '@models/imported/SagaSchema/CAnimal';
import { ParticularsCollapsibleItem } from './particulars-collapsible-item';
import { ParticularsPosition } from './particulars-position';

const CAnimalListCollapsibleName = 'Mobile.CAnimalDetails';
const CAnimalListViewName = 'SingleCallcardParticulars/CallcardDetailCAnimal';

export class CAnimalCollapsibleItem extends ParticularsCollapsibleItem {
  constructor() {
    super(CAnimalListCollapsibleName, CAnimalListViewName, ParticularsPosition.CAnimal);
  }

  updateVisibility(callCard: CallCard): void {
    this.setVisible(callCard !== null ? callCard.component<CAnimal>(CAnimal).length > 0 : false);
  }
}
