import { JournalToolsProviderService } from '@addins/singlecallcard-journal';
import { Injectable } from '@angular/core';
import { AddFreeTextTool } from '../../schema/add-free-text-tool';
import { AddFreeTextService } from '../add-freetext/add-freetext.service';

@Injectable({
  providedIn: 'root'
})
export class JournalToolsControllerService {
  private addFreeTextTool: AddFreeTextTool;

  constructor(private journalTools: JournalToolsProviderService, private addFreeTextService: AddFreeTextService) {}

  setup() {
    this.addFreeTextTool = new AddFreeTextTool(task => this.addFreeTextService.addFreeText(task.ObjGuid));
    this.journalTools.add(this.addFreeTextTool);
  }
}
