// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @11/8/2021 6:28:12 PM
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CCallerBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CCallerBase';
  $t: string = 'SagaSchema.CCallerBase';
  public CallCardId: Guid = GuidTools.Empty;
  public MapX: number = -1.79769313486232e308;
  public MapY: number = -1.79769313486232e308;

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/
  public CoordEpsgCode: string;
  public StreetName: string = '';
  public ListNameValueId: number;
  public IsFirstCaller: boolean = false;
  public CalledService: string;
  public CallSourceId: number = 0;
  public LineOwner: number = 0;
  public Complement: string = '';
  public Fname: string = '';
  public Lname: string = '';
  public Phone: string = '';
  public Mobile: string = '';
  public RecordChannel: string = '';
  public AliasName: string = '';
  public Area1Name: string = '';
  public Area2Name: string = '';
  public Area3Name: string;
  public TypeId: number = -1;
  public PersonQualifierId: number = 0;
  public CityName: string = '';
  public GeoLocationId: number;
  public GeoLocationText: string;
  public GeoLocationXML: string;
  public GeoLocationSearchLabel: string;
  public LocationMiscData: string;
  public GeoLocationType: number;
  public Normalized: number = 0;
  public PlaceName: string = '';
  public StreetNumber: string = '';
  public Zip: string = '';
  public GeoSectorId: number;
  public GeoSectorName: string;
  public Organization: string;
  public DateOfBirth: Date;
  public NationalityId: number = 0;
  public Narrative: string;
  public NeedUserValidation: boolean = false;
  public PhonebookSource: SagaBase.EtdSource = SagaBase.EtdSource.None;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public TraceId: Guid = GuidTools.Empty;

  get ParentObjId(): any {
    return this.CallCardId;
  }

  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'MapX',
      'MapY',
      'CoordEpsgCode',
      'StreetName',
      'ListNameValueId',
      'IsFirstCaller',
      'CalledService',
      'CallSourceId',
      'LineOwner',
      'Complement',
      'Fname',
      'Lname',
      'Phone',
      'Mobile',
      'RecordChannel',
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'TypeId',
      'PersonQualifierId',
      'CityName',
      'GeoLocationId',
      'GeoLocationText',
      'GeoLocationXML',
      'GeoLocationSearchLabel',
      'LocationMiscData',
      'GeoLocationType',
      'Normalized',
      'PlaceName',
      'StreetNumber',
      'Zip',
      'GeoSectorId',
      'GeoSectorName',
      'Organization',
      'DateOfBirth',
      'NationalityId',
      'Narrative',
      'NeedUserValidation',
      'PhonebookSource',
      'TraceState',
      'TraceId'
    ].concat(SagaObject.GetMembers());
  }

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  public GetMembers(): string[] {
    return CCallerBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DateOfBirth = this.DateOfBirth && new Date(this.DateOfBirth);

    ///*</AUTO_INIT_CODE>*/
  }

  /*</AUTO_IMPLEMENTATION>*/
}
