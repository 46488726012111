import { DownloadUrlTransformService, FileDownloadService, FileSystemService } from '@addins/core/file';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { Observable, Subscriber } from 'rxjs';
import { FileDownloadState } from '../../schema/file-download-state';
import { IMediaFile } from '../../schema/interfaces/media-file';
import { MediaFile } from '../../schema/media-file';

@Injectable({
  providedIn: 'root'
})
export class MediaFileService {
  private downloadedAttachments: Map<string, IMediaFile> = new Map();

  constructor(
    private fileDownloader: FileDownloadService,
    private downloadUrlTransformer: DownloadUrlTransformService,
    private platform: Platform,
    private fileSystemService: FileSystemService,
    private file: File,
    private fileOpener: FileOpener,
    private toastService: ToastService
  ) {}

  getMediaFile(url: string): MediaFile {
    return (this.downloadedAttachments.get(url) || null) as MediaFile;
  }

  downloadMediaFile(mediaUrl: string, localFileName: string): Observable<MediaFile> {
    let mediaFile = this.createMediaFile(mediaUrl, localFileName);

    return new Observable<MediaFile>(subscriber => {
      this.sendEvent(subscriber, mediaFile, FileDownloadState.starting);
      this.fileDownloader.download(this.downloadUrlTransformer.transform(mediaUrl)).subscribe(
        (event: HttpEvent<HttpProgressEvent>) => this.onDownloadProgress(subscriber, mediaFile, event),
        (error: HttpErrorResponse) => {
          this.onError(subscriber, mediaFile, error.message);
        }
      );
    });
  }

  private createMediaFile(url: string, localFileName: string): MediaFile {
    const mediaFile: MediaFile = new MediaFile(url, localFileName);
    this.downloadedAttachments.set(url, mediaFile);
    return mediaFile;
  }

  private sendEvent(subscriber: Subscriber<MediaFile>, mediaFile: MediaFile, state: FileDownloadState) {
    mediaFile.setState(state);
    subscriber.next(mediaFile);
  }

  private onDownloadProgress(subscriber: Subscriber<MediaFile>, mediaFile: MediaFile, event: HttpEvent<HttpProgressEvent>) {
    if (event.type === HttpEventType.Response) {
      mediaFile.setBlob((event.body as any) as Blob);
      this.storeFile(subscriber, mediaFile).then(() => {
        mediaFile.setProgress(1);
        this.sendEvent(subscriber, mediaFile, FileDownloadState.downloaded);
        subscriber.complete();
      });
    } else if (event.type === HttpEventType.DownloadProgress) {
      mediaFile.setProgress(event.loaded / event.total);
      this.sendEvent(subscriber, mediaFile, FileDownloadState.downloading);
    }
  }

  private storeFile(subscriber: Subscriber<MediaFile>, mediaFile: MediaFile): Promise<MediaFile> {
    if (this.platform.is('cordova')) {
      return this.fileSystemService.getRoot().then(rootDirectory =>
        this.file
          .writeFile(rootDirectory.nativeURL, mediaFile.getLocalName(), mediaFile.getBlob(), { replace: true })
          .then((fileEntry: FileEntry) => (mediaFile.setLocalPath(fileEntry.nativeURL), mediaFile))
          .catch(error => {
            this.onError(subscriber, mediaFile, error);
            return mediaFile;
          })
      );
    } else {
      return Promise.resolve(mediaFile);
    }
  }

  private onError(subscriber: Subscriber<MediaFile>, mediaFile: MediaFile, error: string) {
    this.downloadedAttachments.delete(mediaFile.getUrl());
    this.toastService.show('Mobile.FileDownloadError', ToastColor.Error);
    subscriber.error(error);
  }

  openMediaFile(mediaFile: MediaFile) {
    const filePath: string = mediaFile.getLocalPath();
    const blob: Blob = mediaFile.getBlob();

    if (this.platform.is('cordova')) {
      this.fileOpener.open(filePath, blob.type).catch(error => console.log(error));
    } else {
      window.open(URL.createObjectURL(blob), '_blank');
    }
  }
}
