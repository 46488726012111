import { Injectable } from '@angular/core';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';
import { Destination } from '@addins/external-itinerary/schema/destination';
import { transform } from '@services/map';

@Injectable()
export class TomTomProviderService implements INavigationProvider {
  private readonly _epsg = '4326';

  constructor() {}

  public navigate(destination: Destination): Window {
    if (destination.location.epsg !== this._epsg) {
      destination.location = transform(destination.location, `EPSG:${this._epsg}`);
    }
    return window.open(
      `tomtomhome:geo:action=navigateto&lat=${destination.location.y}&long=${destination.location.x}&name=${destination.extra}`,
      'TomTom'
    );
  }
}
