<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-buttons slot="start">
            <ion-button (click)="close()"><ion-icon slot="icon-only"  name="close"></ion-icon></ion-button>
        </ion-buttons>
        <ion-title>
            <span translate>Saga.UA.Template</span>
        </ion-title>
    </ion-toolbar>
    <ion-toolbar>
        <ion-searchbar (ionChange)="change($event)"></ion-searchbar>
    </ion-toolbar>
</ion-header>
<ion-content class="padding">
    <ion-card *ngFor="let template of templates" (click)="close(template)">
        <ion-item *ngIf="template.vehicle.CallCode">
            {{template.vehicle.CallCode}}
        </ion-item>
        <ion-item *ngIf="template.vehicle.type">
            {{template.vehicle.type.Name}}
        </ion-item>
        <ng-container *ngTemplateOutlet="listNameItem; context: {$implicit: template.vehicle.vehicleType}"></ng-container>
        <ng-container *ngTemplateOutlet="listNameItem; context: {$implicit: template.vehicle.vehicleSubType}"></ng-container>
        <ng-container *ngTemplateOutlet="listNameItem; context: {$implicit: template.vehicle.vehicleModeOfTransport}"></ng-container>
        <ng-container *ngTemplateOutlet="listNameItem; context: {$implicit: template.vehicle.vehicleCategory}"></ng-container>
    </ion-card>
</ion-content>

<ng-template #listNameItem let-item>
    <ion-item *ngIf="item">
        {{item.DisplayValue}}
    </ion-item>
</ng-template>
