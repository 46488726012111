<div *ngIf="layers">
  <div *ngFor="let item of layers" [ngSwitch]="getType(item)">
    <div *ngSwitchCase="group">
      <div *ngIf="shouldShowGroup(item)">
        <ion-item-divider *ngIf="item.get('hide') !== true">
          <ion-label class="layer-group" [translate]="item.get('name')"></ion-label>
        </ion-item-divider>
        <app-overlay-group-select *ngIf="getLayers(item).length > 0" [layers]="getLayers(item)"></app-overlay-group-select>
      </div>
    </div>

    <div *ngSwitchCase="merged">
      <app-overlay-layer-select *ngIf="shouldShowMergedGroup(item)" [item]="item"></app-overlay-layer-select>
    </div>

    <div *ngSwitchCase="layer">
      <app-overlay-layer-select *ngIf="shouldShowLayer(item)" [item]="item"></app-overlay-layer-select>
    </div>
  </div>
</div>
