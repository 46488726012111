import { Injectable } from '@angular/core';
import { AtomicOperation } from '@models/imported/SagaBase/Defines/AtomicOperation';
import { OperationType } from '@models/imported/SagaBase';
import { PersistentObject } from '@models/imported/SagaBase/PersistentObject';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { Security } from '../security/security.service';

@Injectable()
export class DbService {
  constructor(private security: Security, private objectFactory: ObjectFactory) {}

  async submitObject(obj: any): Promise<any> {
    let result = await this.security.post('api/Db/SubmitObject', obj);
    return result;
  }

  async updateObject(obj: any): Promise<any> {
    let result = await this.security.post('api/Db/UpdateObject', obj);
    return result;
  }

  async updateAtomicObject(obj: any): Promise<any> {
    let result = await this.security.post('api/Db/UpdateAtomicObject', obj);
    return result;
  }

  readObject<T>(typeName: string, guid: string): Promise<T> {
    return this.security
      .get('api/Db/ReadObject', {
        typeName: typeName,
        uid: guid
      })
      .then(response => this.objectFactory.create(response));
  }

  createAtomicOp(obj: PersistentObject, action: OperationType, recursive = false, generated = false, shared = true) {
    const atomicOp = new AtomicOperation();
    atomicOp.obj = obj;
    atomicOp.action = action;
    atomicOp.recursive = recursive;
    atomicOp.generated = !!generated;
    atomicOp.sharedOperation = shared;

    return atomicOp;
  }
}
