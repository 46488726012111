import { Observable, Subject } from 'rxjs';
import {
  ICallcardButtonEvent,
  ICallcardButtonTool,
  ToolColor,
  ToolExpand,
  ToolFill,
  ToolSize,
  ToolType
} from './interfaces/ICallcardButtonTool';

export abstract class CallcardButtonTool<T extends ICallcardButtonEvent> implements ICallcardButtonTool {
  color: ToolColor;
  expand: ToolExpand;
  fill: ToolFill = ToolFill.clear;
  isDisabled: boolean = false;
  size: ToolSize;

  get type(): ToolType {
    return ToolType.button;
  }

  protected readonly _onClick = new Subject<T>();
  get onClick(): Observable<T> {
    return this._onClick.asObservable();
  }

  get text(): string {
    return this._text;
  }

  get icon(): string {
    return this._icon;
  }

  get show(): boolean {
    return this._show;
  }
  set show(value: boolean) {
    this._show = value;
  }

  protected constructor(protected _text: string, protected _icon: string, public position: number, protected _show: boolean = true) {}

  abstract click();
}
