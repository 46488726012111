import { Inject, Injectable } from '@angular/core';
import { SETTING_PROVIDER } from '../../schema/injection-token';
import { ISettingProvider } from '../../schema/interfaces/settings-provider';

@Injectable()
export class SettingsService {
  constructor(
    @Inject(SETTING_PROVIDER)
    private readonly _settings: ISettingProvider
  ) {}

  getCustomerName(): string {
    return this._settings.getValue('CustomerName');
  }

  getPositionInterval(): number {
    return this._settings.getValue('SagaMobileWebClient.SendPositionInterval') || null;
  }

  getGpsStatus(): boolean {
    return this._settings.getValue('SagaMobileWebClient.GpsStatus') || false;
  }
}
