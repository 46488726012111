// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CrisisCallCardProperties_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CrisisCallCardProperties';
  $t: string = 'SagaSchema.CrisisCallCardProperties';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'IsSubEventMandatory',
      'IsQualificationMandatory',
      'IsDestinationSectorMandatory',
      'IsDestinationMandatory',
      'IsSourceMandatory',
      'PertinenceColorCertified',
      'PertinenceColorConfirmed',
      'PertinenceColorToVerify',
      'ClassificationColor',
      'QuittanceColorReceived',
      'QuittanceColorTo',
      'IsPermanent',
      'IsSecret',
      'ArchivingPeriod',
      'ParentSectorName',
      'ParentSectorId',
      'SectorName',
      'EventSource',
      'CurrentCounter1'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CrisisCallCardProperties_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public IsSubEventMandatory: boolean = false;
  public IsQualificationMandatory: boolean = false;
  public IsDestinationSectorMandatory: boolean = false;
  public IsDestinationMandatory: boolean = false;
  public IsSourceMandatory: boolean = false;
  public PertinenceColorCertified: string;
  public PertinenceColorConfirmed: string;
  public PertinenceColorToVerify: string;
  public ClassificationColor: string;
  public QuittanceColorReceived: string;
  public QuittanceColorTo: string;
  public IsPermanent: boolean = false;
  public IsSecret: boolean = false;
  public ArchivingPeriod: number = 0;
  public ParentSectorName: string;
  public ParentSectorId: number = 0;
  public SectorName: string;
  public EventSource: SagaBase.CrisisEventSource = SagaBase.CrisisEventSource.Web;
  public CurrentCounter1: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
