// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class AddressComplementArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.AddressComplementArgs';
  $t: string = 'SagaBase.AddressComplementArgs';

  public static GetMembers(): string[] {
    return ['GeoLocationComplementId', 'GeoLocationTypeId', 'GeoLocationId'];
  }

  public GetMembers(): string[] {
    return AddressComplementArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GeoLocationComplementId: number;
  public GeoLocationTypeId: number;
  public GeoLocationId: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
