import { UnitActivityViewName } from '@addins/core/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AlertController, IonItem, ModalController, NavController } from '@ionic/angular';
import { StatusItemAction } from '@models/imported/SagaBase';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { TranslateService } from '@ngx-translate/core';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { ModalService } from '@services/modal/modal.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Security } from '@services/security/security.service';
import { StatusService } from '@services/status/status.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { AddinView } from '@techwan/ionic-core';
import { UnitSelectorService } from '../../services/unit-selector/unit-selector.service';

/**
 * @description
 */
@Component({
  templateUrl: 'unit-activity-view.component.html',
  styleUrls: ['unit-activity-view.component.scss']
})
@AddinView({
  name: UnitActivityViewName
})
export class UnitActivityViewComponent {
  @ViewChild('mapCard') mapCard: ElementRef;
  @ViewChild('mapLabel') mapLabel: IonItem;

  availableStatus: ListResourceStatus;

  get unitActivity(): UnitActivity {
    return this.security.getData('UnitActivity');
  }

  constructor(
    private security: Security,
    private unitActivityService: UnitActivityService,
    private unitSelectorService: UnitSelectorService,
    private modal: ModalService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private statusService: StatusService,
    private translate: TranslateService,
    private objectFactory: ObjectFactory,
    private fetchList: FetchListService,
    private nav: NavController
  ) {
    this.availableStatus = this.fetchList.getItemByAction(ListResourceStatus, StatusItemAction.isAvailable);
  }

  getStatus() {
    return this.statusService.getAllResourceStatus({
      mobile: true,
      currentStatus: this.unitActivity.status
    });
  }

  canEdit() {
    return this.unitActivity.status === this.availableStatus;
  }

  canDelete() {
    return this.unitActivity.status === this.availableStatus && !this.unitSelectorService.disableDissociate;
  }

  sendStatus(status: ListResourceStatus) {
    this.sendResponse('SagaMobile.UA.ChangeStatus', status);
  }

  private async sendResponse(text: string, status: ListResourceStatus) {
    await this.statusService.changeStatus(status, this.unitActivity.callcard, {}, text);
    this.nav.pop();
  }

  async edit() {
    const currentUnitActivity = this.unitActivity;
    const unitActivityCopy = this.objectFactory.clone(currentUnitActivity, false) as UnitActivity;
    currentUnitActivity.devices.forEach(device => {
      unitActivityCopy.devices.push(device);
    });

    currentUnitActivity.vehicles.forEach(vehicle => {
      unitActivityCopy.vehicles.push(vehicle);
    });

    const unit = await this.unitActivityService.openCreateUnit(this.unitSelectorService.getCreateComponent(), {
      unitActivity: unitActivityCopy
    });

    console.log(unit);
  }

  delete() {
    this.alertCtrl
      .create({
        header: this.translate.instant('SagaMobile.UA.ConfirmDelete.Title'),
        message: this.translate.instant('SagaMobile.UA.ConfirmDelete.Text'),
        buttons: [
          {
            text: this.translate.instant('SagaMobile.UA.ConfirmDelete.Cancel'),
            handler: () => {}
          },
          {
            text: this.translate.instant('SagaMobile.UA.ConfirmDelete.Destroy'),
            handler: async () => {
              await this.modalCtrl.dismiss();
              await this.modal.presentLoading('SagaMobile.UA.Destroying');
              await this.unitActivityService.terminateUnitActivity(this.unitActivity);
              await this.modal.dismissLoading();
            }
          }
        ]
      })
      .then(ionAlert => ionAlert.present());
  }
}
