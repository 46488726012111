import { Feature, ItineraryStepGeometry, Map, ToolLayer, transformExtent, transformGeometry } from '@techwan/mapping';
import { ItineraryStep } from './itinerary-step';

export class ItineraryDirection {
  name: string;
  length: number;
  time: number;

  extent: [number, number, number, number];
  epsg: number;

  steps: ItineraryStep[];

  itineraryGeom: any;
  stepFeature: Feature;

  constructor(map: Map, data: any) {
    this.name = data.directions[0].routeName;
    this.length = data.directions[0].summary.totalLength;
    this.time = 60 * data.directions[0].summary.totalTime;
    this.extent = [
      data.directions[0].summary.envelope.xmin,
      data.directions[0].summary.envelope.ymin,
      data.directions[0].summary.envelope.xmax,
      data.directions[0].summary.envelope.ymax
    ];
    this.epsg = data.directions[0].summary.envelope.spatialReference.wkid;

    this.steps = data.directions[0].features.map(feature => new ItineraryStep(feature, this.epsg));

    this.stepFeature = new Feature(null);

    const geom = new ItineraryStepGeometry(data.routes.features[0].geometry.paths[0]);
    this.itineraryGeom = transformGeometry(geom, this.epsg, map.getView().getProjection());

    this.stepFeature.setId('direction-step');
    this.stepFeature.setStrokeWidth(8);
    this.stepFeature.setStrokeColor([255, 0, 0, 0.6]);
  }

  fit(map: Map) {
    const extent = transformExtent(this.extent, this.epsg, map.getView().getProjection());
    map.getView().fit(extent, { duration: 600 });
  }

  displayStepOnLayer(step: ItineraryStep, layer: ToolLayer, map: Map) {
    if (!layer.getSource().getFeatureById('direction-step')) {
      layer.getSource().addFeature(this.stepFeature);
    }

    this.stepFeature.setGeometry(step.transform(map));
  }
}
