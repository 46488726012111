import { CardTitleFormatterService, CurrentCardService, ModalFactoryService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { TranslateService } from '@ngx-translate/core';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';
import { AttachmentModalComponent } from '../../components/attachment-modal/attachment-modal.component';
import { AttachmentsCardButton } from '../../schema/attachments-card-button';

@Injectable()
export class CardButtonControllerService {
  private subs: Subscription[] = [];

  private cardAttachmentsButton: AttachmentsCardButton = null;
  private cardAttachments: List<CAttachment> = null;
  private cardAttachmentsSub: Subscription = null;
  private filterRemover: Function = null;

  constructor(
    private modalFactory: ModalFactoryService,
    private cache: CacheService,
    private currentCardService: CurrentCardService,
    private titleFormatter: CardTitleFormatterService,
    private translate: TranslateService
  ) {}

  setup(button: AttachmentsCardButton) {
    this.cardAttachmentsButton = button;
    this.subs.push(button.onClick.subscribe(() => this.onButtonClicked()));
    this.subs.push(this.currentCardService.$change.subscribe(callcard => this.onCurrentCardChanged(callcard)));
    this.subs.push(this.cache.state.subscribe(state => this.onCacheStateChanged(state)));
  }

  cleanup() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }

  private onButtonClicked() {
    const card: CallCard = this.currentCardService.current;
    const title: string = this.titleFormatter.format(card);

    this.modalFactory.customModal<void>({
      title: 'Mobile.AttachmentList',
      buttons: 'close',
      content: AttachmentModalComponent,
      data: { cardId: card.ObjGuid, title: title }
    });
  }

  private onCurrentCardChanged(card: CallCard) {
    if (this.cardAttachments) {
      this.cardAttachments.refresh();
    }
  }

  private onCacheStateChanged(state: CacheState) {
    if (state === CacheState.ready) {
      this.cardAttachments = this.cache.getListOf(CAttachment);
      this.cardAttachmentsSub = this.cardAttachments.onRefresh.subscribe(() => this.onAttachmentsChanged());
      this.filterRemover = this.cardAttachments.addFilter(
        attachment => this.currentCardService.current && this.currentCardService.current.ObjGuid == attachment.CallCardId
      );
    } else {
      if (this.cardAttachmentsSub) {
        this.cardAttachmentsSub.unsubscribe();
        this.cardAttachmentsSub = null;
        if (this.filterRemover) {
          this.filterRemover();
          this.filterRemover = null;
        }
        this.cache.unregisterListOf(CAttachment, this.cardAttachments);
        this.cardAttachments = null;
      }
    }
  }

  private onAttachmentsChanged() {
    const attachmentsCount: number = this.cardAttachments.items.length;
    this.cardAttachmentsButton.show = attachmentsCount > 0;
    const titleTranslated = this.translate.instant(this.cardAttachmentsButton.ATTACHMENTS_TITLE);
    this.cardAttachmentsButton.text = `${titleTranslated} (${attachmentsCount})`;
  }
}
