import { IModal, ModalButtonImpl, ModalFactoryService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { UploadFeedbackComponent } from '../../components/upload-feedback/upload-feedback.component';
import { IUploadFeedback } from '../../schema/interfaces/IUploadFeedback';

@Injectable({
  providedIn: 'root'
})
export class UploadFeedbackService implements IUploadFeedback {
  private readonly DISMISS_ACTION = '0';
  private readonly ACCEPT_ACTION = '1';

  private _delayButton: ModalButtonImpl;
  private _acceptButton: ModalButtonImpl;

  private _modalInstance: IModal<string> = null;

  private _confirmPromiseResolve: any = null;

  private _statusText: string = null;

  constructor(private _modalFactory: ModalFactoryService, private _translate: TranslateService) {}

  getStatus(): string {
    return this._statusText;
  }

  show(): Promise<IModal<string>> {
    return this.createUploadFeedbackModal();
  }

  private createUploadFeedbackModal(): Promise<IModal<string>> {
    this.createButtons();
    return this._modalFactory
      .customModalWithInstance<string>(
        {
          title: 'Mobile.CreateAttachmentModal',
          buttons: [this._delayButton, this._acceptButton],
          content: UploadFeedbackComponent
        },
        false
      )
      .then(modal => {
        this._modalInstance = modal;
        this._modalInstance.onDismiss.then(result => this.onModalDismiss(result));
        return modal;
      });
  }

  private createButtons() {
    this._delayButton = this._modalFactory.createModalButton({
      text: 'Mobile.Delay',
      action: this.DISMISS_ACTION,
      color: 'primary',
      isHidden: true
    });
    this._acceptButton = this._modalFactory.createModalButton({
      text: 'Mobile.Accept',
      action: this.ACCEPT_ACTION,
      color: 'primary',
      isHidden: true
    });
  }

  onMakeVersion() {
    this._statusText = this._translate.instant('Mobile.MakeVersion');
  }

  onCreate() {
    this._statusText = this._translate.instant('Mobile.CreateMediaMessage');
  }

  dismiss(): Promise<boolean> {
    return this.dismissModal();
  }

  private dismissModal(): Promise<boolean> {
    if (this._modalInstance) {
      return this._modalInstance.modal.dismiss(null);
    } else {
      return Promise.resolve(false);
    }
  }

  onError() {
    this._statusText = this._translate.instant('Mobile.ErrorWhileUploading');
    this._acceptButton.setHidden(false);
  }

  confirmBigFileUpload(): Promise<boolean> {
    this._statusText = this._translate.instant('Mobile.ConfirmUpload');
    this._delayButton.setHidden(false);
    this._acceptButton.setHidden(false);
    return new Promise<boolean>(resolve => (this._confirmPromiseResolve = resolve));
  }

  private onModalDismiss(res: OverlayEventDetail<string>): void {
    this._modalInstance = null;

    // The modal has been discarded programmatically
    if (res.data === null) {
      return;
    }

    this._confirmPromiseResolve && this._confirmPromiseResolve(res.data === this.ACCEPT_ACTION);
  }
}
