import { Injectable } from '@angular/core';
import { TileLoader } from '@techwan/mapping';

@Injectable({
  providedIn: 'root'
})
export class TileLoaderProviderService {
  tileLoader: TileLoader = null;
  isMapAvailable: boolean = false;

  constructor() {}
}
