import { Tool } from '@services/tools';
import { Observable, Subject } from 'rxjs';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

export interface Context {
  callcard: CallCard;
}

export class PictureAccessTool extends Tool {
  private readonly _event = new Subject<Context>();
  public get executor(): Observable<Context> {
    return this._event.asObservable();
  }

  public constructor() {
    super('Saga.AttachmentType.Picture', 'camera', '', 0, ctx => this._event.next(ctx));
  }
}
