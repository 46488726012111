import { Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { MapFullViewBaseComponent } from '../../components/full-map-view-base/map-full-view-base.component';

/**
 * @description
 * A view for the Saga Mapping System
 */
@AddinView({
  name: 'Full',
  description: 'A view for the Saga Mapping System'
})
@Component({
  templateUrl: 'map-full-view.component.html',
  styleUrls: ['map-full-view.component.scss'],
  selector: 'map-full-view'
})
export class MapFullViewComponent extends MapFullViewBaseComponent implements OnInit {
  ngOnInit(): void {}
}
