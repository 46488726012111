import { Inject, Injectable } from '@angular/core';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { Guid } from '@models/guid';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PROVIDER_ERROR_REPORTER } from '../../schema/injection-token';
import { ILocationProvider } from '../../schema/interfaces/location-provider';
import { LocationProviderErrorReporter } from '../../schema/interfaces/location-provider-error-reporter';
import { BackgroundLocationService } from './background-location/background-location.service';
import { NavigatorLocationService } from './navigator-location/navigator-location.service';

@Injectable()
export class LocationProviderService {
  get $location(): Observable<Geoposition> {
    return this.provider.$change;
  }

  /**
   * Current provider which is used to fetch and listen to new position.
   */
  private _provider: ILocationProvider = null;

  private _started: boolean = false;
  get running(): boolean {
    return this._started;
  }

  get provider(): ILocationProvider {
    if (this._provider === null) {
      this._provider = this.getProvider();
    }
    return this._provider;
  }

  constructor(
    private _platform: Platform,
    private _native: BackgroundLocationService,
    private _navigator: NavigatorLocationService,
    @Inject(PROVIDER_ERROR_REPORTER)
    private _errorReporter: LocationProviderErrorReporter
  ) {}

  private getProvider(): ILocationProvider {
    return this._platform.is('cordova') ? this._native : 'geolocation' in navigator ? this._navigator : null;
  }

  getCurrent(): Observable<Geoposition> {
    return this.provider === null ? this._errorReporter.unknownProvider().pipe(map(() => null)) : this._provider.getCurrent();
  }

  start(uid: Guid): Observable<void> {
    return this._started === true ? of() : this.startProvider(uid);
  }

  /**
   * Starts the correct provider according to the platform on which the application is running.
   * @param uid id of my current resource in Saga's system
   */
  private startProvider(uid: Guid): Observable<void> {
    const provider = this.provider;
    if (provider === null) {
      return this._errorReporter.unknownProvider();
    }

    this._started = true;
    provider.setup(uid);
    return provider.start();
  }

  stop(): Observable<void> {
    return this._started === false ? of() : this.provider.stop();
  }
}
