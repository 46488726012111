// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TraceItem_abstract extends PersistentObject {
  static $t: string = 'SagaBase.TraceItem';
  $t: string = 'SagaBase.TraceItem';

  public static GetMembers(): string[] {
    return ['AuditId', 'ObjectUId', 'ObjectName', 'CreatedUTC', 'UserId', 'UserName', 'XmlTrace'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return TraceItem_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.CreatedUTC = this.CreatedUTC && new Date(this.CreatedUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public AuditId: number = 0;
  public ObjectUId: Guid = GuidTools.Empty;
  public ObjectName: string;
  public CreatedUTC: Date = new Date();
  public UserId: number = 0;
  public UserName: string;
  public XmlTrace: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
