import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusChangeResult } from '@models/imported/SagaBase';
import { ToastColor, ToastDuration, ToastService } from '@services/toast/toast.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface StatusParameters {
  callCardUid?: string;
  parameter?: string;
  peopleCount?: number;
  unitVehiclesToUpdate?: string[];
}

interface SetActivityStatusRequest extends StatusParameters {
  unitActivityUid: string;
  newStatus: number;
}

@Injectable()
export class StatusProxyService {
  constructor(private httpClient: HttpClient, private toastService: ToastService) {}

  setActivityStatus(unitActivityUid: string, newStatus: number, statusParameters: StatusParameters = {}): Observable<StatusChangeResult> {
    const requestParameters: SetActivityStatusRequest = Object.assign(
      {
        unitActivityUid,
        newStatus
      },
      statusParameters
    );

    return this.httpClient
      .post<StatusChangeResult>('api/Status/SetActivityStatus', requestParameters)
      .pipe(catchError((error: HttpErrorResponse, caught: Observable<StatusChangeResult>) => this.onError(error, caught)));
  }

  private onError(error: HttpErrorResponse, caught: Observable<StatusChangeResult>): Observable<StatusChangeResult> {
    this.toastService.show(
      'Mobile.StatusChangeFailed',
      ToastColor.Error,
      {
        statusChangeResult: error.statusText
      },
      ToastDuration.Long
    );

    return throwError(error);
  }
}
