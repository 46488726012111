import { CurrentCardService, MyCardService } from '@addins/core/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Initializer } from '@services/initializer/initializer.service';
import { SectorService } from '@services/sector/sector.service';
import { SagaSettingsService } from '@services/settings';
import { AddinView } from '@techwan/ionic-core';
import { CallCardListBaseComponent } from '../../components/callcard-list-base/callcard-list-base.component';
import { CallCardsProviderService } from '../../services/callcards-provider/callcards-provider.service';

export interface CardGroup {
  name: string;
  cards: CallCard[];
  groupFilter(callcard: CallCard): boolean;
  cssClass?: string;
}

@Component({
  selector: 'app-callcard-list-full',
  templateUrl: './callcard-list-full.component.html',
  styleUrls: ['./callcard-list-full.component.scss']
})
@AddinView({
  name: 'CardListFull',
  description: "Use to display a list of CallCards read from the CallCard's service",
  icon: 'list'
})
export class CallcardListFullComponent extends CallCardListBaseComponent implements OnInit, OnDestroy {
  private readonly OTHER_CARDS_GROUP_NAME: string = 'Mobile.OthersCardTitle';
  private readonly MY_SECTOR_CARDS_GROUP_NAME: string = 'Mobile.MySectorCardsTitle';

  cardGroups: CardGroup[] = [{ name: this.OTHER_CARDS_GROUP_NAME, cards: [], groupFilter: () => true }];

  get myCard() {
    return this._myCardService.myCard;
  }

  showMySector: boolean = false;
  showCallCardCount: boolean = false;

  private removerFunctions: Function[] = [];

  constructor(
    protected _initializer: Initializer,
    protected _callCardsProvider: CallCardsProviderService,
    private _myCardService: MyCardService,
    private _currentCardService: CurrentCardService,
    private _sagaSettings: SagaSettingsService,
    private _sectorService: SectorService
  ) {
    super(_initializer, _callCardsProvider);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    while (this.removerFunctions.length > 0) {
      this.removerFunctions.pop()();
    }
  }

  protected onComplete(isComplete: boolean) {
    super.onComplete(isComplete);

    if (isComplete === true) {
      this.setupCardsSorting();
      this.setupCardsGroups();

      this.cards.onRefresh.subscribe(cards => this.onCallcardsChanged(cards));
      this.onCallcardsChanged(this.cards.items);
    }
  }

  private setupCardsSorting() {
    const callcardsSortByField: string = this._sagaSettings.getValue('SagaMobileWebClient.CallcardsSortByField');
    if (callcardsSortByField === 'Location') {
      this.removerFunctions.push(this.cards.addSort((a, b) => this.locationCompare(a, b)));
    } else {
      this.removerFunctions.push(this.cards.addSort((a, b) => a.compare(b)));
    }
  }

  private locationCompare(a: CallCard, b: CallCard): number {
    const locationA: CLocation = a.location;
    const locationB: CLocation = b.location;

    return (
      locationA.CityName.localeCompare(locationB.CityName) ||
      locationA.PlaceName.localeCompare(locationB.PlaceName) ||
      locationA.StreetNumber.localeCompare(locationB.StreetNumber) ||
      a.compare(b)
    );
  }

  private setupCardsGroups() {
    this.showCallCardCount = this._sagaSettings.getValue('SagaMobileWebClient.ShowCallCardCount');
    this.showMySector = this._sagaSettings.getValue('SagaMobileWebClient.ShowMySector');

    if (this.showMySector) {
      this.cardGroups = [
        { name: this.MY_SECTOR_CARDS_GROUP_NAME, cards: [], groupFilter: callcard => this.isInMySector(callcard), cssClass: 'mySector' },
        { name: this.OTHER_CARDS_GROUP_NAME, cards: [], groupFilter: callcard => !this.isInMySector(callcard), cssClass: 'otherSector' }
      ];
    }
  }

  private isInMySector(callcard: CallCard): boolean {
    return callcard.Owner === this._sectorService.myZone.ParentId;
  }

  private onCallcardsChanged(callcards: CallCard[]) {
    this.cardGroups.forEach(cardGroup => (cardGroup.cards = callcards.filter(cardGroup.groupFilter)));
  }

  open(callcard: CallCard) {
    this._currentCardService.select(callcard);
  }
}
