<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-title>
            <span translate>SagaMobile.UA.AddEquipments</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">
                <ion-icon slot="icon-only"  name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <ion-list>
        <ion-item *ngFor="let device of devices" (click)="itemSelected(device)">
            <ion-icon [name]="device.icon" slot="start" [color]="device.color"></ion-icon>
            <h2>{{device.rShort()}}</h2>
        </ion-item>
    </ion-list>
</ion-content>
