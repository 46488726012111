import { Injector, NgModule } from '@angular/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { DownloadUrlTransformService } from './services/download-url-transform/download-url-transform.service';
import { FileBridgeService } from './services/file-bridge/file-bridge.service';
import { FileDownloadService } from './services/file-download/file-download.service';
import { FileSystemService } from './services/file-system/file-system.service';

const views = [];

const components = [...views];

const directives = [];

const executables = [];

const providers = [DownloadUrlTransformService, FileBridgeService, FileDownloadService, FileSystemService, ...executables];

@Addin({
  name: 'File'
})
@NgModule({
  declarations: [...components, ...directives],
  providers
})
export class FileModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
