import { MediaProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ToolsProvider, ToolsProviderService } from '@services/tools';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { MediaType } from '../../../schema/media/media-type';
import { Context, VideoAccessTool } from '../../../schema/video-access-tool';
import { AttachmentService } from '../../attachment/attachment.service';

@AddinExecute({
  name: 'VideoAccess'
})
@Injectable({
  providedIn: 'root'
})
export class VideoAccessToolService extends Execute {
  private videoTool = new VideoAccessTool();

  constructor(
    private toolsProvider: ToolsProviderService,
    private mediaProvider: MediaProviderService,
    private attachments: AttachmentService
  ) {
    super();
  }

  init() {
    super.init();
    this.toolsProvider.register(toolsProvider => this.onProvided(toolsProvider));
    this.mediaProvider.register(this.videoTool);
  }

  execute(): void {
    this.videoTool.executor.subscribe(ctx => this.createMediaMessage(ctx));
  }

  private onProvided(provider: ToolsProvider) {
    const [ctx] = provider.context;
    if (ctx === CallCard) {
      provider.addTool(this.videoTool);
    }
  }

  private createMediaMessage(ctx: Context) {
    this.attachments.createMediaMessage(ctx.callcard.ObjGuid, MediaType.Video);
  }
}
