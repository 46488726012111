import { ButtonItem, MapService } from '@services/map';

export class LocalizeButton implements ButtonItem {
  icon = 'locate';

  get hidden() {
    return this.mapService.moveToPosition;
  }

  constructor(private mapService: MapService, public context: any, public handler: Function) {
  }
}
