import { AfterViewInit, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDestination } from '../../schema/interfaces/IDestination';
import { SelectNavigationTool } from '../../schema/select-navigation-tool';
import { DestinationProviderService } from '../../services/destination-provider/destination-provider.service';
import { Destination } from '@addins/singlecallcard-destination/schema/destination';

@Component({
  selector: 'app-destination-select',
  templateUrl: './destination-select.component.html',
  styleUrls: ['./destination-select.component.scss']
})
export class DestinationSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  private _toolSelect = new SelectNavigationTool(this.destination);
  private _hideLabel: boolean = false;

  get select(): SelectNavigationTool {
    return this._toolSelect;
  }

  get hideLabel(): boolean {
    return this._hideLabel;
  }

  private _sub: Subscription[] = [];

  constructor(private destination: DestinationProviderService, private changeDetectionRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.destination.isReady) {
      this._sub.push(this.destination.state.subscribe(() => this._toolSelect.refresh()));
    }
  }

  ngAfterViewInit() {
    this._hideLabel = this.destination.value ? true : false;
    this._sub.push(this._toolSelect.onChange.subscribe(destination => this.validate(destination)));

    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy() {
    while (this._sub.length > 0) {
      this._sub.pop().unsubscribe();
    }
  }

  private validate(item: IDestination): void {
    // Sets the current destination
    const value = this.destination.services.find((destination: Destination) => destination.ResAddressUid === item.selection) || null;
    const current = this.destination.value;

    // Change the current destination's value according to the choice
    if (value !== null && value !== current) {
      this.destination.value = value;
      this.destination.select(value).subscribe();
      this._hideLabel = true;
    } else {
      this._hideLabel = false;
    }
  }
}
