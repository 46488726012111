// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Task_abstract extends SagaObject {
  static $t: string = 'SagaSchema.Task';
  $t: string = 'SagaSchema.Task';

  public static GetMembers(): string[] {
    return [
      'Id',
      'CreatorId',
      'CreatorName',
      'ParentTaskUid',
      'Text',
      'TaskType',
      'StartDateUTC',
      'EndDateUTC',
      'Name',
      'ProjectType',
      'Archived',
      'SubmittedUTC',
      'ArchiveTypeId',
      'Type',
      'Identifier'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Task_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.StartDateUTC = this.StartDateUTC && new Date(this.StartDateUTC);
    this.EndDateUTC = this.EndDateUTC && new Date(this.EndDateUTC);
    this.SubmittedUTC = this.SubmittedUTC && new Date(this.SubmittedUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.CCaller',
      'SagaSchema.CEvent',
      'SagaSchema.CLocation',
      'SagaBase.PhoneCall',
      'SagaSchema.CStampCard',
      'SagaSchema.CStampResource',
      'SagaSchema.CStampFree',
      'SagaSchema.CStampProcedure',
      'SagaSchema.CStampProcedureAction',
      'SagaSchema.CStampUnitActivity',
      'SagaSchema.CNote',
      'SagaSchema.CAttachment',
      'SagaSchema.CObject',
      'SagaSchema.CPerson',
      'SagaSchema.CAnimal',
      'SagaSchema.CVehicle',
      'SagaSchema.TaskFrequency',
      'SagaSchema.CTacticalSituation',
      'SagaSchema.CTaskRequirement',
      'SagaSchema.CProcStep',
      'SagaSchema.CFreeText',
      'SagaSchema.CStampFreeText'
    ];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public CreatorId: number;
  public CreatorName: string = '';
  public ParentTaskUid: Guid = GuidTools.Empty;
  public Text: string;
  public TaskType: SagaBase.TaskTypes = SagaBase.TaskTypes.Task;
  public StartDateUTC: Date = new Date();
  public EndDateUTC: Date = new Date();
  public Name: string;
  public ProjectType: number;
  public Archived: boolean = false;
  public SubmittedUTC: Date = new Date();
  public ArchiveTypeId: any;
  public Type: number = 0;
  public Identifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
