import { FileBridgeService, FileSystemService } from '@addins/core/file';
import { Injectable } from '@angular/core';
import { DirectoryEntry, FileEntry } from '@ionic-native/file';
import { TileLoader } from '@techwan/mapping';
import { OFFLINE_MAP_FOLDER } from '../map-download/map-download.service';

@Injectable({
  providedIn: 'root'
})
export class TileLoaderService implements TileLoader {
  constructor(private fileSystem: FileSystemService, private fileBridge: FileBridgeService) {}

  loadTile(tileRelativePath: string): Promise<string> {
    return this.fileSystem.getRoot().then((directoryEntry: DirectoryEntry) =>
      this.fileBridge
        .getEntry(`${directoryEntry.nativeURL}${OFFLINE_MAP_FOLDER}/${tileRelativePath}`)
        .then((fileEntry: FileEntry) => this.fileBridge.readFileAsDataUrl(fileEntry))
        .catch(err => {
          console.log(`error reading tile ${OFFLINE_MAP_FOLDER}/${tileRelativePath}`);
          throw err;
        })
    );
  }
}
