import { LocationProviderService } from '@addins/core/core';
import { Component } from '@angular/core';
import { ViewEntry } from '@schema/ViewEntry';
import { Events } from '@services/events/events.service';
import { SagaSettingsService } from '@services/settings';
import { AddinView, MyEquipmentService } from '@techwan/ionic-core';
import { first } from 'rxjs/operators';
import { HomeBase } from '../../schema/HomeBase';

@Component({
  templateUrl: 'generic.component.html',
  styleUrls: ['generic.component.scss']
})
@AddinView({
  name: 'generic'
})
export class GenericHomeComponent extends HomeBase {
  constructor(
    private settings: SagaSettingsService,
    eventService: Events,
    protected locationProvider: LocationProviderService,
    private equipmentService: MyEquipmentService
  ) {
    super(eventService, locationProvider);
    this.equipmentService.myDevice.pipe(first()).subscribe(device => locationProvider.start(device.ObjGuid));
  }

  getEntryIcon(entry: ViewEntry): string {
    return this.settings.getValue('SagaMobileWebClient.Images.' + entry.icon);
  }
}
