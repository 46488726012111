import { MediaProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ToolsProvider, ToolsProviderService } from '@services/tools';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { GalleryAccessTool } from '../../../schema/gallery-access-tool';
import { MediaType } from '../../../schema/media/media-type';
import { Context, PictureAccessTool } from '../../../schema/picture-access-tool';
import { AttachmentService } from '../../attachment/attachment.service';

@AddinExecute({
  name: 'PictureAccess'
})
@Injectable()
export class PictureAccessToolService extends Execute {
  private cameraTool = new PictureAccessTool();
  private galleryTool = new GalleryAccessTool();

  private provider: ToolsProvider = null;

  public constructor(
    private toolsProvider: ToolsProviderService,
    private platform: Platform,
    private mediaProvider: MediaProviderService,
    private attachments: AttachmentService
  ) {
    super();
  }

  execute(): void {
    this.toolsProvider.register(toolsProvider => this.onProvided(toolsProvider));

    // For every platform
    this.mediaProvider.register(this.cameraTool);
    this.cameraTool.executor.subscribe(ctx => this.createMediaMessage(ctx));

    // Only for mobile devices
    if (this.platform.is('cordova')) {
      this.mediaProvider.register(this.galleryTool);
      this.galleryTool.executor.subscribe(ctx => this.createMediaMessage(ctx, true));
    }
  }

  private onProvided(provider: ToolsProvider): void {
    const [ctx] = provider.context;
    if (ctx === CallCard) {
      this.provider = provider;
      this.provider.addTool(this.cameraTool);

      if (this.platform.is('cordova')) {
        this.provider.addTool(this.galleryTool);
      }
    }
  }

  private createMediaMessage(ctx: Context, fromFile = false) {
    this.attachments.createMediaMessage(ctx.callcard.ObjGuid, fromFile === false ? MediaType.Picture : MediaType.File);
  }
}
