<ion-item (click)="onSelect()">
  <ion-avatar slot="start" [ngClass]="{ new: isNew }">
    <ion-icon [icon]="stampIcon"></ion-icon>
  </ion-avatar>
  <ion-label class="ion-text-nowrap">
    <h3 class="ellipses">
      <strong
        >{{ item.CreatedUTC | moment: 'LLL' }}&nbsp;&nbsp;<span>{{ stampName | translate }}</span></strong
      >
    </h3>
    <h3>{{ item.UserName }}</h3>
    <p [ngClass]="{ expanded: isExpanded }">{{ stampText | translateKeys }}</p>
  </ion-label>
</ion-item>
