import { AdditionalViewToolsService, PanelToolBase, PanelToolGroupNames } from '@addins/core/panel';
import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { AppSettingProviderService } from '@services/settings';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuState, PermanentEntry } from '../../schema/permanent-entry';
import { PermanentMenuService } from '../../services/permanent-menu/permanent-menu.service';
import { ViewLoaderService } from '../../services/view-loader/view-loader.service';

interface MenuSize {
  menuWidth: string;
  iconSize: string;
}

@Component({
  selector: 'app-permanent-menu',
  templateUrl: './permanent-menu.component.html',
  styleUrls: ['./permanent-menu.component.scss']
})
export class PermanentMenuComponent implements OnInit, OnDestroy {
  private readonly MENU_SIZES: Map<boolean, MenuSize> = new Map([
    [false, { menuWidth: '50px', iconSize: 'large' }],
    [true, { menuWidth: '84px', iconSize: 'xlarge' }]
  ]);

  menus: PermanentEntry[];
  statusViewTool: PanelToolBase = null;

  private _menuIconSize: string = this.MENU_SIZES.get(false).iconSize;
  get menuIconSize(): string {
    return this._menuIconSize;
  }

  private _current: PermanentEntry = null;

  private _sub: Subscription[] = [];

  constructor(
    private _permanentMenu: PermanentMenuService,
    private _viewLoader: ViewLoaderService,
    private _additionalViewToolsService: AdditionalViewToolsService,
    private _appSettingProvider: AppSettingProviderService
  ) {}

  ngOnInit() {
    this.setupMenuSizeListener();

    this.menus = this._permanentMenu.getEntries();
    this._sub.push(this._viewLoader.onChange.subscribe(view => this.$onViewChanged(view)));

    this.onStatusViewToolChanged();
    this._sub.push(
      this._additionalViewToolsService.$change
        .pipe(filter(panelChange => panelChange.tool && panelChange.tool.groupName === PanelToolGroupNames.StatusToolGroup))
        .subscribe(() => this.onStatusViewToolChanged())
    );
  }

  private setupMenuSizeListener() {
    const PERMANENT_MENU_WIDTH: string = '--permanent-menu-width';
    const PERMANENT_MENU_BUTTON_HEIGHT: string = '--permanent-menu-button-height';

    this._sub.push(
      this._appSettingProvider.getLargeMenu().subscribe(appSetting => {
        appSetting.$change.subscribe(value => {
          const menuSize: MenuSize = this.MENU_SIZES.get(value);
          const menuWidth = menuSize.menuWidth;

          const currentPermanentMenuWidth: string = getComputedStyle(document.documentElement)
            .getPropertyValue(PERMANENT_MENU_WIDTH)
            .trim();

          if (menuWidth !== currentPermanentMenuWidth) {
            document.documentElement.style.setProperty(PERMANENT_MENU_WIDTH, menuWidth);
            document.documentElement.style.setProperty(PERMANENT_MENU_BUTTON_HEIGHT, menuWidth);
          }

          this._menuIconSize = menuSize.iconSize;
        });
      })
    );
  }

  private $onViewChanged(view: Type<any>) {
    const entry = this.menus.filter(menuEntry => menuEntry.type && menuEntry.type === view)[0];
    if (entry !== this._current) {
      this.clearCurrent();
      this.setCurrent(entry);
    }
  }

  private clearCurrent() {
    if (this._current !== null) {
      this._current.reset();
    }
  }

  private setCurrent(permanentEntry: PermanentEntry) {
    this._current = permanentEntry;
    permanentEntry.setState(MenuState.active);
  }

  private onStatusViewToolChanged() {
    const statusToolGroup = this._additionalViewToolsService.getTools(PanelToolGroupNames.StatusToolGroup);
    this.statusViewTool = statusToolGroup.length ? statusToolGroup.pop() : null;
  }

  ngOnDestroy(): void {
    this.clearCurrent();
    this._permanentMenu.cleanUp();
    while (this._sub.length > 0) {
      this._sub.pop().unsubscribe();
    }
  }
}
