import { Injectable } from '@angular/core';
import { Map as SagaMap } from '@techwan/mapping';
import { Observable, Subject } from 'rxjs';
import { MapInstanceEvent, MapInstanceEventType } from '../schema/interfaces/map-instance-event';

@Injectable({
  providedIn: 'root'
})
export class MapInstancesService {
  private readonly _instances: Map<string, SagaMap> = new Map();

  private readonly _change = new Subject<MapInstanceEvent>();
  get $change(): Observable<MapInstanceEvent> {
    return this._change.asObservable();
  }

  constructor() {}

  public add(name: string, map: SagaMap): SagaMap {
    let old: SagaMap;
    if (!this._instances.has(name)) {
      this._instances.set(name, map);
      this._change.next({ name, type: MapInstanceEventType.added, target: map });
    } else if (this._instances.get(name) !== map) {
      old = this.get(name);
      this.delete(name, false);
      this._instances.set(name, map);
      this._change.next({ name, old, type: MapInstanceEventType.changed, target: map });
    }
    return map;
  }

  public remove(name: string): boolean {
    return this.delete(name);
  }

  public get(name: string): SagaMap {
    return this._instances.has(name) ? this._instances.get(name) : null;
  }

  public getMapForView(view: ol.View): SagaMap {
    const idx = Array.from(this._instances.keys()).find(i => this._instances.get(i).getView() === view) || null;
    return idx !== null ? this._instances.get(idx) : null;
  }

  private delete(name: string, notify = true): boolean {
    const old = this._instances.get(name);
    const ret = this._instances.delete(name);
    if (notify === true && ret) {
      this._change.next({ name, old, target: null, type: MapInstanceEventType.removed });
    }
    return ret;
  }
}
