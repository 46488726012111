// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class KeywordSearchObject_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Objects.KeywordSearchObject';
  $t: string = 'SagaBase.Objects.KeywordSearchObject';

  public static GetMembers(): string[] {
    return [
      'Url',
      'Complement',
      'OriginalText',
      'DataSource',
      'Id',
      'TableId',
      'TableName',
      'SearchTypeId',
      'Text',
      'LinkName',
      'LinkId',
      'MapX',
      'MapY',
      'Weight',
      'PrimarySortOrder',
      'ProjCode',
      'LinkedObject'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return KeywordSearchObject_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Url: string;
  public Complement: string;
  public OriginalText: string;
  public DataSource: string;
  public Id: any;
  public TableId: number = 0;
  public TableName: string;
  public SearchTypeId: number = 0;
  public Text: string;
  public LinkName: string;
  public LinkId: number = 0;
  public MapX: number = -1.79769313486232e308;
  public MapY: number = -1.79769313486232e308;
  public Weight: number = 0;
  public PrimarySortOrder: number = 0;
  public ProjCode: string = 'EPSG:4326';
  public LinkedObject: any;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
