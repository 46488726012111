import { Injectable } from '@angular/core';
import { IPollingModalProvider } from '@techwan/ionic-core';
import { from, Observable } from 'rxjs';
import { ModalFactoryService } from '@addins/core/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class UnitPollingModalService implements IPollingModalProvider {
  constructor(private readonly _modal: ModalFactoryService, private readonly _translate: TranslateService) {}

  show(): Observable<void> {
    return from(
      this._modal.simpleModal({
        buttons: 'Mobile.Restart',
        title: this._translate.instant('Mobile.RetryUnitCheck'),
        text: this._translate.instant('Mobile.RetryUnitCheckText')
      })
    ).pipe(map(() => null));
  }
}
