import { Component, OnInit } from '@angular/core';
import { LogoutService } from '@services/logout/logout.service';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';

@Component({
  selector: 'app-connectivity-settings',
  templateUrl: './connectivity-settings.component.html',
  styleUrls: ['./connectivity-settings.component.scss']
})
export class ConnectivitySettingsComponent implements OnInit {
  constructor(private translatedAlert: TranslatedAlertService, private logoutService: LogoutService) {}

  ngOnInit() {}

  onReload() {
    this.translatedAlert
      .show({
        header: 'SagaMobile.Reload',
        buttons: [
          {
            text: 'Mobile.Cancel',
            role: 'cancel',
            value: false
          },
          {
            text: 'Mobile.Confirm',
            value: true
          }
        ]
      })
      .then(confirmed => {
        if (confirmed) {
          location.reload();
        }
      });
  }

  logout() {
    this.logoutService.logout();
  }
}
