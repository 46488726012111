import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { SagaSettingsService } from '@services/settings';
import { Subscription } from 'rxjs';
import { PanelToolBase } from '../..';
import { Panel } from '../../schema/interfaces/Panel';
import { PanelDismissTool } from '../../schema/interfaces/panel-dismiss-tool';
import { AdditionalViewContext } from '../../schema/panel-context/additional-view-context';
import { AdditionalViewToolsService } from '../../services/panel-factory/additional-view-tools.service';
import { PanelPinComponent } from '../panel-pin/panel-pin.component';

@Component({
  selector: 'app-panel-content',
  templateUrl: './panel-content.component.html',
  styleUrls: ['./panel-content.component.scss']
})
export class PanelContentComponent implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy, Panel {
  private additionalViewContext: AdditionalViewContext;
  private contentChangeSub: Subscription = null;

  private _expanding = false;
  get isExpanding(): boolean {
    return this._expanding;
  }

  private _showPin = false;
  get showPin(): boolean {
    return this._showPin;
  }

  get active(): PanelToolBase {
    return (this.additionalViewContext && this.additionalViewContext.activePanelTool) || null;
  }

  get host(): ElementRef {
    return this.elementRef;
  }

  get localRenderer(): Renderer2 {
    return this.renderer;
  }

  @Input()
  fullHeight?: HTMLElement;

  @ViewChild(PanelPinComponent, { static: true })
  private _autoDismiss: PanelDismissTool = null;
  get autoDismiss(): PanelDismissTool {
    return this._autoDismiss;
  }

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container;
  get content(): ViewContainerRef {
    return this.container;
  }

  @ViewChild('parent', { static: true })
  private parent: ElementRef;
  get maxHeight(): ElementRef {
    return this.parent;
  }

  constructor(
    private additionalViewToolsService: AdditionalViewToolsService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private sagaSettings: SagaSettingsService
  ) {}

  ngOnInit() {
    this.contentChangeSub = this.additionalViewToolsService.$change.subscribe(() => {
      this._showPin = this.active && this.active.hasViewSplitter;
    });
  }

  ngAfterContentInit() {
    this.additionalViewContext = this.additionalViewToolsService.createContextWith(this);
  }

  ngAfterViewChecked() {
    const panelMaxHeight: string = this.sagaSettings.getValue('SagaMobileWebClient.ToolsPanelMaxHeight');
    this.renderer.setStyle(this.host.nativeElement, 'max-height', panelMaxHeight);

    if (this.panelHasContent()) {
      this.setElementHeight(this.container.element.nativeElement.nextSibling);
    }
  }

  private panelHasContent(): boolean {
    //Ionic 6 renders comment next to the container
    const nextSibling = this.container.element.nativeElement.nextSibling;
    return nextSibling && nextSibling.nodeType == 1; // NodeType == 1 -> Element
  }

  private setElementHeight(element: any) {
    this.renderer.setStyle(element, 'height', this.getPreviousContainerSiblingHeight());
  }

  private getPreviousContainerSiblingHeight(): string {
    const previousSibling = this.container.element.nativeElement.previousSibling;
    if (previousSibling) {
      const previousSiblingStyle = getComputedStyle(previousSibling);
      return `calc(100% - ${previousSiblingStyle.height})`;
    }

    return `100%`;
  }

  resize() {
    this.additionalViewContext.onResize();
  }

  ngOnDestroy() {
    if (this.additionalViewContext) {
      this.additionalViewContext.destroy();
    }
    if (this.contentChangeSub) {
      this.contentChangeSub.unsubscribe();
      this.contentChangeSub = null;
    }
  }
}
