// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { CEventBase_abstract } from './../abstract/SagaSchema/CEventBase_abstract';
import { romanize } from '@utils/roman';
export class CEventBase extends CEventBase_abstract {
  get icon() {
    return 'flag';
  }

  get text() {
    return romanize(this.Priority) + '-' + this.EventText;
  }
}
