import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalButtonImpl } from '../../schema/modal-button-impl';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {
  @Input()
  readonly buttons: ModalButtonImpl[];

  constructor(private readonly _modal: ModalController) {}

  ngOnInit() {}

  action(action: string): Promise<boolean> {
    return this._modal.dismiss(action);
  }
}
