import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDescriptor } from '@models/imported/SagaSchema/FileDescriptor';
import { Observable } from 'rxjs';
import { IAttachmentQuery } from '../../schema/interfaces/IAttachmentQuery';
import { IMakeVersionQuery } from '../../schema/interfaces/IMakeVersionQuery';

@Injectable()
export class AttachmentProxyService {
  constructor(private httpClient: HttpClient) {}

  makeVersion(data: IMakeVersionQuery): Observable<FileDescriptor> {
    return this.httpClient.post<FileDescriptor>('api/File/MakeVersion', data);
  }

  createAttachment(data: IAttachmentQuery): Observable<any> {
    return this.httpClient.post<boolean>('api/Attachments/Add', data);
  }

  createMediaMessage(data: IAttachmentQuery): Observable<any> {
    return this.httpClient.post<boolean>('api/MediaMessages/Add', data);
  }
}
