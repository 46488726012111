import { Pipe, PipeTransform } from '@angular/core';
import { ListCountries } from '@models/imported/SagaSchema/ListObjects/ListCountries';
import { ListPersonQualifiers } from '@models/imported/SagaSchema/ListObjects/ListPersonQualifiers';
import { FetchListService } from '@services/fetchlist/fetchlist.service';

enum CallerFieldName {
  QUALIFIER = 'Qualifier',
  NATIONALITY = 'Nationality'
}

@Pipe({
  name: 'caller'
})
export class CallerPipe implements PipeTransform {
  constructor(private fetchListService: FetchListService) {}

  transform(fieldValue: number, callerFieldName: string): string {
    switch (callerFieldName) {
      case CallerFieldName.QUALIFIER:
        return this.getPersonQualifier(fieldValue);
      case CallerFieldName.NATIONALITY:
        return this.getNationality(fieldValue);
      default:
        return null;
    }
  }

  private getPersonQualifier(personQualifierId: number): string {
    return this.fetchListService.getRowValue(ListPersonQualifiers, personQualifierId);
  }

  private getNationality(nationalityId: number): string {
    return this.fetchListService.getRowValue(ListCountries, nationalityId);
  }
}
