import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GuidTools } from '@models/guid';
import { EquipmentAssociationCategory, StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { IStatusChangeRequirement } from '@services/status/status-change-requirements/status-change-requirements.service';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { StatusService } from '@services/status/status.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { DisengagementSetupModalComponent } from '../../components/disengagement-setup-modal/disengagement-setup-modal.component';
import { EngagementSetupModalComponent } from '../../components/engagement-setup-modal/engagement-setup-modal.component';
import { ResourceCountService } from '../resource-count/resource-count.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementRequirementService implements IStatusChangeRequirement {
  private confirmed: boolean;
  private peopleCount: number;
  private vehicle: Vehicle;

  constructor(
    private modalController: ModalController,
    private statusService: StatusService,
    private myUnit: MyUnitService,
    private resourceCount: ResourceCountService
  ) {}

  preChangeRequirement(newStatus: ListResourceStatus, callcard: CallCard): Promise<boolean> {
    this.confirmed = false;
    this.peopleCount = 0;

    if (this.isEngagement(newStatus)) {
      return this.onPreEngagementRequirement(callcard);
    } else if (this.isDisengagement(newStatus, callcard)) {
      return this.onPreChangeRequirement(DisengagementSetupModalComponent, callcard);
    } else {
      return Promise.resolve(true);
    }
  }

  private isEngagement(status: ListResourceStatus): boolean {
    return (
      status === this.statusService.getStatusByAction(StatusItemAction.isAssignement) ||
      status === this.statusService.getStatusByAction(StatusItemAction.isReassignment)
    );
  }

  private isDisengagement(status: ListResourceStatus, callcard: CallCard): boolean {
    return callcard && status.hasAction(StatusItemAction.isCancelAssignment);
  }

  private onPreEngagementRequirement(callcard: CallCard): Promise<boolean> {
    const mainRessource = this.myUnit.mine.mainRessource;
    const needResource =
      mainRessource &&
      mainRessource.AssociationCategory === EquipmentAssociationCategory.Singular &&
      !GuidTools.isValid(mainRessource.EquipmentConsumerGuid);

    if (needResource) {
      return this.onPreChangeRequirement(EngagementSetupModalComponent, callcard);
    } else {
      return this.resourceCount
        .selectEngagedPeople()
        .then(value => {
          this.confirmed = true;
          this.peopleCount = value;
          return true;
        })
        .catch(err => (this.confirmed = false));
    }
  }

  private onPreChangeRequirement(component, callcard: CallCard): Promise<boolean> {
    return this.modalController.create({ component: component, componentProps: { callcard: callcard } }).then(modal =>
      modal.present().then(() =>
        modal.onDidDismiss().then(result => {
          this.confirmed = !!result.data;

          if (this.confirmed) {
            this.peopleCount = result.data.peopleCount;
            this.vehicle = result.data.vehicle;
          }

          return Promise.resolve(this.confirmed);
        })
      )
    );
  }

  postChangeRequirement(newStatus: ListResourceStatus, callcard: CallCard): Promise<boolean> {
    return Promise.resolve(true);
  }

  getStatusParameters(): StatusParameters {
    let statusParameters: StatusParameters = {};
    if (this.confirmed) {
      statusParameters.peopleCount = this.peopleCount;
      if (this.vehicle) {
        statusParameters.unitVehiclesToUpdate = [this.vehicle.ObjGuid];
      }
    }
    return statusParameters;
  }
}
