import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SelectNavigationTool } from '../../schema/select-navigation-tool';

@Component({
  selector: 'destination-selection-tool',
  templateUrl: './selection-tool.component.html',
  styleUrls: ['./selection-tool.component.scss']
})
export class SelectionToolComponent implements OnInit {
  @Input()
  tool?: SelectNavigationTool;

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.tool) {
      this.tool.onRefresh.subscribe(() => this.cd.detectChanges());
    }
  }
}
