// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CrisisFieldInfo_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.CrisisFieldInfo';
  $t: string = 'SagaBase.Defines.CrisisFieldInfo';

  public static GetMembers(): string[] {
    return ['Width', 'Visible', 'Name', 'Filters', 'SortStatus', 'ActualPosition'];
  }

  public GetMembers(): string[] {
    return CrisisFieldInfo_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Width: number = 0;
  public Visible: boolean = false;
  public Name: string;
  public Filters: any[];
  public SortStatus: SagaBase.SortOrder = SagaBase.SortOrder.None;
  public ActualPosition: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
