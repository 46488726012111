import { PermanentEntry } from '@addins/core/menus';
import { MenuEntry } from '@services/menu/entry.schema';
import { PlannedMissionDetailsViewComponent } from '../../views/planned-mission-details-view/planned-mission-details-view.component';

export class PlannedMissionDetailsMenuEntry extends PermanentEntry {
  constructor() {
    super(
      new MenuEntry(
        'PlannedMissionDetails',
        'assets/icons/planned-missions/planned-mission-engaged.svg',
        PlannedMissionDetailsViewComponent,
        910
      )
    );
  }
}
