import { Injectable } from '@angular/core';
import { Logger } from '../logger';
import { IdToTypeConverter } from '@models/imported/createInstanceById';

@Injectable()
export class ObjectFactory {
  // private security:Security
  constructor(private idToTypeConverter: IdToTypeConverter) {}

  create(obj, type = null) {
    const constructor = typeof type === 'function' ? type : this.idToTypeConverter.getConstructorOf(type || obj.$t);

    if (constructor) {
      const instance = new constructor();

      // if(instance instanceof SagaObject)
      //     this.security.setUserInfo(<SagaObject>instance);

      this.updateProperties(obj, instance);
      return instance;
    } else {
      Logger.debug(`Type '${type}' is an unknown Saga object`, 'ObjectFactory');
    }
  }

  clone(obj, recursivly = false) {
    const copy = this.create(obj);

    if (recursivly) {
      if ((copy as any).components) {
        (copy as any).components().forEach(componentType => {
          const list = obj[componentType];
          if (list) {
            const components = (copy as any).component(componentType);
            list.forEach(componentObj => {
              const componentObjCopy = this.create(componentObj);
              components.push(componentObjCopy);
            });
          }
        });
      }
    }

    return copy;
  }

  copyComponent(objA, objB) {}

  createList(list: Array<any>, type = null): Array<any> {
    const array = [];
    list.forEach(obj => {
      const cObj = this.create(obj, type);
      if (cObj) {
        array.push(cObj);
      }
    });

    return array;
  }

  updateProperties(source, destination, ignoreFields = {}) {
    destination.GetMembers().forEach(function(memberName: string) {
      if (source[memberName] !== undefined && !ignoreFields[memberName]) {
        destination[memberName] = source[memberName];
      }
    });

    destination._init();
  }

  setConstructorOf(type: any, constructor: Function) {
    if (!type.$t) {
      throw new Error('Error: $t is not present on object you want to customize');
    }
    Logger.debug(`Changing the constructor of ${type.$t}`, 'ObjectFactory');
    this.idToTypeConverter.setConstructorOf(type.$t, constructor);
  }

  doesInherit(A, B) {
    while (A) {
      if (A === B) {
        return true;
      }
      A = Object.getPrototypeOf(A);
    }

    return false;
  }
}
