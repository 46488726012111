import { Component, OnInit } from '@angular/core';
import { SettingOpenerService } from '../../services/setting-opener/setting-opener.service';

@Component({
  selector: 'app-setting-button',
  templateUrl: './setting-button.component.html',
  styleUrls: ['./setting-button.component.scss']
})
export class SettingButtonComponent implements OnInit {
  constructor(public readonly settings: SettingOpenerService) {}

  public ngOnInit() {}
}
