import { Component } from '@angular/core';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { NavParams, PopoverController, ModalController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { VehicleSelectorSettingComponent } from '../vehicleSelectorSetting/vehicleSelectorSetting';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';

/**
 * @name VehicleSelectorComponent
 * @description
 *
 */
@Component({
  templateUrl: 'vehicleSelector.html'
})
export class VehicleSelectorComponent {
  vehicles: Vehicle[];
  extend: boolean = false;

  constructor(
    private association: AssociationSecurity,
    private view: ModalController,
    private navParams: NavParams,
    private modalService: ModalService,
    private popoverCtrl: PopoverController
  ) {}

  cancel() {
    this.view.dismiss();
  }

  itemSelected(item: Vehicle) {
    this.view.dismiss(item);
  }

  showSetting(event) {
    let options = {
      extended: this.extend
    };
    this.popoverCtrl
      .create({
        component: VehicleSelectorSettingComponent,
        componentProps: {
          options: options,
          ev: event
        }
      })
      .then(popover => {
        popover.onDidDismiss().then(() => {
          if (options.extended != this.extend) {
            this.extend = options.extended;
            this.updateList();
          }
        });

        popover.present();
      });
  }

  async updateList() {
    await this.modalService.presentLoading('SagaMobile.UA.LoadVehicles');
    let vehicles: Vehicle[] = await this.association.getVehicles(this.extend);
    console.log(vehicles);
    let filterVehicles: string[] = this.navParams.get('filter').map(d => {
      return d.ObjGuid;
    });
    this.vehicles = vehicles.filter(d => {
      return filterVehicles.indexOf(d.ObjGuid) === -1;
    });
    return this.modalService.dismissLoading();
  }

  refresh() {
    this.updateList();
  }

  async ionViewWillEnter() {
    await this.updateList();
  }
}
