import { Injectable } from '@angular/core';
import { ICallcardListCustomComponent } from '../../schema/interfaces/ICallcardListCustomComponent';

@Injectable({
  providedIn: 'root'
})
export class CustomComponentsService {
  private _customComponents: ICallcardListCustomComponent[] = [];
  get customComponents(): ICallcardListCustomComponent[] {
    return this._customComponents;
  }

  constructor() {}

  add(customComponent: ICallcardListCustomComponent): void {
    this._customComponents.push(customComponent);
  }
}
