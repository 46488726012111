// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class PhoneCallStamp_abstract extends SagaObject {
  static $t: string = 'SagaBase.PhoneCallStamp';
  $t: string = 'SagaBase.PhoneCallStamp';

  public static GetMembers(): string[] {
    return [
      'PhoneCallId',
      'CallState',
      'CallType',
      'CallId',
      'WorkstationId',
      'TransferId',
      'PhoneNumber',
      'VoiceChannel',
      'ServiceObjId',
      'ServiceName',
      'Text',
      'ElementId',
      'ElementName',
      'ProcStepObjId',
      'SourceIdentifier'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return PhoneCallStamp_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public PhoneCallId: Guid = GuidTools.Empty;
  public CallState: SagaBase.PhoneCallStates = SagaBase.PhoneCallStates.Offering;
  public CallType: SagaBase.PhoneCallTypes = SagaBase.PhoneCallTypes.Incoming;
  public CallId: Guid = GuidTools.Empty;
  public WorkstationId: string;
  public TransferId: string;
  public PhoneNumber: string;
  public VoiceChannel: string;
  public ServiceObjId: Guid = GuidTools.Empty;
  public ServiceName: string = '';
  public Text: string = '';
  public ElementId: Guid = GuidTools.Empty;
  public ElementName: string;
  public ProcStepObjId: Guid = GuidTools.Empty;
  public SourceIdentifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.PhoneCallId;
  }
  set ParentObjId(value: any) {
    this.PhoneCallId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
