import { Observable, Subject } from 'rxjs';

export class List<T> {
  private _source: T[];
  set source(source: T[]) {
    this._source = source;
    this.refresh();
  }
  get source(): T[] {
    return this._source;
  }

  private _items: T[];
  get items(): T[] {
    return this._items;
  }

  private readonly _filters: ((a: T) => boolean)[] = [];
  private readonly _sorts: ((a: T, B: T) => number)[] = [];

  private readonly _refresh = new Subject<T[]>();
  get onRefresh(): Observable<T[]> {
    return this._refresh.asObservable();
  }

  constructor(_src = []) {
    this.source = _src;
  }

  addFilter(filter: (a: T) => boolean, refresh = true): () => void {
    this._filters.push(filter);
    if (refresh) {
      this.refresh();
    }
    return () => {
      const i = this._filters.indexOf(filter);
      if (i !== -1) {
        this._filters.splice(i, 1);
        this.refresh();
      }
    };
  }

  removeFilter(filter: (a: T) => boolean): boolean {
    const idx = this._filters.indexOf(filter);
    if (idx !== -1) {
      this._filters.splice(idx, 1);
      this.refresh();
    }
    return idx !== -1;
  }

  addSort(sort: (a: T, b: T) => number, refresh = true, index = -1): () => void {
    if (index === -1) {
      this._sorts.push(sort);
    } else {
      this._sorts.splice(index, 0, sort);
    }
    if (refresh) {
      this.refresh();
    }
    return () => {
      const i = this._sorts.indexOf(sort);
      if (i !== -1) {
        this._sorts.splice(i, 1);
        this.refresh();
      }
    };
  }

  refresh(): void {
    this._items = this.filter(this._source);
    this.sort(this._items);
    this._refresh.next(this._items);
  }

  private filter(items: T[]): T[] {
    return items.filter((i: T) => {
      return this._filters.reduce((memo, filter) => {
        return memo && filter(i);
      }, true);
    });
  }

  private sort(items: T[]): void {
    if (this._sorts.length) {
      items.sort((a: T, b: T): number => {
        let result = 0;
        for (let sortIndex = 0; sortIndex < this._sorts.length; sortIndex++) {
          result = this._sorts[sortIndex](a, b);
          if (result !== 0) {
            break;
          }
        }
        return result;
      });
    }
  }
}
