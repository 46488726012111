import { Injectable } from '@angular/core';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { CacheService } from '../cache/cache.service';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListNameService {
  constructor(
    private readonly http: HttpClient,
    private readonly objectFactory: ObjectFactory,
    private readonly cache: CacheService,
    private readonly refreshToken: RefreshTokenStorageService
  ) {}

  async getList<T = ListNameValue>(listName: string, searchText?: string, owner?: number): Promise<T[]> {
    return this.getListOf<T>(listName, ListNameValue, searchText, owner);
  }

  async getListOf<T = ListNameValue>(listName: string, type: any, searchText?: string, owner?: number): Promise<T[]> {
    let params = new HttpParams();
    owner = owner || owner === null ? owner : this.refreshToken.read().login.SagaUserAuth.SiteId;
    params = params.set('owner', owner ? owner.toString() : null).set('listName', listName);

    if (searchText) {
      params = params.set('searchText', '%' + searchText + '%');
    }

    return this.http
      .get<Array<T>>('api/sagafilter/ReadFilterListByName', { params })
      .pipe(map((n: any) => this.createList<T>(n, type)))
      .toPromise();
  }

  private createList<T>(names: Array<any>, type: T): T[] {
    return this.objectFactory.createList(names, type).map(obj => {
      this.cache.callChanged(obj);
      return obj;
    });
  }
}
