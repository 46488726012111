<div class="deployment-point-message">
  <a class="deployment-point" (click)="openNavigation()">
    <img class="messageDeployment" alt="" [src]="navigationImg" />
  </a>

  <a class="action deployment-point-localize" (click)="onLocalize()">
    <img class="messageDeployment" alt="" [src]="localizeDeploymentImg" />
  </a>
  <hr class="clear" />
</div>
