import { CurrentCardService } from '@addins/core/core';
import { CardPageService } from '@addins/core/singlecallcard/services/card-page/card-page.service';
import { CardStampsPopoverService } from '@addins/core/singlecallcard/services/card-stamps-popover/card-stamps-popover.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavParams } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { CallcardService } from '@services/callcard/callcard.service';
import { Events } from '@services/events/events.service';
import { ModalService } from '@services/modal/modal.service';
import { NavService } from '@services/navigation/navigation.service';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { AddinView } from '@techwan/ionic-core';
import { CallcardDetailBaseComponent } from '../../components/callcard-detail-base/callcard-detail-base.component';

/**
 * @description
 * A detail view  of a callcard
 */
@Component({
  templateUrl: 'slide-detail-view.component.html',
  styleUrls: ['./slide-detail-view.component.scss']
})
@AddinView({
  name: 'CardDetailSlides',
  description: 'Use to display a CallCard give in params and switching between them'
})
export class CallCardSlideDetailViewComponent extends CallcardDetailBaseComponent implements OnInit {
  @ViewChild(IonSlides)
  public slides: IonSlides;

  @ViewChild('pager')
  public pager: ElementRef;

  private _toolsProvider: ToolsProvider;

  private _addPicture: Tool;
  public get addPicture(): Tool {
    return this._addPicture;
  }

  public indexes: number[] = [];
  public currentIndex: number = 0;

  public cacheSize: number = 2;

  public initialSlide: number = this.currentIndex;
  public pagerTimeout: any;

  public readonly callcards: List<CallCard> = new List<CallCard>();

  public readonly options = {
    initialSlide: this.initialSlide
  };

  constructor(
    public readonly stamps: CardStampsPopoverService,
    private readonly modal: ModalService,
    private readonly association: AssociationSecurity,
    private readonly toolsProvider: ToolsProviderService,
    _pages: CardPageService,
    navParams: NavParams,
    callcardService: CallcardService,
    events: Events,
    navService: NavService,
    view: ViewConfigurationService,
    current: CurrentCardService
  ) {
    super(navParams, callcardService, events, navService, view, current, _pages);
    this.modal.listenToModalView(/* this.viewCtrl as ViewController*/);

    this.callcards = this.callcardService.getCardsList('CallCardsDetail', CallCard, callcards => {
      callcards.addSort(
        (a, b) => {
          if (a === this.getUnit().callcard) {
            return -1;
          } else if (b === this.getUnit().callcard) {
            return 1;
          }
          return 0;
        },
        true,
        0
      );

      callcards.onRefresh.subscribe(() => {
        while (this.indexes[this.indexes.length - 1] >= this.callcards.items.length) {
          this.indexes.pop();
        }

        this.slides.getActiveIndex().then(val => {
          if (val >= this.indexes.length) {
            this.slides.slideTo(this.indexes.length - 1, 0);
          }
        });
      });
    });
  }

  public getPage(): Promise<number> {
    return Promise.resolve(this._pages.getPage(this.currentCallCard));
  }

  public load(callcard: CallCard, page: number = null) {
    if (callcard) {
      const currentIndex = this.callcards.items.indexOf(callcard);

      callcard.page = page !== null ? page : 0;

      this.indexes = [currentIndex];
      this.initialSlide = 0;
      for (let i = 0; i < this.cacheSize; i++) {
        this.loadPrev(true);
        this.loadNext();
      }

      super.load(callcard);
      this.currentIndex = this.callcards.items.indexOf(callcard);
    }
  }

  public getUnit() {
    return this.association.getUnitActivity();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.getCurrentCard().then(c => {
      c.page = this.navParams.data.page || 0;
      this.indexes = [this.callcards.items.indexOf(c)];
    });
    this.load(this.currentCallCard);
    this._toolsProvider = this.toolsProvider.getToolsForContext(CallCard);
    this._addPicture = this._toolsProvider.tools.find(t => t.text === 'PictureTool');
  }

  public getCurrentCard(): Promise<CallCard> {
    return this.slides.getActiveIndex().then(i => this.callcards && this.callcards.items[this.indexes[i]]);
  }

  public loadNext() {
    const lastIndex = this.indexes[this.indexes.length - 1];
    if (lastIndex < this.callcards.items.length - 1) {
      this.indexes.push(lastIndex + 1);
    }
  }

  public loadPrev(avoidSlide = false) {
    const firstIndex = this.indexes[0];
    if (firstIndex > 0) {
      this.indexes.unshift(firstIndex - 1);
      if (!avoidSlide) {
        if (this.slides) {
          this.slides.getActiveIndex().then(i => this.slides.slideTo(i + 1, 0));
        }
      } else {
        this.initialSlide++;
      }
    }
  }

  public slideChanged() {
    Promise.all([this.slides.length(), this.slides.getActiveIndex()]).then(res => {
      const numberOfNextSlides = res[0] - 1 - res[1];
      const numberOfPrevSlides = res[1];
      let i;

      for (i = numberOfNextSlides; i < 2; i++) {
        this.loadNext();
      }
      for (i = numberOfPrevSlides; i < 2; i++) {
        this.loadPrev();
      }
      super.load(this.callcards && this.callcards.items[this.indexes[res[1]]]);
      this.currentIndex = this.callcards.items.indexOf(this.currentCallCard);

      this.callcardService.onCardDisplay.next(this.currentCallCard);
    });
  }

  public slideWillChanged() {
    if (this.pagerTimeout) {
      clearTimeout(this.pagerTimeout);
    }

    this.pager.nativeElement.style.opacity = 1;
    this.pagerTimeout = setTimeout(() => (this.pager.nativeElement.style.opacity = 0), 2000);
  }
}
