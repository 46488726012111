import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Ionic plugin dependencies
import { IonicStorageModule } from '@ionic/storage';
import { NgxImports } from './modules/ngx-imports';

// ngx-translate imports
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from '../modules/shared/shared.module';
import { SagaMissingTranslationHandler } from './translations/missing';
import { SagaTranslateParser } from './translations/parser';

import { SagaModelsServiceModule } from '@app/models-services';
import { SagaServiceModule } from '@app/services';

// Addins import
import { ADDIN_MODULES, ADDIN_PROVIDERS } from '@addins/addins';
import { NATIVE_TRANSLATION_TOKEN, NativeTranslationsService, SETTING_PROVIDER, STATIC_TRANSLATION_TOKEN } from '@addins/core/core';
import { AppRootComponent } from '@app/components/app-root/app-root.component';
import { TranslationApiService } from '@services/globalization/translation-api/translation-api.service';
import { LoginConfigService } from '@services/login-config/login-config.service';
import { SagaSettingsService } from '@services/settings';
import { LOGIN_CONFIG_INJECTION_TOKEN } from '@techwan/security';
import { SagaComponentsModule } from './components/module';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    SharedModule,
    NgxImports,

    SagaServiceModule,
    SagaModelsServiceModule,

    // CacheModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      parser: { provide: TranslateParser, useClass: SagaTranslateParser },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: SagaMissingTranslationHandler
      },
      loader: { provide: TranslateLoader, useExisting: TranslationApiService }
    }),

    // Application dependencies
    AppRoutingModule, // Imports routes and also directives
    SagaComponentsModule,
    // VisualModule,

    // Addins dependencies
    ...ADDIN_MODULES

    // BusinessLogicModule,
  ],
  bootstrap: [AppRootComponent],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOGIN_CONFIG_INJECTION_TOKEN, useExisting: LoginConfigService },
    { provide: SETTING_PROVIDER, useExisting: SagaSettingsService },
    { provide: STATIC_TRANSLATION_TOKEN, useExisting: TranslationApiService },
    { provide: NATIVE_TRANSLATION_TOKEN, useExisting: NativeTranslationsService },
    ...ADDIN_PROVIDERS
  ]
})
export class AppModule {}
