import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { EngagementListButtonComponent } from './components/engagement-list-button/engagement-list-button.component';
import { ENGAGEMENT_ADDIN_NAME } from './schema/engagement-addin-name';
import { EngagementSetupService } from './services/executables/engagement-setup/engagement-setup.service';

const views = [];

const executables = [EngagementSetupService];

const providers = [...executables];

const components = [...views, EngagementListButtonComponent];

const directives = [];

@Addin({
  name: ENGAGEMENT_ADDIN_NAME,
  require: ['singlecallcard']
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class EngagementModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
