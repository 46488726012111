import { Component, Inject, OnInit } from '@angular/core';
import { Security } from '@services/security/security.service';
import { MenuController } from '@ionic/angular';
import { SideMenuService } from '@services/menu/side-menu.service';
import { Menu } from '@services/menu/menu';
import { MenuEntry } from '@services/menu/entry.schema';
import { NavService } from '@services/navigation/navigation.service';
import { LogoutService } from '@services/logout/logout.service';
import { EnvironmentService } from '@services/environment/environment.service';
import { SETTING_OPENER_TOKEN } from '../../schema/injector';
import { ISettingOpener } from '../../schema/interfaces/setting-opener';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  providers: [LogoutService, EnvironmentService]
})
export class SideMenuComponent implements OnInit {
  private _mainMenu: Menu;
  get mainMenu(): Menu {
    return this._mainMenu;
  }

  get name(): string {
    return this.env.getConfig().name;
  }

  constructor(
    private readonly security: Security,
    private readonly _menu: MenuController,
    readonly menuService: SideMenuService,
    private readonly navService: NavService,
    private readonly user: LogoutService,
    private readonly env: EnvironmentService,
    @Inject(SETTING_OPENER_TOKEN)
    private readonly settings: ISettingOpener
  ) {}

  ngOnInit() {
    this._mainMenu = this.menuService.get();
  }

  getLoginUser() {
    const data = this.security.loggedUser;
    if (data) {
      return data.UserName;
    }

    return '';
  }

  openSettings(): Promise<void> {
    return this.settings.open();
  }

  logout() {
    this.user.logout();
  }

  viewSelected(view: MenuEntry) {
    const nav = this.navService.get();
    if (!nav) {
      return;
    }
    if (!view.type) {
      nav.canGoBack().then(can => {
        if (can) {
          nav.popToRoot();
        }
      });
    } else if (view.isHandler) {
      (view.type as any)();
    } else {
      nav.getActive().then(a => {
        if (a.component !== view.type) {
          nav.push((view as any).type);
        }
      });
    }
    // Toggle menu, when it's opened
    this._menu.isOpen().then(o => (o ? this._menu.toggle() : null));
  }
}
