import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';

import { CommonAddinModule } from '@addins/common.module';
import { CoreModule } from '../core';
import { MenusModule } from '../menus';
import { PanelModule } from '../panel';

import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { HeaderComponent } from './components/header/header.component';
import { RequestDispatchComponent } from './components/request-dispatch/request-dispatch.component';
import { RowsComponent } from './components/rows/rows.component';
import { TopLayoutSplitterActivatorDirective } from './components/top-layout-splitter-activator/top-layout-splitter-activator.directive';
import { TopLayoutComponent } from './components/top-layout/top-layout.component';
import { ViewComponent } from './components/view/view.component';
import { HomeAccessMenuService } from './services/home-access-menu/home-access-menu.service';
import { HomePageService } from './services/home.service/home.service';
import { MenuService } from './services/menu/menu.service';
import { GenericHomeComponent } from './views/generic/generic.component';
import { HomeComponent } from './views/home/home.component';
import { SimpleHomeComponent } from './views/simple-home/simple-home.component';

const views = [GenericHomeComponent, SimpleHomeComponent, HomeComponent];

const directives = [];

const components = [
  RowsComponent,
  RequestDispatchComponent,
  TopLayoutComponent,
  ViewComponent,
  HeaderComponent,
  HeaderTitleComponent,
  ...views
];
const declarations = [...components, ...directives, TopLayoutSplitterActivatorDirective];

const executables = [];
const providers = [HomePageService, HomeAccessMenuService, MenuService, ...executables];

@NgModule({
  declarations,
  imports: [CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule, PanelModule, MenusModule, CoreModule],
  exports: declarations,
  providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@Addin({
  name: 'Home'
})
export class HomeModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, declarations, providers);
  }
}
