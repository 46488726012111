import { Injectable } from '@angular/core';
import { MediaMessagesService, MessageEvent, MessageEventType } from '../media-messages/media-messages.service';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { MediaMessagesFilterService } from '../media-messages-filter/media-messages-filter.service';
import { MyCardService } from '@addins/core/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Injectable()
export class MediaMessagesAvailableService {
  private readonly _subs: Subscription[] = [];
  private readonly _mediaMessages: MediaMessage[] = [];

  private readonly _event = new Subject<MessageEvent>();

  get $event(): Observable<MessageEvent> {
    return this._event.asObservable();
  }

  constructor(
    private _mediaMessagesService: MediaMessagesService,
    private _mediaMessagesFilterService: MediaMessagesFilterService,
    private _myCardService: MyCardService
  ) {}

  setup() {
    this._mediaMessagesService.setup();
    this._subs.push(this._mediaMessagesService.$event.subscribe(messageEvent => this.onMessageEvent(messageEvent)));
    this._subs.push(this._myCardService.$change.subscribe(callcard => this.onMyCardChanged(callcard)));
  }

  cleanup() {
    this._mediaMessagesService.cleanup();
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  getMessages(): MediaMessage[] {
    return this._mediaMessages;
  }

  private onMessageEvent(event: MessageEvent) {
    const mediaMessage = event.target;

    switch (event.type) {
      case MessageEventType.added:
        if (this._mediaMessagesFilterService.isVisibleForMe(mediaMessage)) {
          this._mediaMessages.splice(0, 0, mediaMessage);
          this._event.next(event);
        }
        break;

      case MessageEventType.removed:
        const idx = this._mediaMessages.indexOf(mediaMessage);
        if (idx >= 0) {
          this._mediaMessages.splice(idx, 1);
          this._event.next(event);
        }
        break;
    }
  }

  private onMyCardChanged(callCard: CallCard) {
    this._mediaMessages.length = 0;
    this._mediaMessagesService
      .getMessages(mediaMessage => this._mediaMessagesFilterService.isVisibleForMe(mediaMessage))
      .forEach(mediaMessage => this._mediaMessages.push(mediaMessage));

    this._event.next({ type: MessageEventType.clean, target: null });
  }
}
