import { Injectable } from '@angular/core';
import { CollapsibleItem } from '../../schema/collapsible-item';

@Injectable()
export class CollapsibleItemsService {
  private _items: CollapsibleItem[] = [];
  get items(): CollapsibleItem[] {
    return this._items;
  }
  constructor() {}

  register(item: CollapsibleItem): void {
    this._items.push(item);
    this._items.sort(function(a, b) {
      return a.position - b.position;
    });
  }

  unregister(item: CollapsibleItem): void {
    const itemIndex = this._items.indexOf(item);
    if (itemIndex !== -1) {
      this._items.splice(itemIndex, 1);
    }
  }
}
