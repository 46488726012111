<ng-template #phone>
  <app-full-map [toc]="false"></app-full-map>
</ng-template>
<ion-split-pane *ngIf="isTablet else phone" when="sm" content-id="map-view">
  <ion-menu content-id="map-view" menu-id="right-side-panel" side="end" [class.smallMenu]="!largeMenu">
    <ion-icon (click)="toggle()" name="arrow-dropright"></ion-icon>
    <app-status-list></app-status-list>
  </ion-menu>
  <app-full-map [toc]="false"></app-full-map>
</ion-split-pane>
