import { Component, ComponentRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ICallcardListCustomComponent } from '../../schema/interfaces/ICallcardListCustomComponent';

@Component({
  selector: 'app-callcard-list-custom-content',
  templateUrl: './callcard-list-custom-content.component.html',
  styleUrls: ['./callcard-list-custom-content.component.scss']
})
export class CallcardListCustomContentComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private viewContainer: ViewContainerRef;

  @Input()
  customComponent: ICallcardListCustomComponent;
  @Input()
  callcard: CallCard;
  @Input()
  isMyCallcard: boolean;

  private componentRef: ComponentRef<any>;

  constructor() {}

  ngOnInit() {
    this.createCustomComponent();
  }

  private createCustomComponent() {
    this.componentRef = this.viewContainer.createComponent(this.customComponent.component);

    this.componentRef.instance.callcard = this.callcard;
    this.componentRef.instance.isMyCallcard = this.isMyCallcard;
  }

  ngOnDestroy() {
    this.destroyCustomComponent();
  }

  private destroyCustomComponent() {
    this.componentRef.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.componentRef) {
      this.destroyCustomComponent();
      this.createCustomComponent();
    }
  }
}
