<ion-item>
  <ion-label translate>{{ entry.setting.name }}</ion-label>

  <ion-select
    [value]="entry.setting.selected.value"
    (ionChange)="onValueChanged($event)"
    [disabled]="entry.readonly || readOnly"
    [okText]="'Mobile.OK' | translate"
    [cancelText]="'Mobile.Cancel' | translate"
  >
    <ion-select-option
      *ngFor="let option of entry.setting.values"
      [value]="option.value"
      [translate]="entry.displayNames[option.name] || option.name"
    ></ion-select-option>
  </ion-select>
</ion-item>
