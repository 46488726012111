// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:28:26
/* tslint:disable */
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CPersonBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CPersonBase';
  $t: string = 'SagaSchema.CPersonBase';

  public static GetMembers(): string[] {
    return [
      'DateOfBirth',
      'NationalityId',
      'GenderId',
      'PersonQualifierId',
      'PersonQualifier',
      'Text',
      'Normalized',
      'TraceId',
      'Nationality',
      'Particulars',
      'Kind',
      'Description',
      'LastName',
      'FirstName',
      'Address',
      'Location',
      'Phone',
      'Mobile',
      'Gender',
      'Age',
      'Height',
      'Build',
      'HairColor',
      'EyeColor',
      'Language',
      'Dress',
      'FieldGeneric1',
      'FieldGeneric2',
      'FieldGeneric3',
      'FieldGeneric4',
      'CallCardId',
      'TraceState',
      'IsPrivate'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CPersonBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DateOfBirth = this.DateOfBirth && new Date(this.DateOfBirth);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public DateOfBirth: Date;
  public NationalityId: number = 0;
  public GenderId: number = 0;
  public PersonQualifierId: number = 0;
  public PersonQualifier: string;
  public Text: string = '';
  public Normalized: number = 1;
  public TraceId: Guid = GuidTools.Empty;
  public Nationality: string = '';
  public Particulars: string = '';
  public Kind: string = '';
  public Description: number = 0;
  public LastName: string = '';
  public FirstName: string = '';
  public Address: string = '';
  public Location: string = '';
  public Phone: string = '';
  public Mobile: string = '';
  public Gender: string = '';
  public Age: string = '';
  public Height: string = '';
  public Build: string = '';
  public HairColor: string = '';
  public EyeColor: string = '';
  public Language: string = '';
  public Dress: string = '';
  public FieldGeneric1: string = '';
  public FieldGeneric2: string = '';
  public FieldGeneric3: string = '';
  public FieldGeneric4: string = '';
  public CallCardId: Guid = GuidTools.Empty;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public IsPrivate: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
