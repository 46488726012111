// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class GeoFence_abstract extends SagaObject {
  static $t: string = 'SagaBase.GeoFence';
  $t: string = 'SagaBase.GeoFence';

  public static GetMembers(): string[] {
    return [
      'GeoFenceType',
      'GeometryFormat',
      'GeometryObjectsBinary',
      'Name',
      'Description',
      'IsActive',
      'IsFrozen',
      'Locked',
      'GroupName',
      'ValidFrom',
      'ValidTo',
      'ImportId'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return GeoFence_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ValidFrom = this.ValidFrom && new Date(this.ValidFrom);
    this.ValidTo = this.ValidTo && new Date(this.ValidTo);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GeoFenceType: SagaBase.GeoFenceTypes = SagaBase.GeoFenceTypes.None;
  public GeometryFormat: SagaBase.GeometryFormats = SagaBase.GeometryFormats.Default;
  public GeometryObjectsBinary: any[];
  public Name: string;
  public Description: string;
  public IsActive: boolean = false;
  public IsFrozen: boolean = false;
  public Locked: boolean = false;
  public GroupName: string;
  public ValidFrom: Date = new Date();
  public ValidTo: Date = new Date();
  public ImportId: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
