// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:22
import { Task_abstract } from './../abstract/SagaSchema/Task_abstract';
import { CEvent } from './CEvent';
import { CLocation } from './CLocation';
import { CNote } from './CNote';
export class Task extends Task_abstract {
  get location(): CLocation {
    return this.component<any>('SagaSchema.CLocation').length ? this.component<any>('SagaSchema.CLocation')[0] : null;
  }

  get note(): CNote {
    return this.component<any>('SagaSchema.CNote').length ? this.component<any>('SagaSchema.CNote')[0] : null;
  }

  get event(): CEvent {
    return this.component<any>('SagaSchema.CEvent').length ? this.component<any>('SagaSchema.CEvent')[0] : null;
  }
}
