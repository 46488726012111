import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToolsProvider } from '../schema/tool-provider';

/**
 * @description
 * A injected service to request tools for a given context
 */
@Injectable()
export class ToolsProviderService {
  private readonly _event = new Subject<ToolsProvider>();
  public get $provide(): Observable<ToolsProvider> {
    return this._event.asObservable();
  }

  /**
   * Tool provider service
   * @param translate Translation service to get translated text
   */
  constructor(private readonly translate: TranslateService) {}

  public getToolsForContext(context: Object | Array<Object>, nav?: IonNav): ToolsProvider {
    const provider = new ToolsProvider(context, nav);
    provider.tools.map(t => (t.text = this.translate.instant(t.text)));
    this._event.next(provider);
    return provider;
  }

  public register(providerFunction: (provider: ToolsProvider) => void): Subscription {
    return this.$provide.subscribe(providerFunction);
  }
}
