// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { LearningUnit } from '../../SagaBase/LearningUnit';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ProbabilityNode_abstract extends LearningUnit {
  static $t: string = 'SagaBase.ProbabilityNode';
  $t: string = 'SagaBase.ProbabilityNode';

  public static GetMembers(): string[] {
    return [
      'States',
      'StatesSpecified',
      'Outputs',
      'OutputsSpecified',
      'OutputWeights',
      'OutputWeightsSpecified',
      'StateWeights',
      'StateWeightsSpecified'
    ].concat(LearningUnit.GetMembers());
  }

  public GetMembers(): string[] {
    return ProbabilityNode_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public States: any[];
  public StatesSpecified: boolean = false;
  public Outputs: any[];
  public OutputsSpecified: boolean = false;
  public OutputWeights: any[];
  public OutputWeightsSpecified: boolean = false;
  public StateWeights: any[];
  public StateWeightsSpecified: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
