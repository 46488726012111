import { MapName } from '@addins/core/core';
import { DeploymentFeatureFactoryService, DeploymentPointsLayer } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { MapInstanceEvent, MapInstanceEventType, MapInstancesService } from '@services/map';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { GroupName, SagaLayerManager } from '@techwan/ionic-core/map';
import { Map as SagaMap } from '@techwan/mapping';
import Group from 'ol/layer/group';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import {
  DeploymenPointOperation,
  DeploymentPointEvent,
  DeploymentPointsProviderService
} from '../../deployment-points-provider/deployment-points-provider.service';

enum LayerName {
  cardDeployments = 'CardDeploymentPoints'
}

@AddinExecute({
  name: 'deployment-toc'
})
@Injectable()
export class DeploymentsLayerService extends Execute {
  private _cacheEventSub: Subscription = null;
  private _deploymentPointsChangeSub: Subscription = null;

  private _cardDeploymentPointsLayer: DeploymentPointsLayer;

  constructor(
    private _cacheService: CacheService,
    private _mapInstancesService: MapInstancesService,
    private _deploymentPointsProvider: DeploymentPointsProviderService,
    private _deploymentFeatureFactory: DeploymentFeatureFactoryService
  ) {
    super();
  }

  execute(): void {
    this._cacheService.state
      .pipe(
        filter(cacheState => cacheState === CacheState.ready),
        first()
      )
      .subscribe(() => this.setup());
  }

  private setup() {
    if (this._cacheService.hasBootedWithType(CDeploymentPoint)) {
      if (this._mapInstancesService.get(MapName.main) !== null) {
        this.createCardDeploymentPointsLayer(this._mapInstancesService.get(MapName.main));
      }

      this._mapInstancesService.$change
        .pipe(filter(mapEvent => mapEvent.name === MapName.main))
        .subscribe(mapEvent => this.onMainMapChanged(mapEvent));
    }
  }

  private onMainMapChanged(mapEvent: MapInstanceEvent) {
    if (mapEvent.type === MapInstanceEventType.added) {
      this.createCardDeploymentPointsLayer(mapEvent.target);
    } else if (mapEvent.type === MapInstanceEventType.removed) {
      this.cleanup();
    }
  }

  private createCardDeploymentPointsLayer(sagaMainMap: SagaMap) {
    const sagaLayerManager = sagaMainMap.getLayerManager() as SagaLayerManager;
    this._cardDeploymentPointsLayer = new DeploymentPointsLayer(LayerName.cardDeployments);
    sagaLayerManager.setAutohide(this._cardDeploymentPointsLayer);
    const deploymentLayersGroup: Group = sagaLayerManager.getLayerGroup(GroupName.deploymentLayers);
    deploymentLayersGroup.getLayers().push(this._cardDeploymentPointsLayer);

    this._deploymentFeatureFactory.setup().subscribe(() => {
      this._deploymentPointsProvider.setup();
      this._deploymentPointsChangeSub = this._deploymentPointsProvider.$change.subscribe((changeEvent: DeploymentPointEvent) =>
        this.onCardDeploymentsChanged(changeEvent)
      );

      this.refresh();
    });
  }

  private onCardDeploymentsChanged(changeEvent: DeploymentPointEvent) {
    const deploymentPoint = changeEvent.deploymentPoint;
    let source = this._cardDeploymentPointsLayer.getSource();

    switch (changeEvent.operation) {
      case DeploymenPointOperation.callcardChanged:
        this.refresh();
        break;

      case DeploymenPointOperation.added:
        source.addFeature(this._deploymentFeatureFactory.fromDeployment(deploymentPoint, this._mapInstancesService.get(MapName.main)));
        break;

      case DeploymenPointOperation.removed:
        const feature = source.getFeatures().find(feature => feature.get('id') === deploymentPoint.ObjGuid);
        if (feature) {
          source.removeFeature(feature);
        }
        break;

      case DeploymenPointOperation.modified:
        this.refreshDeploymentPoint(deploymentPoint);
        break;
    }
  }

  private refresh(): void {
    this.clearCardDeploymentPointsLayer();

    const source = this._cardDeploymentPointsLayer.getSource();

    this._deploymentPointsProvider.cardDeployments.forEach(deploymentPoint => {
      source.addFeature(this._deploymentFeatureFactory.fromDeployment(deploymentPoint, this._mapInstancesService.get(MapName.main)));
    });
  }

  private clearCardDeploymentPointsLayer() {
    this._cardDeploymentPointsLayer.clear();
  }

  private refreshDeploymentPoint(deploymentPoint: CDeploymentPoint) {
    let source = this._cardDeploymentPointsLayer.getSource();

    const oldFeature = source.getFeatures().find(feature => feature.get('id') === deploymentPoint.ObjGuid);
    source.removeFeature(oldFeature);

    const newFeature = this._deploymentFeatureFactory.fromDeployment(deploymentPoint, this._mapInstancesService.get(MapName.main));
    source.addFeature(newFeature);
  }

  private cleanup() {
    this.clearCardDeploymentPointsLayer();

    if (this._deploymentPointsChangeSub) {
      this._deploymentPointsChangeSub.unsubscribe();
      this._deploymentPointsChangeSub = null;
    }

    if (this._cacheEventSub) {
      this._cacheEventSub.unsubscribe();
      this._cacheEventSub = null;
    }
  }
}
