import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '../../schema/list-item';
import { IObjectFilter } from '@addins/unit-custom-creation/src/schema/object-filter';
import { ListCheckBoxItemEvent } from '../list-item/list-item.component';

@Component({
  selector: 'app-virtualized-list',
  templateUrl: './virtualized-list.component.html',
  styleUrls: ['./virtualized-list.component.scss']
})
export class VirtualizedListComponent<T> {
  @Input()
  readonly item;

  @Input()
  readonly listItems: ListItem<T>[];

  @Input()
  private readonly filter: IObjectFilter<ListItem<T>> = null;

  @Output()
  readonly emitSelectedElement = new EventEmitter<ListCheckBoxItemEvent<T>>();

  @Output()
  readonly loadNextBatchOfData = new EventEmitter<ListCheckBoxItemEvent<T>>();

  constructor() {}

  loadData(event) {
    this.loadNextBatchOfData.emit();
    event.target.complete();
  }
}
