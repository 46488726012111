// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { AttachmentBase } from '../../SagaBase/AttachmentBase';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CAttachment_abstract extends AttachmentBase {
  static $t: string = 'SagaSchema.CAttachment';
  $t: string = 'SagaSchema.CAttachment';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'Url',
      'Title',
      'Text',
      'Rank',
      'BinaryData',
      'Thumbnail',
      'BinaryDataType',
      'CoordX',
      'CoordY',
      'CoordZ',
      'AuditOperationsString'
    ].concat(AttachmentBase.GetMembers());
  }

  public GetMembers(): string[] {
    return CAttachment_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public Url: string = '';
  public Title: string = '';
  public Text: string = '';
  public Rank: number = 0;
  public BinaryData: any[];
  public Thumbnail: any[];
  public BinaryDataType: SagaBase.Interfaces.DataType = SagaBase.Interfaces.DataType.None;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public CoordZ: number = 0;
  public AuditOperationsString: string = 'Add,Remove';

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
