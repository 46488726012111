import { Injectable } from '@angular/core';
import { IRadio } from '../../components/unit-activity-editor/unit-activity-editor.component';
import { IExtendedProperties } from '../../list-vehicle-type-pn';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface IUnitCreation {
  radios: IRadio[];
  properties: IUnitActivityExtendedProperties;
  vehicleTypeId: number;
  startDate?: string;
  endDate?: string;
  owner: number;
  ownerName: string;
  userId: number;
  userName: string;
  registration?: string;
  ungrouped?: boolean;
}

export interface IUnitActivityExtendedProperties extends IExtendedProperties {
  nbOfficial?: string;
  nbHighRankingOfficer?: string;
  nbWoman?: string;
  other?: string;
  embededDevices?: string;
  secteurRadio?: string;
  poleTraitement?: number[];
}

@Injectable()
export class UnitCreationService {
  constructor(private readonly http: HttpClient) {}

  getUnitData(unit: UnitActivity): Observable<IUnitCreation> {
    const params = new HttpParams();
    return this.http.get<IUnitCreation>('api/UnitActivity/GetUnitActivityEditData', { params: params.set('unitActivityId', unit.ObjGuid) });
  }
}
