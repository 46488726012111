import { CardsProviderService } from '@addins/core/singlecallcard';
import { CustomComponentsService } from '@addins/listcallcards';
import { UnitCountCardComponent } from '@addins/resource-count/src/components/unit-count-card/unit-count-card.component';
import { Injectable } from '@angular/core';
import { StatusChangeRequirementsService } from '@services/status/status-change-requirements/status-change-requirements.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { EngagedPeopleListButtonComponent } from '../../../components/engaged-people-list-button/engaged-people-list-button.component';
import { ResourceCountCardComponent } from '../../../components/resource-count-card/resource-count-card.component';
import { ResourceCountListComponent } from '../../../components/resource-count-list/resource-count-list.component';
import { EngagedPeopleCardButtonService } from '../../engaged-people-card-button/engaged-people-card-button.service';
import { EngagementRequirementService } from '../../engagement-requirement/engagement-requirement.service';

@AddinExecute({
  name: 'ResourceCountSetup'
})
@Injectable({
  providedIn: 'root'
})
export class ResourceCountSetupService extends Execute {
  constructor(
    private engagedPeopleCardButton: EngagedPeopleCardButtonService,
    private cardsProvider: CardsProviderService,
    private customComponents: CustomComponentsService,
    private statusChangeRequirements: StatusChangeRequirementsService,
    private engagementRequirement: EngagementRequirementService
  ) {
    super();
  }

  execute() {
    this.engagedPeopleCardButton.setup();
    this.cardsProvider.add(ResourceCountCardComponent);
    this.cardsProvider.add(UnitCountCardComponent);

    this.customComponents.add({ slot: 'end', component: EngagedPeopleListButtonComponent });
    this.customComponents.add({ component: ResourceCountListComponent });

    this.statusChangeRequirements.addRequirement(this.engagementRequirement);
  }
}
