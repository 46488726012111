<ion-searchbar
  slot="fixed"
  placeholder="{{'static.search' | translate}}"
  [value]="searchExpression"
  (ionInput)="search($event.target.value)"
></ion-searchbar>
<app-virtualized-list
  style="height: 100%; overflow: auto;"
  *ngIf="list.length"
  [listItems]="list"
  [item]="item"
  (emitSelectedElement)="select($event.event, $event.item.item)"
  (loadNextBatchOfData)="checkOffset()"
></app-virtualized-list>
