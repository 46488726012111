import { FeatureStyleService, ILayerController, MapSettingsService } from '@addins/core/map';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LocalizableObjectLayer, Map as SagaMap, ObjectFeature } from '@techwan/mapping';
import { Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UnitActivityOnMap } from '../../schema/unit-activity-on-map';
import { UnitResourceProviderService } from '../unit-resource-provider/unit-resource-provider.service';

// This service must be merged with position-layer

@Injectable()
export class UnitsLayerService implements ILayerController {
  private _unitsLayer: LocalizableObjectLayer;
  private _map: SagaMap = null;
  private _units: UnitActivityOnMap[];
  private _timer: any = null;
  private _isRunning = false;
  private _request: Subscription = null;

  constructor(
    private readonly _provider: UnitResourceProviderService,
    private readonly _settings: MapSettingsService,
    private readonly _style: FeatureStyleService,
    private readonly _toast: ToastController
  ) {}

  public mapDidChange(map: SagaMap) {
    const current = this._map;
    // Clear the map layers when the map is destroyed. Also preserves the running state.
    if (map === null && this._map !== null && this._isRunning === true) {
      this.stop();
      this._isRunning = true;
    }

    this._map = map || null;
    this._unitsLayer = map
      ? map.createLocalizableObjectLayer({
          cluster: false,
          updateWhileAnimating: false,
          updateWhileInteracting: false
        })
      : null;

    if (current !== this._map && this._map !== null && this._isRunning === true) {
      this.internalGetPositions();
    }
  }

  public start() {
    const running = this._isRunning;
    this._isRunning = true;
    if (this._map !== null && this._isRunning !== running) {
      this.internalGetPositions();
    }
  }

  public stop() {
    this._isRunning = false;

    // Stop listening to the result.
    if (this._request !== null) {
      this._request.unsubscribe();
      this._request = null;
    }

    if (this._map !== null) {
      // Clear the layer content.
      this._unitsLayer.clear();

      // Store the result
      this._units = null;

      if (this._timer !== null) {
        clearTimeout(this._timer);
        this._timer = null;
      }
    }
  }

  private applyStyle(feature: ObjectFeature, idx: number) {
    const object = this._units[idx];

    if (feature) {
      feature.setStrokeColor([255, 255, 255, 0]);
      feature.setFillColor([255, 255, 255, 0]);
      feature.set('id', object.unit.ObjGuid);
      this._style.applyFeatureStyle(feature, object.unit);
    }
  }

  private handleError(err: HttpErrorResponse): Observable<UnitActivityOnMap[]> {
    console.error(err);
    this._toast.create({ message: err.message, duration: (this._settings.positionPolling / 2) * 1000 }).then(t => t.present());
    return of(null);
  }

  private internalGetPositions() {
    this._timer = null;
    this._request = this._provider
      .getMobilePositions()
      .pipe(catchError(err => this.handleError(err)))
      .subscribe(p => this.refresh(p));
  }

  private refresh(p: UnitActivityOnMap[]) {
    this._request = null;
    // No error has been detected
    if (p !== null && this._isRunning === true) {
      // Clear the layer content.
      this._unitsLayer.clear();

      // Store the result
      this._units = p;

      // Process new positions.
      this._units
        .map(i => this._unitsLayer.addObject(i.id, i.position, this._settings.getSystemProjection()))
        .forEach((i, idx) => this.applyStyle(i, idx));
    }

    // Restart the timer for the next update.
    this.restart();
  }

  private restart() {
    // Restart the timer for the next update.
    if (this._isRunning === true) {
      this._timer = setTimeout(() => this.internalGetPositions(), this._settings.positionPolling * 1000);
    }
  }
}
