<ion-header translucent>
  <ion-toolbar>
    <ion-title translate>Mobile.Journal</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()" translate>Mobile.Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-button *ngIf="callcard.hasDatabaseChanged" expand="block" fill="solid" (click)="onRefreshClicked()">
    <ion-icon slot="start" name="refresh"></ion-icon>
    <ion-label translate> Mobile.Refresh </ion-label>
  </ion-button>
</ion-header>

<ion-content>
  <app-journal-list [journalItems]="journalItems" [title]="title"></app-journal-list>
</ion-content>

<ion-footer>
  <ng-container *ngFor="let tool of journalTools">
    <ion-button *ngIf="tool.isEnabled" expand="block" (click)="tool.execute(callcard)">{{ tool.text | translate }}</ion-button>
  </ng-container>
</ion-footer>
