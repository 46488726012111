import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ProfileService } from '@services/profile/profile.service';
import { ICardTitleFormatter } from '../../schema/interfaces/card-title-formatter';
import { CnuTitleFormatter } from './formatters/cnu-title-formatter';
import { DefaultTitleFormatter } from './formatters/default-title-formatter';
import { FusTitleFormatter } from './formatters/fus-title-formatter';

@Injectable({
  providedIn: 'root'
})
export class CardTitleFormatterService {
  private formatter: ICardTitleFormatter = null;

  constructor(private profile: ProfileService) {}

  format(card: CallCard): string {
    return this.getFormatter().format(card);
  }

  private getFormatter(): ICardTitleFormatter {
    if (!this.formatter) {
      this.formatter = this.createFormatter();
    }

    return this.formatter;
  }

  private createFormatter(): ICardTitleFormatter {
    const formatterName: string = this.profile.current.webConfig.titleFormatter;
    switch (formatterName) {
      case 'cnu':
        return new CnuTitleFormatter();

      case 'fus':
        return new FusTitleFormatter();

      default:
        return new DefaultTitleFormatter();
    }
  }
}
