import { Injectable } from '@angular/core';
import { transform } from '@services/map';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MapSettingsService, MyLocationService } from '@addins/core/map';
import { IAlertProvider } from '@addins/core/core';
import { MyEquipmentService } from '@techwan/ionic-core';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { PhoneCreateEventRes } from '@models/imported/SagaBase/PhoneCreateEventRes';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { ICreateCardRequest } from '../../schema/interfaces/create-card-request';
import { EventsProviderService } from '../events-provider/events-provider.service';
import { SagaSettingsService } from '@services/settings';

@Injectable()
export class EventFactoryService implements IAlertProvider {
  constructor(
    private _events: EventsProviderService,
    private _me: MyEquipmentService,
    private _myLocation: MyLocationService,
    private _map: MapSettingsService,
    private _settings: SagaSettingsService
  ) {}

  canCreate(): Observable<boolean> {
    return this._settings.$ready.pipe(
      filter(r => r),
      switchMap(() => this._events.whenReady().pipe(filter(r => r))),
      map(() => this._events.getEmergency() !== null)
    );
  }

  create(event?: MobEvents): Observable<PhoneCreateEventRes> {
    const eventArgument = this.getEventArgument(event);
    if (eventArgument) {
      return this._events.create(this.getEventArgument(event), this.getTimestamp());
    }
    return throwError('Mobile.Location.NotAvailable');
  }

  getHoldTimeout(): number {
    return this._settings.getValue('SagaMobileWebClient.TapHoldThreshold') as number;
  }

  private getEventArgument(event: MobEvents): ICreateCardRequest {
    const myCurrentLocation = this._myLocation.mostRecent;
    if (!myCurrentLocation) {
      return null;
    }
    let unit;
    let device;
    this._me.myDevice.pipe(first()).subscribe(myDevice => (device = myDevice));
    this._me.myUnit.pipe(first()).subscribe(myUnit => (unit = myUnit));
    const coord = transform(
      {
        x: myCurrentLocation.coords.longitude,
        y: myCurrentLocation.coords.latitude,
        epsg: 'EPSG:4326'
      },
      this._map.parse().value
    );
    if (event === null) {
      event = this._events.getEmergency();
    }
    const isAlarm = event.Isemergency === true;
    return {
      coordX: coord.x,
      coordY: coord.y,
      eventId: event.EvtEventId,
      callerLastName: isAlarm ? (device as CommunicationEquipment).Name : (unit as UnitActivity).Name
    };
  }

  private getTimestamp(): number {
    return (this._myLocation.mostRecent && this._myLocation.mostRecent.timestamp) || Date.now();
  }
}
