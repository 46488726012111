import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Observable } from 'rxjs';

export enum ToolExpand {
  block = 'block',
  full = 'full'
}

export enum ToolFill {
  clear = 'clear',
  outline = 'outline',
  solid = 'solid'
}

export enum ToolSize {
  small = 'small',
  'default' = 'default',
  large = 'large'
}

export enum ToolColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  medium = 'medium',
  dark = 'dark'
}

export enum ToolType {
  button,
  select
}

export interface ICallcardButtonEvent {
  target: CallCard;
}

export interface ICallcardSelectEvent<T> extends ICallcardButtonEvent {
  selection: T;
}

export interface ICallcardTool {
  position: number;
  text: string;
  dontTranslate?: boolean;
  type: ToolType;
  icon: string;
  show: boolean;
  isDisabled?: boolean;
  fill: ToolFill;
  expand: ToolExpand;
  size: ToolSize;
  color: ToolColor;
}

export interface ICallcardButtonTool extends ICallcardTool {
  readonly onClick: Observable<ICallcardButtonEvent>;
  click(): void;
}

export interface ICallcardSelectTool extends ICallcardTool {
  readonly onChange: Observable<ICallcardButtonEvent>;
  change(value: any): void;
}

export interface ICallcardCustomSelectTool<T extends ICallcardButtonEvent> extends ICallcardTool {
  readonly onChange: Observable<T>;
  change(value: T): void;
}
