<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button slot="icon-only"  (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            Numéros associées à {{vehicle.Name}}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <ion-list>
        <ion-item *ngFor="let equipment of equipments" (click)="call(equipment)">
            <ion-icon name="call" slot="start"></ion-icon>
            <h2>{{equipment.Name}}</h2>
            <p>{{equipment.PhoneNumber}}</p>
        </ion-item>
    </ion-list>
</ion-content>
