import { Injectable } from '@angular/core';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';
import { AccessMenuTool } from '../../schema/menu/access-menu-tool';
import { CallCardsProviderService } from '../callcards-provider/callcards-provider.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutViewStateService {
  private _entry: AccessMenuTool = null;

  private _provider: CallCardsProviderService = null;

  private _sub: Subscription = null;

  private _init = false;

  public get isOpen(): boolean {
    return this._entry.isActive;
  }

  constructor(private readonly cache: CacheService) {}

  public setup(entry: AccessMenuTool, provider: CallCardsProviderService): void {
    this._entry = entry;
    this._provider = provider;
    this._sub = this._provider.getCards().onRefresh.subscribe(() => this.$onRefresh());
    this.init();
  }

  private init(): void {
    let state;
    this.cache.state.subscribe(s => (state = s)).unsubscribe();
    if (state !== CacheState.ready) {
      this._entry.disable();
    } else if (this._provider.getCards().items.length > 0) {
      this._entry.enable();
    }
  }

  private $onRefresh(): void {
    const cards = this._provider.getCards();
    this._entry.setCounter(cards.items.length);
    if (this._init === false && cards.items.length > 0) {
      this._init = true;
      this._entry.enable();
    } else if (cards.items.length === 0 && this.isOpen) {
      this._entry.disable();
    }
  }
}
