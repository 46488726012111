import { MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { StatusChangeResult, StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { StatusService } from '@services/status/status.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementService {
  private _canReassign: boolean = true;
  get canReassign(): boolean {
    return this._canReassign;
  }

  constructor(private myUnitService: MyUnitService, private statusService: StatusService, private myCardService: MyCardService) {}

  setup(canReassign: boolean) {
    if (!canReassign) {
      this.myCardService.$change.subscribe(callcard => this.onMyCardChanged(callcard));
    }
  }

  private onMyCardChanged(callcard: CallCard) {
    this._canReassign = !!!callcard;
  }

  engage(callcard: CallCard): Promise<StatusChangeResult> {
    const newStatusItemAction: StatusItemAction = this.myUnitService.isEngaged()
      ? StatusItemAction.isReassignment
      : StatusItemAction.isAssignement;
    const newStatus: ListResourceStatus = this.statusService.getStatusByAction(newStatusItemAction);

    return this.statusService.changeStatus(newStatus, callcard);
  }
}
