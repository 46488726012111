import { Component } from '@angular/core';
import { CStampParticulars } from '@models/imported/SagaSchema/CStampParticulars';
import { FollowupTemplate } from '@addins/callcard/components/callCardDetail/callCardDetailFollowup/FollowupTemplate';

@Component({
  templateUrl: 'CStampParticularsFollowup.html'
})
export class CStampParticularsFollowupComponent extends FollowupTemplate {
  stamp(): CStampParticulars {
    return this.data;
  }

  properties(): string[] {
    return Object.keys(this.stamp().values);
  }
}
