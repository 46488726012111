import { IAppSetting } from '@addins/core/core';
import { FileBridgeService, FileDownloadService, FileSystemService } from '@addins/core/file';
import { Injectable } from '@angular/core';
import { FileEntry } from '@ionic-native/file';
import { Zip } from '@ionic-native/zip/ngx';
import { AppSettingProviderService } from '@services/settings';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';

export const OFFLINE_MAP_FOLDER: string = 'mapoffline';

const OFFLINE_MAP_NAME: string = 'mapoffline.zip';
const OFFLINE_MAP_URL: string = `${OFFLINE_MAP_FOLDER}/${OFFLINE_MAP_NAME}`;

export enum MapDownloadState {
  initializing,
  ready,
  downloading,
  downloadPaused,
  downloadFailed,
  downloaded,
  unzipping,
  unzipFailed,
  mapAvailable
}

export interface MapDownloadEvent {
  state: MapDownloadState;
  progress?: number;
  date?: Date;
  version?: number;
  error?: any;
}

@Injectable({
  providedIn: 'root'
})
export class MapDownloadService {
  private _event = new BehaviorSubject<MapDownloadEvent>({ state: MapDownloadState.initializing });
  get $event(): Observable<MapDownloadEvent> {
    return this._event.asObservable();
  }

  get state(): MapDownloadState {
    return this.lastEvent.state;
  }

  private get lastEvent(): MapDownloadEvent {
    return this._event.getValue();
  }

  private storedState: IAppSetting<string> = null;

  constructor(
    private fileDownloadService: FileDownloadService,
    private fileSystem: FileSystemService,
    private fileBridge: FileBridgeService,
    private zip: Zip,
    private toastService: ToastService,
    private appSettingProvider: AppSettingProviderService
  ) {
    this.initStoredState();
  }

  private initStoredState() {
    this.appSettingProvider
      .getSetting<string>('MapDownloadState', JSON.stringify({ state: MapDownloadState.ready }), true)
      .subscribe(appSetting => {
        this.storedState = appSetting;
        this.changeState(JSON.parse(this.storedState.current));
      });
  }

  private changeState(mapDownloadEvent: MapDownloadEvent, store: boolean = false) {
    this._event.next(mapDownloadEvent);
    if (store) {
      this.storedState.setValue(JSON.stringify(mapDownloadEvent));
    }
  }

  downloadMap(): Promise<boolean> {
    const lastEvent: MapDownloadEvent = this.lastEvent;

    return this.fileDownloadService.getFileSize(OFFLINE_MAP_URL).then(fileSize => {
      if (this.state === MapDownloadState.mapAvailable && fileSize === lastEvent.version) {
        return this.toastService.show('Mobile.MapOfflineLatestDownloaded', ToastColor.Info).then(() => false);
      } else {
        return this.fileDownloadService
          .downloadFile(OFFLINE_MAP_URL, OFFLINE_MAP_NAME, this.onDownloadProgress.bind(this))
          .then(fileEntry => {
            this.changeState({ state: MapDownloadState.downloaded });
            this.toastService.show('Mobile.MapOfflineDownloadSuccessful', ToastColor.Success);
            return fileEntry;
          })
          .then(fileEntry =>
            this.unzipMap(fileEntry)
              .then(isSuccesfull => this.fileBridge.remove(fileEntry.nativeURL).then(() => isSuccesfull))
              .catch(error => {
                this.onUnzipFailed(error);
                return false;
              })
          )
          .catch(error => {
            if (!(error && error.cancelled)) {
              this.changeState({ state: MapDownloadState.downloadFailed, error: error });
              this.toastService.show('Mobile.MapOfflineDownloadFailed', ToastColor.Error);
            }
            this.changeState(lastEvent);
            return false;
          });
      }
    });
  }

  private onDownloadProgress(done: number, total: number) {
    if (!this.fileDownloadService.isPaused) {
      this.changeState({ state: MapDownloadState.downloading, progress: done / total });
    } else {
      this.changeState({ state: MapDownloadState.downloadPaused, progress: done / total });
    }
  }

  private unzipMap(fileEntry: FileEntry): Promise<boolean> {
    this.changeState({ state: MapDownloadState.unzipping, progress: 0 });

    return this.fileBridge.getFile(fileEntry).then(file =>
      this.fileSystem.getDirectoryFromRoot(OFFLINE_MAP_FOLDER, true).then(directory =>
        this.fileBridge.removeRecursively(directory.nativeURL).then(() =>
          this.fileSystem.getDirectoryFromRoot(OFFLINE_MAP_FOLDER, true).then(directory =>
            this.zip.unzip(fileEntry.nativeURL, directory.nativeURL, this.onUnzipProgress.bind(this)).then(result => {
              const isSuccesfull: boolean = result === 0;
              if (isSuccesfull) {
                this.changeState({ state: MapDownloadState.mapAvailable, date: new Date(), version: file.size }, true);
                this.toastService.show('Mobile.MapOfflineUnzipSuccessful', ToastColor.Success);
              } else {
                this.onUnzipFailed('Unzip failed');
              }
              return isSuccesfull;
            })
          )
        )
      )
    );
  }

  private onUnzipProgress(progress) {
    this.changeState({ state: MapDownloadState.unzipping, progress: progress.loaded / progress.total });
  }

  private onUnzipFailed(error) {
    this.changeState({ state: MapDownloadState.unzipFailed, error: error });
    this.toastService.show('Mobile.MapOfflineUnzipFailed', ToastColor.Error);
    this.changeState({ state: MapDownloadState.ready }, true);
  }

  pause() {
    if (this.state === MapDownloadState.downloading) {
      this.fileDownloadService.pause();
      this.changeState({ state: MapDownloadState.downloadPaused, progress: this._event.getValue().progress });
    }
  }

  start() {
    if (this.state === MapDownloadState.downloadPaused && this.fileDownloadService.isPaused) {
      this.fileDownloadService.start();
    }
  }

  cancel() {
    if (this.state === MapDownloadState.downloading || this.state == MapDownloadState.downloadPaused) {
      this.fileDownloadService.cancel();
    }
  }
}
