import { LocalizePositionService, MyLocationService } from '@addins/core/map';
import { Component, OnInit } from '@angular/core';
import { SagaSettingsService } from '@services/settings';
import { ButtonsProviderService } from '../../services/buttons-provider/buttons-provider.service';
import { CallcardDetailButtonsComponent } from '../callcard-detail-buttons/callcard-detail-buttons.component';

@Component({
  selector: 'app-callcard-detail-buttons-h',
  templateUrl: './callcard-detail-buttons-h.component.html',
  styleUrls: ['./callcard-detail-buttons-h.component.scss']
})
export class CallcardDetailButtonsHComponent extends CallcardDetailButtonsComponent implements OnInit {
  constructor(
    _localizePosition: LocalizePositionService,
    _buttonsProvider: ButtonsProviderService,
    _myLocationService: MyLocationService,
    _settingsService: SagaSettingsService
  ) {
    super(_localizePosition, _buttonsProvider, _myLocationService, _settingsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.addItem(this.create('LocalizeCardAndMe', 'ellipsis-horizontal-circle-outline', 800));
  }
}
