import { Component, Input, OnInit } from '@angular/core';
import { List } from '@models/list';
import { IDocument, IDocumentGroup } from '../../interfaces';
import { DocumentService } from '../../services/document/document.service';
import { DocumentDownloaderService } from '../../services/document-downloader/document-downloader.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  @Input()
  public readonly documents: List<string>;

  public readonly groups: IDocumentGroup[] = [];

  private filter: string;

  constructor(private readonly _documentService: DocumentService, private readonly _documentDownloader: DocumentDownloaderService) {}

  public ngOnInit() {
    this.documents.onRefresh.subscribe(documents => this.refreshGroupedResults(documents));
    this.documents.addFilter(documentName => new RegExp(this.filter, 'i').test(documentName));
    this.refreshGroupedResults();
  }

  public filterDocuments(query: string) {
    this.filter = query ? query.trim() : '';
    this.documents.refresh();
  }

  public selectDocument(document: IDocument): void {
    this._documentDownloader.download(document);
  }

  private refreshGroupedResults(documentsNames: string[] = this.documents.items) {
    this.groups.length = 0;
    this._documentService.getGroups(documentsNames).forEach(documentName => this.groups.push(documentName));
  }
}
