<ion-header>
    <ion-toolbar color="primary" class="force-back-button">
        <ion-title>
            <span translate>SagaMobile.Stamp.Text</span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="padding textStampModal">
    <ion-item>
        <ion-textarea autofocus translatePlaceholder="SagaMobile.Stamp.TextLabel" [(ngModel)]="text"></ion-textarea>
    </ion-item>
    <ion-button [disabled]="!text" full (click)="save()"><span translate="SagaMobile.Save"></span></ion-button>
</ion-content>
