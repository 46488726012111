import { CommonAddinModule } from '@addins/common.module';
import { CoreModule } from '@addins/core/core';
import { HomeModule } from '@addins/core/home';
import { Injector, NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule, IonicCoreModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { VisualModule } from '../../../../modules/visual/visual.module';
import { ReportComponent } from './components/report/report.component';
import { ToolsService } from './executables/tools/tools.service';
import { FOLLOWUP_STAMPS_TOOLS } from './schema/constants';
import { ReportService } from './services/report/report.service';

const views = [];

const directives = [];

const pipes = [];

const components = [...views];

const executables = [ToolsService];

const providers = [...executables, ReportService];

@NgModule({
  declarations: [ReportComponent, ...components, ...directives, ...pipes],
  providers,
  imports: [CoreModule, IonicModule, SharedModule, SagaPipeModule, VisualModule, IonicCoreModule, CommonAddinModule, HomeModule],
  exports: []
})
@Addin({
  name: FOLLOWUP_STAMPS_TOOLS,
  description: 'This module is used to provide tools and service to manage stamps in the followup'
})
export class FollowupStampsToolsModule extends AddinModule {
  constructor(public readonly injector: Injector) {
    super(injector, components, executables);
  }
}
