import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpErrorParsingService {
  constructor() {}

  public parse(error: HttpErrorResponse): string {
    switch (error.status) {
      case 0:
        return 'SagaMobile.UnableToConnectToServer';

      default:
        return error.message;
    }
  }
}
