import { DeploymentFeatureFactoryService, MapModule } from '@addins/core/map';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { DeploymentPopupComponent } from './components/deployment-popup/deployment-popup.component';
import { DeploymentManagerService } from './services/deployment-manager/deployment-manager.service';
import { DeploymentPanelGroupService } from './services/deployment-panel-group/deployment-panel-group.service';
import { DeploymentParsingService } from './services/deployment-parsing/deployment-parsing.service';
import { DeploymentPointsProviderService } from './services/deployment-points-provider/deployment-points-provider.service';
import { DeploymentProxyService } from './services/deployment-proxy/deployment-proxy.service';
import { DeploymentsLayerService } from './services/executables/deployments-layer/deployments-layer.service';
import { UnitDeploymentService } from './services/unit-deployment/unit-deployment.service';

const executables = [DeploymentsLayerService];
const services = [
  UnitDeploymentService,
  DeploymentProxyService,
  DeploymentManagerService,
  DeploymentParsingService,
  DeploymentPanelGroupService,
  DeploymentPointsProviderService,
  DeploymentFeatureFactoryService,
  ...executables
];

const views = [];
const components = [DeploymentPopupComponent, ...views];
const declarations = [...components];

@NgModule({ 
  declarations,
  providers: services,
  imports: [CommonModule, IonicModule, MapModule]
})
@Addin({
  name: 'Deployment'
})
export class DeploymentModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
