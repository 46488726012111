import { Injectable } from '@angular/core';
import { AppSettingsService, SagaSettingsService } from '@services/settings';
import { IFlowDelegate, IUploadConfigurator } from '@techwan/file-upload';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDelegateService } from '../file-delegate/file-delegate.service';

@Injectable()
export class UploadConfiguratorService implements IUploadConfigurator {
  private _endpoint: string = null;

  constructor(private _settings: SagaSettingsService, private _appSettings: AppSettingsService, private _delegate: FileDelegateService) {}

  setup(): Observable<string> {
    return this._appSettings.baseUrl().pipe(map(base => (this._endpoint = `${base}api/File/Upload`)));
  }

  getDelegate(): IFlowDelegate {
    return this._delegate;
  }

  getEndpoint(): string {
    return this._endpoint;
  }

  getTimeout(): number {
    return this._settings.getValue('FileUpload.Timeout');
  }
}
