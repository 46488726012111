import { Inject, Injectable } from '@angular/core';
import { MomentService } from '@techwan/ionic-core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ISettingProvider, SETTING_PROVIDER } from '@addins/core/core';

@Injectable()
export class CardReceivedCacheService {
  private _sub?: Subscription = null;

  constructor(
    private readonly moment: MomentService,
    private readonly _cache: CacheService,
    @Inject(SETTING_PROVIDER)
    private readonly _settings: ISettingProvider
  ) {}

  public setup() {
    this._sub = this._cache.state.pipe(filter(e => e === CacheState.ready)).subscribe(() => {
      this._sub.unsubscribe();
      this._sub = null;
      const cards = this._cache.getListByType(CallCard);
      cards.forEach(c => this.setTime(c));
      this.clear();
    });
  }

  public setDown() {
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  public getTime(card: CallCard): string {
    return this.moment.formatDate(
      localStorage.getItem(`card_${card.ObjGuid}`),
      this._settings.getValue('SagaMobileWebClient.SubmittedDateFormat')
    );
  }

  public setTime(c: CallCard, force = false) {
    const key = `card_${c.ObjGuid}`;
    if (force === true || !localStorage.getItem(key)) {
      localStorage.setItem(key, this.moment.formatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss.SSSZ'));
    }
  }

  private clear() {
    let i = 0;
    const rx = /card_([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/i;
    while (i < localStorage.length) {
      const key = localStorage.key(i);
      const matches = key.match(rx);
      if (matches && this._cache.getObjectByUid(matches[1]) === null) {
        localStorage.removeItem(key);
      }
      i++;
    }
  }
}
