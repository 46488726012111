import { Injectable } from '@angular/core';
import { IModelService, IObjectChange, ModelService } from '@services/model-factory';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction } from '@services/cache/cacheEvent';
import { UnitActivityResource } from '@models/imported/SagaSchema/UnitActivityResource';
import * as SagaBase from '@models/imported/SagaBase';

@ModelService(UnitActivity)
@Injectable()
export class UnitModelService implements IModelService<UnitActivity> {
  constructor(private readonly _fetchList: FetchListService, private readonly _cache: CacheService) {}

  public change(change: IObjectChange<UnitActivity>) {
    const object = change.target;
    // Set the unit's status
    const raw = this._fetchList.getRow(ListResourceStatus, object.Status);
    if (raw !== object.status) {
      object.status = raw;
    }

    // Set its parent object
    object.callcard = this._cache.getObjectByUid(object.CallCardID);

    // Add a resource to the unit
    if (change.action === CacheAction.added) {
      object.component<UnitActivityResource>(UnitActivityResource).forEach(activityRes => {
        const res = this._cache.getObjectByUid(activityRes.ResourceUId);
        if (res !== null) {
          switch (activityRes.ResType) {
            case SagaBase.ResourceType.Vehicle:
              object.vehicles.push(res);
              break;

            case SagaBase.ResourceType.CommunicationEquipment:
              object.devices.push(res);
              break;
          }
        }
      });
    }

    // Set rShort on the unit
    object.shortText = '';
    object.vehicles.forEach(v => (object.shortText += ' ' + v.rTypeOrCallCode()));
    object.shortText += ' / ';
    object.devices.forEach(d => (object.shortText += ' ' + d.name));

    object.changed(null);
  }
}
