import { Injectable } from '@angular/core';
import { CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { VideoCapturePlusOptions } from '@ionic-native/video-capture-plus/ngx';
import { AppSettingsService } from '@services/settings';

const defaultQuality = 80;

@Injectable()
export class SettingsService {
  private _quality: number;

  private readonly _options: ImageOptions = {
    quality: 100,
    resultType: CameraResultType.Uri,
    correctOrientation: true,
    saveToGallery: false,
    height: 800,
    width: 800
  };

  private readonly _videoOpts: VideoCapturePlusOptions = {
    limit: 1 // the nr of videos to record, default 1 (on iOS always 1)
  };

  constructor(appSettings: AppSettingsService) {
    appSettings.cameraQuality().subscribe(q => (this._quality = q || defaultQuality));
  }

  createCameraOptions(source: CameraSource): ImageOptions {
    return Object.assign({}, this._options, {
      quality: this._quality,
      source: source
    });
  }

  createVideoOptions(duration: number, highQuality: boolean, front: boolean): VideoCapturePlusOptions {
    return Object.assign({}, this._videoOpts, {
      duration, // max duration in seconds, default 0, which is 'forever'
      highquality: highQuality, // set to true to override the default low quality setting
      frontcamera: front // set to true to override the default backfacing camera setting. iOS: works fine, Android: YMMV (#18)
    });
  }
}
