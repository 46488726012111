import { Component, Input, OnInit } from '@angular/core';
import { SettingValue } from '../../schema/setting-value';
import { ListSettingWithNames } from '../../views/settings-editor/settings-editor.component';

@Component({
  selector: 'app-settings-select-field',
  templateUrl: './settings-select-field.component.html',
  styleUrls: ['./settings-select-field.component.scss']
})
export class SettingsSelectFieldComponent implements OnInit {
  @Input()
  entry: ListSettingWithNames;

  @Input()
  readOnly: boolean = false;

  constructor() {}

  ngOnInit() {}

  private getSettingValue(value: any): SettingValue {
    return this.entry.setting.values.filter(item => item.value === value)[0] || null;
  }

  onValueChanged($event) {
    if (!this.entry.readonly && this.getSettingValue($event.detail.value)) {
      this.entry.setting.select(this.getSettingValue($event.detail.value));
    }
  }
}
