import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { PCE_DESCRIPTION_VALUE } from './constants';
import { ParticularsCollapsibleItem } from './particulars-collapsible-item';
import { ParticularsPosition } from './particulars-position';

const CPersonListCollapsibleName = 'Mobile.CPersonDetails';
const CPersonListViewName = 'SingleCallcardParticulars/CallcardDetailCPersonRegular';

export class CPersonRegularCollapsibleItem extends ParticularsCollapsibleItem {
  constructor() {
    super(CPersonListCollapsibleName, CPersonListViewName, ParticularsPosition.CPersonRegular);
  }

  updateVisibility(callCard: CallCard): void {
    this.setVisible(
      callCard !== null
        ? callCard.component<CPerson>(CPerson).filter(person => person.Description !== PCE_DESCRIPTION_VALUE).length > 0
        : false
    );
  }
}
