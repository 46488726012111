// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { CStamp } from '../../SagaSchema/CStamp';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CAlarm_abstract extends CStamp {
  static $t: string = 'SagaSchema.CAlarm';
  $t: string = 'SagaSchema.CAlarm';

  public static GetMembers(): string[] {
    return [
      'Text',
      'TransmitterUId',
      'DetectorId',
      'FileUId',
      'Status',
      'FileIdentifier',
      'FileName',
      'FileInfo',
      'FileURL',
      'TransmitterName',
      'TransmitterTypeId',
      'TransmitterTypeName',
      'TransmitterIdentifier',
      'DetectorTypeId',
      'DetectorTypeName',
      'DetectorIdentifier',
      'DetectorInfo',
      'ProviderAlarmInfo',
      'KeysBunch',
      'KeysMandatory'
    ].concat(CStamp.GetMembers());
  }

  public GetMembers(): string[] {
    return CAlarm_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Text: string = '';
  public TransmitterUId: Guid = GuidTools.Empty;
  public DetectorId: number = 0;
  public FileUId: Guid = GuidTools.Empty;
  public Status: number = 0;
  public FileIdentifier: string = '';
  public FileName: string = '';
  public FileInfo: string = '';
  public FileURL: string = '';
  public TransmitterName: string = '';
  public TransmitterTypeId: number = 0;
  public TransmitterTypeName: string = '';
  public TransmitterIdentifier: string = '';
  public DetectorTypeId: number = 0;
  public DetectorTypeName: string = '';
  public DetectorIdentifier: string = '';
  public DetectorInfo: string = '';
  public ProviderAlarmInfo: string = '';
  public KeysBunch: boolean = false;
  public KeysMandatory: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
