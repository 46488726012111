// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Context_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Context';
  $t: string = 'SagaBase.Context';

  public static GetMembers(): string[] {
    return ['TimeStamp', 'Content', 'LocalContentValid', 'TypeName', 'TypeFullName', 'TableName', 'ContextName', 'Parameters'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return Context_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.TimeStamp = this.TimeStamp && new Date(this.TimeStamp);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TimeStamp: Date = new Date();
  public Content: any[];
  public LocalContentValid: boolean = false;
  public TypeName: string;
  public TypeFullName: string;
  public TableName: string;
  public ContextName: string;
  public Parameters: any[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
