// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../../SagaBase/SagaObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class PhoneFavorite_abstract extends SagaObject {
  static $t: string = 'SagaBase.Objects.PhoneFavorite';
  $t: string = 'SagaBase.Objects.PhoneFavorite';

  public static GetMembers(): string[] {
    return [
      'Id',
      'Normalized',
      'CoordEpsgCode',
      'BusinessCategory',
      'Country',
      'FirstName',
      'StreetNumber',
      'LastName',
      'CityName',
      'Phone',
      'State',
      'PlaceName',
      'XCoord',
      'YCoord',
      'Zip'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return PhoneFavorite_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public Normalized: number = 1;
  public CoordEpsgCode: string;
  public BusinessCategory: string = '';
  public Country: string = '';
  public FirstName: string = '';
  public StreetNumber: string = '';
  public LastName: string = '';
  public CityName: string = '';
  public Phone: string = '';
  public State: string = '';
  public PlaceName: string = '';
  public XCoord: number = -1.79769313486232e308;
  public YCoord: number = -1.79769313486232e308;
  public Zip: string = '';

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
