import { SettingValue } from '@addins/core/settings/schema/setting-value';
import { BehaviorSubject, Observable } from 'rxjs';

export class ListSettingValue {
  public get values(): SettingValue[] {
    return this._values;
  }

  private readonly _selected = new BehaviorSubject<SettingValue>(null);
  public get selected(): SettingValue {
    let v: SettingValue;
    this._selected.subscribe(u => (v = u)).unsubscribe();
    return v;
  }

  public get $change(): Observable<SettingValue> {
    return this._selected.asObservable();
  }

  public get name(): string {
    return this._name;
  }

  constructor(private readonly _name: string, private readonly _values: SettingValue[]) {}

  public select(item: SettingValue) {
    if (this.selected !== item) {
      this._selected.next(item);
    }
  }
}
