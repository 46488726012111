import { Injectable } from '@angular/core';

@Injectable()
export class ImagesSettingsService {
  private readonly _cache = new Map<string, string>();

  public constructor() {}

  public set(name: string, src: string): void {
    this._cache.set(name, src);
  }

  public get(name: string): string {
    return this._cache.get(name);
  }
}
