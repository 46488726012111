// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:30:59
import { CPersonHistory_abstract } from './../../abstract/SagaSchema/History/CPersonHistory_abstract';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { HistoryTextFormatter } from '@utils/formatter/history-text-formatter';

export class CPersonHistory extends CPersonHistory_abstract {
  get text() {
    return HistoryTextFormatter.format(this, CPerson.formsProperties);
  }
}
