<ion-content>
  <app-callcard-detail-info [callcard]="currentCard"></app-callcard-detail-info>

  <app-callcard-detail-card
    *ngFor="let component of customContent"
    [component]="component"
    [callcard]="currentCard"
  ></app-callcard-detail-card>

  <app-callcard-ccallers-card [callcard]="currentCard"></app-callcard-ccallers-card>

  <app-callcard-detail-collapsible [callcard]="currentCard"></app-callcard-detail-collapsible>

  <app-callcard-media-buttons [callcard]="currentCard"></app-callcard-media-buttons>

  <app-callcard-detail-buttons-v [card]="currentCard"></app-callcard-detail-buttons-v>
</ion-content>
