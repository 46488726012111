import { Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * MenuEntry
 * @description
 * A Menu entry to display in the menu
 */
export class MenuEntry {
  public isHandler: boolean;

  protected _disabled = false;
  public get isDisabled(): boolean {
    return this._disabled;
  }

  protected readonly _changed = new Subject<void>();
  get onChanged(): Observable<void> {
    return this._changed.asObservable();
  }

  constructor(public text: string, public icon: string, public type: Type<any> | Function, public priority: number = 0) {
    if (!this.text.startsWith('static')) {
      this.text = 'SagaMobile.Menu.' + this.text;
    }
    this.isHandler = false;
  }

  public hidden() {
    return false;
  }

  public disable(): void {
    const d = this._disabled;
    this._disabled = true;
    if (d !== this._disabled) {
      this._changed.next();
    }
  }

  public enable(): void {
    const d = this._disabled;
    this._disabled = false;
    if (this._disabled !== d) {
      this._changed.next();
    }
  }
}
