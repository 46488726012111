<div>
  <ion-searchbar
    placeholder="{{ 'static.search' | translate }}"
    (ionInput)="filterDocuments($event.target.value)"
    clearIcon="close-circle"
  ></ion-searchbar>
</div>
<ion-item-group *ngFor="let group of groups">
  <ion-item-divider color="light">
    <ion-label>{{ group.name }}</ion-label>
  </ion-item-divider>
  <ion-item *ngFor="let document of group.documents" (click)="selectDocument(document)">
    <ion-icon name="{{ document.iconName }}" *ngIf="document.iconName" slot="start"></ion-icon>
    <ion-icon [class]="document.iconClass || ''" *ngIf="document.iconClass" slot="start"></ion-icon>
    <span>{{ document.name }}</span>
    <loading-bar *ngIf="document.loading" [percent]="document.percent"></loading-bar>
    <p *ngIf="document.error" [translate]="document.error"></p>
  </ion-item>
</ion-item-group>
