// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { User } from '../../SagaBase/User';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class UserSyncClientConnection_abstract extends User {
  static $t: string = 'SagaBase.UserSyncClientConnection';
  $t: string = 'SagaBase.UserSyncClientConnection';

  public static GetMembers(): string[] {
    return [
      'ComputerName',
      'ComputerIPAddress',
      'LastActivity',
      'ParentSectorName',
      'ParentSectorId',
      'SyncServerInstanceUid',
      'LogTimeUtc'
    ].concat(User.GetMembers());
  }

  public GetMembers(): string[] {
    return UserSyncClientConnection_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.LastActivity = this.LastActivity && new Date(this.LastActivity);
    this.LogTimeUtc = this.LogTimeUtc && new Date(this.LogTimeUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ComputerName: string;
  public ComputerIPAddress: string;
  public LastActivity: Date = new Date();
  public ParentSectorName: string;
  public ParentSectorId: number = 0;
  public SyncServerInstanceUid: Guid = GuidTools.Empty;
  public LogTimeUtc: Date = new Date();

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
