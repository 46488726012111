import { TileLoaderProviderService } from '@addins/core/map/services/tile-loader-provider/tile-loader-provider.service';
import { CustomComponentsService } from '@addins/core/settings';
import { OfflineMapSettingsComponent } from '@addins/offline-map/src/components/offline-map-settings/offline-map-settings.component';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { MapDownloadService, MapDownloadState } from '../../map-download/map-download.service';
import { TileLoaderService } from '../../tile-loader/tile-loader.service';

@AddinExecute({
  name: 'OfflineMapSetup'
})
@Injectable({
  providedIn: 'root'
})
export class OfflineMapSetupService extends Execute {
  constructor(
    private customSettingsComponents: CustomComponentsService,
    private mapDownload: MapDownloadService,
    private tileProvider: TileLoaderProviderService,
    private tileLoader: TileLoaderService,
    private platform: Platform
  ) {
    super();
  }

  execute() {
    // Only for mobile devices
    if (this.platform.is('cordova')) {
      this.customSettingsComponents.add(OfflineMapSettingsComponent);
      this.tileProvider.tileLoader = this.tileLoader;
      this.mapDownload.$event.subscribe(event => (this.tileProvider.isMapAvailable = event.state === MapDownloadState.mapAvailable));
    }
  }
}
