import { MenuState, PermanentEntry } from '@addins/core/menus';
import { MenuEntry } from '@services/menu/entry.schema';

export class CallcardMenuEntry extends PermanentEntry {
  constructor(private isToggleable: boolean = false) {
    super(new MenuEntry('singlecallcard', 'document-text', null, 1010));
    this._disabled = true;
    this._state = MenuState.inactive;
  }

  setToggleable(isToggleable: boolean) {
    this.isToggleable = isToggleable;
  }

  click($event: MouseEvent | TouchEvent): void {
    if (this.isToggleable) {
      this.setState(this._state === MenuState.active ? MenuState.inactive : MenuState.active);

      this._onClick.next({
        item: this,
        event: $event
      });
    } else {
      super.click($event);
    }
  }

  disable() {
    super.disable();
    this.reset();
  }

  notifyAttention(): void {
    this._attention = 'alert';
  }
}
