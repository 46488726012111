// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class EventDefinition_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.EventDefinition';
  $t: string = 'SagaSchema.EventDefinition';

  public static GetMembers(): string[] {
    return [
      'FieldGeneric1',
      'FieldGeneric2',
      'FieldGeneric3',
      'EventId',
      'EventGroupUid',
      'Deleted',
      'AgencyId',
      'ActiveFromUTC',
      'MapLayers'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return EventDefinition_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ActiveFromUTC = this.ActiveFromUTC && new Date(this.ActiveFromUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.EventNode', 'SagaSchema.EventQuestion', 'SagaSchema.EventTaskRequirement'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public FieldGeneric1: string;
  public FieldGeneric2: string;
  public FieldGeneric3: string;
  public EventId: number = 0;
  public EventGroupUid: Guid = GuidTools.Empty;
  public Deleted: boolean = false;
  public AgencyId: number;
  public ActiveFromUTC: Date = new Date();
  public MapLayers: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.EventId;
  }
  get ParentObjId(): any {
    return this.EventGroupUid;
  }
  set ParentObjId(value: any) {
    this.EventGroupUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
