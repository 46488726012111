import { MyCardService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Tool } from '@services/tools';
import { MediaProviderService } from '../../services/media-provider/media-provider.service';

@Component({
  selector: 'app-callcard-media-buttons',
  templateUrl: './callcard-media-buttons.component.html',
  styleUrls: ['./callcard-media-buttons.component.scss']
})
export class CallcardMediaButtonsComponent implements OnInit {
  @Input()
  readonly callcard: CallCard;

  private _mediaButtons: Tool[] = null;
  get buttons(): Tool[] {
    return this._mediaButtons;
  }

  get isMine(): boolean {
    return this.callcard === this._myCardService.myCard;
  }

  constructor(private readonly _myCardService: MyCardService, private readonly _mediaProvider: MediaProviderService) {}

  ngOnInit() {
    this._mediaButtons = this._mediaProvider.getButtons();
  }

  execute(tool: Tool) {
    tool.execute({ callcard: this.callcard });
  }
}
