import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from '@models/guid';
import { Observable } from 'rxjs';

@Injectable()
export class DrawingProxyService {
  constructor(private readonly http: HttpClient) {}

  save(callcardUid: Guid, tacticalUid: Guid, geometry: string): Observable<boolean> {
    return this.http.post<boolean>('api/Drawing/Save', { callcardUid, tacticalUid, geometry });
  }

  delete(guid: Guid): Observable<boolean> {
    return this.http.post<boolean>('api/Drawing/Remove', { guid });
  }
}
