import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-keypad-modal',
  templateUrl: './keypad-modal.component.html',
  styleUrls: ['./keypad-modal.component.scss']
})
export class KeypadModalComponent implements OnInit {
  @Input()
  private maxLength?: number = 10;
  @Input()
  private validator: (value: string) => boolean;
  isValid: boolean = true;

  keyRows = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9']
  ];

  private _result: string;
  get result(): string {
    return this._result;
  }
  set result(value: string) {
    this._result = value;
    this.validate();
  }

  private firstChange: boolean = true;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.result = '';
  }

  private validate() {
    this.isValid = this.validator ? this.validator(this.result) : true;
  }

  onSubmit() {
    this.modalController.dismiss({ result: this.result });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onDigitClicked(digit: string) {
    if (this.firstChange) {
      this.firstChange = false;
      this.result = digit;
    } else if (this.result.length < this.maxLength) {
      this.result += digit;
    }
  }

  onClearClicked() {
    this.result = '';
  }

  onClearLastClicked() {
    if (this.result.length) {
      this.result = this.result.slice(0, -1);
    }
  }
}
