import { Injectable } from '@angular/core';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class FetchListProxyService {
  constructor(private readonly http: HttpClient, private readonly objectFactory: ObjectFactory) {}

  public setup(): Observable<any> {
    return this.http.get<any>('api/SagaFetchList/Setup').pipe(map(data => this.parse(data)));
  }

  private parse(data: any): any {
    for (const typeName in data) {
      if (data.hasOwnProperty(typeName)) {
        data[typeName] = data[typeName].map(i => this.objectFactory.create(i));
      }
    }
    return data;
  }
}
