import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Coordinate } from '@models/coordinate';
import { TranslateService } from '@ngx-translate/core';
import { ProviderName } from '../../schema/provider-name';
import { ExternalItineraryProviderService } from '../external-itinerary-provider/external-itinerary-provider.service';

@Injectable()
export class StartItineraryService {
  constructor(
    private externalItineraryProvider: ExternalItineraryProviderService,
    private actionSheet: ActionSheetController,
    private translate: TranslateService
  ) {}

  navigateTo(destination: Coordinate): void {
    const savedProvider: string = this.externalItineraryProvider.currentItineraryProvider.current;
    const providers = this.externalItineraryProvider.getProviders();

    if (providers.indexOf(savedProvider) >= 0) {
      this.navigateToWithProvider(destination, savedProvider);
    } else if (providers.length === 1) {
      this.navigateToWithProvider(destination, providers[0]);
    } else if (providers.length > 1) {
      this.selectProvider(providers).then(res => this.handleResult(res.role, destination));
    }
  }

  private navigateToWithProvider(destination: Coordinate, providerName: string) {
    this.externalItineraryProvider.get(ProviderName[providerName]).navigate({
      location: destination
    });
  }

  private selectProvider(providers: string[]) {
    const buttons = providers.map(createButton);

    return this.actionSheet
      .create({
        header: this.translate.instant('Mobile.SelectExtItineraryProvider'),
        cssClass: 'action-sheet-provider',
        backdropDismiss: false,
        buttons: [...buttons, { text: this.translate.instant('Mobile.Cancel'), role: 'cancel', icon: 'close' }]
      })
      .then(actionSheetElement => actionSheetElement.present().then(() => actionSheetElement.onWillDismiss()));
  }

  private handleResult(result: string, destination: Coordinate) {
    if (result !== 'cancel') {
      this.navigateToWithProvider(destination, result);
    }
  }
}

function createButton(provider: string): any {
  return {
    text: provider,
    role: provider,
    icon: 'navigate-circle',
    cssClass: 'ion-text-capitalize'
  };
}
