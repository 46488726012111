// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Zone_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Zone';
  $t: string = 'SagaBase.Zone';

  public static GetMembers(): string[] {
    return [
      'Id',
      'Name',
      'Abbreviation',
      'ZoneType',
      'ParentId',
      'CoordX',
      'CoordY',
      'RadioCode',
      'CityName',
      'DepartmentName',
      'RegionName',
      'CityCode',
      'DepartmentCode',
      'RegionCode',
      'ProjectionCode',
      'TerritoryCode',
      'GeoSectorImpId',
      'MapObjectId',
      'Deleted',
      'Color',
      'Temp',
      'VisibleByChildren',
      'Lft',
      'Rgt',
      'MiscInfo'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Zone_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public Name: string;
  public Abbreviation: string;
  public ZoneType: number = 0;
  public ParentId: number = 0;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public RadioCode: string;
  public CityName: string;
  public DepartmentName: string;
  public RegionName: string;
  public CityCode: string;
  public DepartmentCode: string;
  public RegionCode: string;
  public ProjectionCode: number = 0;
  public TerritoryCode: string;
  public GeoSectorImpId: number = 0;
  public MapObjectId: number = 0;
  public Deleted: boolean = false;
  public Color: string;
  public Temp: boolean = false;
  public VisibleByChildren: boolean = true;
  public Lft: number = 0;
  public Rgt: number = 0;
  public MiscInfo: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
