import { Component, Input, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { ModalController, ToastController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { TranslateService } from '@ngx-translate/core';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { IExtendedProperties, ListVehicleTypePn } from '@addins/pn/list-vehicle-type-pn';
import { CommunicationEquipmentType } from '@models/imported/SagaBase';
import { ListNameService } from '@services/list-name/list-name.service';
import { GeoSectorService } from '../../services/geo-sector/geo-sector.service';
import { Zone } from '@models/imported/SagaBase/Zone';
import { ListNameSelectorComponent } from '../list-name-selector/list-name-selector.component';
import { ListNameTemplate, UnitTemplateSelectorComponent } from '../unit-template-selector/unit-template-selector.component';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { GuidTools } from '@models/guid';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { IUnitCreation, UnitCreationService } from '../../services/unit-creation/unit-creation.service';
import { SagaSettingsService } from '@services/settings';

export interface IRadio {
  Principal?: boolean;
  Name?: string;
  Description?: string;
  CommunicationEquipmentType?: CommunicationEquipmentType;
  Identifier?: string;
  PhoneNumber?: string;
}

export interface IIdentifierProperties {
  RegistrationName: string;
  ImeiAlias: string;
  Type: CommunicationEquipmentType;
}

function today() {
  const t = new Date();
  t.setSeconds(0);
  t.setMilliseconds(0);
  return t;
}

function tomorrow() {
  const t = today();
  t.setDate(t.getDate() + 1);
  return t;
}

@Component({
  templateUrl: 'unit-activity-editor.component.html',
  styleUrls: ['unit-activity-editor.component.scss'],
  providers: [UnitCreationService, GeoSectorService]
})
@AddinView({
  name: 'UnitActivityEditor'
})
export class UnitActivityEditorComponent implements OnInit {
  @Input() identifier: string = null;
  @Input() unitActivity: UnitActivity = null;

  private otherUnitsCallsign: string[] = [];

  view: string = 'equipments';

  valid: boolean = false;

  communicationEquipmentTypes = Object.keys(CommunicationEquipmentType)
    .filter(key => parseInt(key, 10).toString() !== key)
    .map(name => {
      return {
        name,
        value: CommunicationEquipmentType[name]
      };
    });

  types: ListNameValue[];
  subTypes: { [key: number]: ListNameValue[] } = {};
  modeOfTransports: ListNameValue[];
  categories: ListNameValue[];
  templates: ListNameValue[];

  initialData: IUnitCreation;
  object: IUnitCreation = {
    radios: [
      {
        Principal: true,
        CommunicationEquipmentType: CommunicationEquipmentType.SmartPhone,
        Identifier: null
      }
    ],
    properties: {},
    vehicleTypeId: 0,
    startDate: today().toISOString(),
    endDate: tomorrow().toISOString(),
    owner: null,
    ownerName: null,
    userId: null,
    userName: null,
    ungrouped: false
  };

  identifierListByType: { [key: number]: ListNameValue<IIdentifierProperties>[] } = {};
  identifierList: ListNameValue<IIdentifierProperties>[] = [];
  localIdentifierList: ListNameValue<IIdentifierProperties>[] = [];
  availableIdentifierList: ListNameValue<IIdentifierProperties>[] = [];

  globalFilterList: ListNameValue<any>[] = [];

  direction: number;

  radiosZone: Zone[];

  callsignValid: boolean = true;
  initialCallSign: string;
  callsignMinCharToSearch: number;

  constructor(
    private claims: RefreshTokenStorageService,
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private unitActivityService: UnitActivityService,
    private translate: TranslateService,
    private fetchList: FetchListService,
    private listNameService: ListNameService,
    private geoSectorService: GeoSectorService,
    private toastCtrl: ToastController,
    private proxy: UnitCreationService,
    private sagaSettingsService: SagaSettingsService
  ) {
    this.applySecurityData();
  }

  applySecurityData() {
    const user = this.claims.read().login.SagaUserAuth;
    this.object.owner = user.SiteId;
    this.object.ownerName = user.OwnerName;
    this.object.userId = user.UserID;
    this.object.userName = user.UserName;
  }

  async ngOnInit() {
    const claims = this.claims.read().login.SagaUserAuth;
    await this.modalService.presentLoading('Saga.UA.Creating.Loading');
    this.direction = claims.SiteId;
    this.types = await this.listNameService.getList('Types', '', this.direction);

    for (const type of this.types) {
      this.subTypes[type.ListNameValueId] = await this.listNameService.getList(`SubTypes.${type.Name}`, '', this.direction);
      this.subTypes[type.ListNameValueId].sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
    }

    this.types.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
    this.modeOfTransports = await this.listNameService.getList('ModeOfTransport', '', this.direction);
    this.modeOfTransports.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
    this.categories = await this.listNameService.getList('Category', '', this.direction);
    this.categories.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));

    (await this.listNameService.getList('RfgiImei', '', this.direction)).forEach(rfgi => {
      this.identifierList.push(rfgi);
    });

    (await this.unitActivityService.getAvailableRfgiImei()).forEach(rfgi => {
      this.availableIdentifierList.push(rfgi);
    });

    if (this.identifier) {
      this.object.radios[0].Identifier = this.identifierList.find(
        identifier => identifier.properties.ImeiAlias === this.identifier
      ).DisplayValue;
    }
    this.radiosZone = await this.geoSectorService.getRadioZone();

    this.globalFilterList = await this.listNameService.getList('poletraitement', '', this.direction);

    if (this.unitActivity && GuidTools.isValid(this.unitActivity.ObjGuid)) {
      const data = await this.proxy.getUnitData(this.unitActivity).toPromise();
      this.object = data;
      this.checkPole();
      this.applySecurityData();
      // deep copy
      this.initialData = JSON.parse(JSON.stringify(this.object));
    } else {
      this.checkPole();
    }

    this.updateIdentifierListByType(true);

    this.otherUnitsCallsign = await this.unitActivityService.getAllUnitActivitiesCallSign().toPromise();
    const radio = this.object.radios.find(r => r.Principal);
    if (radio) {
      this.initialCallSign = this.getCallSign(radio);
      const i = this.otherUnitsCallsign.indexOf(this.initialCallSign);
      if (i !== -1) {
        this.otherUnitsCallsign.splice(i, 1);
      }
    }

    this.callsignMinCharToSearch = this.sagaSettingsService.getValue('radioCallsign.minCharToSearch');

    this.callsignChanged();
    this.updateValid();

    this.modalService.dismissLoading();
  }

  getCallSign(radio: IRadio) {
    let suffix = radio.Description || '';
    suffix = suffix.trim();
    if (suffix.length > 0) {
      suffix = ' / ' + suffix;
    }
    return radio.Name + suffix;
  }

  checkCallsignAvailable(): boolean {
    const radio = this.object.radios.find(r => r.Principal);
    if (radio) {
      return !this.otherUnitsCallsign.includes(this.getCallSign(radio));
    }

    return true;
  }

  callsignChanged() {
    this.callsignValid = this.checkCallsignAvailable();
    this.updateValid();
  }

  checkPole() {
    if (!this.object.properties.poleTraitement) {
      this.object.properties.poleTraitement = [];
    }

    if (this.object.properties.poleTraitement.length === 0) {
      const defaultGlobalFilter = this.globalFilterList.find(globalFilter => globalFilter.IsDefault);
      if (defaultGlobalFilter) {
        this.object.properties.poleTraitement.push(defaultGlobalFilter.ListNameValueId);
      }
    }
  }

  hasChanged() {
    return !this.initialData || JSON.stringify(this.initialData) !== JSON.stringify(this.object);
  }

  async searchTemplate() {
    // TODO: @deprecated please use ModalFactoryService instead
    const selected: ListNameTemplate = await this.modalCtrl
      .create({
        component: UnitTemplateSelectorComponent,
        componentProps: {
          types: this.types,
          subTypes: this.subTypes,
          modeOfTransports: this.modeOfTransports,
          categories: this.categories
        }
      })
      .then(m => m.present().then(() => m.onDidDismiss()))
      .then(r => r.data);
    if (selected) {
      this.applyTemplate(selected);
    }
  }

  applyTemplate(template: ListNameTemplate) {
    const radio = this.object.radios[0];
    radio.Name = template.vehicle.CallCode;
    this.object.registration = template.vehicle.RegistrationNumber;
    radio.Identifier = template.vehicle.VehicleCode;
    this.object.vehicleTypeId = template.vehicle.VehicleTypeId;

    this.object.properties.type = template.vehicle.properties.type;
    this.object.properties.category = template.vehicle.properties.category;
    this.object.properties.modeOfTransport = template.vehicle.properties.modeOfTransport;
    this.object.properties.subType = template.vehicle.properties.subType;
    this.updateValid();
  }

  updateIdentifierListByType(init = false) {
    this.identifierListByType = {};
    this.availableIdentifierList.forEach(rfgi => {
      rfgi.associate = null;
      if (!this.identifierListByType[rfgi.properties.Type]) {
        this.identifierListByType[rfgi.properties.Type] = [];
      }

      this.identifierListByType[rfgi.properties.Type].push(rfgi);
    });

    if (init) {
      this.object.radios.forEach(radio => {
        if (!this.identifierListByType[radio.CommunicationEquipmentType]) {
          this.identifierListByType[radio.CommunicationEquipmentType] = [];
        }

        if (
          radio.Identifier &&
          !this.identifierListByType[radio.CommunicationEquipmentType].find(rfgi => rfgi.DisplayValue === radio.Identifier)
        ) {
          const other = this.identifierList.find(r => r.DisplayValue === radio.Identifier);
          if (other) {
            this.localIdentifierList.push(other);
          }
        }
      });
    }

    this.localIdentifierList.forEach(rfgi => {
      this.identifierListByType[rfgi.properties.Type].push(rfgi);

      rfgi.associate = null;
      const radio = this.object.radios.find(r => r.Identifier === rfgi.DisplayValue);
      if (radio) {
        rfgi.associate = radio;
      }
    });
  }

  communicationTypeChanged(radio: IRadio) {
    delete radio.Identifier;
    this.updateIdentifierListByType();
    this.updateValid();
  }

  identifierChanged(radio: IRadio) {
    this.updateIdentifierListByType();
    this.updateValid();

    if (radio.CommunicationEquipmentType === CommunicationEquipmentType.SmartPhone) {
      const list = this.getRfgiList(radio);
      const rfgiImei = list.find(item => item.DisplayValue === radio.Identifier);
      if (rfgiImei && rfgiImei.properties && rfgiImei.properties.ImeiAlias) {
        radio.PhoneNumber = rfgiImei.properties.ImeiAlias;
      } else {
        radio.PhoneNumber = '';
      }
    } else {
      radio.PhoneNumber = '';
    }
  }

  getRfgiList(radio: IRadio): ListNameValue<IIdentifierProperties>[] {
    return this.identifierListByType[radio.CommunicationEquipmentType] || [];
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save() {
    console.log(this.object);
    if (this.valid) {
      const name = this.initialData ? 'Saga.UpdateUnit' : 'Saga.CreateUnit';
      await this.modalService.presentLoading(name);
      let result: boolean;
      if (!this.initialData) {
        result = await this.unitActivityService.createUnit(this.object);
      } else {
        result = await this.unitActivityService.updateUnit(this.object);
      }
      await this.modalService.dismissLoading();
      if (result) {
        this.modalCtrl.dismiss(true);
      } else {
        this.toastCtrl
          .create({
            duration: 2000,
            message: this.translate.instant(`${name}.Error`)
          })
          .then(toast => toast.present());
      }
    }
  }

  addRadio() {
    this.object.radios.push({
      CommunicationEquipmentType: CommunicationEquipmentType.Undefined
    });

    this.updateValid();
  }

  removeRadio(radio: IRadio) {
    const i = this.object.radios.indexOf(radio);
    if (i !== -1) {
      if (this.object.radios.length === 1) {
        this.object.radios[0].Identifier = null;
        this.object.radios[0].Description = null;
      } else {
        this.object.radios.splice(i, 1);
        if (radio.Principal) {
          this.object.radios[0].Principal = true;
        }
      }
    }

    this.updateValid();
  }

  async search(radio: IRadio, name: string) {
    await this.modalService.presentLoading('Saga.UA.Creating.SearchRadio');
    const names = await this.listNameService.getList('IndicatifsRadio', name, null);
    await this.modalService.dismissLoading();
    if (names.length > 0) {
      const selected: ListNameValue = await this.modalCtrl
        .create({
          component: ListNameSelectorComponent,
          componentProps: {
            listNames: names,
            title: 'SagaMobile.Radio.SelectRadio'
          }
        })
        .then(m => m.present().then(() => m.onDidDismiss()))
        .then(r => r.data);
      if (selected) {
        radio.Name = selected.DisplayValue;
        this.updateValid();
      }
    } else {
      this.toastCtrl
        .create({
          duration: 2000,
          message: this.translate.instant('Saga.Registration.NoName')
        })
        .then(toast => toast.present());
    }
  }

  async typeChanged() {
    this.object.properties.subType = null;
    this.changed();
  }

  changed() {
    this.object.vehicleTypeId = this.getVehicleType(this.object.properties).Id;
    this.updateValid();
  }

  getVehicleType(properties: IExtendedProperties): ListVehicleTypePn {
    const vehicleTypes = this.fetchList.getAllRows<ListVehicleTypePn>(ListVehicleTypePn);
    vehicleTypes.sort((a, b) => b.propertyCount - a.propertyCount);
    let vehicleType = vehicleTypes.find(type => type.match(properties));
    if (!vehicleType) {
      vehicleType = vehicleTypes.find(type => type.Abbreviation === 'default');
    }

    if (!vehicleType) {
      vehicleType = vehicleTypes[0];
    }

    return vehicleType;
  }

  setPrincipalRadio(radio: IRadio) {
    this.object.radios.forEach(r => (r.Principal = false));
    radio.Principal = true;
    this.updateValid();
  }

  async searchRegistration() {
    if (this.object.registration) {
      const founds = this.identifierList.filter(identifier =>
        identifier.properties.RegistrationName.toLowerCase().includes(this.object.registration.toLowerCase())
      );
      if (founds.length > 0) {
        const selected: ListNameValue<IIdentifierProperties> = await this.modalCtrl
          .create({
            component: ListNameSelectorComponent,
            componentProps: {
              listNames: founds,
              title: 'SagaMobile.Registrations',
              displayField: (listName: ListNameValue<IIdentifierProperties>) => {
                return listName.properties.RegistrationName;
              }
            }
          })
          .then(m => m.present().then(() => m.onDidDismiss()))
          .then(r => r.data);
        if (selected) {
          this.object.registration = selected.properties.RegistrationName;
          const exisitingRadio = this.object.radios.find(radio => radio.Identifier === selected.DisplayValue);
          if (!exisitingRadio) {
            const radio: IRadio = {
              CommunicationEquipmentType: selected.properties.Type,
              Identifier: selected.DisplayValue
            };
            this.object.radios.unshift(radio);
            this.setPrincipalRadio(radio);
            this.toastCtrl
              .create({
                duration: 2000,
                message: this.translate.instant('Saga.Registration.RadioAdded')
              })
              .then(toast => toast.present());
          } else {
            this.setPrincipalRadio(exisitingRadio);
          }

          this.updateValid();
        }
      } else {
        this.toastCtrl
          .create({
            duration: 2000,
            message: this.translate.instant('Saga.Registration.NoRegistration')
          })
          .then(toast => toast.present());
      }
    }
  }

  updateValid() {
    if (this.hasChanged()) {
      let allRadio = !this.object.radios.find(radio => {
        return !radio.Name || (radio.CommunicationEquipmentType !== CommunicationEquipmentType.Undefined && !radio.Identifier);
      });
      for (let i = 0; allRadio && i < this.object.radios.length; i++) {
        allRadio = !this.object.radios.find(r => r !== this.object.radios[i] && r.Identifier === this.object.radios[i].Identifier);
      }
      const allProperties =
        this.object.properties.type &&
        this.object.properties.subType &&
        this.object.properties.modeOfTransport &&
        this.object.properties.category;
      const vehicle = !!this.object.vehicleTypeId;

      const startDate = this.object.startDate && new Date(this.object.startDate);
      const endDate = this.object.endDate && new Date(this.object.endDate);
      const date = startDate && endDate && startDate.getTime() < endDate.getTime();

      this.valid = allRadio && allProperties && vehicle && date && this.callsignValid;
    } else {
      this.valid = false;
    }
  }
}
