import { Injectable } from '@angular/core';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { MediaAccessMenuTool } from '../../../schema/menu/media-access-menu-tool';
import { UploadAccessMenuTool } from '../../../schema/menu/upload-access-menu-tool';
import { MODULE_NAME } from '../../../schema/module.name';
import { MessageMenuAccessItemService } from '../../menu/message-menu-access-item/message-menu-access-item.service';
import { UploadMenuAccessItemService } from '../../menu/upload-menu-access-item/upload-menu-access-item.service';

@AddinExecute({
  name: 'MediaMessageAccessTool'
})
@Injectable()
export class MenuAccessService extends Execute {
  private messagesMenuEntry = new MediaAccessMenuTool();
  private uploadsMenuEntry = new UploadAccessMenuTool();

  constructor(
    private sideMenu: SideMenuService,
    private profile: ProfileService,
    private profileReader: ProfileReaderService,
    private messageMenuService: MessageMenuAccessItemService,
    private uploadMenuService: UploadMenuAccessItemService
  ) {
    super();
  }

  execute(): void {
    this.profile.ready.subscribe(ready => this.setup(ready));
  }

  /**
   * Read the profile to enable the media message access button when ready.
   */
  private setup(ready: boolean) {
    if (ready === true && (this.profileReader.getComponent(this.profile.current, MODULE_NAME) || null) !== null) {
      this.sideMenu.addMenuEntry(this.messagesMenuEntry);
      this.sideMenu.addMenuEntry(this.uploadsMenuEntry);
      this.messageMenuService.setup(this.messagesMenuEntry);
      this.uploadMenuService.setup(this.uploadsMenuEntry);
    }
  }
}
