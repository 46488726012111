import { IsAssignmentService } from '@addins/core/status';
import { Injectable } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { IModelService, IObjectChange } from '@services/model-factory';
import { MyEquipmentService } from '@techwan/ionic-core';
import { first } from 'rxjs/operators';
import { CallCardVd144 } from '../../../models/CallCardVd144';
import { CardReceivedCacheService } from '../../card-received-cache/card-received-cache.service';
import { CallcardVd144ModelService } from '../callcard-vd144-model/callcard-vd144-model.service';

@Injectable()
export class UnitActivityVd144ModelService implements IModelService<UnitActivity> {
  constructor(
    private myEquipment: MyEquipmentService,
    private assignment: IsAssignmentService,
    private received: CardReceivedCacheService,
    private cardModel: CallcardVd144ModelService
  ) {}

  change(change: IObjectChange<UnitActivity>) {
    const object = change.target;

    let myUnit;
    // myEquipment.myUnit is a behavioral subject, so we get the value immediately
    // also, if we are at this point, myEquipment.myUnit cannot be null, or we would not boot
    this.myEquipment.myUnit.pipe(first()).subscribe(unitActivity => (myUnit = unitActivity));

    // this logic should not be here
    if (myUnit.ObjGuid === object.ObjGuid && this.assignment.is(change.target.status)) {
      this.received.setTime(change.target.callcard, true);
      this.cardModel.apply(change.target.callcard as CallCardVd144);
    }

    object.changed(null);
  }
}
