import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { StatusListService } from '../../services/status-list/status-list.service';
import { StatusListComponent } from '../../components/status-list/status-list.component';
import { StatusAccessMenuService } from '../../services/executables/status-access-menu/status-access-menu';
import { MapInstanceEventType, MapInstancesService } from '@services/map';
import { filter } from 'rxjs/operators';
import { StatusChangeRequirementsService } from '../../services/status-change-requirements/status-change-requirements.service';
import { MapName } from '@addins/core/core';

@AddinView({
  name: 'BannerView'
})
@Component({
  selector: 'app-horizontal-list',
  templateUrl: './horizontal-list.component.html',
  styleUrls: ['./horizontal-list.component.scss'],
  providers: [StatusListService]
})
export class HorizontalListComponent extends StatusListComponent implements OnInit {
  constructor(
    private readonly _statusAccessMenuService: StatusAccessMenuService,
    private readonly _mapInstancesService: MapInstancesService,
    _statusListService: StatusListService,
    _statusChangeRequirementService: StatusChangeRequirementsService,
    _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_statusListService, _statusChangeRequirementService, _changeDetectorRef);
  }

  public ngOnInit() {
    super.ngOnInit();
    this._statusAccessMenuService.disable();
  }

  protected onInit(): void {
    const map = this._mapInstancesService.get(MapName.main);
    super.onInit();

    if (map) {
      map.updateSize();
    } else {
      this._sub = this._mapInstancesService.$change
        .pipe(filter(event => event.name === MapName.main && event.type === MapInstanceEventType.added))
        .subscribe(() => this.onInit());
    }
  }
}
