import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ListItem } from '../schema/list-item';

export abstract class ListBase<T> {
  readonly list: ListItem<T>[] = [];
  tabType: String;

  protected searchResultsSubscription: Subscription = null;

  protected isEndOfTheList: boolean = false;

  protected maxCountOfVisibleItems: number = 0;
  protected searchTextThreshold: number = 3;

  abstract getItems(offset: number, txt: string): Observable<T[]>;

  search(searchText: string) {
    if (searchText.length < this.searchTextThreshold) {
      this.list.length = 0;
      return;
    }

    if (this.searchResultsSubscription !== null) {
      this.searchResultsSubscription.unsubscribe();
    }
    this.searchResultsSubscription = timer(300)
      .pipe(switchMap(() => this.getItems(0, searchText.toLocaleLowerCase())))
      .subscribe(queriedItems => this.parse(queriedItems, true));
  }

  protected parse(items: T[], reset: boolean, load?: boolean) {
    if (!this.maxCountOfVisibleItems || reset) {
      this.maxCountOfVisibleItems = items.length;
    }
    this.isEndOfTheList = !items.length || items.length < this.maxCountOfVisibleItems;

    // Reset the list when required
    if (reset) {
      this.list.length = 0;
    }

    // Populate the list
    items.forEach((item: any) => this.list.push(load ? item : new ListItem<T>(item)));
    this.searchResultsSubscription = null;
  }
}
