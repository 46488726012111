import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { fromEvent, Subscription, timer } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AlertCounterComponent } from '../../components/alert-counter/alert-counter.component';
import { CountDown } from '../../schema/count-down';
import { ALERT_PROVIDER_TOKEN } from '../../schema/injection-token';
import { IAlertProvider } from '../../schema/interfaces/alert-provider';

@Directive({
  selector: '[appAlert]'
})
export class AlertDirective implements OnInit, OnDestroy {
  @Input()
  private appAlert: AlertCounterComponent;
  @Input()
  private event: MobEvents = null;

  private _countDown = new CountDown();

  private _options = { capture: true, passive: true };

  private _holdTimeout: number;

  private createAlarmSubscription: Subscription;
  private creationTimerSubscription: Subscription;

  private CREATE_ALARM_TIMEOUT = 20000;

  constructor(
    @Inject(ALERT_PROVIDER_TOKEN)
    private alert: IAlertProvider,
    private elemetRef: ElementRef,
    private renderer: Renderer2,
    private toastService: ToastService
  ) {}

  ngOnDestroy() {
    this.clear();
  }

  ngOnInit() {
    this.alert
      .canCreate()
      .pipe(
        filter(response => response),
        first()
      )
      .subscribe(r => {
        this._holdTimeout = this.alert.getHoldTimeout();
        fromEvent(this.elemetRef.nativeElement, 'touchstart', this._options)
          .pipe(first())
          .subscribe(() => this.start());
      });
  }

  private clear(reset?: boolean) {
    this._countDown.stop();
    if (this.creationTimerSubscription) {
      this.creationTimerSubscription.unsubscribe();
      this.creationTimerSubscription = null;
    }

    // Start to listen to the touchstart event again
    if (reset) {
      fromEvent(this.elemetRef.nativeElement, 'touchstart', this._options)
        .pipe(first())
        .subscribe(() => this.start());
    }
  }

  private start() {
    this.clear();
    this._countDown.start(this.elemetRef.nativeElement, this.appAlert.e.nativeElement, this.renderer, this._holdTimeout);
    this.creationTimerSubscription = timer(this._holdTimeout).subscribe(() => this.create());
    fromEvent(this.elemetRef.nativeElement, 'touchend', this._options)
      .pipe(first())
      .subscribe(() => this.clear(true));
  }

  private create() {
    this.clear(true);
    if (this.isAnimationNeeded()) {
      const childElement = this.elemetRef.nativeElement.querySelector('ion-toolbar') || document.getElementById('generic-login-toolbar');
      const defaultBackgroundColor = this.elemetRef.nativeElement.querySelector('ion-toolbar') ? 'ion-color-primary' : 'ion-color-light';
      this.modifyElementClasses(childElement, ['blink', 'blink-success', 'blink-fail'], [defaultBackgroundColor]);
      this.modifyElementClasses(childElement, [defaultBackgroundColor], ['blink']);

      const alarmResponseTimer = setTimeout(() => {
        this.modifyElementClasses(childElement, ['blink'], ['blink-fail']);
        if (this.createAlarmSubscription) {
          this.createAlarmSubscription.unsubscribe();
          this.createAlarmSubscription = null;
        }

        this.toastService.show('Mobile.CreateEventFailed', ToastColor.Error);
      }, this.CREATE_ALARM_TIMEOUT);

      this.createAlarmSubscription = this.alert
        .create(this.event)
        .pipe(first())
        .subscribe(
          () => {
            clearTimeout(alarmResponseTimer);
            this.modifyElementClasses(childElement, ['blink'], ['blink-success']);
          },
          error => {
            clearTimeout(alarmResponseTimer);
            this.modifyElementClasses(childElement, ['blink'], ['blink-fail']);
            this.displayAlertCreateError(error);
          }
        );
    } else {
      this.alert
        .create(this.event)
        .pipe(first())
        .subscribe(
          () => {},
          error => {
            this.displayAlertCreateError(error);
          }
        );
    }
  }

  private displayAlertCreateError(error) {
    this.toastService.show(typeof error === 'string' ? error : 'Mobile.CreateEventFailed', ToastColor.Error);
  }

  private modifyElementClasses(element: HTMLElement, removeClasses: string[], addClasses: string[]) {
    removeClasses.forEach(removeClassName => this.renderer.removeClass(element, removeClassName));
    addClasses.forEach(addClassName => this.renderer.addClass(element, addClassName));
  }

  private isAnimationNeeded(): boolean {
    return (
      (this.elemetRef.nativeElement as HTMLElement).id &&
      ['sagamobile-header', 'loading-alert-button'].includes((this.elemetRef.nativeElement as HTMLElement).id)
    );
  }
}
