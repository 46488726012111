import { Component } from '@angular/core';
import { FollowupTemplate } from '@addins/callcard/components/callCardDetail/callCardDetailFollowup/FollowupTemplate';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { Security } from '@services/security/security.service';

@Component({
  templateUrl: 'CAttachmentFollowup.html'
})
export class CAttachmentFollowupComponent extends FollowupTemplate {
  baseThumbUrl: string;

  constructor(private security: Security) {
    super();
    this.init();
  }

  init() {
    this.security.addBaseUrl('api/Attachments/Get/?ids=').subscribe(res => (this.baseThumbUrl = res));
  }

  attachment(): CAttachment {
    return this.data;
  }

  getImageUrl() {
    return 'data:image/png;base64,' + this.attachment().Thumbnail;
  }
}
