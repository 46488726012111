import { Component, Input, OnInit } from '@angular/core';

interface TopicItem {
  name: string;
  value: string;
}

@Component({
  selector: 'app-xml-topics',
  templateUrl: './xml-topics.component.html',
  styleUrls: ['./xml-topics.component.scss']
})
export class XmlTopicsComponent implements OnInit {
  private _xmlTopics: TopicItem[];
  get xmlTopics() {
    return this._xmlTopics;
  }

  @Input()
  set xmlTopics(value: any) {
    this._xmlTopics = Object.keys(value).map(k => ({ name: k, value: value[k] }));
  }

  constructor() {}

  ngOnInit() {}

  isCheckbox(item: TopicItem): boolean {
    return item.value === '0' || item.value === '1';
  }
}
