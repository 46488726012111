import { Injectable } from '@angular/core';
import { TranslatedAlertButton, TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { IDrawingEditionConfirmation } from '@techwan/map-tools';

@Injectable()
export class MapEditionConfirmationService implements IDrawingEditionConfirmation {
  constructor(private translatedAlert: TranslatedAlertService) {}

  private readonly BUTTONS: TranslatedAlertButton[] = [
    {
      text: 'Mobile.No',
      role: 'cancel',
      value: false
    },
    {
      text: 'Mobile.Yes',
      value: true
    }
  ];

  onRemove(): Promise<boolean> {
    return this.translatedAlert.show({
      header: 'Mobile.Drawing.RemoveTitle',
      message: 'Mobile.Drawing.RemoveMessage',
      buttons: this.BUTTONS
    });
  }

  onCancel(): Promise<boolean> {
    return this.translatedAlert.show({
      header: 'Mobile.Drawing.CancelTitle',
      message: 'Mobile.Drawing.CancelMessage',
      buttons: this.BUTTONS
    });
  }

  onSave(): Promise<boolean> {
    return this.translatedAlert.show({
      header: 'Mobile.Drawing.SaveTitle',
      message: 'Mobile.Drawing.SaveMessage',
      buttons: this.BUTTONS
    });
  }
}
