// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CrisisComponentLink_abstract extends BaseObject {
  static $t: string = 'SagaSchema.CrisisComponentLink';
  $t: string = 'SagaSchema.CrisisComponentLink';

  public static GetMembers(): string[] {
    return ['CrisisMessageUid', 'CallcardUid'];
  }

  public GetMembers(): string[] {
    return CrisisComponentLink_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CrisisMessageUid: Guid = GuidTools.Empty;
  public CallcardUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.CallcardUid;
  }
  get ParentObjId(): any {
    return this.CallcardUid;
  }
  set ParentObjId(value: any) {
    this.CallcardUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
