<div class="media">
  <a class="action" (click)="open()" href="#">
    <img class="message-image" [src]="thumbnail" alt="thumbnail" />
    <div class="loading-text"></div>
  </a>
  <img class="attachment-state" alt="state" [src]="getMediaFileStatusImage()" />
  <p *ngIf="isInitializing()">{{ 'mobile.download.initializing' | translate }}</p>
  <ion-progress-bar *ngIf="isDownloading()" [value]="getProgress()"></ion-progress-bar>
  <p class="file-error" *ngIf="hasError()" [translate]="getError()"></p>
</div>
