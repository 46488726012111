// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class UserAccessArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.UserAccessArgs';
  $t: string = 'SagaBase.Defines.UserAccessArgs';

  public static GetMembers(): string[] {
    return ['Operation', 'ADOperations', 'User', 'AssignedRoles', 'DeAssignedRoles', 'Profiles', 'UpdateAD'];
  }

  public GetMembers(): string[] {
    return UserAccessArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Operation: SagaBase.Defines.UserAccessOperation = SagaBase.Defines.UserAccessOperation.None;
  public ADOperations: number = 0;
  public User: any;
  public AssignedRoles: any[];
  public DeAssignedRoles: any[];
  public Profiles: any[];
  public UpdateAD: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
