import { Injectable } from '@angular/core';
import { Guid, GuidTools } from '@models/guid';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CAnimal } from '@models/imported/SagaSchema/CAnimal';
import { CFreeText } from '@models/imported/SagaSchema/CFreeText';
import { CObject } from '@models/imported/SagaSchema/CObject';
import { CPerson } from '@models/imported/SagaSchema/CPerson';
import { CStamp } from '@models/imported/SagaSchema/CStamp';
import { CVehicle } from '@models/imported/SagaSchema/CVehicle';
import { List } from '@models/list';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CallcardFilterService } from '@services/callcard-filter/callcard-filter.service';
import { Security } from '@services/security/security.service';
import { Observable, Subject } from 'rxjs';

const STAMP_API_URL: string = 'api/Stamp';

@Injectable({
  providedIn: 'root'
})
export class CallcardService {
  /**
   * @deprecated please consider using CurrentCardService
   */
  readonly onCardDisplay = new Subject<CallCard>();

  private _lists: any = {};

  private readonly _cardChanged = new Subject<CallCard>();
  get onCardChange(): Observable<CallCard> {
    return this._cardChanged.asObservable();
  }

  constructor(private cache: CacheService, private security: Security, private callcardFilterService: CallcardFilterService) {
    this.cache.state.subscribe(state => this.onCacheStateChanged(state));
    this.cache.listenForChange(CallCard).subscribe(() => this.onCallCardChanged());
  }

  private onCacheStateChanged(state: CacheState) {
    if (state === CacheState.idle) {
      this._lists = {};
    }
  }

  private onCallCardChanged() {
    Object.keys(this._lists).forEach(name => this._lists[name].refresh());
  }

  getCardsList(id: string, type: typeof CallCard, init: (list: List<CallCard>) => void = () => {}): List<CallCard> {
    if (!this._lists[id]) {
      this._lists[id] = this.cache.getListOf<CallCard>(type);
      this.callcardFilterService.filters.forEach(callcardFilter => this._lists[id].addFilter(callcardFilter));
      init(this._lists[id]);
    }

    return this._lists[id];
  }

  async loadStamps(callcard: CallCard) {
    callcard.isStampLoading = true;

    try {
      const data = await this.security.post<Array<any>>('api/Stamp/LoadStamps', {
        CallCardUid: callcard.ObjGuid
      });
      const stamps: CStamp[] = data.map(s => s && this.cache.create(s, true)).filter(s => s);
      callcard.loadStamps(stamps);
      this.cache.callChanged(callcard, {
        stamps: true
      });
    } catch (response) {}

    callcard.isStampLoading = false;
    return callcard;
  }

  addCro(callcard: CallCard, text: string): Promise<any> {
    return this.security.post(`${STAMP_API_URL}/AddCRO`, {
      CallCardId: callcard.ObjGuid,
      Text: text,
      owner: callcard.Owner
    });
  }

  addFreetext(callcardId: Guid, text: string, type?: number): Promise<any> {
    return this.security.post(`${STAMP_API_URL}/AddFreetext`, {
      CallCardId: callcardId,
      Text: text,
      FreeTextType: type
    });
  }

  updateFreetext(text: CFreeText): Promise<any> {
    return this.security.post(`${STAMP_API_URL}/UpdateFreetext`, text);
  }

  addParticularsStamp(stamp: CPerson | CVehicle | CObject | CAnimal): Promise<any> {
    stamp.ObjGuid = GuidTools.generate();
    const stampTypeName = this.getParticularStampName(stamp.$t);
    return this.security.post(`${STAMP_API_URL}/Add${stampTypeName}Stamp`, stamp);
  }

  updateParticularsStamp(stamp: CPerson | CVehicle | CObject | CAnimal): Promise<any> {
    const stampTypeName = this.getParticularStampName(stamp.$t);
    return this.security.post(`${STAMP_API_URL}/Add${stampTypeName}Stamp`, stamp);
  }

  private getParticularStampName(type: string): string {
    switch (type) {
      case CPerson.$t:
        return 'CPerson';
      case CVehicle.$t:
        return 'CVehicle';
      case CAnimal.$t:
        return 'CAnimal';
      case CObject.$t:
        return 'CObject';
    }
  }
}
