import { Injectable } from '@angular/core';

@Injectable()
export class HeaderProviderService {
  private _view: string = '';

  constructor() {}

  public getView(): string {
    return this._view;
  }

  public setView(view: string) {
    this._view = view;
  }
}
