import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeploymentRequests } from '../../schema/interfaces/deployment-requests';

@Injectable()
export class DeploymentProxyService {
  constructor(private readonly http: HttpClient) {}

  save(deploymentRequest: DeploymentRequests): Observable<boolean> {
    return this.http.post<boolean>('api/Drawing/SaveDeployment', deploymentRequest);
  }

  remove(deploymentRequest: DeploymentRequests): Observable<boolean> {
    return this.http.post<boolean>('api/Drawing/RemoveDeployment', deploymentRequest);
  }
}
