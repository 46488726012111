import { Initializer } from '@services/initializer/initializer.service';
import { CompletionHandler } from './completion-handler';

export interface ICompletionDelegate {
  $onComplete(): void;
}

export class CompletionHandlerDelegate extends CompletionHandler {
  private _complete: boolean;

  constructor(protected initializer: Initializer, private readonly delegate: ICompletionDelegate) {
    super(initializer);
    if (this._complete) {
      this.delegate.$onComplete();
    }
  }

  protected $onComplete() {
    if (this.delegate) {
      this.delegate.$onComplete();
    } else {
      this._complete = true;
    }
  }
}
