import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CObject } from '@models/imported/SagaSchema/CObject';
import { ParticularsCollapsibleItem } from './particulars-collapsible-item';
import { ParticularsPosition } from './particulars-position';

const CObjectListCollapsibleName = 'Mobile.CObjectDetails';
const CObjectListViewName = 'SingleCallcardParticulars/CallcardDetailCObject';

export class CObjectCollapsibleItem extends ParticularsCollapsibleItem {
  constructor() {
    super(CObjectListCollapsibleName, CObjectListViewName, ParticularsPosition.CObject);
  }

  updateVisibility(callCard: CallCard): void {
    this.setVisible(callCard !== null ? callCard.component<CObject>(CObject).length > 0 : false);
  }
}
