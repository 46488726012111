import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

export interface SagaToastOptions extends ToastOptions {
  message?: string;
}

/**
 * Extends ToastController to automatically translate messages !
 */
@Injectable()
export class SagaToastController extends ToastController {
  constructor(private translate: TranslateService) {
    super();
  }

  create(opts?: SagaToastOptions): Promise<HTMLIonToastElement> {
    opts.message = this.translate.instant(opts.message);
    return super.create(opts);
  }
}
