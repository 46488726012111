// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:40
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class GeoFenceRequestArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.GeoFenceRequestArgs';
  $t: string = 'SagaBase.GeoFenceRequestArgs';

  public static GetMembers(): string[] {
    return ['Projection', 'CheckDate', 'Broadcast', 'GeometryFormat'];
  }

  public GetMembers(): string[] {
    return GeoFenceRequestArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.CheckDate = this.CheckDate && new Date(this.CheckDate);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Projection: string = 'EPSG:4326';
  public CheckDate: Date = new Date();
  public Broadcast: boolean = true;
  public GeometryFormat: SagaBase.GeometryFormats = SagaBase.GeometryFormats.Default;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
