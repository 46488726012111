import { CommonAddinModule } from '@addins/common.module';
import { CardInfoFormatterService, CoreModule } from '@addins/core/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ModelFactoryService, registerModelFactory } from '@services/model-factory';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { CallCardVd144 } from './models/CallCardVd144';
import { CardReceivedCacheService } from './services/card-received-cache/card-received-cache.service';
import { CallcardVd144ModelService } from './services/models/callcard-vd144-model/callcard-vd144-model.service';

import { UnitActivityVd144ModelService } from './services/models/unit-activity-vd144-model/unit-activity-vd144-model.service';
import { Vd144CardInfoFormatterService } from './services/vd144-card-info-formatter/vd144-card-info-formatter.service';

const services = [CardReceivedCacheService, CallcardVd144ModelService, UnitActivityVd144ModelService, Vd144CardInfoFormatterService];

@NgModule({
  imports: [CoreModule, CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule],
  exports: [CoreModule, CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule],
  providers: services
})
@Addin({
  name: 'Vd144'
})
export class Vd144Module extends AddinModule {
  constructor(
    injector: Injector,
    private _modelFactory: ModelFactoryService,
    private _vd144CardInfoFormatter: Vd144CardInfoFormatterService,
    private _cardInfoFormatter: CardInfoFormatterService
  ) {
    super(injector, [], []);
  }

  load() {
    super.load();

    this._modelFactory.registerModelWithFactory(CallCardVd144, CallcardVd144ModelService);

    registerModelFactory(UnitActivityVd144ModelService, UnitActivity);

    this._cardInfoFormatter.override(this._vd144CardInfoFormatter);
    this._vd144CardInfoFormatter.start();
  }
}
