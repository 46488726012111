import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { CustomCreationModalComponent } from './components/custom-creation-modal/custom-creation-modal.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';
import { InternalResourceListComponent } from './components/internal-resource-list/internal-resource-list.component';
import { ListBaseComponent } from './components/list-base-component/list-base-component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { UnitSummaryComponent } from './components/unit-summary/unit-summary.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { VirtualizedListComponent } from './components/virtualized-list/virtualized-list.component';
import { CustomUnitCreationService } from './services/custom-unit-creation/custom-unit-creation.service';
import { WaitingRoomAccessService } from './services/executables/waiting-room-access/waiting-room-access.service';
import { ResourceProxyService } from './services/resource-proxy/resource-proxy.service';
import { ResourcesProviderService } from './services/resources-provider/resources-provider.service';
import { UnitCreationService } from './services/unit-creation/unit-creation.service';
import { UnitEditorService } from './services/unit-editor/unit-editor.service';

const executables = [WaitingRoomAccessService];

const views = [];

const directives = [];

const components = [
  ListItemComponent,
  UnitSummaryComponent,
  VehicleListComponent,
  EquipmentListComponent,
  VirtualizedListComponent,
  CustomCreationModalComponent,
  InternalResourceListComponent,
  ListBaseComponent,
  ...views
];

const declarations = [...directives, ...components, ...views];

const providers = [
  UnitEditorService,
  UnitCreationService,
  ResourceProxyService,
  ResourcesProviderService,
  CustomUnitCreationService,
  ...executables
];

@Addin({
  name: 'UnitCustomCreation'
})
@NgModule({
  declarations,
  imports: [IonicModule, SagaDirectivesModule, TranslateModule],
  providers
})
export class UnitCustomCreation extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
