import { Observable, Subject } from 'rxjs';
import { PanelAction, PanelTool, PropertyChanged } from '../interfaces/panel-tool';
import { NotImplementedException } from '../not-implemented-exception';

export class PanelPinTool implements PanelTool {
  action = PanelAction.toggleDismiss;
  position = 0;
  isVisible: boolean = true;

  get isActive(): boolean {
    return !this._unpinned;
  }

  get $changed(): Observable<PropertyChanged> {
    throw new NotImplementedException();
  }

  private _click = new Subject<Event>();
  get $click(): Observable<Event> {
    return this._click.asObservable();
  }

  private _unpinned = false;

  private _text: string;
  getText(): string {
    return this._text;
  }
  setText(txt: string) {
    this._text = txt;
  }

  isUnpinned(): boolean {
    return this._unpinned;
  }
  setUnpinned(value: boolean) {
    this._unpinned = value;
  }

  click(e: Event) {
    this._unpinned = !this._unpinned;
    this._click.next(e);
    e.preventDefault();
    e.stopPropagation();
  }

  reset(): void {
    this._unpinned = true;
  }

  destroy(): void {}
}
