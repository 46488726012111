// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:40
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ScannedAssembly_abstract extends BaseObject {
  static $t: string = 'SagaBase.Factory.ScannedAssembly';
  $t: string = 'SagaBase.Factory.ScannedAssembly';

  public static GetMembers(): string[] {
    return ['IsFactory', 'IsCached', 'Timestamp', 'AssemblyName'];
  }

  public GetMembers(): string[] {
    return ScannedAssembly_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.Timestamp = this.Timestamp && new Date(this.Timestamp);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public IsFactory: boolean = false;
  public IsCached: boolean = false;
  public Timestamp: Date = new Date();
  public AssemblyName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
