// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:32:06
import { CallCard } from '../../SagaSchema/CallCard';

export abstract class CallCardDispatch_abstract extends CallCard {
  static $t: string = 'SagaSchema.CallCardDispatch';
  $t: string = 'SagaSchema.CallCardDispatch';

  public static GetMembers(): string[] {
    return [].concat(CallCard.GetMembers());
  }

  public GetMembers(): string[] {
    return CallCardDispatch_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.CCaller',
      'SagaSchema.CEvent',
      'SagaSchema.CLocation',
      'SagaSchema.CDeploymentPoint',
      'SagaSchema.CObject',
      'SagaSchema.CPerson',
      'SagaSchema.CAnimal',
      'SagaBase.PhoneCall',
      'SagaSchema.CStampCard',
      'SagaSchema.CStampResource',
      'SagaSchema.CStampFree',
      'SagaSchema.CStampProcedure',
      'SagaSchema.CStampProcedureAction',
      'SagaSchema.CVehicle',
      'SagaSchema.CAttachment',
      'SagaSchema.CNote',
      'SagaSchema.CTacticalSituation',
      'SagaSchema.History.CTacticalSituationHistory',
      'SagaSchema.CAlarm',
      'SagaSchema.ComJobOverview',
      'SagaSchema.CPatient',
      'SagaSchema.CProcStep',
      'SagaSchema.CIntel',
      'SagaSchema.CStampParticulars',
      'SagaSchema.CFreeText',
      'SagaSchema.CStampFreeText',
      'SagaSchema.CUnmanagedResourceActivity',
      'SagaSchema.CStampUnitActivity',
      'TW.Saga.Core.Schema.Callcard.CMediaMessage',
      'SagaSchema.History.CCallerHistory',
      'SagaSchema.History.CPatientHistory',
      'SagaSchema.History.CEventHistory',
      'SagaSchema.History.CLocationHistory',
      'SagaSchema.History.CNoteHistory',
      'SagaSchema.History.CPersonHistory',
      'SagaSchema.History.CVehicleHistory',
      'SagaSchema.History.CAnimalHistory',
      'SagaSchema.History.CObjectHistory',
      'SagaSchema.CStampShare',
      'SagaSchema.TaskFrequency',
      'SagaSchema.CFormLink'
    ];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
