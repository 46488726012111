import { Injectable } from '@angular/core';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { User } from '@models/imported/SagaBase/User';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { FetchListService } from '../fetchlist/fetchlist.service';
import { Initializer } from '../initializer/initializer.service';
import { SagaSettingsService } from '../settings';
import { CacheService } from '../cache/cache.service';
import { Security } from '../security/security.service';
import { environment } from '@environments/environment';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CompletionHandler } from '../initializer/completion-handler';
import { IUnitSetup, MyEquipmentService } from '@techwan/ionic-core';
import { UnitActivity as UA } from '@techwan/ionic-core/lib/schema/imported/SagaSchema/UnitActivity';
import { ILoginResult } from '@techwan/security';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { filter } from 'rxjs/operators';

export enum SetupDeviceCode {
  Success,
  BadPassword,
  UnknowmDevice,
  LockedDevice = 3,
  Unauthorized = 4,
  InvalidArguments = 5,
  MultipleDevice = 6,
  NotLinked = 7
}

export interface ISetupDeviceResponse {
  error: SetupDeviceCode;
  retryCount: number;
  device: CommunicationEquipment;
}

interface IAuthenticationResult {
  TokenString: string;
  SagaUserAuth: User;
}

export class SetupDeviceResult {
  constructor(
    private code: SetupDeviceCode,
    private device: CommunicationEquipment = null,
    private response: ISetupDeviceResponse = null
  ) {}

  getCode(): SetupDeviceCode {
    return this.code;
  }

  getDevice(): any {
    return this.device;
  }

  getResponse(): ISetupDeviceResponse {
    return this.response;
  }
}

export const DEVICE_SECURITY_KEY = 'saga_device_associate';

@Injectable()
export class AssociationSecurity {
  private _device: CommunicationEquipment;
  private _unitActivity: UnitActivity;
  private _loginData: ILoginResult;
  private _statusFilter: number;

  get unitActivity(): UnitActivity {
    return this._unitActivity;
  }

  set unitActivity(unitActivity: UnitActivity) {
    this._unitActivity = unitActivity;
    this.security.setData('UnitActivity', unitActivity);
  }

  /**
   * @deprecated Please consider using RefreshTokenService from LoginModule instead.
   */
  get loginData(): ILoginResult {
    return this._loginData;
  }

  /**
   * @deprecated Please consider using CredentialService from ionic-core instead.
   */
  set loginData(value: ILoginResult) {
    if (value.SagaUserAuth) {
      value.SagaUserAuth = this.cache.objectFactory.create(value.SagaUserAuth, 'SagaBase.User');
    }
    this.security.loggedUser = value.SagaUserAuth as User;
    this._loginData = value;
  }

  constructor(
    private security: Security,
    private _fetchList: FetchListService,
    initializer: Initializer,
    private appSettingsService: SagaSettingsService,
    private cache: CacheService,
    private readonly myEquipmentService: MyEquipmentService,
    private readonly factory: ObjectFactory
  ) {
    initializer.$onComplete.pipe(filter(ready => ready)).subscribe(() => this.onInitializerReady());

    this.cache.listenForChange(UnitActivity).subscribe(event => this.onUnitActivityChanged(event.object as UnitActivity));
  }

  private onUnitActivityChanged(unitActivity: UnitActivity) {
    if (this.unitActivity && this.unitActivity.ObjGuid === unitActivity.ObjGuid && this.unitActivity !== unitActivity) {
      this.unitActivity = unitActivity;
    }
  }

  onInitializerReady(): void {
    let unit: UA = null;
    this.myEquipmentService.myUnit.subscribe(u => (unit = u)).unsubscribe();
    // Read device from device service.

    this.myEquipmentService.myDevice.subscribe(d => (this._device = this.factory.create(d, CommunicationEquipment))).unsubscribe();
    if (unit !== null) {
      this.unitActivity = this.cache.getObjectByUid(unit.ObjGuid);

      if (this.unitActivity.vehicles.length === 0 && unit.resources && unit.resources.vehicles) {
        unit.resources.vehicles.forEach(v => this.unitActivity.vehicles.push(this.factory.create(v)));
      }

      // Devices are not booted
      unit.resources.devices.forEach(d => this.unitActivity.devices.push(this.factory.create(d)));
    }
  }

  /**
   * @deprecated Use RefreshTokenService instead.
   */
  public getUser(): User {
    return this.loginData.SagaUserAuth as User;
  }

  public clearDevice() {
    this.security.deleteSecurityData(DEVICE_SECURITY_KEY);
  }

  /**
   * @deprecated This method has been deprecated since @techwan/ionic-core has been replacing it
   * @param deviceName name of the equipment to link to
   * @param password password for the equipment
   */
  public async deviceSetup(deviceName: string, password: string = null): Promise<SetupDeviceResult> {
    try {
      return await this.security.get<ISetupDeviceResponse>(`api/UnitActivity/Setup?deviceName=${deviceName}`).then(raw => {
        if (raw.device) {
          const device = raw.device;
          this.setDevice(deviceName);
          this._device = this.cache.objectFactory.create(device, 'SagaSchema.CommunicationEquipment');
          return new SetupDeviceResult(SetupDeviceCode.Success, this._device, raw);
        } else {
          return new SetupDeviceResult(raw.error || SetupDeviceCode.UnknowmDevice, this._device, raw);
        }
      });
    } catch (response) {
      let code = SetupDeviceCode.UnknowmDevice;
      switch (response.status) {
        case 401:
          code = SetupDeviceCode.UnknowmDevice;
          break;
        case 500:
          if (environment.addinParameters.login && environment.addinParameters.login.field === 'imeiAlias') {
            code = SetupDeviceCode.UnknowmDevice;
          }
          break;
      }
      return new SetupDeviceResult(code);
    }
  }

  setDevice(name: string) {
    this.security.addSecurityData(DEVICE_SECURITY_KEY, name);
  }

  public async getDevices() {
    try {
      const device = this.getDevice();
      if (device) {
        try {
          return await this.security
            .post<Array<any>>('api/UnitActivity/GetEquipments', {
              owner: device.Owner
            })
            .then(res => res.map(d => this.cache.objectFactory.create(d, 'SagaSchema.CommunicationEquipment')));
        } catch (e) {
          return Promise.reject(e);
        }
      } else {
        return [];
      }
    } catch (response) {
      return [];
    }
  }

  public async setUserSiteId(site: number) {
    this.loginData.SagaUserAuth.SiteId = site;
    return await this.security.get(`api/SectorsProfils/UpdateUserSiteId?siteid=${site}`);
  }

  public async getVehicles(extend: boolean = false) {
    try {
      const device = this.getDevice();
      if (device) {
        if (!this._statusFilter) {
          const val = this.appSettingsService.getValue('SagaMobileWebClient.VehicleStatusFilter');
          if (val) {
            this._statusFilter = 0;
            for (const key in val) {
              if (val.hasOwnProperty(key)) {
                this._statusFilter += parseInt(key, 10);
              }
            }
          }
        }

        return await this.security
          .post<Array<any>>('api/UnitActivity/GetVehicles', {
            owner: this.loginData.SagaUserAuth.SiteId,
            visibility: extend ? 1 : 0,
            statusFilter: this._statusFilter,
            activityStatus: 1
          })
          .then(res =>
            res.map(d => {
              const vehicle = this.cache.objectFactory.create(d);
              vehicle.update(this._fetchList);
              return vehicle;
            })
          );
      } else {
        return [];
      }
    } catch (response) {
      return [];
    }
  }

  async getMyCard(type: typeof CallCard = CallCard): Promise<CallCard> {
    try {
      return await this.security
        .post('api/UnitActivity/GetUnitActivityCard', {
          unitActivityUid: this.unitActivity.ObjGuid
        })
        .then((response: any) => {
          let callcard = null;
          if (response) {
            callcard = this.cache.getObjectByUid(response.ObjGuid);

            if (!callcard) {
              callcard = this.cache.addObjToCache(response, type);
            }
          }

          return callcard;
        });
    } catch (response) {
      return null;
    }
  }

  private readUnitActivityResponse(data: IUnitSetup) {
    this._unitActivity = this.cache.getObjectByUid(data.unit.ObjGuid);

    if (!this._unitActivity) {
      this._unitActivity = this.cache.objectFactory.create(data.unit, UnitActivity);
      this._unitActivity.update(this._fetchList);
    }

    this.setResources(this._unitActivity, data);
  }

  public async retreiveUnitActivity(): Promise<any> {
    try {
      return await this.security
        .post('api/UnitActivity/GetUnitActivity', {
          equipmentUid: this.getDevice().ObjGuid
        })
        .then(response => {
          if (response) {
            this.readUnitActivityResponse(response);
          } else {
            delete this.unitActivity;
          }

          return {
            success: true,
            activity: this.unitActivity
          };
        });
    } catch (response) {
      return {
        success: false,
        activity: null
      };
    }
  }

  public async setUnitActivity(unitActivity: UnitActivity): Promise<boolean> {
    try {
      return await this.security.post('api/UnitActivity/AddUnitActivity', unitActivity.export()).then((response: IUnitSetup) => {
        if (response) {
          this.readUnitActivityResponse(response);
          return true;
        }

        return false;
      });
    } catch (response) {
      return false;
    }
  }

  /**
   * @deprecated prefer using unitActivity instead
   */
  public getUnitActivity(): UnitActivity {
    return this.unitActivity;
  }

  /**
   * @deprecated Please use @techwan/ionic-core instead
   */
  async checkDevice(): Promise<CommunicationEquipment> {
    const name = this.security.getSecurityData<string>(DEVICE_SECURITY_KEY);
    if (name) {
      return this.deviceSetup(name, null).then(result => {
        if (result.getCode() !== SetupDeviceCode.Success) {
          this.clearDevice();
          return null;
        } else {
          return result.getDevice();
        }
      });
    }
    return null;
  }

  public getDevice(): CommunicationEquipment {
    return this._device;
  }

  /**
   * @deprecated Please prefer using security library instead.
   */
  async getLoginConfig(): Promise<any> {
    return await this.security.request({
      method: 'get',
      url: 'api/security/Config'
    });
  }

  public setResources(unitActivity: UnitActivity, resources: IUnitSetup) {
    unitActivity.devices.length = 0;
    unitActivity.vehicles.length = 0;

    resources.devices.forEach(com => {
      unitActivity.devices.push(this.cache.objectFactory.create(com));
    });

    resources.vehicles.forEach(vehicle => {
      const vehicles: Vehicle = this.cache.objectFactory.create(vehicle);
      vehicles.update(this._fetchList);
      unitActivity.vehicles.push(vehicles);
    });
  }
}
