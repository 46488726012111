import { EventEmitter, Injectable, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Coordinate } from '@models/coordinate';
import { StatusItemAction } from '@models/imported/SagaBase';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { UnitActivityProxyService } from '@services/unit-activity-proxy/unit-activity-proxy.service';
import { IUnitSetup, MyEquipmentService } from '@techwan/ionic-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssociationSecurity } from '../association-security/association-security.service';
import { CacheService } from '../cache/cache.service';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { Security } from '../security/security.service';
import { StatusService } from '../status/status.service';

/**
 * @description
 * Service used to manage UnitActivity
 */
@Injectable()
export class UnitActivityService {
  public readonly onStatusChange = new EventEmitter<ListResourceStatus>();
  public readonly onCardChange = new EventEmitter<CallCard>();

  get unitActivity(): UnitActivity {
    return this.security.getData('UnitActivity');
  }

  constructor(
    private security: Security,
    private objectFactory: ObjectFactory,
    private statusService: StatusService,
    private cache: CacheService,
    private associationSecurity: AssociationSecurity,
    private modalCtrl: ModalController,
    private readonly unitProxy: UnitActivityProxyService,
    private readonly claims: RefreshTokenStorageService,
    private readonly myEquipment: MyEquipmentService
  ) {}

  public select(t: Type<any>): Promise<any> {
    return Promise.resolve();
  }

  public clear() {
    this.myEquipment.setUnit(null);
  }

  public getAvailableRfgiImei() {
    return this.unitProxy
      .getAvailableRfgiImei()
      .pipe(map((n: any) => this.createList<any>(n, ListNameValue)))
      .toPromise();
  }

  private createList<T>(names: Array<any>, type: T): T[] {
    return this.objectFactory.createList(names, type).map(obj => {
      this.cache.callChanged(obj);
      return obj;
    });
  }

  async openCreateUnit(editor: Type<any>, options?: any): Promise<UnitActivity> {
    let unitActivity;
    if (!options || !options.unitActivity) {
      unitActivity = new UnitActivity();
      unitActivity.Owner = this.claims.read().login.SagaUserAuth.SiteId;
      let device;
      this.myEquipment.myDevice.subscribe(d => (device = d)).unsubscribe();
      if (device) {
        unitActivity.devices.push(device);
      }
    } else {
      unitActivity = options.unitActivity;
    }

    // TODO: @deprecated please use ModalFactoryService instead
    return await this.modalCtrl
      .create({
        component: editor,
        componentProps: Object.assign(
          {
            disableCancel: false,
            unitActivity,
            poling: true
          },
          options
        )
      })
      .then(ionModal => ionModal.present().then(() => ionModal.onDidDismiss().then(r => r.data)));
  }

  async cancelDispatch() {
    // tslint:disable-next-line:no-bitwise
    if (this.unitActivity.status.hasAction(StatusItemAction.isRequestAssignment)) {
      await this.sendResponse(
        'SagaMobile.AssignementRequest.Refuse',
        this.statusService.getStatusByAction(StatusItemAction.isCancelAction) as ListResourceStatus
      );
    }
  }

  private async sendResponse(text: string, status: ListResourceStatus) {
    await this.statusService.changeStatus(status, this.unitActivity.callcard, {}, text);
  }

  async acceptDispatch() {
    // tslint:disable-next-line:no-bitwise
    if (this.unitActivity.status.hasAction(StatusItemAction.isRequestAssignment)) {
      await this.sendResponse(
        'SagaMobile.AssignementRequest.Accept',
        this.statusService.getStatusByAction(StatusItemAction.isAssignement) as ListResourceStatus
      );
    }
  }

  setResourcePosition(resource: SagaObject, position?: Coordinate) {
    if (position) {
      return this.security.post('api/Location/ForVehicle', {
        resourceUid: resource.ObjGuid,
        coordX: position.x,
        coordY: position.y
      });
    }
  }

  public async getRessourceInformation(id: string): Promise<Array<CommunicationEquipment>> {
    try {
      const result = await this.security.post<any[]>('api/UnitActivity/GetRessourceInformation', { resourceUid: id });
      return this.objectFactory.createList(result, CommunicationEquipment) as Array<CommunicationEquipment>;
    } catch (ex) {
      console.log(ex);
    }
    return null;
  }

  async terminateUnitActivity(unit: UnitActivity) {
    try {
      return await this.security
        .post('api/UnitActivity/TerminatePatrolActivity', {
          unitActivityUid: unit.ObjGuid
        })
        .then((response: any) => {
          return response;
        });
    } catch (response) {
      return false;
    }
  }

  public getUnitActivities(filterStatus: number): Observable<UnitActivity[]> {
    return this.unitProxy
      .getUnitActivities(filterStatus)
      .pipe(map(result => result.map(unitActivitySetup => this.parseUnitActivitySetup(unitActivitySetup))));
  }

  public getAllUnitActivitiesCallSign(): Observable<string[]> {
    return this.unitProxy.getAllUnitActivitiesCallSign();
  }

  async createUnit(data: any) {
    return this.security.post<boolean>('api/UnitActivity/CreateUnitActivity', data);
  }

  async updateUnit(data: any) {
    return this.security.post<boolean>('api/UnitActivity/EditUnitActivity', data);
  }

  private parseUnitActivitySetup(unitActivitySetup: IUnitSetup) {
    const obj: UnitActivity = this.cache.objectFactory.clone(unitActivitySetup.unit, true);
    this.associationSecurity.setResources(obj, unitActivitySetup);
    this.cache.callChanged(obj);
    return obj;
  }
}
