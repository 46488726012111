import { Type } from '@angular/core';
import { ToggleTool } from '@techwan/map-tools';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelTool, PropertyChanged } from '../interfaces/panel-tool';

export enum PanelToolGroupNames {
  StatusToolGroup = 'STATUS_TOOL_GROUP',
  MapToolGroup = 'MAP_TOOL_GROUP'
}

export class PanelToolBase implements PanelTool {
  readonly name: string;
  readonly groupName: string;

  isVisible: boolean = true;
  hasViewSplitter: boolean = true;

  button: ToggleTool;
  content?: Type<any>;
  position: number;

  get isActive(): boolean {
    return this.button.isActive;
  }

  private readonly _change = new Subject<PropertyChanged>();
  get $changed(): Observable<PropertyChanged> {
    return this._change.asObservable();
  }

  get $click(): Observable<Event> {
    return this.button.$event.pipe(map(toolBase => null));
  }

  reset() {
    this.button.reset();
  }

  click(event: Event) {
    this.button.click(event);
  }
}
