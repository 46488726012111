import { Component, Input, OnInit } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { StampNameProviderService } from '../../services/stamp-name-provider/stamp-name-provider.service';
import { StampTextProviderService } from '../../services/stamp-text-provider/stamp-text-provider.service';

@Component({
  selector: 'app-journal-item',
  templateUrl: './journal-item.component.html',
  styleUrls: ['./journal-item.component.scss']
})
export class JournalItemComponent implements OnInit {
  @Input() item: SagaObject;
  @Input() isNew: boolean;

  stampIcon: string;
  stampName: string;
  stampText: string;

  isExpanded: boolean = false;

  constructor(private stampNameProvider: StampNameProviderService, private stampTextProvider: StampTextProviderService) {}

  ngOnInit(): void {
    this.stampIcon = this.item.icon;
    this.stampName = this.stampNameProvider.getStampName(this.item);
    this.stampText = this.stampTextProvider.getStampText(this.item);
  }

  onSelect() {
    this.isExpanded = !this.isExpanded;
  }
}
