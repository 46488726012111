import { Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { EventsProviderService } from '../../services/events-provider/events-provider.service';

@AddinView({
  name: 'EventListAccess'
})
@Component({
  selector: 'app-event-list-access',
  templateUrl: './event-list-access.component.html',
  styleUrls: ['./event-list-access.component.scss']
})
export class EventListAccessComponent implements OnInit {
  private readonly _list: MobEvents[] = [];
  public get list(): MobEvents[] {
    return this._list;
  }

  public expanded = false;

  constructor(private readonly _events: EventsProviderService) {}

  public ngOnInit() {
    this._events.whenReady().subscribe(r => {
      if (r === true) {
        this._events.getEvents().forEach(item => this._list.push(item));
      }
    });
  }
}
