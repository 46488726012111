import { Injectable } from '@angular/core';
import { AccessMenuTool } from '../../../schema/menu/access-menu-tool';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class MenuAccessItemService {
  protected _entry: AccessMenuTool = null;

  protected _sub: Subscription = null;

  constructor() {}

  abstract setup(entry: AccessMenuTool);

  protected cleanup() {
    if (this._entry !== null && this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }
}
