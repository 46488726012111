import { CallcardButtonTool, ICallcardButtonEvent } from '@addins/core/singlecallcard';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

export class InappItineraryEvent implements ICallcardButtonEvent {
  target: CallCard;
}

export class InappItineraryTool extends CallcardButtonTool<InappItineraryEvent> {
  constructor() {
    super('InAppItinerary', 'navigate-circle-outline', 300);
  }

  click(): void {
    this._onClick.next(new InappItineraryEvent());
  }
}
