import { ViewLoaderService } from '@addins/core/menus';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { LayoutService } from '@services/layout/layout.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';

@Component({
  selector: 'app-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.scss']
})
export class RowsComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('view') view: IonNav;

  topLayoutSplitterNeeded: boolean = false;
  topLayoutMaxHeight: number = 45;
  topLayoutMinHeight: number = 20;

  get mainLayout(): string[] {
    return this.layoutService.mainLayout();
  }

  constructor(
    private viewLoaderService: ViewLoaderService,
    private layoutService: LayoutService,
    private viewSelectorService: ViewSelectorService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.viewLoaderService.initialize(this.view, this.viewSelectorService.getView(this.layoutService.entryPage()).element);
  }

  setTopLayoutSplitterNeeded(isNeeded: boolean) {
    setTimeout(() => {
      this.topLayoutSplitterNeeded = isNeeded;
    });
  }

  ngOnDestroy() {
    this.viewLoaderService.reset();
  }
}
