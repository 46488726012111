import { AdditionalViewToolsService, PanelContent, PanelContentBaseComponent } from '@addins/core/panel';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LayersModalComponent } from '../layers-modal/layers-modal.component';

@Component({
  selector: 'app-layers-panel-content',
  templateUrl: './layers-panel-content.component.html',
  styleUrls: ['./layers-panel-content.component.scss']
})
export class LayersPanelContentComponent extends PanelContentBaseComponent implements OnInit, PanelContent {
  constructor(private modalController: ModalController, private additionalViewTools: AdditionalViewToolsService) {
    super();
  }

  ngOnInit() {
    this.modalController.create({ component: LayersModalComponent }).then(modal => {
      modal.present().then(() =>
        modal.onDidDismiss().then(result => {
          const layersTool = this.additionalViewTools.getTools().filter(tool => tool.isActive)[0];
          layersTool.click(null);
        })
      );
    });
  }
}
