<ion-header translucent>
  <ion-toolbar>
    <ion-title translate>Mobile.Layers</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()" translate>Mobile.Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-base-layer-select *ngIf="layers" [layers]="layers"></app-base-layer-select>
  <app-overlay-group-select *ngIf="layers" [layers]="layers.getOverlayLayers()"></app-overlay-group-select>
</ion-content>
