import { NgModule } from '@angular/core';
import { DistancePipe } from './distance.pipe';
import { DurationPipe } from './duration.pipe';
import { CalendarPipe, CalendarTimePipe, MomentPipe } from './moment';
import { SagaDatePipe } from './saga-date/saga-date.pipe';
import { TranslateKeysPipe } from './translate-keys/translate-keys.pipe';

@NgModule({
  declarations: [SagaDatePipe, MomentPipe, CalendarPipe, CalendarTimePipe, DistancePipe, DurationPipe, TranslateKeysPipe],
  exports: [SagaDatePipe, MomentPipe, CalendarPipe, CalendarTimePipe, DistancePipe, DurationPipe, TranslateKeysPipe]
})
export class SagaPipeModule {}
