import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushService } from './services/push/push.service';

const services = [PushService];

@NgModule({
  declarations: [],
  providers: services,
  imports: [CommonModule]
})
export class SagaPushModule {}
