import { ISettingProvider } from '@addins/core/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSetting } from '@models/imported/SagaBase/AppSetting';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImagesSettingsService } from '../images-settings/images-settings.service';

@Injectable()
export class SagaSettingsService implements ISettingProvider {
  private readonly _ready = new BehaviorSubject(false);
  get $ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  private readonly storageByKey = {};

  constructor(private readonly http: HttpClient, private readonly images: ImagesSettingsService) {}

  getValue(key: string): any {
    return this.storageByKey[key];
  }

  getAll(): any {
    return this.storageByKey;
  }

  setup(): Observable<void> {
    // Reset the ready state
    this._ready.next(false);

    // NOTE: Don't add subscribers to this map, because it will map for every subscriber, and reload all the values
    // Prepare and execute the requests
    return zip(this.getWebSettings(), this.fetch('SagaMobileWebClient', 'Global'), this.fetch('SagaClient', 'Global')).pipe(
      map(() => this._ready.next(true))
    );
  }

  private parse(raw: any): void {
    this.parseAppSettings(Object.keys(raw).map(key => new AppSetting(key, raw[key])));
  }

  private getWebSettings(): Observable<void> {
    return this.http.get<Observable<void>>('api/Settings/GetSagaWebSettings').pipe(map(raw => this.parse(raw)));
  }

  private parseAppSettings(settings: AppSetting[]): AppSetting[] {
    settings.forEach(s => {
      let val: any;

      if (s.Value !== undefined && s.Value != null) {
        switch (s.ValueType) {
          case 'Int32':
            val = parseInt(s.Value, 10);
            break;

          case 'Double':
            val = parseFloat(s.Value);
            break;

          case 'Boolean':
            val = s.Value.toLowerCase() === 'true';
            break;

          case 'Date':
            val = new Date(Date.parse(s.Value));
            break;

          case 'ListObj':
          case 'IconBTAF':
            try {
              val = JSON.parse(s.Value);
            } catch (e) {
              val = s.Value;
            }
            break;

          default:
            val = s.Value;
            if (s.Key.startsWith('SagaMobileWebClient.Images.')) {
              this.images.set(s.Key.substr('SagaMobileWebClient.Images.'.length), s.Value);
            }
        }
      }
      this.storageByKey[s.Key] = val;
    });
    return settings;
  }

  private fetch(applicationName: string, scopeName: string): Observable<AppSetting[]> {
    const params = new HttpParams().set('applicationName', applicationName).set('hostName', scopeName);
    return this.http
      .get<AppSetting[]>('api/SagaAppSettings/GetAppSettingsByAppAndHost', { params })
      .pipe(map(r => this.parseAppSettings(r)));
  }
}
