// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:39
export enum ADOperation {
  None = 0,
  AddUser = 1,
  RemoveUser = 2,
  ChangePassword = 4,
  UpdateUser = 8,
  UpdateGroups = 16
}
export enum PasswordError {
  None = 0,
  DbOperationError = 1,
  PasswordInvalid = 2,
  ADOperationError = 3,
  ADNewPasswordInvalid = 4,
  ADPasswordInvalid = 5,
  NoADPasswordCheck = 6
}
export enum PasswordOperation {
  CheckPassword = 0,
  ChangePassword = 1,
  ResetPassword = 2
}
export enum UserAccessError {
  None = 0,
  DbOperationError = 1,
  AssignRoleError = 2,
  DeassignRoleError = 3,
  ADOperationError = 4,
  ADNewPasswordInvalid = 5
}
export enum UserAccessOperation {
  CreateUser = 0,
  UpdateUser = 1,
  DeleteUser = 2,
  None = 3
}
export enum ResType {
  Unknown = 0,
  Vehicle = 1,
  ResourceTypeManager = 2,
  Actor = 3,
  Crew = 4
}
export enum LinkToParentCardResultEnum {
  Ok = 0,
  ChildIsAlreadyLinked = 1,
  ParentIsAlreadyChild = 2,
  ParentIsNotCardDispatch = 3,
  Error = 4
}
export enum TimerPriority {
  Undefined = 0,
  High = 1,
  Medium = 2,
  Low = 4
}
export enum CompareOperator {
  logicalAnd = 0,
  equal = 1
}
export enum InterfaceMsgStatus {
  Submited = 0,
  Ack = 1,
  NotAck = 2,
  Opened = 3,
  Closed = 4,
  Accepted = 5,
  Terminated = 6,
  NoAction = 7
}
export enum InterfaceMsgKind {
  Phone = 1,
  Infranet = 2,
  Radio = 4,
  Mail = 8,
  GPS = 16,
  Matrix = 32,
  RN = 64,
  Bat = 128,
  SOS = 512,
  TextPanel = 1024,
  Etv = 2048,
  SagaServer = 4096,
  Lights = 4102,
  SoundPhoneFile = 8192,
  Fax = 16384,
  TextFile = 32768,
  Image = 65536,
  Video = 131072,
  Office = 262144,
  TimelineMail = 524288,
  SoundRadioFile = 1048576,
  WebCam = 2097152,
  Radar = 4194304,
  ComDaemon = 8388608
}
export enum TraceStates {
  none = 0,
  isEnabled = 1,
  isTrace = 2,
  isCurrent = 3
}
export enum ScheduledOpStatus {
  Created = 1,
  Executing = 2,
  Executed = 3,
  Failed = 4,
  Cancelled = 5
}
export enum MapSymbolActionType {
  POI = 0,
  Video = 1,
  Document = 2
}
export enum AuditBehaviour {
  None = 0,
  Description = 1,
  Message = 2
}
export enum ScheduleRule {
  Once = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4
}
export enum ScheduleStatus {
  On = 0,
  Off = 1
}
export enum CrewResourceStatusBit {
  Internal = 1,
  Exemplar = 2,
  Programmed = 4,
  Confirmed = 8
}
export enum TimerAttribute {
  AcknowledgeOnOpen = 1,
  IsDialogType = 2,
  IsCallcard = 4,
  IsAlarmManagement = 8
}
export enum TimerItemStatus {
  Created = 0,
  Timeout = 1,
  Ack = 2,
  Disable = 3
}
export enum TimerItemType {
  Status = 1,
  User = 2,
  Broadcast = 4,
  MobResAvailabilities = 8,
  TimedResponse = 16,
  DeferredAssignment = 32
}
export enum ComSendResult {
  Ok = 0,
  NOk = 1,
  Timeout = 2
}
export enum ComProtocol {
  None = 0,
  Phone = 1,
  SMS = 2,
  SMTP = 4,
  PAGER = 8,
  SMS_SMTP = 16,
  FAX = 32,
  FAX_SMTP = 64,
  PAGER_NUMERIC = 128
}
export enum ComSimulationMode {
  None = 0,
  WCF = 1,
  Simulator = 2
}
export enum ScheduleType {
  Recurrent = 0,
  Exception = 1
}
export enum GpsCommands {
  GetPosition = 0,
  SetGpsParam = 1
}
export enum ActorPeriod {
  aucune = 0,
  poursuite = 2,
  urgent = 3,
  patrouille = 5
}
export enum TypeGetListMobilisationEnum {
  All = 0,
  WithoutResources = 1,
  OnlyResources = 2,
  OrganizationResources = 3,
  TeamResources = 4,
  OrganizationServices = 5,
  OneResource = 6
}
export enum ScheduleSourceEnum {
  MainSchedule = 0,
  ServiceResourceSchedule = 1
}
export enum GeoSectorCopyDirection {
  WorkingToAdmin = 1,
  AdminToWorking = 2
}
export enum VehiclePeriod {
  aucune = 0,
  poursuite = 1,
  urgent = 2,
  patrouille = 5
}
