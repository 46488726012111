import { IAppSetting } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { AppSettingProviderService } from '@services/settings';
import { Observable, Subject } from 'rxjs';
import { ICustomFilter } from '../../schema/interfaces/ICustomFilter';

@Injectable({
  providedIn: 'root'
})
export class PriorityFilterService implements ICustomFilter {
  name = 'Mobile.FilterPriority';
  view = 'listcallcards/CardListPriorityFilter';

  private prioritiesToFilter: IAppSetting<number[]> = null;

  private _onSave = new Subject<void>();
  get $onSave(): Observable<void> {
    return this._onSave.asObservable();
  }

  private boundFilter: (callcard: CallCard) => boolean;

  constructor(private appSettingProvider: AppSettingProviderService) {}

  setup() {
    this.boundFilter = this.filter.bind(this);
    this.appSettingProvider.getSetting('PrioritiesToFilter', [], true).subscribe(appSetting => {
      this.prioritiesToFilter = appSetting;
    });
  }

  initFilter(cards: List<CallCard>) {
    cards.addFilter(this.boundFilter);
  }

  removeFilter(cards: List<CallCard>) {
    cards.removeFilter(this.boundFilter);
  }

  private filter(callcard: CallCard): boolean {
    if (this.prioritiesToFilter.current.length) {
      return this.prioritiesToFilter.current.indexOf(callcard.event.Priority) !== -1;
    }
    return true;
  }

  save() {
    this._onSave.next();
  }

  hasActiveFilter(): boolean {
    return this.prioritiesToFilter.current.length > 0;
  }
}
