import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { ItineraryStepsComponent } from './components/itinerary-steps/itinerary-steps.component';
import { StartItineraryMenuService } from './services/executables/start-itinerary-menu/start-itinerary-menu.service';
import { ItineraryProviderService } from './services/itinerary-provider/itinerary-provider.service';
import { ItineraryProxyService } from './services/itinerary-proxy/itinerary-proxy.service';

const executes = [StartItineraryMenuService];

const services = [ItineraryProviderService, ItineraryProxyService];

const views = [];

const components = [ItineraryStepsComponent];

const declarations = [...components, ...views];

@Addin({
  name: 'InApp-Itinerary'
})
@NgModule({
  declarations,
  providers: [...services, ...executes],
  imports: [IonicModule, SagaPipeModule, CommonModule]
})
export class InAppItineraryModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executes);
  }
}
