import { Injectable } from '@angular/core';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import * as moment from 'moment';
import { StatusTimeService } from '../status-time/status-time.service';
import { StatusImageService } from '../status-images/status-image.service';

@Injectable()
export class StatusStateService {
  private _status: ListResourceStatus;

  constructor(private statusTimeService: StatusTimeService, private _images: StatusImageService) {}

  setStatus(item: ListResourceStatus) {
    this._status = item;
  }

  getIcon(): string {
    return this._images.get(this._status);
  }

  getColor(): string {
    return this._status.FColor;
  }

  getTime(): string {
    return this.statusTimeService.getTime(this._status);
  }

  hasIcons(): boolean {
    return !this._images.isEmpty();
  }

  setTime(): void {
    if (this._status) {
      this.statusTimeService.setTime(this._status, moment().toDate());
    }
  }
}
