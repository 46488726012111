import { Component } from '@angular/core';
import { CStampCard } from '@models/imported/SagaSchema/CStampCard';
import { FollowupTemplate } from '@addins/callcard/components/callCardDetail/callCardDetailFollowup/FollowupTemplate';

@Component({
  templateUrl: 'CStampCardFollowup.html'
})
export class CStampCardFollowupComponent extends FollowupTemplate {
  get stamp(): CStampCard {
    return this.data;
  }
}
