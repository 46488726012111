import { Component, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Component({
  selector: 'app-callcard-detail-card',
  templateUrl: './callcard-detail-card.component.html',
  styleUrls: ['./callcard-detail-card.component.scss']
})
export class CallcardDetailCardComponent implements OnInit, OnDestroy {
  @Input()
  callcard: CallCard;
  @Input()
  component: Type<any>;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private viewContainer: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  constructor() {}

  ngOnInit() {
    this.componentRef = this.viewContainer.createComponent(this.component);

    this.componentRef.instance.callcard = this.callcard;
  }

  ngOnDestroy() {
    this.componentRef.destroy();
  }
}
