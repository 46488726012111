import { CallcardButtonTool, ICallcardButtonEvent } from '@addins/core/singlecallcard';

export class ItineraryTool extends CallcardButtonTool<ICallcardButtonEvent> {
  constructor() {
    super('ExtItinerary', 'navigate-circle-outline', 400);
  }

  click() {
    this._onClick.next({ target: null });
  }
}
