// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ETDAddress_abstract extends SagaObject {
  static $t: string = 'SagaBase.ETDAddress';
  $t: string = 'SagaBase.ETDAddress';

  public static GetMembers(): string[] {
    return [
      'Normalizatied',
      'NormalizatiedCaller',
      'NormalizatiedCallerSerialized',
      'XmlData',
      'InterfaceMsgUid',
      'Appartment',
      'BusinessCategory',
      'Country',
      'FirstName',
      'FirstnameExtension',
      'Floor',
      'HouseDescription',
      'StreetNumber',
      'LastName',
      'LastNameExtension',
      'CityName',
      'CityNameExtension',
      'CityNameExtension2',
      'MaidenName',
      'MaidenNameExtension',
      'Phone',
      'POBox',
      'Profession',
      'ProfessionExtension',
      'State',
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'Complement',
      'TypeId',
      'GeoLocationSearchLabel',
      'LocationMiscData',
      'PlaceName',
      'Title',
      'ValidFrom',
      'ValidTo',
      'XCoord',
      'YCoord',
      'Zip',
      'PhonebookSource',
      'IsAddressConfidential'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ETDAddress_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ValidFrom = this.ValidFrom && new Date(this.ValidFrom);
    this.ValidTo = this.ValidTo && new Date(this.ValidTo);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Normalizatied: number = 0;
  public NormalizatiedCaller: any;
  public NormalizatiedCallerSerialized: string;
  public XmlData: string;
  public InterfaceMsgUid: Guid = GuidTools.Empty;
  public Appartment: string = '';
  public BusinessCategory: string = '';
  public Country: string = '';
  public FirstName: string = '';
  public FirstnameExtension: string = '';
  public Floor: string = '';
  public HouseDescription: string = '';
  public StreetNumber: string = '';
  public LastName: string = '';
  public LastNameExtension: string = '';
  public CityName: string = '';
  public CityNameExtension: string = '';
  public CityNameExtension2: string = '';
  public MaidenName: string = '';
  public MaidenNameExtension: string = '';
  public Phone: string = '';
  public POBox: string = '';
  public Profession: string = '';
  public ProfessionExtension: string = '';
  public State: string = '';
  public AliasName: string = '';
  public Area1Name: string = '';
  public Area2Name: string = '';
  public Area3Name: string = '';
  public Complement: string = '';
  public TypeId: number = -1;
  public GeoLocationSearchLabel: string = '';
  public LocationMiscData: string = '';
  public PlaceName: string = '';
  public Title: string = '';
  public ValidFrom: Date = new Date();
  public ValidTo: Date = new Date();
  public XCoord: number = -1.79769313486232e308;
  public YCoord: number = -1.79769313486232e308;
  public Zip: string = '';
  public PhonebookSource: SagaBase.EtdSource = SagaBase.EtdSource.None;
  public IsAddressConfidential: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.InterfaceMsgUid;
  }
  set ParentObjId(value: any) {
    this.InterfaceMsgUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
