import { PermanentEntry } from '@addins/core/menus';
import { Injectable } from '@angular/core';
import { LayoutService } from '@services/layout/layout.service';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { AccessMenuTool } from '../../../schema/menu/access-menu-tool';
import { FullViewMenuTool } from '../../../schema/menu/full-view-menu-tool';
import { MenuEntryControllerService } from '../../menu-entry-controller/menu-entry-controller.service';

@AddinExecute({
  name: 'Menu',
  description: "Provide some menu items to open CallCard's view"
})
@Injectable()
export class MenuService extends Execute {
  /**
   * The menu entry for display the list view
   */
  private readonly _menuEntry: MenuEntry;
  get menuEntry(): AccessMenuTool {
    return this._menuEntry as AccessMenuTool;
  }

  constructor(
    private sideMenuService: SideMenuService,
    private layoutService: LayoutService,
    private menuEntryController: MenuEntryControllerService
  ) {
    super();
    const bannerMode = this.layoutService.mainLayout().indexOf('listcallcards/BannerView') !== -1;
    this._menuEntry = bannerMode ? new AccessMenuTool() : new FullViewMenuTool();
    if (!bannerMode) {
      this.menuEntryController.setup(this._menuEntry as PermanentEntry);
    }
  }

  init(): void {}

  execute(): void {
    this.sideMenuService.addMenuEntry(this._menuEntry);
  }

  destroy(): void {
    this.sideMenuService.removeMenuEntry(this._menuEntry);
  }
}
