import { MapName } from '@addins/core/core';
import { MapProxyService } from '@addins/core/map/services/map-proxy/map-proxy.service';
import { AdditionalViewToolsService } from '@addins/core/panel';
import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { MapInstanceEvent, MapInstanceEventType, MapInstancesService } from '@services/map';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { DrawingPanelTool } from '../../../schema/drawing-panel-tool';

@AddinExecute({
  name: 'drawing-panel'
})
@Injectable()
export class PanelControllerService extends Execute {
  private _drawingPanelTool = new DrawingPanelTool();
  private _subs: Subscription[] = [];

  private _myCallCardId: string = GuidTools.Empty;

  constructor(
    private _mapInstancesService: MapInstancesService,
    private _additionalViewToolsService: AdditionalViewToolsService,
    private _myUnitService: MyUnitService,
    private _mapProxyService: MapProxyService
  ) {
    super();
  }

  execute(): void {
    // preload draw icons at boot. this code will be removed once the icons are loaded with the boot cache (#39052)
    this._myUnitService.$unitChanged
      .pipe(
        filter(unit => unit !== null),
        first()
      )
      .subscribe(() => this._mapProxyService.getIcons().subscribe());

    this._mapInstancesService.$change
      .pipe(filter(mapEvent => mapEvent.name === MapName.main))
      .subscribe(mapEvent => this.onMainMapChanged(mapEvent));
  }

  private onMainMapChanged(mapEvent: MapInstanceEvent) {
    if (mapEvent.type === MapInstanceEventType.removed) {
      this._additionalViewToolsService.removeTool(this._drawingPanelTool);
      this.unsubscribeFromChanges();
    } else if (mapEvent.type === MapInstanceEventType.added) {
      this._subs.push(
        this._myUnitService.$unitChanged
          .pipe(
            filter(unit => unit !== null),
            first()
          )
          .subscribe(() => {
            this._myCallCardId = this._myUnitService.mine.CallCardID;

            if (this._myUnitService.isEngaged()) {
              this._additionalViewToolsService.addTool(this._drawingPanelTool);
            }

            this.subscribeToUnitChange();
          })
      );
    }
  }

  private unsubscribeFromChanges() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private subscribeToUnitChange() {
    this._subs.push(
      this._myUnitService.mine.$changed.pipe(filter(() => this._myCallCardId !== this._myUnitService.mine.CallCardID)).subscribe(() => {
        const currentCallCardId: string = this._myUnitService.mine.CallCardID || GuidTools.Empty;

        if (GuidTools.isEmpty(this._myCallCardId) && GuidTools.isValid(currentCallCardId)) {
          this._additionalViewToolsService.addTool(this._drawingPanelTool);
        } else if (GuidTools.isValid(this._myCallCardId) && GuidTools.isEmpty(currentCallCardId)) {
          this._additionalViewToolsService.removeTool(this._drawingPanelTool);
        }

        this._myCallCardId = currentCallCardId;
      })
    );
  }
}
