import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomComponent, ModalButton } from '@addins/core/core';
import { ModalButtonImpl } from '@addins/core/core';
import { TerminatedMessagesService } from '../../services/terminated-messages/terminated-messages.service';
import { TerminateMessageResult } from '../../schema/interfaces/terminate-message-result';
import { IonTextarea } from '@ionic/angular';

@Component({
  selector: 'app-terminate-message-selection',
  templateUrl: './terminate-message-selection.component.html',
  styleUrls: ['./terminate-message-selection.component.scss']
})
export class TerminateMessageSelectionComponent implements CustomComponent<TerminateMessageResult>, OnInit {
  @ViewChild('textArea') textArea: IonTextarea;

  readonly saveButton: ModalButtonImpl;

  private _terminationMessages: string[] = null;
  get terminationMessages(): string[] {
    return this._terminationMessages;
  }

  private _terminationMessage: string = '';
  get terminationMessage() {
    return this._terminationMessage;
  }
  additionalText: string = '';

  get useCustomData(): boolean {
    return true;
  }

  constructor(private readonly _terminatedMessagesService: TerminatedMessagesService) {}

  ngOnInit() {
    this.saveButton.setHidden(true);
    this._terminatedMessagesService.getMessages().subscribe(messages => (this._terminationMessages = messages));
  }

  hasSelectedMessage(): boolean {
    return this._terminationMessage !== '';
  }

  onMessageSelected(message: string) {
    this._terminationMessage = message;
    this.saveButton.setHidden(false);

    this.textArea.setFocus();
  }

  getResult(button: ModalButton): TerminateMessageResult {
    return button.action === this.saveButton.action
      ? { selectedTerminationMessage: this._terminationMessage, customMessage: this.additionalText }
      : null;
  }
}
