<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-buttons slot="start">
            <ion-button (click)="close()" *ngIf="showClose"><ion-icon slot="icon-only"  name="close"></ion-icon></ion-button>
        </ion-buttons>
        <ion-title>
            <span translate>SagaMobile.UA.UnitActivityEditor</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="selectExisting()" *ngIf="!showClose"><ion-icon slot="icon-only"  name="add"></ion-icon></ion-button>
            <ion-button (click)="save()" *ngIf="hasChange && unitActivity.isValid()"><ion-icon slot="icon-only"  name="checkmark"></ion-icon></ion-button>
        </ion-buttons>
        <ion-buttons slot="end"><div status-bar></div></ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="primary">
        <ion-segment color="light" [(ngModel)]="view">
            <ion-segment-button value="equipments">
                <span translate>SagaMobile.UA.Equipments</span>
            </ion-segment-button>
            <ion-segment-button value="vehicles">
                <span translate>SagaMobile.UA.Vehicles</span>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <div [ngSwitch]="view">

        <div *ngSwitchCase="'equipments'">
            <ion-list>
                <ion-item *ngFor="let device of unitActivity.devices">
                    <ion-icon name="phone-portrait" slot="start"></ion-icon>
                    <ion-label><h2>{{device.Name}}</h2></ion-label>
                    <ion-button (click)="remove('devices', device)" *ngIf="device !== myDevice" fill="clear" slot="end" color="danger"><ion-icon slot="icon-only"  name="trash"></ion-icon></ion-button>
                </ion-item>
            </ion-list>
        </div>

        <div *ngSwitchCase="'vehicles'">
            <ion-list>
                <ion-item *ngFor="let vehicle  of unitActivity.vehicles">
                    <ion-icon name="car" slot="start" [style.color]="vehicle.status ? vehicle.status.color.tinycolor.toRgbString(): 'black'"></ion-icon>
                    <ion-label>
                        <h2>{{vehicle.VehicleCode}}</h2>
                        <p>{{vehicle.type && vehicle.type.Description}}</p>
                    </ion-label>
                    <ion-button (click)="remove('vehicles', vehicle)" fill="clear" slot="end" color="danger"><ion-icon slot="icon-only"  name="trash"></ion-icon></ion-button>
                </ion-item>
            </ion-list>
        </div>

    </div>
    <ion-fab slot="fixed" horizontal="end" vertical="bottom">
        <ion-fab-button color="primary" (click)="addItem()" [disabled]="view == 'vehicles' && unitActivity.vehicles.length >= 1" class="unit-activity-add"><ion-icon name="add"></ion-icon></ion-fab-button>
    </ion-fab>
</ion-content>
