import { NgModule } from '@angular/core';
import { IconDirective } from '@app/directives/icon/icon';
import { SharedModule } from '../../modules/shared/shared.module';
import { DarkModeDirective } from './dark-mode/dark-mode.directive';
import { LargeFontDirective } from './large-font/large-font.directive';
import { MaxWidthOnCountDirective } from './max-width-on-count/max-width-on-count.directive';
import { NavDirective } from './nav/nav';
import { ShowWhenDirective } from './show/show-when';
import { TranslateDirective } from './translate/translate';
import { TranslatePlaceholderDirective } from './translate/translatePlaceholder';

const directives = [
  NavDirective,
  TranslatePlaceholderDirective,
  TranslateDirective,
  ShowWhenDirective,
  IconDirective, // Icon Directive is used to integrate Material Design Icons
  MaxWidthOnCountDirective,
  DarkModeDirective,
  LargeFontDirective
];

@NgModule({
  imports: [SharedModule],
  declarations: directives,
  exports: [SharedModule, ...directives]
})
export class SagaDirectivesModule {}
