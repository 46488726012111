// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class User_abstract extends PersistentObject {
  static $t: string = 'SagaBase.User';
  $t: string = 'SagaBase.User';

  public static GetMembers(): string[] {
    return [
      'SecurityClearance',
      'ApplicationAccess',
      'Password',
      'ResResourceUid',
      'IsResourceLogin',
      'InvalidLoginCounter',
      'Language',
      'ExternalUid',
      'Owner',
      'AppProfile',
      'OwnerName',
      'FirstName',
      'LastName',
      'MailAddress',
      'Service',
      'UserName',
      'GlblUserUid',
      'PasswordExpiryUtc',
      'RefreshTokenData',
      'RefreshTokenKey',
      'NewPassword',
      'Login',
      'Initials',
      'ACNFlag',
      'SiteId',
      'AgencyId',
      'SessionGuid',
      'UserID',
      'ADLogin',
      'FieldGeneric1',
      'FieldGeneric2',
      'FieldGeneric3'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return User_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.PasswordExpiryUtc = this.PasswordExpiryUtc && new Date(this.PasswordExpiryUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SecurityClearance: SagaBase.SecurityClearances = SagaBase.SecurityClearances.None;
  public ApplicationAccess: number = 0;
  public Password: string;
  public ResResourceUid: Guid = GuidTools.Empty;
  public IsResourceLogin: boolean = false;
  public InvalidLoginCounter: number = 0;
  public Language: string = '';
  public ExternalUid: string = '';
  public Owner: number = 0;
  public AppProfile: string;
  public OwnerName: string;
  public FirstName: string = '';
  public LastName: string = '';
  public MailAddress: string;
  public Service: string;
  public UserName: string = ' - ';
  public GlblUserUid: Guid = GuidTools.Empty;
  public PasswordExpiryUtc: Date = new Date();
  public RefreshTokenData: string;
  public RefreshTokenKey: string;
  public NewPassword: string = '';
  public Login: string;
  public Initials: string;
  public ACNFlag: number = 0;
  public SiteId: number = -1;
  public AgencyId: number;
  public SessionGuid: Guid = GuidTools.Empty;
  public UserID: number;
  public ADLogin: boolean = false;
  public FieldGeneric1: string;
  public FieldGeneric2: string;
  public FieldGeneric3: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.UserID;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
