import { ILocation } from '@models/interfaces/ILocation';
import { TranslateService } from '@ngx-translate/core';
import { xml2json } from '@techwan/mapping';
import { ILocationFormatter } from '../ILocationFormatter';

const ATTRIBUTES_NAME = 'attributes';
const KILOMETER_POINT = 'PK';

const FIELDS_TO_SHOW: string[] = [
  'Area3Name',
  'AliasName',
  'PlaceName',
  'StreetNumber',
  'ETC',
  'ST_TYPE',
  'REFHIGHWAYS_SNAME',
  'REFTRACKS_SNAME',
  'REFNODES_SNAME',
  'SFULLTEXT',
  KILOMETER_POINT
];
const DIGICODE_ATTRIBUTE_NAME = 'Digicode';

export class DefaultLocationFormatter implements ILocationFormatter {
  protected miscellaneousData: any;

  constructor(protected translate: TranslateService) {}

  format(location: ILocation): string[] {
    this.miscellaneousData = this.parseMiscData(location.LocationMiscData);

    const digiCode: string = this.getDigiCode(location);
    const renderedFields: string = this.renderFields(FIELDS_TO_SHOW, location);
    const formattedLocation: string = [renderedFields, digiCode].filter(s => s).join(', ');

    return formattedLocation ? [formattedLocation] : [];
  }

  protected parseMiscData(miscData: string): any {
    let jsonMiscData = {};

    if (miscData) {
      try {
        jsonMiscData = JSON.parse(this.isXml(miscData) ? this.xml2JsonString(miscData) : miscData);
      } catch (e) {
        console.error(e);
      }
    }

    return jsonMiscData;
  }

  private isXml(miscData: string) {
    return miscData && miscData.startsWith('<');
  }

  private xml2JsonString(xml: string): string {
    const xmlDoc: Document = new DOMParser().parseFromString(xml, 'text/xml');
    return xml2json(xmlDoc, '  ');
  }

  protected getDigiCode(location: ILocation): string {
    const value = this.getFieldValue(location, DIGICODE_ATTRIBUTE_NAME);
    if (value) {
      return `${this.translate.instant('Mobile.DigiCode')}: ${value}`;
    }
    return null;
  }

  private renderFields(fields: string[], location: ILocation): string {
    let text = '';

    fields.forEach(fieldName => {
      const fieldValue = this.getFieldValue(location, fieldName) || null;
      if (fieldValue !== null) {
        text +=
          fieldName === KILOMETER_POINT
            ? this.kilometerPointFormatter(fieldValue, text === '' ? '' : ', ')
            : this.addComma(fieldValue, text === '' ? '' : ', ');
      }
    });

    return text;
  }

  protected getFieldValue(location: ILocation, fieldName: string): any {
    return location[fieldName] || this.getAttributeValue(fieldName);
  }

  protected getAttributeValue(fieldName: string): any {
    return this.miscellaneousData[ATTRIBUTES_NAME] && this.miscellaneousData[ATTRIBUTES_NAME][fieldName];
  }

  private addComma(field, prefix) {
    return 'undefined' !== typeof field ? (field === 0 ? '' : prefix + field) : '';
  }

  private kilometerPointFormatter(kilometerPoint: number, separator: string) {
    return !kilometerPoint ? '' : `${separator}${this.translate.instant('Mobile.PK')}${this.getFormattedKilometerPoint(kilometerPoint)}`;
  }

  private getFormattedKilometerPoint(kilometerPoint: number) {
    const value = kilometerPoint >= 1000 ? kilometerPoint / 1000 : kilometerPoint;
    return `${value
      .toFixed(1)
      .toString()
      .replace('.', ',')} km`;
  }
}
