import { CurrentCardService, MyCardService } from '@addins/core/core';
import { MenuState } from '@addins/core/menus';
import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { LAYOUT_VIEW_NAME } from '../../schema/constants';
import { CardsProviderService } from '../../services/cards-provider/cards-provider.service';
import { CallcardMenuSetupService } from '../../services/executables/callcard-menu-setup/callcard-menu-setup.service';
import { CallcardOpenerService } from '../../services/executables/callcard-opener/callcard-opener.service';

@AddinView({
  name: LAYOUT_VIEW_NAME
})
@Component({
  selector: 'app-layout-view',
  templateUrl: './layout-view.component.html',
  styleUrls: ['./layout-view.component.scss'],
  providers: [CallcardMenuSetupService]
})
export class LayoutViewComponent implements OnInit, OnDestroy {
  private readonly subs: Subscription[] = [];

  get myCardId(): string {
    return this.myCardService.myCardId;
  }

  get myCard(): CallCard {
    return this.currentCard.current || this.myCardService.myCard;
  }

  get customComponents(): Type<any>[] {
    return this.customItems.cards;
  }

  constructor(
    private myCardService: MyCardService,
    private cache: CacheService,
    private callcardMenu: CallcardMenuSetupService,
    private currentCard: CurrentCardService,
    private customItems: CardsProviderService,
    private callcardOpener: CallcardOpenerService
  ) {}

  ngOnInit() {
    // Tells the card opener not to operate on a card change.
    this.callcardOpener.destroy();

    this.subs.splice(
      0,
      0,
      this.cache.state.subscribe(e => this.onCacheEvent(e))
    );
  }

  ngOnDestroy() {
    while (this.subs.length) {
      this.subs.pop().unsubscribe();
    }
  }

  show(): boolean {
    return this.currentCard.current
      ? true
      : this.callcardMenu.menuEntry.isActive
      ? (this.myCard && this.myCard instanceof CallCard) || false
      : false;
  }

  private onCacheEvent(cacheState: CacheState) {
    if (cacheState === CacheState.ready && this.subs.length < 2) {
      this.subs.push(this.currentCard.$change.subscribe(callcard => this.onCurrentCardChanged(callcard)));
    } else if (cacheState === CacheState.idle && this.subs.length > 1) {
      this.subs.pop().unsubscribe();
    }
  }

  private onCurrentCardChanged(selected?: CallCard): void {
    if (this.myCardService.myCard !== null) {
      this.callcardMenu.menuEntry.setState(this.myCardService.myCard === selected ? MenuState.active : MenuState.inactive);
    }
  }
}
