// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class GenericLog_abstract extends PersistentObject {
  static $t: string = 'SagaBase.GenericLog';
  $t: string = 'SagaBase.GenericLog';

  public static GetMembers(): string[] {
    return [
      'OperationGuid',
      'CreationUtc',
      'CreationUser',
      'Source',
      'SubSource',
      'Identifier',
      'SubIdentifier',
      'State',
      'DetailStr'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return GenericLog_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.CreationUtc = this.CreationUtc && new Date(this.CreationUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public OperationGuid: Guid = GuidTools.Empty;
  public CreationUtc: Date = new Date();
  public CreationUser: string;
  public Source: string;
  public SubSource: string;
  public Identifier: string;
  public SubIdentifier: string;
  public State: string;
  public DetailStr: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
