import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

export enum ToastColor {
  Info = '',
  Error = 'danger',
  Success = 'success',
  Update = 'warning'
}

export enum ToastDuration {
  Short = 2500,
  Long = 5000
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController, private translate: TranslateService) {}

  show(message: string, color: ToastColor, messageParams?: Object, duration: number = ToastDuration.Short): Promise<void> {
    const toastOptions: ToastOptions = {
      color: color,
      duration: duration,
      message: this.translate.instant(message, messageParams)
    };

    return this.toastController.create(toastOptions).then(toast => toast.present());
  }
}
