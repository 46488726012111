import { Injectable } from '@angular/core';
import { ILocation } from '@models/interfaces/ILocation';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapShowAddressService {
  private _event = new BehaviorSubject<ILocation>(null);
  get $event(): Observable<ILocation> {
    return this._event;
  }

  get currentAddress(): ILocation {
    return this._event.value;
  }

  constructor() {}

  showAddress(address: ILocation) {
    this._event.next(address);
  }

  clear() {
    this._event.next(null);
  }
}
