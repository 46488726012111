import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { PermanentMenuItemComponent } from './components/permanent-menu-item/permanent-menu-item.component';
import { PermanentMenuComponent } from './components/permanent-menu/permanent-menu.component';
import { PermanentMenuService } from './services/permanent-menu/permanent-menu.service';
import { SideMenuControllerService } from './services/side-menu-controller/side-menu-controller.service';
import { ViewLoaderService } from './services/view-loader/view-loader.service';

const views = [];

const executables = [];

const providers = [PermanentMenuService, SideMenuControllerService, ViewLoaderService, ...executables];

@Addin({
  name: 'CoreMenu'
})
@NgModule({
  declarations: [PermanentMenuComponent, PermanentMenuItemComponent],
  providers,
  imports: [CommonModule, IonicModule],
  exports: [PermanentMenuComponent, PermanentMenuItemComponent]
})
export class MenusModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
