import { Injectable } from '@angular/core';
import { Guid } from '@models/guid';
import { SagaSettingsService } from '@services/settings';
import { filter, first } from 'rxjs/operators';

const ACTION_DYNAMIC_FORMS: string = 'action=dynamic-forms';

@Injectable({
  providedIn: 'root'
})
export class SagaFormsUrlProviderService {
  private _formsBaseUrl: string;
  get formsBaseUrl(): string {
    return this._formsBaseUrl;
  }

  constructor(private sagaSettings: SagaSettingsService) {
    this.sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this._formsBaseUrl = this.sagaSettings.getValue('Forms.BaseUrl');
      });
  }

  getFormUrl(formValueUid: Guid): string {
    return `${this._formsBaseUrl}?${ACTION_DYNAMIC_FORMS}&formValueUid=${formValueUid}`;
  }

  getLinkedFormUrl(formTemplateUid: Guid, callCardUid: Guid): string {
    return `${this._formsBaseUrl}?${ACTION_DYNAMIC_FORMS}&formTemplateUid=${formTemplateUid}&callCardUid=${callCardUid}`;
  }
}
