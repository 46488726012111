import { CurrentCardService, MyCardService } from '@addins/core/core';
import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@services/cache/cache.service';
import { filter } from 'rxjs/operators';
import { EngagedPeopleCardButton } from '../../schema/engaged-people-card-button';
import { ResourceCountService } from '../resource-count/resource-count.service';

@Injectable({
  providedIn: 'root'
})
export class EngagedPeopleCardButtonService {
  private engagedPeopleCardButton: EngagedPeopleCardButton;

  constructor(
    private buttonProvider: ButtonsProviderService,
    private currentCardService: CurrentCardService,
    private myCardService: MyCardService,
    private cacheService: CacheService,
    private resourceCountService: ResourceCountService,
    private translate: TranslateService
  ) {}

  setup() {
    this.engagedPeopleCardButton = new EngagedPeopleCardButton();
    this.engagedPeopleCardButton.onClick.subscribe(() => this.onEngagedPeopleButtonClicked());
    this.buttonProvider.add(this.engagedPeopleCardButton);

    this.currentCardService.$change.subscribe(callcard => this.onCardChanged());
    this.myCardService.$change.subscribe(callcard => this.onCardChanged());
    this.cacheService
      .listenForChange(CStampProcedure)
      .pipe(filter(cacheEvent => (cacheEvent.object as CStampProcedure).CallCardId === this.myCardService.myCardId))
      .subscribe(() => this.onCardChanged());
  }

  private onEngagedPeopleButtonClicked() {
    this.resourceCountService.updateEngagedPeople();
  }

  private onCardChanged() {
    const currentCard: CallCard = this.currentCardService.current;
    const showButton = currentCard !== null;

    this.engagedPeopleCardButton.show = showButton;

    if (showButton) {
      if (currentCard.ObjGuid === this.myCardService.myCardId) {
        this.onEngagedPeopleChanged(currentCard);
      } else {
        this.engagedPeopleCardButton.show = false;
      }
    }
  }

  private onEngagedPeopleChanged(callcard: CallCard) {
    const engagedPeopleCount: number = this.resourceCountService.getEngagedPeople(callcard);
    const titleTranslated = this.translate.instant(this.engagedPeopleCardButton.ENGAGED_PEOPLE_TITLE);
    this.engagedPeopleCardButton.text = `${titleTranslated} (${engagedPeopleCount})`;
  }
}
