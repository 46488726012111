// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { coordToPosition, getStaticMapUrl } from '@services/map/helpers';
import { StaticMapOption } from '@services/map/schema/interfaces/static-map-options';
import { CCaller } from '../SagaSchema/CCaller';
import { CLocation_abstract } from './../abstract/SagaSchema/CLocation_abstract';

export class CLocation extends CLocation_abstract {
  get lat(): number {
    return this.coordToPosition()[0];
  }

  get lon(): number {
    return this.coordToPosition()[1];
  }

  copyFromCaller(caller: CCaller) {
    this.coordinate = caller.coordinate;
    for (let key in caller) {
      if (caller.hasOwnProperty(key) && this.hasOwnProperty(key) && ['$t', 'ObjGuid', 'Complement'].indexOf(key) === -1) {
        this[key] = caller[key];
      }
    }
  }

  coordToPosition(): ol.Coordinate {
    return coordToPosition(this.coordinate);
  }

  public getStaticMapUrl(options: StaticMapOption): string {
    return getStaticMapUrl(this.coordinate, options);
  }

  public getOLCoordinate(): ol.Coordinate {
    return this.coordinate.x ? [this.coordinate.x, this.coordinate.y] : null;
  }
}
