// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class EngagementResourceType_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.EngagementResourceType';
  $t: string = 'SagaSchema.EngagementResourceType';

  public static GetMembers(): string[] {
    return [
      'EngagementTypeUid',
      'EngagementGuid',
      'EngagementCategoryConst',
      'TypeId',
      'Count',
      'Extra',
      'SortOrder',
      'IsSubordinate'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return EngagementResourceType_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public EngagementTypeUid: Guid = GuidTools.Empty;
  public EngagementGuid: Guid = GuidTools.Empty;
  public EngagementCategoryConst: SagaBase.EngagementCategory;
  public TypeId: number = 0;
  public Count: number = 0;
  public Extra: number = 0;
  public SortOrder: number = 0;
  public IsSubordinate: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.EngagementTypeUid;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
