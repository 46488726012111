// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ResourceInfoMessageArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.ResourceInfoMessageArgs';
  $t: string = 'SagaBase.ResourceInfoMessageArgs';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'InfoType',
      'ResourceId',
      'Text',
      'ElementName',
      'Status',
      'OwnerId',
      'OwnerName',
      'UserId',
      'UserName',
      'Create',
      'InfoId'
    ];
  }

  public GetMembers(): string[] {
    return ResourceInfoMessageArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public InfoType: number = 0;
  public ResourceId: Guid = GuidTools.Empty;
  public Text: string;
  public ElementName: string;
  public Status: number = 0;
  public OwnerId: number = 0;
  public OwnerName: string;
  public UserId: number = 0;
  public UserName: string;
  public Create: boolean = false;
  public InfoId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
