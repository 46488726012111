// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ScheduledOp_abstract extends SagaObject {
  static $t: string = 'SagaBase.ScheduledOp';
  $t: string = 'SagaBase.ScheduledOp';

  public static GetMembers(): string[] {
    return ['ExecutingId', 'ExecutingType', 'Parameters', 'DueDateTimeUtc', 'Persistent', 'Status'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ScheduledOp_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.DueDateTimeUtc = this.DueDateTimeUtc && new Date(this.DueDateTimeUtc);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ExecutingId: Guid = GuidTools.Empty;
  public ExecutingType: string;
  public Parameters: string;
  public DueDateTimeUtc: Date = new Date();
  public Persistent: boolean = true;
  public Status: SagaBase.Defines.ScheduledOpStatus;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
