import { Injectable, Type } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { CacheService } from '@services/cache/cache.service';
import { Feature, ObjectFeature } from '@techwan/mapping';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IPopupFeature } from '../../schema/interfaces/IPopupFeature';

@Injectable()
export class PopupProviderService {
  private _data = new Map<string, Type<any>>();

  private _content: IPopupFeature[] = [];
  get content(): IPopupFeature[] {
    return this._content;
  }

  private _enabled = new BehaviorSubject(true);
  get enabled(): Observable<boolean> {
    return this._enabled.asObservable();
  }

  private _change = new Subject<CDeploymentPoint>();
  get $change(): Observable<CDeploymentPoint> {
    return this._change.asObservable();
  }

  private _close = new Subject<void>();
  get $close(): Observable<void> {
    return this._close.asObservable();
  }

  constructor(private _cache: CacheService) {}

  addComponent(type: typeof SagaObject, component: Type<any>) {
    this._data.set(type.$t, component);
  }

  close(): void {
    this._content.length = 0;
    this._close.next();
  }

  disable(): void {
    this._enabled.next(false);
  }

  enable(): void {
    this._enabled.next(true);
  }

  getComponents(features: IPopupFeature[]): Type<any>[] {
    return features
      .map(feature => this.getObjectFromFeature(feature.feature))
      .filter(object => object)
      .map(object => this._data.get(object.$t))
      .filter(object => object);
  }

  refresh(): void {
    this._change.next();
  }

  setContent(popupFeatures: IPopupFeature[]) {
    this._content.length = 0;
    popupFeatures.forEach(popupFeature => this._content.push(popupFeature));
  }

  private getObjectFromFeature(feature: Feature) {
    if (feature.getType() === 'ObjectFeature' && (feature as ObjectFeature).data_.hasOwnProperty('object')) {
      return (feature as ObjectFeature).data_.object;
    } else {
      return this._cache.getObjectByUid(feature.get('id'));
    }
  }
}
