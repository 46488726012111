import { Injectable } from '@angular/core';
import { Area } from '@models/imported/SagaBase/Area';
import { Zone } from '@models/imported/SagaBase/Zone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { SectorProxyService } from './sector-proxy.service';

@Injectable()
export class SectorService {
  private _mySector = null;
  private readonly _myParents = [];
  private readonly parentZones = new Map<number, Zone>();
  private userAreaZone: Area = null;

  get mySector(): number {
    if (this._mySector === null) {
      this._mySector = this.proxy.getSiteId();
    }
    return this._mySector;
  }

  get myParents(): number[] {
    return this._myParents;
  }

  get myZone(): Zone {
    return this.parentZones.get(this.mySector);
  }

  constructor(private readonly proxy: SectorProxyService, private objectFactory: ObjectFactory) {}

  setup(): Promise<boolean> {
    return this.loadAllParentSectors().toPromise();
  }

  async getUserAreaZone(): Promise<Area> {
    if (this.userAreaZone == null) {
      this.userAreaZone = await this.getAreaBySectorId().toPromise();
    }
    return this.userAreaZone;
  }

  async copySectorOfUser(address) {
    const area = await this.getUserAreaZone();
    address.Normalized = 3;
    address.GeoSectorName = area.ZoneName;
    address.GeoSectorId = area.ZoneId;
  }

  isSectorInParentZones(siteId: number): boolean {
    return this.parentZones.has(siteId);
  }

  protected getAreaBySectorId(): Observable<Area> {
    return this.proxy.getAreaBySectorId().pipe(map(area => (area !== null ? this.objectFactory.create(area) : null)));
  }

  protected loadAllParentSectors(): Observable<boolean> {
    return this.proxy.getAllParents().pipe(map((zones: Zone[]) => this.parse(zones)));
  }

  private parse(zones: Zone[]): boolean {
    this.parentZones.clear();
    this._myParents.length = 0;
    zones.map(zone => this.objectFactory.create(zone)).forEach((zone: Zone) => this.parentZones.set(zone.Id, zone));
    this.parentZones.forEach((zone, siteId) => this._myParents.push(siteId));
    return true;
  }
}
