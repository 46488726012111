import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@techwan/ionic-core';

@Pipe({ name: 'moment', pure: false })
export class MomentPipe implements PipeTransform {
  constructor(private readonly momentService: MomentService) {}

  transform(date: Date, format: string): string {
    return this.momentService.formatDate(date, format);
  }
}

@Pipe({ name: 'calendar', pure: false })
export class CalendarPipe implements PipeTransform {
  constructor(private readonly momentService: MomentService) {}

  transform(date: Date): string {
    return this.momentService.calendar(date).split(' ')[0];
  }
}

@Pipe({ name: 'calendarTime', pure: false })
export class CalendarTimePipe implements PipeTransform {
  constructor(private readonly momentService: MomentService) {}

  transform(date: Date): string {
    return this.momentService.calendar(date);
  }
}
