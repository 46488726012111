import { Component, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { KeywordSearchObject } from '@models/imported/SagaBase/Objects/KeywordSearchObject';
import { FilteredListBase } from '@schema/filtered-list-base';
import { ModalService } from '@services/modal/modal.service';
import { from, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { LocationProxyService } from '../../services/location-proxy/location-proxy.service';

@Component({
  selector: 'app-search-address-modal',
  templateUrl: './search-address-modal.component.html',
  styleUrls: ['./search-address-modal.component.scss']
})
export class SearchAddressModalComponent extends FilteredListBase<KeywordSearchObject> {
  @ViewChild('searchbar') searchbar: IonSearchbar;

  private readonly SEARCHED_CHARACTERS_THRESHOLD: number = 5;
  private readonly SEARCH_DELAY: number = 1000;
  private readonly SEARCH_LIMIT: string = String(200);

  constructor(private modalController: ModalController, private modalService: ModalService, private locationProxy: LocationProxyService) {
    super();
    this.searchedCharactersThreshold = this.SEARCHED_CHARACTERS_THRESHOLD;
    this.searchDelay = this.SEARCH_DELAY;
  }

  ionViewDidEnter() {
    this.searchbar.setFocus();
  }

  onDismiss(result: KeywordSearchObject = null) {
    this.clearPendingSearch();
    this.modalController.dismiss(result);
  }

  onSearch(query: string) {
    super.search(query);
  }

  onSelect(address: KeywordSearchObject) {
    this.onDismiss(address);
  }

  protected getItems(query: string, offset: number = 0): Observable<KeywordSearchObject[]> {
    return from(this.modalService.presentLoading()).pipe(
      switchMap(() => this.locationProxy.searchLocations(query, this.SEARCH_LIMIT)),
      finalize(() => this.modalService.dismissLoading())
    );
  }
}
