import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICustomFilter } from '../../schema/interfaces/ICustomFilter';
import { CallCardListFiltersService } from '../../services/callcard-list-filters/callcard-list-filters.service';

@Component({
  selector: 'app-callcard-list-custom-filters',
  templateUrl: './callcard-list-custom-filters.component.html',
  styleUrls: ['./callcard-list-custom-filters.component.scss']
})
export class CallcardListCustomFiltersComponent implements OnInit {
  customFilters: ICustomFilter[];
  openedAccordions: string[];

  constructor(private callcardsCustomFilters: CallCardListFiltersService, private modalController: ModalController) {}

  ngOnInit() {
    this.customFilters = this.callcardsCustomFilters.filters;
    if (this.customFilters.length > 0) {
      this.openedAccordions = [this.customFilters[0].name];
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onSubmit() {
    this.customFilters.forEach(customFilter => customFilter.save());
    this.modalController.dismiss(true);
  }
}
