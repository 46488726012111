import { CurrentCardService } from '@addins/core/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CardParticularsWrapper } from '../../schema/card-particulars-wrapper';

@Component({
  template: ''
})
export abstract class CallcardDetailParticularsComponent<T extends SagaObject> implements OnInit, OnDestroy {
  @Input()
  public callcard: CallCard;

  protected readonly _cacheSubscriptions: Subscription[] = [];

  public constructor(protected readonly _currentCardService: CurrentCardService, protected readonly _cacheService: CacheService) {}

  public ngOnDestroy() {
    while (this._cacheSubscriptions.length) {
      this._cacheSubscriptions.pop().unsubscribe();
    }
  }

  public ngOnInit() {
    this.callcard = this.callcard || this._currentCardService.current;
  }

  public toggleExtended(wrappedObject: CardParticularsWrapper<any>) {
    wrappedObject.extended = !wrappedObject.extended;
  }

  protected abstract onAdd(particular: T);
  protected abstract onRemove(particular: T);

  protected createWrappersOf(componentClass: Function): CardParticularsWrapper<T>[] {
    return this.callcard.component<T>(componentClass).map((component: T) => new CardParticularsWrapper(component));
  }

  protected getFilteredCacheSubscriptionForClassName(className: string): Subscription {
    return this._cacheService.$events
      .pipe(filter(cacheEvent => this.filterCacheEvent(cacheEvent, className)))
      .subscribe(cacheEvent => this.onCacheEvent(cacheEvent));
  }

  protected storeSubscription(subscription: Subscription) {
    this._cacheSubscriptions.push(subscription);
  }

  private filterCacheEvent(cacheEvent: CacheEvent, className: string): boolean {
    return (
      cacheEvent.object.$t === className &&
      (cacheEvent.action === CacheAction.added || cacheEvent.action === CacheAction.removed) &&
      this.isForCurrentCard(cacheEvent)
    );
  }
  private isForCurrentCard(cacheEvent: CacheEvent) {
    const currentCardId: string = this._currentCardService.current.ObjGuid;
    const changedCardId: string = (cacheEvent.object as any).CallCardId;
    return changedCardId && changedCardId === currentCardId;
  }

  private onCacheEvent(cacheEvent: CacheEvent) {
    if (cacheEvent.action === CacheAction.removed) {
      this.onRemove(cacheEvent.object as T);
    } else if (cacheEvent.action === CacheAction.added) {
      this.onAdd(cacheEvent.object as T);
    }
  }
}
