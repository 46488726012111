import { Injectable } from '@angular/core';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { TokenProvider } from '@addins/core/core';
import { Observable } from 'rxjs';

@Injectable()
export class TokenProviderService implements TokenProvider {
  public get $change(): Observable<string> {
    return this._refreshTokenStorage.$value;
  }

  constructor(private readonly _refreshTokenStorage: RefreshTokenStorageService) {}
}
