import { CollapsibleItemsService } from '@addins/core/singlecallcard';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Injectable } from '@angular/core';
import { UnitListCollapsibleItem } from '../../../schema/unit-list-collapsible-item';
import { CurrentCardService } from '@addins/core/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitListFetchingService } from '../../unit-list-fetching/unit-list-fetching.service';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { isValid } from '@models/guid';
import { CachedList } from '@addins/core/unit-activity/schema/cached-list';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { Subscription } from 'rxjs';

@AddinExecute({
  name: 'UnitCollapsible',
  description: 'Provides unit activity data to singlecallcard collapsible'
})
@Injectable()
export class UnitCollapsibleService extends Execute {
  private readonly _unitListCollapsibleItem = new UnitListCollapsibleItem();

  private _filteredUnitList: CachedList<UnitActivity> = null;

  private _filteredUnitListener: Subscription;

  constructor(
    private readonly itemsService: CollapsibleItemsService,
    private readonly _unitListFetchingService: UnitListFetchingService,
    private readonly _currentCardService: CurrentCardService,
    private readonly _cacheService: CacheService
  ) {
    super();
  }

  execute(): void {
    this.itemsService.register(this._unitListCollapsibleItem);
    this._cacheService.state.subscribe((cacheState: CacheState) => this.onCacheStateChange(cacheState));
    this._currentCardService.$change.subscribe((callCard: CallCard) => this.onCardChange(callCard));
  }

  private onCacheStateChange(cacheState: CacheState) {
    if (cacheState !== CacheState.ready && this._filteredUnitList !== null) {
      this._filteredUnitList.clear();
      this._filteredUnitListener.unsubscribe();
      this._filteredUnitList = null;
    }
  }

  private onCardChange(callCard: CallCard) {
    if (this._filteredUnitList !== null) {
      this._filteredUnitList.clear();
      this._filteredUnitListener.unsubscribe();
    }
    if (callCard !== null) {
      this._filteredUnitList = this.getUnitActivityCachedList();
      this._filteredUnitListener = this._filteredUnitList.$refresh.subscribe(() =>
        this.onRefreshUnitCollapsibleItem(this._currentCardService.current)
      );
      this.onRefreshUnitCollapsibleItem(callCard);
    }
  }

  private onRefreshUnitCollapsibleItem(callCard: CallCard) {
    this._unitListCollapsibleItem.setVisible(this.shouldShowContent(callCard));
  }

  private shouldShowContent(callCard: CallCard): boolean {
    return callCard !== null ? this._filteredUnitList.items.length > 0 : false;
  }

  private getUnitActivityCachedList() {
    return this._unitListFetchingService.createFilteredUnitActivitiesFromCache(unitActivity => this.isForMyCard(unitActivity));
  }

  private isForMyCard(unitActivity: UnitActivity): boolean {
    return (
      isValid(unitActivity.CallCardID) &&
      this._currentCardService.current &&
      unitActivity.CallCardID === this._currentCardService.current.ObjGuid
    );
  }
}
