import { Injectable } from '@angular/core';
import { StampManagerType } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CFormLink } from '@models/imported/SagaSchema/CFormLink';
import { CStampCard } from '@models/imported/SagaSchema/CStampCard';
import { CStampResource } from '@models/imported/SagaSchema/CStampResource';
import { ListCardStatus } from '@models/imported/SagaStateLib/ListCardStatus';
import { CacheAction } from '@services/cache/cacheEvent';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { IModelService, IObjectChange, ModelService } from '@services/model-factory';

@ModelService(CallCard)
@Injectable()
export class CallcardModelService implements IModelService<CallCard> {
  constructor(private _fetchListService: FetchListService) {}

  public change(change: IObjectChange<CallCard>) {
    const object = change.target;
    if (change.data && !change.data.stamps && change.action === CacheAction.modified) {
      object.hasCacheChanged = true;
    }

    if (object.component(CStampCard).length > 0) {
      // Status
      const stamps = object.component<CStampCard>(CStampCard).filter(stamp => stamp.StampType === StampManagerType.Status);
      stamps.sort((a, b) => b.CreatedUTC.getTime() - a.CreatedUTC.getTime());
      if (stamps.length) {
        // Status
        const stamp = stamps[0];
        object.status = this._fetchListService.getRow(ListCardStatus, stamp.Status);
      }
    }

    if (!object.status) {
      object.status = this._fetchListService.getRow(ListCardStatus, 1);
    }

    if (!object.lastStampsLoading) {
      object.stamps.source = object.component<CStampCard>(CStampCard).concat(object.component<CStampCard>(CStampResource));
    }

    object.callers = object.component<any>('SagaSchema.CCaller').concat([]);
    object.callers.sort((a, b) => b.LineOwner - a.LineOwner);

    object.firstCaller = object.callers.find(caller => caller.IsFirstCaller);

    object.component<CFormLink>(CFormLink).sort((a, b) => a.compare(b));
  }
}
