import { Component, OnInit } from '@angular/core';
import { CallcardComponentCard } from '../callcard-component-card';

@Component({
  selector: 'app-callcard-cnotes-card',
  templateUrl: './callcard-cnotes-card.component.html',
  styleUrls: ['./callcard-cnotes-card.component.scss']
})
export class CallcardCnotesCardComponent extends CallcardComponentCard implements OnInit {
  public ngOnInit() {}

  public hasText(): boolean {
    return this.callcard.note && this.callcard.note.Text && true;
  }
}
