// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CreateMobilisationArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.CreateMobilisationArgs';
  $t: string = 'SagaBase.Defines.CreateMobilisationArgs';

  public static GetMembers(): string[] {
    return ['GuidResources', 'ListProtocols', 'Priority', 'CallcardUid', 'ProcStepUid', 'Message', 'SiteId', 'Description'];
  }

  public GetMembers(): string[] {
    return CreateMobilisationArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GuidResources: Guid[];
  public ListProtocols: SagaBase.Defines.ComProtocol[];
  public Priority: SagaBase.ComJobPriority;
  public CallcardUid: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public Message: string;
  public SiteId: number;
  public Description: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
