import { ListSettingValue } from '@addins/core/settings/schema/list-setting-value';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class LocationSettings {
  private readonly _isLocationTrackingEnabled = new BehaviorSubject<boolean>(true);
  get isLocationTrackingEnabled(): boolean {
    return this._isLocationTrackingEnabled.getValue();
  }

  $locationTrackingEnabled(): Observable<boolean> {
    return this._isLocationTrackingEnabled.asObservable();
  }

  get $change(): Observable<ListSettingValue[]> {
    return combineLatest([
      this.serverUpdate.$change.pipe(map(() => this.serverUpdate)),
      this.distanceFilter.$change.pipe(map(() => this.distanceFilter))
    ]);
  }

  constructor(private distanceFilter: ListSettingValue, private serverUpdate: ListSettingValue) {}

  getDistanceFilter(): number {
    return this.distanceFilter.selected.value;
  }

  getServerUpdate(): number {
    return this.serverUpdate.selected.value;
  }

  getDistanceFilterObject(): ListSettingValue {
    return this.distanceFilter;
  }

  getServerUpdateObject(): ListSettingValue {
    return this.serverUpdate;
  }

  setLocationTrackingEnabled(enabled: boolean): void {
    this._isLocationTrackingEnabled.next(enabled);
  }
}
