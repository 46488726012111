import { MyLocationService } from '@addins/core/map';
import { ILocalizeRequest, LocalizePositionService } from '@addins/core/map/';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { SagaSettingsService } from '@services/settings';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ICallcardTool } from '../../schema/interfaces/ICallcardButtonTool';
import { LocalizeButtonTool } from '../../schema/localize-button-tool';
import { ButtonsProviderService } from '../../services/buttons-provider/buttons-provider.service';

interface Button {
  target: LocalizeButtonTool;
  sub: Subscription;
}

@Component({
  selector: 'app-callcard-detail-buttons',
  templateUrl: './callcard-detail-buttons.component.html',
  styleUrls: ['./callcard-detail-buttons.component.scss']
})
export class CallcardDetailButtonsComponent implements OnInit, OnDestroy {
  @Input()
  card: CallCard;

  protected readonly _buttons: Button[] = [];

  get buttonsCount(): number {
    return this._buttonsProvider.buttons.filter(b => b.show === true).length;
  }

  get callcardToolButtons(): ICallcardTool[] {
    return this._buttonsProvider.buttons;
  }

  get compactButtons(): boolean {
    return this.buttonsCount > 4;
  }

  buttonFontSize: string = null;

  constructor(
    protected _localizePosition: LocalizePositionService,
    protected _buttonsProvider: ButtonsProviderService,
    protected _myLocationService: MyLocationService,
    protected _settingsService: SagaSettingsService
  ) {}

  ngOnInit() {
    this.buttonFontSize = this._settingsService.getValue('SagaMobileWebClient.SingleCallcard.FooterButtonsTextSize');

    this.addItem(this.create('LocalizeCard', 'ellipsis-horizontal-circle-outline', 700));
  }

  ngOnDestroy() {
    let button: Button;
    while (this._buttons.length > 0) {
      button = this._buttons.pop();
      button.sub.unsubscribe();
      this._buttonsProvider.remove(button.target);
    }
  }

  protected addItem(button: LocalizeButtonTool): void {
    const obs =
      button.text === 'LocalizeCardAndMe'
        ? button.onClick.pipe(switchMap(localizeRequest => this.getMyLocation(localizeRequest)))
        : button.onClick;
    this._buttons.push({
      target: button,
      sub: this._localizePosition.listenForLocation(obs)
    });
    this._buttonsProvider.add(button);
  }

  protected create(name: string, icon: string, position: number): LocalizeButtonTool {
    return new LocalizeButtonTool([this.card.location.coordinate], name, icon, position);
  }

  private getMyLocation(req: ILocalizeRequest): Observable<ILocalizeRequest> {
    return this._myLocationService.getMyLocation().pipe(
      map(coordinate => {
        req.coordinates.push(coordinate);
        return req;
      })
    );
  }
}
