import { LocalizePositionService } from '@addins/core/map';
import { StartItineraryService } from '@addins/external-itinerary';
import { Component, Input, OnInit } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { ImagesSettingsService } from '@services/settings';

@Component({
  selector: 'app-media-message-deployment',
  templateUrl: './media-message-deployment.component.html',
  styleUrls: ['./media-message-deployment.component.scss']
})
export class MediaMessageDeploymentComponent implements OnInit {
  @Input()
  mediaMessage: MediaMessage;

  navigationImg: string;
  localizeDeploymentImg: string;

  constructor(
    private imagesService: ImagesSettingsService,
    private localizePositionService: LocalizePositionService,
    private startItineraryService: StartItineraryService
  ) {}

  ngOnInit() {
    this.navigationImg = this.imagesService.get('SygicDeploymentPoint');
    this.localizeDeploymentImg = this.imagesService.get('LocalizeDeployment');
  }

  openNavigation() {
    this.startItineraryService.navigateTo(this.getCoordinate());
  }

  private getCoordinate(): Coordinate {
    return {
      x: this.mediaMessage.CoordX,
      y: this.mediaMessage.CoordY,
      epsg: this.mediaMessage.CoordEpsgCode
    };
  }

  onLocalize() {
    this.localizePositionService.localize({
      coordinates: [this.getCoordinate()],
      target: null
    });
  }
}
