import { Inject, Injectable } from '@angular/core';
import { ICardInfoFormatter, ISettingProvider, SETTING_PROVIDER } from '@addins/core/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslateService } from '@ngx-translate/core';
import { MomentService } from '@techwan/ionic-core';
import { CardReceivedCacheService } from '../card-received-cache/card-received-cache.service';

@Injectable()
export class Vd144CardInfoFormatterService implements ICardInfoFormatter {
  constructor(
    private readonly translate: TranslateService,
    private readonly moment: MomentService,
    private readonly received: CardReceivedCacheService,
    @Inject(SETTING_PROVIDER)
    private readonly _settings: ISettingProvider
  ) {}

  public start() {
    this.received.setup();
  }

  public shutdown() {
    this.received.setDown();
  }

  public format(card: CallCard, short: boolean = false): string[] {
    return short === true ? [this.short(card)] : this.long(card);
  }

  protected short(card: CallCard): string {
    return [
      this.translate.instant('Mobile.CardNumberTitle') + card.Id,
      `${this.moment.formatDate(card.SubmittedUTC, this._settings.getValue('SagaMobileWebClient.ShortDateFormat'))}`
    ]
      .filter(i => i)
      .join(' - ');
  }

  protected long(card: CallCard): string[] {
    return [
      [
        `${this.translate.instant('Mobile.CardNumberTitle')}${card.Id}`,
        `${this.translate.instant('Mobile.Creation')}: ${this.moment.formatDate(
          card.CreatedUTC,
          this._settings.getValue('SagaMobileWebClient.SubmittedDateFormat')
        )}`
      ]
        .filter(s => s)
        .join(' - '),
      [`${this.translate.instant(card.status.Name)}`, `${this.translate.instant('Mobile.Received')}: ${this.received.getTime(card)}`]
        .filter(s => s)
        .join(' - ')
    ];
  }
}
