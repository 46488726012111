// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class Join_abstract extends BaseObject {
  static $t: string = 'SagaBase.Objects.Join';
  $t: string = 'SagaBase.Objects.Join';

  public static GetMembers(): string[] {
    return ['TableName', 'Alias', 'DependencyTables', 'OnClause', 'Table'];
  }

  public GetMembers(): string[] {
    return Join_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TableName: string;
  public Alias: string;
  public DependencyTables: string[];
  public OnClause: string;
  public Table: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
