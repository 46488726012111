// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ResourceStatusArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.ResourceStatusArgs';
  $t: string = 'SagaBase.Defines.ResourceStatusArgs';

  public static GetMembers(): string[] {
    return ['ServiceId', 'ResourceType', 'ResourceId', 'XmlData'];
  }

  public GetMembers(): string[] {
    return ResourceStatusArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ServiceId: Guid = GuidTools.Empty;
  public ResourceType: SagaBase.Defines.ResType = SagaBase.Defines.ResType.Unknown;
  public ResourceId: Guid = GuidTools.Empty;
  public XmlData: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
