<ion-item-group>
  <ion-item-divider>
    <ion-label translate>Mobile.LocationTracking</ion-label>
  </ion-item-divider>

  <ion-item>
    <ion-label translate="Mobile.GPSSettingsGPSEnabled" (click)="gpsToggle.checked = !gpsToggle.checked"></ion-label>
    <ion-toggle #gpsToggle [checked]="gpsEnabled" (ionChange)="onGpsToggleChange($event)"></ion-toggle>
  </ion-item>

  <app-settings-select-field
    *ngFor="let gpsSetting of gpsFilterSettings"
    [entry]="gpsSetting"
    [readOnly]="readOnly"
  ></app-settings-select-field>
</ion-item-group>
