import { ChangeDetectorRef, Component, OnInit, Type, ViewChild } from '@angular/core';
import { IonNav, MenuController, Platform } from '@ionic/angular';
import { Events } from '@services/events/events.service';
import { LayoutService } from '@services/layout/layout.service';
import { MenuEntry } from '@services/menu/entry.schema';
import { IMenuEvent, Menu } from '@services/menu/menu';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { AddinResolver, AddinService } from '@techwan/ionic-core';
import { from, zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const ADDIN_VIEW_EVENT_KEY = 'saga.show.addin';

@Component({
  selector: 'app-content',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  providers: []
})
export class RootComponent implements OnInit {
  @ViewChild('content') nav: IonNav;

  rootPage: Type<any>;
  secondPage: Type<any>;

  readonly mainMenu: Menu;

  constructor(
    platform: Platform,
    private addinService: AddinService,
    eventService: Events,
    menuService: SideMenuService,
    private menuController: MenuController,
    private changeDetector: ChangeDetectorRef,
    private viewSelector: ViewSelectorService,
    private layoutService: LayoutService
  ) {
    this.mainMenu = menuService.get();

    zip(from(platform.ready()), viewSelector.ready.pipe(filter(r => r))).subscribe(
      () => (this.rootPage = viewSelector.getCurrent().element)
    );

    this.mainMenu.onEvent
      .pipe(
        filter((e: IMenuEvent) => e.type === 'add' && e.items.length > 0),
        map((e: IMenuEvent) => e.items)
      )
      .subscribe((e: MenuEntry[]) => {
        if (!this.rootPage) {
          this.viewSelected(e[0]);
        }
      });

    this.mainMenu.onEvent
      .pipe(
        filter((e: IMenuEvent) => e.type === 'remove' && e.items.length > 0),
        map((e: IMenuEvent) => e.items)
      )
      .subscribe((menu: MenuEntry[]) => {
        if (this.rootPage === menu[0].type) {
          this.nav.popToRoot();
        }
      });

    eventService.subscribe(ADDIN_VIEW_EVENT_KEY, args => this.onShowAddinEvent(args[0], args[1]));
  }

  private onShowAddinEvent(name: string, data: any) {
    const type: AddinResolver = this.addinService.resolveAddinView(name);
    if (type) {
      this.nav.push(type.element, data);
    }
  }

  ngOnInit(): void {
    this.viewSelector.viewChanged.subscribe(addinResolver => this.onViewChanged(addinResolver));
  }

  viewSelected(view: MenuEntry) {
    if (!this.nav) {
      return;
    }

    if (!view.type) {
      this.nav.canGoBack().then(can => {
        if (can) {
          this.nav.popToRoot();
        }
      });
    } else if (view.isHandler) {
      (view.type as any)();
    } else {
      this.nav.getActive().then(a => {
        if (a.component !== view.type) {
          this.nav.push((view as any).type);
        }
      });
    }

    // Toggle menu, when it's opened
    this.menuController.isOpen().then(o => (o ? this.menuController.toggle() : null));
  }

  private onViewChanged(view: AddinResolver) {
    this.rootPage = view.element;

    // Display menu only when we are logged.
    this.mainMenu.show = this.viewSelector.inApp;

    // Display the map on the second part of the layout (must be changed according to the main page from the web layout.)
    if (this.viewSelector.inApp && !this.secondPage && this.layoutService.hasSecondPage) {
      this.secondPage = this.viewSelector.getMap().element;
    }

    this.changeDetector.detectChanges();
  }
}
