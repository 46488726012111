// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:24
import { CStampResource_abstract } from './../abstract/SagaSchema/CStampResource_abstract';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheChangedInterface } from '@services/cache/cache.service';
export class CStampResource extends CStampResource_abstract {
  private _status: ListResourceStatus;

  get text(): string {
    return this.Text;
  }

  get elementname(): string {
    return this.ElementName;
  }

  get iconStyle(): any {
    switch (this.StampType) {
      case 1:
        return {
          color: this._status.color.tinycolor.toRgbString()
        };
    }
    return {};
  }

  get icon(): string {
    switch (this.StampType) {
      case 1:
        return 'car';
      default:
        return 'information-circle';
    }
  }

  public changed(change: CacheChangedInterface) {
    this._status = change.fetchList.getRow(ListResourceStatus, this.Status);
    return super.changed(change);
  }

  get isCro() {
    return this.StampType === 1 && this.Status === 1024;
  }

  get title(): string {
    return this.$t + '_' + this.StampType + '_' + this.Status;
  }
}
