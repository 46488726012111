import { CurrentCardService } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { CVehicle } from '@models/imported/SagaSchema/CVehicle';
import { CacheService } from '@services/cache/cache.service';
import { AddinView } from '@techwan/ionic-core';
import { CardParticularsWrapper } from '../../schema/card-particulars-wrapper';
import { CallcardDetailParticularsComponent } from '../callcard-detail-particulars/callcard-detail-particulars.component';

@Component({
  selector: 'app-callcard-detail-cvehicle',
  templateUrl: './callcard-detail-cvehicle.component.html',
  styleUrls: ['./callcard-detail-cvehicle.component.scss']
})
@AddinView({
  name: 'CallcardDetailCVehicle'
})
export class CallcardDetailCVehicleComponent extends CallcardDetailParticularsComponent<CVehicle> implements OnInit {
  readonly cVehiclesWrapper: CardParticularsWrapper<CVehicle>[] = [];

  constructor(_currentCardService: CurrentCardService, _cacheService: CacheService) {
    super(_currentCardService, _cacheService);
  }

  protected onAdd(particular: CVehicle) {
    this.cVehiclesWrapper.push(new CardParticularsWrapper<CVehicle>(particular));
  }

  protected onRemove(particular: CVehicle) {
    const wrapperToDelete = this.cVehiclesWrapper.find(vehicleWrapper => vehicleWrapper.wrappedParticular === particular);
    this.cVehiclesWrapper.splice(this.cVehiclesWrapper.indexOf(wrapperToDelete), 1);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.callcard) {
      this.createWrappersOf(CVehicle).forEach(vehicle => this.cVehiclesWrapper.push(vehicle));
      this._cacheSubscriptions.push(this.getFilteredCacheSubscriptionForClassName(CVehicle.$t));
    }
  }
}
