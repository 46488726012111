import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMapIcon, IMapProxy } from '@techwan/map-tools';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MapProxyService implements IMapProxy {
  private _icons: IMapIcon[] = null;

  constructor(private http: HttpClient) {}

  getIcons(): Observable<IMapIcon[]> {
    return this._icons === null
      ? this.http.get<IMapIcon[]>('/api/Map/GetIcons').pipe(tap(icons => (this._icons = icons)))
      : of(this._icons);
  }
}
