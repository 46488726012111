import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'stamp-text-editor.component.html',
  styleUrls: ['stamp-text-editor.component.scss']
})
export class StampTextEditorComponent implements OnInit, AfterViewChecked {
  @ViewChild('textAreaMessage') textArea: IonTextarea;

  @Input()
  title: string;
  @Input()
  initialText?: string;
  @Input()
  textMaxLength: number = 4000;
  @Input()
  resultHandler: (text: string) => Promise<boolean>;

  text: string = '';

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.text = this.initialText ? this.initialText : '';
  }

  ngAfterViewChecked() {
    this.textArea.setFocus();
  }

  onSubmit() {
    this.resultHandler(this.text).then(success => {
      if (success) {
        this.onDismiss();
      }
    });
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }
}
