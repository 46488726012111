// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { UnitActivity_abstract } from './../abstract/SagaSchema/UnitActivity_abstract';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { Color } from '@models/color';
import { GuidTools } from '@models/guid';

export class UnitActivity extends UnitActivity_abstract {
  static globalIcon: string = 'md-directions_car';

  public devices: CommunicationEquipment[] = [];
  public vehicles: Vehicle[] = [];

  defaultColor: Color = new Color('black');

  private _status: ListResourceStatus;
  get status(): ListResourceStatus {
    return this._status;
  }

  set status(status: ListResourceStatus) {
    this._status = status;
    this.Status = status.Value;
  }

  private _callcard: CallCard;
  get callcard(): CallCard {
    return this._callcard;
  }

  set callcard(value: CallCard) {
    this._callcard = value;
    this.CallCardID = value ? value.ObjGuid : GuidTools.Empty;
  }

  private _rShort: string;

  public set shortText(value: string) {
    this._rShort = value;
  }

  $$poling: boolean;

  get mainRessource() {
    if (this.MainResourceUId) {
      return this.devices.find(device => device.ObjGuid === this.MainResourceUId);
    }

    return null;
  }

  get vehicle() {
    return this.vehicles.length ? this.vehicles[0] : null;
  }

  get positionProvider() {
    return this.vehicles.length ? this.vehicles[0] : null;
  }

  set positionProvider(vehicle: Vehicle) {
    this.PositionProviderUId = vehicle.ObjGuid;
  }

  get color(): Color {
    return (this.status && this.status.color) || this.defaultColor;
  }

  get colorRgb(): string {
    return this.color.tinycolor.toRgbString();
  }

  get icon() {
    return this.vehicle ? this.vehicle.icon : UnitActivity.globalIcon;
  }

  get fullCallsign(): string {
    return this.mainRessource ? this.mainRessource.fullCallsign : '';
  }

  public update(fetchList: FetchListService) {
    const row = fetchList.getRow(ListResourceStatus, this.Status);
    if (row != null) {
      this._status = row;
    }
  }

  public changed(changed: CacheChangedInterface) {
    super.changed(changed);
    this._changed.next();
  }

  // TODO: ALTER this behavior to provide the information from a service.
  public rShort(): string {
    return (this.mainRessource ? this.mainRessource.fullCallsign : this._rShort) || this.Name;
  }

  export(): any {
    return {
      owner: this.Owner,
      equipments: this.devices.map(d => d.ObjGuid),
      vehicles: this.vehicles.map(v => v.ObjGuid)
    };
  }

  isValid() {
    return this.devices.length && this.vehicles.length;
  }
}
