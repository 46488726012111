import { CommonAddinModule } from '@addins/common.module';
import { CoreModule } from '@addins/core/core';
import { SettingsModule } from '@addins/core/settings';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Provider } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { LoginModule as CoreLoginModule } from '@techwan/ionic-core/login';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { DeviceComponent } from './components/device/device.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoginConfigSelectorComponent } from './components/login-config-selector/login-config-selector.component';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { SiteSelectorComponent } from './components/site-selector/site-selector.component';
import { TrustedUserLoginComponent } from './components/trusted-user-login/trusted-user-login.component';
import { BootService } from './services/boot/boot.service';
import { LoginService } from './services/executables/login/login.service';
import { InitializerService } from './services/initializer/initializer.service';
import { LoginStateService } from './services/login-state/login-state.service';
import { SiteSelectorService } from './services/site-selector/site-selector.service';
import { GenericLoginComponent } from './views/generic-login/generic-login.component';
import { LoginSetupComponent } from './views/login-setup/login-setup.component';
import { StartingComponent } from './views/starting/starting.component';

const views = [GenericLoginComponent, StartingComponent, LoginSetupComponent];
const components = [
  DeviceComponent,
  LoadingComponent,
  LoginViewComponent,
  TrustedUserLoginComponent,
  LoginConfigSelectorComponent,
  ...views
];
const declarations = [...components, SiteSelectorComponent];
const executables = [LoginService];
const providers: Provider[] = [BootService, LoginStateService, InitializerService, SiteSelectorService, ...executables];

@NgModule({
  declarations,
  imports: [CoreModule, IonicModule, CommonModule, SharedModule, SettingsModule, CoreLoginModule, HttpClientModule, CommonAddinModule],
  exports: components,
  providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@Addin({
  name: 'AppLogin',
  require: ['Settings'],
  autoload: true
})
export class LoginModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, declarations, executables);
  }
}
