import { Component } from '@angular/core';
import { CallcardComponentCard } from '../callcard-component-card';

@Component({
  selector: 'app-callcard-cevents-card',
  templateUrl: './callcard-cevents-card.component.html',
  styleUrls: ['./callcard-cevents-card.component.scss']
})
export class CallcardCeventsCardComponent extends CallcardComponentCard {
  get rLong(): string[] {
    return this.callcard.event.rLong.split('\r\n');
  }
}
