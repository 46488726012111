import { MapName } from '@addins/core/core';
import { AdditionalViewTools, AdditionalViewToolsService, PanelEvent } from '@addins/core/panel';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CLocationHistory } from '@models/imported/SagaSchema/History/CLocationHistory';
import { MapInstancesService, MapService } from '@services/map';
import { MapElementComponent, SagaLayerManager } from '@techwan/ionic-core/map';
import { ToggleTool } from '@techwan/map-tools';
import { Map, TileLoader } from '@techwan/mapping';
import Base from 'ol/layer/base';
import { Subscription } from 'rxjs';
import { filter, find, first } from 'rxjs/operators';
import { IMapView } from '../../schema/interfaces/IMapView';
import { LayerTreePanelTool } from '../../schema/tools/layer-tree-panel-tool';
import { SearchPanelTool } from '../../schema/tools/search-panel-tool';
import { InMapPanelService } from '../../services/in-map-panel/in-map-panel.service';
import { LocalizePositionService } from '../../services/localize-position/localize-position.service';
import { MapImagesService } from '../../services/map-images/map-images.service';
import { MapSettingsService } from '../../services/map-settings/map-settings.service';
import { MyLocationService } from '../../services/my-location/my-location.service';
import { TileLoaderProviderService } from '../../services/tile-loader-provider/tile-loader-provider.service';

@Component({
  selector: 'app-full-map',
  templateUrl: './full-map.component.html',
  styleUrls: ['./full-map.component.scss']
})
export class FullMapComponent implements OnInit, AfterViewInit, OnDestroy, IMapView, AfterViewChecked {
  @ViewChild(MapElementComponent)
  mapElement: MapElementComponent;
  private map: Map;

  @Input()
  toc: boolean = true;

  get baseLayerId(): string {
    return this._mapSettingsService.baseLayerId;
  }
  get tileLoader(): TileLoader {
    return this._tileProvider.tileLoader;
  }

  private _additionalViewTools?: AdditionalViewTools = null;

  private _searchPanelTool = new SearchPanelTool();
  private _layerTreePanelTool = new LayerTreePanelTool();

  private _lastSize: string;
  private _originalParent: any;

  private _subs: Subscription[] = [];
  private _myLocationSubscription: Subscription = null;

  constructor(
    private _mapInstancesService: MapInstancesService,
    private _mapService: MapService,
    private _mapImagesService: MapImagesService,
    private _additionalViewToolsService: AdditionalViewToolsService,
    private _inMapPanelService: InMapPanelService,
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _localizePositionService: LocalizePositionService,
    private _myLocation: MyLocationService,
    private _mapSettingsService: MapSettingsService,
    private _tileProvider: TileLoaderProviderService
  ) {
    this._mapImagesService.setup();
  }

  ngOnInit() {
    this._inMapPanelService.setup();

    if (this.toc === true) {
      this._additionalViewToolsService.addTool(this._searchPanelTool);
      this._additionalViewToolsService.addTool(this._layerTreePanelTool);
    }

    this._subs.push(this._inMapPanelService.fullscreenButton.$event.subscribe((event: ToggleTool) => this.toggleFullscreen(event)));
  }

  private toggleFullscreen(toggleTool: ToggleTool): void {
    this._renderer2.appendChild(
      !toggleTool.isActive ? this._originalParent : document.querySelector('body'),
      this._elementRef.nativeElement
    );
  }

  ngAfterViewInit() {
    this.mapElement.initMap().then(map => this.onMapReady(map));

    this._originalParent = this._renderer2.parentNode(this._elementRef.nativeElement);
  }

  private onMapReady(map: Map) {
    this.map = map;

    if (!this._localizePositionService.isCentering) {
      this.setCenterCoordinates();
    }
    this._mapSettingsService.centerOnUnit = false;
    this.setPoiLayersVisibility();

    this._mapInstancesService.add(MapName.main, map);

    if (this._additionalViewTools !== null) {
      this._subs.push(
        this._additionalViewTools.$change
          .pipe(filter(panelChange => panelChange.name === PanelEvent.panelResized))
          .subscribe(() => this.onResize())
      );
    }
  }

  private onResize(): void {
    this.map.updateSize();
  }

  ngOnDestroy() {
    this._inMapPanelService.setDown();

    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
    this._additionalViewToolsService.removeTool(this._searchPanelTool);
    this._additionalViewToolsService.removeTool(this._layerTreePanelTool);

    if (this._myLocationSubscription) {
      this._myLocationSubscription.unsubscribe();
      this._myLocationSubscription = null;
    }

    if (this.hasMainMap()) {
      this.saveCurrentCoordinatesAndResolution();
      this.saveBaseLayerId();
      this.savePoiLayersVisibility();

      this._mapInstancesService.remove(MapName.main);
    }
  }

  private hasMainMap(): boolean {
    return this.mapElement && this.map && this._mapInstancesService.get(MapName.main) !== null;
  }

  private saveCurrentCoordinatesAndResolution() {
    const currentMapView = this.map.getProperties().view;
    const currentViewCenter: Coordinate = {
      x: currentMapView.getCenter()[0],
      y: currentMapView.getCenter()[1],
      epsg: currentMapView.getProjection().code_
    };

    this._mapSettingsService.centerCoords = currentViewCenter;
    this._mapSettingsService.resolution = currentMapView.getResolution();
  }

  private saveBaseLayerId() {
    const baseLayer = this.map.getBaseLayers().filter(baseLayer => baseLayer.getVisible());
    if (baseLayer.length) {
      this._mapSettingsService.baseLayerId = baseLayer[0].get('id');
    }
  }
  private savePoiLayersVisibility() {
    this._mapSettingsService.poiLayersVisibility.clear();
    this.getPoiLayers().forEach(layer => this._mapSettingsService.poiLayersVisibility.set(layer.get('id'), layer.getVisible()));
  }

  private getPoiLayers(): Base[] {
    return (this.map.getLayerManager() as SagaLayerManager)
      .getOverlayLayers()
      .find(group => group.get('name') === 'POI')
      .getLayers()
      .getArray();
  }

  ngAfterViewChecked() {
    if (this.mapElement && this.map) {
      const size = `${this._elementRef.nativeElement.offsetWidth}x${this._elementRef.nativeElement.offsetHeight}`;
      if (this._lastSize && this._lastSize !== size) {
        this.map.updateSize();
      }
      this._lastSize = size;
    }
  }

  private setCenterCoordinates(): void {
    if (this._mapSettingsService.centerOnUnit) {
      this._myLocationSubscription = this._myLocation.$change
        .pipe(
          find(coord => coord !== this._myLocation.empty),
          first()
        )
        .subscribe(coord => {
          this._myLocationSubscription = null;
          this._localizePositionService.centerView(this.map.getView(), coord, 1);
        });
    } else if (this._mapSettingsService.centerCoords) {
      this._localizePositionService.centerView(
        this.map.getView(),
        this._mapSettingsService.centerCoords,
        this._mapSettingsService.resolution
      );
    }
  }

  private setPoiLayersVisibility() {
    if (this._mapSettingsService.poiLayersVisibility.size) {
      this.getPoiLayers().forEach(layer => layer.setVisible(this._mapSettingsService.poiLayersVisibility.get(layer.get('id'))));
    }
  }

  centerOnCard(callcard: CallCard, resolution: number = null) {
    if (callcard) {
      this.centerOnLocation(callcard.location, resolution);
    }
  }

  private centerOnLocation(location: CLocation | CLocationHistory, resolution: number = -1) {
    if (location) {
      this._localizePositionService.centerOnLocation(this.map.getView(), location, resolution);
      this._mapService.moveToPosition = false;
    }
  }
}
