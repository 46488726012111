import { ILocationProxy } from '@addins/core/core/src/schema/interfaces/location-proxy';
import { Inject, Injectable } from '@angular/core';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Guid } from '@models/guid';
import { BehaviorSubject, from, Observable, Observer, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PROVIDER_ERROR_REPORTER } from '../../../schema/injection-token';
import { ILocationProvider } from '../../../schema/interfaces/location-provider';
import { LocationProviderErrorReporter } from '../../../schema/interfaces/location-provider-error-reporter';
import { PositionProxyService } from '../position-proxy/position-proxy.service';

@Injectable()
export class NavigatorLocationService implements ILocationProvider {
  private readonly _options = { maximumAge: 60000, timeout: 120000, enableHighAccuracy: true };
  private _watch: number = null;

  private _position = new BehaviorSubject<Geoposition>(null);
  get $change(): Observable<Geoposition> {
    return this._position.asObservable();
  }

  constructor(
    @Inject(PROVIDER_ERROR_REPORTER)
    private _reporter: LocationProviderErrorReporter,
    private _proxy: PositionProxyService
  ) {}

  /**
   * This is used only on full web, desktop platform.
   */
  getCurrent(): Observable<Geoposition> {
    return from(
      new Promise<Geoposition>((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, this._options))
    );
  }

  isSupported(): boolean {
    return 'geolocation' in navigator;
  }

  setup(uid: Guid) {
    this._proxy.init(uid);
  }

  start(): Observable<any> {
    this._proxy.start();
    return this._watch === null ? new Observable(obs => this.init(obs)).pipe(tap(position => this.store(position))) : of();
  }

  private init(observer: Observer<any>) {
    this._watch = navigator.geolocation.watchPosition(
      position => {
        observer.next(position);
        observer.complete();
      },
      error => this.error(observer, error),
      this._options
    );
  }

  private error(observer: Observer<any>, error) {
    this._reporter.report(error);
    this.stop();
    observer.error(error);
  }

  stop(): Observable<any> {
    navigator.geolocation.clearWatch(this._watch);
    this._watch = null;
    this._proxy.stop();
    return of();
  }

  private store(position: Geoposition) {
    if (position.coords) {
      // invalidate current lastLocation. Will be recomputed on demand by reading currentLocation()
      this._position.next(position);
      this._proxy.update(position);
    }
  }

  getProxy(): ILocationProxy {
    return this._proxy;
  }
}
