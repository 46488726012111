import { Injectable } from '@angular/core';
import { JournalTool } from '../../schema/journal-tool';

@Injectable({
  providedIn: 'root'
})
export class JournalToolsProviderService {
  private _tools: JournalTool[] = [];
  get tools(): JournalTool[] {
    return this._tools;
  }

  constructor() {}

  add(tool: JournalTool) {
    if (this.indexOf(tool) == -1) {
      this._tools.push(tool);
    }
  }

  private indexOf(tool: JournalTool): number {
    return this._tools.indexOf(tool);
  }

  remove(tool: JournalTool) {
    const index = this.indexOf(tool);
    if (index > -1) {
      this._tools.splice(index, 1);
    }
  }
}
