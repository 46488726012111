// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 13:39:25
export enum GroupLogicType {
  choice = 0,
  or = 1,
  and = 2
}
export enum OperatorType {
  isnull = 0,
  equal = 1,
  notequal = 2,
  like = 3,
  beginswith = 4,
  contains = 5,
  containsfuzzy = 6,
  containslike = 7,
  less = 8,
  greater = 9,
  lessorequal = 10,
  greaterorequal = 11,
  boolean = 12,
  istrue = 13,
  isfalse = 14,
  between = 15,
  inlist = 16,
  none = 17,
  exists = 18
}
export enum OperandType {
  field = 0,
  value = 1
}
export enum ValueType {
  undefined = 0,
  numeric = 1,
  text = 2,
  text_ci_ai = 3,
  date = 4,
  boolean = 5
}
export enum AsyncChange {
  Add = 0,
  Remove = 1,
  Clear = 2
}
export enum DeployementType {
  Unit = 1,
  Vehicle = 2,
  Actor = 4
}
export enum RepeatMode {
  None = 0,
  NbOfTimes = 1,
  Infinite = 2
}
export enum TimeSpanUnitsEnum {
  TimeSpan = 0,
  Month = 1,
  Year = 2
}
