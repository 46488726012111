// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import * as SagaBase from '../../SagaBase';
import { CStamp } from '../../SagaSchema/CStamp';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CStampCard_abstract extends CStamp {
  static $t: string = 'SagaSchema.CStampCard';
  $t: string = 'SagaSchema.CStampCard';

  public static GetMembers(): string[] {
    return [
      'UserSiteId',
      'VoiceChannel',
      'ComponentId',
      'Text',
      'ActionText',
      'Complement',
      'Status',
      'TraceState',
      'SourceIdentifier',
      'AlertStatus',
      'AcknowledgedBy',
      'AcknowledgedByName'
    ].concat(CStamp.GetMembers());
  }

  public GetMembers(): string[] {
    return CStampCard_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public UserSiteId: number;
  public VoiceChannel: string;
  public ComponentId: Guid = GuidTools.Empty;
  public Text: string = '';
  public ActionText: string;
  public Complement: string = '';
  public Status: number = 0;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public SourceIdentifier: string;
  public AlertStatus: number = 0;
  public AcknowledgedBy: number;
  public AcknowledgedByName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
