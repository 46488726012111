// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CIntel_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CIntel';
  $t: string = 'SagaSchema.CIntel';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'IntelSource',
      'ResultType',
      'QueryType',
      'ResultId',
      'Text',
      'HyperLink',
      'QuerySourceInfo',
      'QuerySourceType',
      'QuerySourceId'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CIntel_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public IntelSource: string;
  public ResultType: any;
  public QueryType: any;
  public ResultId: string;
  public Text: string;
  public HyperLink: string;
  public QuerySourceInfo: string;
  public QuerySourceType: string;
  public QuerySourceId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
