import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadCacheService, UploadEventType, UploadManagerService } from '@techwan/file-upload';
import { AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';

@AddinView({
  name: 'UploadList'
})
@Component({
  selector: 'app-upload-view',
  templateUrl: './upload-view.component.html',
  styleUrls: ['./upload-view.component.scss']
})
export class UploadViewComponent implements OnInit, OnDestroy {
  private _eventSubscription: Subscription = null;

  private _uploadItems: SagaFileEntryUploadItem[] = null;
  get uploadItems(): SagaFileEntryUploadItem[] {
    return this._uploadItems;
  }

  constructor(private _uploadCacheService: UploadCacheService, private _uploadManagerService: UploadManagerService) {}

  ngOnInit() {
    this._eventSubscription = this._uploadManagerService.$event
      .pipe(
        filter(uploadEvent =>
          [UploadEventType.uploadAdded, UploadEventType.uploadRemoved, UploadEventType.uploadFinished].includes(uploadEvent.type)
        )
      )
      .subscribe(() => this.refreshUploadItems());

    this.refreshUploadItems();
  }

  ngOnDestroy() {
    if (this._eventSubscription) {
      this._eventSubscription.unsubscribe();
      this._eventSubscription = null;
    }
  }

  private refreshUploadItems() {
    this._uploadItems = this._uploadCacheService.uploadItems as SagaFileEntryUploadItem[];
  }
}
