import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { ModalService } from '@services/modal/modal.service';
import { from, Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { StampsProxyService } from '../stamps-proxy/stamps-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class CallcardStampsService {
  private callcard: CallCard = null;
  private stamps: List<SagaObject>;
  private oldStamps: string[] = [];

  constructor(private stampProxy: StampsProxyService, private modalService: ModalService) {
    this.stamps = new List<SagaObject>();
    this.stamps.addSort((a, b) => b.CreatedUTC.getTime() - a.CreatedUTC.getTime());
  }

  getStamps(callcard: CallCard, refresh: boolean = false): Observable<List<SagaObject>> {
    const callcardId: string = callcard.ObjGuid;
    if (!this.callcard || callcardId !== this.callcard.ObjGuid || refresh) {
      return from(this.modalService.presentLoading()).pipe(
        switchMap(() => this.stampProxy.getStamps(callcardId)),
        map(items => {
          if (refresh) {
            this.markRead();
          }

          this.callcard = callcard;
          this.callcard.hasDatabaseChanged = false;

          this.stamps.source = items;

          return this.stamps;
        }),
        finalize(() => this.modalService.dismissLoading())
      );
    }
    return of(this.stamps);
  }

  isNew(stamp: SagaObject): boolean {
    return !this.oldStamps.find(id => id === stamp.ObjGuid);
  }

  markRead() {
    this.oldStamps.length = 0;
    this.stamps.items.forEach(item => this.oldStamps.push(item.ObjGuid));
  }

  findStamp(findPredicate: (stamp: SagaObject) => boolean): SagaObject {
    return this.stamps.items.find(stamp => findPredicate(stamp));
  }
}
