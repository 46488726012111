import { Injectable } from '@angular/core';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { StatusService } from '@services/status/status.service';
import { TerminateMessageModalService } from '../terminate-message-modal/terminate-message-modal.service';

@Injectable()
export class StatusChangeRequirementsService {
  constructor(
    private readonly _statusService: StatusService,
    private readonly _terminateMessageModalService: TerminateMessageModalService
  ) {}

  getRequestParameter(status: ListResourceStatus): Promise<StatusParameters> {
    if (this.isTerminateAction(status)) {
      return this._terminateMessageModalService.getTerminateRequestParameter();
    }
    return Promise.resolve({});
  }

  private isTerminateAction(status: ListResourceStatus): boolean {
    return this._statusService.getTerminateStatus() === status;
  }
}
