export * from './IDocumentGroup';
export * from './IDocument';
export * from './IDocumentListResponse';

/**
 * @name DocumentType
 * @description
 * The type of document
 */
export enum DocumentType {
  STREAM,
  EXTERNAL,
  WEBSITE
}
