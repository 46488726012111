import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  templateUrl: './map-header.component.html',
  styleUrls: ['./map-header.component.scss'],
  selector: 'map-header'
})
export class MapHeaderComponent {
  isTablet = this.platform.is('tablet');

  constructor(private platform: Platform) {}
}
