import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../logger';
import { AppSettingsService } from '../settings';
import { SagaMissingTranslationHandler } from '@app/translations/missing';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Device } from '@ionic-native/device/ngx';
import { TranslationApiService } from './translation-api/translation-api.service';

/**
 * @description
 * Service for managing the app i18n
 */
@Injectable()
export class GlobalizationService {
  private get missingTranslationsHandler(): SagaMissingTranslationHandler {
    return this.translate.missingTranslationHandler as SagaMissingTranslationHandler;
  }

  private get languageMissingtranslations(): string[] {
    if (!this.missingTranslationsHandler.missingTranslations[this.translate.currentLang]) {
      this.missingTranslationsHandler.missingTranslations[this.translate.currentLang] = [];
    }
    return this.missingTranslationsHandler.missingTranslations[this.translate.currentLang];
  }

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private _settings: AppSettingsService,
    private alertCtrl: AlertController,
    private device: Device,
    private translationApi: TranslationApiService
  ) {}

  /**
   * Initialize translation service with a default language
   * @param lang Default language of the application
   */
  init(lang: string): Observable<any> {
    this.translate.setDefaultLang(lang);

    const isNativeApp: boolean = this.platform.is('cordova') && this.device.platform !== 'browser';
    if (isNativeApp) {
      this.platform.resume.subscribe(() => this.update().subscribe());
    }

    return this.update();
  }

  private update(): Observable<any> {
    return this._settings.language().pipe(concatMap(lang => from(this.setLanguage(lang))));
  }

  setLanguage(lang: string): Promise<any> {
    moment.locale(lang);
    this.translate.use(lang);

    document.querySelector('html').lang = lang;

    return this.updateTranslation(lang);
  }

  updateTranslation(lang: string = this.translate.currentLang || this.translate.defaultLang): Promise<any> {
    return this.getNewTranslation(lang).then(translations => {
      Logger.debug(`Save new content for ${lang}`, 'Globalization');

      this.lowerKeys(translations);
      this.translate.setTranslation(lang, translations, true);

      return translations;
    });
  }

  private getNewTranslation(lang: string): Promise<any> {
    return this.translationApi.getTranslations(lang).toPromise();
  }

  private lowerKeys(translations: any) {
    const keys = Object.keys(translations);
    keys.forEach(key => {
      const value = translations[key];
      delete translations[key];
      translations[key.toLowerCase()] = value;
      if (typeof translations[key.toLowerCase()] === 'object') {
        this.lowerKeys(translations[key.toLowerCase()]);
      }
    });
  }

  getMergedTranslations() {
    const translated = Object.assign({}, this.translate.store.translations[this.translate.currentLang] || {});
    this.languageMissingtranslations.forEach(missing => (translated[missing] = null));
    return translated;
  }

  showTranslateModal(key: string): Promise<any> {
    let currentTranslation = this.translate.instant(key);
    if (currentTranslation === key) {
      currentTranslation = '';
    }
    return this.alertCtrl
      .create({
        header: this.translate.instant('static.translate.key', { key: this.translate.currentLang }),
        message: key,
        inputs: [
          {
            name: 'translate',
            placeholder: this.translate.instant('static.translate.placeholder'),
            value: currentTranslation
          }
        ],
        buttons: [
          {
            text: this.translate.instant('static.cancel'),
            handler: data => {}
          },
          {
            text: this.translate.instant('static.save'),
            handler: async data => {
              if (data && data.translate) {
                return this.saveTranslation(key, data.translate);
              }
            }
          }
        ]
      })
      .then(ionAlert => ionAlert.present());
  }

  private async saveTranslation(key: string, value: string, lang = this.translate.currentLang) {
    key = key.toLowerCase();
    await this.translationApi.save(key, value, lang).toPromise();
    if (this.missingTranslationsHandler.missingTranslations[lang]) {
      const i = this.missingTranslationsHandler.missingTranslations[lang].indexOf(key);
      if (i !== -1) {
        this.missingTranslationsHandler.missingTranslations[lang].splice(i, 1);
      }
    }
    this.translate.set(key, value, lang);
    this.translate.use(this.translate.currentLang);
  }

  exportCSV() {
    const trs = this.getMergedTranslations();
    const keys = Object.keys(trs);
    let csvContent = '\uFEFF';
    keys.forEach(key => {
      const translate = trs[key] !== null ? trs[key] : '';
      const row = `"${key}","${translate}"`;
      csvContent += row + '\r\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    const date = new Date();
    link.setAttribute('download', `translations_mobile_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.csv`);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}
