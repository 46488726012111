import { ImageViewerComponent } from '@addins/callcard/components/image-viewer/image-viewer.component';
import { DownloadUrlTransformService } from '@addins/core/file';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaType } from '@models/imported/SagaBase';
import { DataType } from '@models/imported/SagaBase/Interfaces';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { CStampFreeText } from '@models/imported/SagaSchema/CStampFreeText';
import { CStampParticulars } from '@models/imported/SagaSchema/CStampParticulars';
import { CStampResource } from '@models/imported/SagaSchema/CStampResource';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CLocationHistory } from '@models/imported/SagaSchema/History/CLocationHistory';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { DbService } from '@services/database/database.service';
import { Events } from '@services/events/events.service';
import { ModalService } from '@services/modal/modal.service';
import { NavService } from '@services/navigation/navigation.service';
import { Security } from '@services/security/security.service';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { ADDIN_VIEW_EVENT_KEY, AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { ReportComponent } from '../../components/report/report.component';
import { ReportService } from '../../services/report/report.service';

@AddinExecute({
  name: 'Tools',
  description: 'Provide some tools for the followup stamps'
})
@Injectable()
export class ToolsService extends Execute {
  private editTool: Tool;
  private mediaViewer: Tool;
  private mediaDownload: Tool;
  private locationViewer: Tool;

  private unregisterMediaViewer: Subscription;
  private unregisterEditStamp: Subscription;
  private unregisterLocationViewer: Subscription;

  constructor(
    private toolsProvider: ToolsProviderService,
    private modalCtrl: ModalController,
    private security: Security,
    private report: ReportService,
    private db: DbService,
    private modal: ModalService,
    private navService: NavService,
    private events: Events,
    private view: ViewConfigurationService,
    private downloadUrlTransformService: DownloadUrlTransformService
  ) {
    super();
    this.report.reportComponent = ReportComponent;
  }

  init() {
    this.createTools();
  }

  private createTools() {
    this.editTool = Tool.createTool({
      text: 'Edit',
      execute: async context => {
        if (context[0] instanceof CStampParticulars) {
          const stamp: CStampParticulars = context[0];
          await this.open(stamp.typeName, stamp.SourceComponentUid, stamp.callcard);
        } else if (context[0] instanceof CStampFreeText) {
          const stamp: CStampFreeText = context[0];
          await this.open('CFreeText', stamp.SourceComponentUid, stamp.callcard);
        } else if (context[0] instanceof CStampResource) {
          this.report.addOrEditCro(context[0].callcard);
        }
      },
      icon: 'create'
    });

    this.mediaViewer = Tool.createTool({
      text: 'Open',
      icon: 'open',
      priority: 100,
      execute: async context => {
        const attachment: CAttachment | CMediaMessage = context[0];
        const url: string = this.getMediaUrl(attachment);
        const caption: string = this.getMediaTitle(attachment);

        this.modalCtrl
          .create({
            component: ImageViewerComponent,
            componentProps: {
              images: [
                {
                  url,
                  caption
                }
              ]
            }
          })
          .then(m => m.present());
      }
    });

    this.mediaDownload = Tool.createTool({
      text: 'Download',
      icon: 'download',
      priority: 100,
      execute: async context => {
        const attachment: CAttachment | CMediaMessage = context[0];
        const url: string = this.getMediaUrl(attachment);

        // Download it
        this.security
          .get({
            responseType: 'blob',
            url
          })
          .then((data: Blob) => {
            const blobUrl = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = blobUrl;
            a.setAttribute('download', this.getMediaTitle(attachment));
            a.setAttribute('target', '_blank');
            a.click();
            document.body.removeChild(a);
          });
      }
    });

    this.locationViewer = Tool.createTool({
      text: 'Map',
      icon: 'map',
      priority: 100,
      execute: context => {
        this.navService.getViewOpen(this.view.getMapName()).then(mapView => {
          if (mapView) {
            mapView.centerOnLocation(context[0], 1);
          } else {
            this.events.publish(ADDIN_VIEW_EVENT_KEY, this.view.getMapName(), {
              location: context[0]
            });
          }
        });
      }
    });
  }

  private async open(type: string, guid: string, callcard: CallCard) {
    await this.modal.presentLoading('SagaMobile.ReadObject');
    const obj = await this.db.readObject<SagaObject>(type, guid);
    await this.modal.dismissLoading();
    this.report.edit(callcard, obj);
  }

  private getMediaUrl(attachment: CAttachment | CMediaMessage): string {
    return this.downloadUrlTransformService.transform(attachment.Url);
  }

  private getMediaTitle(attachment: CAttachment | CMediaMessage): string {
    return attachment instanceof CAttachment ? attachment.Title : attachment.Text;
  }

  execute(): void {
    this.registerTools();
  }

  private registerTools() {
    this.unregisterMediaViewer = this.toolsProvider.register(toolsProvider => {
      if (toolsProvider.context.length === 1) {
        if (toolsProvider.contextObjectsAre(CAttachment)) {
          this.registerAttachmentTools(toolsProvider);
        } else if (toolsProvider.contextObjectsAre(CMediaMessage)) {
          this.registerMediaMessageTools(toolsProvider);
        }
      }
    });

    this.unregisterEditStamp = this.toolsProvider.register(toolsProvider => {
      if (
        toolsProvider.context.length === 1 &&
        (toolsProvider.contextObjectsAre(CStampParticulars) ||
          toolsProvider.contextObjectsAre(CStampFreeText) ||
          (toolsProvider.contextObjectsAre(CStampResource) && toolsProvider.context[0].isCro))
      ) {
        toolsProvider.addTool(this.editTool);
      }
    });

    this.unregisterLocationViewer = this.toolsProvider.register(toolsProvider => {
      if (toolsProvider.context.length === 1 && toolsProvider.contextObjectsAre(CLocationHistory)) {
        toolsProvider.addTool(this.locationViewer);
      }
    });
  }

  private registerAttachmentTools(toolsProvider: ToolsProvider) {
    const attachment: CAttachment = toolsProvider.context[0];
    switch (attachment.BinaryDataType) {
      case DataType.Picture:
        toolsProvider.addTool(this.mediaViewer);
        break;
      default:
        toolsProvider.addTool(this.mediaDownload);
        break;
    }
  }

  private registerMediaMessageTools(toolsProvider: ToolsProvider) {
    const mediaMessage: CMediaMessage = toolsProvider.context[0];
    switch (mediaMessage.MediaType) {
      case MediaType.Image:
        toolsProvider.addTool(this.mediaViewer);
        break;
      case MediaType.Document:
      case MediaType.Video:
        toolsProvider.addTool(this.mediaDownload);
        break;
    }
  }

  destroy(): void {
    if (this.unregisterMediaViewer) {
      this.unregisterMediaViewer.unsubscribe();
      this.unregisterMediaViewer = null;
    }
    if (this.unregisterEditStamp) {
      this.unregisterEditStamp.unsubscribe();
      this.unregisterEditStamp = null;
    }
    if (this.unregisterLocationViewer) {
      this.unregisterLocationViewer.unsubscribe();
      this.unregisterLocationViewer = null;
    }
  }
}
