import { Component } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { AddinView } from '@techwan/ionic-core';
import { CallcardUnitsProviderService } from '../../services/callcard-units-provider/callcard-units-provider.service';

@Component({
  selector: 'app-unit-activity-list.view',
  templateUrl: './unit-activity-list-view.component.html',
  styleUrls: ['./unit-activity-list-view.component.scss']
})
@AddinView({
  name: 'UnitActivityListView'
})
export class UnitActivityListViewComponent {
  get units(): UnitActivity[] {
    return this.callcardUnitsProvider.units;
  }

  constructor(private callcardUnitsProvider: CallcardUnitsProviderService) {}
}
