// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListDeviceType_abstract extends SagaObject {
  static $t: string = 'SagaBase.ListDeviceType';
  $t: string = 'SagaBase.ListDeviceType';

  public static GetMembers(): string[] {
    return ['Value', 'Name', 'Description', 'IsOutputDevice', 'IsSwitchable', 'AssociatedDeviceTypeId', 'MatrixId'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return ListDeviceType_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Value: any;
  public Name: string;
  public Description: string;
  public IsOutputDevice: boolean = false;
  public IsSwitchable: boolean = false;
  public AssociatedDeviceTypeId: number = 0;
  public MatrixId: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
