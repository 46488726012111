import { Renderer2, ViewContainerRef } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PanelToolBase } from '../..';
import { ContentLoaderService } from '../../services/content-loader/content-loader.service';
import { ContextChangedEvent, ContextChangedEventType } from '../interfaces/context-changed-event';
import { Panel } from '../interfaces/Panel';
import { PanelContext } from '../interfaces/panel-context';
import { PanelDismissTool } from '../interfaces/panel-dismiss-tool';
import { PanelTool } from '../interfaces/panel-tool';
import { PanelState } from '../panel-state/panel-state';
import { AdditionalViewTools, IPanelChanged, PanelEvent } from '../panel-tools/additional-view-tools';

export class AdditionalViewContext implements PanelContext {
  private _toolSubscriptions = new Map<PanelTool, Subscription>();
  private _subscriptions: Subscription[] = [];

  activePanelTool: PanelToolBase = null;

  private _change = new Subject<ContextChangedEvent>();
  get onChange(): Observable<ContextChangedEvent> {
    return this._change.asObservable();
  }

  states = new Map<PanelTool, PanelState>();

  get host(): any {
    return this._panel.host.nativeElement;
  }
  get container(): ViewContainerRef {
    return this._panel.content;
  }
  get maxHeight(): any {
    return this._panel.maxHeight.nativeElement;
  }
  get renderer(): Renderer2 {
    return this._panel.localRenderer;
  }
  private get autoDismiss(): PanelDismissTool {
    return this._panel.autoDismiss;
  }

  private _contentLoader: ContentLoaderService = null;

  constructor(private _panel: Panel, private _additionalViewTools: AdditionalViewTools) {}

  getPanel(): Panel {
    return this._panel;
  }

  resizeContent() {
    const state = this.getState();
    if (state.height > -1) {
      this.renderer.setStyle(this.maxHeight, 'height', `${state.height}px`);
    } else {
      this.renderer.removeStyle(this.maxHeight, 'height');
    }
    this.onResize();
  }

  getState(): PanelState {
    return this.states.get(this.activePanelTool);
  }

  onResize() {
    this._additionalViewTools.onResize();
  }

  init(): void {
    this._additionalViewTools.tools.forEach(tool => this.subscribeTo(tool));
    this._subscriptions.push(this._additionalViewTools.$change.subscribe(event => this.refresh(event)));
    this._subscriptions.push(
      fromEvent(this.host, 'click')
        .pipe(filter(() => !this.autoDismiss || this.autoDismiss.isUnpinned))
        .subscribe(() => this.toggle())
    );
    this._subscriptions.push(this.autoDismiss.toggle.subscribe(() => this.saveDismiss()));
  }

  private subscribeTo(panelTool: PanelToolBase) {
    if (panelTool.content) {
      this._toolSubscriptions.set(
        panelTool,
        panelTool.$click.subscribe(() => this.toggle(panelTool))
      );
    }
  }

  private refresh(panelChanged: IPanelChanged) {
    if (this.toolHasBeenAdded(panelChanged)) {
      this.subscribeTo(panelChanged.tool);
    } else if (this.toolHasBeenRemoved(panelChanged)) {
      this.unsubscribeTo(panelChanged.tool);
    }
    if (this.activePanelToolHasBeenRemoved(panelChanged)) {
      this.clear();
    }
  }

  private toolHasBeenAdded(panelChanged: IPanelChanged) {
    return panelChanged.name === PanelEvent.toolAdded;
  }

  private toolHasBeenRemoved(panelChanged: IPanelChanged) {
    return panelChanged.name === PanelEvent.toolRemoved;
  }

  private unsubscribeTo(panelTool: PanelTool) {
    if (this._toolSubscriptions.has(panelTool)) {
      this._toolSubscriptions.get(panelTool).unsubscribe();
      this._toolSubscriptions.delete(panelTool);
    }
  }

  private activePanelToolHasBeenRemoved(panelChanged: IPanelChanged) {
    return this.toolHasBeenRemoved(panelChanged) && panelChanged.tool === this.activePanelTool;
  }

  private clear(): void {
    const tool = this.activePanelTool;
    tool.reset();
    this.container.clear();
    this.renderer.removeStyle(this.maxHeight, 'height');
    this._additionalViewTools.onResize();
    this.activePanelTool = null;
    this.autoDismiss.reset();
    this.notify(tool, ContextChangedEventType.contentReset);
  }

  private saveDismiss(): void {
    this.getState().pinned = !this.autoDismiss.isUnpinned;
  }

  setContentLoader(contentLoader: ContentLoaderService) {
    this._contentLoader = contentLoader;
  }

  destroy(): void {
    while (this._subscriptions.length) {
      this._subscriptions.pop().unsubscribe();
    }
    if (this._toolSubscriptions.size > 0) {
      this._toolSubscriptions.forEach(subscription => subscription.unsubscribe());
      this._toolSubscriptions.clear();
    }
  }

  toggleDismiss(): void {
    if (!this.autoDismiss || this.autoDismiss.isUnpinned) {
      this.toggle();
    }
  }

  private toggle(panelTool: PanelToolBase = this.activePanelTool) {
    if (this.activePanelTool && this.activePanelTool !== panelTool) {
      this.activePanelTool.reset();
    }

    if (this.activePanelTool !== panelTool) {
      this.activePanelTool = panelTool;
      this.storeActiveState(panelTool);
      this.setAutoDismiss(!this.getState().pinned);
      this.loadContent();
      this.notify(panelTool, ContextChangedEventType.contentChanged);
    } else {
      this.clear();
    }
  }

  private storeActiveState(activeTool: PanelTool) {
    if (!this.states.has(activeTool)) {
      this.states.set(activeTool, new PanelState());
    }
  }

  setAutoDismiss(value: boolean) {
    this.autoDismiss.setUnpinned(value);
  }

  private loadContent() {
    if (this._contentLoader !== null) {
      this._contentLoader.load(this.container, this.activePanelTool).instance.setPanel(this._panel);
      this.resizeContent();
    }
  }

  private notify(tool: PanelTool, type: ContextChangedEventType): void {
    this._change.next({ type, context: this, tool });
  }
}
