import { LocalizePositionService } from '@addins/core/map';
import { Component, Input, OnInit } from '@angular/core';
import { TaskTypes } from '@models/imported/SagaBase';
import { Task } from '@models/imported/SagaSchema/Task';
import { CacheService } from '@services/cache/cache.service';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss']
})
export class TaskListItemComponent implements OnInit {
  @Input()
  task: Task;
  @Input()
  isMyTask: boolean = false;

  parentTask: Task;
  isMonoMission: boolean;

  get lines(): string {
    return this.isMyTask ? 'none' : undefined;
  }

  constructor(private cache: CacheService, private localilzePosition: LocalizePositionService) {}

  ngOnInit() {
    if (this.task.TaskType == TaskTypes.Task) {
      this.parentTask = this.cache.getObjectByUid(this.task.ParentTaskUid);
    }
    this.isMonoMission = this.task.TaskType == TaskTypes.ProjectTask;
  }

  locateMission($event: MouseEvent | TouchEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.localilzePosition.localize({
      coordinates: [this.task.location.coordinate],
      target: null
    });
  }
}
