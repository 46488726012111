import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FetchListService } from '../fetchlist/fetchlist.service';

export interface IBootResult {
  [name: string]: SagaObject[];
}

@Injectable()
export class CacheProxyService {
  private _error: HttpErrorResponse;
  get error(): HttpErrorResponse {
    return this._error;
  }

  constructor(private httpClient: HttpClient, private objectFactory: ObjectFactory, private fetchList: FetchListService) {}

  setup(): Observable<IBootResult> {
    return this.httpClient.get<IBootResult>('api/SagaCache/Setup').pipe(
      map(bootResult => this.parse(bootResult)),
      catchError((err, caught) => this.handleError(err, caught))
    );
  }

  private parse(data: IBootResult): IBootResult {
    const ret = {};
    for (const typeName in data) {
      if (data.hasOwnProperty(typeName) && !this.fetchList.isList(typeName)) {
        ret[typeName] = data[typeName];
      } else if (data.hasOwnProperty(typeName)) {
        data[typeName].map(obj => this.objectFactory.create(obj, obj.$t)).forEach(o => this.fetchList.addObjToCache(o, o.$t));
      }
    }
    return ret;
  }

  private handleError(err: any, caught: Observable<IBootResult>): Observable<IBootResult> {
    this._error = err;
    return of(null);
  }
}
