import { CommonAddinModule } from '@addins/common.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';

import { HttpClientModule } from '@angular/common/http';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { ViewBaseComponent } from './components/view-base/view-base.component';
import { DOCUMENT_ADDIN } from './schema/constants';
import { BrowserDownloaderService } from './services/browser-downloader/browser-downloader.service';
import { DocumentDownloaderService } from './services/document-downloader/document-downloader.service';
import { DocumentProxyService } from './services/document-proxy/document-proxy.service';
import { DocumentService } from './services/document/document.service';
import { DocumentsMenuService } from './services/executables/document-menu/documents-menu.service';
import { FullViewComponent } from './views/full-view/full-view.component';
import { PageViewComponent } from './views/page-view/page-view.component';

const directives = [];

const views = [FullViewComponent, PageViewComponent];

const components = [DocumentListComponent, ViewBaseComponent, ...views];

const executables = [DocumentsMenuService];

const providers = [BrowserDownloaderService, DocumentDownloaderService, DocumentProxyService, DocumentService, ...executables];

@NgModule({
  declarations: [...components, ...directives],
  imports: [CommonModule, FormsModule, HttpClientModule, IonicModule, CommonAddinModule],
  exports: components,
  providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@Addin({
  name: DOCUMENT_ADDIN
})
export class DocumentsModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
