import { PlannedMissionDetailsMenuEntry } from '@addins/planned-missions/src/schema/menu/planned-mission-details-menu-entry';
import { PlannedMissionsMenuEntry } from '@addins/planned-missions/src/schema/menu/planned-missions-menu-entry';
import { Injectable } from '@angular/core';
import { SideMenuService } from '@services/menu/side-menu.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { JournalToolsControllerService } from '../../journal-tools-controller/journal-tools-controller.service';
import { MissionDetailsMenuControllerService } from '../../mission-details-menu-controller/mission-details-menu-controller.service';
import { TasksLayerService } from '../../tasks-layer/tasks-layer.service';
import { TasksProviderService } from '../../tasks-provider/tasks-provider.service';

@AddinExecute({
  name: 'PlannedMissionsSetup'
})
@Injectable({
  providedIn: 'root'
})
export class PlannedMissionsSetupService extends Execute {
  private plannedMissionsMenuEntry: PlannedMissionsMenuEntry = null;
  private plannedMissionDetailsMenuEntry: PlannedMissionDetailsMenuEntry = null;

  constructor(
    private sideMenu: SideMenuService,
    private missionDetailsMenuController: MissionDetailsMenuControllerService,
    private tasksProvider: TasksProviderService,
    private tasksLayer: TasksLayerService,
    private journalToolsController: JournalToolsControllerService
  ) {
    super();
  }

  execute() {
    this.plannedMissionDetailsMenuEntry = new PlannedMissionDetailsMenuEntry();
    this.plannedMissionDetailsMenuEntry.disable();
    this.sideMenu.addMenuEntry(this.plannedMissionDetailsMenuEntry);
    this.missionDetailsMenuController.setup(this.plannedMissionDetailsMenuEntry);

    this.plannedMissionsMenuEntry = new PlannedMissionsMenuEntry();
    this.sideMenu.addMenuEntry(this.plannedMissionsMenuEntry);

    this.tasksProvider.setup();
    this.tasksLayer.init();

    this.journalToolsController.setup();
  }
}
