import { LocationProviderService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Coordinate } from '@models/coordinate';
import { Guid } from '@models/guid';
import { transform } from '@services/map';
import { MyEquipmentService } from '@techwan/ionic-core';
import Sphere from 'ol/sphere';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { MapSettingsService } from '../map-settings/map-settings.service';
import { ProjectionConverterService } from '../projection-converter/projection-converter.service';

@Injectable()
export class MyLocationService {
  private readonly EARTH_RADIUS: number = 6371000;
  private _epsg = 4326;

  private _empty: Coordinate = { x: 0, y: 0, epsg: this.projectionConverter.getProjection(this._epsg) };
  get empty(): Coordinate {
    return this._empty;
  }

  get value(): Coordinate {
    return this._onNewLocation.value;
  }

  private _onNewLocation = new BehaviorSubject<Coordinate>(this.empty);
  get $change(): Observable<Coordinate> {
    // TODO: If the service corresponding to the platform on which the application is running is not started, start it and return the event.
    if (this._newLocationEvent === null) {
      return this.start().pipe(switchMap(() => this._onNewLocation.asObservable()));
    }
    return this._onNewLocation.asObservable();
  }

  /**
   * This is where the listener on the document event "newLocation" will be stored.
   */
  private _newLocationEvent: Subscription = null;

  private _subscriptions: number = 0;

  /**
   * This value is null at startup and set once this service is started and the device GPS gives us the position. Once it is started, this
   * value changes each time the location changes. The change can come from the HTML5 API or the device's GPS plugin.
   * If the current location is required, please consider using getMyLocation which uses the device's location service or the HTML5 API
   * to get the device's location.
   */
  private _mostRecent: Geoposition = null;
  get mostRecent(): Geoposition {
    if (!this._mostRecent) {
      this.locationProvider
        .getCurrent()
        .pipe(
          map(position => this.transform(position)),
          first()
        )
        .subscribe();
    }
    return this._mostRecent;
  }

  constructor(
    private myEquipment: MyEquipmentService,
    private locationProvider: LocationProviderService,
    private mapSettings: MapSettingsService,
    private projectionConverter: ProjectionConverterService
  ) {}

  /**
   * Convert the current position to the given view's projection.
   * @param view Target view on which the position should be shown.
   */
  forView(view: ol.View): ol.Coordinate {
    const pos = this.value;
    return this.projectionConverter.convertToView(view, [pos.x, pos.y], this.projectionConverter.getProjection(pos.epsg));
  }

  getMyLocation(): Observable<Coordinate> {
    return this.getCurrent();
  }

  getCurrent(): Observable<Coordinate> {
    return this.value !== this.empty ? of(this.value) : this.locationProvider.getCurrent().pipe(map(position => this.transform(position)));
  }

  private transform(position: Geoposition = null): Coordinate {
    const value = position !== null ? { x: position.coords.longitude, y: position.coords.latitude, epsg: this.empty.epsg } : this.empty;
    this._mostRecent = position;
    this._onNewLocation.next(value);
    return value;
  }

  setup(): Observable<void> {
    return this.mapSettings.shouldGeolocate ? this.start().pipe(map(() => {})) : of();
  }

  start(): Observable<void> {
    let uid: Guid;
    if (this._newLocationEvent !== null) {
      return;
    }
    this.myEquipment.myDevice
      .subscribe(device => {
        if (device) {
          uid = device.ObjGuid;
        }
      })
      .unsubscribe();
    this._newLocationEvent = this.locationProvider.$location
      .pipe(map(position => this.transform(position)))
      .subscribe(position => this._onNewLocation.next(position));
    return this.locationProvider.start(uid);
  }

  subscribeToPosition(handler: (position: Coordinate) => void): () => void {
    if (this.value) {
      handler(this.value);
    }
    const unsubscribe = this.$change.subscribe(handler);
    this._subscriptions++;
    return () => {
      unsubscribe.unsubscribe();
      this._subscriptions--;
      if (this._subscriptions === 0) {
        this._newLocationEvent.unsubscribe();
        this._newLocationEvent = null;
        this.locationProvider.stop();
      }
    };
  }

  /**
   * Returns the distance in meters between two coordinates using the haversine formula.
   * @param coord1 first coordinate
   * @param coord2 second coordinate, defaults to my units current position
   */
  haversineDistance(coord1: Coordinate, coord2: Coordinate = this.value): number {
    const coord1Normalized: Coordinate = transform(coord1, this.empty.epsg);
    const coord2Normalized: Coordinate = transform(coord2, this.empty.epsg);

    const olFrom: ol.Coordinate = [coord1Normalized.x, coord1Normalized.y];
    const olTo: ol.Coordinate = [coord2Normalized.x, coord2Normalized.y];

    const wgs84Sphere: Sphere = new Sphere(this.EARTH_RADIUS);
    return wgs84Sphere.haversineDistance(olFrom, olTo);
  }
}
