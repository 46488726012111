// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CImpIdMapping_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.CImpIdMapping';
  $t: string = 'SagaSchema.CImpIdMapping';

  public static GetMembers(): string[] {
    return [
      'Id',
      'SourceId',
      'SourceTextId',
      'ImpSourceTableId',
      'DestinationId',
      'DestinationTextId',
      'ImpDestinationTableId',
      'ModificationDate',
      'Uid',
      'DestinationUid'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CImpIdMapping_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ModificationDate = this.ModificationDate && new Date(this.ModificationDate);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public SourceId: number = 0;
  public SourceTextId: string;
  public ImpSourceTableId: number = 0;
  public DestinationId: number = 0;
  public DestinationTextId: string;
  public ImpDestinationTableId: number = 0;
  public ModificationDate: Date = new Date();
  public Uid: Guid = GuidTools.Empty;
  public DestinationUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.Uid;
  }
  get ParentObjId(): any {
    return this.Uid;
  }
  set ParentObjId(value: any) {
    this.Uid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
