import { AdditionalViewToolsService, PanelContent, PanelContentBaseComponent } from '@addins/core/panel';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KeywordSearchObject } from '@models/imported/SagaBase/Objects/KeywordSearchObject';
import { ModalService } from '@services/modal/modal.service';
import { from } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { LocalizePositionService } from '../../services/localize-position/localize-position.service';
import { LocationProxyService } from '../../services/location-proxy/location-proxy.service';
import { MapShowAddressService } from '../../services/map-show-address/map-show-address.service';
import { SearchAddressModalComponent } from '../search-address-modal/search-address-modal.component';

@Component({
  selector: 'app-search-address-panel-content',
  templateUrl: './search-address-panel-content.component.html',
  styleUrls: ['./search-address-panel-content.component.scss']
})
export class SearchAddressPanelContentComponent extends PanelContentBaseComponent implements OnInit, PanelContent {
  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    private additionalViewTools: AdditionalViewToolsService,
    private mapShowAddress: MapShowAddressService,
    private localizePositionService: LocalizePositionService,
    private locationProxy: LocationProxyService
  ) {
    super();
  }

  ngOnInit() {
    this.modalController.create({ component: SearchAddressModalComponent }).then(modal => {
      modal.present().then(() =>
        modal.onDidDismiss().then(result => {
          const searchAddressTool = this.additionalViewTools.getTools().filter(tool => tool.isActive)[0];
          searchAddressTool.click(null);

          if (result.data) {
            this.onAddressSelected(result.data);
          }
        })
      );
    });
  }

  private onAddressSelected(keywordSearchObject: KeywordSearchObject) {
    from(this.modalService.presentLoading())
      .pipe(
        switchMap(() => this.locationProxy.getLocationDetails(keywordSearchObject)),
        map(location => {
          this.mapShowAddress.showAddress(location);
          this.localizePositionService.localize({
            coordinates: [location.coordinate],
            target: null
          });
        }),
        finalize(() => this.modalService.dismissLoading())
      )
      .subscribe();
  }
}
