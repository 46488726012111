// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmsStampHistory_abstract extends SagaObject {
  static $t: string = 'SagaSchema.AlarmsStampHistory';
  $t: string = 'SagaSchema.AlarmsStampHistory';

  public static GetMembers(): string[] {
    return [
      'ParentObjId',
      'ChangeRequestSource',
      'TransmitterId',
      'DetectorId',
      'Identifier',
      'Text',
      'LocationText',
      'StampTypeDescription',
      'StatusDescription'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmsStampHistory_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ChangeRequestSource: string;
  public TransmitterId: number = 0;
  public DetectorId: number = 0;
  public Identifier: string;
  public Text: string;
  public LocationText: string;
  public StampTypeDescription: string;
  public StatusDescription: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
