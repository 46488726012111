import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PhoneCreateEventRes } from '@models/imported/SagaBase/PhoneCreateEventRes';
import { TranslateService } from '@ngx-translate/core';
import { SagaSettingsService } from '@services/settings';
import * as moment from 'moment';

@Injectable()
export class ResultParserService {
  constructor(private toastController: ToastController, private translate: TranslateService, private settings: SagaSettingsService) {}

  parse(result: PhoneCreateEventRes, last: number) {
    let toastMessage: string;
    let toastColor: string;

    if (result.executed === true) {
      toastMessage = `${this.translate.instant('Mobile.EventCreatedWithLastKnownPosition')} ${moment(last).format(
        this.settings.getValue('SagaMobileWebClient.DateFormat')
      )}`;
      toastColor = 'success';
    } else {
      toastMessage = `${this.translate.instant('Mobile.ServerError')}: ${this.translate.instant('Mobile.UnableToCreateAlert')}`;
      toastColor = 'danger';
    }

    this.showToast(toastMessage, toastColor);
  }

  private showToast(message: string, color?: string) {
    this.toastController.create({ message, duration: 5000, color }).then(toast => toast.present());
  }
}
