// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class KeywordSearchDef_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Objects.KeywordSearchDef';
  $t: string = 'SagaBase.Objects.KeywordSearchDef';

  public static GetMembers(): string[] {
    return [
      'ApplyModifier',
      'IDField',
      'LinktableID',
      'Name',
      'GroupItemField',
      'GroupHeaderField',
      'Grouping',
      'SelectCommand',
      'ObjectType',
      'ChildDefinition',
      'ChildWidth',
      'HasDynamicFilters',
      'Selected'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return KeywordSearchDef_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ApplyModifier: string = '';
  public IDField: string;
  public LinktableID: number = 0;
  public Name: string;
  public GroupItemField: string;
  public GroupHeaderField: string;
  public Grouping: number = 0;
  public SelectCommand: string;
  public ObjectType: string;
  public ChildDefinition: string;
  public ChildWidth: number = -1;
  public HasDynamicFilters: boolean = false;
  public Selected: boolean = true;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
