// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class MapSymbolDefinition_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Objects.MapSymbolDefinition';
  $t: string = 'SagaBase.Objects.MapSymbolDefinition';

  public static GetMembers(): string[] {
    return ['MapSymbolTypeId', 'Action', 'Name', 'Visible', 'ImageData'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return MapSymbolDefinition_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public MapSymbolTypeId: number = 0;
  public Action: SagaBase.Defines.MapSymbolActionType = SagaBase.Defines.MapSymbolActionType.POI;
  public Name: string;
  public Visible: boolean = false;
  public ImageData: any[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
