<ion-header>
  <ion-toolbar>
    <ion-title>
      <span translate>Mobile.SetupUnit</span>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div #content></div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        *ngFor="let tabButton of tabButtonsArray"
        (click)="setContent(tabButton.itemType)"
        [class.selected]="selection === tabButton.itemType"
        color="dark"
      >
        <div class="ion-text-wrap" translate>{{ tabButton.itemName }}</div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
