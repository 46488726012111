<div class="resize-backdrop {{direction}}" #resizeElement [hidden]="resizeLinePosition === null">
    <div class="resize-line {{direction}}" [ngStyle]="resizeLinePosition">
        <div class="splitter-button {{direction}}">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </div>
    </div>
</div>
<div class="splitter-button {{direction}}" (mousedown)="start($event)" (touchstart)="start($event)" [hidden]="resizeLinePosition !== null">
    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
</div>
