// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CTacticalSituationBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CTacticalSituationBase';
  $t: string = 'SagaSchema.CTacticalSituationBase';

  public static GetMembers(): string[] {
    return [
      'GeoFenceType',
      'CallCardId',
      'TacticalSituation',
      'GeometryFormat',
      'IsFrozen',
      'GeometryObjects',
      'GeometryObjectsOld',
      'IsLocked',
      'IsPrivate',
      'Description',
      'CoordEpsgCode',
      'CreatorId',
      'OriginalTacticalUid',
      'UserEditingId',
      'UserEditingName'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CTacticalSituationBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GeoFenceType: SagaBase.GeoFenceTypes = SagaBase.GeoFenceTypes.None;
  public CallCardId: Guid = GuidTools.Empty;
  public TacticalSituation: string = '';
  public GeometryFormat: SagaBase.GeometryFormats = SagaBase.GeometryFormats.Default;
  public IsFrozen: boolean = false;
  public GeometryObjects: string = '';
  public GeometryObjectsOld: string;
  public IsLocked: boolean = false;
  public IsPrivate: boolean = false;
  public Description: string = '';
  public CoordEpsgCode: string;
  public CreatorId: number = 0;
  public OriginalTacticalUid: Guid = GuidTools.Empty;
  public UserEditingId: number;
  public UserEditingName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
