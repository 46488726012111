// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:20
import { Coordinate } from '@models/coordinate';
import { ILocation } from '@models/interfaces/ILocation';
import { Address_abstract } from './../abstract/SagaBase/Address_abstract';
export class Address extends Address_abstract implements ILocation {
  get coordinate(): Coordinate {
    return {
      x: this.GeoPoint.X,
      y: this.GeoPoint.Y,
      epsg: this.CoordEpsgCode
    };
  }
}
