import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CameraComponent } from '../../components/camera/camera.component';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { MediaType } from '../../schema/media/media-type';

@Injectable({
  providedIn: 'root'
})
export class CameraProviderService {
  constructor(private modalCtrl: ModalController) {}

  /**
   * Shows the camera component view to select a file and preview a file.
   */
  showCamera(mediaType: MediaType): Promise<ICustomFile> {
    const componentProps = { type: mediaType };
    const options = { component: CameraComponent, componentProps };
    return this.modalCtrl.create(options).then(modal => modal.present().then(() => modal.onDidDismiss().then(result => result.data)));
  }
}
