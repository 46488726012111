import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { SagaSettingsService } from '@services/settings';

class SystemProjection {
  value: string;
  code: number;
  constructor(value: string, code: number) {
    this.value = value;
    this.code = code;
  }
}

@Injectable()
export class MapSettingsService {
  private _projection: SystemProjection = null;
  get projection(): SystemProjection {
    if (this._projection === null) {
      this._projection = this.parse();
    }
    return this._projection;
  }

  get shouldGeolocate(): boolean {
    return this._settings.getValue('SagaMobileWebClient.GPSEnabled') || true;
  }

  get positionPolling(): number {
    return this._settings.getValue('SagaMobileWebClient.MapMobilePositionPollingInterval') || 10;
  }

  centerOnUnit: boolean = true;
  centerCoords: Coordinate = null;
  resolution: any = null;
  baseLayerId: string = null;
  poiLayersVisibility: Map<string, boolean> = new Map();
  showScaleChoice: boolean = true;

  constructor(private _settings: SagaSettingsService) {}

  getSystemProjection(): string {
    return this.projection.value;
  }

  getSystemEpsg(): number {
    return this.projection.code;
  }

  parse(value: string = this._settings.getValue('System.GeoCoordinates')): SystemProjection {
    const code = value !== null && parseInt(/\d+/.exec(value)[0], 10);
    return new SystemProjection(value, code);
  }
}
