<ion-content class="padding">
  <loading-bar *ngIf="isLoading"></loading-bar>
  <app-document-list *ngIf="documents" [documents]="documents"></app-document-list>
  <div class="error" *ngIf="error !== null">
    <h1 translate>{{error.type}}</h1>
    <p translate>{{error.message}}</p>
    <p translate>{{error.error}}</p>
  </div>
  <div *ngIf="isEmpty" translate>Mobile.NoDocuments</div>
</ion-content>
