// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class UnitActivityLog_abstract extends SagaObject {
  static $t: string = 'SagaSchema.UnitActivityLog';
  $t: string = 'SagaSchema.UnitActivityLog';

  public static GetMembers(): string[] {
    return ['ActivityUid', 'Action', 'Field', 'Value', 'ResourceId', 'ResType', 'ServiceId', 'TraceId', 'StampType'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return UnitActivityLog_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ActivityUid: Guid = GuidTools.Empty;
  public Action: string;
  public Field: string;
  public Value: string;
  public ResourceId: Guid = GuidTools.Empty;
  public ResType: SagaBase.ResourceType = SagaBase.ResourceType.Undefined;
  public ServiceId: Guid = GuidTools.Empty;
  public TraceId: Guid = GuidTools.Empty;
  public StampType: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ActivityUid;
  }
  set ParentObjId(value: any) {
    this.ActivityUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
