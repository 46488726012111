import { Injectable, Type } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { TranslateService } from '@ngx-translate/core';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { Observable, Subject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UnitActivityEditorComponent } from '../../components/unit-activity-editor/unit-activity-editor.component';

type SelectFunction = (name: string) => Observable<any>;
export interface IUnitSelector {
  create?: Type<any>;
  select?: SelectFunction;
  disableDissociate?: boolean;
}

@Injectable()
export class UnitSelectorService {
  private _deviceIdentifier: string = null;

  private readonly _buttons = [];
  private _result: Subject<UnitActivity> = null;
  private _htmlIAS: HTMLIonActionSheetElement = null;

  private _selector: IUnitSelector = null;

  canCreateUnit: boolean = false;

  get disableDissociate() {
    return this._selector ? this._selector.disableDissociate : true;
  }

  constructor(
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private unitActivityService: UnitActivityService
  ) {}

  useCustom(selector: IUnitSelector) {
    this._selector = selector;
  }

  setCanCreate(deviceIdentifier: string) {
    this._deviceIdentifier = deviceIdentifier;
    this.canCreateUnit = this._deviceIdentifier && this._deviceIdentifier !== null;
  }

  reset() {
    this.canCreateUnit = false;
    this._deviceIdentifier = null;
    if (this._result !== null) {
      this._result = null;
      this._htmlIAS.dismiss().then(() => (this._htmlIAS = null));
    }
  }

  setup(deviceIdentifier: string): Observable<UnitActivity> {
    this._deviceIdentifier = deviceIdentifier;
    return this._result === null ? this.internalSetup() : this._result;
  }

  private internalSetup(): Observable<UnitActivity> {
    const options = {
      header: this.translate.instant('static.login.unit.nounit'),
      buttons: this._buttons
    };
    this._result = new Subject<UnitActivity>();
    this.init();

    return from(this.actionSheetController.create(options).then(ionActionSheet => (this._htmlIAS = ionActionSheet).present())).pipe(
      switchMap(() => this._result.asObservable())
    );
  }

  private init() {
    this._buttons.length = 0;
    this.addCreateButton();

    if (this._selector && this._selector.select) {
      this.addSelectButton();
    }

    if (this._buttons.length > 0) {
      this._buttons.push({
        text: this.translate.instant('static.login.unit.cancel'),
        role: 'cancel',
        handler: () => this.onCancel()
      });
    }
  }

  private onCancel() {
    this._result = null;
  }

  private addCreateButton() {
    const button = this.createButton('static.login.unit.Create');
    button.handler = () => this.onCreate();
    this.addButton(button);
  }

  private addSelectButton() {
    const button = this.createButton('static.login.unit.Select');
    button.handler = () => this.onSelect();
    this.addButton(button);
  }

  private createButton(key: string) {
    return {
      text: this.translate.instant(key),
      handler: null
    };
  }

  private addButton(button) {
    this._buttons.push(button);
  }

  onCreate(): Promise<boolean> {
    const component = this.getCreateComponent();
    return this.unitActivityService.openCreateUnit(component, { identifier: this._deviceIdentifier }).then(unitActivity => {
      // when unit was truthy, associate was called here
      if (this._htmlIAS !== null) {
        this._htmlIAS.dismiss().then(() => {
          this._result.next(unitActivity);
          if (!unitActivity) {
            this.onCancel();
          }
        });
      }
      return unitActivity ? true : false;
    });
  }

  getCreateComponent() {
    return this._selector && this._selector.create ? this._selector.create : UnitActivityEditorComponent;
  }

  private onSelect(): boolean {
    this._selector.select(this._deviceIdentifier).subscribe(unitActivity => {
      if (this._htmlIAS !== null) {
        this._htmlIAS.dismiss().then(() => {
          this._result.next(unitActivity);
          if (!unitActivity) {
            this.onCancel();
          }
        });
      }
    });
    return true;
  }
}
