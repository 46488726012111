import { ICardInfoFormatter } from './interfaces/card-info-formatter';
import { MomentService } from '@techwan/ionic-core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

export class DefaultCardInfoFormatter implements ICardInfoFormatter {
  constructor(private readonly _moment: MomentService) {}

  format(card: CallCard): string[] {
    return [this._moment.formatDate(card.SubmittedUTC, 'LLLL')];
  }
}
