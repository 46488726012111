// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class TextMessage_abstract extends BaseObject {
  static $t: string = 'SagaBase.TextMessage';
  $t: string = 'SagaBase.TextMessage';

  public static GetMembers(): string[] {
    return [
      'JsonData',
      'Url',
      'Protocol',
      'ShowProtocols',
      'CardUid',
      'CardOwner',
      'ImageName',
      'ShowImage',
      'ImageData',
      'ShowTargets',
      'PreselectedTexts',
      'CustomPart',
      'UserId',
      'To',
      'ToName',
      'CallcardId',
      'Event',
      'Location',
      'Caller',
      'Attachments',
      'Info',
      'RecallNumber',
      'RecallDelay',
      'Status',
      'Subject',
      'Complement',
      'IsComplexMessage',
      'Prefix',
      'LocationFirst',
      'UseCustomPart',
      'Header',
      'FullText'
    ];
  }

  public GetMembers(): string[] {
    return TextMessage_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public JsonData: string;
  public Url: string;
  public Protocol: SagaBase.Defines.ComProtocol = SagaBase.Defines.ComProtocol.None;
  public ShowProtocols: boolean = false;
  public CardUid: Guid = GuidTools.Empty;
  public CardOwner: Guid = GuidTools.Empty;
  public ImageName: string;
  public ShowImage: boolean = false;
  public ImageData: any[];
  public ShowTargets: boolean = true;
  public PreselectedTexts: SagaBase.PreselectText;
  public CustomPart: string;
  public UserId: number = 0;
  public To: string;
  public ToName: string;
  public CallcardId: string;
  public Event: string;
  public Location: string;
  public Caller: string;
  public Attachments: any[];
  public Info: string;
  public RecallNumber: string;
  public RecallDelay: number = 0;
  public Status: string;
  public Subject: string;
  public Complement: string;
  public IsComplexMessage: boolean = false;
  public Prefix: string;
  public LocationFirst: boolean = false;
  public UseCustomPart: boolean = false;
  public Header: string = '';
  public FullText: string = '';

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
