// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class AtomicOperation_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.AtomicOperation';
  $t: string = 'SagaBase.Defines.AtomicOperation';

  public static GetMembers(): string[] {
    return [
      'obj',
      'action',
      'recursive',
      'siteId',
      'sharedOperation',
      'siteOnlyBroadcast',
      'generated',
      'broadcastOnly',
      'forceToChache',
      'doNotAudit'
    ];
  }

  public GetMembers(): string[] {
    return AtomicOperation_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public obj: any;
  public action: SagaBase.OperationType = SagaBase.OperationType.Submit;
  public recursive: boolean = false;
  public siteId: number = 0;
  public sharedOperation: boolean = true;
  public siteOnlyBroadcast: boolean = false;
  public generated: boolean = false;
  public broadcastOnly: boolean = false;
  public forceToChache: boolean = false;
  public doNotAudit: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
