import { CardTitleFormatterService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Component({
  selector: 'app-callcard-list-title',
  templateUrl: './callcard-list-title.component.html',
  styleUrls: ['./callcard-list-title.component.scss']
})
export class CallcardListTitleComponent implements OnInit {
  @Input()
  callcard: CallCard;

  callcardTitle: string;

  constructor(private titleFormatter: CardTitleFormatterService) {}

  ngOnInit() {
    this.callcardTitle = this.titleFormatter.format(this.callcard);
  }
}
