import { Injectable, Type } from '@angular/core';
import { AddinService, MyEquipmentService } from '@techwan/ionic-core';
import { UnitActivitySelectorComponent, IUnitSelector } from '@addins/core/unit-activity';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CommunicationEquipmentType } from '@models/imported/SagaBase';
import { from, Observable, of } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { map, switchMap } from 'rxjs/operators';
import { ListNameService } from '@services/list-name/list-name.service';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { CommunicationEquipmentAbstract } from '@techwan/ionic-core/lib/schema/imported/abstract/SagaSchema/CommunicationEquipment_abstract';
import { IUpdateRequestArgs, UnitActivityProxyService } from '@services/unit-activity-proxy/unit-activity-proxy.service';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { CommunicationEquipment_abstract } from '@models/imported/abstract/SagaSchema/CommunicationEquipment_abstract';

export interface ImeiAlias {
  RegistrationName: string;
  ImeiAlias: string;
  Type: CommunicationEquipmentType;
}

@Injectable()
export class UnitActivitySelectorService implements IUnitSelector {
  private _name: string;

  private _device: CommunicationEquipmentAbstract;

  get create(): Type<any> {
    const addin = this.addins.resolveAddinView('PnAddin/UnitActivityEditor');
    return addin.element;
  }

  disableDissociate = true;

  constructor(
    private readonly addins: AddinService,
    private readonly modalCtrl: ModalController,
    private readonly modalService: ModalService,
    private readonly listNameService: ListNameService,
    private readonly myEquipment: MyEquipmentService,
    private readonly unitProxy: UnitActivityProxyService,
    private readonly refreshTokenStorage: RefreshTokenStorageService
  ) {}

  select(name: string): Observable<UnitActivity> {
    const component = UnitActivitySelectorComponent;
    this._name = name;
    // TODO: @deprecated please use ModalFactoryService instead
    return from(this.modalCtrl.create({ component })).pipe(switchMap(m => this.init(m)));
  }

  private init(m: HTMLIonModalElement): Observable<UnitActivity> {
    return this.myEquipment.myDevice.pipe(
      switchMap(d => {
        this._device = d;
        return from(m.present().then(() => m.onDidDismiss().then(r => this.onDismiss(r).toPromise())));
      })
    );
  }

  private onDismiss(res): Observable<UnitActivity> {
    const selectedUA: UnitActivity = res.data;
    return selectedUA
      ? from(this.modalService.presentLoading('SagaMobile.UA.Associating')).pipe(
          switchMap(() => this.getUnit(selectedUA)),
          map(u => this.parse(u))
        )
      : of(null);
  }

  private parse(u: UnitActivity) {
    if (!u) {
      this.modalService.dismissLoading().then();
    }
    return u;
  }

  private getUnit(selectedUA: UnitActivity): Observable<UnitActivity> {
    const updateRequest: IUpdateRequestArgs = {
      activityUid: selectedUA.ObjGuid,
      addedVehicles: [],
      addedEquipments: [],
      removedVehicles: [],
      removedEquipments: []
    };
    const alias = this._name;
    const owner = this.refreshTokenStorage.read().login.SagaUserAuth.SiteId;
    if (this._device) {
      updateRequest.addedEquipments.push((this._device as any) as CommunicationEquipment_abstract);
    }

    return !this._device
      ? from(this.listNameService.getList<ListNameValue<ImeiAlias>>('RfgiImei', '', owner)).pipe(
          map(r => r.find(v => v.properties.ImeiAlias === alias)),
          switchMap(r =>
            this.unitProxy.associateWithIdentifier({
              UnitActivityId: selectedUA.ObjGuid,
              Identifier: r.DisplayValue,
              Name: selectedUA.mainRessource.Name
            })
          ),
          map(r => (r ? selectedUA : null))
        )
      : this.unitProxy.updateUnitActivity(updateRequest);
  }
}
