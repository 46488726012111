import { Injectable } from '@angular/core';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { transformOL } from '@services/map';
import { Image, ImageFeature, Map as SagaMap } from '@techwan/mapping';
import Icon from 'ol/style/icon';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureStyleService } from '../feature-style/feature-style.service';
import { MapConfigurationService } from '../map-configuration/map-configuration.service';
import { MapSettingsService } from '../map-settings/map-settings.service';

@Injectable()
export class DeploymentFeatureFactoryService {
  private _icon: Icon = null;

  constructor(
    private mapConfiguration: MapConfigurationService,
    private mapSettings: MapSettingsService,
    private featureStyle: FeatureStyleService
  ) {}

  setup(): Observable<Icon> {
    return this.mapConfiguration.getDeploymentIcon().pipe(tap(icon => (this._icon = icon)));
  }

  fromGeometry(geometry: any, map: SagaMap): ImageFeature {
    const image = new ImageFeature(geometry, map);

    image.setImageUrl(this._icon.getSrc());
    image.setImageScale(this._icon.getScale());
    image.setImageSize(this._icon.getSize());

    return image;
  }

  fromDeployment(deploymentPoint: CDeploymentPoint, map: SagaMap, isMine: boolean = false): ImageFeature {
    const dpCoordinate: ol.Coordinate = this.calculateDPCoordinate(deploymentPoint, map.getView().getProjection());
    const image = new Image(dpCoordinate, map);
    const imageFeature = this.fromGeometry(image, map);

    imageFeature.set('id', deploymentPoint.ObjGuid);
    imageFeature.set('label', deploymentPoint.Name);
    imageFeature.set('isDeploymentPoint', true);

    const unitIds: string[] = (deploymentPoint.UnitUids && deploymentPoint.UnitUids.split(',').filter(uid => uid)) || [];
    const labelText: string = unitIds.length ? `${deploymentPoint.Name} (${unitIds.length})` : `${deploymentPoint.Name}`;
    const labelColor: string = isMine ? 'orange' : 'red';

    this.featureStyle.createLabel(imageFeature, labelText, labelColor);

    return imageFeature;
  }

  private calculateDPCoordinate(deploymentPoint: CDeploymentPoint, mapProjection: any): ol.Coordinate {
    let dpProjection: string = deploymentPoint.CoordEpsgCode;
    if (!dpProjection) {
      dpProjection = this.mapSettings.getSystemProjection();
      console.error(`Deployment Point (name: ${deploymentPoint.Name}, id: ${deploymentPoint.ObjGuid}) has NULL Projection Code!)`);
    }
    return transformOL([deploymentPoint.CenterX, deploymentPoint.CenterY], dpProjection, mapProjection);
  }
}
