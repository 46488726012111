import { Injectable } from '@angular/core';
import { SagaSettingsService } from '@services/settings';

@Injectable()
export class DownloadUrlTransformService {
  constructor(private sagaSettings: SagaSettingsService) {}

  private readonly FILE_GUID_REGEX: RegExp = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/i;

  // URL format is saga+file:///{fileUid}?{optionalQueryParameters}
  transform(url: string): string {
    const fileApiPath: string = this.sagaSettings.getValue('FileController.FileApiPath');
    const fileGuid: string = this.FILE_GUID_REGEX.test(url) ? url.match(this.FILE_GUID_REGEX)[0] : '';
    const optionsIndex = url.indexOf('?');
    const fileOptions: string = optionsIndex > 0 ? url.substring(optionsIndex) : '';

    return `${fileApiPath}/${fileGuid}/Download${fileOptions}`;
  }
}
