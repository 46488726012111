<ion-header>
  <ion-toolbar color="primary ion-text-center">
    <ion-menu-toggle *ngIf="displayMenu(); else logo" menu="start" slot="start">
      <ion-button fill="clear" color="light"><ion-icon slot="icon-only" size="large" name="menu"></ion-icon></ion-button>
    </ion-menu-toggle>
    <ng-template #logo>
      <img slot="start" alt="" src="assets/logo.png" />
    </ng-template>

    <ion-title>
      <span translate>{{ name }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen scrollY="false" class="padding content" layout="column">
  <ion-chip *ngIf="!booted">
    <ion-spinner></ion-spinner>
    <ion-label translate="SagaMobile.Cache.Loading"></ion-label>
  </ion-chip>

  <ion-card class="unit with-map" (click)="openMyUnit()" *ngIf="unitActivity">
    <ion-item>
      <ion-icon [icon]="unitActivity.icon" [color]="unitActivity.color.toRgbString()" name="car" slot="start" size="large"></ion-icon>
      <ion-label>
        <h2>{{ unitActivity.rShort() }}</h2>
        <p [style.color]="unitActivity.color.toRgbString()" style="white-space: initial;">
          <span [translate]="unitActivity.status.Name"></span>
        </p>
      </ion-label>
    </ion-item>
  </ion-card>

  <ion-card class="callcard with-map" (click)="openMyCard()" *ngIf="unitActivity && unitActivity.callcard">
    <ion-card-header>
      <ion-card-title color="primary"><span translate>SagaMobile.MyCard</span></ion-card-title>
    </ion-card-header>
    <ion-item>
      <ion-icon [style.color]="unitActivity.callcard.color.toRgbString()" name="document" slot="start" size="large"></ion-icon>

      <ion-label>
        <h2>{{ unitActivity.callcard.CreatedUTC | moment: 'LLLL' }}</h2>
        <p style="white-space: initial;">{{ unitActivity.callcard.rShort() }}</p>
        <p style="white-space: initial;" *ngIf="unitActivity.callcard.event">{{ unitActivity.callcard.event.EventText }}</p>
        <p style="white-space: initial;" *ngIf="unitActivity.callcard.location">
          {{ unitActivity.callcard.location.FormattedLocation }}
        </p>
      </ion-label>
    </ion-item>
    <ion-card-header *ngIf="unitActivity.callcard.hasDatabaseChanged || myCardChanged" color="danger">
      <ion-card-subtitle><span translate>SagaMobile.MyCard.Changed</span></ion-card-subtitle>
    </ion-card-header>
  </ion-card>

  <div class="full bottom">
    <div layout="row" class="actions">
      <ion-button [style.flex]="isTablet ? 1 : 0" class="disconnect" fill="outline" (click)="showSettings()">
        <ion-icon slot="icon-only" name="settings"></ion-icon>
      </ion-button>
      <ion-button *ngIf="!isTablet" flex class="disconnect" fill="outline" (click)="openMap()">
        <ion-icon name="map" slot="start"></ion-icon>
        <span translate="SagaMobile.Map"></span>
      </ion-button>
    </div>
    <ion-button class="disconnect" expand="block" color="danger" fill="outline" (click)="logout()">
      <div translate="SagaMobile.Disconnect"></div>
    </ion-button>
  </div>
</ion-content>
