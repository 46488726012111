// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class FileChunk_abstract extends SagaObject {
  static $t: string = 'SagaSchema.FileChunk';
  $t: string = 'SagaSchema.FileChunk';

  public static GetMembers(): string[] {
    return ['Number', 'Size', 'TotalSize', 'FileUid', 'Name', 'TotalChunks', 'Version'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return FileChunk_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Number: number = 0;
  public Size: number = 0;
  public TotalSize: number = 0;
  public FileUid: Guid = GuidTools.Empty;
  public Name: string;
  public TotalChunks: number = 0;
  public Version: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
