import { Component, Input } from '@angular/core';
import { CLocation } from '@models/imported/SagaSchema/CLocation';

@Component({
  selector: 'app-callcard-list-location',
  templateUrl: './callcard-list-location.component.html',
  styleUrls: ['./callcard-list-location.component.scss']
})
export class CallcardListLocationComponent {
  @Input()
  location: CLocation;
}
