import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import Sphere from 'ol/sphere';
import ol from 'ol';
import { transform } from '@services/map';
import { MyLocationService } from '../my-location/my-location.service';

@Injectable()
export class GeofenceService {
  constructor(private readonly _myLocation: MyLocationService) {}

  trackPosition(position: Coordinate, radius: number, handler: Function): Function {
    const currentCoordinate: Coordinate = { x: 0, y: 0, epsg: '4326' };
    return this._myLocation.subscribeToPosition(p => {
      currentCoordinate.x = p.x;
      currentCoordinate.y = p.y;

      const pos1 = transform(position, '4326');
      const pos2 = transform(currentCoordinate, '4326');

      const olPos1 = [pos1.x, pos1.y];
      const olPos2 = [pos2.x, pos2.y];

      const sphere = new Sphere(6378137);
      const distance = sphere.haversineDistance(olPos1 as ol.Coordinate, olPos2 as ol.Coordinate);
      if (distance <= radius) {
        handler(distance);
      }
    });
  }
}
