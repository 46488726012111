import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ImagesSettingsService, SagaSettingsService } from '@services/settings';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { MediaType } from '../../schema/media/media-type';

@Injectable()
export class ThumbnailService {
  private THUMBNAIL_MIME_TYPE: string = 'image/jpeg';

  constructor(private _settingsService: SagaSettingsService, private _imagesService: ImagesSettingsService, private _platform: Platform) {}

  createThumbnail(customFile: ICustomFile, mediaType: MediaType): Promise<string> {
    switch (mediaType) {
      case MediaType.Picture:
      case MediaType.File:
        return this.createImageThumbnail(customFile);

      case MediaType.Video:
        return this.createVideoThumbnail();
    }
  }

  private createImageThumbnail(customFile: ICustomFile): Promise<string> {
    const newWidth: number = this._settingsService.getValue('SagaMobileWebClient.ThumbnailWidth');
    const newHeight: number = this._settingsService.getValue('SagaMobileWebClient.ThumbnailHeight');
    const quality: number = this._settingsService.getValue('SagaMobileWebClient.ThumbnailQuality');
    let imageSrc: string;
    if (!this._platform.is('cordova')) {
      imageSrc = customFile.url;
    } else {
      imageSrc = (window as any).Ionic.WebView.convertFileSrc(customFile.uri);
    }

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const element = document.createElement('canvas');
        element.width = newWidth;
        element.height = newHeight;
        const ctx = element.getContext('2d');
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        resolve(ctx.canvas.toDataURL(this.THUMBNAIL_MIME_TYPE, quality));
      };
      image.onerror = error => reject(error);
    });
  }

  private createVideoThumbnail(): Promise<string> {
    return Promise.resolve(this._imagesService.get('VideoAttachments'));
  }
}
