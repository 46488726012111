import { Injectable } from '@angular/core';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { transform } from '@services/map';
import { Destination } from '@addins/external-itinerary/schema/destination';

@Injectable({
  providedIn: 'root'
})
export class WazeProviderService implements INavigationProvider {
  private readonly _epsg = '4326';

  constructor(private readonly window: WindowOpenerService) {}

  public navigate(destination: Destination): Window {
    if (destination.location.epsg !== this._epsg) {
      destination.location = transform(destination.location, `EPSG:${this._epsg}`);
    }
    return this.window.system(`waze://?ll=${destination.location.y},${destination.location.x}&navigate=yes`);
  }
}
