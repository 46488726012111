import { Component, ViewChild } from '@angular/core';
import { List } from '@models/list';
import { ModalController, NavParams } from '@ionic/angular';

/**
 * @name EventSelectComponent
 * @description
 * The event selection component
 */
@Component({
  templateUrl: 'eventSelect.html'
})
export class EventSelectComponent {
  @ViewChild('searchBar') searchInput;

  static elements: List<any> = new List<any>();

  currentElement: number;
  newElement: number;

  searchTxt: string;

  constructor(private viewCtrl: ModalController, private params: NavParams) {
    this.currentElement = params.get('id');
    this.newElement = this.currentElement;

    EventSelectComponent.elements = new List<any>(params.get('source'));
    EventSelectComponent.elements.addFilter(c => !this.searchTxt || c.id === this.newElement || c.search(this.searchTxt).success);
    EventSelectComponent.elements.addSort((a, b) => {
      if (a.id === this.newElement) {
        return -1;
      } else if (b.id === this.newElement) {
        return 1;
      } else {
        return a.text.localeCompare(b.text);
      }
    });
  }

  radioGroupChange($event) {
    this.newElement = $event.detail.value;
  }

  filterElements(query: string) {
    this.searchTxt = query ? query.trim() : '';
    if (EventSelectComponent.elements) {
      EventSelectComponent.elements.refresh();
    }
  }

  close() {
    return this.viewCtrl.dismiss();
  }

  canSave() {
    return !!this.newElement;
  }

  save() {
    return this.viewCtrl.dismiss(this.newElement);
  }

  get elements() {
    return EventSelectComponent.elements.items;
  }
}
