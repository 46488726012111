import { UnitActivityAddinName } from '@addins/core/core';
import { LayerControllerService as Controller } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UnitsLayerService } from '../../units-layer/units-layer.service';

@AddinExecute({
  name: 'layers-controller',
  description: 'No description'
})
@Injectable()
export class LayerControllerService extends Execute {
  private _sub: Subscription = null;

  constructor(
    private readonly _controller: Controller,
    private readonly _units: UnitsLayerService,
    private readonly _myUnit: MyUnitService,
    private readonly _profileService: ProfileService,
    private readonly _profileReaderService: ProfileReaderService
  ) {
    super();
  }

  execute(): void {
    this._profileService.ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        const disableUnitLayer = this._profileReaderService.getComponentConfig(UnitActivityAddinName, 'disableUnitLayer', false);
        if (!disableUnitLayer) {
          this._controller.addController(this._units);
          this._myUnit.$unitChanged.subscribe(u => this.setup(u));
        }
      });
  }

  private setup(u?: UnitActivity) {
    if (u !== null && this._sub === null) {
      this.refresh();
      this._sub = u.$changed.subscribe(e => this.refresh());
    } else if (u === null && this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  private refresh() {
    if (this._myUnit.isEngaged()) {
      this._units.start();
    } else {
      this._units.stop();
    }
  }
}
