import { CommonAddinModule } from '@addins/common.module';
import { CardTitleFormatterService } from '@addins/core/core';
import { SingleCallCardModule } from '@addins/core/singlecallcard';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LocationFormatterFactoryService } from '@services/location/location-formatter-factory/location-formatter-factory.service';
import { Addin, AddinModule, IonicCoreModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../modules/shared/shared.module';
import { CallcardListBannerItemComponent } from './components/callcard-list-banner-item/callcard-list-banner-item.component';
import { CallCardListBaseComponent } from './components/callcard-list-base/callcard-list-base.component';
import { CallcardListCustomContentComponent } from './components/callcard-list-custom-content/callcard-list-custom-content.component';
import { CallcardListCustomFiltersComponent } from './components/callcard-list-custom-filters/callcard-list-custom-filters.component';
import { CallcardListEventComponent } from './components/callcard-list-event/callcard-list-event.component';
import { CallcardListFilterComponent } from './components/callcard-list-filter/callcard-list-filter.component';
import { CallcardListHeaderComponent } from './components/callcard-list-header/callcard-list-header.component';
import { CallCardListItemComponent } from './components/callcard-list-item/callcard-list-item.component';
import { CallcardListLocationComponent } from './components/callcard-list-location/callcard-list-location.component';
import { CallcardListTitleComponent } from './components/callcard-list-title/callcard-list-title.component';
import { CallCardsProviderService } from './services/callcards-provider/callcards-provider.service';
import { CustomFiltersSetupService } from './services/executables/custom-filters-setup/custom-filters-setup.service';
import { MenuService } from './services/executables/menu/menu.service';
import { HeaderProviderService } from './services/header-provider/header-provider.service';
import { MenuEntryControllerService } from './services/menu-entry-controller/menu-entry-controller.service';
import { CallcardListBannerComponent } from './views/callcard-list-banner/callcard-list-banner.component';
import { CallcardListFullComponent } from './views/callcard-list-full/callcard-list-full.component';
import { CallcardListPriorityFilterComponent } from './views/callcard-list-priority-filter/callcard-list-priority-filter.component';

const views = [CallcardListFullComponent, CallcardListBannerComponent, CallCardListBaseComponent];

const services = [
  CallCardsProviderService,
  CardTitleFormatterService,
  MenuService,
  CustomFiltersSetupService,
  LocationFormatterFactoryService,
  HeaderProviderService,
  MenuEntryControllerService
];

const components = [
  ...views,
  CallCardListItemComponent,
  CallcardListEventComponent,
  CallcardListTitleComponent,
  CallcardListHeaderComponent,
  CallcardListFilterComponent,
  CallcardListCustomFiltersComponent,
  CallcardListLocationComponent,
  CallcardListBannerItemComponent,
  CallcardListCustomContentComponent,
  CallcardListPriorityFilterComponent
];

@NgModule({
  declarations: components,
  imports: [SharedModule, HttpClientModule, IonicModule, SingleCallCardModule, CommonAddinModule, IonicCoreModule],
  exports: [SharedModule, HttpClientModule, IonicModule, CommonAddinModule],
  providers: services
})
@Addin({
  name: 'listcallcards',
  description: 'Use to manipulate callcard',
  require: ['singlecallcard']
})
export class ListCallCardsModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, components, services);
  }
}
