import { Component, Input } from '@angular/core';
import { GuidTools } from '@models/guid';
import { StatusItemAction } from '@models/imported/SagaBase';
import { Task } from '@models/imported/SagaSchema/Task';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { StatusService } from '@services/status/status.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';

@Component({
  selector: 'app-task-engagement-button',
  templateUrl: './task-engagement-button.component.html',
  styleUrls: ['./task-engagement-button.component.scss']
})
export class TaskEngagementButton {
  @Input()
  task: Task;

  get isNotEngaged(): boolean {
    return (
      !this.myUnit.mine ||
      (GuidTools.isEmpty((this.myUnit.mine as UnitActivityMDP).TaskID) && GuidTools.isEmpty(this.myUnit.mine.CallCardID))
    );
  }

  constructor(private myUnit: MyUnitService, private statusService: StatusService) {}

  engage() {
    const assignement: ListResourceStatus = this.statusService.getStatusByAction(StatusItemAction.isAssignement);
    this.statusService.changeStatus(assignement, null, { callCardUid: this.task.ObjGuid });
  }
}
