import { MapSettingsService } from '@addins/core/map';
import { CustomComponentsService } from '@addins/core/settings';
import { ButtonsProviderService, ICallcardButtonEvent } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { ExternalItinerarySettingsComponent } from '../../../components/external-itinerary-settings/external-itinerary-settings.component';
import { ItineraryTool } from '../../../schema/itinerary-tool';
import { ExternalItineraryProviderService } from '../../external-itinerary-provider/external-itinerary-provider.service';
import { StartItineraryService } from '../../start-itinerary/start-itinerary.service';

@AddinExecute({
  name: 'external-itinerary'
})
@Injectable()
export class StartItineraryMenuService extends Execute {
  private readonly button = new ItineraryTool();

  public constructor(
    private buttonsProvider: ButtonsProviderService,
    private customSettingsComponents: CustomComponentsService,
    private startItineraryService: StartItineraryService,
    private mapSettingsService: MapSettingsService,
    private externalItineraryProvider: ExternalItineraryProviderService
  ) {
    super();
  }

  public execute(): void {
    this.externalItineraryProvider.setup();
    this.customSettingsComponents.add(ExternalItinerarySettingsComponent);
    this.buttonsProvider.add(this.button).subscribe(e => this.navigateTo(e));
  }

  private navigateTo(callcardButtonEvent: ICallcardButtonEvent): void {
    const destination = this.getCoordinateWithProjection(callcardButtonEvent.target.location);
    this.startItineraryService.navigateTo(destination);
  }

  private getCoordinateWithProjection(location: CLocation): Coordinate {
    return {
      x: location.coordinate.x,
      y: location.coordinate.y,
      epsg: location.coordinate.epsg || this.mapSettingsService.getSystemProjection()
    };
  }
}
