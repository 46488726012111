<ion-item (click)="mainClick($event)" button="true" class="title-item">
  <ion-icon slot="start" size="large" [icon]="iconName" class="unit-icon"></ion-icon>
  <span class="username">
    <strong>{{ unit.Name }}</strong>
  </span>
  <span>{{ 'TotalCount' | translate }} : {{ resources?.length }}</span>
  <ion-icon name="location" size="large" slot="end" (click)="localize($event)" [appLocalizeUnit]="unit.ObjGuid"></ion-icon>
</ion-item>

<div [class.ion-hide]="!opened" class="unit-resources">
  <app-unit-resources *ngIf="resources" [unit]="unit" [resources]="resources"></app-unit-resources>
  <div *ngIf="!resources" class="ion-padding custom-skeleton">
    <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
    <ion-skeleton-text animated style="width: 56%"></ion-skeleton-text>
    <ion-skeleton-text animated style="width: 68%"></ion-skeleton-text>
    <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
  </div>
</div>
