import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService } from '@services/cache/cache.service';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyCardService {
  private _unitActivitySub: Subscription = null;
  private _currentLocation: Coordinate = null;

  get myCardId(): string {
    return (this._myUnit.mine && this._myUnit.mine.CallCardID) || '';
  }

  get myCard(): CallCard {
    return (this.myCardId !== '' && this._cache.getObjectByUid(this.myCardId)) || null;
  }

  private _myCallcardId: string = null;

  private _ready = new BehaviorSubject(false);
  get $ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  private _callcard = new BehaviorSubject(null);
  get $change(): Observable<CallCard> {
    return this._callcard.asObservable();
  }

  constructor(private _myUnit: MyUnitService, private _cache: CacheService, private _toastService: ToastService) {}

  isReady(): boolean {
    return this._ready.value;
  }

  setup(): void {
    this._myUnit.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity));
    this._cache
      .listenForChange(CLocation)
      .pipe(filter(cacheEvent => (cacheEvent.object as CLocation).CallCardId === this._myCallcardId))
      .subscribe(cacheEvent => this.onLocationChange(cacheEvent.object as CLocation));
  }

  private onLocationChange(callcardLocation: CLocation) {
    if (this.isLocationChanged(callcardLocation)) {
      this._toastService.show('Mobile.PositionChanged', ToastColor.Update);
    }
    this._currentLocation = { ...callcardLocation.coordinate };
  }

  private isLocationChanged(callcardLocation: CLocation): boolean {
    return (
      !this._currentLocation || this._currentLocation.x !== callcardLocation.CenterX || this._currentLocation.y !== callcardLocation.CenterY
    );
  }

  private onUnitChanged(unitActivity: UnitActivity): void {
    if (this._unitActivitySub !== null) {
      this._unitActivitySub.unsubscribe();
      this._unitActivitySub = null;
    }

    let unitCallcardId: string = null;

    if (unitActivity !== null) {
      unitCallcardId = unitActivity.CallCardID;

      this._unitActivitySub = unitActivity.$changed.pipe(filter(() => this.hasMyCallcardChanged(unitActivity.CallCardID))).subscribe(() => {
        this.onMyCallcardChanged(unitActivity.CallCardID);
      });
    }

    if (this.hasMyCallcardChanged(unitCallcardId)) {
      this.onMyCallcardChanged(unitCallcardId);
    }

    this._ready.next(unitActivity !== null);
  }

  private hasMyCallcardChanged(callCardId: string): boolean {
    return this._myCallcardId !== callCardId;
  }

  private onMyCallcardChanged(callcardId: string) {
    this._myCallcardId = callcardId;
    this._callcard.next(this.myCard);
  }
}
