import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CFreeText } from '@models/imported/SagaSchema/CFreeText';
import { CacheAction } from '@services/cache/cacheEvent';
import { ModelFactoryService } from '@services/model-factory';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const STAMP_API_URL: string = 'api/Stamp';

@Injectable({
  providedIn: 'root'
})
export class StampsProxyService {
  constructor(private httpClient: HttpClient, private objectFactory: ObjectFactory, private modelFactory: ModelFactoryService) {}

  getStamps(callcardId: string): Observable<SagaObject[]> {
    return this.httpClient
      .post<Array<any>>(`${STAMP_API_URL}/LoadStamps`, {
        CallCardUid: callcardId
      })
      .pipe(map(items => this.parseItems(items)));
  }

  private parseItems(items: any): SagaObject[] {
    const stamps: SagaObject[] = items.filter(item => item).map(item => this.objectFactory.create(item));
    stamps.forEach(item => this.modelFactory.callChanged({ action: CacheAction.added, target: item }));
    return stamps;
  }

  addFreetext(callcardId: string, text: string, type?: number): Observable<any> {
    return this.httpClient.post(`${STAMP_API_URL}/AddFreetext`, {
      CallCardId: callcardId,
      Text: text,
      FreeTextType: type
    });
  }

  readFreetext(objGuid: string): Observable<CFreeText> {
    return this.readObject<CFreeText>('CFreeText', objGuid);
  }

  updateFreetext(freeText: CFreeText): Observable<any> {
    return this.httpClient.post(`${STAMP_API_URL}/UpdateFreetext`, freeText);
  }

  private readObject<T>(typeName: string, guid: string): Observable<T> {
    const params = new HttpParams({ fromObject: { typeName: typeName, uid: guid } });
    return this.httpClient.get('api/Db/ReadObject', { params }).pipe(map(response => this.objectFactory.create(response)));
  }
}
