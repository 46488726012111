import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Coordinate } from '@models/coordinate';
import { ViewLoaderService } from '@addins/core/menus';
import { MapName, RequestLocalization } from '@addins/core/core';
import { MapInstancesService } from '@services/map';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { LocalizePositionService } from '../../localize-position/localize-position.service';

@Injectable()
export class RequestLocalizeService implements RequestLocalization {
  private _sub: Subscription = null;

  constructor(
    private readonly _localize: LocalizePositionService,
    private readonly _maps: MapInstancesService,
    private readonly _views: ViewLoaderService,
    private readonly _selector: ViewSelectorService
  ) {}

  public requestLocalize(target: Coordinate, zoom: number) {
    const map = this._maps.get(MapName.main);
    if (map !== null) {
      this._localize.centerView(map.getView(), target, 1);
    } else {
      this._sub = this._maps.$change.pipe(filter(e => e.name === MapName.main)).subscribe(e => this.localize(target, zoom));
      this._views.show(this._selector.getMap().element);
    }
  }

  private localize(target: Coordinate, zoom: number) {
    const map = this._maps.get(MapName.main);
    this._sub.unsubscribe();
    this._sub = null;
    this._localize.centerView(map.getView(), target, zoom);
  }
}
