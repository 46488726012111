import { Pipe, PipeTransform } from '@angular/core';

export type Unit = 'cm' | 'm' | 'km';
const UNITS: Unit[] = ['cm', 'm', 'km'];
const UNITS_ROUND = [0, 0, 1];

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {
  transform(value: number, initialUnit: Unit = 'm'): any {
    let unit = initialUnit;
    let unitIndex = UNITS.indexOf(unit);
    while (value / 1000 >= 1 && unitIndex < UNITS.length - 1) {
      value = value / 1000;
      unit = UNITS[UNITS.indexOf(unit) + 1];
      unitIndex = UNITS.indexOf(unit);
    }

    const pow = Math.pow(10, UNITS_ROUND[unitIndex]);
    const rounded = Math.round(value * pow) / pow;
    return `${rounded} ${unit}`;
  }
}
