import { Injectable } from '@angular/core';
import { ServicesProviderInterface } from '@models/BaseObject';
import { AtomicOperation } from '@models/imported/SagaBase/Defines/AtomicOperation';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { List } from '@models/list';
import { CacheProxyService, IBootResult } from '@services/cache/cache-proxy.service';
import { ModelFactoryService } from '@services/model-factory';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as SagaBase from '../../models/imported/SagaBase';
import { FetchListService } from '../fetchlist/fetchlist.service';
import { ObjectFactory } from '../object-factory/object-factory.service';
import { CacheAction, CacheEvent } from './cacheEvent';

export interface CacheChangedInterface<T = any> extends ServicesProviderInterface {
  obj: SagaObject;
  action: CacheAction;
  cloneBeforeChange?: SagaObject;
  data: T;
}

export enum CacheState {
  idle,
  busy,
  ready,
  erroneous
}

@Injectable()
export class CacheService {
  private readonly storage = { byTypeName: {}, byId: {} };
  private readonly lists: Map<string, List<any>[]> = new Map<string, List<any>[]>();
  private readonly _bootedType = [];

  private readonly _state = new BehaviorSubject<CacheState>(CacheState.idle);
  get state(): Observable<CacheState> {
    return this._state.asObservable();
  }

  private readonly _onChange = new Subject<void>();
  get onChange(): Observable<void> {
    return this._onChange.asObservable();
  }

  private events: Subject<CacheEvent> = new Subject();
  $events: Observable<CacheEvent> = this.events.asObservable();

  constructor(
    private modelFactory: ModelFactoryService,
    private cacheProxy: CacheProxyService,
    private fetchList: FetchListService,
    public objectFactory: ObjectFactory
  ) {}

  clear() {
    this.storage.byId = {};
    this.storage.byTypeName = {};
    this.lists.clear();
    this._bootedType.length = 0;
    this._state.next(CacheState.idle);
  }

  listenForChange(type: any): Observable<CacheEvent> {
    return this.$events.pipe(filter(event => event.object instanceof type));
  }

  boot(): Observable<boolean> {
    this._state.next(CacheState.busy);
    return this.cacheProxy.setup().pipe(map(boot => this.parseCacheData(boot)));
  }

  create(obj: any, silent = false) {
    if (obj) {
      if (this.storage.byId[obj.ObjGuid]) {
        return this.storage.byId[obj.ObjGuid];
      }

      const listChanges: Array<CacheChangedInterface> = [];
      const instance = this.addObjToCacheRecursively(obj as SagaObject, listChanges, null, true);

      this.notifyAllInstances(listChanges, silent);

      return instance;
    }

    return null;
  }

  callChanged(obj: SagaObject, data: any = {}) {
    const action = CacheAction.modified;
    // Should be removed after finishing the refactoring of the models' services
    obj.changed({ cache: this, fetchList: this.fetchList, obj, action, data });

    // Process changes through the model's factory
    this.modelFactory.callChanged({ action, target: obj, data });

    // Notify cache's listeners
    this.events.next({ object: obj, action });
  }

  hasBootedWithType(type: typeof SagaObject): boolean {
    return this._bootedType.indexOf(type.$t) > -1;
  }

  isReady(): boolean {
    let ready: boolean;
    this._state.subscribe(r => (ready = r === CacheState.ready)).unsubscribe();
    return ready;
  }

  private notifyAllInstances(listChanges: Array<CacheChangedInterface>, silent = false) {
    const alreadyNotifiedChanges = {};
    if (listChanges.length > 0) {
      this._onChange.next();
    }
    listChanges.forEach(change => this.dispatchChange(change, alreadyNotifiedChanges, silent));
  }

  private parseCacheData(data: IBootResult): boolean {
    if (data === null) {
      this._state.next(CacheState.erroneous);
      return false;
    }

    const listChanges: Array<CacheChangedInterface> = [];
    for (const typeName in data) {
      if (data.hasOwnProperty(typeName)) {
        this._bootedType.push(typeName);
        for (const obj of data[typeName]) {
          if (obj) {
            this.addObjToCacheRecursively(obj, listChanges, typeName);
          }
        }
      }
    }

    this.notifyAllInstances(listChanges);
    this._state.next(CacheState.ready);
    return true;
  }

  getObjectByUid(uid: string): any {
    return this.storage.byId[uid] || null;
  }

  getListByType(type: any): any[] {
    return this.getListByTypeName(type.$t);
  }

  getListByTypeName(typeName: string): any[] {
    if (!this.storage.byTypeName[typeName]) {
      return [];
    }
    const result = [];
    for (const i in this.storage.byTypeName[typeName]) {
      if (this.storage.byTypeName[typeName].hasOwnProperty(i)) {
        result.push(this.storage.byTypeName[typeName][i]);
      }
    }
    return result;
  }

  private addObjToCacheRecursively(obj: SagaObject, listChanges: Array<CacheChangedInterface>, typeName = null, addToParent = false) {
    let instance: SagaObject;
    let action: CacheAction;

    // Update if obj already in cache
    if (this.storage.byId[obj.ObjGuid]) {
      action = CacheAction.modified;
      instance = this.storage.byId[obj.ObjGuid];

      this.objectFactory.updateProperties(obj, instance);

      if (instance.ParentObjId !== instance.ObjGuid && this.storage.byId[instance.ParentObjId]) {
        const parent = this.storage.byId[instance.ParentObjId];

        if (addToParent && parent.add) {
          parent.add(instance);
        }
      }
    } else {
      action = CacheAction.added;
      instance = this.objectFactory.create(obj, typeName);

      if (!instance) {
        return null;
      }

      // Special case when the ParentObjId is serialized as is instead of serialize the real parent field (for example CallCardId)
      if (obj.ParentObjId !== undefined) {
        instance.ParentObjId = obj.ParentObjId;
      }

      // Add to parent if exists
      if (addToParent) {
        if (instance.ParentObjId !== instance.ObjGuid && this.storage.byId[instance.ParentObjId]) {
          const parent = this.storage.byId[instance.ParentObjId];

          if (parent.add) {
            parent.add(instance);
          }
        }
      }

      // Add to cache
      if (this.storage.byTypeName[instance.$t] == null) {
        this.storage.byTypeName[instance.$t] = {};
      }
      this.storage.byTypeName[instance.$t][instance.ObjGuid] = instance;
      this.storage.byId[instance.ObjGuid] = instance;
      this.updateListsOf(instance);

      if ((instance as any).components) {
        (instance as any).components().forEach(componentType => {
          const list = obj[componentType];
          if (list) {
            const components = (instance as any).component(componentType);
            list.forEach(componentObj => {
              const objInstance = this.addObjToCacheRecursively(componentObj, listChanges, componentType);
              components.push(objInstance);
              objInstance.ParentObjId = instance.ObjGuid;
            });
          }
        });
      }
    }

    listChanges.push({
      cache: this,
      fetchList: this.fetchList,
      obj: instance,
      action,
      data: {}
    });

    return instance;
  }

  private dispatchChange(change: CacheChangedInterface, alreadyNotifiedChanges: any, silent = false) {
    let current = change.obj;
    // Notify the object only when it has no model's service
    if (!this.modelFactory.hasService(current)) {
      change.obj.changed(change);
    }
    while (current && !alreadyNotifiedChanges[`${current.ObjGuid}/${change.action}`]) {
      this.modelFactory.callChanged({ action: change.action, target: current });
      this.events.next({ action: change.action, object: current });
      alreadyNotifiedChanges[`${current.ObjGuid}/${change.action}`] = true;
      current = !silent && current.ParentObjId !== current.ObjGuid && this.storage.byId[current.ParentObjId];
      // Parent action is always a modification
      change = Object.assign(change, { obj: current, action: CacheAction.modified });
    }
  }

  removeObjFromCache(obj: SagaObject, listChanges: Array<CacheChangedInterface>) {
    if (this.storage.byId[obj.ObjGuid]) {
      const instance = this.storage.byId[obj.ObjGuid] as SagaObject;

      // Remove components
      if ((instance as any).components) {
        (instance as any).components().forEach(componentType => {
          const list = (instance as any).component(componentType);
          if (list) {
            list.forEach(componentObj => this.removeObjFromCache(componentObj, listChanges));
          }
        });
      }

      // Remove from cache
      delete this.storage.byId[instance.ObjGuid];
      delete this.storage.byTypeName[instance.$t][instance.ObjGuid];
      this.updateListsOf(instance);

      // Remove from parent
      if (instance.ParentObjId !== instance.ObjGuid && this.storage.byId[instance.ParentObjId]) {
        const parent = this.storage.byId[instance.ParentObjId];

        if ((parent as any).components && (parent as any).remove) {
          (parent as any).remove(instance);
        }
      }
      // notify(instance, tw.Action.Delete, true, notifyList);

      listChanges.push({
        cache: this,
        fetchList: this.fetchList,
        obj: instance,
        action: CacheAction.removed,
        data: {}
      });
    }
  }

  pushMessageReceived(ops: Array<AtomicOperation>) {
    // For DEV purpose : we can add a delay before manage atomic operations by setting window.delayPushInMs > 0
    if ((window as any).delayPushInMs > 0) {
      setTimeout(() => this.internalPushMessageReceived(ops), (window as any).delayPushInMs);
    } else {
      this.internalPushMessageReceived(ops);
    }
  }

  addObjToCache<T = SagaObject>(obj: T & SagaObject, typeName = null): T {
    const listChanges: Array<CacheChangedInterface> = [];
    const instance = this.addObjToCacheRecursively(obj, listChanges, typeName, true);
    if (this.isReady()) {
      this.notifyAllInstances(listChanges);
    }
    return instance as any;
  }

  private internalPushMessageReceived(ops: Array<AtomicOperation>) {
    // $rootScope.$broadcast('saga.cache.beginAtomic');
    const listChanges: Array<CacheChangedInterface> = [];
    ops.forEach(op => {
      switch (op.action) {
        case SagaBase.OperationType.Submit:
        case SagaBase.OperationType.Update:
        case SagaBase.OperationType.Activate:
        case SagaBase.OperationType.Undelete:
          this.addObjToCacheRecursively(op.obj as SagaObject, listChanges, null, true);

          break;
        case SagaBase.OperationType.Delete:
        case SagaBase.OperationType.Remove:
          this.removeObjFromCache(op.obj as SagaObject, listChanges);
          break;
      }
    });

    this.notifyAllInstances(listChanges);
  }

  private updateListsOf<T>(type: any) {
    const lists = this.getRegisteredListOf<T>(type);
    lists.forEach(list => {
      list.source = this.getListByType(type);
    });
  }

  private getRegisteredListOf<T>(type: any): List<T>[] {
    if (!this.lists.has(type.$t || type)) {
      return [];
    }
    return this.lists.get(type.$t || type);
  }

  unregisterListOf<T>(type: any, list: List<T>) {
    if (this.lists.has(type.$t || type)) {
      const listOfType = this.lists.get(type.$t || type);
      const index = listOfType.indexOf(list);
      if (index !== -1) {
        listOfType.splice(index, 1);
      }
    }
  }

  getListOf<T>(type: any): List<T> {
    const list = new List<T>();
    list.source = this.getListByType(type);

    if (!this.lists.has(type.$t || type)) {
      this.lists.set(type.$t || type, []);
    }
    this.lists.get(type.$t || type).push(list);
    return list;
  }
}
