// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ActivityStatusArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.ActivityStatusArgs';
  $t: string = 'SagaBase.Defines.ActivityStatusArgs';

  public static GetMembers(): string[] {
    return [
      'ProcStepUid',
      'Parameter',
      'DeploymentPointUid',
      'DeploymentPointName',
      'IsAutoEngage',
      'ReassignmentStatus',
      'IsMobileSource',
      'IntermediateStatus'
    ];
  }

  public GetMembers(): string[] {
    return ActivityStatusArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ProcStepUid: Guid = GuidTools.Empty;
  public Parameter: string;
  public DeploymentPointUid: Guid = GuidTools.Empty;
  public DeploymentPointName: string;
  public IsAutoEngage: boolean = false;
  public ReassignmentStatus: number;
  public IsMobileSource: boolean = false;
  public IntermediateStatus: number[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
