// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class BootEventArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.BootEventArgs';
  $t: string = 'SagaBase.Defines.BootEventArgs';

  public static GetMembers(): string[] {
    return [
      'LoginName',
      'SiteId',
      'UserId',
      'SessionId',
      'Types',
      'Data',
      'QueueName',
      'FetchList',
      'BroadcastObjectsFilters',
      'BroadcastObjectsFiltersParameters'
    ];
  }

  public GetMembers(): string[] {
    return BootEventArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public LoginName: string;
  public SiteId: number = 0;
  public UserId: number;
  public SessionId: string;
  public Types: any[];
  public Data: any[];
  public QueueName: string;
  public FetchList: boolean = false;
  public BroadcastObjectsFilters: string;
  public BroadcastObjectsFiltersParameters: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
