// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ISagaEnvironment } from './saga-environment';

export const environment: ISagaEnvironment = {
  profile: 'fus',
  home: 'SimpleHome/Home',
  // login: 'AppLogin/HeaderLogin',
  login: 'AppLogin/GenericLogin',
  production: false,
  pushAddress: 'Push',
  addinParameters: {
    map: {}
  },
  config: {
    name: '',
    colors: { primary: '#00498d', secondary: '#32db64', danger: '#f53d3d', light: '#f4f4f4', dark: '#222' },
    package: 'com.techwan.sagamobile'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
