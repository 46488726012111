// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { Actor } from '../../SagaSchema/Actor';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class MobActor_abstract extends Actor {
  static $t: string = 'SagaSchema.MobActor';
  $t: string = 'SagaSchema.MobActor';

  public static GetMembers(): string[] {
    return ['ResourcePriority', 'IsOptional'].concat(Actor.GetMembers());
  }

  public GetMembers(): string[] {
    return MobActor_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.PhoneNumber',
      'SagaSchema.ResourcePosition',
      'SagaSchema.ResAddress',
      'SagaSchema.ResVideo',
      'SagaSchema.ResComplement'
    ];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ResourcePriority: number = 0;
  public IsOptional: boolean = true;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
