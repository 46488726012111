import { CurrentCardService } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { Guid } from '@models/guid';
import { CFormLink } from '@models/imported/SagaSchema/CFormLink';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { AddinView } from '@techwan/ionic-core';
import { SagaFormsUrlProviderService } from '../../services/saga-forms-url-provider/saga-forms-url-provider.service';

@AddinView({
  name: 'CallcardForms'
})
@Component({
  selector: 'app-saga-forms-collapsible-view',
  templateUrl: './saga-forms-collapsible-view.component.html',
  styleUrls: ['./saga-forms-collapsible-view.component.scss']
})
export class SagaFormsCollapsibleViewComponent implements OnInit {
  forms: CFormLink[] = [];

  constructor(
    private currentCard: CurrentCardService,
    private sagaFormsUrlProviderService: SagaFormsUrlProviderService,
    private windowOpener: WindowOpenerService
  ) {}

  ngOnInit() {
    const currentCard = this.currentCard.current;
    this.forms = currentCard.component<CFormLink>(CFormLink);
  }

  openForm(formValueUid: Guid) {
    const url: string = this.sagaFormsUrlProviderService.getFormUrl(formValueUid);
    this.windowOpener.system(url);
  }
}
