// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 13:39:25
export enum DefaultApplicationIcon {
  Card = 1,
  PlannedEvent = 2,
  Task = 3,
  Resource = 4,
  UnitActivity = 5,
  Patient = 6
}

// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 13:39:26
export enum DialogType {
  QandA = 0,
  TimedResponse = 1
}
