// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:43
import { CallCard } from '../../SagaSchema/CallCard';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class SimpleCallCard_abstract extends CallCard {
  static $t: string = 'SagaSchema.SimpleCallCard';
  $t: string = 'SagaSchema.SimpleCallCard';

  public static GetMembers(): string[] {
    return ['Reactivable', 'TaskType', 'ProjectType', 'ReportDate', 'EndDateUTC', 'ProjectTitle', 'TaskTitle'].concat(
      CallCard.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return SimpleCallCard_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ReportDate = this.ReportDate && new Date(this.ReportDate);
    this.EndDateUTC = this.EndDateUTC && new Date(this.EndDateUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.CCaller', 'SagaSchema.CEvent', 'SagaSchema.CLocation'];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Reactivable: boolean = false;
  public TaskType: any;
  public ProjectType: number;
  public ReportDate: Date = new Date();
  public EndDateUTC: Date = new Date();
  public ProjectTitle: string;
  public TaskTitle: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
