// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:30:53
import { CObjectHistory_abstract } from './../../abstract/SagaSchema/History/CObjectHistory_abstract';
import { CObject } from '@models/imported/SagaSchema/CObject';
import { HistoryTextFormatter } from '@utils/formatter/history-text-formatter';

export class CObjectHistory extends CObjectHistory_abstract {
  get text() {
    return HistoryTextFormatter.format(this, CObject.formsProperties);
  }
}
