export class SettingValue {
  public get value(): any {
    return this._value;
  }

  public get name(): string {
    return this._name;
  }

  constructor(protected readonly _name: string, protected readonly _value: any) {}
}
