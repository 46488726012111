import { Trace } from './trace';

/**
 * @name Stack
 * @description
 * A StackTrace created from an Error object
 */
export class Stack {
  private traces: Trace[] = [];

  /**
   * @constructor
   */
  constructor() {
    let error = new Error();
    let stackStr = error.stack;
    let stackes = stackStr.split('\n');
    stackes.splice(0, 1);
    stackes = stackes.map(s => s.trim().replace(/^at /, ''));
    stackes.forEach(s => {
      let t = new Trace(s);
      if (!t.file.path.match(/services\/logger.ts$/)) {
        this.traces.push(t);
      }
    });
  }

  toString(): string {
    return `StackTrace
${this.traces.map((t, i) => ` ${i + 1} - ` + t.toString()).join('\n')}`;
  }
}
