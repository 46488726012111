import { MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { CTacticalSituation } from '@models/imported/SagaSchema/CTacticalSituation';
import { List } from '@models/list';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ITacticalDrawingEvent } from '../../schema/interfaces/ITacticalDrawingEvent';
import { MyCardDrawings } from '../../schema/my-card-drawings';
import { MyDrawing } from '../../schema/my-drawing';

@Injectable()
export class TacticalDrawingsService {
  private _drawings: List<CTacticalSituation> = null;

  private readonly _myCardDrawings = new MyCardDrawings();

  private readonly _myDrawings = new MyDrawing();

  private readonly _event = new Subject<ITacticalDrawingEvent>();
  public get onEvent(): Observable<ITacticalDrawingEvent> {
    return this._event.asObservable();
  }

  private _cacheUpdateSub: Subscription = null;
  private _cacheReady: Subscription = null;

  public constructor(
    private readonly _cache: CacheService,
    private readonly _myUnit: MyUnitService,
    private readonly _myCard: MyCardService,
    private readonly claims: RefreshTokenStorageService
  ) {}

  public getDrawingsOfMyCard(): MyCardDrawings {
    this.setup();
    return this._myCardDrawings;
  }

  public getMyDrawings(): MyDrawing {
    this.setup();
    return this._myDrawings;
  }

  private setup() {
    if (this._cacheReady === null) {
      if (this._cache.isReady()) {
        this.onCacheStateChange();
      } else {
        this._cacheReady = this._cache.state.subscribe(state => this.onCacheStateChange(state));
      }
    }
  }

  private myDrawings(): void {
    const d = this.drawingsOfMyCard();
    const user = this.claims.read().login.SagaUserID;
    this._myDrawings.item = d.items.find(i => i.CreatorId === user) || null;
  }

  private myCardButMe(): void {
    const user = this.claims.read().login.SagaUserID;
    const list = this.drawingsOfMyCard();
    list.items.filter(i => i.CreatorId !== user).forEach(i => this._myCardDrawings.add(i));
  }

  private drawingsOfMyCard(): List<CTacticalSituation> {
    this._drawings = this._cache.getListOf<CTacticalSituation>(CTacticalSituation);
    this._drawings.addFilter(i => this.isForMyCard(i));
    return this._drawings;
  }

  private isForMyCard(item: CTacticalSituation): boolean {
    const unit = this._myUnit.mine;
    return unit && GuidTools.isValid(unit.CallCardID) && item.CallCardId === unit.CallCardID;
  }

  private onCacheStateChange(state: CacheState = CacheState.idle) {
    if (state === CacheState.ready) {
      this.updateDrawingsItems();
      this._cacheReady.unsubscribe();

      if (this._cacheUpdateSub === null) {
        merge(this._myUnit.$unitChanged, this._myCard.$change).subscribe(() => this.updateDrawingsItems());

        this._cacheUpdateSub = this._cache.$events
          .pipe(filter(e => e.object instanceof CTacticalSituation))
          .subscribe(e => this.onCacheEvent(e));
      }
    }
  }

  private updateDrawingsItems() {
    this._myCardDrawings.clear();
    this.myDrawings();
    this.myCardButMe();
  }

  private onCacheEvent(e: CacheEvent): void {
    const d = e.object as CTacticalSituation;
    const user = this.claims.read().login.SagaUserID;

    // This is my drawings on this card
    if (this.isForMyCard(d) && d.CreatorId === user) {
      switch (e.action) {
        case CacheAction.added:
          this._myDrawings.item = d;
          break;

        case CacheAction.modified:
          this._myDrawings.refresh();
          break;

        case CacheAction.removed:
          this._myDrawings.item = null;
      }
    } else if (this.isForMyCard(d)) {
      switch (e.action) {
        case CacheAction.added:
          this._myCardDrawings.add(d);
          break;

        case CacheAction.modified:
          this._myCardDrawings.update(d);
          break;

        case CacheAction.removed:
          this._myCardDrawings.remove(d);
      }
    }
  }
}
