import { Injectable } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService } from '@services/cache/cache.service';
import { ModalService } from '@services/modal/modal.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CachedList } from '../../schema/cached-list';

@Injectable({
  providedIn: 'root'
})
export class UnitListFetchingService {
  constructor(private modalService: ModalService, private unitActivityService: UnitActivityService, private cacheService: CacheService) {}

  fetch(status: number): Observable<UnitActivity[]> {
    return from(this.modalService.presentLoading('SagaMobile.UA.Loading')).pipe(
      switchMap(() => this.unitActivityService.getUnitActivities(status)),
      switchMap(res => from(this.modalService.dismissLoading()).pipe(map(() => res)))
    );
  }

  createFilteredUnitActivitiesFromCache(_filter: (value: UnitActivity) => boolean): CachedList<UnitActivity> {
    return new CachedList<UnitActivity>(this.cacheService, UnitActivity, _filter);
  }
}
