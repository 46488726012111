import { Component, ElementRef, OnInit } from '@angular/core';
import { Map } from '@techwan/mapping';
import { IonNav } from '@ionic/angular';
import { AddinView } from '@techwan/ionic-core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CLocationHistory } from '@models/imported/SagaSchema/History/CLocationHistory';
import { MapService } from '@services/map';
import { NavService } from '@services/navigation/navigation.service';
import { DrawingLayerService } from '../../services/drawing-layer/drawing-layer.service';
import { LayerControllerService } from '../../services/layer-controller/layer-controller.service';
import { LocalizePositionService } from '../../services/localize-position/localize-position.service';

/**
 * @description
 * A view for the Saga Mapping System
 */
@AddinView({
  name: 'Page',
  description: 'A view for the Saga Mapping System'
})
@Component({
  templateUrl: 'map-page-view.component.html',
  styleUrls: ['map-page-view.component.scss'],
  providers: [DrawingLayerService]
})
export class MapPageViewComponent implements OnInit {
  /**
   * The map object of SagaMapping
   */
  public map_: Map;

  private readonly nav: IonNav;

  get moreItems() {
    return this.mapService.moreItems;
  }

  /**
   * Warning: Use an internal copy (new reference) of the map service tool array to prevent DOM rebuilding by Angular
   * due to reference changes.
   *
   * Should use map service event instead of rebuilding array at each refresh.
   *
   * @AMO Rollback to get the reference of buttonItems directly
   */
  get buttonItems() {
    return this.mapService.buttonItems;
  }

  constructor(
    private readonly el: ElementRef,
    private readonly mapService: MapService,
    private readonly navService: NavService,
    private readonly _controller: LayerControllerService,
    private readonly _roDrawing: DrawingLayerService,
    private readonly _localize: LocalizePositionService
  ) {
    this.nav = this.navService.get();
  }

  public ngOnInit() {
    this._controller.addController(this._roDrawing);
  }

  public centerOnLocation(location: CLocation | CLocationHistory, resolution: number = null) {
    if (location) {
      this._localize.centerOnLocation(this.map_.getView(), location, resolution);
      this.mapService.moveToPosition = false;
    }
  }

  public centerOnCard(callcard: CallCard, resolution: number = null) {
    if (callcard) {
      this.centerOnLocation(callcard.location, resolution);
    }
  }
}
