import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-layout',
  templateUrl: './top-layout.component.html',
  styleUrls: ['./top-layout.component.scss']
})
export class TopLayoutComponent implements OnInit {
  @Input()
  views: string[];

  constructor() {}

  ngOnInit() {}

  getClassName(view: string) {
    return view.replace('/', '-').toLowerCase();
  }
}
