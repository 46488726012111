import { Injectable } from '@angular/core';
import { CacheService } from '@services/cache/cache.service';
import { Guid } from '@models/guid';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { List } from '@models/list';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { IUnitDeployment } from '../../schema/interfaces/deployment-requests';

@Injectable()
export class UnitDeploymentService {
  constructor(private readonly _cache: CacheService) {}

  public getUnitForCard(id: Guid): UnitActivity[] {
    const list = this._cache.getListOf(UnitActivity) as List<UnitActivity>;
    return list.items.filter(i => i.CallCardID === id);
  }

  public deployUnitsOn(dp: CDeploymentPoint, units: string[], atomics: IUnitDeployment[] = []): IUnitDeployment[] {
    const deployment = dp.clone();
    atomics.push({ deployment, units: units.slice() });
    return atomics;
  }
}
