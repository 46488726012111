import { Component, Input, OnInit, Output } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { SagaSettingsService } from '@services/settings';
import { Observable, Subject } from 'rxjs';
import { StatusStateService } from '../../services/status-state/status-state.service';

@Component({
  selector: 'app-status-item',
  templateUrl: './status-item.component.html',
  styleUrls: ['./status-item.component.scss'],
  providers: [StatusStateService]
})
export class StatusItemComponent implements OnInit {
  @Input()
  status: ListResourceStatus;

  @Input()
  myUnit: UnitActivity;

  @Input()
  horizontal?: boolean;

  @Output()
  get onClick(): Observable<Event> {
    return this._onClick.asObservable();
  }

  get isMyStatus(): boolean {
    return this.myUnit.status.Value === this.status.Value;
  }

  get isAllowed(): boolean {
    return (this.myUnit.status.Children & this.status.Value) !== 0;
  }

  statusColor: string = null;
  showIcon: boolean = false;
  statusIcon: string = null;

  statusName: string = null;
  statusNameFontSize: string = null;
  statusTimeFontSize: string = null;

  private readonly _onClick = new Subject<Event>();

  constructor(private _statusStateService: StatusStateService, private _settingsService: SagaSettingsService) {}

  ngOnInit() {
    this._statusStateService.setStatus(this.status);

    this.statusName = `Mobile.${this.status.Name}`;

    this.statusNameFontSize = this._settingsService.getValue('SagaMobileWebClient.StatusNameTextSize');
    this.statusTimeFontSize = this._settingsService.getValue('SagaMobileWebClient.StatusTimeTextSize');

    this.statusColor = this._statusStateService.getColor();

    this.showIcon = this._statusStateService.hasIcons();
    this.statusIcon = this._statusStateService.getIcon();
  }

  onStatusClicked($event) {
    if (this.isAllowed) {
      this._onClick.next($event);
      this._statusStateService.setTime();
    }
  }

  getTime(): string {
    return this._statusStateService.getTime() || '-';
  }
}
