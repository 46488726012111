import { Injectable } from '@angular/core';
import { CallCardDispatch } from '@models/imported/SagaSchema/CallCardDispatch';
import { CAnimalHistory } from '@models/imported/SagaSchema/History/CAnimalHistory';
import { CObjectHistory } from '@models/imported/SagaSchema/History/CObjectHistory';
import { CPatientHistory } from '@models/imported/SagaSchema/History/CPatientHistory';
import { CPersonHistory } from '@models/imported/SagaSchema/History/CPersonHistory';
import { CVehicleHistory } from '@models/imported/SagaSchema/History/CVehicleHistory';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { ActionCondition } from './SagaActionLib/ActionCondition';
import { ActionConditionGroup } from './SagaActionLib/ActionConditionGroup';
import { ActionConfigurationContainer } from './SagaActionLib/ActionConfigurationContainer';
import { ConditionPart } from './SagaActionLib/ConditionPart';
import { ProcedureAction } from './SagaActionLib/ProcedureAction';
import { ProcedureActionCondition } from './SagaActionLib/ProcedureActionCondition';
import { ProcedureActionContext } from './SagaActionLib/ProcedureActionContext';
import { ProcedureActionContextConfigurationBase } from './SagaActionLib/ProcedureActionContextConfigurationBase';
import { ProcedureEditorInterface } from './SagaActionLib/ProcedureEditorInterface';
import { Address } from './SagaBase/Address';
import { AddressComplement } from './SagaBase/AddressComplement';
import { AddressComplementArgs } from './SagaBase/AddressComplementArgs';
import { AlarmInfo } from './SagaBase/AlarmInfo';
import { AlarmStateChangedBuffer } from './SagaBase/AlarmStateChangedBuffer';
import { AlternateUserProfile } from './SagaBase/AlternateUserProfile';
import { AppSetting } from './SagaBase/AppSetting';
import { AppSettingApplication } from './SagaBase/AppSettingApplication';
import { AppSettingScope } from './SagaBase/AppSettingScope';
import { AppSettingsScopeTypes } from './SagaBase/AppSettingsScopeTypes';
import { AppSettingsSetting } from './SagaBase/AppSettingsSetting';
import { ApplicationAccess } from './SagaBase/ApplicationAccess';
import { ApplicationAccessFilter } from './SagaBase/ApplicationAccessFilter';
import { ArchiveResult } from './SagaBase/ArchiveResult';
import { Area } from './SagaBase/Area';
import { AttachmentBase } from './SagaBase/AttachmentBase';
import { AvailableMember } from './SagaBase/AvailableMember';
import { BoolQuestion } from './SagaBase/BoolQuestion';
import { BoolState } from './SagaBase/BoolState';
import { BootObject } from './SagaBase/BootObject';
import { BootObject_SL } from './SagaBase/BootObject_SL';
import { BroadcastMessageArgs } from './SagaBase/BroadcastMessageArgs';
import { CardInitializer } from './SagaBase/CardInitializer';
import { ChoiceQuestion } from './SagaBase/ChoiceQuestion';
import { ChoiceState } from './SagaBase/ChoiceState';
import { CommunicationTypeDefinition } from './SagaBase/CommunicationTypeDefinition';
import { Context } from './SagaBase/Context';
import { DecisionNode } from './SagaBase/DecisionNode';
import { DecisionTreeNode } from './SagaBase/DecisionTreeNode';
import { ActivityStatusArgs } from './SagaBase/Defines/ActivityStatusArgs';
import { ActivityStatusResults } from './SagaBase/Defines/ActivityStatusResults';
import { AddAttachmentArgs } from './SagaBase/Defines/AddAttachmentArgs';
import { AlarmStateChangeRequest } from './SagaBase/Defines/AlarmStateChangeRequest';
import { AlarmStateChangeResponse } from './SagaBase/Defines/AlarmStateChangeResponse';
import { ArchiveCardsArgs } from './SagaBase/Defines/ArchiveCardsArgs';
import { AtomicArgs } from './SagaBase/Defines/AtomicArgs';
import { AtomicOperation } from './SagaBase/Defines/AtomicOperation';
import { AtomicResult } from './SagaBase/Defines/AtomicResult';
import { AvailabilityRatios } from './SagaBase/Defines/AvailabilityRatios';
import { BootEventArgs } from './SagaBase/Defines/BootEventArgs';
import { BootEventArgs_SL } from './SagaBase/Defines/BootEventArgs_SL';
import { CallcardCreateArgs } from './SagaBase/Defines/CallcardCreateArgs';
import { ComResult } from './SagaBase/Defines/ComResult';
import { ComjobArgs } from './SagaBase/Defines/ComjobArgs';
import { CommArgs } from './SagaBase/Defines/CommArgs';
import { CommTarget } from './SagaBase/Defines/CommTarget';
import { ComputeStateArgs } from './SagaBase/Defines/ComputeStateArgs';
import { CopyScheduleArgs } from './SagaBase/Defines/CopyScheduleArgs';
import { CreateMobilisationArgs } from './SagaBase/Defines/CreateMobilisationArgs';
import { CrisisCardCreateArgs } from './SagaBase/Defines/CrisisCardCreateArgs';
import { CrisisFieldInfo } from './SagaBase/Defines/CrisisFieldInfo';
import { DataMessage } from './SagaBase/Defines/DataMessage';
import { DeferArgs } from './SagaBase/Defines/DeferArgs';
import { Ellipse } from './SagaBase/Defines/Ellipse';
import { EtdResult } from './SagaBase/Defines/EtdResult';
import { EventRequirements } from './SagaBase/Defines/EventRequirements';
import { ExportArgs } from './SagaBase/Defines/ExportArgs';
import { GeoSectorArgs } from './SagaBase/Defines/GeoSectorArgs';
import { GetStampsByAlarmHistoryArgs } from './SagaBase/Defines/GetStampsByAlarmHistoryArgs';
import { IntelResultArgs } from './SagaBase/Defines/IntelResultArgs';
import { KeywordSearchArgs } from './SagaBase/Defines/KeywordSearchArgs';
import { LinkCardsArgs } from './SagaBase/Defines/LinkCardsArgs';
import { LinkGroupDescription } from './SagaBase/Defines/LinkGroupDescription';
import { LinkToParentCardArgs } from './SagaBase/Defines/LinkToParentCardArgs';
import { LinkToParentCardResult } from './SagaBase/Defines/LinkToParentCardResult';
import { ListTypeInclusion } from './SagaBase/Defines/ListTypeInclusion';
import { LockArgs } from './SagaBase/Defines/LockArgs';
import { MapSector } from './SagaBase/Defines/MapSector';
import { MobileMessageArgs } from './SagaBase/Defines/MobileMessageArgs';
import { MobileMessageResult } from './SagaBase/Defines/MobileMessageResult';
import { MobilisationResultArgs } from './SagaBase/Defines/MobilisationResultArgs';
import { PasswordArgs } from './SagaBase/Defines/PasswordArgs';
import { PasswordReturnArgs } from './SagaBase/Defines/PasswordReturnArgs';
import { PflauSpecificPhoneNumberArgs } from './SagaBase/Defines/PflauSpecificPhoneNumberArgs';
import { ProcedureArgs } from './SagaBase/Defines/ProcedureArgs';
import { ProcedureResultArgs } from './SagaBase/Defines/ProcedureResultArgs';
import { ProcedureSuggestionResultArgs } from './SagaBase/Defines/ProcedureSuggestionResultArgs';
import { ReactivateCardsArgs } from './SagaBase/Defines/ReactivateCardsArgs';
import { ReactivateCardsResult } from './SagaBase/Defines/ReactivateCardsResult';
import { ReadSchElementObjectArgs } from './SagaBase/Defines/ReadSchElementObjectArgs';
import { ResourcePlanningInfo } from './SagaBase/Defines/ResourcePlanningInfo';
import { ResourceStatusArgs } from './SagaBase/Defines/ResourceStatusArgs';
import { SagaResult } from './SagaBase/Defines/SagaResult';
import { SaveProcedureResultArgs } from './SagaBase/Defines/SaveProcedureResultArgs';
import { ScheduleDatePeriod } from './SagaBase/Defines/ScheduleDatePeriod';
import { SearchArgs } from './SagaBase/Defines/SearchArgs';
import { SearchResourceArgs } from './SagaBase/Defines/SearchResourceArgs';
import { SearchResults } from './SagaBase/Defines/SearchResults';
import { SectorStatusArgs } from './SagaBase/Defines/SectorStatusArgs';
import { SectorisationArgs } from './SagaBase/Defines/SectorisationArgs';
import { StampStatusChangeResponse } from './SagaBase/Defines/StampStatusChangeResponse';
import { StatusArgs } from './SagaBase/Defines/StatusArgs';
import { TimerScheduleOperation } from './SagaBase/Defines/TimerScheduleOperation';
import { TimerScheduleResultArgs } from './SagaBase/Defines/TimerScheduleResultArgs';
import { UndoDeleteCardComponentArgs } from './SagaBase/Defines/UndoDeleteCardComponentArgs';
import { UnlinkFromParentCardArgs } from './SagaBase/Defines/UnlinkFromParentCardArgs';
import { UserAccessArgs } from './SagaBase/Defines/UserAccessArgs';
import { UserAccessReturnArgs } from './SagaBase/Defines/UserAccessReturnArgs';
import { DummyDecisionTreeNode } from './SagaBase/DummyDecisionTreeNode';
import { ETDAddress } from './SagaBase/ETDAddress';
import { ScannedAssemblies } from './SagaBase/Factory/ScannedAssemblies';
import { ScannedAssembly } from './SagaBase/Factory/ScannedAssembly';
import { FactoryEditor } from './SagaBase/FactoryEditor';
import { FactoryObject } from './SagaBase/FactoryObject';
import { FeatureInfoArgs } from './SagaBase/FeatureInfoArgs';
import { FeatureInfoRequestResult } from './SagaBase/FeatureInfoRequestResult';
import { FeatureResponse } from './SagaBase/FeatureResponse';
import { Field } from './SagaBase/Field';
import { FileAttachment } from './SagaBase/FileAttachment';
import { GenericLog } from './SagaBase/GenericLog';
import { GeoFence } from './SagaBase/GeoFence';
import { GeoFenceRequestArgs } from './SagaBase/GeoFenceRequestArgs';
import { GeoFenceRequestResult } from './SagaBase/GeoFenceRequestResult';
import { GeoInfoResultArgs } from './SagaBase/GeoInfoResultArgs';
import { GetParentOwnerRequest } from './SagaBase/GetParentOwnerRequest';
import { GetResourceListRequest } from './SagaBase/GetResourceListRequest';
import { HardwareDevice } from './SagaBase/HardwareDevice';
import { InterfaceMsg } from './SagaBase/InterfaceMsg';
import { ICustomerSpecificsResult } from './SagaBase/Interfaces/ICustomerSpecificsResult';
import { Interval } from './SagaBase/Interval';
import { IsochroneArgs } from './SagaBase/IsochroneArgs';
import { IsochroneResult } from './SagaBase/IsochroneResult';
import { IsochroneResults } from './SagaBase/IsochroneResults';
import { LearningUnit } from './SagaBase/LearningUnit';
import { ListArchiveType } from './SagaBase/ListArchiveType';
import { ListArchiveTypeEx } from './SagaBase/ListArchiveTypeEx';
import { ListDeviceType } from './SagaBase/ListDeviceType';
import { Location } from './SagaBase/Location';
import { Lock } from './SagaBase/Lock';
import { LogEntry } from './SagaBase/LogEntry';
import { MapLayer } from './SagaBase/MapLayer';
import { MapSectorLayer } from './SagaBase/MapSectorLayer';
import { MobilePosition } from './SagaBase/MobilePosition';
import { NumberQuestion } from './SagaBase/NumberQuestion';
import { NumberState } from './SagaBase/NumberState';
import { AVLPOSITIONS } from './SagaBase/Objects/AVLPOSITIONS';
import { AVLSTATUS } from './SagaBase/Objects/AVLSTATUS';
import { Agency } from './SagaBase/Objects/Agency';
import { AgencySpecificEvent } from './SagaBase/Objects/AgencySpecificEvent';
import { CurrentAlertsConfiguration } from './SagaBase/Objects/CurrentAlertsConfiguration';
import { DisabledAlertSite } from './SagaBase/Objects/DisabledAlertSite';
import { ExecutingTypeDefinition } from './SagaBase/Objects/ExecutingTypeDefinition';
import { ExportIntelData } from './SagaBase/Objects/ExportIntelData';
import { IDS } from './SagaBase/Objects/IDS';
import { Join } from './SagaBase/Objects/Join';
import { KeywordAddress } from './SagaBase/Objects/KeywordAddress';
import { KeywordSearchDef } from './SagaBase/Objects/KeywordSearchDef';
import { KeywordSearchFilters } from './SagaBase/Objects/KeywordSearchFilters';
import { KeywordSearchLinks } from './SagaBase/Objects/KeywordSearchLinks';
import { KeywordSearchObject } from './SagaBase/Objects/KeywordSearchObject';
import { KeywordSearchTypes } from './SagaBase/Objects/KeywordSearchTypes';
import { ListVideoProviders } from './SagaBase/Objects/ListVideoProviders';
import { LogEntryDefinition } from './SagaBase/Objects/LogEntryDefinition';
import { MapGroup } from './SagaBase/Objects/MapGroup';
import { MapIcon } from './SagaBase/Objects/MapIcon';
import { MapSymbol } from './SagaBase/Objects/MapSymbol';
import { MapSymbolDefinition } from './SagaBase/Objects/MapSymbolDefinition';
import { NodeSharedObject } from './SagaBase/Objects/NodeSharedObject';
import { Operand } from './SagaBase/Objects/Operand';
import { OperandFunction } from './SagaBase/Objects/OperandFunction';
import { Parameter } from './SagaBase/Objects/Parameter';
import { Pflau } from './SagaBase/Objects/Pflau';
import { PhoneFavorite } from './SagaBase/Objects/PhoneFavorite';
import { PhoneSession } from './SagaBase/Objects/PhoneSession';
import { QueryCondition } from './SagaBase/Objects/QueryCondition';
import { QueryDefinition } from './SagaBase/Objects/QueryDefinition';
import { QueryGroupCondition } from './SagaBase/Objects/QueryGroupCondition';
import { QueryPart } from './SagaBase/Objects/QueryPart';
import { QueryTables } from './SagaBase/Objects/QueryTables';
import { QueryValueElement } from './SagaBase/Objects/QueryValueElement';
import { ResCommunicationTypes } from './SagaBase/Objects/ResCommunicationTypes';
import { ResContactCategories } from './SagaBase/Objects/ResContactCategories';
import { ResStatus_Phone } from './SagaBase/Objects/ResStatus_Phone';
import { SagaSystemMessage } from './SagaBase/Objects/SagaSystemMessage';
import { ScheduledOpArgs } from './SagaBase/Objects/ScheduledOpArgs';
import { ScheduledOpReturnStatus } from './SagaBase/Objects/ScheduledOpReturnStatus';
import { ServiceDeployment } from './SagaBase/Objects/ServiceDeployment';
import { ServiceDeploymentResource } from './SagaBase/Objects/ServiceDeploymentResource';
import { SimplePhoneNumber } from './SagaBase/Objects/SimplePhoneNumber';
import { TextPanelMessage } from './SagaBase/Objects/TextPanelMessage';
import { TimerBase } from './SagaBase/Objects/TimerBase';
import { TimerItem } from './SagaBase/Objects/TimerItem';
import { TimerSchedule } from './SagaBase/Objects/TimerSchedule';
import { TransferFile } from './SagaBase/Objects/TransferFile';
import { TreeType } from './SagaBase/Objects/TreeType';
import { UnusedResourceData } from './SagaBase/Objects/UnusedResourceData';
import { Wakeup } from './SagaBase/Objects/Wakeup';
import { OwnerParent } from './SagaBase/OwnerParent';
import { PersistentObject } from './SagaBase/PersistentObject';
import { PhoneAddStampProcedureArgs } from './SagaBase/PhoneAddStampProcedureArgs';
import { PhoneCall } from './SagaBase/PhoneCall';
import { PhoneCallStamp } from './SagaBase/PhoneCallStamp';
import { PhoneCreateEventRes } from './SagaBase/PhoneCreateEventRes';
import { ProbabilityNode } from './SagaBase/ProbabilityNode';
import { Procedure } from './SagaBase/Procedure';
import { ProcedureItem } from './SagaBase/ProcedureItem';
import { Profile } from './SagaBase/Profile';
import { QuestionState } from './SagaBase/QuestionState';
import { RbacAccessObject } from './SagaBase/RBAC/RbacAccessObject';
import { RbacObject } from './SagaBase/RBAC/RbacObject';
import { RbacOperation } from './SagaBase/RBAC/RbacOperation';
import { RbacPermission } from './SagaBase/RBAC/RbacPermission';
import { RbacProfile } from './SagaBase/RBAC/RbacProfile';
import { RbacRole } from './SagaBase/RBAC/RbacRole';
import { RbacSiteRoles } from './SagaBase/RBAC/RbacSiteRoles';
import { RbacUser } from './SagaBase/RBAC/RbacUser';
import { RbacUserRole } from './SagaBase/RBAC/RbacUserRole';
import { ReportDefinition } from './SagaBase/ReportDefinition';
import { ResourceInfoMessageArgs } from './SagaBase/ResourceInfoMessageArgs';
import { ResourcePositionLight } from './SagaBase/ResourcePositionLight';
import { ReverseGeoCodeArgs } from './SagaBase/ReverseGeoCodeArgs';
import { ReverseGeoResult } from './SagaBase/ReverseGeoResult';
import { RouteArgs } from './SagaBase/RouteArgs';
import { RouteResult } from './SagaBase/RouteResult';
import { RouteResults } from './SagaBase/RouteResults';
import { SagaObject } from './SagaBase/SagaObject';
import { SagaObjectManager } from './SagaBase/SagaObjectManager';
import { ScatterDataPoint } from './SagaBase/ScatterDataPoint';
import { ScheduledOp } from './SagaBase/ScheduledOp';
import { ScheduledOpResponse } from './SagaBase/ScheduledOpResponse';
import { Sector } from './SagaBase/Sector';
import { SettingBase } from './SagaBase/SettingBase';
import { Shift } from './SagaBase/Shift';
import { ShiftBase } from './SagaBase/ShiftBase';
import { SiteProfiles } from './SagaBase/SiteProfiles';
import { Stat } from './SagaBase/Stat';
import { State } from './SagaBase/State';
import { SystemProfile } from './SagaBase/SystemProfile';
import { TableDefinition } from './SagaBase/TableDefinition';
import { TaskSplit } from './SagaBase/TaskSplit';
import { TerminalNode } from './SagaBase/TerminalNode';
import { TextMessage } from './SagaBase/TextMessage';
import { TextObject } from './SagaBase/TextObject';
import { TmpTextObject } from './SagaBase/TmpTextObject';
import { TraceItem } from './SagaBase/TraceItem';
import { TrafficFlowArgs } from './SagaBase/TrafficFlowArgs';
import { TrafficFlowResult } from './SagaBase/TrafficFlowResult';
import { TrafficFlowResults } from './SagaBase/TrafficFlowResults';
import { TrafficIncidentResult } from './SagaBase/TrafficIncidentResult';
import { TrafficIncidentsArgs } from './SagaBase/TrafficIncidentsArgs';
import { TrafficIncidentsResults } from './SagaBase/TrafficIncidentsResults';
import { UnusedByType } from './SagaBase/UnusedByType';
import { User } from './SagaBase/User';
import { UserAccessibleRoles } from './SagaBase/UserAccessibleRoles';
import { UserActivity } from './SagaBase/UserActivity';
import { UserProfile } from './SagaBase/UserProfile';
import { UserSyncClientConnection } from './SagaBase/UserSyncClientConnection';
import { XmlNameValueItem } from './SagaBase/XmlNameValueItem';
import { Zone } from './SagaBase/Zone';
import { ZoneAdmin } from './SagaBase/ZoneAdmin';
import { ZoneAdminAssociation } from './SagaBase/ZoneAdminAssociation';
import { ZoneAggregation } from './SagaBase/ZoneAggregation';
import { ZoneAssociation } from './SagaBase/ZoneAssociation';
import { ZoneDB } from './SagaBase/ZoneDB';
import { ZoneState } from './SagaBase/ZoneState';
import { geoExtent } from './SagaBase/geoExtent';
import { geoPoint } from './SagaBase/geoPoint';
import { geoSectorType } from './SagaBase/geoSectorType';
import { Actor } from './SagaSchema/Actor';
import { AgencyProcedure } from './SagaSchema/AgencyProcedure';
import { AgencyService } from './SagaSchema/AgencyService';
import { AlarmContact } from './SagaSchema/AlarmContact';
import { AlarmCustomer } from './SagaSchema/AlarmCustomer';
import { AlarmCustomerContact } from './SagaSchema/AlarmCustomerContact';
import { AlarmDetector } from './SagaSchema/AlarmDetector';
import { AlarmFile } from './SagaSchema/AlarmFile';
import { AlarmStamp } from './SagaSchema/AlarmStamp';
import { AlarmTest } from './SagaSchema/AlarmTest';
import { AlarmTestStamp } from './SagaSchema/AlarmTestStamp';
import { AlarmTransmitter } from './SagaSchema/AlarmTransmitter';
import { AlarmUriDocument } from './SagaSchema/AlarmUriDocument';
import { AlarmsHistory } from './SagaSchema/AlarmsHistory';
import { AlarmsStampHistory } from './SagaSchema/AlarmsStampHistory';
import { ApplicationPurgeType } from './SagaSchema/ApplicationPurgeType';
import { CAlarm } from './SagaSchema/CAlarm';
import { CAnimal } from './SagaSchema/CAnimal';
import { CAttachment } from './SagaSchema/CAttachment';
import { CAttachmentBinaryData } from './SagaSchema/CAttachmentBinaryData';
import { CCaller } from './SagaSchema/CCaller';
import { CCallerBase } from './SagaSchema/CCallerBase';
import { CDeploymentPoint } from './SagaSchema/CDeploymentPoint';
import { CEvent } from './SagaSchema/CEvent';
import { CEventBase } from './SagaSchema/CEventBase';
import { CFormLink } from './SagaSchema/CFormLink';
import { CFreeText } from './SagaSchema/CFreeText';
import { CHistory } from './SagaSchema/CHistory';
import { CImpIdMapping } from './SagaSchema/CImpIdMapping';
import { CIntel } from './SagaSchema/CIntel';
import { CLocation } from './SagaSchema/CLocation';
import { CLocationBase } from './SagaSchema/CLocationBase';
import { CNote } from './SagaSchema/CNote';
import { CNoteBase } from './SagaSchema/CNoteBase';
import { CObject } from './SagaSchema/CObject';
import { CPatient } from './SagaSchema/CPatient';
import { CPatientDestinationLocation } from './SagaSchema/CPatientDestinationLocation';
import { CPatientLocation } from './SagaSchema/CPatientLocation';
import { CPerson } from './SagaSchema/CPerson';
import { CProcStep } from './SagaSchema/CProcStep';
import { CStamp } from './SagaSchema/CStamp';
import { CStampCard } from './SagaSchema/CStampCard';
import { CStampComJob } from './SagaSchema/CStampComJob';
import { CStampFree } from './SagaSchema/CStampFree';
import { CStampFreeText } from './SagaSchema/CStampFreeText';
import { CStampParticulars } from './SagaSchema/CStampParticulars';
import { CStampProcedure } from './SagaSchema/CStampProcedure';
import { CStampProcedureAction } from './SagaSchema/CStampProcedureAction';
import { CStampResource } from './SagaSchema/CStampResource';
import { CStampShare } from './SagaSchema/CStampShare';
import { CStampShareComponent } from './SagaSchema/CStampShareComponent';
import { CStampUnitActivity } from './SagaSchema/CStampUnitActivity';
import { CTacticalSituation } from './SagaSchema/CTacticalSituation';
import { CTacticalSituationBase } from './SagaSchema/CTacticalSituationBase';
import { CTacticalSituationTemplate } from './SagaSchema/CTacticalSituationTemplate';
import { CTaskRequirement } from './SagaSchema/CTaskRequirement';
import { CTopic } from './SagaSchema/CTopic';
import { CUnmanagedResourceActivity } from './SagaSchema/CUnmanagedResourceActivity';
import { CUnmanagedResourceActivityBase } from './SagaSchema/CUnmanagedResourceActivityBase';
import { CVehicle } from './SagaSchema/CVehicle';
import { CallCard } from './SagaSchema/CallCard';
import { ComJobOverview } from './SagaSchema/ComJobOverview';
import { CommunicationEquipment } from './SagaSchema/CommunicationEquipment';
import { CompUnit } from './SagaSchema/CompUnit';
import { Crew } from './SagaSchema/Crew';
import { CrewResource } from './SagaSchema/CrewResource';
import { CrewTask } from './SagaSchema/CrewTask';
import { CrisisAttachedFile } from './SagaSchema/CrisisAttachedFile';
import { CrisisAttachedFileInfo } from './SagaSchema/CrisisAttachedFileInfo';
import { CrisisCallCard } from './SagaSchema/CrisisCallCard';
import { CrisisCallCardProperties } from './SagaSchema/CrisisCallCardProperties';
import { CrisisComponentLink } from './SagaSchema/CrisisComponentLink';
import { CrisisMessage } from './SagaSchema/CrisisMessage';
import { CrisisMessageLocation } from './SagaSchema/CrisisMessageLocation';
import { CrisisMissionStatus } from './SagaSchema/CrisisMissionStatus';
import { CrisisMissionType } from './SagaSchema/CrisisMissionType';
import { CrisisSimpleCallCard } from './SagaSchema/CrisisSimpleCallCard';
import { CrisisSubEvent } from './SagaSchema/CrisisSubEvent';
import { CustomCallCard } from './SagaSchema/CustomCallCard';
import { DangerItem } from './SagaSchema/DangerItem';
import { DangerItemLink } from './SagaSchema/DangerItemLink';
import { DangerItemPhoneNumber } from './SagaSchema/DangerItemPhoneNumber';
import { DeferredAssignment } from './SagaSchema/DeferredAssignment';
import { DestinationType } from './SagaSchema/DestinationType';
import { DictionaryElement } from './SagaSchema/DictionaryElement';
import { DirectoryItem } from './SagaSchema/DirectoryItem';
import { Engagement } from './SagaSchema/Engagement';
import { EngagementResourceType } from './SagaSchema/EngagementResourceType';
import { EventComplement } from './SagaSchema/EventComplement';
import { EventDefinition } from './SagaSchema/EventDefinition';
import { EventGroup } from './SagaSchema/EventGroup';
import { EventNode } from './SagaSchema/EventNode';
import { EventQuestion } from './SagaSchema/EventQuestion';
import { EventQuestionDefinition } from './SagaSchema/EventQuestionDefinition';
import { EventTaskRequirement } from './SagaSchema/EventTaskRequirement';
import { EventText } from './SagaSchema/EventText';
import { EventText1 } from './SagaSchema/EventText1';
import { EventText2 } from './SagaSchema/EventText2';
import { EventText3 } from './SagaSchema/EventText3';
import { FileChunk } from './SagaSchema/FileChunk';
import { FileDescriptor } from './SagaSchema/FileDescriptor';
import { FileDescriptorHistory } from './SagaSchema/FileDescriptorHistory';
import { FlattenedCallCard } from './SagaSchema/FlattenedCallCard';
import { FormTemplate } from './SagaSchema/FormTemplate';
import { FullCrewResource } from './SagaSchema/FullCrewResource';
import { GeoAreasSectors } from './SagaSchema/GeoAreasSectors';
import { GeoSectorAssociation } from './SagaSchema/GeoSectorAssociation';
import { GeoSectorComplement } from './SagaSchema/GeoSectorComplement';
import { GeoSectorNode } from './SagaSchema/GeoSectorNode';
import { CCallerHistory } from './SagaSchema/History/CCallerHistory';
import { CEventHistory } from './SagaSchema/History/CEventHistory';
import { CLocationHistory } from './SagaSchema/History/CLocationHistory';
import { CNoteHistory } from './SagaSchema/History/CNoteHistory';
import { CTacticalSituationHistory } from './SagaSchema/History/CTacticalSituationHistory';
import { CUnmanagedResourceActivityHistory } from './SagaSchema/History/CUnmanagedResourceActivityHistory';
import { Intruder } from './SagaSchema/Intruder';
import { IntruderCandidate } from './SagaSchema/IntruderCandidate';
import { ListNameAction } from './SagaSchema/ListNameAction';
import { ListNameAssociation } from './SagaSchema/ListNameAssociation';
import { ApplicationIcons } from './SagaSchema/ListObjects/ApplicationIcons';
import { ListArchivingPeriods } from './SagaSchema/ListObjects/ListArchivingPeriods';
import { ListAvailabilityPourcent } from './SagaSchema/ListObjects/ListAvailabilityPourcent';
import { ListCallSource } from './SagaSchema/ListObjects/ListCallSource';
import { ListCardTypes } from './SagaSchema/ListObjects/ListCardTypes';
import { ListColors } from './SagaSchema/ListObjects/ListColors';
import { ListCommunictionType } from './SagaSchema/ListObjects/ListCommunictionType';
import { ListComputeState } from './SagaSchema/ListObjects/ListComputeState';
import { ListContactCategory } from './SagaSchema/ListObjects/ListContactCategory';
import { ListCountries } from './SagaSchema/ListObjects/ListCountries';
import { ListCrisisClassification } from './SagaSchema/ListObjects/ListCrisisClassification';
import { ListCrisisDashboardMessage } from './SagaSchema/ListObjects/ListCrisisDashboardMessage';
import { ListCrisisPertinence } from './SagaSchema/ListObjects/ListCrisisPertinence';
import { ListCrisisQualification } from './SagaSchema/ListObjects/ListCrisisQualification';
import { ListCrisisQuittance } from './SagaSchema/ListObjects/ListCrisisQuittance';
import { ListCrisisUsers } from './SagaSchema/ListObjects/ListCrisisUsers';
import { ListDetectorStatus } from './SagaSchema/ListObjects/ListDetectorStatus';
import { ListDetectorTypes } from './SagaSchema/ListObjects/ListDetectorTypes';
import { ListDialog } from './SagaSchema/ListObjects/ListDialog';
import { ListDispositionCode } from './SagaSchema/ListObjects/ListDispositionCode';
import { ListEmailPDFMailling } from './SagaSchema/ListObjects/ListEmailPDFMailling';
import { ListEquipmentType } from './SagaSchema/ListObjects/ListEquipmentType';
import { ListExports } from './SagaSchema/ListObjects/ListExports';
import { ListFunctions } from './SagaSchema/ListObjects/ListFunctions';
import { ListGPSMode } from './SagaSchema/ListObjects/ListGPSMode';
import { ListGenders } from './SagaSchema/ListObjects/ListGenders';
import { ListGeoSectors } from './SagaSchema/ListObjects/ListGeoSectors';
import { ListHandlingAgencies } from './SagaSchema/ListObjects/ListHandlingAgencies';
import { ListLanguages } from './SagaSchema/ListObjects/ListLanguages';
import { ListMapLayers } from './SagaSchema/ListObjects/ListMapLayers';
import { ListMedicalization } from './SagaSchema/ListObjects/ListMedicalization';
import { ListName } from './SagaSchema/ListObjects/ListName';
import { ListNameType } from './SagaSchema/ListObjects/ListNameType';
import { ListNameValue } from './SagaSchema/ListObjects/ListNameValue';
import { ListNodeTags } from './SagaSchema/ListObjects/ListNodeTags';
import { ListOrganizationTypes } from './SagaSchema/ListObjects/ListOrganizationTypes';
import { ListPersonQualifiers } from './SagaSchema/ListObjects/ListPersonQualifiers';
import { ListPriority } from './SagaSchema/ListObjects/ListPriority';
import { ListProjectTypes } from './SagaSchema/ListObjects/ListProjectTypes';
import { ListQualifications } from './SagaSchema/ListObjects/ListQualifications';
import { ListRadio } from './SagaSchema/ListObjects/ListRadio';
import { ListRanks } from './SagaSchema/ListObjects/ListRanks';
import { ListResourceTypes } from './SagaSchema/ListObjects/ListResourceTypes';
import { ListServices } from './SagaSchema/ListObjects/ListServices';
import { ListSvcTypes } from './SagaSchema/ListObjects/ListSvcTypes';
import { ListTerminationCode } from './SagaSchema/ListObjects/ListTerminationCode';
import { ListTitles } from './SagaSchema/ListObjects/ListTitles';
import { ListTransmitterStatus } from './SagaSchema/ListObjects/ListTransmitterStatus';
import { ListTransmitterTypes } from './SagaSchema/ListObjects/ListTransmitterTypes';
import { ListTreeTypes } from './SagaSchema/ListObjects/ListTreeTypes';
import { ListUnitTypes } from './SagaSchema/ListObjects/ListUnitTypes';
import { ListVehicleKinds } from './SagaSchema/ListObjects/ListVehicleKinds';
import { ListVehicleMakes } from './SagaSchema/ListObjects/ListVehicleMakes';
import { ListVehicleType } from './SagaSchema/ListObjects/ListVehicleType';
import { PhoneLine } from './SagaSchema/ListObjects/PhoneLine';
import { LocationGeometry } from './SagaSchema/LocationGeometry';
import { MediaMessage } from './SagaSchema/MediaMessage';
import { MobActor } from './SagaSchema/MobActor';
import { MobOffice } from './SagaSchema/MobOffice';
import { MobPerson } from './SagaSchema/MobPerson';
import { MobService } from './SagaSchema/MobService';
import { NotificationMessage } from './SagaSchema/NotificationMessage';
import { Office } from './SagaSchema/Office';
import { OfficeResource } from './SagaSchema/OfficeResource';
import { OrgOffice } from './SagaSchema/OrgOffice';
import { Organization } from './SagaSchema/Organization';
import { OrganizationAdmin } from './SagaSchema/OrganizationAdmin';
import { OrganizationService } from './SagaSchema/OrganizationService';
import { OrganizationServiceResource } from './SagaSchema/OrganizationServiceResource';
import { Person } from './SagaSchema/Person';
import { PhoneDirectoryItem } from './SagaSchema/PhoneDirectoryItem';
import { PhoneNumber } from './SagaSchema/PhoneNumber';
import { ProcStepComplement } from './SagaSchema/ProcStepComplement';
import { ProcStepComplements } from './SagaSchema/ProcStepComplements';
import { ProcStepGroup } from './SagaSchema/ProcStepGroup';
import { ProcStepHeader } from './SagaSchema/ProcStepHeader';
import { ProcStepService } from './SagaSchema/ProcStepService';
import { ProcedureActionConfigurationObjects } from './SagaSchema/ProcedureActionConfigurationObjects';
import { ProcedureResource } from './SagaSchema/ProcedureResource';
import { ProcedureStep } from './SagaSchema/ProcedureStep';
import { ProcedureUnit } from './SagaSchema/ProcedureUnit';
import { PropertyFormat } from './SagaSchema/PropertyFormat';
import { RTMEngagementType } from './SagaSchema/RTMEngagementType';
import { RTMResource } from './SagaSchema/RTMResource';
import { RTMSEngagementType } from './SagaSchema/RTMSEngagementType';
import { RTMSResource } from './SagaSchema/RTMSResource';
import { Radio } from './SagaSchema/Radio';
import { RequestFollowup } from './SagaSchema/RequestFollowup';
import { ResAddress } from './SagaSchema/ResAddress';
import { ResComplement } from './SagaSchema/ResComplement';
import { ResVideo } from './SagaSchema/ResVideo';
import { ResourcePosition } from './SagaSchema/ResourcePosition';
import { ResourcePositionHistory } from './SagaSchema/ResourcePositionHistory';
import { ResourceServiceSchedule } from './SagaSchema/ResourceServiceSchedule';
import { ResourceTypeManager } from './SagaSchema/ResourceTypeManager';
import { ResourceTypeManagerData } from './SagaSchema/ResourceTypeManagerData';
import { ResourceTypeManagerSite } from './SagaSchema/ResourceTypeManagerSite';
import { ResourceTypeManagerType } from './SagaSchema/ResourceTypeManagerType';
import { ResourceUriDocument } from './SagaSchema/ResourceUriDocument';
import { Schedule } from './SagaSchema/Schedule';
import { ScheduledServiceResource } from './SagaSchema/ScheduledServiceResource';
import { Service } from './SagaSchema/Service';
import { ServiceFolderItem } from './SagaSchema/ServiceFolderItem';
import { ServiceResource } from './SagaSchema/ServiceResource';
import { ServiceResourceLight } from './SagaSchema/ServiceResourceLight';
import { ServiceResourceSector } from './SagaSchema/ServiceResourceSector';
import { ServiceResourceSectorEx } from './SagaSchema/ServiceResourceSectorEx';
import { SimpleCallCard } from './SagaSchema/SimpleCallCard';
import { SiteProfile } from './SagaSchema/SiteProfile';
import { SourceType } from './SagaSchema/SourceType';
import { Task } from './SagaSchema/Task';
import { TaskFrequency } from './SagaSchema/TaskFrequency';
import { TaskRequirementSuggestions } from './SagaSchema/TaskRequirementSuggestions';
import { TaskResource } from './SagaSchema/TaskResource';
import { Team } from './SagaSchema/Team';
import { TeamAdmin } from './SagaSchema/TeamAdmin';
import { Unit } from './SagaSchema/Unit';
import { UnitActivity } from './SagaSchema/UnitActivity';
import { UnitActivityContent } from './SagaSchema/UnitActivityContent';
import { UnitActivityLog } from './SagaSchema/UnitActivityLog';
import { UnitActivityLogCallCard } from './SagaSchema/UnitActivityLogCallCard';
import { UnitActivityResource } from './SagaSchema/UnitActivityResource';
import { UnitResource } from './SagaSchema/UnitResource';
import { UnitService } from './SagaSchema/UnitService';
import { UnitServiceSector } from './SagaSchema/UnitServiceSector';
import { UriDocument } from './SagaSchema/UriDocument';
import { Vehicle } from './SagaSchema/Vehicle';
import { geoSectorEscalation } from './SagaSchema/geoSectorEscalation';
import { glblScheduleDescriptions } from './SagaSchema/glblScheduleDescriptions';
import { glblScheduleTemplates } from './SagaSchema/glblScheduleTemplates';
import { resResourceSchedules } from './SagaSchema/resResourceSchedules';
import { ListCardStatus } from './SagaStateLib/ListCardStatus';
import { ListProcedureStatus } from './SagaStateLib/ListProcedureStatus';
import { ListResourceGps } from './SagaStateLib/ListResourceGps';
import { ListResourceStatus } from './SagaStateLib/ListResourceStatus';
import { ListStatusCollection } from './SagaStateLib/ListStatusCollection';
import { ListTaskStatus } from './SagaStateLib/ListTaskStatus';
import { StatusItems } from './SagaStateLib/StatusItems';
import { StateTimerDefinition } from './SagaTimers/StateTimerDefinition';
import { MobEvents } from './TW/Saga/Core/Base/Mobile/MobEvents';

@Injectable()
export class IdToTypeConverter {
  private static __constructorByTypeId = {
    'SagaActionLib.ActionCondition': ActionCondition,
    'SagaActionLib.ActionConditionGroup': ActionConditionGroup,
    'SagaActionLib.ActionConfigurationContainer': ActionConfigurationContainer,
    'SagaActionLib.ConditionPart': ConditionPart,
    'SagaActionLib.ProcedureAction': ProcedureAction,
    'SagaActionLib.ProcedureActionCondition': ProcedureActionCondition,
    'SagaActionLib.ProcedureActionContext': ProcedureActionContext,
    'SagaActionLib.ProcedureActionContextConfigurationBase': ProcedureActionContextConfigurationBase,
    'SagaActionLib.ProcedureEditorInterface': ProcedureEditorInterface,
    'SagaBase.Address': Address,
    'SagaBase.AddressComplement': AddressComplement,
    'SagaBase.AddressComplementArgs': AddressComplementArgs,
    'SagaBase.AlarmInfo': AlarmInfo,
    'SagaBase.AlarmStateChangedBuffer': AlarmStateChangedBuffer,
    'SagaBase.AlternateUserProfile': AlternateUserProfile,
    'SagaBase.ApplicationAccess': ApplicationAccess,
    'SagaBase.ApplicationAccessFilter': ApplicationAccessFilter,
    'SagaBase.AppSetting': AppSetting,
    'SagaBase.AppSettingApplication': AppSettingApplication,
    'SagaBase.AppSettingScope': AppSettingScope,
    'SagaBase.AppSettingsScopeTypes': AppSettingsScopeTypes,
    'SagaBase.AppSettingsSetting': AppSettingsSetting,
    'SagaBase.ArchiveResult': ArchiveResult,
    'SagaBase.Area': Area,
    'SagaBase.AttachmentBase': AttachmentBase,
    'SagaBase.AvailableMember': AvailableMember,
    'SagaBase.BoolQuestion': BoolQuestion,
    'SagaBase.BoolState': BoolState,
    'SagaBase.BootObject': BootObject,
    'SagaBase.BootObject_SL': BootObject_SL,
    'SagaBase.BroadcastMessageArgs': BroadcastMessageArgs,
    'SagaBase.CardInitializer': CardInitializer,
    'SagaBase.ChoiceQuestion': ChoiceQuestion,
    'SagaBase.ChoiceState': ChoiceState,
    'SagaBase.CommunicationTypeDefinition': CommunicationTypeDefinition,
    'SagaBase.Context': Context,
    'SagaBase.DecisionNode': DecisionNode,
    'SagaBase.DecisionTreeNode': DecisionTreeNode,
    'SagaBase.Defines.ActivityStatusArgs': ActivityStatusArgs,
    'SagaBase.Defines.ActivityStatusResults': ActivityStatusResults,
    'SagaBase.Defines.AddAttachmentArgs': AddAttachmentArgs,
    'SagaBase.Defines.AlarmStateChangeRequest': AlarmStateChangeRequest,
    'SagaBase.Defines.AlarmStateChangeResponse': AlarmStateChangeResponse,
    'SagaBase.Defines.ArchiveCardsArgs': ArchiveCardsArgs,
    'SagaBase.Defines.AtomicArgs': AtomicArgs,
    'SagaBase.Defines.AtomicOperation': AtomicOperation,
    'SagaBase.Defines.AtomicResult': AtomicResult,
    'SagaBase.Defines.AvailabilityRatios': AvailabilityRatios,
    'SagaBase.Defines.BootEventArgs': BootEventArgs,
    'SagaBase.Defines.BootEventArgs_SL': BootEventArgs_SL,
    'SagaBase.Defines.CallcardCreateArgs': CallcardCreateArgs,
    'SagaBase.Defines.ComjobArgs': ComjobArgs,
    'SagaBase.Defines.CommArgs': CommArgs,
    'SagaBase.Defines.CommTarget': CommTarget,
    'SagaBase.Defines.ComputeStateArgs': ComputeStateArgs,
    'SagaBase.Defines.ComResult': ComResult,
    'SagaBase.Defines.CopyScheduleArgs': CopyScheduleArgs,
    'SagaBase.Defines.CreateMobilisationArgs': CreateMobilisationArgs,
    'SagaBase.Defines.CrisisCardCreateArgs': CrisisCardCreateArgs,
    'SagaBase.Defines.CrisisFieldInfo': CrisisFieldInfo,
    'SagaBase.Defines.DataMessage': DataMessage,
    'SagaBase.Defines.DeferArgs': DeferArgs,
    'SagaBase.Defines.Ellipse': Ellipse,
    'SagaBase.Defines.EtdResult': EtdResult,
    'SagaBase.Defines.EventRequirements': EventRequirements,
    'SagaBase.Defines.ExportArgs': ExportArgs,
    'SagaBase.Defines.GeoSectorArgs': GeoSectorArgs,
    'SagaBase.Defines.GetStampsByAlarmHistoryArgs': GetStampsByAlarmHistoryArgs,
    'SagaBase.Defines.IntelResultArgs': IntelResultArgs,
    'SagaBase.Defines.KeywordSearchArgs': KeywordSearchArgs,
    'SagaBase.Defines.LinkCardsArgs': LinkCardsArgs,
    'SagaBase.Defines.LinkGroupDescription': LinkGroupDescription,
    'SagaBase.Defines.LinkToParentCardArgs': LinkToParentCardArgs,
    'SagaBase.Defines.LinkToParentCardResult': LinkToParentCardResult,
    'SagaBase.Defines.ListTypeInclusion': ListTypeInclusion,
    'SagaBase.Defines.LockArgs': LockArgs,
    'SagaBase.Defines.MapSector': MapSector,
    'SagaBase.Defines.MobileMessageArgs': MobileMessageArgs,
    'SagaBase.Defines.MobileMessageResult': MobileMessageResult,
    'SagaBase.Defines.MobilisationResultArgs': MobilisationResultArgs,
    'SagaBase.Defines.PasswordArgs': PasswordArgs,
    'SagaBase.Defines.PasswordReturnArgs': PasswordReturnArgs,
    'SagaBase.Defines.PflauSpecificPhoneNumberArgs': PflauSpecificPhoneNumberArgs,
    'SagaBase.Defines.ProcedureArgs': ProcedureArgs,
    'SagaBase.Defines.ProcedureResultArgs': ProcedureResultArgs,
    'SagaBase.Defines.ProcedureSuggestionResultArgs': ProcedureSuggestionResultArgs,
    'SagaBase.Defines.ReactivateCardsArgs': ReactivateCardsArgs,
    'SagaBase.Defines.ReactivateCardsResult': ReactivateCardsResult,
    'SagaBase.Defines.ReadSchElementObjectArgs': ReadSchElementObjectArgs,
    'SagaBase.Defines.ResourcePlanningInfo': ResourcePlanningInfo,
    'SagaBase.Defines.ResourceStatusArgs': ResourceStatusArgs,
    'SagaBase.Defines.SagaResult': SagaResult,
    'SagaBase.Defines.SaveProcedureResultArgs': SaveProcedureResultArgs,
    'SagaBase.Defines.ScheduleDatePeriod': ScheduleDatePeriod,
    'SagaBase.Defines.SearchArgs': SearchArgs,
    'SagaBase.Defines.SearchResourceArgs': SearchResourceArgs,
    'SagaBase.Defines.SearchResults': SearchResults,
    'SagaBase.Defines.SectorisationArgs': SectorisationArgs,
    'SagaBase.Defines.SectorStatusArgs': SectorStatusArgs,
    'SagaBase.Defines.StampStatusChangeResponse': StampStatusChangeResponse,
    'SagaBase.Defines.StatusArgs': StatusArgs,
    'SagaBase.Defines.TimerScheduleOperation': TimerScheduleOperation,
    'SagaBase.Defines.TimerScheduleResultArgs': TimerScheduleResultArgs,
    'SagaBase.Defines.UndoDeleteCardComponentArgs': UndoDeleteCardComponentArgs,
    'SagaBase.Defines.UnlinkFromParentCardArgs': UnlinkFromParentCardArgs,
    'SagaBase.Defines.UserAccessArgs': UserAccessArgs,
    'SagaBase.Defines.UserAccessReturnArgs': UserAccessReturnArgs,
    'SagaBase.DummyDecisionTreeNode': DummyDecisionTreeNode,
    'SagaBase.ETDAddress': ETDAddress,
    'SagaBase.Factory.ScannedAssemblies': ScannedAssemblies,
    'SagaBase.Factory.ScannedAssembly': ScannedAssembly,
    'SagaBase.FactoryEditor': FactoryEditor,
    'SagaBase.FactoryObject': FactoryObject,
    'SagaBase.FeatureInfoArgs': FeatureInfoArgs,
    'SagaBase.FeatureInfoRequestResult': FeatureInfoRequestResult,
    'SagaBase.FeatureResponse': FeatureResponse,
    'SagaBase.Field': Field,
    'SagaBase.FileAttachment': FileAttachment,
    'SagaBase.GenericLog': GenericLog,
    'SagaBase.geoExtent': geoExtent,
    'SagaBase.GeoFence': GeoFence,
    'SagaBase.GeoFenceRequestArgs': GeoFenceRequestArgs,
    'SagaBase.GeoFenceRequestResult': GeoFenceRequestResult,
    'SagaBase.GeoInfoResultArgs': GeoInfoResultArgs,
    'SagaBase.geoPoint': geoPoint,
    'SagaBase.geoSectorType': geoSectorType,
    'SagaBase.GetParentOwnerRequest': GetParentOwnerRequest,
    'SagaBase.GetResourceListRequest': GetResourceListRequest,
    'SagaBase.HardwareDevice': HardwareDevice,
    'SagaBase.InterfaceMsg': InterfaceMsg,
    'SagaBase.Interfaces.ICustomerSpecificsResult': ICustomerSpecificsResult,
    'SagaBase.Interval': Interval,
    'SagaBase.IsochroneArgs': IsochroneArgs,
    'SagaBase.IsochroneResult': IsochroneResult,
    'SagaBase.IsochroneResults': IsochroneResults,
    'SagaBase.LearningUnit': LearningUnit,
    'SagaBase.ListArchiveType': ListArchiveType,
    'SagaBase.ListArchiveTypeEx': ListArchiveTypeEx,
    'SagaBase.ListDeviceType': ListDeviceType,
    'SagaBase.Location': Location,
    'SagaBase.Lock': Lock,
    'SagaBase.LogEntry': LogEntry,
    'SagaBase.MapLayer': MapLayer,
    'SagaBase.MapSectorLayer': MapSectorLayer,
    'SagaBase.MobilePosition': MobilePosition,
    'SagaBase.NumberQuestion': NumberQuestion,
    'SagaBase.NumberState': NumberState,
    'SagaBase.Objects.Agency': Agency,
    'SagaBase.Objects.AgencySpecificEvent': AgencySpecificEvent,
    'SagaBase.Objects.AVLPOSITIONS': AVLPOSITIONS,
    'SagaBase.Objects.AVLSTATUS': AVLSTATUS,
    'SagaBase.Objects.CurrentAlertsConfiguration': CurrentAlertsConfiguration,
    'SagaBase.Objects.DisabledAlertSite': DisabledAlertSite,
    'SagaBase.Objects.ExecutingTypeDefinition': ExecutingTypeDefinition,
    'SagaBase.Objects.ExportIntelData': ExportIntelData,
    'SagaBase.Objects.IDS': IDS,
    'SagaBase.Objects.Join': Join,
    'SagaBase.Objects.KeywordAddress': KeywordAddress,
    'SagaBase.Objects.KeywordSearchDef': KeywordSearchDef,
    'SagaBase.Objects.KeywordSearchFilters': KeywordSearchFilters,
    'SagaBase.Objects.KeywordSearchLinks': KeywordSearchLinks,
    'SagaBase.Objects.KeywordSearchObject': KeywordSearchObject,
    'SagaBase.Objects.KeywordSearchTypes': KeywordSearchTypes,
    'SagaBase.Objects.ListVideoProviders': ListVideoProviders,
    'SagaBase.Objects.LogEntryDefinition': LogEntryDefinition,
    'SagaBase.Objects.MapGroup': MapGroup,
    'SagaBase.Objects.MapIcon': MapIcon,
    'SagaBase.Objects.MapSymbol': MapSymbol,
    'SagaBase.Objects.MapSymbolDefinition': MapSymbolDefinition,
    'SagaBase.Objects.NodeSharedObject': NodeSharedObject,
    'SagaBase.Objects.Operand': Operand,
    'SagaBase.Objects.OperandFunction': OperandFunction,
    'SagaBase.Objects.Parameter': Parameter,
    'SagaBase.Objects.Pflau': Pflau,
    'SagaBase.Objects.PhoneFavorite': PhoneFavorite,
    'SagaBase.Objects.PhoneSession': PhoneSession,
    'SagaBase.Objects.QueryCondition': QueryCondition,
    'SagaBase.Objects.QueryDefinition': QueryDefinition,
    'SagaBase.Objects.QueryGroupCondition': QueryGroupCondition,
    'SagaBase.Objects.QueryPart': QueryPart,
    'SagaBase.Objects.QueryTables': QueryTables,
    'SagaBase.Objects.QueryValueElement': QueryValueElement,
    'SagaBase.Objects.ResCommunicationTypes': ResCommunicationTypes,
    'SagaBase.Objects.ResContactCategories': ResContactCategories,
    'SagaBase.Objects.ResStatus_Phone': ResStatus_Phone,
    'SagaBase.Objects.SagaSystemMessage': SagaSystemMessage,
    'SagaBase.Objects.ScheduledOpArgs': ScheduledOpArgs,
    'SagaBase.Objects.ScheduledOpReturnStatus': ScheduledOpReturnStatus,
    'SagaBase.Objects.ServiceDeployment': ServiceDeployment,
    'SagaBase.Objects.ServiceDeploymentResource': ServiceDeploymentResource,
    'SagaBase.Objects.SimplePhoneNumber': SimplePhoneNumber,
    'SagaBase.Objects.TextPanelMessage': TextPanelMessage,
    'SagaBase.Objects.TimerBase': TimerBase,
    'SagaBase.Objects.TimerItem': TimerItem,
    'SagaBase.Objects.TimerSchedule': TimerSchedule,
    'SagaBase.Objects.TransferFile': TransferFile,
    'SagaBase.Objects.TreeType': TreeType,
    'SagaBase.Objects.UnusedResourceData': UnusedResourceData,
    'SagaBase.Objects.Wakeup': Wakeup,
    'SagaBase.OwnerParent': OwnerParent,
    'SagaBase.PersistentObject': PersistentObject,
    'SagaBase.PhoneAddStampProcedureArgs': PhoneAddStampProcedureArgs,
    'SagaBase.PhoneCall': PhoneCall,
    'SagaBase.PhoneCallStamp': PhoneCallStamp,
    'SagaBase.PhoneCreateEventRes': PhoneCreateEventRes,
    'SagaBase.ProbabilityNode': ProbabilityNode,
    'SagaBase.Procedure': Procedure,
    'SagaBase.ProcedureItem': ProcedureItem,
    'SagaBase.Profile': Profile,
    'SagaBase.QuestionState': QuestionState,
    'SagaBase.RBAC.RbacAccessObject': RbacAccessObject,
    'SagaBase.RBAC.RbacObject': RbacObject,
    'SagaBase.RBAC.RbacOperation': RbacOperation,
    'SagaBase.RBAC.RbacPermission': RbacPermission,
    'SagaBase.RBAC.RbacProfile': RbacProfile,
    'SagaBase.RBAC.RbacRole': RbacRole,
    'SagaBase.RBAC.RbacSiteRoles': RbacSiteRoles,
    'SagaBase.RBAC.RbacUser': RbacUser,
    'SagaBase.RBAC.RbacUserRole': RbacUserRole,
    'SagaBase.ReportDefinition': ReportDefinition,
    'SagaBase.ResourceInfoMessageArgs': ResourceInfoMessageArgs,
    'SagaBase.ResourcePositionLight': ResourcePositionLight,
    'SagaBase.ReverseGeoCodeArgs': ReverseGeoCodeArgs,
    'SagaBase.ReverseGeoResult': ReverseGeoResult,
    'SagaBase.RouteArgs': RouteArgs,
    'SagaBase.RouteResult': RouteResult,
    'SagaBase.RouteResults': RouteResults,
    'SagaBase.SagaObject': SagaObject,
    'SagaBase.SagaObjectManager': SagaObjectManager,
    'SagaBase.ScatterDataPoint': ScatterDataPoint,
    'SagaBase.ScheduledOp': ScheduledOp,
    'SagaBase.ScheduledOpResponse': ScheduledOpResponse,
    'SagaBase.Sector': Sector,
    'SagaBase.SettingBase': SettingBase,
    'SagaBase.Shift': Shift,
    'SagaBase.ShiftBase': ShiftBase,
    'SagaBase.SiteProfiles': SiteProfiles,
    'SagaBase.Stat': Stat,
    'SagaBase.State': State,
    'SagaBase.SystemProfile': SystemProfile,
    'SagaBase.TableDefinition': TableDefinition,
    'SagaBase.TaskSplit': TaskSplit,
    'SagaBase.TerminalNode': TerminalNode,
    'SagaBase.TextMessage': TextMessage,
    'SagaBase.TextObject': TextObject,
    'SagaBase.TmpTextObject': TmpTextObject,
    'SagaBase.TraceItem': TraceItem,
    'SagaBase.TrafficFlowArgs': TrafficFlowArgs,
    'SagaBase.TrafficFlowResult': TrafficFlowResult,
    'SagaBase.TrafficFlowResults': TrafficFlowResults,
    'SagaBase.TrafficIncidentResult': TrafficIncidentResult,
    'SagaBase.TrafficIncidentsArgs': TrafficIncidentsArgs,
    'SagaBase.TrafficIncidentsResults': TrafficIncidentsResults,
    'SagaBase.UnusedByType': UnusedByType,
    'SagaBase.User': User,
    'SagaBase.UserAccessibleRoles': UserAccessibleRoles,
    'SagaBase.UserActivity': UserActivity,
    'SagaBase.UserProfile': UserProfile,
    'SagaBase.UserSyncClientConnection': UserSyncClientConnection,
    'SagaBase.XmlNameValueItem': XmlNameValueItem,
    'SagaBase.Zone': Zone,
    'SagaBase.ZoneAdmin': ZoneAdmin,
    'SagaBase.ZoneAdminAssociation': ZoneAdminAssociation,
    'SagaBase.ZoneAggregation': ZoneAggregation,
    'SagaBase.ZoneAssociation': ZoneAssociation,
    'SagaBase.ZoneDB': ZoneDB,
    'SagaBase.ZoneState': ZoneState,
    'SagaSchema.Actor': Actor,
    'SagaSchema.AgencyProcedure': AgencyProcedure,
    'SagaSchema.AgencyService': AgencyService,
    'SagaSchema.AlarmContact': AlarmContact,
    'SagaSchema.AlarmCustomer': AlarmCustomer,
    'SagaSchema.AlarmCustomerContact': AlarmCustomerContact,
    'SagaSchema.AlarmDetector': AlarmDetector,
    'SagaSchema.AlarmFile': AlarmFile,
    'SagaSchema.AlarmsHistory': AlarmsHistory,
    'SagaSchema.AlarmsStampHistory': AlarmsStampHistory,
    'SagaSchema.AlarmStamp': AlarmStamp,
    'SagaSchema.AlarmTest': AlarmTest,
    'SagaSchema.AlarmTestStamp': AlarmTestStamp,
    'SagaSchema.AlarmTransmitter': AlarmTransmitter,
    'SagaSchema.AlarmUriDocument': AlarmUriDocument,
    'SagaSchema.ApplicationPurgeType': ApplicationPurgeType,
    'SagaSchema.CAlarm': CAlarm,
    'SagaSchema.CallCard': CallCard,
    'SagaSchema.CallCardDispatch': CallCardDispatch,
    'SagaSchema.CAnimal': CAnimal,
    'SagaSchema.CAttachment': CAttachment,
    'SagaSchema.CAttachmentBinaryData': CAttachmentBinaryData,
    'SagaSchema.CCaller': CCaller,
    'SagaSchema.CCallerBase': CCallerBase,
    'SagaSchema.CDeploymentPoint': CDeploymentPoint,
    'SagaSchema.CEvent': CEvent,
    'SagaSchema.CEventBase': CEventBase,
    'SagaSchema.CFormLink': CFormLink,
    'SagaSchema.CFreeText': CFreeText,
    'SagaSchema.CHistory': CHistory,
    'SagaSchema.CImpIdMapping': CImpIdMapping,
    'SagaSchema.CIntel': CIntel,
    'SagaSchema.CLocation': CLocation,
    'SagaSchema.CLocationBase': CLocationBase,
    'SagaSchema.CNote': CNote,
    'SagaSchema.CNoteBase': CNoteBase,
    'SagaSchema.CObject': CObject,
    'SagaSchema.ComJobOverview': ComJobOverview,
    'SagaSchema.CommunicationEquipment': CommunicationEquipment,
    'SagaSchema.CompUnit': CompUnit,
    'SagaSchema.CPatient': CPatient,
    'SagaSchema.CPatientDestinationLocation': CPatientDestinationLocation,
    'SagaSchema.CPatientLocation': CPatientLocation,
    'SagaSchema.CPerson': CPerson,
    'SagaSchema.CProcStep': CProcStep,
    'SagaSchema.Crew': Crew,
    'SagaSchema.CrewResource': CrewResource,
    'SagaSchema.CrewTask': CrewTask,
    'SagaSchema.CrisisAttachedFile': CrisisAttachedFile,
    'SagaSchema.CrisisAttachedFileInfo': CrisisAttachedFileInfo,
    'SagaSchema.CrisisCallCard': CrisisCallCard,
    'SagaSchema.CrisisCallCardProperties': CrisisCallCardProperties,
    'SagaSchema.CrisisComponentLink': CrisisComponentLink,
    'SagaSchema.CrisisMessage': CrisisMessage,
    'SagaSchema.CrisisMessageLocation': CrisisMessageLocation,
    'SagaSchema.CrisisMissionStatus': CrisisMissionStatus,
    'SagaSchema.CrisisMissionType': CrisisMissionType,
    'SagaSchema.CrisisSimpleCallCard': CrisisSimpleCallCard,
    'SagaSchema.CrisisSubEvent': CrisisSubEvent,
    'SagaSchema.CStamp': CStamp,
    'SagaSchema.CStampCard': CStampCard,
    'SagaSchema.CStampComJob': CStampComJob,
    'SagaSchema.CStampFree': CStampFree,
    'SagaSchema.CStampFreeText': CStampFreeText,
    'SagaSchema.CStampParticulars': CStampParticulars,
    'SagaSchema.CStampProcedure': CStampProcedure,
    'SagaSchema.CStampProcedureAction': CStampProcedureAction,
    'SagaSchema.CStampResource': CStampResource,
    'SagaSchema.CStampShare': CStampShare,
    'SagaSchema.CStampShareComponent': CStampShareComponent,
    'SagaSchema.CStampUnitActivity': CStampUnitActivity,
    'SagaSchema.CTacticalSituation': CTacticalSituation,
    'SagaSchema.CTacticalSituationBase': CTacticalSituationBase,
    'SagaSchema.CTacticalSituationTemplate': CTacticalSituationTemplate,
    'SagaSchema.CTaskRequirement': CTaskRequirement,
    'SagaSchema.CTopic': CTopic,
    'SagaSchema.CUnmanagedResourceActivity': CUnmanagedResourceActivity,
    'SagaSchema.CUnmanagedResourceActivityBase': CUnmanagedResourceActivityBase,
    'SagaSchema.CustomCallCard': CustomCallCard,
    'SagaSchema.CVehicle': CVehicle,
    'SagaSchema.DangerItem': DangerItem,
    'SagaSchema.DangerItemLink': DangerItemLink,
    'SagaSchema.DangerItemPhoneNumber': DangerItemPhoneNumber,
    'SagaSchema.DeferredAssignment': DeferredAssignment,
    'SagaSchema.DestinationType': DestinationType,
    'SagaSchema.DictionaryElement': DictionaryElement,
    'SagaSchema.DirectoryItem': DirectoryItem,
    'SagaSchema.Engagement': Engagement,
    'SagaSchema.EngagementResourceType': EngagementResourceType,
    'SagaSchema.EventComplement': EventComplement,
    'SagaSchema.EventDefinition': EventDefinition,
    'SagaSchema.EventGroup': EventGroup,
    'SagaSchema.EventNode': EventNode,
    'SagaSchema.EventQuestion': EventQuestion,
    'SagaSchema.EventQuestionDefinition': EventQuestionDefinition,
    'SagaSchema.EventTaskRequirement': EventTaskRequirement,
    'SagaSchema.EventText': EventText,
    'SagaSchema.EventText1': EventText1,
    'SagaSchema.EventText2': EventText2,
    'SagaSchema.EventText3': EventText3,
    'SagaSchema.FileChunk': FileChunk,
    'SagaSchema.FileDescriptor': FileDescriptor,
    'SagaSchema.FileDescriptorHistory': FileDescriptorHistory,
    'SagaSchema.FlattenedCallCard': FlattenedCallCard,
    'SagaSchema.FormTemplate': FormTemplate,
    'SagaSchema.FullCrewResource': FullCrewResource,
    'SagaSchema.GeoAreasSectors': GeoAreasSectors,
    'SagaSchema.GeoSectorAssociation': GeoSectorAssociation,
    'SagaSchema.GeoSectorComplement': GeoSectorComplement,
    'SagaSchema.geoSectorEscalation': geoSectorEscalation,
    'SagaSchema.GeoSectorNode': GeoSectorNode,
    'SagaSchema.glblScheduleDescriptions': glblScheduleDescriptions,
    'SagaSchema.glblScheduleTemplates': glblScheduleTemplates,
    'SagaSchema.History.CAnimalHistory': CAnimalHistory,
    'SagaSchema.History.CCallerHistory': CCallerHistory,
    'SagaSchema.History.CEventHistory': CEventHistory,
    'SagaSchema.History.CLocationHistory': CLocationHistory,
    'SagaSchema.History.CNoteHistory': CNoteHistory,
    'SagaSchema.History.CObjectHistory': CObjectHistory,
    'SagaSchema.History.CPatientHistory': CPatientHistory,
    'SagaSchema.History.CPersonHistory': CPersonHistory,
    'SagaSchema.History.CTacticalSituationHistory': CTacticalSituationHistory,
    'SagaSchema.History.CUnmanagedResourceActivityHistory': CUnmanagedResourceActivityHistory,
    'SagaSchema.History.CVehicleHistory': CVehicleHistory,
    'SagaSchema.Intruder': Intruder,
    'SagaSchema.IntruderCandidate': IntruderCandidate,
    'SagaSchema.ListNameAction': ListNameAction,
    'SagaSchema.ListNameAssociation': ListNameAssociation,
    'SagaSchema.ListObjects.ApplicationIcons': ApplicationIcons,
    'SagaSchema.ListObjects.ListArchivingPeriods': ListArchivingPeriods,
    'SagaSchema.ListObjects.ListAvailabilityPourcent': ListAvailabilityPourcent,
    'SagaSchema.ListObjects.ListCallSource': ListCallSource,
    'SagaSchema.ListObjects.ListCardTypes': ListCardTypes,
    'SagaSchema.ListObjects.ListColors': ListColors,
    'SagaSchema.ListObjects.ListCommunictionType': ListCommunictionType,
    'SagaSchema.ListObjects.ListComputeState': ListComputeState,
    'SagaSchema.ListObjects.ListContactCategory': ListContactCategory,
    'SagaSchema.ListObjects.ListCountries': ListCountries,
    'SagaSchema.ListObjects.ListCrisisClassification': ListCrisisClassification,
    'SagaSchema.ListObjects.ListCrisisDashboardMessage': ListCrisisDashboardMessage,
    'SagaSchema.ListObjects.ListCrisisPertinence': ListCrisisPertinence,
    'SagaSchema.ListObjects.ListCrisisQualification': ListCrisisQualification,
    'SagaSchema.ListObjects.ListCrisisQuittance': ListCrisisQuittance,
    'SagaSchema.ListObjects.ListCrisisUsers': ListCrisisUsers,
    'SagaSchema.ListObjects.ListDetectorStatus': ListDetectorStatus,
    'SagaSchema.ListObjects.ListDetectorTypes': ListDetectorTypes,
    'SagaSchema.ListObjects.ListDialog': ListDialog,
    'SagaSchema.ListObjects.ListDispositionCode': ListDispositionCode,
    'SagaSchema.ListObjects.ListEmailPDFMailling': ListEmailPDFMailling,
    'SagaSchema.ListObjects.ListEquipmentType': ListEquipmentType,
    'SagaSchema.ListObjects.ListExports': ListExports,
    'SagaSchema.ListObjects.ListFunctions': ListFunctions,
    'SagaSchema.ListObjects.ListGenders': ListGenders,
    'SagaSchema.ListObjects.ListGeoSectors': ListGeoSectors,
    'SagaSchema.ListObjects.ListGPSMode': ListGPSMode,
    'SagaSchema.ListObjects.ListHandlingAgencies': ListHandlingAgencies,
    'SagaSchema.ListObjects.ListLanguages': ListLanguages,
    'SagaSchema.ListObjects.ListMapLayers': ListMapLayers,
    'SagaSchema.ListObjects.ListMedicalization': ListMedicalization,
    'SagaSchema.ListObjects.ListName': ListName,
    'SagaSchema.ListObjects.ListNameType': ListNameType,
    'SagaSchema.ListObjects.ListNameValue': ListNameValue,
    'SagaSchema.ListObjects.ListNodeTags': ListNodeTags,
    'SagaSchema.ListObjects.ListOrganizationTypes': ListOrganizationTypes,
    'SagaSchema.ListObjects.ListPersonQualifiers': ListPersonQualifiers,
    'SagaSchema.ListObjects.ListPriority': ListPriority,
    'SagaSchema.ListObjects.ListProjectTypes': ListProjectTypes,
    'SagaSchema.ListObjects.ListQualifications': ListQualifications,
    'SagaSchema.ListObjects.ListRadio': ListRadio,
    'SagaSchema.ListObjects.ListRanks': ListRanks,
    'SagaSchema.ListObjects.ListResourceTypes': ListResourceTypes,
    'SagaSchema.ListObjects.ListServices': ListServices,
    'SagaSchema.ListObjects.ListSvcTypes': ListSvcTypes,
    'SagaSchema.ListObjects.ListTerminationCode': ListTerminationCode,
    'SagaSchema.ListObjects.ListTitles': ListTitles,
    'SagaSchema.ListObjects.ListTransmitterStatus': ListTransmitterStatus,
    'SagaSchema.ListObjects.ListTransmitterTypes': ListTransmitterTypes,
    'SagaSchema.ListObjects.ListTreeTypes': ListTreeTypes,
    'SagaSchema.ListObjects.ListUnitTypes': ListUnitTypes,
    'SagaSchema.ListObjects.ListVehicleKinds': ListVehicleKinds,
    'SagaSchema.ListObjects.ListVehicleMakes': ListVehicleMakes,
    'SagaSchema.ListObjects.ListVehicleType': ListVehicleType,
    'SagaSchema.ListObjects.PhoneLine': PhoneLine,
    'SagaSchema.LocationGeometry': LocationGeometry,
    'SagaSchema.MediaMessage': MediaMessage,
    'SagaSchema.MobActor': MobActor,
    'SagaSchema.MobOffice': MobOffice,
    'SagaSchema.MobPerson': MobPerson,
    'SagaSchema.MobService': MobService,
    'SagaSchema.NotificationMessage': NotificationMessage,
    'SagaSchema.Office': Office,
    'SagaSchema.OfficeResource': OfficeResource,
    'SagaSchema.Organization': Organization,
    'SagaSchema.OrganizationAdmin': OrganizationAdmin,
    'SagaSchema.OrganizationService': OrganizationService,
    'SagaSchema.OrganizationServiceResource': OrganizationServiceResource,
    'SagaSchema.OrgOffice': OrgOffice,
    'SagaSchema.Person': Person,
    'SagaSchema.PhoneDirectoryItem': PhoneDirectoryItem,
    'SagaSchema.PhoneNumber': PhoneNumber,
    'SagaSchema.ProcedureActionConfigurationObjects': ProcedureActionConfigurationObjects,
    'SagaSchema.ProcedureResource': ProcedureResource,
    'SagaSchema.ProcedureStep': ProcedureStep,
    'SagaSchema.ProcedureUnit': ProcedureUnit,
    'SagaSchema.ProcStepComplement': ProcStepComplement,
    'SagaSchema.ProcStepComplements': ProcStepComplements,
    'SagaSchema.ProcStepGroup': ProcStepGroup,
    'SagaSchema.ProcStepHeader': ProcStepHeader,
    'SagaSchema.ProcStepService': ProcStepService,
    'SagaSchema.PropertyFormat': PropertyFormat,
    'SagaSchema.Radio': Radio,
    'SagaSchema.RequestFollowup': RequestFollowup,
    'SagaSchema.ResAddress': ResAddress,
    'SagaSchema.ResComplement': ResComplement,
    'SagaSchema.ResourcePosition': ResourcePosition,
    'SagaSchema.ResourcePositionHistory': ResourcePositionHistory,
    'SagaSchema.ResourceServiceSchedule': ResourceServiceSchedule,
    'SagaSchema.ResourceTypeManager': ResourceTypeManager,
    'SagaSchema.ResourceTypeManagerData': ResourceTypeManagerData,
    'SagaSchema.ResourceTypeManagerSite': ResourceTypeManagerSite,
    'SagaSchema.ResourceTypeManagerType': ResourceTypeManagerType,
    'SagaSchema.ResourceUriDocument': ResourceUriDocument,
    'SagaSchema.resResourceSchedules': resResourceSchedules,
    'SagaSchema.ResVideo': ResVideo,
    'SagaSchema.RTMEngagementType': RTMEngagementType,
    'SagaSchema.RTMResource': RTMResource,
    'SagaSchema.RTMSEngagementType': RTMSEngagementType,
    'SagaSchema.RTMSResource': RTMSResource,
    'SagaSchema.Schedule': Schedule,
    'SagaSchema.ScheduledServiceResource': ScheduledServiceResource,
    'SagaSchema.Service': Service,
    'SagaSchema.ServiceFolderItem': ServiceFolderItem,
    'SagaSchema.ServiceResource': ServiceResource,
    'SagaSchema.ServiceResourceLight': ServiceResourceLight,
    'SagaSchema.ServiceResourceSector': ServiceResourceSector,
    'SagaSchema.ServiceResourceSectorEx': ServiceResourceSectorEx,
    'SagaSchema.SimpleCallCard': SimpleCallCard,
    'SagaSchema.SiteProfile': SiteProfile,
    'SagaSchema.SourceType': SourceType,
    'SagaSchema.Task': Task,
    'SagaSchema.TaskFrequency': TaskFrequency,
    'SagaSchema.TaskRequirementSuggestions': TaskRequirementSuggestions,
    'SagaSchema.TaskResource': TaskResource,
    'SagaSchema.Team': Team,
    'SagaSchema.TeamAdmin': TeamAdmin,
    'SagaSchema.Unit': Unit,
    'SagaSchema.UnitActivity': UnitActivity,
    'SagaSchema.UnitActivityContent': UnitActivityContent,
    'SagaSchema.UnitActivityLog': UnitActivityLog,
    'SagaSchema.UnitActivityLogCallCard': UnitActivityLogCallCard,
    'SagaSchema.UnitActivityResource': UnitActivityResource,
    'SagaSchema.UnitResource': UnitResource,
    'SagaSchema.UnitService': UnitService,
    'SagaSchema.UnitServiceSector': UnitServiceSector,
    'SagaSchema.UriDocument': UriDocument,
    'SagaSchema.Vehicle': Vehicle,
    'SagaStateLib.ListCardStatus': ListCardStatus,
    'SagaStateLib.ListProcedureStatus': ListProcedureStatus,
    'SagaStateLib.ListResourceGps': ListResourceGps,
    'SagaStateLib.ListResourceStatus': ListResourceStatus,
    'SagaStateLib.ListStatusCollection': ListStatusCollection,
    'SagaStateLib.ListTaskStatus': ListTaskStatus,
    'SagaStateLib.StatusItems': StatusItems,
    'SagaTimers.StateTimerDefinition': StateTimerDefinition,
    'TW.Saga.Core.Base.Mobile.MobEvents': MobEvents,
    'TW.Saga.Core.Schema.Callcard.CMediaMessage': CMediaMessage
  };

  static setCustomConstructor(constructor: typeof SagaObject) {
    IdToTypeConverter.__constructorByTypeId[constructor.$t] = constructor;
  }

  public getConstructorOf(typeId: string): any {
    return IdToTypeConverter.__constructorByTypeId[typeId];
  }

  public setConstructorOf(typeId: string, constructor: any) {
    IdToTypeConverter.__constructorByTypeId[typeId] = constructor;
  }
}
