import { MapName } from '@addins/core/core';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { ILocation } from '@models/interfaces/ILocation';
import { LocationFormatterFactoryService } from '@services/location/location-formatter-factory/location-formatter-factory.service';
import { MapInstancesService, transformOL } from '@services/map';
import { Overlay, Map as SagaMap } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MapShowAddressService } from '../../services/map-show-address/map-show-address.service';

@Component({
  selector: 'app-map-address-popup',
  templateUrl: './map-address-popup.component.html',
  styleUrls: ['./map-address-popup.component.scss']
})
export class MapAddressPopupComponent implements OnInit, OnDestroy {
  private map: SagaMap = null;
  private overlay: Overlay;

  private showAddressEventSub: Subscription = null;
  formattedAddress: string[] = null;

  constructor(
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private mapInstancesService: MapInstancesService,
    private mapShowAddress: MapShowAddressService,
    private locationFormatter: LocationFormatterFactoryService
  ) {}

  ngOnInit() {
    if (this.mapInstancesService.get(MapName.main) !== null) {
      this.setup();
    } else {
      this.mapInstancesService.$change
        .pipe(
          filter(event => event.name === MapName.main),
          first()
        )
        .subscribe(() => this.setup());
    }
  }

  private setup() {
    this.map = this.mapInstancesService.get(MapName.main);
    if (this.map !== null) {
      this.overlay = new Overlay(this.map, { element: this.element.nativeElement });
      this.overlay.setName('popup-address-overlay');
      this.overlay.setPositioning('bottom-center');

      this.showAddressEventSub = this.mapShowAddress.$event.subscribe(address => this.onShowAddress(address));
    }
  }

  private onShowAddress(address: ILocation) {
    if (address !== null) {
      this.formattedAddress = this.locationFormatter.getFormatter().format(address);
      this.changeDetector.detectChanges();

      const popupCoordinate: ol.Coordinate = this.calculatePopupCoordinate(address.coordinate, this.map.getView().getProjection());
      this.overlay.setPosition(popupCoordinate);
    } else {
      this.formattedAddress = null;
      this.overlay.hide();
    }
  }

  private calculatePopupCoordinate(addressCoordinate: Coordinate, mapProjection: any): ol.Coordinate {
    return transformOL([addressCoordinate.x, addressCoordinate.y], addressCoordinate.epsg, mapProjection);
  }

  ngOnDestroy() {
    if (this.showAddressEventSub) {
      this.showAddressEventSub.unsubscribe();
      this.showAddressEventSub = null;
    }
  }
}
