<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <span translate>static.settings.title</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-text showWhen="ios" translate>static.close</ion-text>
        <ion-icon name="close-outline" showWhen="android,desktop" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-list-header>
      <span translate>static.settings.general</span>
    </ion-list-header>
    <ion-item *ngIf="isDev">
      <ion-label class="badged-label">
        <span translate>static.settings.scale</span>
        <ion-badge slot="end">{{ scale }}%</ion-badge>
      </ion-label>
      <ion-range [(ngModel)]="scale" min="50" max="100">
        <ion-icon slot="start" size="small" name="image"></ion-icon>
        <ion-icon slot="end" name="image"></ion-icon>
      </ion-range>
    </ion-item>
    <ion-item *ngIf="isDev">
      <ion-label class="badged-label">
        <span translate>static.settings.fontsize</span>
        <ion-badge slot="end">{{ styles.fontSize }}</ion-badge>
      </ion-label>
      <ion-range [(ngModel)]="styles.fontSize" min="30" max="100">
        <ion-icon slot="start" size="small" name="image"></ion-icon>
        <ion-icon slot="end" name="image"></ion-icon>
      </ion-range>
    </ion-item>
    <ion-item>
      <ion-icon name="flag" slot="start"></ion-icon>
      <ion-label><span translate>static.settings.language</span></ion-label>
      <ion-select [(ngModel)]="language" (ngModelChange)="settingChange('lang', language)">
        <ion-select-option value="platform">Platform ({{ platformLanguage }})</ion-select-option>
        <ion-select-option value="fr">Français</ion-select-option>
        <ion-select-option value="en">English</ion-select-option>
        <ion-select-option value="ar" translate>Arabic</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-list-header>
      <span translate>static.settings.media</span>
    </ion-list-header>
    <ion-item>
      <ion-label class="badged-label">
        <span translate>static.settings.cameraquality</span>
        <ion-badge slot="end">{{ cameraQuality }}</ion-badge>
      </ion-label>
      <ion-range [(ngModel)]="cameraQuality" min="1" max="100" (ngModelChange)="settingChange('camera_quality', cameraQuality)">
        <ion-icon range-left size="small" name="image"></ion-icon>
        <ion-icon range-right name="image"></ion-icon>
      </ion-range>
    </ion-item>

    <ion-list-header>
      <span translate>static.settings.equipment</span>
    </ion-list-header>
    <ion-item *ngIf="equipment">
      <ion-icon [name]="equipment.icon" slot="start"></ion-icon>
      <ion-label>{{ deviceName }}</ion-label>
      <ion-button style="width: 40px;height: 40px;" slot="end" color="danger" (click)="clearData()"
        ><ion-icon slot="icon-only" name="trash"></ion-icon
      ></ion-button>
    </ion-item>

    <ng-container *ngFor="let group of settingsService.getGroups()">
      <ion-list-header>
        <span translate>Saga.Settings.{{ group }}</span>
      </ion-list-header>
      <div *ngFor="let setting of settingsService.getSettingsForGroup(group)" [ngSwitch]="setting.type">
        <ng-container *ngTemplateOutlet="settingsItem; context: { $implicit: setting }"></ng-container>
      </div>
    </ng-container>
  </ion-list>
</ion-content>

<ng-template #settingsItem let-settings>
  <ng-container [ngSwitch]="settings.type">
    <ion-item *ngSwitchCase="'range'">
      <ion-label class="badged-label">
        <span [translate]="settings.text"></span>
        <ion-badge slot="end">{{ settings.displayValue || settings.value }}</ion-badge>
      </ion-label>
      <ion-range [(ngModel)]="settings.value" [min]="settings.config.min" [max]="settings.config.max" [step]="settings.config.step || 1">
        <ion-icon slot="start" size="small" [name]="settings.icon"></ion-icon>
        <ion-icon slot="end" size="large" [name]="settings.icon"></ion-icon>
      </ion-range>
    </ion-item>

    <ion-item *ngSwitchCase="'input'">
      <ion-icon [name]="settings.icon" *ngIf="settings.icon" slot="start"></ion-icon>
      <ion-label><span [translate]="settings.text"></span></ion-label>
      <ion-input type="text" [(ngModel)]="settings.value"></ion-input>
    </ion-item>

    <ion-item *ngSwitchCase="'checkbox'">
      <ion-icon [name]="settings.icon" *ngIf="settings.icon" slot="start"></ion-icon>
      <ion-label><span [translate]="settings.text"></span></ion-label>
      <ion-checkbox slot="end" [(ngModel)]="settings.value"></ion-checkbox>
    </ion-item>

    <ion-item (click)="settings.handler && settings.handler($event, navCtrl)" *ngSwitchDefault>
      <ion-icon [name]="settings.icon" *ngIf="settings.icon" slot="tart"></ion-icon>
      <ion-label><span [translate]="settings.text"></span></ion-label>
      <ion-icon name="ios-arrow-forward-outline" slot="end" *ngIf="settings.handler"></ion-icon>
    </ion-item>
  </ng-container>
</ng-template>
