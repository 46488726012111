import { Injectable } from '@angular/core';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { IFlowDelegate, IFlowQuery, IReadFileArgs, IUploadQuery } from '@techwan/file-upload';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';
import { SagaFileUploadItem } from '../../schema/saga-file-upload-item';
import { FileReaderService } from '../file-reader/file-reader.service';

@Injectable()
export class FileDelegateService implements IFlowDelegate {
  constructor(private fileReader: FileReaderService, private tokenStorage: RefreshTokenStorageService) {}

  headers(query: IFlowQuery): any {
    return {
      SagaSecTok: this.tokenStorage.getToken()
    };
  }

  query(query: IFlowQuery): IUploadQuery {
    const item = query.item as SagaFileUploadItem;
    return {
      versionToken: item.versionToken,
      version: item.version
    };
  }

  readFile(item: IReadFileArgs): any {
    const upload = item.item;
    if (upload instanceof SagaFileEntryUploadItem) {
      this.fileReader
        .readFile(upload.getFile())
        .then(data => item.chunk.readFinished(new Blob([data.slice(item.start, item.end)]), item.type));
    }
  }
}
