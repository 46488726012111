<ion-item>
  <ion-label translate>Mobile.UnitName</ion-label>
  <ion-input disabled [value]="name"></ion-input>
</ion-item>

<ion-item>
  <ion-label translate>Mobile.MainResource</ion-label>
  <ion-select name="select-main" [value]="main && main.ObjGuid" (ionChange)="setName($event.detail.value)">
    <ion-select-option *ngFor="let res of mainResources" [value]="res.ObjGuid">{{ getItemText(res) }}</ion-select-option>
  </ion-select>
</ion-item>

<ion-item>
  <ion-label translate>Mobile.MainAgent</ion-label>
  <ion-select name="select-main" [value]="agent && agent.ObjGuid">
    <ion-select-option *ngFor="let res of agents" [value]="res.ObjGuid">{{ getItemText(res) }}</ion-select-option>
  </ion-select>
</ion-item>

<ion-item>
  <ion-label translate>Mobile.Communications</ion-label>
  <ion-select name="select-main" [value]="communication && communication.ObjGuid">
    <ion-select-option *ngFor="let res of communications" [value]="res.ObjGuid">{{ getItemText(res) }}</ion-select-option>
  </ion-select>
</ion-item>

<ion-item>
  <ion-label translate>Mobile.PositionProvider</ion-label>
  <ion-select name="select-main" [value]="position && position.ObjGuid">
    <ion-select-option *ngFor="let res of positions" [value]="res.ObjGuid">{{ getItemText(res) }}</ion-select-option>
  </ion-select>
</ion-item>

<ion-button (click)="cancel()" expand="full" translate>Mobile.Cancel</ion-button>
<ion-button (click)="validate()" fill="solid" expand="full" translate>Mobile.Ok</ion-button>

<div *ngIf="httpError !== null" translate>{{ httpError.message }}</div>
<div *ngIf="error === true" translate>Mobile.ServerError</div>
