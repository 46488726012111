import { LocationConfigurationProvider } from '@addins/core/core';
import { LocationSettingsManagerService } from '@addins/core/settings';
import { ListSettingValue } from '@addins/core/settings/schema/list-setting-value';
import { Injectable } from '@angular/core';
import { LocationConfig } from '@techwan/ionic-plugin-location';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationConfigService implements LocationConfigurationProvider {
  private locationConfig: LocationConfig = null;

  constructor(private locationSettingsManager: LocationSettingsManagerService) {}

  get $change(): Observable<LocationConfig> {
    return this.locationSettingsManager.settings.$change.pipe(map(settingValues => this.update(settingValues)));
  }

  getCurrent(): LocationConfig {
    if (this.locationConfig === null) {
      this.init();
    }
    return this.locationConfig;
  }

  private init() {
    const values = this.locationSettingsManager.settings;
    this.locationConfig = {
      DistanceFilter: values.getDistanceFilter(),
      ServerUpdatePeriod: values.getServerUpdate()
    };
  }

  private update(values: ListSettingValue[]): LocationConfig {
    const [serverUpdate, distanceFilter] = values;
    const config = this.getCurrent();
    config.ServerUpdatePeriod = serverUpdate.selected.value;
    config.DistanceFilter = distanceFilter.selected.value;

    return config;
  }
}
