// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ProcedureStep_abstract extends SagaObject {
  static $t: string = 'SagaSchema.ProcedureStep';
  $t: string = 'SagaSchema.ProcedureStep';

  public static GetMembers(): string[] {
    return [
      'ProcedureAction',
      'StepGuid',
      'StepGroupGuid',
      'StepName',
      'Info',
      'Description',
      'Url',
      'AutoOpen',
      'ItemSelect',
      'Ordering',
      'ServiceGrouping',
      'AltId',
      'DialogId',
      'OneClick',
      'ActionType',
      'ServiceAction',
      'ActionParameter',
      'SortOrder',
      'ArgbBackground',
      'FilterOptions'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ProcedureStep_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ProcedureAction: any;
  public StepGuid: Guid = GuidTools.Empty;
  public StepGroupGuid: Guid = GuidTools.Empty;
  public StepName: string = '';
  public Info: string = '';
  public Description: string;
  public Url: string;
  public AutoOpen: SagaBase.ProcStepOnInitializeCategory = SagaBase.ProcStepOnInitializeCategory.Nothing;
  public ItemSelect: boolean = false;
  public Ordering: SagaBase.OrderingCategory = SagaBase.OrderingCategory.ByDistance;
  public ServiceGrouping: boolean = false;
  public AltId: string;
  public DialogId: number;
  public OneClick: boolean = false;
  public ActionType: string;
  public ServiceAction: boolean = false;
  public ActionParameter: string;
  public SortOrder: number = 0;
  public ArgbBackground: number = -12192;
  public FilterOptions: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.StepGuid;
  }
  get ParentObjId(): any {
    return this.StepGroupGuid;
  }
  set ParentObjId(value: any) {
    this.StepGroupGuid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
