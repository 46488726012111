// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class HardwareDevice_abstract extends SagaObject {
  static $t: string = 'SagaBase.HardwareDevice';
  $t: string = 'SagaBase.HardwareDevice';

  public static GetMembers(): string[] {
    return [
      'Name',
      'OwnerDeviceIdentifier',
      'Description',
      'hwDeviceTypeId',
      'IsOn',
      'AssociatedDeviceUid',
      'IsOutputdevice',
      'IsSwitchable'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return HardwareDevice_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Name: string;
  public OwnerDeviceIdentifier: string;
  public Description: string;
  public hwDeviceTypeId: number = 0;
  public IsOn: boolean = false;
  public AssociatedDeviceUid: Guid = GuidTools.Empty;
  public IsOutputdevice: boolean = false;
  public IsSwitchable: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
