import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowOpenerService {
  constructor() {}

  system(uri: string): Window {
    return this.open(uri, '_system');
  }

  open(url: string, target?: string, features?: string): Window {
    return window.open(url, target, features);
  }
}
