import { Injectable } from '@angular/core';
import { StatusItemAction } from '@models/imported/SagaBase';
import { ListName } from '@models/imported/SagaSchema/ListObjects/ListName';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { CacheAction } from '@services/cache/cacheEvent';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FetchListProxyService } from './fetchlist-proxy.service';

@Injectable()
export class FetchListService {
  private readonly storage = {};
  private readonly _types = [
    'SagaStateLib.ListCardStatus',
    'SagaStateLib.ListResourceStatus',
    'SagaSchema.ListObjects.ListVehicleType',
    'SagaSchema.ListObjects.ListPersonQualifiers',
    'SagaSchema.ListObjects.ListCountries'
  ];

  private readonly _ready = new BehaviorSubject(false);
  public get $ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  public constructor(private readonly _proxy: FetchListProxyService) {}

  public getItemByAction<T = any>(listName: any, action: StatusItemAction): T {
    return this.getAllRows(listName).find(r => r.hasAction(action));
  }

  public getRow<T = any>(listName: any, search: string | number): T {
    if (typeof listName !== 'string') {
      listName = listName.$t;
    }
    return this.storage[listName + 'ByValue'] && this.storage[listName + 'ByValue'][search];
  }

  public getRowValue(listName: any, search: string | number, field: string = 'Name') {
    if (typeof listName !== 'string') {
      listName = listName.$t;
    }

    const row = this.getRow(listName, search);
    if (row) {
      return row[field];
    }
    return null;
  }

  public getAllRows<T = any>(listName: any): T[] {
    if (typeof listName !== 'string') {
      listName = listName.$t;
    }

    if (!listName) {
      return [];
    }
    const items = this.storage[listName];
    if (!items) {
      return [];
    }
    return items;
  }

  public isList(type: string): boolean {
    return this._types.indexOf(type) > -1;
  }

  public addObjToCache(obj, listName) {
    let instance: any = obj;

    if (!this.storage[listName]) {
      this.storage[listName] = [];
      this.storage[listName + 'ByValue'] = {};
    }

    // TODO: remove this code, we shouldn't have a temp patch for this but fix it properly
    // Temp patch as some list changed the field Value to Id without notice!
    if (instance.Value === undefined) {
      instance.Value = (instance as any).Id;
    }

    if (instance.Value === undefined) {
      return;
    }

    if (!this.storage[listName + 'ByValue'][instance.Value]) {
      this.storage[listName].push(instance);
      this.storage[listName + 'ByValue'][instance.Value] = instance;

      if (listName === ListNameValue.$t) {
        const parentListNameObj = this.getRow<ListName>(ListName.$t, (instance as ListNameValue).ListNameId);
        if (parentListNameObj) {
          listName = 'ListName.' + parentListNameObj.Name;
          this.initListName(listName);
          this.storage[listName].push(instance);
          this.storage[listName + 'ByValue'][instance.Value] = instance;
        }
      }

      instance.changed({
        obj: instance as any,
        action: CacheAction.added,
        fetchList: this,
        cache: null
      });
    } else {
      console.warn('fetchlist instance already in cache! ListName = ' + listName + ' value = ' + instance.Value);
      instance = this.storage[listName + 'ByValue'][instance.Value];
    }

    return instance;
  }

  public setup(): Observable<boolean> {
    // remove all if re-load
    this.clearStorage();

    return this._proxy.setup().pipe(map(d => this.parse(d)));
  }

  private clearStorage() {
    for (const k in this.storage) {
      if (this.storage.hasOwnProperty(k)) {
        delete this.storage[k];
      }
    }
  }

  private initListName(listName) {
    if (!this.storage[listName]) {
      this.storage[listName] = [];
      this.storage[listName + 'ByValue'] = {};
    }
  }

  private parse(data): boolean {
    for (const r in data) {
      if (data.hasOwnProperty(r)) {
        data[r].forEach(d => this.addObjToCache(d, d.$t));
      }
    }
    this._ready.next(true);
    return true;
  }
}
