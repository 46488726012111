import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CurrentCardService {
  private readonly _current = new BehaviorSubject<CallCard>(null);
  get $change(): Observable<CallCard> {
    return this._current.asObservable();
  }

  get current(): CallCard {
    return this._current.value;
  }

  constructor() {}

  toggle(callcard: CallCard): void {
    if (this.current === callcard) {
      this.reset();
    } else {
      this.select(callcard);
    }
  }

  select(callcard: CallCard): void {
    if (callcard !== this.current) {
      this._current.next(callcard);
    }
  }

  reset(): void {
    this.select(null);
  }
}
