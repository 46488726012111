import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { DisengagementSetupModalComponent } from './components/disengagement-setup-modal/disengagement-setup-modal.component';
import { EngagedPeopleListButtonComponent } from './components/engaged-people-list-button/engaged-people-list-button.component';
import { EngagementSetupModalComponent } from './components/engagement-setup-modal/engagement-setup-modal.component';
import { KeypadModalComponent } from './components/keypad-modal/keypad-modal.component';
import { ResourceCountCardComponent } from './components/resource-count-card/resource-count-card.component';
import { ResourceCountListComponent } from './components/resource-count-list/resource-count-list.component';
import { SearchVehicleModalComponent } from './components/search-vehicle-modal/search-vehicle-modal.component';
import { UnitCountCardComponent } from './components/unit-count-card/unit-count-card.component';
import { ResourceCountSetupService } from './services/executables/resource-count-setup/resource-count-setup.service';

export const RESOURCE_COUNT_ADDIN_NAME = 'ResourceCount';

const views = [];

const executables = [ResourceCountSetupService];

const providers = [...executables];

const components = [
  ...views,
  DisengagementSetupModalComponent,
  EngagedPeopleListButtonComponent,
  EngagementSetupModalComponent,
  KeypadModalComponent,
  ResourceCountCardComponent,
  ResourceCountListComponent,
  SearchVehicleModalComponent,
  UnitCountCardComponent
];

const directives = [];

@Addin({
  name: RESOURCE_COUNT_ADDIN_NAME,
  require: ['singlecallcard']
})
@NgModule({
  declarations: [...components, ...directives],
  providers,
  imports: [CommonModule, IonicModule, SagaDirectivesModule, TranslateModule]
})
export class ResourceCountModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
