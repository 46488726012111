import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { Observable } from 'rxjs';

@Injectable()
export class ResourceProxyService {
  constructor(private httpClient: HttpClient) {}

  getAvailableResources(offset: number, search: string): Observable<Vehicle[]> {
    return this.request<Vehicle>('GetVehicleList', { param: { offset, search } });
  }

  getActorList(offset: number, search: string): Observable<Actor[]> {
    return this.request<Actor>('GetActors', { param: { offset, search } });
  }

  getEquipmentList(offset: number, search: string): Observable<CommunicationEquipment[]> {
    return this.request<CommunicationEquipment>('GetEquipmentList', { param: { offset, search } });
  }

  private request<T>(resourceName: string, param: any): Observable<T[]> {
    return this.httpClient.post<T[]>(`api/UnitActivity/${resourceName}`, param);
  }
}
