import { Injectable } from '@angular/core';
import { DocumentType, IDocument } from '../../interfaces';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { BrowserDownloaderService } from '../browser-downloader/browser-downloader.service';
import { getFileInfo } from '../../helper/get-file-info';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DocumentDownloaderService {
  private readonly _iosFeatures = 'location=no,EnableViewPortScale=yes,closebuttoncaption={closebuttoncaption},clearcache=yes';

  private readonly _androidFeatures = 'location=no,clearcache=yes';

  constructor(
    private readonly _platform: Platform,
    private readonly _inAppBrowser: InAppBrowser,
    private readonly _windowOpener: WindowOpenerService,
    private readonly _fileOpener: FileOpener,
    private readonly _browserDownloader: BrowserDownloaderService,
    private readonly _translate: TranslateService
  ) {}

  public download(document: IDocument) {
    return this._platform.is('cordova') ? this.fromNative(document) : this._browserDownloader.download(document);
  }

  private fromNative(document: IDocument) {
    switch (document.type) {
      case DocumentType.WEBSITE:
        this._inAppBrowser.create(document.url, '_blank').show();
        break;

      case DocumentType.EXTERNAL:
        this.open(document);
        break;

      case DocumentType.STREAM:
        const fileInfo = getFileInfo(document.url);
        this._fileOpener.open(fileInfo.url, fileInfo.mimeType);
        break;
    }
  }

  private open(document: IDocument): void {
    const fileUrl = document.url;
    if (this._platform.is('ios')) {
      this._windowOpener.open(fileUrl.replace(/ /g, '%20'), '_blank', this.getIosFeature());
    } else if (this._platform.is('android')) {
      this._windowOpener.open(fileUrl, '_system', this._androidFeatures);
    } else {
      this._windowOpener.open(fileUrl, '_blank');
    }
  }

  private getIosFeature(): string {
    return this._iosFeatures.replace('{closebuttoncaption}', this._translate.instant('Mobile.Close'));
  }
}
