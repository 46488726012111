// Automatically generated from TW.Saga.Shared.MetadataLib, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @19.06.2017 18:53:46
import { CacheChangedInterface } from '@services/cache/cache.service';
import { StatusItems_abstract } from './../abstract/SagaStateLib/StatusItems_abstract';

export class StatusItems extends StatusItems_abstract {
  private actions_: number[] = [];

  set Actions(value: string) {
    this.actions_ = value
      .split(';')
      .filter(s => s)
      .map(action => parseInt(action, 10));
  }

  get Actions(): string {
    return this.actions_.join(';');
  }

  public changed(change: CacheChangedInterface) {
    this.actions_ = this.Actions.split(';')
      .filter(s => s)
      .map(action => parseInt(action, 10));
  }

  public hasAction(action: number) {
    return this.actions_.includes(action);
  }
}
