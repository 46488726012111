import { CurrentCardService } from '@addins/core/core';
import { CollapsibleItemsService } from '@addins/core/singlecallcard';
import { CAnimalCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/canimal-collapsible-item';
import { CObjectCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/cobject-collapsible-item';
import { CPersonPceCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/cperson-pce-collapsible-item';
import { CPersonRegularCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/cperson-regular-collapsible-item';
import { CVehicleCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/cvehicle-collapsible-item';
import { ParticularsCollapsibleItem } from '@addins/singlecallcard-particulars/src/schema/particulars-collapsible-item';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';
import { CacheEvent } from '@services/cache/cacheEvent';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ICallCardParticularsDelegate,
  SingleCallcardParticularsControllerService
} from '../../single-callcard-particular-controller/single-callcard-particulars-controller.service';

@AddinExecute({
  name: 'SinglecallcardDetailCard'
})
@Injectable()
export class SinglecallcardDetailCardService extends Execute implements ICallCardParticularsDelegate {
  private _callCardSubscription: Subscription = null;

  private readonly _collapsibleItems: ParticularsCollapsibleItem[] = [
    new CPersonRegularCollapsibleItem(),
    new CPersonPceCollapsibleItem(),
    new CVehicleCollapsibleItem(),
    new CObjectCollapsibleItem(),
    new CAnimalCollapsibleItem()
  ];

  constructor(
    private _singleCallcardParticularsController: SingleCallcardParticularsControllerService,
    private _collapsibleItemsService: CollapsibleItemsService,
    private _currentCardService: CurrentCardService,
    private _cacheService: CacheService
  ) {
    super();
  }

  execute() {
    this._singleCallcardParticularsController.registerDelegate(this);
  }

  onLoadCardParticulars() {
    this._collapsibleItems.forEach(item => {
      this._collapsibleItemsService.register(item);
    });
    this._callCardSubscription = this._currentCardService.$change.subscribe((callCard: CallCard) => this.onCardChange(callCard));
    this._cacheService
      .listenForChange(CallCard)
      .pipe(filter(cacheEvent => this.isForCurrentCard(cacheEvent)))
      .subscribe(event => this.onCardChange(event.object as CallCard));
  }

  private onCardChange(callCard: CallCard) {
    this._collapsibleItems.forEach(item => {
      item.updateVisibility(callCard);
    });
  }

  private isForCurrentCard(cacheEvent: CacheEvent) {
    const currentCard: CallCard = this._currentCardService.current;
    const changedCard: CallCard = cacheEvent.object as CallCard;
    return currentCard && changedCard && changedCard.ObjGuid === currentCard.ObjGuid;
  }

  onUnloadCardParticulars() {
    this._collapsibleItems.forEach(item => {
      this._collapsibleItemsService.unregister(item);
    });
    if (this._callCardSubscription !== null) {
      this._callCardSubscription.unsubscribe();
      this._callCardSubscription = null;
    }
  }
}
