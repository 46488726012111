export class ExternalSearchResult {
  errorMessage: string = '';
  isSearching: boolean = false;
  results: { Name: string; Value: string }[] = null;
  read: boolean = false;

  constructor() {}

  getUnreadCount(): number {
    return (!this.read && this.results && this.results.length) || 0;
  }

  needSearch(): boolean {
    return !this.isSearching && !this.results;
  }

  reset() {
    this.errorMessage = '';
    this.isSearching = false;
    this.results = null;
    this.read = false;
  }
}
