import { Injectable } from '@angular/core';
import { AddStampTool } from '../../schema/add-stamp-tool';

@Injectable()
export class AddStampButtonsProviderService {
  private _buttons: AddStampTool[] = [];

  get buttons(): AddStampTool[] {
    return this._buttons;
  }

  constructor() {}

  addButton(button: AddStampTool) {
    this._buttons.push(button);
  }
}
