<ion-header>
  <ion-toolbar>
    <img class="icon rotary" [class.rotary]="isStarting" src="assets/icon.png" alt="icon" slot="start" />
    <app-setting-button slot="end"></app-setting-button>
  </ion-toolbar>
</ion-header>
<ion-content>
  <img src="assets/logo.png" alt="log" />
  <div *ngIf="!hasError" class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <ion-button [class.hidden]="!hasError" (click)="refresh()" translate>SagaMobile.Reload</ion-button>
</ion-content>
<ion-footer>
  <div class="login-error" *ngIf="hasError">
    <span [translate]="error"></span>
  </div>
</ion-footer>
