import { MapName, MyCardService } from '@addins/core/core';
import { InMapPanelService, MyLocationService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';
import { ItineraryDirection, ItineraryOption, MapInstanceEventType, MapInstancesService, MapService, transform } from '@services/map';
import { ModalService } from '@services/modal/modal.service';
import { SagaSettingsService } from '@services/settings';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { ToggleTool } from '@techwan/map-tools';
import { Feature, Map } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ItineraryProxyService } from '../itinerary-proxy/itinerary-proxy.service';

@Injectable()
export class ItineraryProviderService {
  private lastItinerary: ItineraryDirection = null;
  private fitItineraryToScreen: boolean = false;
  private recalculateRouteAfterMissionPositionChange: boolean = false;
  private recalculateRouteAfterUnitPositionChangeChange: boolean = false;
  private recalculateRouteDistance: number = 500;
  private recalculateRouteTime: number = 60 * 1000;

  private lastUnitLocation: Coordinate = null;
  private targetLocation: CLocation = null;
  private targetLocationCoordinate: Coordinate = null;

  private routeButtonSub: Subscription = null;
  private myCardSub: Subscription = null;
  private myLocationSub: Subscription = null;
  private cacheLocationSub: Subscription = null;
  private refreshRouteTimer: any = null;
  private itineraryStrokeWidth: number;
  private itineraryStrokeColor: string;

  constructor(
    private itineraryProxy: ItineraryProxyService,
    private inMapPanel: InMapPanelService,
    private toastService: ToastService,
    private mapService: MapService,
    private mapInstances: MapInstancesService,
    private modalService: ModalService,
    private myLocation: MyLocationService,
    private myCard: MyCardService,
    private sagaSettings: SagaSettingsService,
    private cacheService: CacheService
  ) {}

  setup() {
    this.sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.fitItineraryToScreen = this.sagaSettings.getValue('SagaMobileWebClient.FitItineraryToScreen');
        this.recalculateRouteAfterMissionPositionChange = this.sagaSettings.getValue(
          'SagaMobileWebClient.RecalculateRouteAfterMissionPositionChange'
        );
        this.recalculateRouteAfterUnitPositionChangeChange = this.sagaSettings.getValue(
          'SagaMobileWebClient.RecalculateRouteAfterUnitPositionChange'
        );
        this.recalculateRouteDistance = this.sagaSettings.getValue('SagaMobileWebClient.RecalculateRouteDistance');
        this.recalculateRouteTime = this.sagaSettings.getValue('SagaMobileWebClient.RecalculateRouteTime');
        this.itineraryStrokeWidth = this.sagaSettings.getValue('SagaMobileWebClient.ItineraryStrokeWidth');
        this.itineraryStrokeColor = this.sagaSettings.getValue('SagaMobileWebClient.ItineraryStrokeColor');
      });

    this.mapInstances.$change
      .pipe(filter(mapEvent => mapEvent.name === MapName.main))
      .subscribe(mapEvent => this.onMainMapChanged(mapEvent.target, mapEvent.type));
  }

  private onMainMapChanged(sagaMap: Map, mapEvent: MapInstanceEventType) {
    if (mapEvent === MapInstanceEventType.added) {
      if (this.lastItinerary) {
        this.showItinerary(this.lastItinerary);
      }
      this.myCardSub = this.myCard.$change.subscribe(callcard => this.onCallcardChanged(callcard));
    } else if (mapEvent === MapInstanceEventType.removed) {
      this.cleanup();
    }
  }

  private cleanup() {
    if (this.myCardSub) {
      this.myCardSub.unsubscribe();
      this.myCardSub = null;
    }
    if (this.routeButtonSub) {
      this.routeButtonSub.unsubscribe();
      this.routeButtonSub = null;
    }

    this.stopMissionPositionMonitoring();
    this.stopItineraryUpdate();
  }

  private stopMissionPositionMonitoring() {
    if (this.cacheLocationSub) {
      this.cacheLocationSub.unsubscribe();
      this.cacheLocationSub = null;
    }
  }

  private stopItineraryUpdate() {
    if (this.myLocationSub) {
      this.myLocationSub.unsubscribe();
      this.myLocationSub = null;
    }
    this.stopRefreshRouteTimer();
    this.lastUnitLocation = null;
  }

  private onCallcardChanged(callcard: CallCard) {
    this.refreshRouteButton();
  }

  private refreshRouteButton() {
    const routeButton: ToggleTool = this.inMapPanel.routeButton;

    if (routeButton) {
      const showButton: boolean = !!this.myCard.myCard || !!this.lastItinerary;
      if (showButton) {
        this.inMapPanel.showButton(routeButton);
        routeButton.setActive(!!this.lastItinerary);

        if (this.routeButtonSub === null) {
          this.routeButtonSub = routeButton.$event.subscribe(toolBase => this.onRouteButtonClicked(toolBase as ToggleTool));
        }
      } else {
        this.inMapPanel.hideButton(routeButton);
      }
    }
  }

  private onRouteButtonClicked(routeButton: ToggleTool): void {
    if (this.lastItinerary) {
      this.removeLastItinerary();
      this.refreshRouteButton();
    }
    if (routeButton.isActive) {
      this.navigateTo(this.myCard.myCard.location, false).catch(() => routeButton.setActive(!!this.lastItinerary));
    }
  }

  private showItinerary(itinerary: ItineraryDirection, fitItineraryToScreen: boolean = false) {
    const sagaMap: Map = this.mapService.map;
    sagaMap
      .getItineraryLayer()
      .getSource()
      .clear();

    sagaMap.getItineraryLayer().setZIndex(1);

    const itineraryFeature: Feature = new Feature(itinerary.itineraryGeom);
    this.configureItineraryStyle(itineraryFeature);

    sagaMap
      .getItineraryLayer()
      .getSource()
      .addFeature(itineraryFeature);

    if (fitItineraryToScreen) {
      sagaMap.fitToFeatures([itineraryFeature]);
    }
    if (this.recalculateRouteAfterUnitPositionChangeChange) {
      this.startItineraryUpdate();
    }
    if (this.recalculateRouteAfterMissionPositionChange) {
      this.startMissionPositionMonitoring();
    }
  }

  private startMissionPositionMonitoring() {
    if (!this.cacheLocationSub) {
      this.cacheLocationSub = this.cacheService
        .listenForChange(CLocation)
        .pipe(filter(cacheEvent => this.hasTargetLocationChanged(cacheEvent.object as CLocation)))
        .subscribe(cacheEvent => {
          this.onTargetLocationChanged(cacheEvent.object as CLocation);
        });
      if (this.hasTargetLocationChanged(this.targetLocation)) {
        this.onTargetLocationChanged(this.targetLocation);
      }
    }
  }

  private hasTargetLocationChanged(newLocation: CLocation): boolean {
    const isMyTargetLocation: boolean = this.targetLocation && this.targetLocation.CallCardId === newLocation.CallCardId;
    return (
      isMyTargetLocation &&
      (this.targetLocationCoordinate.x !== newLocation.CenterX || this.targetLocationCoordinate.y !== newLocation.CenterY)
    );
  }

  private onTargetLocationChanged(newLocation: CLocation) {
    this.setTargetLocation(newLocation);
    this.refreshRoute().then(() => this.toastService.show('Mobile.CallCardPositionChangedItineraryUpdated', ToastColor.Update));
  }

  private setTargetLocation(location) {
    this.targetLocation = location instanceof CLocation ? location : null;
    this.targetLocationCoordinate = this.targetLocation ? { ...this.targetLocation.coordinate } : null;
  }

  private startItineraryUpdate() {
    if (!this.myLocationSub) {
      this.myLocationSub = this.myLocation.$change
        .pipe(filter(coordinate => coordinate !== this.myLocation.empty))
        .subscribe(coordinate => this.onUnitLocationChanged(coordinate));
    }

    this.startRefreshRouteTimer();
  }

  private onUnitLocationChanged(coordinate: Coordinate) {
    if (!this.lastUnitLocation) {
      this.lastUnitLocation = coordinate;
    }
    const distanceFromLastLocation: number = this.myLocation.haversineDistance(this.lastUnitLocation);

    if (distanceFromLastLocation > this.recalculateRouteDistance) {
      this.lastUnitLocation = coordinate;
      this.refreshRoute();
    }
  }

  private refreshRoute(): Promise<void> {
    this.stopRefreshRouteTimer();

    return this.navigateTo(this.targetLocation, false, false, false).then(() => {
      if (this.myLocation.value !== this.myLocation.empty) {
        this.lastUnitLocation = this.myLocation.value;
      }
    });
  }

  private stopRefreshRouteTimer() {
    clearTimeout(this.refreshRouteTimer);
    this.refreshRouteTimer = null;
  }

  private startRefreshRouteTimer() {
    this.stopRefreshRouteTimer();
    this.refreshRouteTimer = setTimeout(() => this.refreshRoute(), this.recalculateRouteTime * 1000);
  }

  navigateTo(
    location: CLocation | Coordinate,
    viability: boolean,
    fitItineraryToScreen: boolean = this.fitItineraryToScreen,
    withLoadingIndicator: boolean = true
  ): Promise<void> {
    const toCoordinate: Coordinate = location instanceof CLocation ? location.coordinate : location;

    return this.mapService.showMap().then(map =>
      this.getItinerary(toCoordinate, viability, withLoadingIndicator).then(itineraryDirection => {
        if (itineraryDirection != null) {
          this.lastItinerary = itineraryDirection;
          this.setTargetLocation(location);
        }

        this.refreshRouteButton();

        if (itineraryDirection !== null) {
          this.showItinerary(itineraryDirection, fitItineraryToScreen);

          return withLoadingIndicator ? this.toastService.show('Mobile.Itinerary.Calculated', ToastColor.Info) : Promise.resolve();
        } else {
          Promise.reject();
        }
      })
    );
  }

  private getItinerary(to: Coordinate, viability: boolean, withLoadingIndicator: boolean = true): Promise<ItineraryDirection> {
    return this.myLocation
      .getCurrent()
      .toPromise()
      .then(from => this.downloadItinerary(this.mapService.map, from, to, { viability }, withLoadingIndicator))
      .catch(() => this.onError().then(() => null));
  }

  private downloadItinerary(
    sagaMap: Map,
    start: Coordinate,
    destination: Coordinate,
    options?: ItineraryOption,
    withLoadingIndicator: boolean = true
  ): Promise<ItineraryDirection> {
    const mapEpsg = sagaMap
      .getView()
      .getProjection()
      .getCode();

    options = Object.assign(
      {
        viability: false,
        projection: mapEpsg.split(':')[1],
        territoryCode: 1
      },
      options
    );

    const normalizedStart: Coordinate = transform(start, mapEpsg);
    let normalizedDestination: Coordinate = destination;
    normalizedDestination = transform(destination, mapEpsg);

    if (!normalizedStart || !normalizedDestination) {
      return Promise.reject('Coordinate out of bounds');
    }

    return (withLoadingIndicator ? this.modalService.presentLoading('Mobile.Itinerary.Calculation') : Promise.resolve())
      .then(() =>
        this.itineraryProxy
          .get(normalizedStart, normalizedDestination, options)
          .pipe(map(itineraryData => new ItineraryDirection(sagaMap, itineraryData)))
          .toPromise()
      )
      .finally(() => (withLoadingIndicator ? this.modalService.dismissLoading() : Promise.resolve()));
  }

  private configureItineraryStyle(itinerary: Feature) {
    itinerary.setStrokeWidth(this.itineraryStrokeWidth);
    itinerary.setStrokeColor(this.itineraryStrokeColor);
  }

  removeLastItinerary() {
    this.stopMissionPositionMonitoring();
    this.stopItineraryUpdate();
    this.targetLocation = null;
    this.targetLocationCoordinate = null;

    this.lastItinerary = null;
    this.mapService.map
      .getItineraryLayer()
      .getSource()
      .clear(true);
  }

  getLastItinerary(): ItineraryDirection {
    return this.lastItinerary;
  }

  private onError(): Promise<void> {
    return this.toastService.show('Mobile.Itinerary.CalculateFailure', ToastColor.Error);
  }
}
