import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { QRCodeViewComponent } from '../../../views/qr-code-view/qr-code-view.component';

@AddinExecute({
  name: 'QRCodeSetup'
})
@Injectable({
  providedIn: 'root'
})
export class QRCodeSetupService extends Execute {
  private qrCodeMenuEntry: MenuEntry = null;

  constructor(private sideMenu: SideMenuService) {
    super();
  }

  execute() {
    this.qrCodeMenuEntry = new MenuEntry('QRCode', 'qr-code', QRCodeViewComponent, 450);
    this.sideMenu.addMenuEntry(this.qrCodeMenuEntry);
  }
}
