// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class InterfaceMsg_abstract extends SagaObject {
  static $t: string = 'SagaBase.InterfaceMsg';
  $t: string = 'SagaBase.InterfaceMsg';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'Info',
      'Line',
      'Complement',
      'DetectorId',
      'PhoneNumber',
      'Parameter1',
      'Parameter2',
      'Status',
      'Kind',
      'Source'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return InterfaceMsg_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaBase.ETDAddress'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public Info: string = '';
  public Line: number = 0;
  public Complement: string = '';
  public DetectorId: number = 0;
  public PhoneNumber: string = '';
  public Parameter1: string = '';
  public Parameter2: string = '';
  public Status: SagaBase.Defines.InterfaceMsgStatus = SagaBase.Defines.InterfaceMsgStatus.Submited;
  public Kind: SagaBase.Defines.InterfaceMsgKind;
  public Source: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
