import { CollapsibleItem, CollapsiblePositionBaseValue } from '@addins/core/singlecallcard';

const UnitListCollapsibleName = 'Units';
const UnitListViewName = 'UnitActivity/UnitActivityListView';

export class UnitListCollapsibleItem extends CollapsibleItem {
  constructor() {
    super(UnitListCollapsibleName, UnitListViewName, CollapsiblePositionBaseValue.UnitActivity);
  }
}
