<ion-header>
  <ion-toolbar>
    <ion-title translate>Mobile.Filter</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-accordion-group [multiple]="true" [value]="openedAccordions">
    <ion-accordion *ngFor="let filter of customFilters" [value]="filter.name">
      <ion-item slot="header" color="light">
        <ion-label translate>{{ filter.name }}</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        <lib-addin-view [name]="filter.view"></lib-addin-view>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</ion-content>

<ion-footer>
  <ion-button (click)="onDismiss()" color="danger" translate>Mobile.Cancel</ion-button>
  <ion-button (click)="onSubmit()" color="primary" translate>Mobile.Confirm</ion-button>
</ion-footer>
