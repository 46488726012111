import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { IExtendedProperties } from '@addins/pn/list-vehicle-type-pn';
import { CacheChangedInterface } from '@services/cache/cache.service';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';

export class VehiclePn extends Vehicle {
  properties: IExtendedProperties;
  vehicleType: ListNameValue;
  vehicleSubType: ListNameValue;
  vehicleModeOfTransport: ListNameValue;
  vehicleCategory: ListNameValue;

  changed(change: CacheChangedInterface) {
    super.changed(change);

    this.properties = JSON.parse(this.FreeField1);
  }
}
