import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Security } from '../security/security.service';
import { NavService } from '../navigation/navigation.service';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
// import {GnService} from "@addins/homes/gnhome/services/gn/gn";
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { CurrentCardService } from '@addins/core/core';

export interface GlobalAction {
  key: string;
  description?: string;
  run: (config?: any) => Promise<void> | void;
}

export interface GlobalActionHandler {
  key: string;
  config?: any;
}

@Injectable()
export class GlobalActionsService {
  readonly actions: GlobalAction[] = [];

  get unitActivity(): UnitActivity {
    return this.security.getData('UnitActivity');
  }

  constructor(
    // private gn: GnService,
    private vibration: Vibration,
    private readonly _card: CurrentCardService,
    private security: Security,
    private nav: NavService,
    private launchNavigator: LaunchNavigator
  ) {
    this.actions.push({
      key: 'vibrate',
      run: (config?: any) => {
        this.vibration.vibrate((config && config.duration) || 500);
      }
    });

    this.actions.push({
      key: 'myUnit',
      run: () => {
        if (this.unitActivity && this.unitActivity.callcard) {
          this._card.select(this.unitActivity.callcard);
        }
      }
    });

    this.actions.push({
      key: 'toggleTracking',
      run: () => {} // this.gn.toggleSendPosition()
    });

    this.actions.push({
      key: 'direction',
      run: () => {
        /*if(this.unitActivity && this.unitActivity.callcard) {
                    let coordinate = this.unitActivity.callcard.location.coordToPosition();
                    this.launchNavigator.navigate(`${coordinate[1]},${coordinate[0]}`);
                }*/
        this.launchNavigator.navigate(`47,6`);
      }
    });

    //
  }

  async run(actions: GlobalAction[]) {
    return new Promise<void>((resolve, reject) => {
      (actions || []).forEach(async action => {
        await action.run();
      });
      resolve();
    });
  }

  get(key: string) {
    return this.actions.filter(a => a.key === key)[0];
  }
}
