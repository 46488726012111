// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @19.06.2017 15:21:14
import { ListNameValue_abstract } from './../../abstract/SagaSchema/ListObjects/ListNameValue_abstract';
import { CacheChangedInterface } from '@services/cache/cache.service';

export class ListNameValue<T = any> extends ListNameValue_abstract {
  properties: T = {} as any;

  associate: any;

  changed(change: CacheChangedInterface) {
    super.changed(change);

    this.properties = JSON.parse(this.Json);
  }
}
