// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AppSetting_abstract extends PersistentObject {
  static $t: string = 'SagaBase.AppSetting';
  $t: string = 'SagaBase.AppSetting';

  public static GetMembers(): string[] {
    return ['test', 'AppSettingId', 'AppSettingsSchemaId', 'Key', 'Value', 'ValueType', 'OverridesConfigFile'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return AppSetting_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public test: number = 0;
  public AppSettingId: number = 0;
  public AppSettingsSchemaId: number = 0;
  public Key: string;
  public Value: string;
  public ValueType: string;
  public OverridesConfigFile: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
