// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { Team } from '../../SagaSchema/Team';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class TeamAdmin_abstract extends Team {
  static $t: string = 'SagaSchema.TeamAdmin';
  $t: string = 'SagaSchema.TeamAdmin';

  public static GetMembers(): string[] {
    return [].concat(Team.GetMembers());
  }

  public GetMembers(): string[] {
    return TeamAdmin_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.MobService', 'SagaSchema.ServiceResource'];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
