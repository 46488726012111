import { UnitActivityAddinName, UnitActivityViewName } from '@addins/core/core';
import { SettingOpenerService } from '@addins/core/settings';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService } from '@services/cache/cache.service';
import { EnvironmentService } from '@services/environment/environment.service';
import { Events } from '@services/events/events.service';
import { CompletionHandler } from '@services/initializer/completion-handler';
import { Initializer } from '@services/initializer/initializer.service';
import { LogoutService } from '@services/logout/logout.service';
import { NavService } from '@services/navigation/navigation.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { ADDIN_VIEW_EVENT_KEY, AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HomeAccessMenuService } from '../../services/home-access-menu/home-access-menu.service';

@Component({
  templateUrl: 'simple-home.component.html',
  styleUrls: ['simple-home.component.scss'],
  providers: [LogoutService]
})
@AddinView({
  name: 'SimpleHome'
})
export class SimpleHomeComponent extends CompletionHandler implements OnInit, OnDestroy {
  private _booted: boolean = false;
  get booted(): boolean {
    return this._booted;
  }

  get name(): string {
    return this._environmentService.getConfig().name;
  }

  get unitActivity(): UnitActivity {
    return this.booted && this._myUnitService.mine;
  }

  isTablet = this._platform.is('tablet');

  myCardChanged: boolean = false;
  private myCardSub: Subscription;

  constructor(
    protected initializer: Initializer,
    private _events: Events,
    private _myUnitService: MyUnitService,
    private _navService: NavService,
    private _platform: Platform,
    private _logoutService: LogoutService,
    private _viewConfiguration: ViewConfigurationService,
    private _environmentService: EnvironmentService,
    private _homeAccessMenuService: HomeAccessMenuService,
    private _settingOpener: SettingOpenerService,
    private _viewSelector: ViewSelectorService,
    private cache: CacheService
  ) {
    super(initializer);
    this._homeAccessMenuService.setup(SimpleHomeComponent);
  }

  ngOnInit() {
    this.myCardSub = this.cache
      .listenForChange(CallCard)
      .pipe(filter(event => this.unitActivity.callcard && this.unitActivity.callcard.ObjGuid === event.object.ObjGuid))
      .subscribe(() => (this.myCardChanged = true));
  }

  ngOnDestroy() {
    this.myCardSub.unsubscribe();
  }

  openMyCard() {
    this.myCardChanged = false;
    this._events.publish(ADDIN_VIEW_EVENT_KEY, 'singlecallcard/PageView', {
      card: this.unitActivity.callcard
    });
  }

  openMyUnit() {
    this._navService.get().push(this._viewSelector.getView(`${UnitActivityAddinName}/${UnitActivityViewName}`).element);
  }

  openMap() {
    this._events.publish(ADDIN_VIEW_EVENT_KEY, this._viewConfiguration.getMapName());
  }

  logout() {
    this._logoutService.logout();
  }

  showSettings(): Promise<void> {
    return this._settingOpener.open();
  }

  displayMenu() {
    const hideMenu = this._environmentService.get('hideMenu');
    if (typeof hideMenu === 'boolean') {
      return hideMenu;
    } else if (typeof hideMenu === 'string') {
      return !this._platform.is(hideMenu as any);
    }

    return true;
  }

  protected $onComplete() {
    this._booted = true;
  }
}
