import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { CacheService } from '@services/cache/cache.service';
import { MediaMessagesFilterService } from '../../services/media-messages/media-messages-filter/media-messages-filter.service';

@Component({
  selector: 'app-media-message-item',
  templateUrl: './media-message-item.component.html',
  styleUrls: ['./media-message-item.component.scss']
})
export class MediaMessageItemComponent implements OnInit {
  @Input()
  readonly mediaMessage: MediaMessage;

  isMine: boolean;
  isGlobal: boolean;
  isForMyUnit: boolean;
  isDeployment: boolean;
  hasAttachment: boolean;

  private parentCallcard: CallCard = null;
  get eventText(): string {
    return (this.parentCallcard && this.parentCallcard.event.EventText) || '';
  }

  constructor(private cacheService: CacheService, private mediaMessagesFilter: MediaMessagesFilterService) {}

  ngOnInit() {
    this.setMessageAttributes();
  }

  private setMessageAttributes() {
    this.isMine = this.mediaMessagesFilter.isMine(this.mediaMessage);
    this.isGlobal = this.mediaMessagesFilter.isGlobal(this.mediaMessage);
    this.isForMyUnit = this.mediaMessagesFilter.isForMyUnit(this.mediaMessage);

    this.isDeployment = this.mediaMessagesFilter.isDeployment(this.mediaMessage);
    this.hasAttachment = this.mediaMessagesFilter.hasAttachment(this.mediaMessage);

    this.parentCallcard = !this.isGlobal
      ? (this.cacheService.getObjectByUid((this.mediaMessage as CMediaMessage).ParentObjId) as CallCard)
      : null;
  }
}
