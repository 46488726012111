import { SelectInteraction } from '@techwan/mapping';
import Feature from 'ol/feature';
import Select from 'ol/interaction/select';
import { Observable, Subject } from 'rxjs';

export class RemoveInteraction extends SelectInteraction {
  private readonly _event = new Subject<Feature>();
  get $event(): Observable<Feature> {
    return this._event.asObservable();
  }

  constructor(filter?: ol.SelectFilterFunction) {
    super({ multi: false, filter });

    this.on('select', this.onFeatureSelected);
  }

  destroy() {
    this.un('select', this.onFeatureSelected);
    this.getFeatures().clear();
  }

  private onFeatureSelected(event: Select.Event) {
    if (event.selected.length > 0) {
      this._event.next(event.selected[0]);
      this.getFeatures().clear();
    }
  }
}
