import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { CallcardService } from '@services/callcard/callcard.service';

@Injectable()
export class CallCardsProviderService {
  constructor(private callcardService: CallcardService) {}

  getCards(): List<CallCard> {
    return this.callcardService.getCardsList('CallCards', CallCard, callcards => {});
  }
}
