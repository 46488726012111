import { CallcardStampsService, StampsProxyService } from '@addins/singlecallcard-journal';
import { Injectable } from '@angular/core';
import { AlertInput, ModalController } from '@ionic/angular';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CStampFreeText } from '@models/imported/SagaSchema/CStampFreeText';
import { ModalService } from '@services/modal/modal.service';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { StampTextEditorComponent } from '../../components/stamp-text-editor/stamp-text-editor.component';

enum FreeTextType {
  Report = 2,
  RequestReport = 3,
  CR = 5,
  ImportantFact = 6
}

interface FreeTextLabel {
  freeTextType: FreeTextType;
  label: string;
}

const FreeTextLabels: FreeTextLabel[] = [
  {
    freeTextType: FreeTextType.RequestReport,
    label: 'Mobile.ReportRequest'
  },
  { freeTextType: FreeTextType.CR, label: 'Mobile.Debriefing' },
  { freeTextType: FreeTextType.ImportantFact, label: 'Mobile.Highlight' },
  { freeTextType: FreeTextType.Report, label: 'Mobile.Report' }
];

@Injectable({
  providedIn: 'root'
})
export class AddFreeTextService {
  constructor(
    private translatedAlert: TranslatedAlertService,
    private modalController: ModalController,
    private modalService: ModalService,
    private stampsProxy: StampsProxyService,
    private callcardStamps: CallcardStampsService
  ) {}

  addFreeText(parentId: string) {
    this.selectFreeTextType().then(freeTextLabel => this.createStamp(parentId, freeTextLabel));
  }

  private selectFreeTextType(): Promise<FreeTextLabel> {
    const radioInputs = this.createRadioInputs();
    radioInputs[0].checked = true;

    return new Promise<FreeTextLabel>(resolve => {
      this.translatedAlert.show({
        header: 'Mobile.AddStamp',
        inputs: radioInputs,
        buttons: [
          { text: 'Mobile.Cancel', value: false },
          {
            text: 'Mobile.Confirm',
            value: true,
            handler(freeTextLabel) {
              resolve(freeTextLabel);
            }
          }
        ]
      });
    });
  }

  private createRadioInputs(): AlertInput[] {
    return FreeTextLabels.map(freeTextLabel => ({
      type: 'radio',
      label: freeTextLabel.label,
      value: freeTextLabel
    }));
  }

  private createStamp(parentId: string, freeTexLabel: FreeTextLabel) {
    const title: string = freeTexLabel.label;

    switch (freeTexLabel.freeTextType) {
      case FreeTextType.Report: {
        this.showStampFreeTextEditor(title, text => this.createFreeText(parentId, text, FreeTextType.Report));
        break;
      }

      case FreeTextType.RequestReport: {
        this.createFreeTextRequestReport(parentId, title);
        break;
      }

      case FreeTextType.CR: {
        this.showStampFreeTextEditor(title, text => this.createFreeText(parentId, text, FreeTextType.CR));
        break;
      }

      case FreeTextType.ImportantFact: {
        this.showStampFreeTextEditor(title, text => this.createFreeText(parentId, text, FreeTextType.ImportantFact));
        break;
      }
    }
  }

  private showStampFreeTextEditor(title: string, resultHandler: (text: string) => Promise<boolean>, initialText?: string) {
    this.modalController
      .create({
        component: StampTextEditorComponent,
        componentProps: { title: title, resultHandler: resultHandler.bind(this), initialText: initialText }
      })
      .then(modal => modal.present());
  }

  private createFreeText(parentId: string, text: string, type: number): Promise<boolean> {
    return this.modalService
      .presentLoading()
      .then(() => this.stampsProxy.addFreetext(parentId, text, type).toPromise())
      .finally(() => this.modalService.dismissLoading());
  }

  private createFreeTextRequestReport(parentId: string, title: string) {
    const stamp: CStampFreeText = this.callcardStamps.findStamp(
      (stamp: SagaObject) => stamp.$t == CStampFreeText.$t && (stamp as CStampFreeText).StampType == FreeTextType.RequestReport
    ) as CStampFreeText;
    if (!stamp) {
      this.showStampFreeTextEditor(title, text => this.createFreeText(parentId, text, FreeTextType.RequestReport));
    } else {
      this.translatedAlert
        .show({
          header: 'Mobile.ConfirmUpdate',
          message: 'Mobile.ReportRequestAlreadyExists',
          buttons: [
            {
              text: 'Mobile.No',
              role: 'cancel',
              value: false
            },
            {
              text: 'Mobile.Yes',
              value: true
            }
          ]
        })
        .then(confirmed => {
          if (confirmed) {
            this.showStampFreeTextEditor(title, text => this.updateFreeText(stamp.SourceComponentUid, text), stamp.Text);
          }
        });
    }
  }

  private updateFreeText(objGuid: string, text: string): Promise<boolean> {
    return this.modalService
      .presentLoading()
      .then(() =>
        this.stampsProxy
          .readFreetext(objGuid)
          .toPromise()
          .then(freeText => {
            freeText.Text = text;
            return this.stampsProxy.updateFreetext(freeText).toPromise();
          })
      )
      .finally(() => this.modalService.dismissLoading());
  }
}
