import { TraceFile } from './trace-file';

/**
 * @name Trace
 * @description
 * A stack's trace created from a line of the Error stack
 */
export class Trace {
  public function: string;
  public file: TraceFile;

  /**
   * @constructor
   * @param {string} traceLine
   */
  constructor(traceLine: string) {
    let matches = traceLine.match(/\(.*\)/);
    if (matches && matches.length > 0) {
      let fileInfo = traceLine.match(/\(.*\)/)[0];
      let functionName = traceLine.replace(fileInfo, '');
      fileInfo = fileInfo.replace(/webpack:\/\/\//, '');
      let [filePath, line] = fileInfo
        .replace('(', '')
        .replace(')', '')
        .split(':');

      this.function = functionName.trim();
      this.file = new TraceFile(filePath.trim().replace(/\?$/, ''), parseInt(line));
    } else {
      this.function = 'Main';
      let [filePath, line] = traceLine
        .replace('(', '')
        .replace(')', '')
        .split(':');
      this.file = new TraceFile(filePath.trim().replace(/\?$/, ''), parseInt(line));
    }
  }

  toString() {
    return `${this.function} in ${this.file.filename}:${this.file.line}`;
  }
}
