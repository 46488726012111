import { Injectable } from '@angular/core';
import { ImagesSettingsService } from '@services/settings';
import { ImageName, ImageProviderService } from '@techwan/map-tools';

@Injectable()
export class MapImagesService {
  private readonly _names = [{ key: ImageName.image, name: 'MapImage' }];

  constructor(private settings: ImagesSettingsService, private images: ImageProviderService) {}

  setup() {
    this._names.forEach(n => this.images.setImage(n.key, this.settings.get(n.name)));
  }
}
