// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListNameAssociation_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.ListNameAssociation';
  $t: string = 'SagaSchema.ListNameAssociation';

  public static GetMembers(): string[] {
    return ['ListNameAssociationId', 'ListNameValueId', 'ListNameActionId', 'ListNameId'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ListNameAssociation_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ListNameAssociationId: number = 0;
  public ListNameValueId: number = 0;
  public ListNameActionId: number = 0;
  public ListNameId: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.ListNameAssociationId;
  }
  get ParentObjId(): any {
    return this.ListNameActionId;
  }
  set ParentObjId(value: any) {
    this.ListNameActionId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
