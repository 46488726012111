import { MyCardService } from '@addins/core/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { CallCardListFiltersService } from '../../services/callcard-list-filters/callcard-list-filters.service';
import { CallcardListCustomFiltersComponent } from '../callcard-list-custom-filters/callcard-list-custom-filters.component';

@Component({
  selector: 'app-callcard-list-filter',
  templateUrl: './callcard-list-filter.component.html',
  styleUrls: ['./callcard-list-filter.component.scss']
})
export class CallcardListFilterComponent implements OnInit, OnDestroy {
  @Input()
  callcards: List<CallCard>;

  private removerFunctions: Function[] = [];
  private searchText = '';

  hasCustomFilters: boolean = false;
  filterIcon: string = 'filter-circle-outline';

  constructor(
    private myCardService: MyCardService,
    private callcardListFilters: CallCardListFiltersService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.removerFunctions.push(
      this.callcards.addFilter(
        callcard => (this.myCardService.myCard !== callcard && (!this.searchText || callcard.search(this.searchText).success)) || false
      )
    );

    this.hasCustomFilters = !!this.callcardListFilters.filters.length;
    this.callcardListFilters.filters.forEach(filter => filter.initFilter(this.callcards));
    this.setFilterIcon();
  }

  private setFilterIcon() {
    this.filterIcon = this.callcardListFilters.filters.filter(filter => filter.hasActiveFilter()).length
      ? 'filter-circle'
      : 'filter-circle-outline';
  }

  ngOnDestroy() {
    this.callcardListFilters.filters.forEach(filter => filter.removeFilter(this.callcards));
    while (this.removerFunctions.length > 0) {
      this.removerFunctions.pop()();
    }
  }

  filterCards(query: string) {
    this.searchText = query ? query.trim() : '';
    if (this.callcards) {
      this.callcards.refresh();
    }
  }

  setCustomFilters() {
    this.modalController
      .create({
        component: CallcardListCustomFiltersComponent
      })
      .then(modal =>
        modal.present().then(() =>
          modal.onDidDismiss().then(result => {
            if (result.data) {
              this.setFilterIcon();
              this.callcards.refresh();
            }
          })
        )
      );
  }
}
