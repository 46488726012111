import { Component, Input, OnInit } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { UnitActivityResource } from '@models/imported/SagaSchema/UnitActivityResource';
import { IChildAwareResource } from '../../schema/interfaces/IChildAwareResource';
import { UnitResourceProviderService } from '../../services/unit-resource-provider/unit-resource-provider.service';

@Component({
  selector: 'app-unit-resources',
  templateUrl: './unit-resources.component.html',
  styleUrls: ['./unit-resources.component.scss']
})
export class UnitResourcesComponent implements OnInit {
  @Input()
  public unit: UnitActivity;

  @Input()
  public resources: IChildAwareResource[];

  @Input()
  public level: number = 0;

  constructor() {}

  ngOnInit() {}

  getMainIcon(resource: UnitActivityResource): string {
    return UnitResourceProviderService.getResourceIconName(`SagaSchema.${resource.shortName}`);
  }

  getPadding() {
    return `${25 * this.level}px`;
  }

  getSecondaryIcon(resource: UnitActivityResource): string {
    if (resource.ObjGuid === this.unit.LeaderID) {
      return 'flag';
    } else if (resource.ObjGuid === this.unit.PositionProviderUId) {
      return 'location';
    } else if (resource.ObjGuid === this.unit.MainResourceUId) {
      return 'star';
    }

    return '';
  }
}
