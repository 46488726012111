// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CStampShare_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CStampShare';
  $t: string = 'SagaSchema.CStampShare';

  public static GetMembers(): string[] {
    return [
      'ParentObjId',
      'ParentStampUid',
      'Provider',
      'Title',
      'Direction',
      'Type',
      'Text',
      'Details',
      'MetadataRaw',
      'Priority',
      'ShareUid',
      'JobProviderUid'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CStampShare_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.CStampShareComponent'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ParentStampUid: Guid = GuidTools.Empty;
  public Provider: string;
  public Title: string;
  public Direction: SagaBase.ShareStampDirection = SagaBase.ShareStampDirection.Inbound;
  public Type: SagaBase.ShareStampTypes = SagaBase.ShareStampTypes.Header;
  public Text: string;
  public Details: string;
  public MetadataRaw: string;
  public Priority: number = 0;
  public ShareUid: Guid = GuidTools.Empty;
  public JobProviderUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
