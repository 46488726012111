import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeywordSearchObject } from '@models/imported/SagaBase/Objects/KeywordSearchObject';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationProxyService {
  constructor(private httpClient: HttpClient, private objectFactory: ObjectFactory) {}

  searchLocations(query: string, limit: string): Observable<KeywordSearchObject[]> {
    const params = new HttpParams({ fromObject: { query, limit } });
    return this.httpClient
      .get<KeywordSearchObject[]>('api/LocationData/SearchLocation', { params })
      .pipe(map(locations => this.parseSearchLocationResult(locations)));
  }

  private parseSearchLocationResult(locations: KeywordSearchObject[]): KeywordSearchObject[] {
    let keywordSearchObjects: KeywordSearchObject[] = [];
    locations
      .map(keywordSearchObject => this.objectFactory.create(keywordSearchObject, KeywordSearchObject.$t))
      .forEach(keywordSearchObject => keywordSearchObjects.push(keywordSearchObject));
    return keywordSearchObjects;
  }

  getLocationDetails(keywordSearchObject: KeywordSearchObject): Observable<CLocation> {
    return this.httpClient
      .post<CLocation>('api/LocationData/GetLocationDetails', keywordSearchObject)
      .pipe(map(location => this.objectFactory.create(location, CLocation.$t)));
  }
}
