import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { IEventFormatter } from '../../../schema/interfaces/event-formatter';

export class CnuEventFormatter implements IEventFormatter {
  format(event: CEvent, short: boolean = false): string {
    const eventName: string = [event.EventText, event.NodeTag].filter(item => item).join(' ');

    if (short) {
      return eventName;
    } else {
      const firstLine: string = [eventName, event.FieldGeneric1].filter(string => string).join(' - ');
      const secondLine: string = event.Complement;
      return [firstLine, secondLine].filter(string => string).join('\r\n');
    }
  }
}
