import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NATIVE_TRANSLATION_TOKEN, STATIC_TRANSLATION_TOKEN } from '../../schema/injection-token';
import { INativeTranslationsProvider } from '../../schema/interfaces/native-translations-provider';
import { IStaticTranslationsProxy, StaticTranslation } from '../../schema/interfaces/static-translation-proxy';
import { LanguageService } from '../language/language.service';

@Injectable()
export class StaticTranslationsService {
  constructor(
    @Inject(STATIC_TRANSLATION_TOKEN)
    private _static: IStaticTranslationsProxy,
    @Inject(NATIVE_TRANSLATION_TOKEN)
    private _native: INativeTranslationsProvider,
    private _language: LanguageService,
    private _translations: TranslateService,
    private _platform: Platform
  ) {}

  loadLocalTranslations(): Observable<boolean> {
    return this._language.getLanguage().pipe(
      map(lang => {
        const i18n = require(`src/assets/i18n/${lang}.json`);
        return this.store(lang, i18n);
      })
    );
  }

  loadStaticTranslations(): Observable<boolean> {
    return this._language.getLanguage().pipe(switchMap(lang => this.fetchTranslations(lang)));
  }

  protected fetchTranslations(lang: string): Observable<boolean> {
    if (this._platform.is('cordova')) {
      return this._native.setup().pipe(
        map(v => this.store(lang, v)),
        switchMap(merge => this._static.setup(lang).pipe(map(t => this.parse(lang, t, merge))))
      );
    } else {
      return this._static.setup(lang).pipe(map(t => this.parse(lang, t)));
    }
  }

  protected parse(lang: string, list: StaticTranslation, merge: boolean = true): boolean {
    const cache = {};
    (list[lang] || []).forEach(i => (cache[i.Key] = i.Text));
    this._translations.setTranslation(lang, cache, merge);
    this._translations.use(lang);
    return true;
  }

  protected store(lang: string, v: Object, shouldMerge: boolean = true): boolean {
    if (v) {
      this._translations.setTranslation(lang, v, shouldMerge);
      this._translations.use(lang);
      return true;
    }
    return false;
  }
}
