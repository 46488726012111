// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @09.07.2019 16:16:46
/* tslint:disable */

import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { Guid, GuidTools } from '@models/guid';

export abstract class CMediaMessage_abstract extends MediaMessage {
  static $t: string = 'TW.Saga.Core.Schema.Callcard.CMediaMessage';
  $t: string = 'TW.Saga.Core.Schema.Callcard.CMediaMessage';

  public static GetMembers(): string[] {
    return ['CallCardId'].concat(MediaMessage.GetMembers());
  }

  public GetMembers(): string[] {
    return CMediaMessage_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
