<ion-row>
  <ion-col size="12">
    <ion-button [disabled]="!canStartAll()" color="primary" (click)="onStartAll()" translate>
      Upload.StartAll
      <ion-icon slot="end" name="cloud-upload"></ion-icon>
    </ion-button>
    <ion-button id="pauseAllButton" [disabled]="!canPauseAll()" color="warning" (click)="onPauseAll()" translate>
      Upload.PauseAll
      <ion-icon slot="end" name="pause"></ion-icon>
    </ion-button>
    <ion-button id="deleteAllButton" [disabled]="!uploadItems || !uploadItems.length" color="danger" (click)="onDeleteAll()" translate>
      Upload.DeleteAll
      <ion-icon slot="end" name="trash"></ion-icon
    ></ion-button>
  </ion-col>
</ion-row>
