import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { TranslationListComponent } from './components/translation-list/translation-list.component';
import { MenuAccessToolService } from './services/executables/menu-access-tool/menu-access-tool.service';

const executables = [MenuAccessToolService];

const views = [TranslationListComponent];

const declarations = [TranslationListComponent];

const services = [...executables];

@Addin({
  name: 'Translations'
})
@NgModule({
  declarations,
  providers: [...services],
  imports: [IonicModule, TranslateModule, CommonModule]
})
export class TranslationModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
