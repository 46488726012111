import { Component, Input, OnInit } from '@angular/core';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { ILayerDefinition, SagaLayerManager } from '@techwan/ionic-core/map';
import { TileLoaderProviderService } from '../../services/tile-loader-provider/tile-loader-provider.service';

@Component({
  selector: 'app-base-layer-select',
  templateUrl: './base-layer-select.component.html',
  styleUrls: ['./base-layer-select.component.scss']
})
export class BaseLayerSelectComponent implements OnInit {
  @Input()
  layers: SagaLayerManager;

  private _current: string;
  get current(): string {
    return this._current;
  }

  get baseLayers(): ILayerDefinition[] {
    return this.layers.getRootLayers();
  }

  constructor(private tileLoaderProvider: TileLoaderProviderService, private translatedAlert: TranslatedAlertService) {}

  ngOnInit() {
    this._current = this.layers.getSelectedBaseLayer();
  }

  select(item: CustomEvent): void {
    const layers: ILayerDefinition[] = this.layers.getRootLayers();
    const layer: ILayerDefinition = layers.filter(layer => layer.name === item.detail.value)[0];

    if (layer.useTileLoader && !this.tileLoaderProvider.isMapAvailable) {
      this.translatedAlert.show({
        header: 'Mobile.MapOfflineDownload',
        message: 'Mobile.MapOfflineNotDownloaded',
        buttons: [
          {
            text: 'Mobile.Close',
            value: true
          }
        ]
      });
    }

    this.layers.toggleBaseLayer(layer.id);
  }
}
