import { CollapsibleItem, CollapsiblePositionBaseValue } from '@addins/core/singlecallcard';
import { CFormLink } from '@models/imported/SagaSchema/CFormLink';

export class SagaFormsCollapsibleItem extends CollapsibleItem {
  private _items: CFormLink[] = [];
  set items(items: CFormLink[]) {
    this._items = items;
  }

  constructor() {
    super('Mobile.Forms.GroupName', 'SagaForms/CallcardForms', CollapsiblePositionBaseValue.SagaForms);
  }

  isVisible(): boolean {
    return this._items.length > 0;
  }
}
