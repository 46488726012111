// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { romanize } from '../../../utils/roman';
import { CEvent_abstract } from './../abstract/SagaSchema/CEvent_abstract';

export class CEvent extends CEvent_abstract {
  public isExpanded: boolean = false;

  private _rLong: string = '';

  set rLong(value: string) {
    this._rLong = value;
  }
  get rLong(): string {
    return this._rLong;
  }

  rShort() {
    return romanize(this.Priority) + ' - ' + this.EventText;
  }

  get XmlTopicsQuestions(): string[] {
    if (this.XmlTopics) {
      const keys = Object.keys(this.XmlTopics);
      if (keys.length > 0) {
        return keys;
      }
    }
    return null;
  }
}
