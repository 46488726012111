import { MenuState, PermanentEntry } from '@addins/core/menus';
import { Type } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';

export class AccessMenuTool extends PermanentEntry {
  constructor(text: string, icon: string, target: Type<any>, priority: number) {
    super(new MenuEntry(text, icon, target, priority));
    this._disabled = true;
    this._state = MenuState.inactive;
  }

  requireAttention() {
    this._attention = 'alert';
  }

  clearAttention() {
    this._attention = '';
  }

  setCounter(value: number) {
    this._counter = value;
  }

  disable() {
    super.disable();
    this.reset();
  }
}
