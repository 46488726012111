import { Stack } from './stack';
import { LogLevel, LEVELS_NAMED, LEVELS_ICONS } from './const';

/**
 * @name Log
 * @description
 * Call representing a Log inside the Saga Application
 */
export class Log {
  /**
   * The domain applied to this log
   * @type {string}
   */
  domain: string;

  /**
   * The text of the log
   * @type {string}
   */
  text: string;

  /**
   * The level of the log (see Logger.LEVELS)
   * @type {LogLevel}
   */
  level: LogLevel;

  /**
   * The DateTime when the log was created
   * @type {Date}
   */
  date: Date;

  /**
   * The log stack trace
   */
  stack: Stack;

  /**
   * Get the level name according to the level
   * @returns {string}
   */
  get levelName() {
    return LEVELS_NAMED[this.level];
  }

  /**
   * Get the level icon according to the level
   * @returns {string}
   */
  get levelIcon() {
    return LEVELS_ICONS[this.level];
  }

  /**
   * @constructor
   * @param {string} domain
   * @param {string} text
   * @param {number} level
   * @param {Date?} date Default to now
   */
  constructor(domain: string, text: string, level: number, date: Date = new Date()) {
    this.domain = domain;
    this.text = text;
    this.level = level;
    this.date = date;
    this.stack = new Stack();
  }

  /**
   * Format the the log for displaying DOMAIN :: TEXT
   * @returns {string}
   */
  format() {
    return `${this.domain} :: ${this.text}`;
  }
}
