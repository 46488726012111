import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { PopupProviderService } from '../../services/popup-provider/popup-provider.service';

@Component({
  template: ''
})
export class PopupBaseComponent implements OnInit, OnDestroy {
  protected readonly _subs: Subscription[] = [];

  public constructor(protected readonly _popup: PopupProviderService, protected readonly _e: ElementRef) {}

  public ngOnInit() {
    this._subs.push(this._popup.$change.subscribe(() => this.refresh()));
    this._subs.push(fromEvent(this._e.nativeElement, 'click').subscribe((e: MouseEvent) => this.close(e)));
    this._subs.push(fromEvent(this._e.nativeElement, 'touchstart').subscribe((e: TouchEvent) => this.close(e)));
  }

  public ngOnDestroy() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  protected close(e: MouseEvent | TouchEvent) {
    if (e.currentTarget === this._e.nativeElement) {
      this._popup.close();
    }
  }

  protected refresh() {}
}
