import { BehaviorSubject, Observable } from 'rxjs';
import { IAppSetting } from '@addins/core/core';

export class AppSetting<T> implements IAppSetting<T> {
  protected readonly _value: BehaviorSubject<T>;

  public get $change(): Observable<T> {
    return this._value.asObservable();
  }

  public get current(): T {
    let c: T;
    this._value.subscribe(v => (c = v)).unsubscribe();
    return c;
  }

  public constructor(protected _name: string, _default: T, protected _useStorage: boolean) {
    this._value = new BehaviorSubject<T>(_default);
  }

  public setValue(_value: T) {
    if (_value !== this.current && this._useStorage) {
      localStorage.setItem(this._name, `${_value}`);
    }
    if (_value !== this.current) {
      this._value.next(_value);
    }
  }
}
