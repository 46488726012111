import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Injectable()
export class CardPageService {
  private readonly _pages = new Map<string, number>();

  constructor() {}

  public getPage(card: CallCard): number {
    return this._pages.has(card.ObjGuid) ? this._pages.get(card.ObjGuid) : 0;
  }

  public setPage(card: CallCard, page: number) {
    this._pages.set(card.ObjGuid, page);
  }
}
