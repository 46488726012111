export type Guid = string;

const guidRegex: RegExp = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/i;

export function isValid(guid: Guid): boolean {
  return guid && guid !== GuidTools.Empty && guidRegex.test(guid);
}

export class GuidTools {
  static Empty: Guid = '00000000-0000-0000-0000-000000000000';

  static isValid(guid: Guid): boolean {
    return isValid(guid);
  }

  private static pad4(num: number): string {
    let ret: string = num.toString(16);
    while (ret.length < 4) {
      ret = '0' + ret;
    }
    return ret;
  }

  static generate(): Guid {
    if (window !== undefined && window.crypto !== undefined && window.crypto.getRandomValues !== undefined) {
      // If we have a cryptographically secure PRNG, use that
      // http://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
      const buf: Uint16Array = new Uint16Array(8);
      window.crypto.getRandomValues(buf);
      return (
        this.pad4(buf[0]) +
        this.pad4(buf[1]) +
        '-' +
        this.pad4(buf[2]) +
        '-' +
        this.pad4(buf[3]) +
        '-' +
        this.pad4(buf[4]) +
        '-' +
        this.pad4(buf[5]) +
        this.pad4(buf[6]) +
        this.pad4(buf[7])
      );
    } else {
      return (
        GuidTools.s4() +
        GuidTools.s4() +
        '-' +
        GuidTools.s4() +
        '-' +
        GuidTools.s4() +
        '-' +
        GuidTools.s4() +
        '-' +
        GuidTools.s4() +
        GuidTools.s4() +
        GuidTools.s4()
      );
    }
  }

  private static s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  static isEmpty(guid: Guid): boolean {
    return !guid || guid === GuidTools.Empty;
  }
}
