// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:39
export enum PhoneCallStates {
  Offering = 0,
  Answered = 1,
  Transferring = 2,
  TransferCancelled = 3,
  TransferFailed = 4,
  Terminated = 5,
  Dialing = 6,
  Holded = 7,
  Unholded = 8,
  Parked = 9,
  Unparked = 10,
  Deleted = 11
}
export enum CardServiceAction {
  CardUpdate = 0,
  CardUpdateNotify = 1,
  ChangeOwner = 2,
  Share = 3,
  UnShare = 4,
  NoSpecificAction = 5
}
export enum PhoneCallTypes {
  Incoming = 0,
  Outgoing = 1,
  Transfer = 2
}
export enum StatusEvaluationItemType {
  Event = 0,
  Card = 1,
  Resource = 2,
  Procedure = 3,
  Free = 4,
  ProcedureAction = 5,
  Phone = 10,
  Animal = 20,
  Object = 21,
  Person = 22,
  Vehicle = 23,
  Patient = 30
}
export enum CustomStatusParamType {
  E_FREESTAMP = 0
}
export enum StampProcedureActionType {
  NextGroup = 0,
  OneClick = 1,
  Requirement = 2,
  Execute = 3,
  Unknown = -1
}
export enum SecurityClearances {
  None = 0,
  Restricted = 1,
  High = 2,
  Maximum = 3
}
export enum TaskTypes {
  Project = 1,
  Task = 2,
  ProjectTask = 3,
  Group = 4
}
export enum DeferredAssignmentState {
  created = 0,
  active = 1,
  inProgress = 2,
  submitted = 3,
  alerted = 4,
  handled = 5
}
export enum ResourceStampType {
  Vehicle = 1,
  Actor = 2,
  Crew = 4,
  CreatedByMobile = 8,
  CreatedByDesktop = 16,
  Destination = 32,
  Unknown = -1
}
export enum AddressNormalization {
  None = 0,
  Address = 1,
  GeoPoint = 2,
  All = 3,
  AllKeywords = 7,
  Etd = 8
}
export enum SearchStatus {
  OK = 0,
  InvalidRequest = 1,
  MaxRecordsExceeded = 2
}
export enum InterfaceMsgSource {
  Phone = 1,
  RN = 2,
  Camera = 3
}
export enum InterfaceCommandEnum {
  TextPanel_SetText = 1,
  GPS_SetConfig = 2,
  GPS_RequestPosition = 3,
  GPS_Manual = 4,
  GPS_Automatic = 5,
  GPS_Disabled = 6,
  SVGASetBeamer = 7,
  SVGAOnOff = 8,
  RN_AlarmOpened = 9,
  RN_AlarmTerminated = 10,
  RadioSelectChannel = 11,
  ETV_QueryAddress = 12,
  RN_SetCamera = 13
}
export enum AtomicErroCode {
  None = 0,
  Concurrency = 1,
  DeadLock = 2,
  DatabaseError = 3
}
export enum OperationType {
  Submit = 0,
  Update = 1,
  Remove = 2,
  Delete = 3,
  Activate = 4,
  SubmitNotShared = 5,
  UpdateNotShared = 6,
  DbCheck = 7,
  Undelete = 8
}
export enum ClientType {
  Application = 0,
  Service = 1
}
export enum ServerErrorEnum {
  RequestArchivedCallCardsError = 0,
  RequestCallsError = 1,
  CallcardUpdateError = 2,
  CallcardCreateError = 3,
  CallcardRequestError = 4,
  CallcardDeleteError = 5,
  VehicleUpdateError = 6,
  VehicleRequestError = 7,
  DispatchUnitUpdateError = 8,
  DispatchUnitRequestError = 9,
  DispatchUnitStructuresRequestError = 10,
  ProcedureRequestError = 11,
  RequestMessageBoardItemError = 12,
  InfranetUpdateError = 13,
  CallcardTagError = 14,
  CommSubmitError = 15,
  DepartureTicketError = 16
}
export enum InfranetRequestType {
  SetOutOfService = 1,
  Restart = 2,
  Cancel = 10
}
export enum EnumCommType {
  None = 0,
  Phone = 1,
  Other = 2,
  DirectLine = 3
}
export enum DefaultProcCommEnum {
  None = 0,
  Phone = 1,
  Other = 2
}
export enum ProcDisplayGroup {
  None = 0,
  Vehicle = 1,
  Material = 2,
  Comm = 3,
  Infranet = 4
}
export enum EventType {
  Normal = 0,
  AA = 1,
  D6 = 2,
  D7 = 3,
  D8 = 4,
  Technical = 5
}
export enum ChangeEvent {
  Add = 0,
  Update = 1,
  Remove = 2,
  Refresh = 3,
  Submitted = 4,
  Lock = 5,
  Unlock = 6
}
export enum ProcedureStampType {
  IsCommunication = 0,
  IsDeferredCommunication = 1,
  IsDocument = 10,
  IsMobilization = 11,
  IsCustomAction = 12,
  IsMobileEngagement = 13,
  IsMobileDeengagement = 14,
  IsContactResource = 21,
  IsEngageResource = 22,
  IsOnSiteResource = 23,
  IsTerminateResource = 24
}
export enum StampManagerType {
  None = 0,
  Alarm = 1,
  Note = 2,
  Caller = 3,
  Location = 4,
  Event = 5,
  Topic = 6,
  Status = 7,
  Merge = 8,
  Free = 9,
  Objects = 10,
  Persons = 11,
  Vehicles = 12,
  Procedure = 13,
  Resource = 14,
  Export = 15,
  Transfer = 16,
  Escalade = 17,
  ExportGML = 18,
  Link = 19,
  TacticalSituation = 20,
  Report = 21,
  RequestReport = 22,
  ExportReport = 30,
  Alert = 100,
  EventDeleted = 105,
  CreateTacticalSituation = 201,
  SaveTacticalSituation = 202,
  RemoveTacticalSituation = 203,
  NoMoreResourceNeeded = 204,
  DeferredAlarmRequest = 400,
  DeferredAlarmRequestCancelled = 401,
  LinkToParent = 402,
  LinkToChild = 403,
  UnlinkFromParent = 404,
  UnlinkFromChild = 405,
  TaskNotApproved = 500,
  TaskApproved = 501,
  TaskPublished = 505,
  TaskNotAccepted = 508,
  TaskAccepted = 509,
  TaskStatus = 510,
  PriorityChange = 511,
  EspriLink = 512,
  AgencyAdded = 600,
  AgencyRemoved = 601,
  AgencyAcknowledged = 602,
  MobilizationStamp = 700,
  Patient = 800,
  AddAttachment = 900,
  RemoveAttachment = 901,
  AddLink = 902,
  RemoveLink = 903,
  AddDeploymentPoint = 904,
  RemoveDeploymentPoint = 905,
  ImportExportService = 1000,
  DeferAssignmentAdd = 1100
}
export enum UserAccessibleRole {
  NotSet = 0,
  Read = 1,
  Write = 2,
  Execute = 4,
  Admin = 8,
  Supervisor = 16,
  System = 32,
  ReadSharePoint = 64,
  WriteSharePoint = 128,
  ExtendedAdmin = 256,
  ReadWriteConfidential = 512
}
export enum EtdSource {
  None = 0,
  Callcards = 1,
  InternalPhoneBook = 2,
  ResourcesPhoneBook = 3,
  ExternalPhoneBook = 4,
  RubisPhonebook = 5,
  LdapPhonebook = 6,
  PFLAU = 7,
  GisPhonebook = 8
}
export enum BroadcastMessageReason {
  None = 0,
  CloseApp = 1,
  RestartApp = 2,
  ShutdownSystem = 3,
  RestartSystem = 4,
  ReloginApp = 5,
  ReloginSystem = 6,
  Info = 7,
  RefreshData = 8,
  PreaAlert = 9,
  SchedulerRefresh = 10,
  ScheduledOpResponse = 11
}
export enum ServerErrorType {
  None = 0,
  ConcurrencyError = 1,
  UnspecifiedError = 2,
  DeleteConstraintError = 3,
  DuplicateConstraintError = 4,
  StatutConstraintError = 5
}
export enum StatusChangeResult {
  OK = 0,
  NotOK = 1,
  InvalidUser = 2,
  InvalidResource = 3,
  InvalidCallcard = 4,
  InvalidStatus = 5,
  InvalidVisibility = 6,
  ConcurrencyError = 7,
  MessageNotSent = 8,
  StatusValuesNotFound = 9,
  CallcardNotFound = 10,
  InvisibleCallcard = 11,
  UnhandledRequest = 12,
  UnhandledDeploymentPoint = 13
}
export enum ArchiveResultStatus {
  OK = 0,
  NotOK = 1,
  MissingItems = 2,
  AlreadyArchived = 3,
  NotArchivable = 4,
  InvalidUser = 5,
  invalidZone = 6,
  MobilizationRunning = 7,
  invalidTypeArchive = 8
}
export enum ReactivateResultStatus {
  Reactivated = 0,
  CardNotFound = 1,
  InvalidZone = 2,
  NotArchived = 3,
  UnknowError = 4
}
export enum MergeResult {
  OK = 0,
  NotOK = 1,
  InvalidSource = 2,
  InvalidTarget = 3,
  ConcurrencyError = 4,
  MessageNotSent = 5,
  InvalidUser = 6,
  DuplicateConstraintError = 7
}
export enum MergeAction {
  Copy = 0,
  Replace = 1,
  Discard = 2,
  Append = 3,
  ChangeParent = 4,
  UnKnown = 5
}
export enum ExportResult {
  OK = 0,
  OK_IMGNOK = 1,
  NotOK = 2
}
export enum ParticularsStampType {
  CAnimal = 0,
  CObject = 1,
  CPerson = 2,
  CVehicle = 3
}
export enum DeviceTypes {
  NA = 0,
  Mobile = 1,
  Desktop = 2,
  Web = 3,
  Interface = 4,
  MobileWeb = 5
}
export enum OrderingCategory {
  ByDistance = 0,
  ByName = 1,
  ByStatus = 2,
  Customized = 3,
  Disabled = -1
}
export enum DispatchSelectionModeEnum {
  Proximity = 0,
  Area = 1,
  None = -1
}
export enum PreselectText {
  None = 0,
  Caller = 1,
  Location = 2,
  Event = 4,
  Custom = 8,
  Info = 16,
  Recall = 32,
  Subject = 64,
  Message = 128,
  Attachment = 256
}
export enum ActivityType {
  Engagement = 0,
  Patrol = 1,
  PlannedPatrol = 2
}
export enum SettingsValueType {
  String = 0,
  Boolean = 1,
  Byte = 2,
  Char = 3,
  DateTime = 4,
  Decimal = 5,
  Double = 6,
  Int16 = 7,
  Int32 = 8,
  Int64 = 9,
  SByte = 10,
  Single = 11,
  UInt16 = 12,
  UInt32 = 13,
  UInt64 = 14,
  Undefined = 15
}
export enum RoutingRequestType {
  Route = 1,
  TimeDistance = 2
}
export enum RoutePathType {
  None = 0,
  Points = 1
}
export enum RouteDistanceUnit {
  Km = 0,
  Mile = 1
}
export enum TrafficUsage {
  None = 0,
  Time = 1,
  RouteAndTime = 2
}
export enum RouteOptimization {
  None = 0,
  Time = 1,
  Distance = 2
}
export enum RouteMode {
  None = 0,
  Driving = 1,
  Walking = 2
}
export enum GISAvailableServices {
  None = 0,
  ReverseGeoCoding = 1,
  Routing = 2,
  Measure = 4,
  Features = 8,
  GeoFences = 16,
  TrafficFlow = 32,
  TrafficIncidents = 64,
  Isochrone = 128
}
export enum GeoResultCode {
  None = 0,
  Normalization = 1,
  Sectorization = 4
}
export enum GeoErrorCode {
  None = 0,
  Normalization_TooManyResults = 1,
  Normalization_Error = 2,
  Sectorization_Error = 4,
  Insufficient_Data = 5,
  Normalization_Provider_Unavailable = 6,
  Normalization_Provider_Error = 7,
  Normalization_BadMatch = 8
}
export enum ShareStampTypes {
  Header = 0,
  Body = 1,
  Status = 2,
  Acknowledge = 3
}
export enum ShareStampDirection {
  Inbound = 0,
  Outbound = 1
}
export enum TrafficIncidentType {
  Accident = 0,
  Congestion = 1,
  DisabledVehicle = 2,
  RoadHazard = 3,
  Construction = 4,
  PlannedEvent = 5,
  MassTransit = 6,
  OtherNews = 7,
  Weather = 8,
  Misc = 9,
  RoadClosure = 10,
  LaneRestriction = 11
}
export enum GeoFenceTypes {
  None = 0,
  Inclusion = 1,
  Exclusion = 2
}
export enum PEFormat {
  PE32 = 267,
  PE32Plus = 523
}
export enum FuntionType {
  SELECT = 0,
  SELECTALL = 1,
  INSERT = 2,
  UPDATE = 3,
  DELETE = 4
}
export enum VideoProviderEnum {
  None = 0,
  Axis = 1,
  DigitalBarriers = 2,
  Genetec = 3
}
export enum AllowedContent {
  AllowActor = 1,
  AllowExternal = 2,
  AllowVehicle = 4,
  AllowEquipment = 8,
  AllowUriDocument = 16,
  AllowResourceTypeManager = 32,
  AllowOffice = 64,
  AllowCrew = 256
}
export enum FilterOperators {
  none = 0,
  equal = 1,
  binaryEqual = 2,
  isNull = 3,
  isNotNull = 4
}
export enum ResourceType {
  Undefined = 0,
  Actor = 1,
  External = 2,
  Vehicle = 3,
  CommunicationEquipment = 4,
  UriDocument = 5,
  ResourceTypeManager = 6,
  Office = 7,
  Crew = 9
}
export enum CommunicationType {
  Unknown = 0,
  Phone = 1,
  PhoneText = 2,
  Pager = 3,
  Fax = 4,
  SMTP = 5,
  Radio = 6,
  PagerNumeric = 7
}
export enum EngagementCategoryBit {
  Vehicles = 1,
  Qualifications = 2,
  Services = 4
}
export enum EngagementCategory {
  Vehicles = 1,
  Qualifications = 2,
  Services = 3,
  Composites = 4
}
export enum EquipmentAssociationCategory {
  Singular = 1,
  Linked = 2
}
export enum CommunicationEquipmentType {
  Undefined = 0,
  SmartPhone = 1,
  Radio = 2
}
export enum FrequencyOccurence {
  Daily = 0,
  Weekly = 1,
  Monthly = 2
}
export enum FrequencyModes {
  Recurence = 0,
  DateSelect = 1
}
export enum CorFlags {
  ILOnly = 1,
  F32BitsRequired = 2,
  StrongNameSigned = 8,
  TrackDebugData = 65536
}
export enum ActionFilterOption {
  UnSectorized = 1,
  UnScheduled = 2,
  FilterUnavailable = 4
}
export enum GroupModeEnum {
  None = 0,
  Automatic = 1,
  Manual = 2
}
export enum StructureElementKind {
  StructLevel1 = 1,
  StructLevel2 = 2,
  StructLevel3 = 3,
  DispatchUnit = 4,
  Building = 5,
  Camera = 6,
  Organisation = 7,
  Group = 8,
  Vehicle = 9,
  Person = 10,
  Itinerary = 11,
  AASubscriber = 12,
  AAEnv = 13,
  AASubEnv = 14,
  Rooms = 15,
  GarageDoors = 16,
  HeaderOrg = 17,
  HeaderVehic = 18,
  HeaderItineraries = 19,
  HeaderAA = 20,
  HeaderAASubscriber = 21,
  HeaderRooms = 22,
  HeaderGarageDoors = 23,
  HeaderAAEnv = 24,
  VehicleGarageDoor = 25,
  Roles = 26,
  AbsenceCodes = 27,
  Ranks = 28,
  Presence = 29,
  HeaderCameras = 30,
  RemoteElement = 50,
  Hardware = 75,
  Callcard = 100,
  CallcardTag = 150,
  Comm = 200
}
export enum ScrollOnEdit {
  None = 0,
  Half = 1,
  Full = 2
}
export enum Arity {
  SingleInstance = 0,
  MultiInstance = 1
}
export enum CheckAttribute {
  Yes = 0,
  No = 1
}
export enum ComputeStateCategory {
  DatabaseQuery = 1,
  SagaMethod = 2
}
export enum ComputeStateType {
  BoolState = 1,
  ChoiceState = 2,
  NumberState = 3
}
export enum EditType {
  Always = 0,
  Never = 1,
  OnEdit = 2
}
export enum GeometryFormats {
  Default = 0,
  Shape = 1,
  GeoJson = 2
}
export enum TransferFunction {
  Sign = 1,
  Tanh = 2,
  TanhAnnl = 3
}
export enum AlarmState {
  Alarm = 0,
  Reset = 1,
  OutOfOrder = 2,
  ResetImpulse = 3,
  AlarmImpulse = 4,
  ManualAlarm = 5,
  Acknowledge = 99,
  Online = 100
}
export enum AlarmType {
  Phone = 1,
  Infranet = 2,
  RadioTrap = 4,
  Mail = 8,
  Etrinex = 16,
  Highway = 64,
  Building = 128,
  Communication = 256,
  HighwayEmergencyPhone = 512,
  WirelessAlarm = 1024,
  Sca = 2048,
  Espa = 4096,
  SurveillanceCamera = 8192,
  SMSTrap = 16384,
  Technical = 32768,
  MobileAlarm = 65536
}
export enum AlarmChangeType {
  Normal = 0,
  SelfTest = 1,
  Manual = 2
}
export enum AlarmTestStatus {
  Active = 0,
  Deferred = 1,
  PendingAction = 2,
  PendingActionAcknowledged = 3
}
export enum AlarmStampType {
  TransmitterStateStamp = 0,
  AlarmStamp = 1,
  DetectorStateStamp = 2
}
export enum UnitActivityStampType {
  Status = 0,
  ResourceLeader = 1,
  DeferredAssign = 2,
  Unassigned = 3
}
export enum AlarmEvent {
  Alarm = 0,
  Reset = 1,
  Call = 2,
  Acknowledge = 3,
  None = -1
}
export enum AlarmStatus {
  Hidden = 0,
  OnLine = 1,
  OffLine = 2,
  OutOfService = 3,
  Test = 4,
  None = -1
}
export enum AlarmStateChangeType {
  Transmitter = 0,
  Detector = 1
}
export enum Frequency {
  once = 0,
  daily = 1,
  weekly = 2,
  monthly = 3
}
export enum GpsMode {
  Unavailable = 0,
  Off = 1,
  Manual = 2,
  Automatic = 4,
  Trace = 8,
  Follow = 16
}
export enum ProcStepOnInitializeCategory {
  Nothing = 0,
  AutoOpen = 1,
  Prefetch = 2
}
export enum DefaultScheduleType {
  UnAvailable = 0,
  Available = 1,
  UnDefined = -1
}
export enum TaskServiceResult {
  OK = 0,
  NotOK = 1,
  InvalidDates = 2
}
export enum CallcardType {
  Saga = 0,
  Crisis = 1
}
export enum QuittanceEnum {
  None = 1,
  Open = 2,
  Executed = 3
}
export enum EventNodeType {
  TerminalNode = 0,
  DecisionNode = 1,
  ProbabilityNode = 2,
  Unknown = -1
}
export enum EventCategory {
  Card = 0,
  Alarm = 1,
  Task = 2
}
export enum StampItemAction {
  none = 0,
  AllowDelete = 2,
  IsVisible = 4,
  SingleEntry = 8,
  IsExternalStamp = 16,
  IsInternalStamp = 32,
  IsFollowupStamp = 64,
  isCommStamp = 128,
  IsParticularsStamp = 256,
  isMessageStamp = 512,
  isReportStamp = 1024,
  isNotEditable = 2048,
  isDeletable = 4096,
  IsDocumentStamp = 8192,
  IsOfficeStamp = 16384,
  AllowChangeDateTime = 32768,
  isDeferredCommStamp = 65536,
  isResourceContacted = 131072,
  isResourceEngaged = 262144,
  isResourceOnSite = 524288,
  isResourceTerminated = 1048576,
  isMobilization = 2097152
}
export enum ProcedureRequestTypeEnum {
  Update = 0,
  Full = 1,
  RequestSingle = 2,
  FullSubmit = 3
}
export enum CardTabStatusEnum {
  Local = 0,
  Save = 1,
  Processing = 2,
  Submitted = 3,
  CommError = 4
}
export enum EditStateFlags {
  None = 0,
  Insert = 1,
  Update = 2,
  Delete = 4
}
export enum DispatchUnitStatusEnum {
  Unavailable = 0,
  Available = 1
}
export enum DispatchUnitStatusModeEnum {
  UseTimeScheduling = 0,
  ForcedOpen = 1,
  ForcedClosed = 2
}
export enum DispatchUnitChangesFlags {
  None = 0,
  Status = 1
}
export enum CallCardTypeFlags {
  Normal = 0,
  Tapi = 1,
  Test = 2,
  Automatic = 4,
  Technical = 8,
  Check = 16
}
export enum CallCardStatusFlags {
  Local = 0,
  Archived = 1,
  ReActivated = 2,
  Deleted = 4,
  Submitted = 16,
  PendingAlarm = 32,
  Alarmed = 64,
  Running = 128,
  Backup = 256,
  Terminated = 512
}
export enum AddressTypeFlags {
  None = 0,
  City = 1,
  Street = 2,
  Address = 3,
  Place = 4,
  WaterWay = 5
}
export enum CallcardChangesFlags {
  None = 0,
  Status = 1,
  Caller = 2,
  Location = 4,
  eEvent = 8,
  dispatching = 16,
  vehicle = 32,
  archive = 64,
  alarmed = 128
}
export enum VehicleChangesFlags {
  None = 0,
  Status = 1,
  Position = 2,
  Callcard = 4,
  DispatchUnit = 8,
  Misc = 16,
  GPSmode = 32
}
export enum LogTypes {
  CallCard = 0,
  Vehicle = 1
}
export enum LocationTypeEnum {
  None = 0,
  From = 1,
  Destination = 2
}
export enum StatusItemAction {
  none = 0,
  isVisible = 1,
  isVisibleOnMap = 2,
  allowEdit = 4,
  allowDelete = 8,
  allowTerminate = 16,
  allowArchive = 32,
  isAssignement = 64,
  isCancelAssignment = 128,
  isMessageItem = 256,
  isLowByteText = 512,
  isReassignment = 1024,
  isCommandStatus = 2048,
  isSubmitAction = 4096,
  isArchiveAction = 8192,
  isReactivateAction = 16384,
  isDeleteAction = 32768,
  isReportItem = 65536,
  isAvailable = 131072,
  isTerminateAction = 262144,
  isInformAction = 524288,
  isRequestAssignment = 1048576,
  isAlarmAction = 2097152,
  isOnSite = 4194304,
  isUnAvailable = 8388608,
  isMobilisation = 16777216,
  isFilterItem = 33554432,
  isDeferedAction = 67108864,
  isCancelAction = 134217728,
  isApproved = 268435456,
  isPublished = 536870912,
  isAccepted = 1073741824
}
export enum ScopeType {
  Global = 1,
  HostGroup = 2,
  Host = 3
}
export enum DashboardMessageEnum {
  None = 0,
  SimpleLink = 1,
  ConfidentialLink = 2
}
export enum PertinenceEnum {
  None = 0,
  ToVerify = 1,
  Confirmed = 2,
  Certified = 3
}
export enum HardwareDeviceType {
  Monitor = 1,
  Camera = 2,
  Projector = 3,
  OperatorStation = 4,
  OnOff = 5,
  PCBoard = 6
}
export enum AssociationType {
  None = 0,
  Read = 1,
  Mixed = 2
}
export enum ClassificationEnum {
  None = 1,
  Confidential = 2
}
export enum CreateEventResultEnum {
  OK = 0,
  AlreadyEngaged = 1,
  CallerNotFound = 2,
  UnitActivityNotFound = 3,
  ErrorDuringCallcardCreation = 4
}
export enum ConvertPropertyCondition {
  Never = 0,
  Boot = 1,
  Submit = 2,
  BootOrSubmit = 3,
  Update = 4,
  AlwaysWithoutDelete = 7,
  Delete = 8,
  Always = 2147483647
}
export enum ComJobPriority {
  High = 0,
  Normal = 1,
  Low = 2
}
export enum CallcardElementType {
  CallCardCaller = 101,
  CallCardLocation = 102,
  CallCardEvent = 103,
  CallcardStatus = 104
}
export enum MediaType {
  Unknown = 0,
  Text = 1,
  Image = 2,
  Video = 3,
  Document = 4
}
export enum ScheduleCopyMode {
  Resource = 1,
  SvcResourcesMainSchedule = 2,
  SvcResourcesServiceSchedule = 3
}
export enum SchElementTypeEnum {
  resResources = 1,
  resInternalPersons = 2,
  resVehicles = 3,
  resExternalPersons = 4,
  geoSectors = 5,
  svcResources = 6
}
export enum GPSPositionFrequency {
  None = 0,
  Slow = 1,
  Normal = 2,
  Fast = 3,
  UltraFast = 5
}
export enum MobilizationControlAvailabilityType {
  None = 0,
  ByPercentOfResources = 1,
  ByNumberOfResources = 2
}
export enum SortOrder {
  None = 0,
  Ascending = 1,
  Descending = 2
}
export enum CrisisEventSource {
  Web = 0,
  Online = 1,
  Offline = 2
}
export enum MediaMessageStatus {
  Info = 0,
  InfoWithAck = 1,
  Post = 2,
  RequestWithConfirmation = 3,
  Destination = 4,
  DestinationRemove = 5
}
export enum ComjobSearchDateTypeEnum {
  CreatedDate = 0,
  ExecuteBegindDate = 1,
  ExecutedEndDate = 2,
  ArchivedDate = 3
}
export enum ConvertoToPhoneMode {
  None = 0,
  Boot = 1,
  Broadcast = 2,
  Request = 4,
  Components = 8
}
export enum MobilizationWizardForms {
  Description = 0,
  AlarmType = 1,
  AudioRec = 2,
  TextMessage = 3,
  ResourceSelection = 4,
  FileAttachment = 5,
  Validation = 6
}
export enum DbOperationType {
  None = 0,
  Insert = 1,
  Update = 2,
  Delete = 3
}
export enum QueueingOperationType {
  None = 0,
  Queue = 1,
  Cancel = 2
}

import * as Defines from './Defines';
export { Defines };

import * as Objects from './Objects';
export { Objects };

import * as Interfaces from './Interfaces';
export { Interfaces };

import * as Factory from './Factory';
export { Factory };

import * as RBAC from './RBAC';
export { RBAC };
