import { ToolConfig } from '@services/tools';

/**
 * @name Tool
 * @description
 * A tool in the saga environment
 */
export class Tool {
  public text: string;
  public icon: string;
  public role: string;
  public priority: number;
  public execute: Function;

  /**
   *
   * @param {string} text
   * @param {string} icon
   * @param {string} role
   * @param {number} priority
   * @param {Function} execute
   */
  constructor(text: string, icon: string, role: string, priority: number, execute: Function) {
    this.text = text;
    this.icon = icon;
    this.role = role;
    this.priority = priority || 0;
    this.execute = execute;
  }

  /**
   * Create a new tool from a config
   * @param {ToolConfig} config
   * @returns {Tool}
   */
  static createTool(config: ToolConfig): Tool {
    return new Tool(config.text, config.icon, config.role, config.priority, config.execute);
  }

  /**
   * Call the tool execute function with a given context
   * @param {{}} context
   * @param {{}} data
   * @returns {{}}
   */
  public handler(context: any): any {
    return (this.execute || function() {})(context);
  }

  /**
   * Get the current tool config
   * @returns {any}
   */
  public config(): any {
    return {
      text: this.text,
      icon: this.icon,
      priority: this.priority,
      role: this.role,
      execute: this.execute
    };
  }
}
