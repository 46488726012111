// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ResourceServiceSchedule_abstract extends SagaObject {
  static $t: string = 'SagaSchema.ResourceServiceSchedule';
  $t: string = 'SagaSchema.ResourceServiceSchedule';

  public static GetMembers(): string[] {
    return ['SvcResourceId', 'StartDateUTC', 'EndDateUTC', 'ResourcePriority'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ResourceServiceSchedule_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.StartDateUTC = this.StartDateUTC && new Date(this.StartDateUTC);
    this.EndDateUTC = this.EndDateUTC && new Date(this.EndDateUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SvcResourceId: number = 0;
  public StartDateUTC: Date = new Date();
  public EndDateUTC: Date = new Date();
  public ResourcePriority: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
