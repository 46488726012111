import { Injectable } from '@angular/core';
import { Security } from '@services/security/security.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { Zone } from '@models/imported/SagaBase/Zone';

@Injectable()
export class GeoSectorService {
  constructor(private security: Security, private objectFactory: ObjectFactory) {}

  getRadioZone(): Promise<Zone[]> {
    return this.security
      .get(`api/Gis/GetSectorsByTreeTypeIdAndParent?treetypeid=3`)
      .then((objects: any[]) => this.objectFactory.createList(objects, Zone));
  }
}
