import { Injectable } from '@angular/core';
import { CLocationBase } from '@models/imported/SagaSchema/CLocationBase';
import { CacheAction } from '@services/cache/cacheEvent';
import { LocationFormatterFactoryService } from '@services/location/location-formatter-factory/location-formatter-factory.service';
import { IModelService, IObjectChange, ModelService } from '@services/model-factory';

@ModelService(CLocationBase)
@Injectable()
export class CLocationBaseModelService implements IModelService<CLocationBase> {
  constructor(private locationFormatter: LocationFormatterFactoryService) {}

  change(change: IObjectChange<CLocationBase>) {
    if (change.action === CacheAction.removed) {
      return;
    }

    const location: CLocationBase = change.target;
    location.FormattedLocation = this.locationFormatter.getFormatter().format(location);
  }
}
