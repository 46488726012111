import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngagedPeopleProxyService {
  constructor(private httpClient: HttpClient) {}

  updateEngagedPeople(numberOfPeople: number): Observable<boolean> {
    const data = { text: `${numberOfPeople}` };
    return this.httpClient.post<any>('api/Stamp/UpdateProcedureStamp', data);
  }
}
