// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class KeywordSearchArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.KeywordSearchArgs';
  $t: string = 'SagaBase.Defines.KeywordSearchArgs';

  public static GetMembers(): string[] {
    return [
      'SearchTypeVal',
      'SearchString',
      'FilterTable',
      'DynamicFilterId',
      'CurrentSearchID',
      'UseReplace',
      'UseWildcards',
      'SiteId',
      'ExtendInfo',
      'EncapsulateNumbersForExactMatch',
      'OverrideUserAgency',
      'Extent',
      'ProjCode'
    ];
  }

  public GetMembers(): string[] {
    return KeywordSearchArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SearchTypeVal: string;
  public SearchString: string;
  public FilterTable: number = 0;
  public DynamicFilterId: number = 0;
  public CurrentSearchID: Guid = GuidTools.Empty;
  public UseReplace: boolean = false;
  public UseWildcards: boolean = false;
  public SiteId: number = 0;
  public ExtendInfo: any;
  public EncapsulateNumbersForExactMatch: boolean;
  public OverrideUserAgency: number;
  public Extent: any;
  public ProjCode: string = 'EPSG:4326';

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
