import { Component, Input, OnInit } from '@angular/core';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { ListNameService } from '@services/list-name/list-name.service';
import { ModalController } from '@ionic/angular';
import { VehiclePn } from '@addins/pn/vehicle-pn';
import { CacheService } from '@services/cache/cache.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { ModalService } from '@services/modal/modal.service';

export class ListNameTemplate extends ListNameValue<VehiclePn> {
  vehicle: VehiclePn;

  private getListNameValue(list: ListNameValue): string {
    return list && list.DisplayValue;
  }

  rShort() {
    return [
      this.vehicle.CallCode,
      this.vehicle.type && this.vehicle.type.Name,
      this.getListNameValue(this.vehicle.vehicleType),
      this.getListNameValue(this.vehicle.vehicleSubType),
      this.getListNameValue(this.vehicle.vehicleModeOfTransport),
      this.getListNameValue(this.vehicle.vehicleCategory)
    ]
      .filter(value => !!value)
      .join(' - ');
  }
}

@Component({
  templateUrl: 'unit-template-selector.component.html'
})
export class UnitTemplateSelectorComponent implements OnInit {
  @Input() types: ListNameValue[];
  @Input() subTypes: { [key: number]: ListNameValue[] } = {};
  @Input() modeOfTransports: ListNameValue[];
  @Input() categories: ListNameValue[];

  allTemplates: ListNameTemplate[] = [];
  templates: ListNameTemplate[] = [];

  constructor(
    private listNameService: ListNameService,
    private modalCtrl: ModalController,
    private objectFactory: ObjectFactory,
    private cache: CacheService,
    private modalService: ModalService
  ) {}

  async load() {
    await this.modalService.presentLoading('Saga.LoadTemplates');
    this.allTemplates = await this.listNameService.getListOf<ListNameTemplate>('ResourceTemplatePn', ListNameTemplate);
    this.allTemplates.forEach(template => {
      template.vehicle = this.objectFactory.create(template.properties, VehiclePn);
      this.cache.callChanged(template.vehicle);
      template.vehicle.vehicleType = this.types.find(type => type.ListNameValueId === template.vehicle.properties.type);
      if (template.vehicle.properties.type && this.subTypes[template.vehicle.properties.type]) {
        template.vehicle.vehicleSubType = this.subTypes[template.vehicle.properties.type].find(
          type => type.ListNameValueId === template.vehicle.properties.subType
        );
      }
      template.vehicle.vehicleModeOfTransport = this.modeOfTransports.find(
        type => type.ListNameValueId === template.vehicle.properties.modeOfTransport
      );
      template.vehicle.vehicleCategory = this.categories.find(type => type.ListNameValueId === template.vehicle.properties.category);

      console.log(template);
    });
    this.templates = this.allTemplates.concat([]);
    this.modalService.dismissLoading();
  }

  async change(event) {
    return this.search(event.detail.value);
  }

  async search(text: string) {
    this.templates = this.allTemplates.filter(template => template.search(text).success);
  }

  close(template?: ListNameValue) {
    this.modalCtrl.dismiss(template);
  }

  ngOnInit(): void {
    this.load();
  }
}
