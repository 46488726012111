import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { Observable } from 'rxjs';

@Injectable()
export class ItineraryProxyService {
  constructor(private httpClient: HttpClient) {}

  get(start: Coordinate, destination: Coordinate, options): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        startX: `${start.x}`,
        startY: `${start.y}`,
        destinationX: `${destination.x}`,
        destinationY: `${destination.y}`,
        useViability: options.viability === true ? 'true' : 'false',
        EPSG: options.projection,
        territoryCode: options.territoryCode
      }
    });
    return this.httpClient.get<any>('api/Map/GetItinerary', { params });
  }
}
