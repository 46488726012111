import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { EngagementService } from '../../services/engagement/engagement.service';

@Component({
  selector: 'app-engagement-list-button',
  templateUrl: './engagement-list-button.component.html',
  styleUrls: ['./engagement-list-button.component.scss']
})
export class EngagementListButtonComponent implements OnInit {
  @Input()
  callcard: CallCard;
  @Input()
  isMyCallcard: boolean;

  get canReassign(): boolean {
    return this.engagementService.canReassign;
  }

  constructor(private engagementService: EngagementService) {}

  ngOnInit() {}

  onEngageButtonClicked($event: MouseEvent | TouchEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.engagementService.engage(this.callcard);
  }
}
