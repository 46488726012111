import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Security } from '@services/security/security.service';
import { SiteSelect } from '@techwan/ionic-core';

export interface Departement {
  code: string;
  sites: Site[];
  open: boolean;
}

export interface Site {
  DepartmentCode: string;
  geosectorid: number;
  geosectorname: string;
  open: boolean;
}

@Component({
  templateUrl: 'site-selector.component.html',
  styleUrls: ['site-selector.component.scss']
})
export class SiteSelectorComponent implements OnInit {
  departements: Departement[] = null;

  constructor(private modalCtrl: ModalController, private securityService: Security) {}

  ngOnInit(): void {
    this.loadSites();
  }

  async loadSites() {
    this.departements = null;

    const rawSites: Site[] = await this.securityService.get<Site[]>('api/SectorsProfils/GetDepartementsAndCic');

    if (rawSites) {
      this.departements = [];
      rawSites.forEach(site => {
        let departement = this.departements.find(dep => dep.code === site.DepartmentCode);
        if (!departement) {
          departement = {
            code: site.DepartmentCode,
            sites: [],
            open: false
          };
          this.departements.push(departement);
        }
        departement.sites.push(site);
      });

      this.departements.sort((a, b) => parseInt(a.code, 10) - parseInt(b.code, 10));
    }
  }

  close(data?: SiteSelect) {
    this.modalCtrl.dismiss(data);
  }

  selectSite(site) {
    this.close({ id: site.geosectorid, name: site.geosectorname });
  }
}
