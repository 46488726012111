import { Component } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { DeviceSelectorComponent } from '../deviceSelector/deviceSelector';
import { VehicleSelectorComponent } from '../vehicleSelector/vehicleSelector';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { ModalService } from '@services/modal/modal.service';
import { UnitActivitySelectorComponent } from '../unit-activity-selector/unit-activity-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { IUpdateRequestArgs, UnitActivityProxyService } from '@services/unit-activity-proxy/unit-activity-proxy.service';
import { environment } from '@environments/environment';

@Component({
  templateUrl: 'unit-activity-editor.component.html',
  styleUrls: ['unit-activity-editor.component.scss']
})
@AddinView({
  name: 'UnitActivityEditor'
})
export class UnitActivityEditorComponent {
  view: string = 'equipments';
  hasChange: boolean = false;
  unitActivity: UnitActivity;
  showClose: boolean = true;
  poling: any;

  get myDevice() {
    return this.association.getDevice();
  }

  get polingDelay() {
    return 5000;
  }

  pairingDone(unitActivity: UnitActivity, poling = false) {
    this.alertCtrl
      .create({
        subHeader: 'Saga.ParingDone',
        buttons: [
          {
            text: 'ok',
            handler: () => {
              clearTimeout(this.poling);
              this.hasChange = false;
              unitActivity.$$poling = poling;
              this.modalCtrl.dismiss(unitActivity);
            }
          }
        ]
      })
      .then(a => a.present());
  }

  async checkActivity() {
    clearTimeout(this.poling);
    let response = await this.association.retreiveUnitActivity();
    if (response.success && response.activity) {
      this.pairingDone(response.activity, true);
    } else {
      this.poling = setTimeout(() => this.checkActivity(), this.polingDelay);
    }
  }

  constructor(
    navParams: NavParams,
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private association: AssociationSecurity,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private unitActivityService: UnitActivityProxyService
  ) {
    this.unitActivity = navParams.get('unitActivity');
    this.showClose = !navParams.get('disableCancel');

    this.modalService.listenToModalView(/* this.modalCtrl as ViewController */);

    if (navParams.get('poling')) {
      setTimeout(() => this.checkActivity(), this.polingDelay);
    }

    // TODO: /!\ TEMP FIX /!\ Do not use a global environment variable but a provider !!!!
    if (
      environment &&
      environment.addinParameters &&
      environment.addinParameters.unitEditor &&
      environment.addinParameters.unitEditor.allowCreate === false
    ) {
      this.selectExisting();
    }
  }

  addItem() {
    switch (this.view) {
      case 'equipments':
        // TODO: @deprecated please use ModalFactoryService instead
        this.modalCtrl
          .create({
            component: DeviceSelectorComponent,
            componentProps: {
              filter: this.unitActivity.devices
            }
          })
          .then(deviceModal => {
            deviceModal.onDidDismiss().then(res => {
              const device = res.data as CommunicationEquipment;
              if (device) {
                this.hasChange = true;
                this.unitActivity.devices.push(device);
              }
            });
            deviceModal.present();
          });
        break;

      case 'vehicles':
        // TODO: @deprecated please use ModalFactoryService instead
        this.modalCtrl
          .create({
            component: VehicleSelectorComponent,
            componentProps: {
              filter: this.unitActivity.vehicles
            }
          })
          .then(vehicleModal => {
            vehicleModal.onDidDismiss().then(res => {
              const vehicle: Vehicle = res.data;
              if (vehicle) {
                this.hasChange = true;
                this.unitActivity.vehicles.push(vehicle);
              }
            });
            vehicleModal.present();
          });
        break;
    }
  }

  remove(type: string, item: CommunicationEquipment | Vehicle) {
    let list = this.unitActivity[type];
    let i = list.indexOf(item);
    if (i != -1) {
      list.splice(i, 1);
      this.hasChange = true;
    }
  }

  close() {
    clearTimeout(this.poling);
    this.modalCtrl.dismiss();
  }

  async save() {
    clearTimeout(this.poling);
    this.modalCtrl.dismiss(this.unitActivity);
    this.hasChange = false;
  }

  async selectExisting() {
    // TODO: @deprecated please use ModalFactoryService instead
    this.modalCtrl
      .create({
        component: UnitActivitySelectorComponent
      })
      .then(uaModal => {
        uaModal.onDidDismiss().then(async res => {
          const selectedUA: UnitActivity = res.data;
          if (selectedUA) {
            const updateRequest: IUpdateRequestArgs = {
              activityUid: selectedUA.ObjGuid,
              addedVehicles: [],
              addedEquipments: [],
              removedVehicles: [],
              removedEquipments: []
            };

            if (this.myDevice) {
              updateRequest.addedEquipments.push(this.myDevice);
            }

            await this.modalService.presentLoading('SagaMobile.UA.Associating');
            const unit = await this.unitActivityService.updateUnitActivity(updateRequest);

            if (!unit) {
              await this.modalService.dismissLoading();
              this.alertCtrl
                .create({
                  message: this.translate.instant('SagaMobile.UA.Associating.Error'),
                  buttons: [
                    {
                      text: this.translate.instant('SagaMobile.UA.Associating.Error.OK'),
                      handler: () => {}
                    }
                  ]
                })
                .then(a => a.present());
            } else {
              await this.checkActivity();
              await this.modalService.dismissLoading();
            }
          }
        });

        uaModal.present();
      });
  }
}
