// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import * as SagaBase from '../../SagaBase';
import { CLocationBase } from '../../SagaSchema/CLocationBase';

export abstract class CrisisMessageLocation_abstract extends CLocationBase {
  static $t: string = 'SagaSchema.CrisisMessageLocation';
  $t: string = 'SagaSchema.CrisisMessageLocation';

  public static GetMembers(): string[] {
    return ['ParentObjId', 'LocationType'].concat(CLocationBase.GetMembers());
  }

  public GetMembers(): string[] {
    return CrisisMessageLocation_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public LocationType: SagaBase.LocationTypeEnum = SagaBase.LocationTypeEnum.From;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
