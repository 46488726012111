import { Observable, Subject, Subscription } from 'rxjs';
import Feature from 'ol/feature';
import { Image, Map as SagaMap } from '@techwan/mapping';
import { AddInteraction, MoveInteraction, RemoveInteraction } from '@addins/core/map';
import { IDeploymentOperation } from './interfaces/IDeploymentOperation';
import { DeploymentOperationType } from './deployment-operation-type';
import { IDeploymentDataProvider } from './interfaces/IDeploymentDataProvider';
import { IDeploymentConfiguration } from './interfaces/IDeploymentConfiguration';
import { DeploymentTool, DeploymentToolName } from './deployment-tool';

export class DeploymentOperation {
  private _interaction: AddInteraction | RemoveInteraction | MoveInteraction = null;

  private _interactionSub: Subscription = null;

  private readonly _event = new Subject<IDeploymentOperation>();
  get $event(): Observable<IDeploymentOperation> {
    return this._event.asObservable();
  }

  constructor(
    private _sagaMap: SagaMap,
    private _deploymentDataProvider: IDeploymentDataProvider,
    private _deploymentConfig: IDeploymentConfiguration
  ) {}

  destroy() {
    this.removeInteraction();
  }

  private removeInteraction() {
    if (this._interactionSub !== null) {
      this._interactionSub.unsubscribe();
      this._interactionSub = null;
    }

    if (this._interaction !== null) {
      this._interaction.destroy();
      this._sagaMap.getInteractions().remove(this._interaction);
      this._interaction = null;
    }
  }

  onToolActiveChanged(tool: DeploymentTool) {
    this.removeInteraction();
    if (tool.isActive) {
      this.createInteraction(tool);
    }
  }

  private createInteraction(tool: DeploymentTool) {
    switch (tool.name) {
      case DeploymentToolName.add:
        this.createAddInteraction();
        break;

      case DeploymentToolName.move:
        this.createMoveInteraction();
        break;

      case DeploymentToolName.remove:
        this.createRemoveInteraction();
        break;
    }
  }

  private createAddInteraction() {
    this._interaction = this._deploymentDataProvider.createAddInteraction(this._deploymentConfig.deploymentPointsLayer);
    this._sagaMap.addInteraction(this._interaction);
    this._interactionSub = this._interaction.$event.subscribe(feature => this.addDeploymentPoint(feature));
  }

  private addDeploymentPoint(feature: ol.Feature) {
    this._deploymentDataProvider.confirmAdd().then(confirmed => {
      this._deploymentConfig.deploymentPointsLayer.getSource().removeFeature(feature);
      if (confirmed) {
        this._event.next({ type: DeploymentOperationType.add, feature });
      }
    });
  }

  private createMoveInteraction() {
    this._interaction = this._deploymentDataProvider.createMoveInteraction();
    this._sagaMap.addInteraction(this._interaction);
    this._interaction.$event.subscribe(moveEvent => this.updateFeature(moveEvent.feature, moveEvent.newCoordinate));
  }

  private updateFeature(feature: Feature, newCoordinate: ol.Coordinate) {
    this._deploymentDataProvider.confirmMove().then(confirmed => {
      if (confirmed) {
        const image = feature.getGeometry();
        if (image instanceof Image) {
          image.setCoordinates(newCoordinate);
        }

        this._event.next({ type: DeploymentOperationType.update, feature });
      }
    });
  }

  private createRemoveInteraction() {
    this._interaction = this._deploymentDataProvider.createRemoveInteraction();
    this._sagaMap.addInteraction(this._interaction);
    this._interaction.$event.subscribe(feature => this.removeFeature(feature));
  }

  private removeFeature(feature: Feature) {
    this._deploymentDataProvider.confirmRemoval().then(confirmed => {
      if (confirmed) {
        this._event.next({ feature, type: DeploymentOperationType.remove });
      }
    });
  }
}
