import { Injectable } from '@angular/core';
import { LocationProviderService as CoreLocationProviderService, LocationProxyEvent } from '@addins/core/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocationProviderService {
  public get $event(): Observable<LocationProxyEvent> {
    const proxy = this._location.provider.getProxy();
    return proxy ? proxy.$event : of();
  }

  public get $state(): Observable<boolean> {
    const proxy = this._location.provider.getProxy();
    return proxy ? proxy.$state : of(false);
  }

  public constructor(private readonly _location: CoreLocationProviderService) {}

  public startSendingPosition() {
    const proxy = this._location.provider.getProxy();
    if (proxy && !proxy.isRunning) {
      proxy.start();
    }
  }

  public stopSendingPosition() {
    const proxy = this._location.provider.getProxy();
    if (proxy && proxy.isRunning) {
      proxy.stop();
    }
  }
}
