// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { LearningUnit } from '../../SagaBase/LearningUnit';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class TerminalNode_abstract extends LearningUnit {
  static $t: string = 'SagaBase.TerminalNode';
  $t: string = 'SagaBase.TerminalNode';

  public static GetMembers(): string[] {
    return ['EventId', 'Procedure', 'ProcedureSpecified'].concat(LearningUnit.GetMembers());
  }

  public GetMembers(): string[] {
    return TerminalNode_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public EventId: number = 0;
  public Procedure: any;
  public ProcedureSpecified: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
