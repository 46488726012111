import { Injectable } from '@angular/core';
import { IPushConfiguration } from '@services/push';
import { EnvironmentService } from '@services/environment/environment.service';
import { ConfigurationService } from '@techwan/ionic-core';
import { Platform } from '@ionic/angular';
import { AppSettingsService, SagaSettingsService } from '@services/settings';

@Injectable()
export class PushConfigurationService implements IPushConfiguration {
  constructor(
    private readonly env: EnvironmentService,
    private readonly configuration: ConfigurationService,
    private readonly platform: Platform,
    private readonly sagaSettings: SagaSettingsService,
    private readonly appSettings: AppSettingsService
  ) {}

  getUrl(): string {
    const defaultAddress = !this.platform.is('cordova')
      ? this.env.get('pushAddress') || `${this.configuration.endpoint}Push`
      : `${this.configuration.endpoint}Push`;
    return this.sagaSettings.getValue('SagaWeb.PushAddress') || this.appSettings.getSync('push', defaultAddress);
  }

  isProd(): boolean {
    return this.env.get('production');
  }
}
