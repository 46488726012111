// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @16/01/2024 14:52:15
import { SagaObjectManager } from '../../SagaBase/SagaObjectManager';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CallCard_abstract extends SagaObjectManager {
  static $t: string = 'SagaSchema.CallCard';
  $t: string = 'SagaSchema.CallCard';

  public static GetMembers(): string[] {
    return [
      'IsTest',
      'CreatorId',
      'CreatorName',
      'LinkName',
      'Text',
      'Id',
      'Archived',
      'SubmittedUTC',
      'DueDateUtc',
      'TaskEndDateUTC',
      'ArchiveTypeId',
      'Type',
      'MergedGuid',
      'Identifier',
      'ParentCardUid',
      'JsonData',
      'Name',
      'ExternalReference',
      'ExternalOperator'
    ].concat(SagaObjectManager.GetMembers());
  }

  public GetMembers(): string[] {
    return CallCard_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.SubmittedUTC = this.SubmittedUTC && new Date(this.SubmittedUTC);
    this.DueDateUtc = this.DueDateUtc && new Date(this.DueDateUtc);
    this.TaskEndDateUTC = this.TaskEndDateUTC && new Date(this.TaskEndDateUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return [
      'SagaSchema.CCaller',
      'SagaSchema.CEvent',
      'SagaSchema.CLocation',
      'SagaSchema.CDeploymentPoint',
      'SagaSchema.CObject',
      'SagaSchema.CPerson',
      'SagaSchema.CAnimal',
      'SagaBase.PhoneCall',
      'SagaSchema.CStampCard',
      'SagaSchema.CStampResource',
      'SagaSchema.CStampFree',
      'SagaSchema.CStampProcedure',
      'SagaSchema.CStampProcedureAction',
      'SagaSchema.CVehicle',
      'SagaSchema.CAttachment',
      'SagaSchema.CNote',
      'SagaSchema.CTacticalSituation',
      'SagaSchema.History.CTacticalSituationHistory',
      'SagaSchema.CAlarm',
      'SagaSchema.ComJobOverview',
      'SagaSchema.CPatient',
      'SagaSchema.CProcStep',
      'SagaSchema.CIntel',
      'SagaSchema.CStampParticulars',
      'SagaSchema.CFreeText',
      'SagaSchema.CStampFreeText',
      'SagaSchema.CUnmanagedResourceActivity',
      'SagaSchema.CStampUnitActivity',
      'TW.Saga.Core.Schema.Callcard.CMediaMessage',
      'SagaSchema.History.CCallerHistory',
      'SagaSchema.History.CPatientHistory',
      'SagaSchema.History.CEventHistory',
      'SagaSchema.History.CLocationHistory',
      'SagaSchema.History.CNoteHistory',
      'SagaSchema.History.CPersonHistory',
      'SagaSchema.History.CVehicleHistory',
      'SagaSchema.History.CAnimalHistory',
      'SagaSchema.History.CObjectHistory',
      'SagaSchema.CStampShare',
      'SagaSchema.TaskFrequency',
      'SagaSchema.CFormLink'
    ];
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public IsTest: boolean = false;
  public CreatorId: number;
  public CreatorName: string = '';
  public LinkName: string;
  public Text: string;
  public Id: number = 0;
  public Archived: boolean = false;
  public SubmittedUTC: Date = new Date();
  public DueDateUtc: Date;
  public TaskEndDateUTC: Date;
  public ArchiveTypeId: any;
  public Type: number = 0;
  public MergedGuid: Guid = GuidTools.Empty;
  public Identifier: string = '';
  public ParentCardUid: Guid;
  public JsonData: string;
  public Name: string;
  public ExternalReference: string;
  public ExternalOperator: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
