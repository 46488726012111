<ion-select
  (ionChange)="tool.change($event.detail.value)"
  value="{{ tool.current }}"
  [okText]="'Mobile.OK' | translate"
  [cancelText]="'Mobile.Cancel' | translate"
>
  <ion-select-option *ngFor="let destination of tool.values" value="{{ destination.ResAddressUid }}">{{
    destination.Name
  }}</ion-select-option>
</ion-select>
