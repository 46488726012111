import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaMessageEditorComponent } from '../../components/media-message-editor/media-message-editor.component';

@Injectable()
export class TextMessageService {
  constructor(private modalController: ModalController) {}

  open(): Promise<any> {
    return this.modalController.create({ component: MediaMessageEditorComponent }).then(modal => modal.present());
  }
}
