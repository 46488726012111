import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { CustomModalComponent } from '../../components/custom-modal/custom-modal.component';
import { SimpleModalComponent } from '../../components/simple-modal/simple-modal.component';
import { CustomModalParameter } from '../../schema/interfaces/custom-modal-parameter';
import { ModalButton } from '../../schema/interfaces/modal-button';
import { ModalParameter } from '../../schema/interfaces/modal-parameter';
import { SimpleModalParameter } from '../../schema/interfaces/simple-modal-parameter';
import { ModalButtonImpl } from '../../schema/modal-button-impl';

export interface IModal<T> {
  modal?: HTMLIonModalElement;
  onDismiss?: Promise<OverlayEventDetail<T>>;
}

@Injectable()
export class ModalFactoryService {
  constructor(private readonly _modalController: ModalController) {}

  simpleModal(options: SimpleModalParameter): Promise<OverlayEventDetail> {
    return this.create({
      component: SimpleModalComponent,
      componentProps: this.createButtons(options)
    }).then(r => r.onDismiss);
  }

  customModal<T>(options: CustomModalParameter<T>, canDismiss: boolean = true): Promise<OverlayEventDetail<T>> {
    return this.create<T>({
      component: CustomModalComponent,
      componentProps: this.createButtons(options),
      backdropDismiss: canDismiss
    }).then(r => r.onDismiss);
  }

  customModalWithInstance<T>(options: CustomModalParameter<T>, canDismiss: boolean = true): Promise<IModal<T>> {
    return this.create({
      component: CustomModalComponent,
      componentProps: this.createButtons(options),
      backdropDismiss: canDismiss
    });
  }

  protected create<T>(options: ModalOptions): Promise<IModal<T>> {
    return this.onDismiss(this._modalController.create(options));
  }

  protected onDismiss<T>(modal: Promise<HTMLIonModalElement>): Promise<IModal<T>> {
    const res: IModal<T> = {};
    return modal.then(m =>
      (res.modal = m).present().then(() => {
        res.onDismiss = m.onDidDismiss();
        return res;
      })
    );
  }

  private createButtons(options: ModalParameter): ModalParameter {
    if (typeof options.buttons === 'string') {
      options.buttons = this.fromString(options.buttons);
    }
    return options;
  }

  private fromString(buttons: string): ModalButton[] {
    return buttons.split(',').map(i => new ModalButtonImpl(i, i));
  }

  createModalButton(modalButton: ModalButton): ModalButtonImpl {
    return new ModalButtonImpl(
      modalButton.action,
      modalButton.text,
      modalButton.fill || 'solid',
      modalButton.expand || 'block',
      modalButton.icon,
      modalButton.color || 'primary',
      modalButton.isDisabled || false,
      modalButton.isHidden || false
    );
  }
}
