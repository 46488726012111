// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:28:25
/* tslint:disable */
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CPatientBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CPatientBase';
  $t: string = 'SagaSchema.CPatientBase';

  public static GetMembers(): string[] {
    return [
      'CallCardId',
      'Birthday',
      'AgeYear',
      'AgeMonth',
      'AgeDay',
      'IsAdult',
      'Gender',
      'NACA',
      'SSP',
      'TransportStampUid',
      'TransportName',
      'MedicalizationIdStampUid',
      'MedicalizationName',
      'DestinationResourceUid',
      'HasTransportName',
      'HasMedicalization',
      'DoctorResourceUid',
      'DoctorName',
      'PatientNumber',
      'Lname',
      'Fname',
      'Phone',
      'Mobile',
      'TraceId',
      'CoordEpsgCode',
      'NeedUserValidation',
      'PhonebookSource',
      'GeoPoint',
      'RecordChannel',
      'LineOwner',
      'CalledService',
      'DestinationText',
      'LocationText',
      'TraceState',
      'IsFirstCaller'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CPatientBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.Birthday = this.Birthday && new Date(this.Birthday);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.CPatientLocation', 'SagaSchema.CPatientDestinationLocation'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public Birthday: Date;
  public AgeYear: number = 0;
  public AgeMonth: number = 0;
  public AgeDay: number = 0;
  public IsAdult: boolean = false;
  public Gender: string;
  public NACA: string;
  public SSP: string;
  public TransportStampUid: Guid = GuidTools.Empty;
  public TransportName: string;
  public MedicalizationIdStampUid: Guid = GuidTools.Empty;
  public MedicalizationName: string;
  public DestinationResourceUid: Guid = GuidTools.Empty;
  public HasTransportName: boolean = false;
  public HasMedicalization: boolean = false;
  public DoctorResourceUid: Guid;
  public DoctorName: string;
  public PatientNumber: number = 0;
  public Lname: string;
  public Fname: string;
  public Phone: string;
  public Mobile: string;
  public TraceId: Guid = GuidTools.Empty;
  public CoordEpsgCode: string;
  public NeedUserValidation: boolean = false;
  public PhonebookSource: SagaBase.EtdSource = SagaBase.EtdSource.None;
  public GeoPoint: any;
  public RecordChannel: string;
  public LineOwner: number = 0;
  public CalledService: string;
  public DestinationText: string;
  public LocationText: string;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public IsFirstCaller: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
