// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class RbacSiteRoles_abstract extends PersistentObject {
  static $t: string = 'SagaBase.RBAC.RbacSiteRoles';
  $t: string = 'SagaBase.RBAC.RbacSiteRoles';

  public static GetMembers(): string[] {
    return ['RoleFkId', 'SiteFkId', 'SiteRoleDefault', 'SiteRoleId'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return RbacSiteRoles_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public RoleFkId: number = 0;
  public SiteFkId: number = 0;
  public SiteRoleDefault: boolean = false;
  public SiteRoleId: number;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return null;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
