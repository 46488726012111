import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UnitCreationService } from '../../services/unit-creation/unit-creation.service';
import { UnitEditorService } from '../../services/unit-editor/unit-editor.service';

@Component({
  selector: 'app-unit-summary',
  templateUrl: './unit-summary.component.html',
  styleUrls: ['./unit-summary.component.scss']
})
export class UnitSummaryComponent implements OnInit, OnDestroy {
  get mainResources(): SagaObject[] {
    return [...this._editor.editor.vehicles, ...this._editor.editor.actors, ...this._editor.editor.equipments];
  }

  get communications(): CommunicationEquipment[] {
    return this._editor.editor.equipments;
  }

  get agents(): Actor[] {
    return this._editor.editor.actors;
  }

  get vehicles(): Vehicle[] {
    return this._editor.editor.vehicles;
  }

  get positions(): SagaObject[] {
    return [...this._editor.editor.equipments, ...this._editor.editor.vehicles, ...this._editor.editor.actors];
  }

  get main(): SagaObject {
    return this._editor.editor.mainResource;
  }

  get agent(): Actor {
    return this._editor.editor.agent;
  }

  get position(): SagaObject {
    return this._editor.editor.position;
  }

  get communication(): CommunicationEquipment {
    return this._editor.editor.communications;
  }

  get name(): string {
    return this._editor.editor.name;
  }

  httpError: HttpErrorResponse = null;
  error = false;

  constructor(private _editor: UnitEditorService, private _modal: ModalController, private _factory: UnitCreationService) {}

  cancel() {
    this._modal.dismiss();
  }

  getItemText(item: SagaObject): string {
    return item instanceof Vehicle ? item.VehicleCode : item instanceof Actor ? item.Name : (item as CommunicationEquipment).Name;
  }

  ngOnInit() {
    const editor = this._editor.editor;
    if (this.mainResources.length && editor.mainResource === null) {
      editor.mainResource = this.mainResources[0];
      this.setName(editor.mainResource.ObjGuid);
    }
    if (this.positions.length && editor.position === null) {
      editor.position = this.positions[0];
    }
    if (editor.equipments.length && editor.communications === null) {
      editor.communications = editor.equipments[0];
    }
    if (editor.actors.length && editor.agent === null) {
      editor.agent = editor.actors[0];
    }
  }

  ngOnDestroy(): void {
    this.resetSelects();
  }

  resetSelects() {
    this._editor.editor.mainResource = null;
    this._editor.editor.position = null;
    this._editor.editor.communications = null;
    this._editor.editor.agent = null;
  }

  setName(id: string) {
    const main = this.mainResources.find(resource => resource.ObjGuid === id) || null;
    this._editor.editor.mainResource = main;
    this._editor.editor.name =
      main instanceof Vehicle ? main.VehicleCode : main instanceof Actor ? main.Name : (main as CommunicationEquipment).Name;
  }

  validate() {
    this.httpError = null;
    this.error = false;
    this._factory
      .create(this._editor.editor)
      .pipe(catchError(error => this.handleError(error)))
      .subscribe(res => this.parse(res));
  }

  private handleError(error: HttpErrorResponse) {
    this.httpError = error;
    return of(null);
  }

  private parse(res: boolean) {
    if (res === true) {
      this._modal.dismiss(res);
    } else if (this.httpError === null) {
      this.error = true;
    }
  }
}
