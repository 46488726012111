import { Injectable } from '@angular/core';
import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { TranslateService } from '@ngx-translate/core';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { ProfileService } from '@services/profile/profile.service';
import { IEventFormatter } from '../../schema/interfaces/event-formatter';
import { CnuEventFormatter } from './formatters/cnu-event-formatter';
import { DefaultEventFormatter } from './formatters/default-event-formatter';
import { FusEventFormatter } from './formatters/fus-event-formatter';
import { PcfEventFormatter } from './formatters/pcf-event-formatter';
import { PnEventFormatter } from './formatters/pn-event-formatter';

@Injectable({
  providedIn: 'root'
})
export class EventFormatterService {
  private formatter: IEventFormatter;

  constructor(private profile: ProfileService, private translate: TranslateService, private fetchList: FetchListService) {}

  format(event: CEvent, short: boolean = false): string {
    return this.getFormatter().format(event, short);
  }

  private getFormatter(): IEventFormatter {
    if (!this.formatter) {
      this.formatter = this.createFormatter();
    }

    return this.formatter;
  }

  private createFormatter(): IEventFormatter {
    const formatterName: string = this.profile.current.webConfig.eventFormatter;
    switch (formatterName) {
      case 'cnu':
        return new CnuEventFormatter();

      case 'fus':
        return new FusEventFormatter(this.fetchList, this.translate);

      case 'pcf':
        return new PcfEventFormatter();

      case 'pn':
        return new PnEventFormatter();

      default:
        return new DefaultEventFormatter();
    }
  }
}
