import { EventFormatterService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CEvent } from '@models/imported/SagaSchema/CEvent';
import { CacheAction } from '@services/cache/cacheEvent';
import { IModelService, IObjectChange, ModelService } from '@services/model-factory';

@ModelService(CEvent)
@Injectable()
export class CEventModelService implements IModelService<CEvent> {
  constructor(private eventFormatter: EventFormatterService) {}

  change(change: IObjectChange<CEvent>) {
    if (change.action === CacheAction.removed) {
      return;
    }

    const event: CEvent = change.target;
    event.rLong = this.eventFormatter.format(event);
  }
}
