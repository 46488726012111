import { Injectable } from '@angular/core';
import { IConfiguration, ILoginResult, ILoginConfig, IRefreshTokenConfiguration, IRefreshTokenStorage } from '@techwan/security';
import { BehaviorSubject, Observable } from 'rxjs';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';
import { AppSettingsService } from '@services/settings';
import { ConfigurationService } from '@techwan/ionic-core';
import { PersistentRefreshTokenService } from '@services/persistent-refresh-token/persistent-refresh-token.service';
import { IMobileLoginConfig } from '@services/login-config/login-config.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginConfigService implements IConfiguration {
  private _loginConfig: ILoginConfig;
  public get loginConfig(): ILoginConfig {
    return this._loginConfig;
  }

  private readonly _ready = new BehaviorSubject<boolean>(false);
  public get ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  constructor(
    private readonly _refreshTokenStorage: RefreshTokenStorageService,
    private readonly _persistentTokenService: PersistentRefreshTokenService,
    private readonly _appSettings: AppSettingsService,
    private readonly _configuration: ConfigurationService,
    private readonly _httpClient: HttpClient
  ) {}

  getConfigurationForRefreshToken(login: ILoginResult): IRefreshTokenConfiguration {
    return {
      refreshTokenDelay: this._loginConfig.AccessTokenWatchdogMarginInSec * 1000,
      expirationNotification: this._loginConfig.RefreshTokenWatchdogMarginInSec * 1000,
      expirationDate: new Date(login.RefreshTokenValidUntilUtc),
      validUntil: new Date(login.Claims.ValidUntilUtc)
    };
  }

  getLoginConfig(): Observable<IMobileLoginConfig> {
    let baseUrl: string;
    return this._appSettings.apiBaseUrl().pipe(
      tap(url => (baseUrl = this.parseUrl(url))),
      switchMap(() => this._httpClient.get<IMobileLoginConfig>('api/Security/Config')),
      switchMap(loginConfig => {
        this.storeLoginConfig(loginConfig, baseUrl);
        return this._persistentTokenService.onPersistentRefreshTokenChanged(loginConfig.PersistentRefreshToken).then(() => {
          this._ready.next(true);
          return loginConfig;
        });
      })
    );
  }

  private parseUrl(url: string): string {
    this._configuration.endpoint = url ? (!url.endsWith('/') ? `${url}/` : url) : '';
    return `Push`;
  }

  private storeLoginConfig(loginConfig: ILoginConfig, baseUrl: string) {
    this._loginConfig = loginConfig;
    loginConfig.baseUrl = baseUrl;
  }

  getStorage(): IRefreshTokenStorage {
    return this._refreshTokenStorage;
  }
}
