import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { AssociationSecurity, DEVICE_SECURITY_KEY } from '@services/association-security/association-security.service';
import { GlobalizationService } from '@services/globalization/globalization.service';
import { ModalService } from '@services/modal/modal.service';
import { NavService } from '@services/navigation/navigation.service';
import { Security } from '@services/security/security.service';
import { AppSettingsService } from '@services/settings';
import { StylesService } from '@services/styles/styles.service';
import { zip } from 'rxjs';

@Component({
  templateUrl: 'settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  apiBaseUrl: string;
  updateBaseUrl: string;
  language: string;
  platformLanguage: string;
  requestMode: number;
  cameraQuality: number;
  saveToAlbum: boolean;
  _createOnShake: boolean;
  equipment: CommunicationEquipment;
  isDev: boolean;

  get scale(): number {
    return Math.round(this.styles.scale);
  }

  set scale(scale: number) {
    this.styles.scale = Math.round(scale);
  }

  get deviceName(): string {
    return this.security.getSecurityData<string>(DEVICE_SECURITY_KEY);
  }

  constructor(
    public settingsService: AppSettingsService,
    private globalization: GlobalizationService,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public platform: Platform,
    public security: Security,
    public styles: StylesService,
    private modal: ModalService,
    private nav: NavService,
    private association: AssociationSecurity
  ) {
    this.isDev = !environment.production;
    // this.modal.listenToModalView(this.viewCtrl);
  }

  get createOnShake() {
    return this._createOnShake;
  }

  set createOnShake(createOnShake: boolean) {
    localStorage.setItem('createOnShake', createOnShake ? '1' : '0');
    this._createOnShake = createOnShake;
  }

  async ionViewWillEnter() {
    if (this.platform.is('cordova')) {
      zip(
        this.settingsService.getPlatformLanguage(),
        this.settingsService.language(true),
        this.settingsService.apiBaseUrl(),
        this.settingsService.updateBaseUrl(),
        this.settingsService.cameraQuality(),
        this.settingsService.saveToAlbum()
      ).subscribe(res => {
        this.platformLanguage = res[0] as string;
        this.language = res[1] as string;
        this.apiBaseUrl = res[2] as string;
        this.updateBaseUrl = res[3] as string;
        this.cameraQuality = res[4] as number;
        this.saveToAlbum = res[5] as boolean;
      });
    } else {
      this.platformLanguage = navigator.language;
      this.language = localStorage.getItem('lang') || this.platformLanguage;
      this.cameraQuality = parseInt(localStorage.getItem('quality'), 10) || 90;
    }

    this.requestMode = parseInt(localStorage.getItem('request_mode') || '0', 10);
    this.equipment = this.association.getDevice();
    this._createOnShake = !!(parseInt(localStorage.getItem('createOnShake'), 10) || false);
  }

  clearData() {
    this.security.clearSecurityData();
    window.location.reload();
  }

  showServers() {}

  settingChange(name: string, value: any) {
    if (name === 'request_mode') {
      localStorage.setItem('request_mode', this.requestMode + '');
      window.location.reload();
      return;
    }
    this.settingsService.set(name, value);

    if (name === 'lang') {
      if (value === 'platform') {
        value = this.platformLanguage;
      }
      this.globalization.setLanguage(value);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
