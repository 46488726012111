import { LanguageService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Guid } from '@models/guid';
import { GlobalizationService } from '@services/globalization/globalization.service';
import { Initializer as AppInitializer } from '@services/initializer/initializer.service';
import { ModalService } from '@services/modal/modal.service';
import { AuthenticationService, MyEquipmentService, MyEquipmentState } from '@techwan/ionic-core';
import { LoginEvent } from '@techwan/security';
import { BehaviorSubject, Observable, Subscription, combineLatest, from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

export enum InitializationState {
  pending,
  authenticated,
  setup,
  requireAction,
  completed
}

@Injectable()
export class InitializerService {
  private readonly _event = new BehaviorSubject<InitializationState>(InitializationState.pending);
  get ready(): Observable<InitializationState> {
    return this._event.asObservable();
  }

  private _sub: Subscription;
  private _loading: Promise<void> = null;
  private _currentUnitId: Guid = null;

  /**
   * Wait until platform is ready and authentication has been successfully completed.
   */
  constructor(
    private readonly initializer: AppInitializer,
    private readonly auth: AuthenticationService,
    private readonly myEquipment: MyEquipmentService,
    private readonly platform: Platform,
    private readonly modal: ModalService,
    private readonly _language: LanguageService,
    private readonly globalization: GlobalizationService
  ) {
    this._sub = from(this.platform.ready())
      .pipe(switchMap(() => this.auth.onStateChange.pipe(filter(e => e === LoginEvent.authenticated))))
      .subscribe(() => this.onAuthenticated());
  }

  private onAuthenticated() {
    this._sub.unsubscribe();
    this._loading = this.modal.presentLoading('static.login.device.initializing');
    this._language
      .getLanguage()
      .pipe(switchMap(l => this.globalization.init(l)))
      .subscribe(() => this.setupEquipment());
  }

  private setup() {
    this._event.next(InitializationState.setup);
    combineLatest([this.myEquipment.myDevice, this.myEquipment.myUnit]).subscribe(e => this.parseEquipment(e));
  }

  private parseEquipment([device, unit]) {
    if (device !== null && unit !== null) {
      this._currentUnitId = unit.ObjGuid;
      this.initializer.setup();
      this._event.next(InitializationState.completed);
    } else {
      this._event.next(InitializationState.requireAction);
    }
    // Disconnect the push and everything.
    if (unit === null && this._currentUnitId !== null) {
      this._currentUnitId = null;
      this.initializer.setDown();
    }
  }

  private setupEquipment() {
    this._loading.then(() => this.modal.dismissLoading());
    this._sub = this.myEquipment.stateChanged.pipe(filter(e => e === MyEquipmentState.ready)).subscribe(() => {
      this._sub.unsubscribe();
      this.initializer.setupBefore(this.auth).then(() => this.setup());
      this._event.next(InitializationState.authenticated);
    });
  }
}
