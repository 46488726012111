// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class RbacProfile_abstract extends PersistentObject {
  static $t: string = 'SagaBase.RBAC.RbacProfile';
  $t: string = 'SagaBase.RBAC.RbacProfile';

  public static GetMembers(): string[] {
    return ['ApplicationProfileId', 'ApplicationId', 'Name', 'IsHidden', 'IsDefaultProfile'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return RbacProfile_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ApplicationProfileId: number = 0;
  public ApplicationId: string;
  public Name: string;
  public IsHidden: boolean = false;
  public IsDefaultProfile: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.ApplicationProfileId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
