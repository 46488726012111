import { Component, Input, OnInit } from '@angular/core';
import { ImagesSettingsService } from '@services/settings';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input()
  private icon?: string;

  @Input()
  public alt?: string;

  @Input()
  public maxWidth?: string;

  private readonly linkProtocols = ['data:', 'http', 'file://', '//'];

  public get src(): string {
    return this.icon && this.linkProtocols.find(i => this.icon.startsWith(i)) ? this.icon : this.imageSettings.get(this.icon) || '';
  }
  constructor(private readonly imageSettings: ImagesSettingsService) {}

  ngOnInit() {}
}
