import { Injectable } from '@angular/core';
import { UserProfile } from '@models/imported/SagaBase/UserProfile';
import { IProfileComponent } from '@models/profile';
import { ProfileService } from '@services/profile/profile.service';

@Injectable()
export class ProfileReaderService {
  constructor(private _profileService: ProfileService) {}

  getMainViewName(profile: UserProfile): string {
    return profile && profile.webLayout ? profile.webLayout.home : '';
  }

  getComponent(profile: UserProfile, name: string): IProfileComponent {
    return profile.webProfile.components.find(i => i.name === name) || null;
  }

  getComponentConfig<T = any>(componentName: string, configName: string, defaultValue: T = null): T {
    const component = this.getComponent(this._profileService.current, componentName);
    if (component && component.config && component.config[configName] !== undefined) {
      return component.config[configName];
    }
    return defaultValue;
  }
}
