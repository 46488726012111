// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class PhoneLine_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.ListObjects.PhoneLine';
  $t: string = 'SagaSchema.ListObjects.PhoneLine';

  public static GetMembers(): string[] {
    return [
      'ListPhoneLineId',
      'LineIdentifier',
      'DisplayName',
      'OfferingForegroundColor',
      'OfferingBackgroundColor',
      'RingToneFileName',
      'HandleCalls',
      'CallCardType',
      'WorkstationVisibility',
      'SiteVisibility',
      'SiteNameVisibility'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return PhoneLine_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ListPhoneLineId: number = 0;
  public LineIdentifier: string;
  public DisplayName: string;
  public OfferingForegroundColor: string;
  public OfferingBackgroundColor: string;
  public RingToneFileName: string;
  public HandleCalls: boolean = false;
  public CallCardType: number;
  public WorkstationVisibility: string;
  public SiteVisibility: number;
  public SiteNameVisibility: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.ListPhoneLineId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
