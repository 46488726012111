// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ListResourceTypes_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.ListObjects.ListResourceTypes';
  $t: string = 'SagaSchema.ListObjects.ListResourceTypes';

  public static GetMembers(): string[] {
    return ['Description', 'Icon', 'TypeName', 'Id'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ListResourceTypes_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Description: string;
  public Icon: any[];
  public TypeName: string;
  public Id: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
