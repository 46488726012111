// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import * as SagaBase from '../../SagaBase';
import { CStamp } from '../../SagaSchema/CStamp';
import * as SagaSchema from '../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CStampFree_abstract extends CStamp {
  static $t: string = 'SagaSchema.CStampFree';
  $t: string = 'SagaSchema.CStampFree';

  public static GetMembers(): string[] {
    return ['SourceComponentUid', 'ElementType', 'ElementId', 'ElementName', 'Text', 'TraceState'].concat(CStamp.GetMembers());
  }

  public GetMembers(): string[] {
    return CStampFree_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public SourceComponentUid: Guid = GuidTools.Empty;
  public ElementType: number = 0;
  public ElementId: Guid = GuidTools.Empty;
  public ElementName: string = '';
  public Text: string = '';
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
