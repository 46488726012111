import { EventFormatterService } from '@addins/core/core';
import { Component, Input, OnInit } from '@angular/core';
import { CEvent } from '@models/imported/SagaSchema/CEvent';

@Component({
  selector: 'app-callcard-list-event',
  templateUrl: './callcard-list-event.component.html',
  styleUrls: ['./callcard-list-event.component.scss']
})
export class CallcardListEventComponent implements OnInit {
  @Input()
  event?: CEvent;

  private _eventText: string = '';
  get text(): string {
    if (this.event) {
      this._eventText = this.eventFormatter.format(this.event);
    }
    return this._eventText;
  }

  constructor(private eventFormatter: EventFormatterService) {}

  ngOnInit() {}
}
