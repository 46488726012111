<ion-header translucent>
  <ion-toolbar>
    <ion-title translate>Mobile.SetNumberOfPeople</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content largeFont>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <ion-button expand="block" fill="clear" size="large" class="result">
          {{ result }}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let keyRow of keyRows" class="ion-justify-content-center">
      <ion-col *ngFor="let key of keyRow">
        <ion-button expand="block" color="light" size="large" (click)="onDigitClicked(key)">{{ key }}</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <ion-button expand="block" color="medium" size="large" (click)="onClearClicked()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button expand="block" color="light" size="large" (click)="onDigitClicked('0')">0</ion-button>
      </ion-col>
      <ion-col>
        <ion-button expand="block" color="medium" size="large" (click)="onClearLastClicked()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-button (click)="onDismiss()" color="danger" translate>Mobile.Cancel</ion-button>
  <ion-button (click)="onSubmit()" color="primary" translate [disabled]="!isValid">Mobile.Confirm</ion-button>
</ion-footer>
