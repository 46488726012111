import { CardTitleFormatterService, CurrentCardService } from '@addins/core/core';
import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { JournalModalComponent } from '../../../components/journal-modal/journal-modal.component';
import { JournalCardButton } from '../../../schema/journal-card-button';

@AddinExecute({
  name: 'JournalCardButton'
})
@Injectable()
export class JournalCardButtonService extends Execute {
  private journalCardButton: JournalCardButton = null;

  constructor(
    private buttonProvider: ButtonsProviderService,
    private currentCardService: CurrentCardService,
    private titleFormatter: CardTitleFormatterService,
    private modalController: ModalController
  ) {
    super();
  }

  execute() {
    this.journalCardButton = new JournalCardButton();

    this.journalCardButton.onClick.subscribe(() => this.onJournalButtonClicked());
    this.currentCardService.$change.subscribe(callcard => this.onCurrentCardChanged(callcard));

    this.buttonProvider.add(this.journalCardButton);
  }

  private onJournalButtonClicked() {
    const callcard: CallCard = this.currentCardService.current;
    const title: string = this.titleFormatter.format(callcard);
    this.modalController
      .create({
        component: JournalModalComponent,
        componentProps: { callcard: callcard, title: title },
        cssClass: 'fullscreen-modal'
      })
      .then(modal => modal.present());
  }

  private onCurrentCardChanged(callcard: CallCard) {
    this.journalCardButton.show = callcard != null;
  }
}
