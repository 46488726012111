// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Vehicle_abstract extends SagaObject {
  static $t: string = 'SagaSchema.Vehicle';
  $t: string = 'SagaSchema.Vehicle';

  public static GetMembers(): string[] {
    return [
      'CallCardID',
      'Service',
      'GPSMode',
      'ResRadioId',
      'CallCode',
      'ResRadioCallCode',
      'ResourceImpId',
      'ResResourceId',
      'Misc',
      'RegistrationNumber',
      'RadioModemID',
      'Status',
      'ActivityUid',
      'ActivityStatus',
      'ActivityCounter',
      'VehicleCode',
      'ImageName',
      'VehicleImage',
      'Model',
      'Color',
      'TypeImageSymbol',
      'TypeName',
      'VehicleTypeId',
      'VehicleTypeCode',
      'HasActiveGPS',
      'HasTIE',
      'Special',
      'FreeField1',
      'FreeField2',
      'FreeField3',
      'FreeField4',
      'DefaultSchedule',
      'ActiveFromUTC',
      'InactiveFromUTC',
      'BackInServiceUTC',
      'RetiredUTC'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Vehicle_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ActiveFromUTC = this.ActiveFromUTC && new Date(this.ActiveFromUTC);
    this.InactiveFromUTC = this.InactiveFromUTC && new Date(this.InactiveFromUTC);
    this.BackInServiceUTC = this.BackInServiceUTC && new Date(this.BackInServiceUTC);
    this.RetiredUTC = this.RetiredUTC && new Date(this.RetiredUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.ResourcePosition', 'SagaSchema.PhoneNumber', 'SagaSchema.ResVideo'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardID: Guid = GuidTools.Empty;
  public Service: Guid = GuidTools.Empty;
  public GPSMode: number = 1;
  public ResRadioId: number;
  public CallCode: string = '';
  public ResRadioCallCode: string;
  public ResourceImpId: number = 0;
  public ResResourceId: number = 0;
  public Misc: string = '';
  public RegistrationNumber: string = '';
  public RadioModemID: string;
  public Status: number = 0;
  public ActivityUid: Guid = GuidTools.Empty;
  public ActivityStatus: SagaBase.Interfaces.ActivityStatusType = SagaBase.Interfaces.ActivityStatusType.Available;
  public ActivityCounter: number = 0;
  public VehicleCode: string = '';
  public ImageName: string;
  public VehicleImage: string = '';
  public Model: string = '';
  public Color: string = '';
  public TypeImageSymbol: number = 0;
  public TypeName: string;
  public VehicleTypeId: number = 0;
  public VehicleTypeCode: string = '';
  public HasActiveGPS: boolean = false;
  public HasTIE: boolean = false;
  public Special: boolean = false;
  public FreeField1: string;
  public FreeField2: string;
  public FreeField3: string;
  public FreeField4: string;
  public DefaultSchedule: SagaBase.DefaultScheduleType = SagaBase.DefaultScheduleType.Available;
  public ActiveFromUTC: Date = new Date();
  public InactiveFromUTC: Date = new Date();
  public BackInServiceUTC: Date = new Date();
  public RetiredUTC: Date = new Date();

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
