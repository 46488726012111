// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaActionLib from '../../SagaActionLib';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ProcedureActionCondition_abstract extends BaseObject {
  static $t: string = 'SagaActionLib.ProcedureActionCondition';
  $t: string = 'SagaActionLib.ProcedureActionCondition';

  public static GetMembers(): string[] {
    return ['GroupLogic', 'Negate'];
  }

  public GetMembers(): string[] {
    return ProcedureActionCondition_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GroupLogic: SagaActionLib.GroupType = SagaActionLib.GroupType.and;
  public Negate: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
