// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ApplicationAccessFilter_abstract extends PersistentObject {
  static $t: string = 'SagaBase.ApplicationAccessFilter';
  $t: string = 'SagaBase.ApplicationAccessFilter';

  public static GetMembers(): string[] {
    return ['FilterId', 'ApplicationAccessConst', 'ObjectName', 'PropertyName', 'Filters', 'Context', 'Exclude', 'Operator'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return ApplicationAccessFilter_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public FilterId: number = 0;
  public ApplicationAccessConst: number = 0;
  public ObjectName: string;
  public PropertyName: string;
  public Filters: string;
  public Context: string;
  public Exclude: boolean = false;
  public Operator: SagaBase.FilterOperators = SagaBase.FilterOperators.none;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
