// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class StatusArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.StatusArgs';
  $t: string = 'SagaBase.Defines.StatusArgs';

  public static GetMembers(): string[] {
    return [
      'CrewCallCardUID',
      'UserId',
      'ServiceId',
      'ProcStepUid',
      'Resource',
      'ResourceType',
      'ResourceId',
      'CallCode',
      'NewCallcardId',
      'NewStatus',
      'Message',
      'XmlData',
      'IsAutoAssign',
      'IsDecoupledUnavailableCrewMode',
      'IsMobileSource'
    ];
  }

  public GetMembers(): string[] {
    return StatusArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CrewCallCardUID: Guid = GuidTools.Empty;
  public UserId: number = 0;
  public ServiceId: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public Resource: any;
  public ResourceType: SagaBase.Defines.ResType = SagaBase.Defines.ResType.Unknown;
  public ResourceId: Guid = GuidTools.Empty;
  public CallCode: string;
  public NewCallcardId: Guid = GuidTools.Empty;
  public NewStatus: number = 0;
  public Message: any;
  public XmlData: string;
  public IsAutoAssign: boolean = false;
  public IsDecoupledUnavailableCrewMode: boolean = false;
  public IsMobileSource: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
