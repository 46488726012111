import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CompletionHandlerDelegate, ICompletionDelegate } from '@services/initializer/completion-handler-delegate';
import { Initializer } from '@services/initializer/initializer.service';
import { ButtonItem, MapService } from '@services/map';
import { Security } from '@services/security/security.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { Image, LocalizableObjectLayer, ObjectFeature, Map as SagaMap } from '@techwan/mapping';
import { Subscription, zip } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ILayerController } from '../../schema/interfaces/ILayerController';
import { FeatureStyleService } from '../feature-style/feature-style.service';
import { LocalizePositionService } from '../localize-position/localize-position.service';
import { MyLocationService } from '../my-location/my-location.service';
import { ProjectionConverterService } from '../projection-converter/projection-converter.service';
import { LocalizeButton } from './localize-button';

@Injectable()
export class PositionLayerService implements ICompletionDelegate, ILayerController {
  private readonly localizeButton: ButtonItem;
  private readonly subs: Subscription[] = [];
  private removeButton: () => void = null;
  private pointerDragListener: any = null;
  private completionHandler: CompletionHandlerDelegate;

  private myUnitName: string = null;
  private myLocationLayer: LocalizableObjectLayer = null;
  private myLocationFeature: ObjectFeature = null;
  private map: SagaMap = null;
  private unitUpdatedSub: Subscription = null;
  private locationValidFilter: (coordinate: Coordinate) => boolean;

  private followMe: boolean = false;

  constructor(
    private mapService: MapService,
    private security: Security,
    private cache: CacheService,
    private initializer: Initializer,
    private featureStyle: FeatureStyleService,
    private myUnit: MyUnitService,
    private myLocation: MyLocationService,
    private projectionConverter: ProjectionConverterService,
    private localizePosition: LocalizePositionService,
    private myEquipmentService: MyEquipmentService
  ) {
    this.locationValidFilter = this.isValidLocation.bind(this);
    this.localizeButton = new LocalizeButton(this.mapService, this, () => this.setMapToCurrentLocation());

    this.myUnit.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity));

    this.initializer.onSetupBefore.pipe(first()).subscribe(() => {
      this.myLocation.setup().subscribe();
    });
  }

  private onUnitChanged(unitActivity: UnitActivity) {
    if (unitActivity === null && this.unitUpdatedSub !== null) {
      this.unitUpdatedSub.unsubscribe();
      this.unitUpdatedSub = null;
    } else if (unitActivity !== null && this.unitUpdatedSub === null) {
      this.unitUpdatedSub = unitActivity.$changed.subscribe(() => this.updateUnitFeature());
    }
  }

  private updateUnitFeature() {
    const unit = this.myUnit.mine;
    if (this.myLocationFeature && unit !== null) {
      this.myUnitName = unit.Name;
      if (this.myLocationFeature) {
        this.featureStyle.myLocationStyle(this.myLocationFeature, this.myUnitName, unit);
      }
    }
  }

  mapDidChange(map: SagaMap) {
    if (map && this.map === null) {
      this.map = map;

      this.initMyLocationLayer();
      this.initMyLocationFeature();

      this.subs.push(
        this.cache.state
          .pipe(
            filter(cacheState => cacheState === CacheState.ready),
            first()
          )
          .subscribe(() => {
            this.onCacheReady();
          })
      );
    } else {
      // The map has become null (e.g. it has been removed from the view).
      this.clean();
      this.map = null;
    }
  }

  private initMyLocationLayer() {
    this.myLocationLayer = this.map.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      zIndex: 3
    });
  }

  private initMyLocationFeature() {
    // boot cache might not be ready when we first display the map, so we get the unit name from what info we got in the setup phase
    if (!this.myUnitName) {
      this.myEquipmentService.myUnit.subscribe(myUnitActivity => (this.myUnitName = myUnitActivity.Name)).unsubscribe();
    }

    // Listen to the position of the device
    // we add the subscription to the first valid location to the subs so we can unsubscribe in case we move away
    // from the map before we receive it
    this.subs.push(
      this.myLocation.$change.pipe(filter(this.locationValidFilter), first()).subscribe(coordinate => {
        this.createMyLocationFeature(coordinate);
        if (this.myLocationFeature) {
          this.completionHandler = new CompletionHandlerDelegate(this.initializer, this);
          this.subs.push(this.myLocation.$change.subscribe(coordinate => this.onLocationChanged(coordinate)));
        }
      })
    );
  }

  private isValidLocation(coordinate): boolean {
    return coordinate !== this.myLocation.empty;
  }

  private createMyLocationFeature(coordinate: Coordinate) {
    this.myLocationFeature = this.myLocationLayer.addObject('@position', [coordinate.x, coordinate.y], coordinate.epsg);
    if (this.myLocationFeature !== null) {
      this.security.setData('MapPositionFeature', this.myLocationFeature);
      this.featureStyle.myLocationStyle(this.myLocationFeature, this.myUnitName);
    } else {
      console.warn(`My unit could not be added to the map (coordinates: x: ${coordinate.x}, y: ${coordinate.y}, epsg: ${coordinate.epsg})`);
    }
  }

  private onLocationChanged(coordinate: Coordinate) {
    if (this.isValidLocation(coordinate)) {
      const view = this.map.getView();
      const convertedCoordinate = this.projectionConverter.convertToView(
        view,
        [coordinate.x, coordinate.y],
        this.projectionConverter.getProjection(coordinate.epsg)
      );

      if (this.followMe) {
        this.localizePosition.centerView(view, coordinate, 1);
      }

      (this.myLocationFeature.getGeometry() as Image).setCoordinates(convertedCoordinate);
      this.myLocationFeature.changed();
    }
  }

  setFollowMe(followMe: boolean) {
    this.followMe = followMe;
    if (this.followMe) {
      this.onLocationChanged(this.myLocation.value);
    }
  }

  private onCacheReady() {
    // We're going to wait for the unit to be set first,
    // and then we want an actual location to be set as well (not an empty one).
    // Only after both are available we'll apply the style for our unit.
    this.subs.push(
      zip(this.myUnit.$unitChanged.pipe(filter(unit => unit !== null)), this.myLocation.$change.pipe(filter(this.locationValidFilter)))
        .pipe(first())
        .subscribe(([unitActivity, coordinate]) => this.updateUnitFeature())
    );

    this.registerPointerDragListener();
  }

  /**
   * Display the current location
   */
  private registerPointerDragListener(): void {
    this.map.addReadyCallback(() => {
      if (this.pointerDragListener === null) {
        // !!! WARNING !!! Do not remove brackets because returning a false result will stop the event's propagation.
        this.pointerDragListener = this.map.on('pointerdrag', () => {
          this.mapService.moveToPosition = false;
          return true;
        });
      }
    });
  }

  $onComplete(): void {
    this.completionHandler = null;
    if (this.myLocationFeature !== null) {
      if (this.removeButton === null) {
        this.removeButton = this.mapService.addButtonItems(this.localizeButton);
      }

      if (this.mapService.moveToPosition) {
        this.setMapToCurrentLocation();
      }
    }
  }

  /**
   * Set the map center to the current location
   */
  private setMapToCurrentLocation(): void {
    if (this.myLocationFeature !== null) {
      const olCoordinate = (this.myLocationFeature.getGeometry() as any).getCoordinates();
      this.localizePosition.centerView(
        this.map.getView(),
        {
          x: olCoordinate[0],
          y: olCoordinate[1],
          epsg: this.map
            .getView()
            .getProjection()
            .getCode()
        },
        1,
        true
      );
      this.mapService.moveToPosition = true;
    }
  }

  private clean() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }

    if (this.pointerDragListener !== null) {
      this.map.un('pointerdrag', this.pointerDragListener.listener);
      this.pointerDragListener = null;
    }

    this.clearLayer();

    if (this.removeButton !== null) {
      this.removeButton();
      this.removeButton = null;
    }

    this.myLocationFeature = null;
  }

  private clearLayer() {
    if (this.myLocationLayer !== null) {
      this.myLocationLayer.clearObjects();
      this.myLocationLayer.clear();
      this.myLocationLayer = null;
    }
  }
}
