import { NgModule } from '@angular/core';
import { CallcardModelService } from './callcard-model/callcard-model.service';
import { CCallerBaseModelService } from './ccallerbase-model/ccallerbase-model.service';
import { CEventModelService } from './cevent-model/cevent-model.service';
import { CLocationBaseModelService } from './clocationbase-model/clocationbase-model.service';
import { UnitModelService } from './unit-model/unit-model.service';

@NgModule({
  imports: [],
  exports: [],
  providers: [CallcardModelService, CCallerBaseModelService, CEventModelService, CLocationBaseModelService, UnitModelService]
})
export class SagaModelsServiceModule {}
