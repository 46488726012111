import { AdditionalViewToolsService, IPanelChanged, PanelEvent, PanelToolGroupNames } from '@addins/core/panel';
import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ProfileService } from '@services/profile/profile.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { AddinExecute, AddinService, Execute } from '@techwan/ionic-core';
import { MapToolsService } from '@techwan/map-tools';
import { filter, first } from 'rxjs/operators';

/**
 * @description
 * Add a menu entry for open the map
 */
@AddinExecute({
  name: 'Menu',
  description: 'Add a menu entry for opening the map'
})
@Injectable()
export class MapAccessMenuService extends Execute {
  private menuEntry: MenuEntry;

  constructor(
    private sideMenu: SideMenuService,
    private addins: AddinService,
    private viewConfiguration: ViewConfigurationService,
    private profile: ProfileService,
    private additionalViewTools: AdditionalViewToolsService,
    private mapTools: MapToolsService
  ) {
    super();
  }

  init() {}

  execute() {
    this.profile.ready
      .pipe(
        filter(isReady => isReady),
        first()
      )
      .subscribe(() => this.onProfileReady());
  }

  private onProfileReady() {
    this.createMenu();

    this.additionalViewTools.$change
      .pipe(filter(panelChanged => panelChanged.tool && panelChanged.tool.groupName === PanelToolGroupNames.MapToolGroup))
      .subscribe(panelChange => {
        this.onMapToolChanged(panelChange);
      });
  }

  private createMenu(): void {
    this.menuEntry = new MenuEntry('map', 'map', this.addins.resolveAddinView(this.viewConfiguration.getMapName()).element, 600);
    this.sideMenu.addMenuEntry(this.menuEntry);
  }

  private onMapToolChanged(panelChanged: IPanelChanged) {
    const panelTool = panelChanged.tool;
    const toggleTool = panelTool.button;
    if (panelTool.groupName === PanelToolGroupNames.MapToolGroup) {
      if (panelChanged.name === PanelEvent.toolAdded) {
        this.mapTools.mapTools.push(toggleTool);
      } else if (panelChanged.name === PanelEvent.toolRemoved) {
        const index = this.mapTools.mapTools.indexOf(toggleTool);
        if (index > -1) {
          this.mapTools.mapTools.splice(index, 1);
        }
      }
    }
  }
}
