export class HistoryTextFormatter {
  static format(obj: any, groups: any): string {
    const texts = [];
    Object.keys(groups).forEach(groupName => {
      texts.push(
        groups[groupName]
          .map(key => obj[key])
          .filter(s => s)
          .join('\n')
      );
    });
    return texts.join('\n');
  }
}
