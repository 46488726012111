import { MapName } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MapInstanceEvent, MapInstanceEventType, MapInstancesService } from '@services/map';
import { SagaLayerManager } from '@techwan/ionic-core/map';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-layers-modal',
  templateUrl: './layers-modal.component.html',
  styleUrls: ['./layers-modal.component.scss']
})
export class LayersModalComponent implements OnInit {
  private _layers: SagaLayerManager;
  get layers(): SagaLayerManager {
    return this._layers;
  }

  constructor(private maps: MapInstancesService, private modalController: ModalController) {}

  ngOnInit() {
    const map = this.maps.get(MapName.main);
    if (map !== null) {
      this._layers = map.getLayerManager() as SagaLayerManager;
    } else {
      this.maps.$change
        .pipe(
          filter(mapEvent => mapEvent.name === MapName.main && mapEvent.type === MapInstanceEventType.added),
          first()
        )
        .subscribe(mapEvent => this.onMapAdded(mapEvent));
    }
  }

  private onMapAdded(mapEvent: MapInstanceEvent) {
    this._layers = mapEvent.target.getLayerManager() as SagaLayerManager;
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
