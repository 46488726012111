import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Guid } from '@models/guid';
import { DataType } from '@models/imported/SagaBase/Interfaces';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { MediaType } from '../../schema/media/media-type';
import { CameraProviderService } from '../camera-provider/camera-provider.service';
import { FileChooserService } from '../file-chooser/file-chooser.service';
import { ThumbnailService } from '../thumbnail/thumbnail.service';
import { UploadService } from '../upload/upload.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  constructor(
    private platform: Platform,
    private cameraProvider: CameraProviderService,
    private fileChooser: FileChooserService,
    private thumbnailService: ThumbnailService,
    private uploadService: UploadService
  ) {}

  createAttachment(parentId: Guid, type: MediaType): Promise<boolean> {
    return this.selectFile(type, parentId)
      .then(customFile => this.setAttachmentDataType(customFile, type))
      .then(file => this.uploadService.upload(file))
      .catch(console.log.bind(console));
  }

  createMediaMessage(parentId: Guid, type: MediaType): Promise<boolean> {
    return this.selectFile(type, parentId)
      .then(file => this.uploadService.upload(file, true))
      .catch(console.log.bind(console));
  }

  private selectFile(type: MediaType, parentId): Promise<ICustomFile> {
    return (type === MediaType.File && !this.platform.is('cordova') ? this.fileChooser.choose(type) : this.cameraProvider.showCamera(type))
      .then(customFile => (customFile ? customFile : Promise.reject('select file canceled')))
      .then(customFile => this.setParent(customFile, parentId))
      .then(customFile => this.setThumbnail(customFile, type));
  }

  private setParent(customFile: ICustomFile, parentId: Guid): ICustomFile {
    customFile.parent = parentId;
    return customFile;
  }

  private setThumbnail(customFile: ICustomFile, type: MediaType): Promise<ICustomFile> {
    return this.thumbnailService.createThumbnail(customFile, type).then(thumbnail => {
      customFile.thumbnail = thumbnail;
      return customFile;
    });
  }

  private setAttachmentDataType(customFile: ICustomFile, type: MediaType): ICustomFile {
    switch (type) {
      case MediaType.Picture:
      case MediaType.File:
        customFile.dataType = DataType.Picture;
        break;

      case MediaType.Video:
        customFile.dataType = DataType.Video;
        break;
    }

    return customFile;
  }
}
