import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Tool } from '@services/tools';
import { AddStampButtonsProviderService } from '../../services/add-stamp-buttons-provider/add-stamp-buttons-provider.service';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

/**
 * @description
 * The callcard detail popover
 */
@Component({
  templateUrl: 'callcard-detail-popover.component.html',
  styleUrls: ['callcard-detail-popover.component.scss'],
  selector: 'callcard-detail-popover'
})
export class CallCardDetailPopoverComponent implements OnInit {
  callcard: CallCard;

  get tools(): Tool[] {
    return this.addStampButtonsProviderService.buttons;
  }

  constructor(
    private viewCtrl: PopoverController,
    private navParams: NavParams,
    private addStampButtonsProviderService: AddStampButtonsProviderService
  ) {}

  ngOnInit() {
    this.callcard = this.navParams.get('card');
  }

  public execute(tool: Tool): void {
    this.closePopover();
    tool.execute([this.callcard]);
  }

  private closePopover() {
    this.viewCtrl.dismiss();
  }
}
