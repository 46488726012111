import { Injectable } from '@angular/core';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AccessMenuTool } from '../../../schema/menu/access-menu-tool';
import { MediaMessagesAvailableService } from '../../media-messages/media-messages-available/media-messages-available.service';
import { MediaMessagesNewService } from '../../media-messages/media-messages-new/media-messages-new.service';
import { MessageEvent, MessageEventType } from '../../media-messages/media-messages/media-messages.service';
import { MenuAccessItemService } from '../menu-access-item/menu-access-item.service';

@Injectable()
export class MessageMenuAccessItemService extends MenuAccessItemService {
  private messageSub: Subscription = null;

  private mediaMessages: MediaMessage[] = null;

  constructor(
    private myUnitService: MyUnitService,
    private cacheService: CacheService,
    private mediaMessagesAvailableService: MediaMessagesAvailableService,
    private mediaMessagesNewService: MediaMessagesNewService
  ) {
    super();
  }

  setup(menuTool: AccessMenuTool) {
    this._entry = menuTool || null;

    this._sub = this.myUnitService.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity));
  }

  private onUnitChanged(unitActivity: UnitActivity) {
    if (unitActivity) {
      this.cacheService.state
        .pipe(
          filter(state => state === CacheState.ready),
          first()
        )
        .subscribe(state => this.onCacheReady());
    } else {
      this.mediaMessagesAvailableService.cleanup();
      this.mediaMessagesNewService.cleanup();
    }
  }

  private onCacheReady() {
    this.mediaMessagesAvailableService.setup();
    this.mediaMessagesNewService.setup();
    this.setupMessagesMenuButton();
  }

  private setupMessagesMenuButton() {
    this._entry.enable();
    this._entry.onChanged.subscribe(() => this._entry.clearAttention());

    this.mediaMessages = this.mediaMessagesAvailableService.getMessages();
    this.updateCounter();

    if (this.messageSub === null) {
      this.messageSub = this.mediaMessagesAvailableService.$event.subscribe(event => this.onMessageEvent(event));
    }
  }

  private updateCounter() {
    this._entry.setCounter(this.mediaMessages.length);
  }

  private onMessageEvent(event: MessageEvent) {
    if (event.type === MessageEventType.added) {
      this._entry.requireAttention();
    }
    this.updateCounter();
  }
}
