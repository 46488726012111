import { MyCardService } from '@addins/core/core';
import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { UnitActivity } from '@techwan/ionic-core/lib/schema/imported/SagaSchema/UnitActivity';
import { CommunicationEquipmentAbstract } from '@techwan/ionic-core/lib/schema/imported/abstract/SagaSchema/CommunicationEquipment_abstract';
import { Observable } from 'rxjs';
import { MediaMessageProxyService } from '../../services/media-message-proxy/media-message-proxy.service';

@Component({
  templateUrl: 'media-message-editor.component.html',
  styleUrls: ['media-message-editor.component.scss']
})
export class MediaMessageEditorComponent implements AfterViewChecked {
  @ViewChild('textAreaMessage') textArea: IonTextarea;

  private CREATE_ALARM_TIMEOUT: number = 5000;
  MAX_TEXT_LENGTH: number = 1024;

  text: string = '';

  constructor(
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private myEquipmentService: MyEquipmentService,
    private myCardService: MyCardService,
    private mediaMessageProxy: MediaMessageProxyService
  ) {}

  ngAfterViewChecked() {
    this.textArea.setFocus();
  }

  createMessage(text: string) {
    const responseTimer = setTimeout(() => {
      this.requestErrorHandling();
    }, this.CREATE_ALARM_TIMEOUT);

    this.save(text).subscribe(
      response => {
        clearTimeout(responseTimer);
        this.close();
      },
      error => {
        clearTimeout(responseTimer);
        this.requestErrorHandling();
      }
    );
  }

  private save(text: string): Observable<boolean> {
    const callcard = this.myCardService.myCard;
    let myUnit: UnitActivity;
    let myDevice: CommunicationEquipmentAbstract;
    this.myEquipmentService.myUnit.subscribe(unit => (myUnit = unit)).unsubscribe();
    this.myEquipmentService.myDevice.subscribe(device => (myDevice = device)).unsubscribe();

    const data = {
      ParentMessageUid: myUnit.ObjGuid,
      CallCardId: callcard.ObjGuid,
      Text: text,
      Caption: myDevice.Name,
      Owner: callcard.Owner
    };

    return this.mediaMessageProxy.save(data);
  }

  private requestErrorHandling() {
    this.toastService.show('Mobile.ErrorDuringSending', ToastColor.Error);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
