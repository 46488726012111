import { IAppSetting } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { AppSettingProviderService, SagaSettingsService } from '@services/settings';
import { filter, first } from 'rxjs/operators';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';
import { ProviderName } from '../../schema/provider-name';
import { GoogleMapsProviderService } from '../google-maps-provider/google-maps-provider.service';
import { SygicProviderService } from '../sygic-provider/sygic-provider.service';
import { TomTomProviderService } from '../tom-tom-provider/tom-tom-provider.service';
import { WazeProviderService } from '../waze-provider/waze-provider.service';

@Injectable()
export class ExternalItineraryProviderService {
  private itineraryProviders: string[];

  currentItineraryProvider: IAppSetting<string> = null;

  constructor(
    private sygicProvider: SygicProviderService,
    private tomTomProvider: TomTomProviderService,
    private wazeProvider: WazeProviderService,
    private googleMapsProvider: GoogleMapsProviderService,
    private settings: SagaSettingsService,
    private appSettingsProvider: AppSettingProviderService
  ) {}

  setup() {
    this.settings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.onSettingsReady();
      });
  }

  private onSettingsReady() {
    let defaultExtItinerary: string = this.settings.getValue('SagaMobileWebClient.DefaultExtItinerary');
    this.appSettingsProvider
      .getSetting<string>('DefaultExtItineraryProvider', defaultExtItinerary, true)
      .subscribe(appSetting => (this.currentItineraryProvider = appSetting));
  }

  getProviders(): string[] {
    if (!this.itineraryProviders) {
      this.itineraryProviders = this.getItineraryProviders();
    }
    return this.itineraryProviders;
  }

  private getItineraryProviders(): string[] {
    return (this.settings.getValue('SagaMobileWebClient.ExternalItineraryProvider') || ProviderName[1]) //sygic
      .split(',')
      .filter(providerName => ProviderName[providerName] !== undefined);
  }

  get(providerName: ProviderName): INavigationProvider {
    switch (providerName) {
      case ProviderName.sygic:
        return this.sygicProvider;

      case ProviderName.tomtom:
        return this.tomTomProvider;

      case ProviderName.waze:
        return this.wazeProvider;

      case ProviderName.googlemaps:
        return this.googleMapsProvider;
    }
  }
}
