import { ResourceProxyService } from '@addins/unit-custom-creation/src/services/resource-proxy/resource-proxy.service';
import { Component, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { FilteredListBase } from '@schema/filtered-list-base';
import { ModalService } from '@services/modal/modal.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-search-vehicle-modal',
  templateUrl: './search-vehicle-modal.component.html',
  styleUrls: ['./search-vehicle-modal.component.scss']
})
export class SearchVehicleModalComponent extends FilteredListBase<Vehicle> {
  @ViewChild('searchbar') searchbar: IonSearchbar;

  private vehicles: Vehicle[] = [];

  constructor(private modalController: ModalController, private modalService: ModalService, private resourceProxy: ResourceProxyService) {
    super();
    this.searchedCharactersThreshold = 0;
    this.searchDelay = 0;
  }

  ionViewDidEnter() {
    this.modalService.presentLoading().then(() =>
      this.resourceProxy
        .getAvailableResources(0, '')
        .toPromise()
        .then((vehicles: Vehicle[]) => (this.vehicles = vehicles))
        .then(() => this.parseQueryResult(this.vehicles, true))
        .finally(() => this.modalService.dismissLoading().then(() => this.searchbar.setFocus()))
    );
  }

  onDismiss(result: Vehicle = null) {
    this.clearPendingSearch();
    this.modalController.dismiss(result);
  }

  onSearch(query: string) {
    super.search(query);
  }

  onSelect(vehicle: Vehicle) {
    this.onDismiss(vehicle);
  }

  protected getItems(query: string, offset: number = 0): Observable<Vehicle[]> {
    return of(this.vehicles.filter(vehicle => vehicle.VehicleCode.includes(query)));
  }
}
