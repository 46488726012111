import { Injectable } from '@angular/core';
import { AppSettingsService } from '@services/settings';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageService {
  constructor(private readonly _settings: AppSettingsService) {}

  getLanguage(): Observable<string> {
    return this._settings.language();
  }
}
