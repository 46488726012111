import { FileDownloadState } from './file-download-state';
import { IMediaFile } from './interfaces/media-file';

export class MediaFile implements IMediaFile {
  private _localPath: string = null;

  private _state: FileDownloadState = FileDownloadState.notStarted;

  private _blob: Blob;
  private _size: number = 0;
  private _progress: number = 0;

  private _uri: string = null;
  public get uri(): string {
    return this._uri;
  }

  public constructor(private readonly _url: string, private readonly _localName: string) {}

  public getLocalName(): string {
    return this._localName;
  }

  public getUrl(): string {
    return this._url;
  }

  public getSize(): number {
    return this._size;
  }

  public setSize(size: number) {
    this._size = size;
  }

  public getState(): FileDownloadState {
    return this._state;
  }

  public setState(newState: FileDownloadState) {
    this._state = newState;
  }

  public getLocalPath(): string {
    return this._localPath;
  }

  public setLocalPath(path: string) {
    this._localPath = path;
  }

  public getBlob(): Blob {
    return this._blob;
  }

  public setBlob(blob: Blob) {
    this._blob = blob;
  }

  public getProgress(): number {
    return this._progress;
  }

  public setProgress(progress: number) {
    this._progress = progress;
  }

  public isDownloaded(): boolean {
    return this._state === FileDownloadState.downloaded;
  }

  public isLoading(): boolean {
    return this._state === FileDownloadState.downloading;
  }
}
