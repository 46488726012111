import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class SoundService {
  private _audioAlert: HTMLAudioElement = null;
  private get audioAlert(): HTMLAudioElement {
    if (this._audioAlert === null) {
      this._audioAlert = document.createElement('AUDIO') as HTMLAudioElement;
      this._audioAlert.src = 'assets/sounds/alert.ogg';
    }
    return this._audioAlert;
  }

  constructor(private readonly _platform: Platform) {}

  playNotification() {
    if (this._platform.is('cordova')) {
      (navigator as any).notification.beep(1);
    } else {
      this.audioAlert.play().catch(reason => console.log(reason));
    }
  }
}
