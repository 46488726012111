// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class DeferredAssignment_abstract extends SagaObject {
  static $t: string = 'SagaSchema.DeferredAssignment';
  $t: string = 'SagaSchema.DeferredAssignment';

  public static GetMembers(): string[] {
    return ['CallcardUid', 'UnitUid', 'ResourceUid', 'ResourceType', 'Status', 'EnforceDueDate'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return DeferredAssignment_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallcardUid: Guid = GuidTools.Empty;
  public UnitUid: Guid = GuidTools.Empty;
  public ResourceUid: Guid = GuidTools.Empty;
  public ResourceType: SagaBase.Defines.ResType;
  public Status: SagaBase.DeferredAssignmentState = SagaBase.DeferredAssignmentState.created;
  public EnforceDueDate: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
