// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CallCardDispatch_abstract } from '@models/imported/abstract/SagaSchema/CallCardDispatch_abstract';
import * as moment from 'moment';

export class CallCardDispatch extends CallCardDispatch_abstract {
  rShort(): string {
    return 'FE: ' + this.Identifier;
  }

  rLong(): string[] {
    return [moment(this.CreatedUTC).format('LLL'), this.status.text];
  }

  get identifier(): string {
    return this.rShort();
  }
}
