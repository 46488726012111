import { Injectable, Type } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';

@Injectable()
export class HomeAccessMenuService {
  private readonly _entry: MenuEntry;

  constructor(private readonly menuService: SideMenuService) {
    this._entry = new MenuEntry('Home', 'home', null, 101);
  }

  public setup(view: Type<any>): void {
    if (this._entry.type !== view) {
      this._entry.type = view;
      this.menuService.addMenuEntry(this._entry);
    }
  }
}
