import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { AddinService } from '@techwan/ionic-core';
import { Logger } from '../logger';

export const DEFAULT_NAV = 'main';

@Injectable()
export class NavService {
  private readonly navs: Map<string, IonNav> = new Map<string, IonNav>();

  constructor(private addinService: AddinService) {}

  register(nav: IonNav, name: string = DEFAULT_NAV): void {
    Logger.debug(`Register new navigation panel names '${name}'`, 'NavService');
    this.navs.set(name, nav);
  }

  isPrimary(nav: IonNav) {
    return this.getName(nav) === DEFAULT_NAV;
  }

  private getName(nav: IonNav) {
    const position = Array.from(this.navs.values()).indexOf(nav);
    if (position !== -1) {
      return Array.from(this.navs.keys())[position];
    }

    return null;
  }

  get(name: string = DEFAULT_NAV): IonNav {
    return this.navs.get(name);
  }

  async getViewOpenInNav(view: any, navName: string = DEFAULT_NAV) {
    const nav = this.get(navName);
    let type = view;
    if (typeof view === 'string') {
      const resolution = this.addinService.resolveAddinView(view);
      type = resolution && resolution.element;
    }
    if (type && nav) {
      const currentViewInstance = await nav.getActive().then(v => (v.delegate as any).elRefMap.get(v.element).instance);
      if (currentViewInstance instanceof type || currentViewInstance === type) {
        return currentViewInstance;
      }
    }

    return null;
  }

  /**
   * Retrieve the view container which contains the view instance provided in arguments
   */
  getViewOpen<T = any>(view: any): Promise<T> {
    return new Promise(async resolve => {
      let instance: any = null;
      const navsName = Array.from(this.navs.keys());
      for (let i = 0; !instance && i < navsName.length; i++) {
        instance = await this.getViewOpenInNav(view, navsName[i]);
      }
      resolve(instance);
    });
  }
}
