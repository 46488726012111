// Automatically generated from TW.Saga.Shared.MetadataLib, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import * as SagaBase from '../../SagaBase';
import { PersistentObject } from '../../SagaBase/PersistentObject';

export abstract class StatusItems_abstract extends PersistentObject {
  static $t: string = 'SagaStateLib.StatusItems';
  $t: string = 'SagaStateLib.StatusItems';

  public static GetMembers(): string[] {
    return [
      'RequestParameter',
      'ReassignmentPriorityFilter',
      'IsRequest',
      'AcceptRequest',
      'NegateRequest',
      'Action',
      'Actions',
      'NAction',
      'ARGBBackColor',
      'BColor',
      'Children',
      'ForceAvailability',
      'ARGBForeColor',
      'FColor',
      'Name',
      'Value',
      'ImageKey',
      'OBackColor',
      'OverrideBackColor',
      'TimeOut',
      'TimeOutMessage',
      'ParentValue'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return StatusItems_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public RequestParameter: string;
  public ReassignmentPriorityFilter: number = 0;
  public IsRequest: boolean = false;
  public AcceptRequest: number = 0;
  public NegateRequest: number = 0;
  public Action: SagaBase.StatusItemAction = SagaBase.StatusItemAction.none;
  /**
   * @deprecated Please consider using Actions instead
   */
  public NAction: number = 0;
  private _Actions: string = '';
  set Actions(value: string) {
    this._Actions = value;
  }

  get Actions(): string {
    return this._Actions;
  }
  public ARGBBackColor: number = 0;
  public BColor: string;
  public Children: number = 0;
  public ForceAvailability: number = -1;
  public ARGBForeColor: number = 0;
  public FColor: string;
  public Name: string;
  public Value: number = 0;
  public ImageKey: string;
  public OBackColor: string;
  public OverrideBackColor: boolean = false;
  public TimeOut: number = 0;
  public TimeOutMessage: string;
  public ParentValue: number = -1;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
