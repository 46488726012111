import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlide, IonSlides, ModalController, NavParams } from '@ionic/angular';

export interface ViewerImage {
  url: string;
  caption: string;
}

@Component({
  templateUrl: 'image-viewer.component.html',
  styleUrls: ['image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @Input() images: ViewerImage[] = [];

  slideOpts = {
    centeredSlides: 'true'
  };

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    console.log(this.slides);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
