import { Injectable } from '@angular/core';
import { ISiteSelector, SiteSelect } from '@techwan/ionic-core';
import { from, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { SiteSelectorComponent } from '../../components/site-selector/site-selector.component';

@Injectable({
  providedIn: 'root'
})
export class SiteSelectorService implements ISiteSelector {
  constructor(private modalCtrl: ModalController) {}

  select(): Observable<SiteSelect> {
    // TODO: @deprecated please use ModalFactoryService instead
    return from(
      this.modalCtrl
        .create({
          component: SiteSelectorComponent
        })
        .then(modal => this.present(modal))
    );
  }

  private present(modal): Promise<SiteSelect> {
    return modal.present().then(() => modal.onDidDismiss().then(result => this.handleResult(result)));
  }

  private handleResult(result: any): Promise<SiteSelect> {
    return Promise.resolve(result ? result.data : -1);
  }
}
