<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="close()"><ion-icon slot="icon-only" name="close"></ion-icon></ion-button>
    </ion-buttons>
    <ion-title>
      <span translate>Mobile.Itinerary.Steps</span>
    </ion-title>
    <ion-buttons slot="end">
    </ion-buttons>
    <ion-buttons slot="end"><div status-bar></div></ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="padding">
  <ion-list>
    <ion-item *ngFor="let step of steps; let i = index" (click)="stepClicked(step)">
      <div slot="start">{{i + 1}}</div>
      <ion-label class="ion-text-wrap">
        <ion-text color="primary">
          <h3>{{step.text}}</h3>
        </ion-text>
        <div class="info">
          <div>{{step.length | distance}}</div>
          <div flex></div>
          <div>{{step.time | duration:'s':0}}</div>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
