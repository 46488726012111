import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

/**
 * @name NotesEditComponent
 * @description
 * The notes editor component
 */
@Component({
  templateUrl: 'note-editor.component.html',
  styleUrls: ['./note-editor.component.scss']
})
export class NoteEditorComponent implements OnInit {
  notes: string;
  maxlength: number;

  constructor(private viewCtrl: ModalController, private params: NavParams) {
    this.notes = params.get('text');
    this.maxlength = params.get('maxlength') || 200;
  }

  ngOnInit() {}

  close() {
    return this.viewCtrl.dismiss();
  }

  save() {
    return this.viewCtrl.dismiss(this.notes);
  }
}
