import { CallcardGuidSelectTool } from '@addins/core/singlecallcard';
import { Guid } from '@models/guid';
import { Observable, Subject } from 'rxjs';
import { DestinationProviderService } from '../services/destination-provider/destination-provider.service';
import { Destination } from './destination';

export class SelectNavigationTool extends CallcardGuidSelectTool {
  private _refresh = new Subject<SelectNavigationTool>();
  get onRefresh(): Observable<SelectNavigationTool> {
    return this._refresh.asObservable();
  }

  get values(): Destination[] {
    return this._destinations.services;
  }

  get selection(): Destination {
    return this._destinations.value;
  }

  get current(): string {
    return (this.selection && this.selection.ResAddressUid) || '';
  }

  constructor(private readonly _destinations: DestinationProviderService) {
    super('DestinationSelect', 'flag');
  }

  change(value: Guid): void {
    if (value) {
      this._onClick.next({
        target: null,
        selection: value
      });
    }
  }

  refresh(): void {
    this._refresh.next(this);
  }
}
