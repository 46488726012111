import { CallCard } from '@models/imported/SagaSchema/CallCard';

export class CallCardCVP extends CallCard {
  private _shortText: string;
  public set shortText(value: string) {
    this._shortText = value;
  }

  private _longText: string[];
  public set longText(value: string[]) {
    this._longText = value;
  }

  public rShort(): string {
    return this._shortText;
  }

  public rLong(): string[] {
    return this._longText;
  }
}
