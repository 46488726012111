// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:21
import { SagaObject_abstract } from './../abstract/SagaBase/SagaObject_abstract';

export class SagaObject extends SagaObject_abstract {
  compare(second: SagaObject): number {
    if (this.CreatedUTC > second.CreatedUTC) return -1;
    if (this.CreatedUTC < second.CreatedUTC) return 1;
    return 0;
  }

  rShort(): string {
    return this.ObjGuid.toUpperCase();
  }

  toString(): string {
    return this.rShort();
  }
}
