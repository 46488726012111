import { Injectable } from '@angular/core';
import { MenuEntry } from '@services/menu/entry.schema';
import { IMenuEvent } from '@services/menu/menu';
import { SideMenuService } from '@services/menu/side-menu.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { PermanentEntry } from '../../schema/permanent-entry';

@Injectable()
export class PermanentMenuService {
  private _entries: PermanentEntry[] = [];

  private _sub: Subscription = null;

  private _clean = new Subject<void>();
  get $clean(): Observable<void> {
    return this._clean.asObservable();
  }

  constructor(private menu: SideMenuService) {}

  cleanUp() {
    this._entries.length = 0;

    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }

    this._clean.next();
  }

  getEntries(): PermanentEntry[] {
    if (this._entries.length === 0) {
      this.setup();
    }
    return this._entries;
  }

  private setup(): void {
    const menu = this.menu.get();
    menu.menus.forEach(menuEntry => this.addEntry(menuEntry));
    this._sub = menu.onEvent.subscribe(event => this.$onEvent(event));
  }

  private addEntry(menuEntry: MenuEntry) {
    this._entries.push(menuEntry instanceof PermanentEntry ? menuEntry : new PermanentEntry(menuEntry));
  }

  private $onEvent(event: IMenuEvent): void {
    let missing: PermanentEntry[];
    if (event.type === 'add') {
      missing = event.items.filter(item => !this._entries.find(entry => entry.text === item.text)) as PermanentEntry[];
      missing.forEach(permanentEntry => this.addEntry(permanentEntry));
    }
  }
}
