import { Injectable } from '@angular/core';
import { Camera, ImageOptions, Photo } from '@capacitor/camera';
import { MediaFile, VideoCapturePlus, VideoCapturePlusOptions } from '@ionic-native/video-capture-plus/ngx';
import { Platform } from '@ionic/angular';
import { MediaType } from '@models/imported/SagaBase';
import { ImagesSettingsService } from '@services/settings';
import { AttachmentOption } from '../../schema/attachment-option';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';

@Injectable()
export class CameraBridgeService {
  constructor(private _platform: Platform, private _videoCapture: VideoCapturePlus, private _imagesSettings: ImagesSettingsService) {}

  getPicture(attachmentOption: AttachmentOption): Promise<ICustomFile> {
    return Camera.getPhoto(attachmentOption.captureOptions as ImageOptions).then(cameraPhoto =>
      this.createCustomFileFromPhoto(attachmentOption, cameraPhoto)
    );
  }

  captureVideo(attachmentOption: AttachmentOption): Promise<ICustomFile> {
    return this._videoCapture
      .captureVideo(attachmentOption.captureOptions as VideoCapturePlusOptions)
      .then(mediaFiles => this.createCustomFileFromVideo(attachmentOption, mediaFiles[0]));
  }

  private createCustomFileFromPhoto(attachmentOption: AttachmentOption, cameraPhoto: Photo): ICustomFile {
    const customFile: ICustomFile = {
      uri: cameraPhoto.path,
      url: cameraPhoto.webPath,
      file: null,
      parent: null,
      dataType: MediaType.Image,
      mimeType: attachmentOption.mimeType
    };

    return customFile;
  }

  private createCustomFileFromVideo(attachmentOption: AttachmentOption, mediaFile: MediaFile): ICustomFile {
    let filePath = mediaFile.fullPath;
    if (this._platform.is('ios')) {
      filePath = `file://${filePath}`;
    }
    const videoAttachmentsIcon: string = this._imagesSettings.get('VideoAttachments');
    const preview: string = videoAttachmentsIcon ? videoAttachmentsIcon.substring(videoAttachmentsIcon.indexOf(',') + 1) : '';

    const customFile: ICustomFile = {
      uri: filePath,
      url: null,
      file: null,
      parent: null,
      dataType: MediaType.Video,
      mimeType: attachmentOption.mimeType,
      size: mediaFile.size,
      data: preview
    };

    return customFile;
  }
}
