// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.10.2019 07:02:09
/* tslint:disable */
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CMediaMessage_abstract } from '@models/imported/abstract/TW/Saga/Core/Schema/Callcard/CMediaMessage_abstract';
import { CacheChangedInterface } from '@services/cache/cache.service';

export class CMediaMessage extends CMediaMessage_abstract {
  static globalIcon: string = 'phone-portrait';
  callcard: CallCard;
  static icon = CMediaMessage.globalIcon;

  get icon() {
    return CMediaMessage.icon;
  }

  changed(change: CacheChangedInterface) {
    super.changed(change);

    this.callcard = change.cache.getObjectByUid(this.CallCardId);
  }
}
