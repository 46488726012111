import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class StatusTimeService {
  private _timesObject: any = null;

  private _myUnit: UnitActivity = null;
  get myUnit(): UnitActivity {
    return this._myUnit;
  }
  private _myCallcardId: string = null;

  constructor(private device: MyEquipmentService, private cache: CacheService) {
    zip(
      this.device.myUnit.pipe(filter(unitActivity => unitActivity !== null)),
      this.cache.state.pipe(filter(cacheState => cacheState === CacheState.ready))
    )
      .pipe(first())
      .subscribe(unit => this.init(unit));
  }

  private init([unit, booted]) {
    this._myUnit = this.cache.getObjectByUid((unit as UnitActivity).ObjGuid);
    this.myUnit.$changed.pipe(filter(() => this._myCallcardId !== this._myUnit.CallCardID)).subscribe(() => this.timesInit());
    this.timesInit();
  }

  private timesInit() {
    if (this._myUnit && this._myUnit.CallCardID) {
      this._myCallcardId = this._myUnit.CallCardID;

      this._timesObject = JSON.parse(localStorage.getItem('statusUnitTime'));
      if (!this._timesObject || (!GuidTools.isEmpty(this._myUnit.CallCardID) && this._timesObject.id !== this._myUnit.CallCardID)) {
        this._timesObject = { id: this._myUnit.CallCardID, statuses: {} };
        localStorage.setItem('statusUnitTime', JSON.stringify(this._timesObject));
      }
    }
  }

  setTime(status: ListResourceStatus, date: Date) {
    const time: string = moment(date).format('HH:mm:ss');

    this._timesObject.statuses[status.Value] = time;
    localStorage.setItem('statusUnitTime', JSON.stringify(this._timesObject));
  }

  getTime(status: ListResourceStatus): string {
    return this._timesObject ? this._timesObject.statuses[status.Value] : null;
  }

  reset() {
    localStorage.removeItem('statusUnitTime');
    this.timesInit();
  }
}
