<ion-button
  largeFont
  *ngFor="let button of callcardToolButtons"
  [class.ion-hide]="!button.show"
  [color]="button.color"
  [size]="button.size"
  [disabled]="button.isDisabled"
  [style.font-size]="buttonFontSize"
  expand="block"
  fill="solid"
  class="tools"
  (click)="button.click()"
>
  <ion-icon [icon]="button.icon" slot="start" size="large"></ion-icon>
  <span>{{ button.dontTranslate ? button.text : (button.text | translate) }}</span>
</ion-button>
