import { Component, Input } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Security } from '@services/security/security.service';
import { NavService } from '@services/navigation/navigation.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { UnitActivityAddinName, UnitActivityViewName } from '@addins/core/core';
import { ReportService } from '@addins/core/followup-stamps-tools';

/**
 * @description
 *
 */
@Component({
  templateUrl: './callcard-detail-home.component.html',
  styleUrls: ['./callcard-detail-home.component.scss'],
  selector: 'callcard-detail-home'
})
export class CallCardDetailHomeComponent {
  /**
   * The callcard to display
   */
  @Input()
  public callcard: CallCard;

  get unitActivity(): UnitActivity {
    return this._security.getData('UnitActivity');
  }

  constructor(
    private readonly _security: Security,
    private readonly _navService: NavService,
    private readonly _reportService: ReportService,
    private readonly _viewSelector: ViewSelectorService
  ) {}

  openMyUnit() {
    this._navService.get().push(this._viewSelector.getView(`${UnitActivityAddinName}/${UnitActivityViewName}`).element);
  }
}
