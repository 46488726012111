// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:31:14
import { CVehicleHistory_abstract } from './../../abstract/SagaSchema/History/CVehicleHistory_abstract';
import { CVehicle } from '@models/imported/SagaSchema/CVehicle';
import { HistoryTextFormatter } from '@utils/formatter/history-text-formatter';

export class CVehicleHistory extends CVehicleHistory_abstract {
  get text() {
    return HistoryTextFormatter.format(this, CVehicle.formsProperties);
  }
}
