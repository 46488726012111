import { LogLevel, LEVELS_NAMED, Log } from '../models/log';
/**
 * @name LogInterface
 * @description
 * The Logger interface to use when we want to register a Call for logging
 */
export interface LogInterface {
  log(log: Log);
}

/**
 * @name Logger
 * @description
 * Service for logging inside the application
 */
// @dynamic
export class Logger {
  /**
   * The log level of the logger (all log less than this level will be ignored)
   * @type {number}
   * @static
   */
  static _logLevel: LogLevel = 4;

  static get logLevel(): LogLevel {
    return Logger._logLevel;
  }

  static set logLevel(value: LogLevel) {
    if (value < 0) value = 0;
    if (value >= LEVELS_NAMED.length) value = LEVELS_NAMED.length - 1;
    Logger._logLevel = value;
  }

  get logLevel() {
    return Logger.logLevel;
  }

  set logLevel(value: LogLevel) {
    Logger.logLevel = value;
  }

  /**
   * Log filters (log will be passed to all functions registered)
   * @type {Function[]}
   * @static
   */
  static filters: Array<(Log) => boolean> = [];

  /**
   * The storage key of the logger
   * @type {string}
   * @static
   */
  static STORAGE_KEY = 'saga.logger';

  /**
   * Array of register interfaces to log
   * @type {LogInterface[]}
   * @static
   */
  static interfaces: Array<LogInterface> = [
    {
      log: log => {
        if (console) {
          console[log.levelName](log.format());
        }
      }
    }
  ];

  /**
   * Register an interface to log
   * @static
   * @param {LogInterface} logInterface
   * @returns {Function} Function to call to unregister the interface
   */
  static addInterface(logInterface: LogInterface): Function {
    this.interfaces.push(logInterface);
    return () => {
      let i = Logger.interfaces.indexOf(logInterface);
      if (i != -1) {
        Logger.interfaces.splice(i, 1);
      }
    };
  }

  /**
   * Create a new log
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   * @param {LogLevel?} level The log level (default to LogLevel.LOG)
   */
  static output(text: string, domain: string = 'SagaMobile', level: LogLevel = LogLevel.LOG): Log {
    if (level < 0) {
      level = 0;
    }
    if (level > LEVELS_NAMED.length - 1) {
      level = LEVELS_NAMED.length - 1;
    }

    let log = new Log(domain, text, level);
    if (
      Logger._logLevel >= level &&
      Logger.filters.reduce((isNotFilter: boolean, filterFunction: Function) => {
        return isNotFilter && filterFunction(log);
      }, true)
    ) {
      Logger.interfaces.forEach(logInterface => {
        logInterface.log(log);
      });
    }
    return log;
  }

  /**
   * Add a filter to the logger
   * @static
   * @param {(Log) => boolean} filter A function that is call to prevent logs
   * @returns {() => void} Function to remove the filter
   */
  static addFilter(filter: (Log) => boolean): Function {
    Logger.filters.push(filter);

    return () => {
      let i = Logger.filters.indexOf(filter);
      if (i != -1) {
        Logger.filters.splice(i, 1);
      }
    };
  }

  /**
   * Output a new log with LogLevel.LOG
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   */
  static log(text: string, domain?: string): Log {
    return Logger.output(text, domain, LogLevel.LOG);
  }

  /**
   * Output a new log with LogLevel.DEBUG
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   */
  static debug(text: string, domain?: string): Log {
    return Logger.output(text, domain, LogLevel.DEBUG);
  }

  /**
   * Output a new log with LogLevel.INFO
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   */
  static info(text: string, domain?: string): Log {
    return Logger.output(text, domain, LogLevel.INFO);
  }

  /**
   * Output a new log with LogLevel.WARN
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   */
  static warn(text: string, domain?: string): Log {
    return Logger.output(text, domain, LogLevel.WARN);
  }

  /**
   * Output a new log with LogLevel.ERROR
   * @static
   * @param {string} text The text of the log
   * @param {string?} domain The domain of the log (default the app name)
   */
  static error(text: string, domain?: string): Log {
    return Logger.output(text, domain, LogLevel.ERROR);
  }
}
