// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:23
import { CAttachment_abstract } from './../abstract/SagaSchema/CAttachment_abstract';
// import {CAttachmentFollowupComponent} from "@addins/callcard/components/callCardDetail/callCardDetailFollowup/CAttachmentFollowup/CAttachmentFollowup";
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheChangedInterface } from '@services/cache/cache.service';

export class CAttachment extends CAttachment_abstract {
  private _callcard: CallCard;

  get callcard(): CallCard {
    return this._callcard;
  }

  get followupComponent(): any {
    return null;
    // return CAttachmentFollowupComponent;
  }

  get text() {
    return this.Title;
  }

  get icon() {
    return 'attach';
  }

  public changed(change: CacheChangedInterface) {
    super.changed(change);

    this._callcard = change.cache.getObjectByUid(this.ParentObjId);
  }
}
