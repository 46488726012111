import { GuidTools } from '@models/guid';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Task } from '@models/imported/SagaSchema/Task';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';

export class UnitActivityMDP extends UnitActivity {
  task: Task;
  TaskID: string;

  get callcard(): CallCard {
    return super.callcard;
  }

  set callcard(value: CallCard) {
    if (value && value instanceof Task) {
      this.TaskID = this.CallCardID;
      super.callcard = null;
    } else {
      super.callcard = value;
      this.TaskID = GuidTools.Empty;
    }
  }
}
