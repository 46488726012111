import { CurrentCardService } from '@addins/core/core';
import { Component, InjectionToken, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CallcardService } from '@services/callcard/callcard.service';
import { Events } from '@services/events/events.service';
import { NavService } from '@services/navigation/navigation.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { ADDIN_VIEW_EVENT_KEY } from '@techwan/ionic-core';
import { CardPageService } from '../../services/card-page/card-page.service';

export const CALLCARD_VIEW_TOKEN = new InjectionToken('CALLCARD_VIEW_TOKEN');

/**
 * @description
 * A detail view  of a callcard
 */
@Component({
  template: ''
})
export class CallcardDetailBaseComponent implements OnInit {
  public currentCallCard: CallCard = null;

  public get page() {
    return this.currentCallCard !== null ? this._pages.getPage(this.currentCallCard) : 0;
  }
  public set page(page: number) {
    if (this.currentCallCard !== null) {
      this._pages.setPage(this.currentCallCard, page);
    }
  }

  constructor(
    protected navParams: NavParams,
    protected callcardService: CallcardService,
    private events: Events,
    private navService: NavService,
    private view: ViewConfigurationService,
    private current: CurrentCardService,
    protected _pages: CardPageService
  ) {
    this.currentCallCard = this.navParams.data.card;
    if (this.currentCallCard !== null) {
      this.currentCallCard.hasCacheChanged = false;
    }
  }

  ngOnInit() {}

  load(callcard: CallCard) {
    this.currentCallCard = callcard;
    this.current.select(this.currentCallCard);
    this.callcardService.onCardDisplay.next(callcard);
    this.currentCallCard.hasCacheChanged = false;
  }

  openMap() {
    this.navService.getViewOpen(this.view.getMapName()).then(mapView => {
      // Won't be used since the button is hidden in tablet mode !
      if (mapView) {
        mapView.centerOnCard(this.currentCallCard, 1);
      } else {
        this.events.publish(ADDIN_VIEW_EVENT_KEY, this.view.getMapName(), {
          location: this.currentCallCard.location
        });
      }
    });
  }
}
