import { Injectable } from '@angular/core';
import { PopupProviderService } from '@addins/core/map';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { UnitActivityPopupComponent } from '../../../components/unit-activity-popup/unit-activity-popup.component';

@AddinExecute({
  name: 'UnitPopupService'
})
@Injectable()
export class UnitPopupService extends Execute {
  constructor(private readonly _popup: PopupProviderService) {
    super();
  }

  public execute(): void {
    this._popup.addComponent(UnitActivity, UnitActivityPopupComponent);
  }
}
