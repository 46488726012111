import { Component } from '@angular/core';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { ActivityStatusType } from '@models/imported/SagaBase/Interfaces';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * @name DeviceSelectorComponent
 * @description
 *
 */
@Component({
  templateUrl: 'deviceSelector.html'
})
export class DeviceSelectorComponent {
  devices: CommunicationEquipment[];
  ActivityStatusType: ActivityStatusType;
  constructor(
    private association: AssociationSecurity,
    private view: ModalController,
    private navParams: NavParams,
    private modalService: ModalService,
    private alertCtrl: AlertController,
    private toast: ToastController
  ) {}

  cancel() {
    this.view.dismiss();
  }

  itemSelected(item: CommunicationEquipment) {
    if (item.ActivityStatus === ActivityStatusType.Available) {
      this.view.dismiss(item);
    } else {
      this.alertCtrl
        .create({
          header: item.name,
          subHeader: item.ActivityStatus === ActivityStatusType.Active ? 'AlreadyAssociated' : 'NotAvailable',
          buttons: ['OK']
        })
        .then(a => a.present());
    }
  }

  async ionViewWillEnter() {
    await this.modalService.presentLoading('SagaMobile.UA.LoadDevices');
    const devices: CommunicationEquipment[] = await this.association.getDevices().catch((e: HttpErrorResponse) => {
      console.error(e.error);
      this.toast
        .create({
          message: e.statusText,
          duration: 2000
        })
        .then(t => t.present());
      return Promise.resolve([]);
    });
    const filterDevices: string[] = this.navParams.get('filter').map((d: CommunicationEquipment) => d.ObjGuid);

    this.devices = devices.filter(
      d => filterDevices.indexOf(d.ObjGuid) === -1 && d.ActivityStatus === ActivityStatusType.Available // Can be removed if we want to display devices that are not selectable for information
    );
    this.modalService.dismissLoading();
  }
}
