<ng-container *ngFor="let wrapper of cPersonWrappers">
  <ion-item>
    <ion-icon name="person" slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <div *ngIf="wrapper.wrappedParticular.PersonQualifier">
        <h2>
          <strong translate>Mobile.CPersonQualifier</strong>
        </h2>
        <h2>
          {{ wrapper.wrappedParticular.PersonQualifier }}
        </h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.FirstName">
        <h2>
          <strong translate>Mobile.CPersonFirstName</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.FirstName }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.LastName">
        <h2>
          <strong translate>Mobile.CPersonLastName</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.LastName }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.DateOfBirth">
        <h2>
          <strong translate>Mobile.CPersonDateOfBirth</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.DateOfBirth | sagaDate: 'ShortDateFormat' }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Nationality">
        <h2>
          <strong translate>Mobile.CPersonNationality</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Nationality }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Gender">
        <h2>
          <strong translate>Mobile.CPersonGender</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Gender }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Phone">
        <h2>
          <strong *ngIf="isPCE(wrapper.wrappedParticular)" translate>Mobile.CPersonPhone1</strong>
          <strong *ngIf="!isPCE(wrapper.wrappedParticular)" translate>Mobile.CPersonPhone</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Phone }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Mobile">
        <h2>
          <strong *ngIf="isPCE(wrapper.wrappedParticular)" translate>Mobile.CPersonPhone2</strong>
          <strong *ngIf="!isPCE(wrapper.wrappedParticular)" translate>Mobile.CPersonMobile</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Mobile }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Address">
        <h2>
          <strong translate>Mobile.CPersonAddress</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Address }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.FieldGeneric1">
        <h2>
          <strong translate>Mobile.CPersonFG1</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.FieldGeneric1 }}</h2>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Location">
        <h2>
          <strong translate>Mobile.CPersonLocation</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Location }}</h2>
      </div>
      <div *ngIf="!isPCE(wrapper.wrappedParticular)">
        <div *ngIf="wrapper.wrappedParticular.FieldGeneric2">
          <h2>
            <strong translate>Mobile.CPersonFG2</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.FieldGeneric2 }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.FieldGeneric3">
          <h2>
            <strong translate>Mobile.CPersonFG3</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.FieldGeneric3 }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.FieldGeneric4">
          <h2>
            <strong translate>Mobile.CPersonFG4</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.FieldGeneric4 }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.Age">
          <h2>
            <strong translate>Mobile.CPersonAge</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.Age }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.Height">
          <h2>
            <strong translate>Mobile.CPersonHeight</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.Height }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.Build">
          <h2>
            <strong translate>Mobile.CPersonBuild</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.Build }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.HairColor">
          <h2>
            <strong translate>Mobile.CPersonHairColor</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.HairColor }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.EyeColor">
          <h2>
            <strong translate>Mobile.CPersonEyeColor</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.EyeColor }}</h2>
        </div>
        <div *ngIf="wrapper.wrappedParticular.Language">
          <h2>
            <strong translate>Mobile.CPersonLanguage</strong>
          </h2>
          <h2>{{ wrapper.wrappedParticular.Language }}</h2>
        </div>
      </div>
      <div *ngIf="wrapper.wrappedParticular.Text">
        <h2>
          <strong translate>Mobile.CPersonText</strong>
        </h2>
        <h2>{{ wrapper.wrappedParticular.Text }}</h2>
      </div>
    </ion-label>
  </ion-item>
</ng-container>
