import { SagaObject } from '@models/imported/SagaBase/SagaObject';

export enum CacheAction {
  added,
  modified,
  removed
}

export interface CacheEvent<T = SagaObject> {
  action: CacheAction;
  object: T;
}
