import { CurrentCardService } from '@addins/core/core';
import { ButtonsProviderService } from '@addins/core/singlecallcard';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { MapService, MoreItem, MoreItemsType } from '@services/map';
import { SagaSettingsService } from '@services/settings';
import { StatusChangeRequirementsService } from '@services/status/status-change-requirements/status-change-requirements.service';
import { Tool, ToolsProvider, ToolsProviderService } from '@services/tools';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ItineraryStepsComponent } from '../../../components/itinerary-steps/itinerary-steps.component';
import { InappItineraryTool } from '../../../schema/inapp-itinerary-tool';
import { EngagementRequirementService } from '../../engagement-requirement/engagement-requirement.service';
import { ItineraryProviderService } from '../../itinerary-provider/itinerary-provider.service';
import { TranslateService } from '@ngx-translate/core';

@AddinExecute({
  name: 'Tools',
  description: 'Provide some tools'
})
@Injectable()
export class StartItineraryMenuService extends Execute {
  private itineraryToolWithoutViability: Tool;

  private centerItineraryItem: MoreItem;
  private itineraryStepsItem: MoreItem;
  private itineraryClearItem: MoreItem;
  private itineraryToCard: MoreItem;
  private readonly removeTools: (() => void)[] = [];

  private readonly inappItineraryButton = new InappItineraryTool();

  private _sub: Subscription = null;

  constructor(
    private map: MapService,
    private modalController: ModalController,
    private itineraryProvider: ItineraryProviderService,
    private buttonsProvider: ButtonsProviderService,
    private toolsProvider: ToolsProviderService,
    private currentCard: CurrentCardService,
    private sagaSettings: SagaSettingsService,
    private statusChangeRequirements: StatusChangeRequirementsService,
    private engagementRequirement: EngagementRequirementService,
    private translate: TranslateService
  ) {
    super();
  }

  init(): void {
    this.itineraryToolWithoutViability = Tool.createTool({
      text: this.translate.instant('Mobile.Itinerary.WithoutViability'),
      icon: 'navigate',
      execute: async context => this.navigateTo(context[0], false)
    });
  }

  destroy() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  execute(): void {
    this.itineraryProvider.setup();
    this.addEngagementRequirement();

    this.buttonsProvider.add(this.inappItineraryButton).subscribe(buttonEvent => this.navigateTo(buttonEvent.target.location, false));
    this._sub = this.toolsProvider.register(toolsProvider => this.provideTool(toolsProvider));

    this.createItineraryTools();
  }

  private addEngagementRequirement() {
    this.sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        const autoItineraryOnEngage: boolean = this.sagaSettings.getValue('SagaMobileWebClient.AutoItineraryOnEngage');
        if (autoItineraryOnEngage) {
          this.statusChangeRequirements.addRequirement(this.engagementRequirement);
        }
      });
  }

  private navigateTo(location: CLocation | Coordinate, viability: boolean): void {
    this.itineraryProvider
      .navigateTo(location, viability)
      .then(() => this.updateMoreMenu())
      .catch(() => console.log('Could not calculate itinerary to given location'));
  }

  /**
   * Provide arhive tool to the provider if needed
   * @param {ToolsProvider} provider
   */
  private provideTool(provider: ToolsProvider): void {
    const object = provider.context[0];
    if (object.x && object.y) {
      provider.addTool(this.itineraryToolWithoutViability);
    }
  }

  private createItineraryTools() {
    // TODO: Refactor this code to use specific MoreItem object
    this.centerItineraryItem = {
      title: 'Mobile.Map.CenterItinerary',
      type: MoreItemsType.Line,
      handler: () => {
        const layer = this.map.map.getItineraryLayer();
        const feature = layer.getSource().getFeatures()[0];
        if (feature) {
          this.map.map.fitToFeatures([feature]);
        }
      }
    };

    this.itineraryStepsItem = {
      title: 'Mobile.Map.ItinerarySteps',
      type: MoreItemsType.Line,
      handler: async () => {
        // TODO: @deprecated please use ModalFactoryService instead
        const { data } = await this.modalController
          .create({ component: ItineraryStepsComponent })
          .then(m => m.present().then(() => m.onDidDismiss()));
        if (data) {
          data.fit(this.map.map);
          this.itineraryProvider.getLastItinerary().displayStepOnLayer(data, this.map.map.getItineraryLayer(), this.map.map);
        }
      }
    };

    this.itineraryClearItem = {
      title: 'Mobile.Map.ItineraryClear',
      type: MoreItemsType.Line,
      handler: async () => {
        this.itineraryProvider.removeLastItinerary();
        this.updateMoreMenu();
      }
    };

    this.itineraryToCard = {
      title: 'Mobile.Map.ItineraryToCard',
      type: MoreItemsType.Line,
      handler: async () => {
        this.navigateTo(this.currentCard.current.location, false);
      }
    };
    Object.defineProperty(this.itineraryToCard, 'hidden', {
      get: () => !!this.itineraryProvider.getLastItinerary() || !this.currentCard.current
    });
    this.map.addMoreItems(this.itineraryToCard);
  }

  private updateMoreMenu() {
    if (this.itineraryProvider.getLastItinerary()) {
      if (!this.map.moreItems.includes(this.centerItineraryItem)) {
        this.removeTools.push(this.map.addMoreItems(this.centerItineraryItem));
        this.removeTools.push(this.map.addMoreItems(this.itineraryStepsItem));
        this.removeTools.push(this.map.addMoreItems(this.itineraryClearItem));
      }
    } else {
      while (this.removeTools.length > 0) {
        this.removeTools.pop()();
      }
    }
  }
}
