import { Injectable } from '@angular/core';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { CMediaMessage } from '@models/imported/TW/Saga/Core/Schema/Callcard/CMediaMessage';
import { CacheService } from '@services/cache/cache.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';

export interface MessageEvent {
  type: MessageEventType;
  target: MediaMessage;
}

export enum MessageEventType {
  added,
  removed,
  modified,
  clean
}

@Injectable()
export class MediaMessagesService {
  private readonly _mediaMessages: MediaMessage[] = [];

  private readonly _event = new Subject<MessageEvent>();
  get $event(): Observable<MessageEvent> {
    return this._event.asObservable();
  }

  private _cacheSub: Subscription = null;

  constructor(private _cache: CacheService) {}

  getMessages(filter?: (mediaMessage: MediaMessage) => boolean): MediaMessage[] {
    return filter ? this._mediaMessages.filter(mediaMessage => filter(mediaMessage)) : this._mediaMessages;
  }

  setup() {
    this._cache
      .getListByType(MediaMessage)
      .concat(this._cache.getListByType(CMediaMessage))
      .sort((a, b) => (a.CreatedUTC > b.CreatedUTC ? -1 : a.CreatedUTC === b.CreatedUTC ? 0 : 1))
      .forEach(mediaMessage => this._mediaMessages.push(mediaMessage));

    this._cacheSub = this._cache.listenForChange(MediaMessage).subscribe(event => this.onCacheEvent(event));
  }

  cleanup() {
    this._mediaMessages.length = 0;
    if (this._cacheSub !== null) {
      this._cacheSub.unsubscribe();
      this._cacheSub = null;
    }
  }

  private onCacheEvent(event: CacheEvent) {
    const target = event.object as MediaMessage;
    let type: MessageEventType;

    switch (event.action) {
      case CacheAction.added:
        this._mediaMessages.splice(0, 0, target);
        type = MessageEventType.added;
        break;

      case CacheAction.removed:
        this.removeItem(target);
        type = MessageEventType.removed;
        break;

      case CacheAction.modified:
        type = MessageEventType.modified;
        break;
    }

    this._event.next({ type, target });
  }

  private removeItem(mediaMessage: MediaMessage) {
    const idx = this._mediaMessages.indexOf(mediaMessage);
    if (idx >= 0) {
      this._mediaMessages.splice(idx, 1);
    }
  }
}
