import { Injectable } from '@angular/core';
import { PushService } from '@services/push';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';
import { AuthenticationService } from '@techwan/ionic-core';
import { LoginStorageService } from '@techwan/ionic-core/login';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  constructor(
    private translatedAlert: TranslatedAlertService,
    private auth: AuthenticationService,
    private push: PushService,
    private loginStorage: LoginStorageService
  ) {}

  logout(): void {
    this.translatedAlert
      .show({
        header: 'Mobile.Logout',
        buttons: [
          {
            text: 'Mobile.Cancel',
            role: 'cancel',
            value: false
          },
          {
            text: 'Mobile.Confirm',
            value: true
          }
        ]
      })
      .then(confirmed => {
        if (confirmed) {
          this.doLogout();
        }
      });
  }

  private doLogout() {
    this.push.uninitialize().then(() =>
      this.auth
        .logout()
        .toPromise()
        .then(() => this.loginStorage.removeSagaUsername())
        .then(() => location.reload())
    );
  }
}
