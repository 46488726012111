import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { SagaSettingsService } from '@services/settings';
import { filter, first } from 'rxjs/operators';

type CallcardFilter = (CallCard) => boolean;

@Injectable({
  providedIn: 'root'
})
export class CallcardFilterService {
  private _excludedDispositionCodes: number[] = [];

  private _filters: CallcardFilter[] = [this.isValid];
  get filters(): CallcardFilter[] {
    return this._filters;
  }

  constructor(private sagaSettingsService: SagaSettingsService) {
    this.sagaSettingsService.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.onSettingsReady();
      });
  }

  private onSettingsReady() {
    const cardListFieldsFiltering = this.sagaSettingsService.getValue('SagaMobileWebClient.CardListFieldsFiltering');
    if (cardListFieldsFiltering) {
      const parsedSetting = JSON.parse(cardListFieldsFiltering);

      if (parsedSetting.DispositionCode) {
        this.initExcludedDispositionCodes(parsedSetting.DispositionCode);
        this.addFilter(this.isAllowedDispositionCode.bind(this));
      }

      if (parsedSetting.ExternalReference) {
        this.addFilter(callcard => callcard.ExternalReference);
      }
    }
  }

  private initExcludedDispositionCodes(dispositionCodeSetting: any): void {
    if (dispositionCodeSetting instanceof Array) {
      dispositionCodeSetting.forEach(item => {
        this._excludedDispositionCodes.push(item);
      });
    } else {
      this._excludedDispositionCodes.push(dispositionCodeSetting);
    }
  }

  addFilter(callcardFilter: CallcardFilter) {
    this._filters.push(callcardFilter);
  }

  private isAllowedDispositionCode(callCard: CallCard): boolean {
    return callCard.event !== null && !this._excludedDispositionCodes.includes(callCard.event.DispositionCode);
  }

  private isValid(callcard: CallCard): boolean {
    return callcard.event !== null && callcard.event.EventText !== '' && callcard.location !== null;
  }
}
