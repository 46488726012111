import { Injectable } from '@angular/core';
import { CrewTask } from '@models/imported/SagaSchema/CrewTask';
import { Task } from '@models/imported/SagaSchema/Task';
import { List } from '@models/list';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';

@Injectable({
  providedIn: 'root'
})
export class TasksProviderService {
  private _tasks: List<Task> = new List<Task>();
  get tasks(): List<Task> {
    return this._tasks;
  }

  get myTask(): Task {
    return this.myUnit.mine && (this.myUnit.mine as UnitActivityMDP).task;
  }
  private myTaskId: string = null;

  private unitChangeSub: Subscription = null;
  private cacheSubs: Subscription[] = [];

  constructor(private cacheService: CacheService, private myUnit: MyUnitService) {
    this._tasks.addFilter(task => task.ObjGuid != this.myTaskId);
    this._tasks.addSort((a, b) => a.StartDateUTC.getTime() - b.StartDateUTC.getTime());
  }

  setup() {
    this.myUnit.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity as UnitActivityMDP));
    this.cacheService.state.subscribe(state => this.onCacheStateChanged(state));
  }

  private onUnitChanged(unitActivity: UnitActivityMDP) {
    if (unitActivity) {
      this.unitChangeSub = this.myUnit.$change.subscribe(() => this.onUnitUpdate(this.myUnit.mine as UnitActivityMDP));
      this.onUnitUpdate(this.myUnit.mine as UnitActivityMDP);
    } else {
      if (this.unitChangeSub) {
        this.unitChangeSub.unsubscribe();
        this.unitChangeSub = null;
      }
    }
  }

  private onUnitUpdate(unitActivity: UnitActivityMDP) {
    const myTaskId: string = (unitActivity.task && unitActivity.task.ObjGuid) || null;
    if (myTaskId != this.myTaskId) {
      this.myTaskId = myTaskId;
      this._tasks.refresh();
    }
  }

  private onCacheStateChanged(cacheState: CacheState) {
    if (cacheState == CacheState.ready) {
      this.initTasks();

      this.cacheSubs.push(
        this.cacheService.listenForChange(CrewTask).subscribe((event: CacheEvent<CrewTask>) => this.onCrewTasksChanged(event))
      );

      this.cacheSubs.push(
        this.cacheService
          .listenForChange(Task)
          .pipe(filter((event: CacheEvent<Task>) => this.isMyTaskRemoved(event)))
          .subscribe((event: CacheEvent<Task>) => this.onMyTaskRemoved(event.object))
      );
    } else {
      while (this.cacheSubs.length > 0) {
        this.cacheSubs.pop().unsubscribe();
      }

      this.clearTasks();
    }
  }

  private initTasks() {
    const crewTasks: CrewTask[] = this.cacheService.getListByType(CrewTask);
    const tasks: Task[] = crewTasks
      .map<Task>((crewTask: CrewTask) => this.cacheService.getObjectByUid(crewTask.TaskUId))
      .filter(task => task);

    this._tasks.source = tasks;
  }

  private clearTasks() {
    this._tasks.source.length = 0;
    this._tasks.refresh();
  }

  private onCrewTasksChanged(event: CacheEvent<CrewTask>) {
    const crewTask: CrewTask = event.object;
    const task: Task = this.cacheService.getObjectByUid(crewTask.TaskUId);

    switch (event.action) {
      case CacheAction.added: {
        this._tasks.source.push(task);
        break;
      }

      case CacheAction.removed: {
        this.removeTask(task);
        break;
      }
    }

    this._tasks.refresh();
  }

  private removeTask(task: Task) {
    const index: number = this._tasks.source.indexOf(task);
    if (index >= 0) {
      this._tasks.source.splice(index, 1);
    }
  }

  private isMyTaskRemoved(event: CacheEvent<Task>): boolean {
    return event.action == CacheAction.removed && this._tasks.source.indexOf(event.object) != -1;
  }

  private onMyTaskRemoved(task: Task) {
    this.removeTask(task);
    this._tasks.refresh();
  }
}
