import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EnvironmentService } from '@services/environment/environment.service';
import { MenuEntry } from './entry.schema';
import { Menu } from './menu';

/**
 * @description
 * A service manage menu items
 */
@Injectable()
export class SideMenuService {
  private readonly menus: Map<string, Menu> = new Map();

  private readonly _enabled: boolean = true;

  private _showMenu: boolean = true;
  set showMenu(value: boolean) {
    this._showMenu = value;
  }
  get showMenu(): boolean {
    return this._enabled && this._showMenu;
  }

  /**
   * Define if the menu can be shown or not
   */
  show: boolean;

  constructor(private readonly platform: Platform, private readonly env: EnvironmentService) {
    const hideMenu = this.env.get('hideMenu');
    if (typeof hideMenu === 'boolean') {
      this._enabled = hideMenu;
    } else if (typeof hideMenu === 'string') {
      this._enabled = !this.platform.is(hideMenu as any);
    } else {
      this._enabled = true;
    }
  }

  /**
   * Returns the specified menu from the list of existing menu.
   * If the name provided doesn't match any of the items in the map, its instance will be created and returned.
   * @param {string} name of the menu to read from
   */
  get(name: string = 'main'): Menu {
    let menu = this.menus.get(name);
    if (!menu) {
      menu = new Menu();
      this.menus.set(name, menu);
    }
    return menu;
  }

  /**
   * Add a menu to the system
   * @param {MenuEntry} menuEntry
   * @param {string} name name of the menu in which add the element
   */
  addMenuEntry(menuEntry: MenuEntry, name: string = 'main') {
    const menu: Menu = (this.menus.has(name) && this.menus.get(name)) || this.menus.set(name, new Menu()).get(name);

    menu.addItem(menuEntry);
  }

  /**
   * Remove an entry from the menu
   * @param {MenuEntry} menuEntry
   * @param {string} name of the menu in which removing element
   */
  removeMenuEntry(menuEntry: MenuEntry, name: string = 'main'): void {
    const menu: Menu = (this.menus.has(name) && this.menus.get(name)) || this.menus.set(name, new Menu()).get(name);

    menu.removeItem(menuEntry);
  }
}
