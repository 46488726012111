// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class ExportArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.ExportArgs';
  $t: string = 'SagaBase.Defines.ExportArgs';

  public static GetMembers(): string[] {
    return ['Obj', 'TargetName', 'UserId', 'TacticalDraw', 'MapBackground', 'MinX', 'MaxX', 'MinY', 'MaxY', 'ImgHeight', 'ImgWidth'];
  }

  public GetMembers(): string[] {
    return ExportArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Obj: any;
  public TargetName: string;
  public UserId: number = 0;
  public TacticalDraw: string;
  public MapBackground: boolean = false;
  public MinX: number = 0;
  public MaxX: number = 0;
  public MinY: number = 0;
  public MaxY: number = 0;
  public ImgHeight: number = 768;
  public ImgWidth: number = 1024;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
