import { MenuState, PermanentEntry } from './permanent-entry';

export class PermanentEntryToggle extends PermanentEntry {
  click($event: MouseEvent | TouchEvent): void {
    this.setState(this._state === MenuState.active ? MenuState.inactive : MenuState.active);
    this._onClick.next({
      item: this,
      event: $event
    });
  }
}
