import { Observable, Subject } from 'rxjs';
import { ModalButton } from './interfaces/modal-button';

export class ModalButtonImpl implements ModalButton {
  private readonly _event = new Subject<ModalButton>();
  get $event(): Observable<ModalButton> {
    return this._event.asObservable();
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get isHidden(): boolean {
    return this._isHidden;
  }

  setHidden(hidden: boolean) {
    this._isHidden = hidden;
  }

  get action(): string {
    return this._action;
  }

  get text(): string {
    return this._text;
  }

  get fill(): string {
    return this._fill;
  }

  get expand(): string {
    return this._expand;
  }

  get icon(): string {
    return this._icon;
  }

  get color(): string {
    return this._color;
  }

  constructor(
    protected readonly _action: string,
    protected readonly _text?: string,
    protected readonly _fill: string = 'solid',
    protected readonly _expand: string = 'block',
    protected readonly _icon?: string,
    protected readonly _color: string = 'primary',
    protected _isDisabled: boolean = false,
    protected _isHidden: boolean = false
  ) {}

  onClicked(): void {
    this._event.next(this);
  }
}
