// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../../SagaBase/SagaObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class MapIcon_abstract extends SagaObject {
  static $t: string = 'SagaBase.Objects.MapIcon';
  $t: string = 'SagaBase.Objects.MapIcon';

  public static GetMembers(): string[] {
    return ['Name', 'ImageData', 'MapGroupUid'].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return MapIcon_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Name: string;
  public ImageData: any[];
  public MapGroupUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.MapGroupUid;
  }
  set ParentObjId(value: any) {
    this.MapGroupUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
