import { CurrentCardService, IModal, ModalButton, ModalFactoryService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { Guid } from '@models/guid';
import { StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { CacheService } from '@services/cache/cache.service';
import { Initializer } from '@services/initializer/initializer.service';
import { SagaSettingsService } from '@services/settings';
import { StatusService } from '@services/status/status.service';
import { AddinExecute, Execute, MyEquipmentService } from '@techwan/ionic-core';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { StatusModalComponent } from '../../../components/status-modal/status-modal/status-modal.component';
import { StatusTimeService } from '../../status-time/status-time.service';

@AddinExecute({
  name: 'IsAssignment'
})
@Injectable()
export class IsAssignmentService extends Execute {
  private readonly ACCEPT_ACTION = '1';
  private readonly CANCEL_ACTION = '0';

  private _assignment: ListResourceStatus = null;

  private _modalInstance: IModal<string> = null;
  private _modalPromise: Promise<void> = null;

  private _unit: UnitActivity;

  private _sub: Subscription = null;

  private readonly _receive = new Subject<CallCard>();

  get $receive(): Observable<CallCard> {
    return this._receive.asObservable();
  }

  constructor(
    private initializer: Initializer,
    private _statusService: StatusService,
    private _myEquipment: MyEquipmentService,
    private _cacheService: CacheService,
    private _modalFactory: ModalFactoryService,
    private _settingsService: SagaSettingsService,
    private _statusTimeService: StatusTimeService,
    private _currentCardService: CurrentCardService
  ) {
    super();
  }

  execute(): void {
    this._statusService.$ready.subscribe(
      () => (this._assignment = this._statusService.getStatusByAction(StatusItemAction.isRequestAssignment))
    );
    this.initializer.$onComplete.subscribe(c => this.setup(c));
  }

  is(status: ListResourceStatus): boolean {
    return this._assignment === status;
  }

  private setup(complete: boolean): void {
    if (complete === true && this._assignment !== null) {
      this._myEquipment.myUnit.subscribe(unitActivity => this.listenTo(unitActivity.ObjGuid)).unsubscribe();
    } else {
      this.clear();
    }
  }

  private listenTo(unitActivityId: Guid): void {
    this.clear();
    this._unit = this._cacheService.getObjectByUid(unitActivityId) as UnitActivity;
    this._sub = this._unit.$changed.subscribe(() => this.check());
    this.check();
  }

  private check(): void {
    if (this._unit.status === this._assignment && this._modalPromise === null) {
      this._receive.next(this._unit.callcard);
      this._modalPromise = this._modalFactory
        .customModalWithInstance<string>(
          {
            title: 'Mobile.RequestAssignment',
            buttons: this.getButtons(),
            content: StatusModalComponent
          },
          false
        )
        .then(modalInstance => (this._modalInstance = modalInstance).onDismiss.then(result => this.onModalDismiss(result)));
    } else if (this._unit.status !== this._assignment && this._modalPromise !== null) {
      // Close the modal due to a change on the status' value.
      this._modalInstance.modal.dismiss(null).then(() => (this._modalPromise = null));
    }
  }

  private getButtons(): ModalButton[] {
    const buttons: ModalButton[] = [
      this._modalFactory.createModalButton({ text: 'Mobile.Accept', action: this.ACCEPT_ACTION, color: 'primary' })
    ];
    if (this.canRefuse()) {
      buttons.unshift(this._modalFactory.createModalButton({ text: 'Mobile.Refuse', action: this.CANCEL_ACTION }));
    }
    return buttons;
  }

  private canRefuse(): boolean {
    return this._settingsService.getValue('SagaMobileWebClient.CanRefuseAssignment') || false;
  }

  private onModalDismiss(result: OverlayEventDetail<string>): void {
    this._modalPromise = null;

    // The modal has been discarded programmatically due to a dispatcher change (f.i.)
    if (result.data === null) {
      return;
    }

    const newStatusItemAction: StatusItemAction =
      result.data === this.ACCEPT_ACTION ? StatusItemAction.isAssignement : StatusItemAction.isCancelAssignment;
    const newStatus: ListResourceStatus = this._statusService.getStatusByAction(newStatusItemAction);

    this._statusTimeService.setTime(newStatus, moment().toDate());
    this._statusService.changeStatus(newStatus, this._unit.callcard);

    if (result.data === this.ACCEPT_ACTION) {
      this._currentCardService.select(this._unit.callcard);
    }
  }

  private clear() {
    if (this._sub !== null) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }
}
