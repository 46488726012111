// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @15.06.2017 16:59:16
import { PersistentObject_abstract } from './../abstract/SagaBase/PersistentObject_abstract';
import { CacheChangedInterface } from '@services/cache/cache.service';

export class SearchResult {
  constructor(public term: string, public context: PersistentObject, public paths?: string[]) {}

  get success(): boolean {
    return !!this.paths;
  }

  get path(): string {
    return this.paths && this.paths.join('.').replace(/\.\[/g, '[');
  }
}

export class PersistentObject extends PersistentObject_abstract {
  static SEARCH_PROPERTIES_EXCEPTION: string[] = ['$t'];

  get shortName() {
    let classPath = this.$t.split('.');
    return classPath[classPath.length - 1];
  }

  get text(): string {
    return this['Text'] !== undefined ? this['Text'] : '';
  }

  get icon(): string {
    return 'information-circle';
  }

  get iconStyle(): any {
    return {};
  }

  get title(): string {
    return this.$t;
  }

  get className() {
    return this.$t.replace(/\./g, '_');
  }

  rShort(): string {
    return this.title;
  }

  /**
   * @deprecated Should be changed, please consider using ModelService
   */
  public changed(change: CacheChangedInterface) {}

  private _searchItem(term: string, value: any): string[] {
    let result: string[];
    if (value instanceof PersistentObject) {
      result = value._search(term);
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        result = this._searchItem(term, value[i]);
        if (result) {
          result.unshift(`[${i}]`);
          break;
        }
      }
    } else if (typeof value == 'object') {
      for (let i in value) {
        if (value.hasOwnProperty(i)) {
          result = this._searchItem(term, value[i]);
          if (result) {
            result.unshift(`["${i}"]`);
            break;
          }
        }
      }
    } else {
      let strValue = value + '';
      let isOk = strValue.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      if (isOk) {
        result = [];
      }
    }

    return result;
  }

  private _search(term: string) {
    let result = null;
    for (let property in this) {
      if (this.hasOwnProperty(property) && PersistentObject.SEARCH_PROPERTIES_EXCEPTION.indexOf(property) === -1) {
        let value: any = this[property];
        if (value) {
          result = this._searchItem(term, value);
        }
      }
      if (result) {
        result.unshift(property);
        break;
      }
    }
    return result;
  }

  search(term: string): SearchResult {
    return new SearchResult(term, this, this._search(term));
  }
}
