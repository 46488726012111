import { Inject, Injectable } from '@angular/core';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import { Device } from '@ionic-native/device/ngx';
import { IonNav, Platform } from '@ionic/angular';
import { ToastColor, ToastService } from '@services/toast/toast.service';
import { Observable, from, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { NATIVE_PREFERENCE_TOKEN } from '../../schema/injector';
import { INativePreferences } from '../../schema/interfaces/INativePreferences';

export interface SettingEntry {
  text: string;
  icon?: string;
  handler?: (event?: Event, navCtrl?: IonNav) => void;
  type?: string;
  config?: any;
  value?: any;
}

/**
 * @description
 * Service used to manage settings
 */
@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private settingsEntry: any = {};

  private availableLanguages = ['en', 'fr', 'ar', 'de'];
  private defaultLanguage = 'fr';

  constructor(
    private platform: Platform,
    private device: Device,
    private settings: AppPreferences,
    @Inject(NATIVE_PREFERENCE_TOKEN)
    private nativePreferences: INativePreferences,
    private toastService: ToastService
  ) {}

  addSettingsEntry(entry: SettingEntry, group: string = 'global'): void {
    group = group.toLowerCase();
    if (!this.settingsEntry[group]) {
      this.settingsEntry[group] = [];
    }

    this.settingsEntry[group].push(entry);
  }

  getGroups(): string[] {
    return Object.keys(this.settingsEntry);
  }

  getSettingsForGroup(group: string = 'global'): SettingEntry[] {
    return this.settingsEntry[group.toLowerCase()] || [];
  }

  baseUrl(): Observable<string> {
    return this.get('ServerURL', '').pipe(map((baseUrl: string) => (baseUrl && !baseUrl.endsWith('/') ? baseUrl + '/' : baseUrl)));
  }

  apiKey(): Observable<string> {
    return this.platform.is('cordova') ? from(this.settings.fetch('ApiKey')) : of('');
  }

  apiBaseUrl(): Observable<string> {
    return !this.platform.is('cordova') || this.device.platform === 'browser' ? of('') : this.baseUrl();
  }

  updateBaseUrl(): Observable<string> {
    return this.device.platform === 'browser' ? this.apiBaseUrl().pipe(map(x => x + 'api/')) : this.baseUrl();
  }

  getPlatformLanguage(): Observable<string> {
    return of(navigator.language.substring(0, 2));
  }

  cameraQuality(): Observable<number> {
    return !this.platform.is('cordova')
      ? of(parseInt(this.getSync('camera_quality', '80'), 10))
      : from(this.settings.fetch('camera_quality')).pipe(map((quality: string) => parseInt(quality, 10) || 80));
  }

  saveToAlbum(): Observable<boolean> {
    return from(this.settings.fetch('save_to_album')).pipe(map((saveToAlbum: string) => saveToAlbum === 'true' || true));
  }

  language(skipPlatform: boolean = false): Observable<string> {
    return zip(this.get('lang', 'platform'), this.getPlatformLanguage())
      .pipe(
        map(([language, platform]) => {
          if (language === 'platform' && !skipPlatform) {
            return platform;
          } else if (language !== 'platform') {
            language = language.substring(0, 2);
          }

          return language || this.defaultLanguage;
        })
      )
      .pipe(
        map(lang => {
          if (!this.availableLanguages.includes(lang)) {
            this.toastService.show('SagaMobile.Translation.Unavailable', ToastColor.Info, {
              wantedLanguage: lang,
              defaultLanguage: this.defaultLanguage
            });
            lang = this.defaultLanguage;
          }
          return lang;
        })
      );
  }

  getSync(name: string, def: any) {
    return localStorage.getItem(name) || def;
  }

  get(name: string, def: any, forceLocalStorage: boolean = false): Observable<any> {
    return !this.platform.is('cordova') || forceLocalStorage
      ? of(localStorage.getItem(name) || def)
      : zip(from(this.settings.fetch(name)), this.nativePreferences.fetch(name)).pipe(map(([s1, s2]) => (s2 && s2[name]) || s1 || def));
  }

  set(name: string, value: any, forceLocalStorage: boolean = false): Observable<any> {
    return !this.platform.is('cordova') || forceLocalStorage
      ? of(localStorage.setItem(name, value))
      : of(this.nativePreferences.store(name, value));
  }
}
