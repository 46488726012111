import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { MapInstancesService } from '@services/map';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import Feature from 'ol/feature';
import Point from 'ol/geom/point';
import { DeploymentRequests } from '../../schema/interfaces/deployment-requests';

@Injectable()
export class DeploymentParsingService {
  constructor(private myUnit: MyUnitService, private mapInstances: MapInstancesService) {}

  forAdd(feature: Feature): DeploymentRequests {
    const deployment = this.createDeploymentPoint(GuidTools.generate(), feature);
    return { updates: [{ deployment }] };
  }

  private createDeploymentPoint(id: string, feature: Feature): CDeploymentPoint {
    const [x, y] = (feature.getGeometry() as Point).getCoordinates();

    const map = this.mapInstances.get('main');
    const mapProjection = map.getView().getProjection();

    const deploymentPoint = new CDeploymentPoint();
    deploymentPoint.ObjGuid = id;
    deploymentPoint.CenterX = x;
    deploymentPoint.CenterY = y;
    deploymentPoint.CoordEpsgCode = mapProjection.getCode();
    deploymentPoint.CallCardId = this.myUnit.mine.CallCardID;

    return deploymentPoint;
  }

  forUpdate(feature: Feature): DeploymentRequests {
    const deployment = this.createDeploymentPoint(feature.get('id'), feature);
    return { updates: [{ deployment }] };
  }

  forRemoval(feature: Feature): DeploymentRequests {
    const deployment = { ObjGuid: feature.get('id') } as CDeploymentPoint;
    return { updates: [{ deployment }] };
  }
}
