import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { Security } from '@services/security/security.service';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { FetchListService } from '@services/fetchlist/fetchlist.service';
import { StatusItemAction } from '@models/imported/SagaBase';
import { TranslateService } from '@ngx-translate/core';
import { UnitListFetchingService } from '../../services/unit-list-fetching/unit-list-fetching.service';
import { StatusService } from '@services/status/status.service';

@Component({
  templateUrl: './unit-activity-selector.component.html',
  styleUrls: ['./unit-activity-selector.component.scss'],
  providers: [UnitListFetchingService]
})
export class UnitActivitySelectorComponent {
  unitActivities: UnitActivity[];
  extend: boolean = false;

  constructor(
    private readonly _unitActivityService: UnitActivityService,
    private readonly _modalController: ModalController,
    private readonly _modalService: ModalService,
    private readonly _security: Security,
    private readonly _alertController: AlertController,
    private readonly _fetchListService: FetchListService,
    private readonly _statusService: StatusService,
    private readonly _translateService: TranslateService,
    private readonly _unitListFetchingService: UnitListFetchingService
  ) {}

  cancel() {
    this._modalController.dismiss();
  }

  itemSelected(item: UnitActivity) {
    this._alertController
      .create({
        message: this._translateService.instant('Saga.AssociateMessage'),
        subHeader: item.rShort(),
        buttons: [
          {
            text: this._translateService.instant('Saga.Cancel'),
            role: 'cancel',
            handler: () => {}
          },
          {
            text: this._translateService.instant('Saga.Associate'),
            role: 'primary',
            handler: async () => {
              this._modalController.dismiss(item);
            }
          }
        ]
      })
      .then(a => a.present());
  }

  refresh() {
    this.updateList();
  }

  ionViewWillEnter() {
    this.updateList();
  }

  private updateList() {
    const status = this._statusService.getStatusByAction(StatusItemAction.isAvailable).Value;
    this._unitListFetchingService.fetch(status).subscribe(unitActivityResource => (this.unitActivities = unitActivityResource));
  }
}
