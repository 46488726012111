<ion-header id="sagamobile-header" [appAlert]="count">
  <ion-toolbar color="primary ion-text-center">
    <ion-title>
      <app-header-title></app-header-title>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
  <app-alert-counter #count></app-alert-counter>
</ion-header>
