import { Pipe, PipeTransform } from '@angular/core';
import { SagaSettingsService } from '@services/settings';
import { MomentService } from '@techwan/ionic-core';

// possible DB values for sagaFormat: DateFormat, ShortDateFormat, SubmittedDateFormat
export enum SagaDate {
  DEFAULT = 'DateFormat',
  SHORT = 'ShortDateFormat',
  SUBMITTED = 'SubmittedDateFormat'
}

@Pipe({
  name: 'sagaDate'
})
export class SagaDatePipe implements PipeTransform {
  constructor(private momentService: MomentService, private sagaSettings: SagaSettingsService) {}

  transform(date: Date, sagaFormat: string = SagaDate.DEFAULT): string {
    let format = this.sagaSettings.getValue(`SagaMobileWebClient.${sagaFormat}`);

    return this.momentService.formatDate(date, format);
  }
}
