import { Component, OnInit } from '@angular/core';
import { CallcardComponentCard } from '../callcard-component-card';

@Component({
  selector: 'app-callcard-info-card',
  templateUrl: './callcard-info-card.component.html',
  styleUrls: ['./callcard-info-card.component.scss']
})
export class CallcardInfoCardComponent extends CallcardComponentCard implements OnInit {
  constructor() {
    super();
  }

  public ngOnInit() {}
}
