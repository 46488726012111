// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class UnitActivityLogCallCard_abstract extends SagaObject {
  static $t: string = 'SagaSchema.UnitActivityLogCallCard';
  $t: string = 'SagaSchema.UnitActivityLogCallCard';

  public static GetMembers(): string[] {
    return [
      'ActivityUid',
      'CallCardId',
      'Id',
      'Identifier',
      'EventText',
      'Status',
      'Acknowledge',
      'Data',
      'Text',
      'ServiceObjId',
      'ProcStepUid',
      'TraceId',
      'StampType'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return UnitActivityLogCallCard_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ActivityUid: Guid = GuidTools.Empty;
  public CallCardId: Guid = GuidTools.Empty;
  public Id: number = 0;
  public Identifier: string;
  public EventText: string;
  public Status: number = 0;
  public Acknowledge: boolean = false;
  public Data: string = '';
  public Text: string = '';
  public ServiceObjId: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public TraceId: Guid = GuidTools.Empty;
  public StampType: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ActivityUid;
  }
  set ParentObjId(value: any) {
    this.ActivityUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
