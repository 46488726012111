import { Injectable } from '@angular/core';
import { transform } from '@services/map';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';
import { Destination } from '../../schema/destination';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';

@Injectable()
export class GoogleMapsProviderService implements INavigationProvider {
  private readonly EPSG = '4326';

  constructor(private windowOpenerService: WindowOpenerService) {}

  navigate(destination: Destination): Window {
    if (destination.location.epsg !== this.EPSG) {
      destination.location = transform(destination.location, `EPSG:${this.EPSG}`);
    }
    return this.windowOpenerService.system(
      `https://www.google.com/maps/dir/?api=1&destination=${destination.location.y},${destination.location.x}`
    );
  }
}
