import { MapName } from '@addins/core/core';
import { MapConfigurationService } from '@addins/core/map';
import { DeploymentManagerService } from '@addins/deployment';
import { Inject, Injectable } from '@angular/core';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { MapInstanceEventType, MapInstancesService } from '@services/map';
import { MyEquipmentService } from '@techwan/ionic-core';
import {
  DrawingManager,
  DrawingManagerFactoryService,
  IValueProvider,
  MapToolsPanel,
  MapToolsPanelFactoryService,
  VALUE_PROVIDER
} from '@techwan/map-tools';
import { LayerManager, Map } from '@techwan/mapping';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DrawingPanelService {
  private _toolsPanel: MapToolsPanel = null;
  get tools(): MapToolsPanel {
    return this._toolsPanel;
  }

  private _unit: UnitActivity;

  constructor(
    private configuration: MapConfigurationService,
    private mapToolsPanelFactory: MapToolsPanelFactoryService,
    private drawingManager: DrawingManagerFactoryService,
    private deploymentManager: DeploymentManagerService,
    @Inject(VALUE_PROVIDER)
    private valueProvider: IValueProvider,
    private maps: MapInstancesService,
    private equipmentService: MyEquipmentService,
    private cache: CacheService
  ) {}

  setup(): Observable<DrawingManager> {
    return this.cache.state.pipe(
      filter(state => state === CacheState.ready),
      tap(() => this.myUnit()),
      switchMap(() => this.setupMap()),
      map(tacticalMap => this.init(tacticalMap))
    );
  }

  destroy() {
    this.deploymentManager.destroy();
  }

  private setupMap(): Observable<Map> {
    return this.maps.get(MapName.main)
      ? of(this.maps.get(MapName.main))
      : this.maps.$change.pipe(
          filter(event => event.type === MapInstanceEventType.added && event.name === MapName.main),
          map(e => e.target)
        );
  }

  private myUnit(): void {
    this.equipmentService.myUnit.subscribe(u => (this._unit = this.cache.getObjectByUid(u.ObjGuid))).unsubscribe();
  }

  private init(tacticalMap: Map): DrawingManager {
    const tools = this.configuration.getTools();
    this._toolsPanel = this.mapToolsPanelFactory.createPanel({ tools: tools.tools });

    if (tools.deployment === true) {
      this.deploymentManager.setup(tacticalMap, this._toolsPanel);
    }

    return this.drawingManager
      .setPanel(this._toolsPanel)
      .setManager(tacticalMap.getLayerManager() as LayerManager)
      .setValueProvider(this.valueProvider)
      .createAndInit();
  }
}
