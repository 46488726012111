// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class PhoneNumber_abstract extends SagaObject {
  static $t: string = 'SagaSchema.PhoneNumber';
  $t: string = 'SagaSchema.PhoneNumber';

  public static GetMembers(): string[] {
    return [
      'ResResourceUid',
      'Address',
      'Description',
      'CommunicationTypeConst',
      'ContactCategoryConst',
      'MiscInfo',
      'SortOrder',
      'IsExcludedFromMobilization',
      'ActiveFromUTC',
      'InactiveFromUTC',
      'BackInServiceUTC',
      'RetiredUTC',
      'ImportId'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return PhoneNumber_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.ActiveFromUTC = this.ActiveFromUTC && new Date(this.ActiveFromUTC);
    this.InactiveFromUTC = this.InactiveFromUTC && new Date(this.InactiveFromUTC);
    this.BackInServiceUTC = this.BackInServiceUTC && new Date(this.BackInServiceUTC);
    this.RetiredUTC = this.RetiredUTC && new Date(this.RetiredUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ResResourceUid: Guid = GuidTools.Empty;
  public Address: string;
  public Description: string;
  public CommunicationTypeConst: SagaBase.CommunicationType = SagaBase.CommunicationType.Unknown;
  public ContactCategoryConst: number;
  public MiscInfo: string;
  public SortOrder: number;
  public IsExcludedFromMobilization: boolean = false;
  public ActiveFromUTC: Date = new Date();
  public InactiveFromUTC: Date = new Date();
  public BackInServiceUTC: Date = new Date();
  public RetiredUTC: Date = new Date();
  public ImportId: number = -1;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ResResourceUid;
  }
  set ParentObjId(value: any) {
    this.ResResourceUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
