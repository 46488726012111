<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-title>
            <span translate>SagaMobile.UA.Associate</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon slot="icon-only"  name="refresh"></ion-icon>
            </ion-button>
            <ion-button (click)="cancel()">
                <ion-icon slot="icon-only"  name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <div class="ua-list">
        <ion-list>
            <ion-item *ngFor="let unitActivity of unitActivities" (click)="itemSelected(unitActivity)" class="ua">
                <div class="ua-avatar" slot="start"  [style.borderLeftColor]="unitActivity.colorRgb">
                    <ion-icon [icon]="unitActivity.icon" [color]="unitActivity.color.toRgbString()"></ion-icon>
                </div>

                <h4>{{unitActivity.rShort()}}</h4>
                <p></p>
            </ion-item>
        </ion-list>
    </div>
</ion-content>
