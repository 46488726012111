// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class ApplicationPurgeType_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.ApplicationPurgeType';
  $t: string = 'SagaSchema.ApplicationPurgeType';

  public static GetMembers(): string[] {
    return [
      'PurgeParameterID',
      'PurgeFrequency',
      'PurgeLimitDays',
      'PurgeDate',
      'PurgeProcedure',
      'IsScheduled',
      'Status',
      'Logged',
      'Running'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return ApplicationPurgeType_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.PurgeDate = this.PurgeDate && new Date(this.PurgeDate);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public PurgeParameterID: number = 0;
  public PurgeFrequency: SagaBase.Frequency = SagaBase.Frequency.once;
  public PurgeLimitDays: number = 0;
  public PurgeDate: Date = new Date();
  public PurgeProcedure: string;
  public IsScheduled: boolean = true;
  public Status: boolean = false;
  public Logged: boolean = false;
  public Running: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.PurgeParameterID;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
