// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AlarmCustomer_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.AlarmCustomer';
  $t: string = 'SagaSchema.AlarmCustomer';

  public static GetMembers(): string[] {
    return [
      'CustomerUId',
      'Id',
      'Name',
      'Contact',
      'Phone1',
      'Phone2',
      'Mobile',
      'Fax',
      'EMail',
      'LocationID',
      'LocationText',
      'LocationComplement',
      'CoordX',
      'CoordY',
      'Deleted'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AlarmCustomer_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.AlarmCustomerContact'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CustomerUId: Guid = GuidTools.Empty;
  public Id: number = 0;
  public Name: string;
  public Contact: string;
  public Phone1: string;
  public Phone2: string;
  public Mobile: string;
  public Fax: string;
  public EMail: string;
  public LocationID: number;
  public LocationText: string = '';
  public LocationComplement: string;
  public CoordX: number = 0;
  public CoordY: number = 0;
  public Deleted: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.CustomerUId;
  }
  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
