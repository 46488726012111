// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { ConditionPart } from '../../SagaActionLib/ConditionPart';
import * as SagaActionLib from '../../SagaActionLib';
import { Guid, GuidTools } from '@models/guid';

export abstract class ActionConditionGroup_abstract extends ConditionPart {
  static $t: string = 'SagaActionLib.ActionConditionGroup';
  $t: string = 'SagaActionLib.ActionConditionGroup';

  public static GetMembers(): string[] {
    return ['Grouplogic'].concat(ConditionPart.GetMembers());
  }

  public GetMembers(): string[] {
    return ActionConditionGroup_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Grouplogic: SagaActionLib.GroupType = SagaActionLib.GroupType.and;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
