import { Injectable } from '@angular/core';
import { LoginStorageService } from '@techwan/ionic-core/login';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { IRefreshTokenStoreItem } from '@techwan/security';

@Injectable({
  providedIn: 'root'
})
export class PersistentRefreshTokenService {
  private _isPersistentRefreshToken: boolean = false;

  constructor(private readonly _loginStorage: LoginStorageService, private readonly _refreshTokenStorage: RefreshTokenStorageService) {
    this._refreshTokenStorage.$value.subscribe(() => this.onRefreshTokenChanged());
  }

  onPersistentRefreshTokenChanged(isPersistentRefreshToken: boolean): Promise<void> {
    this._isPersistentRefreshToken = isPersistentRefreshToken;

    if (isPersistentRefreshToken) {
      return this.setRefreshTokenInSessionStorage();
    } else {
      this._loginStorage.removeRefreshToken();
      this._refreshTokenStorage.clear();
      return Promise.resolve();
    }
  }

  private setRefreshTokenInSessionStorage(): Promise<void> {
    return this._loginStorage.getRefreshToken().then(
      refreshToken => {
        if (refreshToken !== null) {
          const refreshTokenStoreItem: IRefreshTokenStoreItem = JSON.parse(refreshToken);
          this._refreshTokenStorage.write(refreshTokenStoreItem);
        }
      },
      error => error
    );
  }

  private onRefreshTokenChanged() {
    if (this._isPersistentRefreshToken) {
      const refreshToken: IRefreshTokenStoreItem = this._refreshTokenStorage.read();
      if (refreshToken !== null) {
        this._loginStorage.setRefreshToken(JSON.stringify(refreshToken));
      } else {
        this._loginStorage.removeRefreshToken();
      }
    }
  }
}
