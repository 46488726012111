import { SETTING_OPENER_TOKEN } from '@addins/core/menus/schema/injector';
import { Injector, NgModule } from '@angular/core';
import { SagaComponentsModule } from '@app/components/module';
import { SagaDirectivesModule } from '@app/directives';
import { NATIVE_PREFERENCE_TOKEN } from '@app/services';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { ConnectivitySettingsComponent } from './components/connectivity-settings/connectivity-settings.component';
import { CustomSettingsComponent } from './components/custom-settings/custom-settings.component';
import { GenericSettingsComponent } from './components/generic-settings/generic-settings.component';
import { LocationSettingsComponent } from './components/location-settings/location-settings.component';
import { ServerListComponent } from './components/server-list/server-list.component';
import { SettingButtonComponent } from './components/setting-button/setting-button.component';
import { SettingsSelectFieldComponent } from './components/settings-select-field/settings-select-field.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsMenuService } from './services/executables/settings-menu.service';
import { LocationSettingsManagerService } from './services/location-settings-manager/location-settings-manager.service';
import { NativePreferencesService } from './services/native-preferences/native-preferences.service';
import { SettingOpenerService } from './services/setting-opener/setting-opener.service';
import { SettingsEditorComponent } from './views/settings-editor/settings-editor.component';

const executables = [SettingsMenuService];

const services = [NativePreferencesService, SettingOpenerService, LocationSettingsManagerService, ...executables];

const views = [SettingsEditorComponent];

const components = [
  ConnectivitySettingsComponent,
  CustomSettingsComponent,
  GenericSettingsComponent,
  LocationSettingsComponent,
  SettingsComponent,
  ServerListComponent,
  SettingsSelectFieldComponent,
  SettingButtonComponent,
  ...views
];

const providers = [
  ...executables,
  ...services,
  { provide: NATIVE_PREFERENCE_TOKEN, useExisting: NativePreferencesService },
  { provide: SETTING_OPENER_TOKEN, useExisting: SettingOpenerService }
];

@Addin({
  name: 'Settings'
})
@NgModule({
  declarations: [...components],
  imports: [IonicModule, SharedModule, SagaDirectivesModule, SagaComponentsModule],
  exports: [...components],
  providers
})
export class SettingsModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
