import { CommonModule, Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule } from '@ngx-translate/core';
import { IconService } from '@services/icon/icon.service';
import { PUSH_CONFIGURATION_TOKEN, SagaPushModule } from '@services/push';
import { AddinService } from '@techwan/ionic-core';
import { IdToTypeConverter } from '../models/imported/createInstanceById';
import { NgxImports } from '../modules/ngx-imports';
import { AssociationSecurity } from './association-security/association-security.service';
import { CacheProxyService } from './cache/cache-proxy.service';
import { CacheService } from './cache/cache.service';
import { CallcardService } from './callcard/callcard.service';
import { DbService } from './database/database.service';
import { EnvironmentService } from './environment/environment.service';
import { EventService } from './event/event';
import { FetchListProxyService } from './fetchlist/fetchlist-proxy.service';
import { FetchListService } from './fetchlist/fetchlist.service';
import { GlobalActionsService } from './global-actions/global-actions.service';
import { GlobalizationService } from './globalization/globalization.service';
import { TranslationApiService } from './globalization/translation-api/translation-api.service';
import { HomeService } from './home/home.service';
import { Initializer } from './initializer/initializer.service';
import { LayoutService } from './layout/layout.service';
import { ListNameService } from './list-name/list-name.service';
import { LoginConfigService } from './login-config/login-config.service';
import { MapInstancesService } from './map/map-instances/map-instances.service';
import { MapService } from './map/map/map.service';
import { SideMenuService } from './menu/side-menu.service';
import { ModalService } from './modal/modal.service';
import { ModelFactoryService } from './model-factory';
import { NavService } from './navigation/navigation.service';
import { ObjectFactory } from './object-factory/object-factory.service';
import { SagaFile, SagaToastController } from './overrides';
import { PersistentRefreshTokenService } from './persistent-refresh-token/persistent-refresh-token.service';
import { ProfileReaderService } from './profile-reader/profile-reader.service';
import { ProfileService } from './profile/profile.service';
import { PushConfigurationService } from './push-configuration/push-configuration.service';
import { RefreshTokenStorageService } from './refresh-token-storage/refresh-token-storage.service';
import { SectorService } from './sector/sector.service';
import { Security } from './security/security.service';
import { AppSettingProviderService, AppSettingsService, ImagesSettingsService, SagaSettingsService } from './settings';
import { StatusBarService } from './status-bar/status-bar.service';
import { StatusProxyService } from './status/status-proxy/status-proxy.service';
import { StatusService } from './status/status.service';
import { StylesService } from './styles/styles.service';
import { ToolsProviderService } from './tools';
import { UnitActivityProxyService } from './unit-activity-proxy/unit-activity-proxy.service';
import { MyUnitService } from './unit-activity/my-unit/my-unit.service';
import { UnitActivityService } from './unit-activity/unit-activity.service';
import { WindowOpenerService } from './window-opener/window-opener.service';

export { NATIVE_PREFERENCE_TOKEN } from './schema/injector';
export { INativePreferences } from './schema/interfaces/INativePreferences';

@NgModule({
  imports: [
    HttpClientModule,
    IonicStorageModule.forRoot(),
    NgxImports,
    CommonModule,
    TranslateModule.forRoot(),
    IonicModule,
    SagaPushModule
  ],
  exports: [HttpClientModule, IonicStorageModule, NgxImports, CommonModule, TranslateModule, SagaPushModule],
  providers: [
    IconService,
    Location,
    AppSettingsService,
    ImagesSettingsService,
    AddinService,
    Security,
    SideMenuService,
    GlobalizationService,
    NavService,
    SagaFile,
    ModalService,
    Initializer,
    MapService,
    MapInstancesService,
    SagaSettingsService,
    UnitActivityService,
    StatusBarService,
    StatusProxyService,
    StatusService,
    AssociationSecurity,
    CacheService,
    ModelFactoryService,
    FetchListService,
    FetchListProxyService,
    ObjectFactory,
    IdToTypeConverter,
    StylesService,
    SectorService,
    EventService,
    ProfileService,
    ProfileReaderService,
    LayoutService,
    CallcardService,
    DbService,
    GlobalActionsService,
    HomeService,
    ToolsProviderService,
    SagaToastController,
    TranslationApiService,
    MyUnitService,
    ListNameService,
    CacheProxyService,
    EnvironmentService,
    LoginConfigService,
    PersistentRefreshTokenService,
    WindowOpenerService,
    PushConfigurationService,
    UnitActivityProxyService,
    AppSettingProviderService,
    RefreshTokenStorageService,
    { provide: PUSH_CONFIGURATION_TOKEN, useExisting: PushConfigurationService }
  ]
})
export class SagaServiceModule {}
