import { Component, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  @Input()
  name: string;

  @ViewChild('content', { read: ViewContainerRef, static: true })
  private _content: ViewContainerRef;

  private _component: Type<any>;

  constructor(private readonly selector: ViewSelectorService) {}

  ngOnInit() {
    this._component = this._content.createComponent(this.selector.getView(this.name).element).instance;
  }
}
