import { Injectable, Type } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { CFreeText } from '@models/imported/SagaSchema/CFreeText';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslateService } from '@ngx-translate/core';
import { CallcardService } from '@services/callcard/callcard.service';
import { ModalService } from '@services/modal/modal.service';
import { ObjectFactory } from '@services/object-factory/object-factory.service';

/**
 * ReportService
 * @description
 * The service for managing report
 */

@Injectable()
export class ReportService {
  private _reportComponent: Type<any>;
  set reportComponent(value: Type<any>) {
    this._reportComponent = value;
  }

  constructor(
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private modal: ModalService,
    private modalCtrl: ModalController,
    private callcardService: CallcardService,
    private objectFactory: ObjectFactory
  ) {}

  addOrEditCro(callcard: CallCard) {
    this.alertCtrl
      .create({
        header: this.translate.instant('SagaMobile.Stamp.Cro.Title'),
        message: this.translate.instant('SagaMobile.Stamp.Cro.Message'),
        inputs: [
          {
            name: 'text',
            placeholder: this.translate.instant('SagaMobile.Stamp.Cro.PlaceHolder')
          }
        ],
        buttons: [
          {
            text: this.translate.instant('SagaMobile.Stamp.Cro.Cancel'),
            handler: data => {}
          },
          {
            text: this.translate.instant('SagaMobile.Stamp.Cro.Send'),
            handler: async data => {
              if (data && data.text) {
                await this.modal.presentLoading('SagaMobile.Stamp.Cro.Sending');
                const response = await this.callcardService.addCro(callcard, data.text);
                await this.modal.dismissLoading();
                if (response) {
                  callcard.refreshStamps.emit();
                }
              }
            }
          }
        ]
      })
      .then(ionAlert => ionAlert.present());
  }

  addOrEditFreetext(callcard: CallCard, object?: CFreeText) {
    this.alertCtrl
      .create({
        header: this.translate.instant('SagaMobile.Stamp.Freetext.Title'),
        message: this.translate.instant('SagaMobile.Stamp.Freetext.Message'),
        inputs: [
          {
            name: 'text',
            placeholder: this.translate.instant('SagaMobile.Stamp.Freetext.PlaceHolder'),
            min: 1,
            max: 200
          }
        ],
        buttons: [
          {
            text: this.translate.instant('SagaMobile.Stamp.Freetext.Cancel'),
            handler: data => {}
          },
          {
            text: this.translate.instant('SagaMobile.Stamp.Freetext.Send'),
            handler: async data => {
              if (data && data.text) {
                await this.modal.presentLoading('SagaMobile.Stamp.Freetext.Sending');
                let response;
                if (!object) {
                  response = await this.callcardService.addFreetext(callcard.ObjGuid, data.text);
                } else {
                  object.Text = data.text;
                  response = await this.callcardService.updateFreetext(object);
                }
                await this.modal.dismissLoading();
                if (response) {
                  callcard.refreshStamps.emit();
                }
              }
            }
          }
        ]
      })
      .then(ionAlert => ionAlert.present());
  }

  add(callcard: CallCard, object: SagaObject | typeof SagaObject, initialization: any = {}) {
    const update: boolean = object instanceof SagaObject;
    if (!update) {
      object = this.objectFactory.create(initialization, object);
    }

    // TODO: @deprecated please use ModalFactoryService instead
    this.modalCtrl
      .create({
        component: this._reportComponent,
        componentProps: {
          CallCard: callcard,
          object,
          update
        }
      })
      .then(modal => modal.present());
  }

  edit(callcard: CallCard, object: SagaObject) {
    if (object instanceof CFreeText) {
      this.addOrEditFreetext(callcard, object);
    } else {
      // TODO: @deprecated please use ModalFactoryService instead
      this.modalCtrl
        .create({
          component: this._reportComponent,
          componentProps: {
            CallCard: callcard,
            object,
            update: true
          }
        })
        .then(modal => modal.present());
    }
  }
}
