import { Injectable, Type } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { IPermanentMenuEvent, PermanentEntry } from '../../schema/permanent-entry';
import { PermanentMenuService } from '../permanent-menu/permanent-menu.service';

@Injectable()
export class ViewLoaderService {
  private _view: IonNav = null;

  private _current: PermanentEntry = null;

  private _subs: Subscription[] = [];

  private _event = new Subject<Type<any>>();
  get onChange(): Observable<Type<any>> {
    return this._event.asObservable();
  }

  constructor(private menu: PermanentMenuService) {}

  initialize(view: IonNav, type?: Type<any>) {
    this._view = view;

    this.subscribe();

    // Try to load the content into the view
    this.load(type);
  }

  show(type: Type<any>, data?: any) {
    this.load(type, data);
  }

  reset() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
    this._current = null;
    this._view = null;
  }

  private load(content: Type<any>, data?: any) {
    const old = this._current;
    if (!this._view) {
      throw new Error('ViewLoaderService must be initialized first with a view.');
    }

    if (!this.check(content)) {
      return;
    }

    this._current = this.getItem(content);
    if (old && old !== this._current) {
      old.reset();
    }
    if (old !== this._current) {
      this.loadAndNotify(content, data);
    }
  }

  private loadAndNotify(content: Type<any>, data?: any) {
    // Update the view and notify listener about the changes
    this._view.setRoot(content, data).then(() => this._event.next(content));
  }

  private subscribe() {
    this.menu.getEntries().forEach(entry => this._subs.push(entry.onClick.subscribe(event => this.onChanged(event))));
  }

  private onChanged(event: IPermanentMenuEvent) {
    this.load(event.item.type as Type<any>);
  }

  private check(type: Type<any>): boolean {
    return this.menu.getEntries().filter(entry => entry.type && entry.type === type).length > 0;
  }

  private getItem(type: Type<any>): PermanentEntry {
    return this.menu.getEntries().filter(entry => entry.type && entry.type === type)[0];
  }
}
