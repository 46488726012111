import { MapName } from '@addins/core/core';
import { Component, ViewChild } from '@angular/core';
import { ActionSheetController, IonNav, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CLocationHistory } from '@models/imported/SagaSchema/History/CLocationHistory';
import { TranslateService } from '@ngx-translate/core';
import { MapInstancesService, MapService } from '@services/map';
import { ToolsProviderService } from '@services/tools';
import { AddinView } from '@techwan/ionic-core';
import { MapElementComponent } from '@techwan/ionic-core/map';
import { Map, MapOptions } from '@techwan/mapping';
import { MapMorePopoverComponent } from '../../components/map-more-popover/map-more-popover.component';
import { ObjectChooserComponent } from '../../components/object-chooser/object-chooser.component';
import { LocalizePositionService } from '../../services/localize-position/localize-position.service';
import { MapSettingsService } from '../../services/map-settings/map-settings.service';

@AddinView({
  name: 'Fab',
  description: 'A view for the Saga Mapping System'
})
@Component({
  selector: 'map-fab-view',
  templateUrl: './map-fab-view.component.html',
  styleUrls: ['./map-fab-view.component.scss']
})
export class MapFabViewComponent {
  @ViewChild(MapElementComponent)
  mapElement: MapElementComponent;

  mapOptions: MapOptions;

  private map: Map;
  private readonly nav: IonNav;

  constructor(
    private maps: MapInstancesService,
    private mapService: MapService,
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
    private toolsProvider: ToolsProviderService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private localize: LocalizePositionService,
    private mapSettings: MapSettingsService
  ) {
    this.mapOptions = {
      scaleLine: true,
      scaleChoice: this.mapSettings.showScaleChoice
    };
  }

  /**
   * Warning: Use an internal copy (new reference) of the map service tool array to prevent DOM rebuilding by Angular
   * due to reference changes.
   *
   * Should use map service event instead of rebuilding array at each refresh.
   *
   * @AMO Rollback to get the reference of buttonItems directly
   */
  get buttonItems() {
    return this.mapService.buttonItems;
  }

  ionViewWillUnload() {
    if (this.mapElement) {
      if (this.map) {
        this.maps.remove(MapName.main);
      }
    }
  }

  ionViewDidEnter() {
    this.mapElement.initMap().then(map => this.onMapReady(map));
  }

  private onMapReady(map: Map) {
    this.map = map;
    this.maps.add(MapName.main, map);

    if (this.navParams.get('location')) {
      this.localize.centerView(map.getView(), this.navParams.get('location'), 1);
    }

    map.addClickEventListener(mapClickEvent => this.onEvent(mapClickEvent));
  }

  private onEvent(event, objects?) {
    const map = this.map;
    objects = objects || event.objects.filter(o => o.feature.getData && o.feature.getData('object')).map(o => o.feature.getData('object'));

    if (objects.length === 1 && objects[0].location) {
      event.mapCoordinate = objects[0].location.getOLCoordinate();
    }
    const coordinate = {
      x: event.mapCoordinate[0],
      y: event.mapCoordinate[1],
      epsg: (map.getView().getProjection() as any).getEpsgCode(),
      objects: objects || []
    };
    let coordinateToolButtons = this.toolsProvider.getToolsForContext(coordinate, this.nav).getActionSheetButtons(false);
    let title;
    if (objects.length) {
      const provider = this.toolsProvider.getToolsForContext(objects, this.nav);
      const buttons = provider.getActionSheetButtons(false);
      coordinateToolButtons = coordinateToolButtons.concat(buttons);
      title = (objects.length === 1 && objects[0].rShort()) || undefined;
    }

    if (objects.length > 1) {
      coordinateToolButtons.push({
        text: this.translate.instant('SagaMobile.Map.ChooseObject'),
        icon: 'albums',
        priority: -99,
        handler: () => {
          // TODO: @deprecated please use ModalFactoryService instead
          this.modalCtrl
            .create({
              component: ObjectChooserComponent,
              componentProps: {
                objects,
                map,
                event: {
                  offsetX: event.offsetPixel[0],
                  offsetY: event.offsetPixel[1]
                }
              }
            })
            .then(ionModal => {
              ionModal.onDidDismiss().then(data => {
                if (data.data) {
                  this.onEvent(event, [data.data]);
                }
              });
              return ionModal.present();
            });
        }
      } as any);
    }

    coordinateToolButtons.push({
      text: 'Annuler',
      role: 'cancel',
      icon: 'close',
      priority: -100
    });

    if (coordinateToolButtons.length > 1) {
      this.actionSheetCtrl
        .create({
          header: title,
          buttons: coordinateToolButtons
        })
        .then(actionSheet => actionSheet.present());
    }
  }

  openMoreItems(evt) {
    this.popoverCtrl
      .create({
        component: MapMorePopoverComponent,
        event: evt
      })
      .then(popover => popover.present());
  }

  centerOnLocation(location: CLocation | CLocationHistory, resolution: number = null) {
    if (location) {
      this.localize.centerOnLocation(this.map.getView(), location, resolution);
      this.mapService.moveToPosition = false;
    }
  }

  centerOnCard(callcard: CallCard, resolution: number = null) {
    if (callcard) {
      this.centerOnLocation(callcard.location, resolution);
    }
  }
}
