// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:28:21
/* tslint:disable */
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CObjectBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CObjectBase';
  $t: string = 'SagaSchema.CObjectBase';

  public static GetMembers(): string[] {
    return ['TraceId', 'Kind', 'Identification', 'Make', 'Color', 'Size', 'Description', 'Text', 'CallCardId', 'TraceState'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return CObjectBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public TraceId: Guid = GuidTools.Empty;
  public Kind: string = '';
  public Identification: string = '';
  public Make: string = '';
  public Color: string = '';
  public Size: string = '';
  public Description: number = 0;
  public Text: string = '';
  public CallCardId: Guid = GuidTools.Empty;
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
