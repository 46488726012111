<ng-container *ngFor="let form of forms">
  <ion-item (click)="openForm(form.FormValueUid)">
    <ion-icon src="assets/icons/saga-forms/saga-forms.svg" slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h2>
        <strong translate>Mobile.Forms.FormName</strong>
      </h2>
      <h2>
        {{ form.FormName }}
      </h2>
      <h2>
        <strong translate>Mobile.Forms.FormCreationDate</strong>
      </h2>
      <h2>{{ form.CreatedUTC | sagaDate: 'SubmittedDateFormat' }}</h2>
      <h2>
        <strong translate>Mobile.Forms.FormId</strong>
      </h2>
      <h2>{{ form.FormIdentifier }}</h2>
    </ion-label>
  </ion-item>
</ng-container>
