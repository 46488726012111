import { Injectable } from '@angular/core';
import { transformOL } from '@services/map';
import { MapSettingsService } from '../map-settings/map-settings.service';

@Injectable()
export class ProjectionConverterService {
  constructor(private readonly _settings: MapSettingsService) {}

  public convertToView(
    view: ol.View,
    position: ol.Coordinate,
    fromProjection: string = this._settings.getSystemProjection()
  ): ol.Coordinate {
    return transformOL(position, fromProjection, view.getProjection());
  }

  public getProjection(value: number | string = null): string {
    return (
      (value === null && this._settings.getSystemProjection()) || (`${value}`.toUpperCase().indexOf('EPSG') > -1 ? '' : 'EPSG:') + value
    );
  }
}
