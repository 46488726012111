import { CommonAddinModule } from '@addins/common.module';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { SharedModule } from '../../../modules/shared/shared.module';
import { AttachmentViewerComponent } from './components/attachmentsViewer/attachmentViewer';
import { CAttachmentFollowupComponent } from './components/callCardDetail/callCardDetailFollowup/CAttachmentFollowup/CAttachmentFollowup';
import { CCallerBaseFollowupComponent } from './components/callCardDetail/callCardDetailFollowup/CCallerBaseFollowup/CCallerBaseFollowup';
import { CStampCardFollowupComponent } from './components/callCardDetail/callCardDetailFollowup/CStampCard/CStampCardFollowup';
import { CStampParticularsFollowupComponent } from './components/callCardDetail/callCardDetailFollowup/CStampParticularsFollowup/CStampParticularsFollowup';
import { CallCardSipComponent } from './components/callCardDetail/callCardSip/callCardSip';
import { TextStampModalComponent } from './components/callCardDetail/textStampModal/textStampModal';
import { EventSelectComponent } from './components/eventSelect/eventSelect';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { EquipmentPhoneSelectComponent } from './components/vehicleContactViewer/equipmentPhoneSelect';
import { VehicleContactViewerComponent } from './components/vehicleContactViewer/vehicleContactViewer';
import { CardTitlePipe } from './pipe/cardTitle';
import { SecurePipe } from './pipe/secure/secure.pipe';
import { ExternalService } from './services/external/external.service';

const publicComponents = [EventSelectComponent, ImageViewerComponent];

const directives = [];
const entries = [...publicComponents];

const exports = [
  /* COMPONENT EXPORTATION */
  ...entries,

  ...directives
];
const declarations = [
  /* DECLARATIONS LIST */
  CStampParticularsFollowupComponent,
  CAttachmentFollowupComponent,
  CCallerBaseFollowupComponent,
  CStampCardFollowupComponent,

  AttachmentViewerComponent,

  CallCardSipComponent,
  TextStampModalComponent,

  CardTitlePipe,
  SecurePipe,

  EventSelectComponent,
  VehicleContactViewerComponent,
  EquipmentPhoneSelectComponent,
  /* DECLARATIONS LIST */
  ...entries,

  ...directives
];
const services = [ExternalService, Camera];

@NgModule({
  declarations,
  imports: [SharedModule, HttpClientModule, IonicModule, CommonAddinModule],
  exports,
  providers: services
})
@Addin({
  name: 'callcard',
  description: 'Use to manipulate callcard'
})
export class CallCardModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, declarations, services);
  }
}
