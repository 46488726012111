import { Component, Input } from '@angular/core';
import { UploadManagerService } from '@techwan/file-upload';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';

@Component({
  selector: 'app-upload-control',
  templateUrl: './upload-control.component.html',
  styleUrls: ['./upload-control.component.scss']
})
export class UploadControlComponent {
  @Input()
  uploadItems: SagaFileEntryUploadItem[];

  constructor(private uploadManager: UploadManagerService) {}

  canStartAll(): boolean {
    return (
      this.uploadItems.filter(uploadItem => uploadItem.isReady() && !uploadItem.isUploading()).length > 0 &&
      !this.uploadManager.isUploading()
    );
  }

  canPauseAll(): boolean {
    return this.uploadItems.filter(uploadItem => uploadItem.isReady() && uploadItem.isUploading()).length > 0;
  }

  onStartAll() {
    this.uploadManager.start();
  }

  onPauseAll() {
    this.uploadManager.pause();
  }

  onDeleteAll() {
    this.uploadItems.forEach(uploadItem => uploadItem.cancel());
  }
}
