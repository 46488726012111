import { MyLocationService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { StatusChangeResult, StatusItemAction } from '@models/imported/SagaBase';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { SagaSettingsService } from '@services/settings';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StatusService } from '../status.service';

@Injectable({
  providedIn: 'root'
})
export class LocationBasedStatusService {
  private maxDistanceToTriggerOnSite: number = 100;

  private unitChangeSubscription: Subscription = null;
  private myLocationSubscription: Subscription = null;
  private statusChangeInProgress: boolean = false;

  constructor(
    private myUnitService: MyUnitService,
    private myLocation: MyLocationService,
    private settingsService: SagaSettingsService,
    private statusService: StatusService
  ) {}

  setup() {
    this.maxDistanceToTriggerOnSite = this.settingsService.getValue('SagaMobileWebClient.MaxDistanceToTriggerOnSite');

    this.myUnitService.$unitChanged.subscribe(unit => {
      if (unit) {
        this.unitChangeSubscription = this.myUnitService.$change.subscribe(() => this.onUnitUpdate(this.myUnitService.mine));
        this.onUnitUpdate(this.myUnitService.mine);
      } else if (!unit && this.unitChangeSubscription) {
        this.unitChangeSubscription.unsubscribe();
        this.unitChangeSubscription = null;
      }
    });
  }

  private onUnitUpdate(unit: UnitActivity) {
    const isAssignementStatus: ListResourceStatus = this.statusService.getStatusByAction(StatusItemAction.isAssignement);

    if (!this.myLocationSubscription && unit.status === isAssignementStatus) {
      this.myLocationSubscription = this.myLocation.$change
        .pipe(filter(coordinate => coordinate !== this.myLocation.empty))
        .subscribe(() => {
          const callcardLocation: Coordinate = {
            x: unit.callcard.location.lat,
            y: unit.callcard.location.lon,
            epsg: this.myLocation.empty.epsg
          };
          const distanceToCallcardLocation: number = this.myLocation.haversineDistance(callcardLocation);

          if (distanceToCallcardLocation <= this.maxDistanceToTriggerOnSite && !this.statusChangeInProgress) {
            this.statusChangeInProgress = true;
            const onSiteStatus: ListResourceStatus = this.statusService.getStatusByAction(StatusItemAction.isOnSite);
            this.statusService
              .changeStatus(onSiteStatus, unit.callcard, {}, '', false)
              .then(statusChangeResult => {
                if (statusChangeResult === StatusChangeResult.OK) {
                  this.clearLocationSubscription();
                }
              })
              .finally(() => {
                this.statusChangeInProgress = false;
              });
          }
        });
    } else if (this.myLocationSubscription && unit.status !== isAssignementStatus) {
      this.clearLocationSubscription();
    }
  }

  private clearLocationSubscription() {
    if (this.myLocationSubscription) {
      this.myLocationSubscription.unsubscribe();
      this.myLocationSubscription = null;
    }
  }
}
