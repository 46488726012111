// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { BoolQuestion } from '../../SagaBase/BoolQuestion';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class BoolState_abstract extends BoolQuestion {
  static $t: string = 'SagaBase.BoolState';
  $t: string = 'SagaBase.BoolState';

  public static GetMembers(): string[] {
    return ['ComputeStateId', 'ComputeCategory', 'ComputeProcedure', 'ComputeParameter'].concat(BoolQuestion.GetMembers());
  }

  public GetMembers(): string[] {
    return BoolState_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ComputeStateId: number = 0;
  public ComputeCategory: SagaBase.ComputeStateCategory;
  public ComputeProcedure: string;
  public ComputeParameter: any[];

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
