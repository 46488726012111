// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class GeoSectorComplement_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.GeoSectorComplement';
  $t: string = 'SagaSchema.GeoSectorComplement';

  public static GetMembers(): string[] {
    return [
      'GeoSectorId',
      'GeoSectorImpId',
      'ResRadioId',
      'Name',
      'StreetName',
      'StreetNumber',
      'PostalCode',
      'CityName',
      'CityINSEECode',
      'DepartmentName',
      'DepartmentINSEECode',
      'RegionName',
      'RegionINSEECode',
      'TerritoryCode',
      'EPSG_Code',
      'CoordX',
      'CoordY',
      'SiteId',
      'Deleted'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return GeoSectorComplement_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public GeoSectorId: number = 0;
  public GeoSectorImpId: number = 0;
  public ResRadioId: number = 0;
  public Name: string = '';
  public StreetName: string = '';
  public StreetNumber: string = '';
  public PostalCode: string = '';
  public CityName: string = '';
  public CityINSEECode: string = '';
  public DepartmentName: string = '';
  public DepartmentINSEECode: string = '';
  public RegionName: string = '';
  public RegionINSEECode: string = '';
  public TerritoryCode: string = '';
  public EPSG_Code: number = 0;
  public CoordX: number = 0;
  public CoordY: number = 0;
  public SiteId: number = 0;
  public Deleted: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
