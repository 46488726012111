import { Component, OnInit } from '@angular/core';
import { AddinView } from '@techwan/ionic-core';

@AddinView({
  name: 'MediaMessagesView'
})
@Component({
  selector: 'app-media-messages-view',
  templateUrl: './media-messages-view.component.html',
  styleUrls: ['./media-messages-view.component.scss']
})
export class MediaMessagesViewComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
