import { Component, Input } from '@angular/core';
import { Guid } from '@models/guid';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';

@Component({
  selector: 'app-unit-activity-list',
  templateUrl: './unit-activity-list.component.html',
  styleUrls: ['./unit-activity-list.component.scss']
})
export class UnitActivityListComponent {
  @Input()
  units: UnitActivity[];

  openedUnit: Guid;

  constructor() {}

  onOpen($event) {
    this.openedUnit = this.openedUnit === $event ? null : $event;
  }
}
