// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 13:30:58
import * as SagaBase from '../../../SagaBase';
import { CPersonBase } from '../../../SagaSchema/CPersonBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CPersonHistory_abstract extends CPersonBase {
  static $t: string = 'SagaSchema.History.CPersonHistory';
  $t: string = 'SagaSchema.History.CPersonHistory';

  public static GetMembers(): string[] {
    return ['StampOperation', 'SourceUid', 'SourceIdentifier'].concat(CPersonBase.GetMembers());
  }

  public GetMembers(): string[] {
    return CPersonHistory_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public StampOperation: SagaBase.Interfaces.StampProviderOperation = SagaBase.Interfaces.StampProviderOperation.Add;
  public SourceUid: Guid = GuidTools.Empty;
  public SourceIdentifier: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
