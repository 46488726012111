// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class GetResourceListRequest_abstract extends BaseObject {
  static $t: string = 'SagaBase.GetResourceListRequest';
  $t: string = 'SagaBase.GetResourceListRequest';

  public static GetMembers(): string[] {
    return ['Type', 'Owner', 'ResourceStatus', 'UnitActivityStatus', 'CallcardId', 'ResEquipmentId', 'EngagementId'];
  }

  public GetMembers(): string[] {
    return GetResourceListRequest_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Type: SagaBase.ResourceType = SagaBase.ResourceType.Undefined;
  public Owner: number = 0;
  public ResourceStatus: number = 0;
  public UnitActivityStatus: number = 0;
  public CallcardId: Guid = GuidTools.Empty;
  public ResEquipmentId: Guid = GuidTools.Empty;
  public EngagementId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
