import { Injectable } from '@angular/core';
import { StatusItemAction } from '@models/imported/SagaBase';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { IStatusChangeRequirement } from '@services/status/status-change-requirements/status-change-requirements.service';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { StatusService } from '@services/status/status.service';
import { TranslatedAlertService } from '@services/translated-alert/translated-alert.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementRequirementService implements IStatusChangeRequirement {
  constructor(private translatedAlert: TranslatedAlertService, private statusService: StatusService) {}

  preChangeRequirement(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    if (this.isAssignment(newStatus)) {
      return this.translatedAlert.show({
        header: 'Mobile.ConfirmRequest',
        message: 'Mobile.EngageToMission',
        buttons: [
          {
            text: 'Mobile.No',
            role: 'cancel',
            value: false
          },
          {
            text: 'Mobile.Yes',
            value: true
          }
        ]
      });
    } else {
      return Promise.resolve(true);
    }
  }

  private isAssignment(status: ListResourceStatus): boolean {
    return (
      status === this.statusService.getStatusByAction(StatusItemAction.isAssignement) ||
      status === this.statusService.getStatusByAction(StatusItemAction.isReassignment)
    );
  }

  postChangeRequirement(newStatus: ListResourceStatus, callcard?: CallCard): Promise<boolean> {
    return Promise.resolve(true);
  }

  getStatusParameters(): StatusParameters {
    return {};
  }
}
