import { CurrentCardService, MyCardService, SoundService } from '@addins/core/core';
import { MenuState, SideMenuControllerService } from '@addins/core/menus';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';
import { CacheEvent } from '@services/cache/cacheEvent';
import { SagaSettingsService } from '@services/settings';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CallcardMenuEntry } from '../../schema/callcard-menu-entry';
import { ProfileParserService } from '../profile-parser/profile-parser.service';

@Injectable()
export class CallcardMenuControllerService {
  private menuEntry: CallcardMenuEntry;

  private subs: Subscription[] = [];

  constructor(
    private profileParser: ProfileParserService,
    private currentCardService: CurrentCardService,
    private myCardService: MyCardService,
    private myUnitService: MyUnitService,
    private sideMenuControllerService: SideMenuControllerService,
    private cacheService: CacheService,
    private soundService: SoundService,
    private sagaSettings: SagaSettingsService
  ) {}

  setup(menuEntry: CallcardMenuEntry) {
    this.menuEntry = menuEntry;

    this.myCardService.setup();

    this.myCardService.$ready.pipe(filter(isReady => isReady)).subscribe(() => {
      this.clearSubscriptions();
      this.onCardServiceReady();
    });
  }

  private clearSubscriptions() {
    while (this.subs.length) {
      this.subs.pop().unsubscribe();
    }
  }

  private onCardServiceReady(): void {
    this.subs.push(this.cacheService.listenForChange(CallCard).subscribe(event => this.onCallcardChanged(event)));

    if (!this.profileParser.shouldSelectCurrentCard()) {
      this.menuEntry.type = this.profileParser.getCardView();
      this.sideMenuControllerService.setup();
    }

    if (this.profileParser.selectMyCardOnEngage()) {
      this.subs.push(
        this.myCardService.$change
          .pipe(filter(myCard => !!myCard && !this.menuEntry.isActive))
          .subscribe(myCard => this.menuEntry.click(null))
      );
    }

    this.subs.push(
      this.myUnitService.mine.$changed.subscribe(() => {
        this.onMyUnitChanged();
      })
    );
    this.onMyUnitChanged();
  }

  private onCallcardChanged(cacheEvent: CacheEvent) {
    const callcard: CallCard = cacheEvent.object as CallCard;
    if (callcard && callcard.ObjGuid === this.myCardService.myCardId) {
      if (this.sagaSettings.getValue('SagaMobileWebClient.PlaySoundWhenCallCardUpdated') === true) {
        this.soundService.playNotification();
      }
    }
  }

  private onMyUnitChanged() {
    if (this.menuEntry && !this.myUnitService.isEngaged()) {
      this.disableCallcardMenu();
    } else if (this.menuEntry) {
      this.enableCallcardMenu();
    }
  }

  private disableCallcardMenu() {
    if (this.profileParser.shouldResetCurrentCard()) {
      this.currentCardService.select(null);
    }
    this.menuEntry.disable();
  }

  private enableCallcardMenu() {
    this.menuEntry.enable();
    if (this.profileParser.shouldSelectCurrentCard()) {
      this.menuEntry.setState(MenuState.active);
      this.selectCurrentCard();
    }
  }

  private selectCurrentCard(): void {
    if (!this.currentCardService.current) {
      this.currentCardService.select(this.myCardService.myCard);
    } else {
      this.menuEntry.notifyAttention();
    }
  }
}
