import { FileBridgeService } from '@addins/core/file';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { MediaType } from '@models/imported/SagaBase';
import { TranslateService } from '@ngx-translate/core';
import { SagaSettingsService } from '@services/settings';
import { scaleImage } from '@utils/image';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';

@Injectable()
export class FileReaderService {
  private readonly TOAST_DURATION: number = 3000;

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService,
    private sagaSettings: SagaSettingsService,
    private fileBridge: FileBridgeService
  ) {}

  readFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) =>
      this.fileBridge
        .readFileAsBuffer(file)
        .then(buffer => resolve(buffer))
        .catch(fileError => this.onError(fileError, reject))
    );
  }

  readFromInput(files: FileList): Promise<ICustomFile> {
    return this.read(files[0]);
  }

  private read(file: File): Promise<ICustomFile> {
    const fileMaxSize: number = this.sagaSettings.getValue('SagaMobileWebClient.FileMaxSize') || Infinity;
    const imageResizeLimit: number = this.sagaSettings.getValue('SagaMobileWebClient.ImageResizeLimit') || Infinity;

    return new Promise((resolve, reject) => {
      const customFile: ICustomFile = this.createCustomFile(file);
      if (file.size > fileMaxSize && customFile.dataType != MediaType.Image) {
        this.toastController
          .create({
            message: this.translateService.instant('Saga.File.MaxSize'),
            duration: this.TOAST_DURATION
          })
          .then(toast => toast.present());
      } else {
        const fileReader = new FileReader();
        fileReader.onerror = fileError => this.onError(fileError, reject);
        fileReader.onload = (result: any) => {
          if (customFile.dataType === MediaType.Image && file.size > imageResizeLimit) {
            return scaleImage(result.target.result, imageResizeLimit).then(base64 => {
              this.createImageFile(base64, file.name, file.type).then(file => {
                customFile.file = file;
                this.setCustomFileData(customFile, base64);
                return resolve(customFile);
              });
            });
          } else {
            this.setCustomFileData(customFile, result.target.result);
            return resolve(customFile);
          }
        };
        fileReader.readAsDataURL(file);
      }
    });
  }

  private createCustomFile(file: File): ICustomFile {
    let mediaType: MediaType = MediaType.Document;

    if (file.type.startsWith('image')) {
      mediaType = MediaType.Image;
    } else if (file.type.startsWith('video')) {
      mediaType = MediaType.Video;
    }

    return { file, parent: null, mimeType: file.type, dataType: mediaType };
  }

  private onError(e: ProgressEvent, reject: Function) {
    this.toastController
      .create({
        message: this.translateService.instant('FileReader.Error'),
        duration: this.TOAST_DURATION
      })
      .then(toast => toast.present());
    return reject(e);
  }

  private createImageFile(imageBase64: string, fileName, fileType: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = function() {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(blob => {
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        }, fileType);
      };
      image.onerror = error => reject(error);

      image.src = imageBase64;
    });
  }

  private setCustomFileData(customFile: ICustomFile, base64: string) {
    const [match, type, data] = base64.match(/data:([^;]*);base64,(.*)/);
    customFile.url = base64;
    customFile.data = data || null;
  }
}
