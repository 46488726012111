import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItineraryStep } from '@services/map';
import { ItineraryProviderService } from '../../services/itinerary-provider/itinerary-provider.service';

@Component({
  templateUrl: 'itinerary-steps.component.html',
  styleUrls: ['itinerary-steps.component.scss']
})
export class ItineraryStepsComponent {
  get steps(): ItineraryStep[] {
    return (this.itineraryProviderService.getLastItinerary() && this.itineraryProviderService.getLastItinerary().steps) || [];
  }

  constructor(private modalController: ModalController, private itineraryProviderService: ItineraryProviderService) {}

  close() {
    this.modalController.dismiss();
  }

  stepClicked(step: ItineraryStep) {
    this.modalController.dismiss(step);
  }
}
