import { Tool } from '@services/tools';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { Observable, Subject } from 'rxjs';

export class MediaMessageTool extends Tool {
  private readonly _executor = new Subject<void>();
  public get executor(): Observable<void> {
    return this._executor.asObservable();
  }

  public target: CallCard;

  public constructor() {
    super('SagaSchema.MediaMessage', 'text', '', 0, ctx => this._executor.next());
  }
}
