import { IFile } from '@ionic-native/file/ngx';
import { Guid } from '@models/guid';
import { FileDescriptor } from '@models/imported/SagaSchema/FileDescriptor';
import { SagaFileUploadItem } from './saga-file-upload-item';

export class SagaFileEntryUploadItem extends SagaFileUploadItem {
  /**
   * File path on local filesystem
   */
  protected readonly _localURL: string;
  get localURL(): string {
    return this._localURL;
  }

  constructor(_file: IFile, _fileDescriptor: FileDescriptor, _callcardId: Guid) {
    super(_file as any, _fileDescriptor, _callcardId);
    this._localURL = _file.localURL || null;
  }
}
