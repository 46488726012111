<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span translate>static.translations</span>
    </ion-title>
    <ion-buttons slot="end">
      <div status-bar></div>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary" no-border-top>
    <div style="width: 100%;display: flex;align-items: center">
      <ion-searchbar style="flex: 1" (ionInput)="onSearch($event)" placeholder="{{ 'static.search' | translate }}"></ion-searchbar>
      <ion-icon style="font-size: 2em" name="download" (click)="download()"></ion-icon>
      <ion-icon
        style="padding: 0 20px;font-size: 2em;"
        name="funnel"
        [color]="showOnlyNull ? 'danger' : 'light'"
        (click)="showOnlyNull = !showOnlyNull; onSearch()"
      ></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let t of translations" (click)="translateItem(t)">
      <h2>{{ t.key }}</h2>
      <p>{{ t.value }}</p>
    </ion-item>
  </ion-list>
</ion-content>
