import { Injectable } from '@angular/core';
import { GuidTools } from '@models/guid';
import { CommunicationEquipment_abstract } from '@models/imported/abstract/SagaSchema/CommunicationEquipment_abstract';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { Actor } from '@models/imported/SagaSchema/Actor';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Vehicle } from '@models/imported/SagaSchema/Vehicle';
import { CacheService } from '@services/cache/cache.service';
import { Guid } from '@techwan/ionic-core/lib/schema/Guid';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IChildAwareResource } from '../../schema/interfaces/IChildAwareResource';
import { UnitActivityOnMap } from '../../schema/unit-activity-on-map';
import { UnitResourceProxyService, UnitResources } from '../unit-resource-proxy/unit-resource-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class UnitResourceProviderService {
  private readonly unitResourcesCache = new Map<Guid, UnitResources>();

  private readonly unitPositions = new Map<Guid, UnitActivityOnMap>();

  private readonly _unitPositionChangedEvent = new Subject<Guid>();
  get $unitPositionChangedEvent(): Observable<Guid> {
    return this._unitPositionChangedEvent.asObservable();
  }

  private readonly _unitChangedEvent = new Subject<Guid>();
  get $unitChangedEvent(): Observable<Guid> {
    return this._unitChangedEvent.asObservable();
  }

  constructor(private unitResourceProxy: UnitResourceProxyService, private cacheService: CacheService) {
    this.cacheService.listenForChange(UnitActivity).subscribe(cacheEvent => this.onUnitChanged(cacheEvent.object as UnitActivity));
  }

  private onUnitChanged(unitActivity: UnitActivity) {
    if (this.unitResourcesCache.has(unitActivity.ObjGuid)) {
      this.unitResourcesCache.delete(unitActivity.ObjGuid);
      this._unitChangedEvent.next(unitActivity.ObjGuid);
    }
  }

  static getResourceIconName(resourceTypeName: string): string {
    switch (resourceTypeName) {
      case Actor.$t:
        return 'person';
      case Vehicle.$t:
        return 'assets/icons/unit-resources/resource-vehicle.svg';
      case CommunicationEquipment_abstract.$t:
        return 'assets/icons/unit-resources/resource-communication-equipment.svg';

      default:
        return 'person';
    }
  }

  getResourcesFor(unit: Guid): Observable<IChildAwareResource[]> {
    return this.get(unit).pipe(map(({ resources }) => this.getResources(resources)));
  }

  private get(id: Guid): Observable<UnitResources> {
    return this.unitResourcesCache.get(id)
      ? of(this.unitResourcesCache.get(id))
      : this.unitResourceProxy.getResourcesForUnit(id).pipe(tap(resForUnit => this.set(id, resForUnit)));
  }

  private set(id: Guid, resForUnit: UnitResources): void {
    this.unitResourcesCache.set(id, resForUnit);
  }

  private getResources(resIn: SagaObject[]): IChildAwareResource[] {
    const children: CommunicationEquipment[] = [];
    const resources = [];
    resIn.forEach(resource => {
      if (
        resource instanceof CommunicationEquipment &&
        GuidTools.isValid(resource.EquipmentConsumerGuid) &&
        resIn.find(r => r.ObjGuid === resource.EquipmentConsumerGuid)
      ) {
        children.push(resource);
      } else {
        resources.push({ resource, children: [] });
      }
    });

    while (children.length > 0) {
      children.forEach((child, index) => {
        const parent = this.findParent(child.EquipmentConsumerGuid, resources);

        if (parent) {
          parent.children.push({ resource: child, children: [] });
          children.splice(index, 1);
        }
      });
    }
    return resources;
  }

  private findParent(childId: Guid, resources: IChildAwareResource[]) {
    for (let i = 0; i < resources.length; i++) {
      const res = resources[i];

      if (res.resource.ObjGuid === childId) {
        return res;
      }

      if (res.children && res.children.length > 0) {
        const childParent = this.findParent(childId, res.children);
        if (childParent !== null) {
          return childParent;
        }
      }
    }

    return null;
  }

  getPositionFor(unit: Guid): ol.Coordinate {
    return this.unitPositions.get(unit) ? this.unitPositions.get(unit).position : null;
  }

  getMobilePositions(): Observable<UnitActivityOnMap[]> {
    return this.unitResourceProxy.getMobilePositions().pipe(tap(mobilePositions => this.checkValues(mobilePositions)));
  }

  private checkValues(mobilePositions: UnitActivityOnMap[]) {
    const toRemove = Array.from(this.unitPositions.keys());
    this.unitPositions.clear();
    mobilePositions.forEach(unitActivityOnMap => {
      const idx = toRemove.indexOf(unitActivityOnMap.id);
      this.unitPositions.set(unitActivityOnMap.id, unitActivityOnMap);
      if (idx > -1) {
        toRemove.splice(idx, 1);
      }
    });
    toRemove.forEach(unitActivityOnMap => this._unitPositionChangedEvent.next(unitActivityOnMap));
    mobilePositions.forEach(unitActivityOnMap => this._unitPositionChangedEvent.next(unitActivityOnMap.id));
  }
}
