import { PanelTool } from './panel-tool';
import { PanelContext } from './panel-context';

export enum ContextChangedEventType {
  contentChanged,
  contentReset,
  contentLoaded
}

export interface ContextChangedEvent {
  type: ContextChangedEventType;
  context: PanelContext;
  tool: PanelTool;
}
