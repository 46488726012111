// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:14
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CopyScheduleArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.CopyScheduleArgs';
  $t: string = 'SagaBase.Defines.CopyScheduleArgs';

  public static GetMembers(): string[] {
    return ['Schedule', 'Mode', 'ListResources', 'SiteId'];
  }

  public GetMembers(): string[] {
    return CopyScheduleArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Schedule: any;
  public Mode: SagaBase.ScheduleCopyMode = SagaBase.ScheduleCopyMode.Resource;
  public ListResources: any[];
  public SiteId: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
