import { JournalModalComponent } from '@addins/singlecallcard-journal';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Task } from '@models/imported/SagaSchema/Task';

@Component({
  selector: 'app-task-journal-button',
  templateUrl: './task-journal-button.component.html',
  styleUrls: ['./task-journal-button.component.scss']
})
export class TaskJournalButton {
  @Input()
  task: Task;

  constructor(private modalController: ModalController) {}

  showJournal() {
    this.modalController
      .create({
        component: JournalModalComponent,
        componentProps: { title: this.task.Name, callcard: this.task },
        cssClass: 'fullscreen-modal'
      })
      .then(modal => modal.present());
  }
}
