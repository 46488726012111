import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LayoutService } from '@services/layout/layout.service';
import { AddinView } from '@techwan/ionic-core';
import { MapFullViewBaseComponent } from '../../components/full-map-view-base/map-full-view-base.component';

/**
 * @description
 * A view for the Saga Mapping System
 */
@AddinView({
  name: 'FullNoToc',
  description: 'A view for the Saga Mapping System'
})
@Component({
  selector: 'app-map-full-no-toc-view',
  templateUrl: './map-full-no-toc-view.component.html',
  styleUrls: ['../map-full-view/map-full-view.component.scss']
})
export class MapFullNoTocViewComponent extends MapFullViewBaseComponent implements OnInit {
  constructor(platform: Platform, _layout: LayoutService) {
    super(platform, _layout);
  }

  ngOnInit() {}
}
