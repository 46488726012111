import { CallcardUnitsProviderService } from '@addins/core/unit-activity';
import { Component } from '@angular/core';

@Component({
  selector: 'app-unit-count-card',
  templateUrl: './unit-count-card.component.html',
  styleUrls: ['./unit-count-card.component.scss']
})
export class UnitCountCardComponent {
  get unitCount(): number {
    return this.callcardUnitsProvider.units.length;
  }

  constructor(private callcardUnitsProvider: CallcardUnitsProviderService) {}
}
