import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'textStampModal.html'
})
export class TextStampModalComponent {
  text: string;
  constructor(private viewCtrl: ModalController) {}

  save() {
    this.viewCtrl.dismiss({ text: this.text });
  }
}
