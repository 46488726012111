import { Injectable } from '@angular/core';
import { SagaObject } from '@models/imported/SagaBase/SagaObject';
import { ImagesSettingsService } from '@services/settings';
import { Feature, ObjectFeature, RectangleTextBackground, TextPosition } from '@techwan/mapping';
import { DEFAULT_IMAGE_SIZE, calcImageScale } from '@utils/image';

@Injectable({
  providedIn: 'root'
})
export class FeatureStyleService {
  constructor(private images: ImagesSettingsService) {}

  createLabel(feature: Feature, text: string, color: string, textColor: number[] = [255, 255, 255, 1]) {
    feature.setLabel({
      text,
      textBackground: new RectangleTextBackground({
        fill: {
          color
        },
        textColor
      }),
      position: TextPosition.Top
    });
  }

  applyFeatureStyle(feature: ObjectFeature, object: SagaObject, icon?: string) {
    this.createText(feature, object);
    let image;
    icon = icon || object.icon;
    if (icon.includes('/')) {
      image = icon;
    } else if (!icon.startsWith('md-')) {
      image = `svg/md-${icon}.svg`;
    } else {
      icon = icon.replace(/^md-/, '').trim();
    }

    if (image) {
      feature.setImageUrl(image);
      calcImageScale(image, DEFAULT_IMAGE_SIZE[0]).then(scale => {
        feature.setImageScale(scale[0]);
        feature.changed();
      });
    } else {
      const color = object.color ? object.color.toArray() : null;
      feature.setIcon({
        icon,
        font: 'Material Icons',
        color,
        size: 24
      });
    }
  }

  applyCardStyle(feature: ObjectFeature, callcard: SagaObject, name: string, color: string, isMine: boolean) {
    const image = this.images.get(isMine ? 'CallcardRed' : 'CallcardBlue');
    if (image) {
      this.createLabel(feature, name, color);
      feature.setFillColor([255, 255, 255, 0]);
      feature.setImageUrl(image);
      calcImageScale(image, DEFAULT_IMAGE_SIZE[0]).then(scale => {
        // TODO: An offset must be added to put the pin on the right pixel.
        feature.setImageScale(scale[0]);
        feature.changed();
      });
    } else {
      this.applyFeatureStyle(feature, callcard);
    }
  }

  myLocationStyle(feature: ObjectFeature, text: string, object?: SagaObject) {
    const url = this.images.get('DownIndicator');
    if (text) {
      feature.setLabel({ text });
    }
    feature.setStrokeColor([255, 255, 255, 0]);
    feature.setFillColor([255, 255, 255, 0]);
    if (url) {
      calcImageScale(url, DEFAULT_IMAGE_SIZE[0]).then(scale => {
        feature.setImageUrl(url);
        feature.setImageScale(scale[0]);
        feature.changed();
      });
    } else if (object) {
      this.applyFeatureStyle(feature, object);
    } else {
      feature.setIcon({
        icon: 'radio_button_checked',
        font: 'Material Icons',
        color: [0, 0, 255],
        size: 24
      });
    }
  }

  private createText(feature: ObjectFeature, object: SagaObject) {
    const text = feature.createText(object.rShort());
    text.setOffset([0, 25]);
    text.setId('label');
    text.setOutlineColor([255, 255, 255, 0.9], 7);
    return text;
  }
}
