// Helper component to add dynamic components
import { ChangeDetectorRef, Component, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'component-loader',
  template: `
    <div #target></div>
  `
})
export class ComponentLoaderComponent {
  @ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
  @Input() component: any;
  @Input() data: any;
  cmpRef: ComponentRef<any>;
  private isViewInitialized: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  updateComponent() {
    if (!this.component) {
      return;
    }
    if (!this.isViewInitialized) {
      return;
    }

    if (this.cmpRef) {
      this.target.insert(this.cmpRef.hostView);
    } else {
      this.cmpRef = this.target.createComponent(this.component);
    }

    // to access the created instance use
    this.cmpRef.instance.data = this.data;
    // this.card.cmpRef.instance.someProperty = 'someValue';
    // this.card.cmpRef.instance.someOutput.subscribe(val => doSomething());
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: any) {
    this.updateComponent();
  }

  ngAfterViewInit() {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }
}
