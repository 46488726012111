import { CoreModule } from '@addins/core/core';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SagaDirectivesModule } from '@app/directives';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { NoteEditorComponent } from './components/note-editor/note-editor.component';
import { EventFactoryService } from './services/event-factory/event-factory.service';
import { EventsProviderService } from './services/events-provider/events-provider.service';
import { EventListService } from './services/executables/event-list/event-list.service';
import { ResultParserService } from './services/result-parser/result-parser.service';
import { CallcardCreateComponent } from './views/callcard-create/callcard-create.component';
import { EventListAccessComponent } from './views/event-list-access/event-list-access.component';

const views = [CallcardCreateComponent, EventListAccessComponent];

const components = [NoteEditorComponent, ...views];

const directives = [];

const executables = [EventListService];

const providers = [EventsProviderService, EventFactoryService, ResultParserService, ...executables];

@Addin({
  name: 'CreateEvent'
})
@NgModule({ 
  providers,
  imports: [IonicModule, CommonModule, CoreModule, FormsModule, SagaDirectivesModule, TranslateModule],
  declarations: [...components, ...directives]
})
export class CreateEventModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
