import { MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CFormLink } from '@models/imported/SagaSchema/CFormLink';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction } from '@services/cache/cacheEvent';
import { ToastColor, ToastDuration, ToastService } from '@services/toast/toast.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SagaFormsToastService {
  constructor(private cacheService: CacheService, private myCardService: MyCardService, private toastService: ToastService) {}

  setup() {
    this.cacheService
      .listenForChange(CFormLink)
      .pipe(filter(cacheEvent => (cacheEvent.object as CFormLink).CallCardId === this.myCardService.myCardId))
      .subscribe(event => this.onMyCardFormChanged(event.object as CFormLink, event.action));
  }

  private onMyCardFormChanged(cFormLink: CFormLink, action: CacheAction) {
    let messageKeySuffix: string;
    switch (action) {
      case CacheAction.added:
        messageKeySuffix = 'NewFormCreated';
        break;
      case CacheAction.modified:
        messageKeySuffix = 'FormUpdated';
        break;
      case CacheAction.removed:
        messageKeySuffix = 'FormDeleted';
        break;
    }
    this.toastService.show(`Mobile.Forms.${messageKeySuffix}`, ToastColor.Info, { formName: cFormLink.FormName }, ToastDuration.Long);
  }
}
