// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:42
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CrisisMessage_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CrisisMessage';
  $t: string = 'SagaSchema.CrisisMessage';

  public static GetMembers(): string[] {
    return [
      'InfoUTC',
      'CallCardId',
      'PertinenceId',
      'ClassificationId',
      'Locate',
      'X',
      'Y',
      'From',
      'Counter1',
      'Counter2',
      'QuittanceId',
      'Qualification',
      'Importance',
      'DestGeoSector',
      'DestinationType',
      'SubEvent',
      'Json',
      'OriginalCallCardId',
      'MessageUid',
      'LinkedComponents',
      'Comment',
      'TraceId',
      'IsArchived',
      'IsFromOffline',
      'Tags',
      'ShowInDashboard'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CrisisMessage_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.InfoUTC = this.InfoUTC && new Date(this.InfoUTC);

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.CrisisAttachedFileInfo', 'SagaSchema.CrisisMessageLocation'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public InfoUTC: Date = new Date();
  public CallCardId: Guid = GuidTools.Empty;
  public PertinenceId: number;
  public ClassificationId: number = 0;
  public Locate: boolean = false;
  public X: number = 0;
  public Y: number = 0;
  public From: string;
  public Counter1: number = 0;
  public Counter2: number = 0;
  public QuittanceId: number;
  public Qualification: string;
  public Importance: boolean = false;
  public DestGeoSector: string;
  public DestinationType: string;
  public SubEvent: string;
  public Json: string;
  public OriginalCallCardId: Guid = GuidTools.Empty;
  public MessageUid: Guid = GuidTools.Empty;
  public LinkedComponents: string;
  public Comment: string;
  public TraceId: Guid = GuidTools.Empty;
  public IsArchived: boolean = false;
  public IsFromOffline: boolean = false;
  public Tags: string;
  public ShowInDashboard: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
