import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { SagaFormsCollapsibleService } from './services/executables/saga-forms-collapsible/saga-forms-collapsible.service';
import { SagaFormsSetupService } from './services/executables/saga-forms-setup/saga-forms-setup.service';
import { SagaFormsCollapsibleViewComponent } from './views/saga-forms-collapsible-view/saga-forms-collapsible-view.component';
import { SagaFormsViewComponent } from './views/saga-forms-view/saga-forms-view.component';

export const SAGA_FORMS_ADDIN_NAME = 'SagaForms';

const views = [SagaFormsViewComponent, SagaFormsCollapsibleViewComponent];

const components = [...views];

const executables = [SagaFormsSetupService, SagaFormsCollapsibleService];

const providers = [...executables];

@Addin({
  name: SAGA_FORMS_ADDIN_NAME,
  require: ['singlecallcard']
})
@NgModule({
  declarations: [...components],
  entryComponents: components,
  providers,
  imports: [CommonModule, IonicModule, TranslateModule, SagaDirectivesModule, SagaPipeModule]
})
export class SagaFormsModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, views, executables);
  }
}
