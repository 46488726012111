import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MobEvents } from '@models/imported/TW/Saga/Core/Base/Mobile/MobEvents';
import { PhoneCreateEventRes } from '@models/imported/SagaBase/PhoneCreateEventRes';
import { ObjectFactory } from '@services/object-factory/object-factory.service';
import { CreateEventRequest } from '../../schema/interfaces/create-event-request';
import { ICreateEventResult } from '../../schema/interfaces/create-event-result';
import { ICreateCardRequest } from '../../schema/interfaces/create-card-request';
import { ResultParserService } from '../result-parser/result-parser.service';

@Injectable()
export class EventsProviderService {
  private readonly _ready = new BehaviorSubject(false);
  private readonly _events: MobEvents[] = [];

  private _emergency: MobEvents = null;

  private _request: Subscription = null;

  constructor(
    private readonly _http: HttpClient,
    private readonly _factory: ObjectFactory,
    private readonly _parser: ResultParserService
  ) {}

  public create(data: ICreateCardRequest, timestamp: number): Observable<PhoneCreateEventRes> {
    return this._http.post<PhoneCreateEventRes>('api/Event/CreateCard', data).pipe(tap(res => this._parser.parse(res, timestamp)));
  }

  public createCard(request: CreateEventRequest): Observable<ICreateEventResult> {
    return this._http.post<ICreateEventResult>('api/Event/CreateEvent', request);
  }

  public getEmergency(): MobEvents {
    return this._emergency;
  }

  public getEvents(): MobEvents[] {
    return this._events;
  }

  public whenReady(): Observable<boolean> {
    if (this.isReady() === false && this._request === null) {
      this._request = this._http.get<MobEvents[]>('api/Event/GetMobileEvents').subscribe(i => this.parse(i));
    }
    return this._ready.asObservable();
  }

  protected isReady(): boolean {
    let ready: boolean;
    this._ready.subscribe(r => (ready = r)).unsubscribe();
    return ready;
  }

  private parse(i: MobEvents[]) {
    const list: MobEvents[] = this._factory.createList(i);
    this._emergency = list.find(item => item.Isemergency === true) || null;
    list.filter(item => item.Isemergency === false).forEach(item => this._events.push(item));
    this._ready.next(true);
  }
}
