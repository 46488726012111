import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ModalButtonImpl } from '@addins/core/core';

@Component({
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent implements OnInit, OnDestroy {
  public readonly title: string;
  public readonly text: string;
  public readonly buttons: ModalButtonImpl[];

  private _subs: Subscription[] = null;

  constructor(private readonly _modal: ModalController) {}

  public ngOnInit() {
    if (this.buttons) {
      this._subs = this.buttons.map(b => b.$event.subscribe(() => this.close(b)));
    }
  }

  public ngOnDestroy() {
    if (this._subs !== null) {
      while (this._subs.length > 0) {
        this._subs.pop().unsubscribe();
      }
    }
  }

  private close(b: ModalButtonImpl) {
    this._modal.dismiss(b.text);
  }
}
