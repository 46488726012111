<ion-header *ngIf="cards">
  <app-callcard-list-header></app-callcard-list-header>
  <app-callcard-list-filter [callcards]="cards"></app-callcard-list-filter>
  <ion-item-divider *ngIf="myCard" [class.myCard]="showMySector">
    <ion-label><strong translate>Mobile.MyCardTitle</strong></ion-label>
  </ion-item-divider>
  <app-callcard-list-item
    *ngIf="myCard"
    [callcard]="myCard"
    (click)="open(myCard)"
    isMyCallcard="true"
    [cssClass]="showMySector ? 'myCard' : ''"
  ></app-callcard-list-item>
</ion-header>

<ion-content *ngIf="cards">
  <ion-list>
    <ng-container *ngFor="let cardGroup of cardGroups">
      <ion-item-divider sticky [class]="cardGroup.cssClass">
        <ion-label>
          <strong translate>{{ cardGroup.name }}</strong>
        </ion-label>
        <ion-badge *ngIf="showCallCardCount" slot="end">{{ cardGroup.cards.length }}</ion-badge>
      </ion-item-divider>
      <ng-container *ngFor="let callcard of cardGroup.cards">
        <app-callcard-list-item [callcard]="callcard" (click)="open(callcard)" [cssClass]="cardGroup.cssClass"></app-callcard-list-item>
      </ng-container>
    </ng-container>
  </ion-list>
</ion-content>

<div class="cache-loader" *ngIf="!cards">
  <ion-spinner></ion-spinner>
  <div class="cache-loader-label" translate>SagaMobile.Cache.Loading</div>
</div>
