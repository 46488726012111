// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class MapLayer_abstract extends PersistentObject {
  static $t: string = 'SagaBase.MapLayer';
  $t: string = 'SagaBase.MapLayer';

  public static GetMembers(): string[] {
    return ['IsDefault', 'MapLayerId', 'MapLayerName', 'IdFieldName', 'DescriptionFieldName', 'AgenciesString'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return MapLayer_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public IsDefault: boolean = false;
  public MapLayerId: number = 0;
  public MapLayerName: string;
  public IdFieldName: string;
  public DescriptionFieldName: string;
  public AgenciesString: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
