import { Tool } from '@services/tools';
import { Observable, Subject } from 'rxjs';
import { Context } from './gallery-access-tool';

export class FileAccessTool extends Tool {
  private readonly _event = new Subject<Context>();
  public get executor(): Observable<Context> {
    return this._event.asObservable();
  }

  public constructor() {
    super('Saga.AttachmentType.File', 'document', '', 0, ctx => this._event.next(ctx));
  }
}
