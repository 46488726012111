import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { ResourcePositionLight } from '../../../../models/imported/SagaBase/ResourcePositionLight';
import { ActivityStatusType } from '../../../../models/imported/SagaBase/Interfaces/index';
import { EquipmentPhoneSelectComponent } from './equipmentPhoneSelect';
import { NavService } from '@services/navigation/navigation.service';

@Component({
  templateUrl: 'vehicleContactViewer.html'
})
export class VehicleContactViewerComponent {
  public vehicles: Array<ResourcePositionLight> = [];
  constructor(
    private viewCtrl: NavService,
    private modal: ModalService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private unitActivity: UnitActivityService
  ) {
    let context = <Array<any>>this.navParams.get('context');
    context.forEach(item => {
      if (item instanceof ResourcePositionLight && (<ResourcePositionLight>item).ActivityStatus == ActivityStatusType.Active) {
        this.vehicles.push(item);
      }
    });
  }

  close() {
    this.viewCtrl.get().pop();
  }

  async loadInfo(item: ResourcePositionLight) {
    //this.navParams.get('CallCard');
    this.modal.presentLoading('SagaMobile.vehicleContactViewer.loading');

    //let resource:ResourcePositionLight = context[0];
    let infos = await this.unitActivity.getRessourceInformation(item.ObjId);
    await this.modal.dismissLoading();

    if (infos.length > 0) {
      // TODO: @deprecated please use ModalFactoryService instead
      this.modalCtrl
        .create({
          component: EquipmentPhoneSelectComponent,
          componentProps: { vehicle: item, infos: infos }
        })
        .then(m => m.present());
    }
  }
}
