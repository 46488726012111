// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CommunicationEquipment_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CommunicationEquipment';
  $t: string = 'SagaSchema.CommunicationEquipment';

  public static GetMembers(): string[] {
    return [
      'Name',
      'Description',
      'Identifier',
      'AssociationCategory',
      'CommunicationEquipmentType',
      'PhoneNumber',
      'IsEnabled',
      'HasGPS',
      'EquipmentConsumerGuid',
      'ActivityUid',
      'ActivityStatus',
      'Status',
      'CallCardID',
      'IsLoginResource',
      'Language',
      'Password',
      'ResetPassword',
      'RequireTouchID',
      'LoginCount',
      'DefaultSchedule'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CommunicationEquipment_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  components(): string[] {
    return ['SagaSchema.ResourcePosition'];
  }

  private _components = {};

  component<T>(obj: any): Array<T> {
    let name: string;

    if (typeof obj === 'string') name = <string>obj;
    else if (obj.$t) name = obj.$t;
    else return [];

    if (!this._components[name]) {
      if (this.components().indexOf(name) >= 0) this._components[name] = [];
    }
    return this._components[name];
  }

  public add(obj: any): void {
    var compList = this.component<any>(obj.$t);

    // Only list elements can be added.
    for (var i = 0; i < compList.length; i++)
      if (compList[i].ObjId == obj.ObjId) {
        compList[i] = obj;
        return;
      }
    compList.push(obj);
    obj.ParentObjId = (<any>this).ObjId;
  }

  public remove(obj: any): void {
    if (obj == null) return;

    var compObj = <Array<any>>this.component<any>(obj.$t);
    if (compObj != null) {
      var index = compObj.indexOf(obj);
      if (index >= 0) compObj.splice(index, 1);
    }
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Name: string;
  public Description: string;
  public Identifier: string;
  public AssociationCategory: SagaBase.EquipmentAssociationCategory = SagaBase.EquipmentAssociationCategory.Singular;
  public CommunicationEquipmentType: SagaBase.CommunicationEquipmentType = SagaBase.CommunicationEquipmentType.Undefined;
  public PhoneNumber: string;
  public IsEnabled: boolean = false;
  public HasGPS: boolean = true;
  public EquipmentConsumerGuid: Guid = GuidTools.Empty;
  public ActivityUid: Guid = GuidTools.Empty;
  public ActivityStatus: SagaBase.Interfaces.ActivityStatusType = SagaBase.Interfaces.ActivityStatusType.Available;
  public Status: number = 0;
  public CallCardID: Guid = GuidTools.Empty;
  public IsLoginResource: boolean = false;
  public Language: string;
  public Password: string;
  public ResetPassword: boolean = false;
  public RequireTouchID: boolean = false;
  public LoginCount: number = 0;
  public DefaultSchedule: SagaBase.DefaultScheduleType = SagaBase.DefaultScheduleType.Available;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return null;
  }
  set ParentObjId(value: any) {}

  /*</AUTO_IMPLEMENTATION>*/
}
