import { NavigateButtonTool } from '@addins/core/singlecallcard/schema/navigate-button-tool';

export class NavigateToTool extends NavigateButtonTool {
  constructor() {
    super('NavigateToDest', 'navigate-sharp', 500);
  }

  click(): void {
    this._onClick.next({ target: null });
  }
}
