<!--<ion-fab-button size="small" slot="icon-only" class="refresh-button" (click)="!callcard.isStampLoading && doRefresh()">
    <ion-icon *ngIf="!callcard.isStampLoading" name="refresh"></ion-icon>
    <ion-spinner *ngIf="callcard.isStampLoading"></ion-spinner>
</ion-fab-button>-->
<ion-list class="followup">
    <!--<div class="followup-feed-line" #timeLine></div>-->
    <ion-item *ngFor="let stamp of callcard.stamps.items; let i = index" class="followup-stamp" (click)="expandStamp(stamp)" [ngClass]="{expanded: stamp.isExpanded}">
        <ion-button slot="end" class="tools" expand="round" fill="clear" *ngIf="getStampToolIcon(stamp) !== null" (click)="$event.stopPropagation(); stampClick(stamp)">
            <ion-icon slot="icon-only" [name]="getStampToolIcon(stamp)"></ion-icon>
        </ion-button>
        <ion-avatar slot="start" [ngClass]="{new: stamp.isNew}">
            <ion-icon [ngStyle]="stamp.iconStyle" [icon]="stamp.icon"></ion-icon>
            <ion-icon *ngIf="stamp.secondaryIcon" [style.color]="stamp.secondaryIconColor" class="secondary-icon" [icon]="stamp.secondaryIcon"></ion-icon>
        </ion-avatar>
        <ion-label>
            <h2 class="stamp-title">{{stamp.CreatedUTC | moment:'LLL'}}&nbsp;&nbsp;<span [translate]="stamp.title"></span></h2>
            <!--<div class="stamp-user">{{stamp.UserName}}</div>-->
            <p *ngIf="!stamp.followupComponent" class="ellipsed">{{stamp.text}}</p>
        </ion-label>
        <component-loader *ngIf="stamp.isExpanded && stamp.followupComponent" [component]="stamp.followupComponent" [data]="stamp"></component-loader>
    </ion-item>
</ion-list>
