import { MapName } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CDeploymentPoint } from '@models/imported/SagaSchema/CDeploymentPoint';
import { MapInstancesService } from '@services/map';
import { GroupName, SagaLayerManager } from '@techwan/ionic-core/map';
import { ExtendedLayer, LayerFactory, Map as SagaMap } from '@techwan/mapping';
import { Observable, Subject, Subscription } from 'rxjs';
import { ILayerController } from '../../schema/interfaces/ILayerController';
import { DeploymentFeatureFactoryService } from '../deployment-feature-factory/deployment-feature-factory.service';
import { MyDeploymentService } from '../executables/my-deployment/my-deployment.service';
import { LocalizePositionService } from '../localize-position/localize-position.service';

const MY_DEPLOYMENT_NAME = 'MyDeployment';

@Injectable()
export class MyDeploymentLayerService implements ILayerController {
  private _sagaMap: SagaMap = null;
  private _myDeploymentLayer: ExtendedLayer = null;

  private _active: Subject<boolean> = new Subject<boolean>();
  get $active(): Observable<boolean> {
    return this._active as Observable<boolean>;
  }

  private _visible: Subject<boolean> = new Subject<boolean>();
  get $visible(): Observable<boolean> {
    return this._visible as Observable<boolean>;
  }

  private subs: Subscription[] = [];

  constructor(
    private _maps: MapInstancesService,
    private _myDeployment: MyDeploymentService,
    private _deploymentPointFactory: DeploymentFeatureFactoryService,
    private _localize: LocalizePositionService
  ) {}

  mapDidChange(map: SagaMap): void {
    if (map && this._sagaMap === null) {
      this._sagaMap = map;
      this.subs.push(this._deploymentPointFactory.setup().subscribe(() => this.init()));
    } else if (!map) {
      this._sagaMap = null;
      this.cleanup();
    }
  }

  private cleanup() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
    if (this._myDeploymentLayer) {
      this._myDeploymentLayer.un('change:visible', this.onChangeVisible, this);
      this._myDeploymentLayer = null;
    }
  }

  private init() {
    const map = this._maps.get(MapName.main);
    const sagaLayerManager = map.getLayerManager() as SagaLayerManager;

    this._myDeploymentLayer = this.createMyDeploymentLayer(map);
    sagaLayerManager.setAutohide(this._myDeploymentLayer);
    this._myDeploymentLayer.on('change:visible', this.onChangeVisible, this);

    const deploymentLayersGroup = sagaLayerManager.getLayerGroup(GroupName.deploymentLayers);
    deploymentLayersGroup.getLayers().insertAt(0, this._myDeploymentLayer);

    this.subs.push(this._myDeployment.$myDeploymentPoint.subscribe(deploymentPoint => this.onMyDeploymentChanged(deploymentPoint)));
  }

  private onChangeVisible(event: ol.ObjectEvent) {
    this._visible.next(this._myDeploymentLayer.getVisible());
  }

  private createMyDeploymentLayer(map: SagaMap): ExtendedLayer {
    const layerFactory = new LayerFactory();
    layerFactory.map = map;
    let myDeploymentLayer = layerFactory.createLayer({ id: GroupName.showMyDeployment, cluster: false });
    myDeploymentLayer.set('name', MY_DEPLOYMENT_NAME);
    myDeploymentLayer.setZIndex(2);

    return myDeploymentLayer;
  }

  private onMyDeploymentChanged(deploymentPoint: CDeploymentPoint): void {
    this.refreshMyDeploymentPoint();
    this._active.next(deploymentPoint !== null);
    this._visible.next(this._myDeploymentLayer.getVisible());
  }

  private refreshMyDeploymentPoint() {
    const source = this._myDeploymentLayer.getSource();
    source.clear();

    if (this._myDeployment.mine !== null) {
      source.addFeature(this._deploymentPointFactory.fromDeployment(this._myDeployment.mine, this._maps.get(MapName.main), true));
      source.changed();
    }
  }

  showDeploymentPoint(show: boolean): void {
    if (this._myDeploymentLayer !== null) {
      this._myDeploymentLayer.setVisible(show);
      if (show) {
        this._localize.centerView(this._maps.get(MapName.main).getView(), this.getMyDeploymentCoordinates());
      }
    }
  }

  private getMyDeploymentCoordinates(): Coordinate {
    const mine = this._myDeployment.mine;
    return { x: mine.CenterX, y: mine.CenterY, epsg: mine.CoordEpsgCode };
  }
}
