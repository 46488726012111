import { CustomModalParameter, ModalButton, ModalFactoryService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { StatusParameters } from '@services/status/status-proxy/status-proxy.service';
import { TerminateMessageSelectionComponent } from '../../components/terminate-message-selection/terminate-message-selection.component';
import { TerminateMessageResult } from '../../schema/interfaces/terminate-message-result';

@Injectable()
export class TerminateMessageModalService {
  private readonly _cancelButton: ModalButton = this._modalFactory.createModalButton({
    text: 'Mobile.Cancel',
    action: 'cancel',
    color: 'danger'
  });
  private readonly _saveButton: ModalButton = this._modalFactory.createModalButton({
    text: 'Mobile.Save',
    action: 'save',
    color: 'primary',
    isHidden: true
  });

  private readonly _modalOptions: CustomModalParameter<TerminateMessageResult> = {
    content: TerminateMessageSelectionComponent,
    data: { saveButton: this._saveButton },
    buttons: [this._cancelButton, this._saveButton],
    title: 'Mobile.ResourceStatus.RequestTerminate'
  };

  constructor(private readonly _modalFactory: ModalFactoryService) {}

  getTerminateRequestParameter(): Promise<StatusParameters> {
    return this._modalFactory
      .customModal<TerminateMessageResult>(this._modalOptions)
      .then(overlay => this.getTerminateRequestReason(overlay.data));
  }

  private getTerminateRequestReason(modalResult?: TerminateMessageResult): Promise<StatusParameters> {
    if (modalResult !== null) {
      return Promise.resolve({ parameter: `${modalResult.selectedTerminationMessage} ${modalResult.customMessage}` });
    }
    return Promise.reject();
  }
}
