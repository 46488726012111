export function getRangedPercent(value: number, min: number, max: number) {
  if (value < 0) {
    value = 0;
  } else if (value > 100) {
    value = 100;
  }
  if (min !== null && value < min) {
    value = min;
  }
  if (max !== null && value > max) {
    value = max;
  }
  return value;
}
