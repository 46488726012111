// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:38
import * as SagaBase from '../../../SagaBase';
import { CTacticalSituationBase } from '../../../SagaSchema/CTacticalSituationBase';
import * as SagaSchema from '../../../SagaSchema';
import { Guid, GuidTools } from '@models/guid';

export abstract class CTacticalSituationHistory_abstract extends CTacticalSituationBase {
  static $t: string = 'SagaSchema.History.CTacticalSituationHistory';
  $t: string = 'SagaSchema.History.CTacticalSituationHistory';

  public static GetMembers(): string[] {
    return ['StampOperation', 'SourceUid'].concat(CTacticalSituationBase.GetMembers());
  }

  public GetMembers(): string[] {
    return CTacticalSituationHistory_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public StampOperation: SagaBase.Interfaces.StampProviderOperation = SagaBase.Interfaces.StampProviderOperation.Add;
  public SourceUid: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
