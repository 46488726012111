// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class SettingBase_abstract extends PersistentObject {
  static $t: string = 'SagaBase.SettingBase';
  $t: string = 'SagaBase.SettingBase';

  public static GetMembers(): string[] {
    return ['ID', 'ApplicationId', 'Value', 'AltValue', 'TypeName', 'ApplicationName', 'Description'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return SettingBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ID: string;
  public ApplicationId: string = '1';
  public Value: string;
  public AltValue: any[];
  public TypeName: string;
  public ApplicationName: string;
  public Description: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ObjId(): any {
    return this.ID;
  }
  get ParentObjId(): any {
    return this.ID;
  }
  set ParentObjId(value: any) {
    this.ID = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
