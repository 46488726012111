// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @26.11.2021 15:28:52
/* tslint:disable */
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CVehicleBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CVehicleBase';
  $t: string = 'SagaSchema.CVehicleBase';

  public static GetMembers(): string[] {
    return [
      'CountryId',
      'MakeId',
      'KindId',
      'ColorId',
      'Description',
      'Country',
      'InvolvedInCrime',
      'TraceId',
      'Kind',
      'Make',
      'Model',
      'Color',
      'Registration',
      'RunningDirection',
      'Remarks',
      'LinkedDescription',
      'Occupant',
      'TraceState',
      'CallCardId'
    ].concat(SagaObject.GetMembers());
  }

  public GetMembers(): string[] {
    return CVehicleBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CountryId: number = 0;
  public MakeId: number = 0;
  public KindId: number = 0;
  public ColorId: number = 0;
  public Description: number = 0;
  public Country: string = '';
  public InvolvedInCrime: boolean = false;
  public TraceId: Guid = GuidTools.Empty;
  public Kind: string = '';
  public Make: string = '';
  public Model: string = '';
  public Color: string = '';
  public Registration: string = '';
  public RunningDirection: string = '';
  public Remarks: string = '';
  public LinkedDescription: string = '';
  public Occupant: string = '';
  public TraceState: SagaBase.Defines.TraceStates = SagaBase.Defines.TraceStates.none;
  public CallCardId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
