import { IObjectFilter } from '@addins/unit-custom-creation/src/schema/object-filter';
import { Component, OnInit } from '@angular/core';
import { CommunicationEquipment } from '@models/imported/SagaSchema/CommunicationEquipment';
import { SagaSettingsService } from '@services/settings';
import { Observable } from 'rxjs';
import { ListItemComponent } from '../../components/list-item/list-item.component';
import { ListItem } from '../../schema/list-item';
import { ResourcesProviderService } from '../../services/resources-provider/resources-provider.service';
import { UnitEditorService } from '../../services/unit-editor/unit-editor.service';
import { ListBaseComponent } from '../list-base-component/list-base-component';

@Component({
  selector: 'app-equipment-list',
  templateUrl: '../../abstract-template/tab-list.template.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent extends ListBaseComponent<CommunicationEquipment>
  implements OnInit, IObjectFilter<ListItem<CommunicationEquipment>> {
  public readonly item = ListItemComponent;

  public get me(): string {
    return this.unitEditor.editor.equipments[0].Name;
  }

  constructor(
    private resourcesProvider: ResourcesProviderService,
    protected unitEditor: UnitEditorService,
    protected sagaSettings: SagaSettingsService
  ) {
    super(unitEditor, sagaSettings);
    this.tabType = 'Equipment';
  }

  filter(item: ListItem<CommunicationEquipment>): boolean {
    return item.item.ObjGuid !== this.unitEditor.editor.equipments[0].ObjGuid;
  }

  getItems(offset: number, txt: string): Observable<CommunicationEquipment[]> {
    return this.resourcesProvider.getEquipments(offset, (this.searchExpression = txt));
  }

  ngOnInit() {
    this._list = this.unitEditor.editor.equipments;
    super.ngOnInit();
  }
}
