<div class="login-page">
  <div class="logo-wrapper">
    <img class="login-back-image" src="assets/logo.png" />
  </div>
  <div class="login-message" *ngIf="user">
    <span class="welcome-message" translate="static.welcomemsg"></span> &nbsp; <span class="user">{{ user }} !</span>
  </div>
  <div class="login-error" *ngIf="errorText">
    <span [translate]="errorText"></span>
  </div>
  <ion-button fill="clear" *ngIf="user" class="login-button" (click)="loginClicked()" color="primary">
    <span translate>static.login.start</span>
  </ion-button>
</div>
