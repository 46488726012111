import { Component, Inject, OnInit, Optional } from '@angular/core';
import { REQUEST_LOCALIZE } from '../../../schema/injection-token';
import { RequestLocalization } from '../../../schema/interfaces/request-localization';
import { CallcardComponentCard } from '../callcard-component-card';

@Component({
  selector: 'app-callcard-clocations-card',
  templateUrl: './callcard-clocations-card.component.html',
  styleUrls: ['./callcard-clocations-card.component.scss']
})
export class CallcardClocationsCardComponent extends CallcardComponentCard implements OnInit {
  constructor(
    @Optional()
    @Inject(REQUEST_LOCALIZE)
    private localizer: RequestLocalization
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * Call when the map is clicked
   */
  mapClick() {
    const location = this.callcard.location;
    if (this.enableInteraction && this.localizer) {
      this.localizer.requestLocalize(location.coordinate, 1);
    }
  }
}
