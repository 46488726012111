<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-title>
            <span translate>SagaMobile.UA.AddVehicle</span>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon slot="icon-only"  name="refresh"></ion-icon>
            </ion-button>
            <ion-button (click)="showSetting($event)">
                <ion-icon slot="icon-only"  name="settings"></ion-icon>
            </ion-button>
            <ion-button (click)="cancel()">
                <ion-icon slot="icon-only"  name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="padding">
    <ion-list>
        <ion-item *ngFor="let vehicle of vehicles" (click)="itemSelected(vehicle)">
            <ion-icon name="car" slot="start" [style.color]="vehicle.status ? vehicle.status.color.tinycolor.toRgbString(): 'black'"></ion-icon>
            <h2>{{vehicle.VehicleCode}}</h2>
            <p>{{vehicle.type && vehicle.type.Description}}</p>
        </ion-item>
    </ion-list>
</ion-content>
