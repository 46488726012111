import { MediaFileService } from '@addins/media';
import { MediaFile } from '@addins/media/schema/media-file';
import { Component, Input, OnInit } from '@angular/core';
import { DataType } from '@models/imported/SagaBase/Interfaces';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { WindowOpenerService } from '@services/window-opener/window-opener.service';

const enum IconNames {
  Audio = 'musical-notes-outline',
  File = 'document-outline',
  Link = 'link-outline',
  NotDefined = 'document-outline',
  Picture = 'image-outline',
  Spreadsheet = 'assets/icons/attachment-icons/document-xls.svg',
  Video = 'videocam-outline',
  Word = 'assets/icons/attachment-icons/document-word.svg'
}

@Component({
  selector: 'app-attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss']
})
export class AttachmentItemComponent implements OnInit {
  @Input()
  attachment: CAttachment;

  private _mediaTypeIcon: string = null;
  get mediaTypeIcon() {
    return this._mediaTypeIcon;
  }
  private mediaFile: MediaFile = null;

  constructor(private mediaFileService: MediaFileService, private windowOpener: WindowOpenerService) {}

  ngOnInit() {
    this._mediaTypeIcon = this.getIconName();
  }

  private getIconName(): string {
    switch (this.attachment.BinaryDataType) {
      case DataType.Audio:
        return IconNames.Audio;

      case DataType.Picture:
        return IconNames.Picture;

      case DataType.Video:
        return IconNames.Video;

      case DataType.Link:
        return IconNames.Link;

      case DataType.File:
        return this.getFileTypeIcon(this.attachment.Title);

      default:
        return IconNames.NotDefined;
    }
  }

  private getFileTypeIcon(fileName: string): string {
    if (fileName.match(/\.mov$/i) !== null) {
      return IconNames.Video;
    } else if (fileName.match(/\.xlsx$/i) !== null) {
      return IconNames.Spreadsheet;
    } else if (fileName.match(/\.docx$/i) !== null) {
      return IconNames.Word;
    }
    return IconNames.File;
  }

  onClicked() {
    if (this.attachment.BinaryDataType === DataType.Uri) {
      this.windowOpener.system(this.attachment.Url);
    } else {
      this.mediaFile = this.mediaFileService.getMediaFile(this.attachment.Url);
      if (this.mediaFile === null) {
        this.mediaFileService
          .downloadMediaFile(this.attachment.Url, this.getLocalFileName(this.attachment))
          .subscribe(mediaFile => this.onDownloadProgress(mediaFile));
      } else if (this.mediaFile.isDownloaded()) {
        this.mediaFileService.openMediaFile(this.mediaFile);
      }
    }
  }

  private getLocalFileName(attachment: CAttachment) {
    return `${attachment.ObjGuid}_${attachment.Title}`;
  }

  private onDownloadProgress(mediaFile: MediaFile) {
    this.mediaFile = mediaFile;
    if (mediaFile.isDownloaded()) {
      this.mediaFileService.openMediaFile(mediaFile);
    }
  }
}
