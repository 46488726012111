import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MessageEvent, MessageEventType } from '../../services/media-messages/media-messages/media-messages.service';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { Subscription } from 'rxjs';
import { MediaMessagesAvailableService } from '../../services/media-messages/media-messages-available/media-messages-available.service';
import { MediaMessagesNewService } from '../../services/media-messages/media-messages-new/media-messages-new.service';
import { MyCardService } from '@addins/core/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Component({
  selector: 'app-media-messages-list',
  templateUrl: './media-messages-list.component.html',
  styleUrls: ['./media-messages-list.component.scss']
})
export class MediaMessagesListComponent implements OnInit, OnDestroy {
  private readonly _subs: Subscription[] = [];

  private readonly _mediaMessages: MediaMessage[] = [];
  get mediaMessages(): MediaMessage[] {
    return this._mediaMessages;
  }

  get myCard(): CallCard {
    return this._myCardService.myCard;
  }

  constructor(
    private _mediaMessagesAvailableService: MediaMessagesAvailableService,
    private _mediaMessagesNewService: MediaMessagesNewService,
    private _myCardService: MyCardService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  ngOnInit() {
    this.getVisibleMessages();
    this._subs.push(this._mediaMessagesAvailableService.$event.subscribe(event => this.onMessageEvent(event)));
    this._subs.push(this._myCardService.$change.subscribe(() => this.onMyCallcardChanged()));
  }

  private getVisibleMessages() {
    this._mediaMessages.length = 0;
    this._mediaMessagesAvailableService
      .getMessages()
      .filter(mediaMessage => !this._mediaMessagesNewService.has(mediaMessage))
      .forEach(mediaMessage => this._mediaMessages.push(mediaMessage));
  }

  private onMessageEvent(event: MessageEvent) {
    switch (event.type) {
      case MessageEventType.removed:
        const idx = this._mediaMessages.indexOf(event.target);
        if (idx >= 0) {
          this._mediaMessages.splice(idx, 1);
        }
        break;

      case MessageEventType.modified:
        this._changeDetectorRef.detectChanges();
        break;

      case MessageEventType.clean:
        this.getVisibleMessages();
        break;
    }
  }

  private onMyCallcardChanged() {
    this.getVisibleMessages();
  }
}
