import { CurrentCardService, LocationProxyEvent } from '@addins/core/core';
import { GeofenceService } from '@addins/core/map';
import { Injectable } from '@angular/core';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { StatusItemAction } from '@models/imported/SagaBase';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { TranslateService } from '@ngx-translate/core';
import { CompletionHandler } from '@services/initializer/completion-handler';
import { Initializer } from '@services/initializer/initializer.service';
import { ModalService } from '@services/modal/modal.service';
import { Security } from '@services/security/security.service';
import { SagaSettingsService } from '@services/settings';
import { StatusBarItem, StatusBarService } from '@services/status-bar/status-bar.service';
import { StatusService } from '@services/status/status.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { ObjectFeature } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { RequestDispatchComponent } from '../../components/request-dispatch/request-dispatch.component';
import { LocationProviderService } from '../location-provider/location-provider.service';

@Injectable()
export class HomePageService extends CompletionHandler {
  onSiteStatus: ListResourceStatus;

  engagedModal: HTMLIonModalElement;

  private locationTrackerUnsubscriber: Function;
  private trackOnSitePositionRadius: number;
  private distanceMaxToBeOnSite = 10;
  private isSendingSub: Subscription = null;

  get unitActivity() {
    return this.security.getData('UnitActivity');
  }

  protected readonly positionStatusItem: StatusBarItem = {
    id: 'position',
    iconName: 'pin'
  };

  constructor(
    private modal: ModalService,
    private modalCtrl: ModalController,
    protected readonly initializer: Initializer,
    private security: Security,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private platform: Platform,
    private vibration: Vibration,
    private localNotifications: LocalNotifications,
    private backgroundMode: BackgroundMode,
    private appSettings: SagaSettingsService,
    private unitActivityService: UnitActivityService,
    private readonly statusBarService: StatusBarService,
    private readonly current: CurrentCardService,
    private geofence: GeofenceService,
    private statusService: StatusService,
    private _location: LocationProviderService
  ) {
    super(initializer);
  }

  public setup() {
    this._location.$state.subscribe(s => this.toggleItem(s));
    this.isSendingSub = this._location.$event.subscribe(e => this.updatePositionIconIfNeeded(e));
    this.security.onDataChange('MapPositionFeature', () =>
      this._location.$event.subscribe(v => this.updatePositionIconIfNeeded(v)).unsubscribe()
    );

    this.unitActivityService.onStatusChange.subscribe((status: ListResourceStatus) => {
      if (this.engagedModal) {
        this.engagedModal.dismiss(true);
      }

      if (this.locationTrackerUnsubscriber) {
        this.locationTrackerUnsubscriber();
        this.locationTrackerUnsubscriber = null;
      }

      if (status.hasAction(StatusItemAction.isRequestAssignment)) {
        this.requestAssignment();
      } else if (status.hasAction(StatusItemAction.isAssignement)) {
        this.trackPosition(this.unitActivity);
      }
    });
  }

  protected toggleItem(state: boolean) {
    if (state) {
      this.statusBarService.addItem(this.positionStatusItem);
    } else {
      this.statusBarService.removeItem(this.positionStatusItem);
    }
  }

  private trackPosition(unitActivity: UnitActivity) {
    let alert: HTMLIonAlertElement;
    if (this.locationTrackerUnsubscriber) {
      this.locationTrackerUnsubscriber();
      this.locationTrackerUnsubscriber = null;
    }
    this.locationTrackerUnsubscriber = this.geofence.trackPosition(
      unitActivity.callcard.location.coordinate,
      this.trackOnSitePositionRadius,
      distance => {
        distance = Math.round(distance);
        const msg =
          distance < this.distanceMaxToBeOnSite
            ? this.translate.instant('SagaMobile.UA.OnSite')
            : this.translate.instant('SagaMobile.UA.NearTheSite', { distance });

        if (!alert) {
          this.alertCtrl
            .create({
              message: msg,
              buttons: [
                {
                  text: this.translate.instant('SagaMobile.UA.OnSite.Cancel'),
                  handler: () => {
                    if (this.locationTrackerUnsubscriber) {
                      this.locationTrackerUnsubscriber();
                      this.locationTrackerUnsubscriber = null;
                    }
                  }
                },
                {
                  text: this.translate.instant('SagaMobile.UA.OnSite.Validate'),
                  handler: async () => {
                    if (this.locationTrackerUnsubscriber) {
                      this.locationTrackerUnsubscriber();
                      this.locationTrackerUnsubscriber = null;
                    }
                    await this.statusService.changeStatus(this.onSiteStatus, unitActivity.callcard, {}, 'SagaMobile.UA.ChangeStatus');
                  }
                }
              ]
            })
            .then(a => {
              alert = a;
              alert.onDidDismiss().then(() => {
                alert = null;
              });
              alert.present();
            });
        } else {
          alert.innerText = msg;
        }
      }
    );
  }

  /**
   * TODO: Move this portion of code to the IsAssignmentService to use the same code everywhere.
   */
  private requestAssignment() {
    // TODO: @deprecated please use ModalFactoryService instead
    this.modalCtrl.create({ component: RequestDispatchComponent }).then(m => {
      const unregisterBackButton = this.platform.backButton.subscribeWithPriority(10000, () => {});
      this.engagedModal = m;
      this.engagedModal.onDidDismiss().then(res => {
        if (res.data.valid) {
          if (this.modal.currentModalViewCtrl) {
            this.modal.currentModalViewCtrl.dismiss();
          }
          this.current.select(this.unitActivity.callcard);
        }

        this.engagedModal = null;
        unregisterBackButton.unsubscribe();
      });
      this.engagedModal.present();
    });

    if (this.backgroundMode.isActive()) {
      this.localNotifications.schedule({
        id: 1,
        text: this.translate.instant('SagaMobile.AssignementRequest.Title')
      });
    } else {
      this.vibration.vibrate([200, 200, 1000]);
    }
  }

  private updatePositionIconIfNeeded(e: LocationProxyEvent) {
    const feature: ObjectFeature = this.security.getData('MapPositionFeature');
    if (feature) {
      const recorderIcon = feature.getTextById('position-record');
      if (e === LocationProxyEvent.sending && !recorderIcon) {
        const text = feature.createText('\uf111');
        text.setId('position-record');
        text.setFont('FontAwesome');
        text.setColor([255, 0, 0, 1]);
        text.setOffset([-20, -10]);
        text.setSize(15);
        feature.changed();
      } else if (e !== LocationProxyEvent.sending && recorderIcon) {
        feature.removeText(recorderIcon);
        feature.changed();
      }
    }
  }

  protected $onComplete() {
    this.onSiteStatus = this.statusService.getStatusByAction(StatusItemAction.isOnSite);
    this.trackOnSitePositionRadius = this.appSettings.getValue('SagaMobileWebClient.TrackOnSitePosition.Radius');
  }
}
