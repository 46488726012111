// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CDeploymentPoint_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CDeploymentPoint';
  $t: string = 'SagaSchema.CDeploymentPoint';

  public static GetMembers(): string[] {
    return ['CallCardId', 'CoordEpsgCode', 'Text', 'Name', 'UnitUids', 'UnitLabels', 'NotifyUnits', 'CenterX', 'CenterY', 'CenterZ'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return CDeploymentPoint_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public CoordEpsgCode: string;
  public Text: string;
  public Name: string;
  public UnitUids: string;
  public UnitLabels: string;
  public NotifyUnits: string;
  public CenterX: number = -1.79769313486232e308;
  public CenterY: number = -1.79769313486232e308;
  public CenterZ: number = 0;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
