import { PanelToolBase, PanelToolGroupNames } from '@addins/core/panel';
import { ToggleTool } from '@techwan/map-tools';
import { SearchAddressPanelContentComponent } from '../../components/search-address-panel-content/search-address-panel-content.component';

export class SearchPanelTool extends PanelToolBase {
  readonly name: string = 'SearchPanelTool';
  readonly groupName: string = PanelToolGroupNames.MapToolGroup;
  readonly button: ToggleTool = new ToggleTool('search-address-tool-button', ['search-outline']);
  readonly content = SearchAddressPanelContentComponent;
  hasViewSplitter: boolean = false;
}
