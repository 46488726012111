import { Injectable } from '@angular/core';
import Icon from 'ol/style/icon';
import { ImagesSettingsService, SagaSettingsService } from '@services/settings';
import { DrawingShape, getImageScale, getImageSize } from '@techwan/map-tools';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IDrawingToolsConfiguration {
  tools: DrawingShape[];
  deployment: boolean;
}

@Injectable()
export class MapConfigurationService {
  private _icon: Icon = null;

  constructor(private readonly settings: SagaSettingsService, private readonly images: ImagesSettingsService) {}

  getTools(): IDrawingToolsConfiguration {
    const value = this.settings.getValue('SagaMobileWebClient.MapDrawingTools') || '';
    return {
      tools: value
        .split(',')
        .filter(t => t in DrawingShape)
        .map(t => DrawingShape[t]),
      deployment: value.indexOf('deployment-point') > -1
    };
  }

  getDeploymentIcon(): Observable<Icon> {
    return this._icon !== null ? of(this._icon) : getImageSize(this.getIcon()).pipe(map(s => this.setIcon(s)));
  }

  getIcon(): string {
    return this.images.get('LocalizeDeployment');
  }

  private setIcon(size: ol.Size): Icon {
    const scale = getImageScale(size, [32, 32]);
    return (this._icon = new Icon({ src: this.getIcon(), size, scale }));
  }
}
