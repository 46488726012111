import { HttpErrorParsingService } from '@addins/core/core';
import { BootService } from '@addins/core/login/services/boot/boot.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AddinView, MyEquipmentState } from '@techwan/ionic-core';
import { LoginStateService } from '../../services/login-state/login-state.service';

@Component({
  selector: 'app-starting',
  templateUrl: './starting.component.html',
  styleUrls: ['./starting.component.scss']
})
@AddinView({
  name: 'Starting'
})
export class StartingComponent implements OnInit {
  private _error: string = null;
  get error(): string {
    if (this._error === null) {
      this._error =
        this.boot.error !== null || this.loginState.error instanceof HttpErrorResponse
          ? this.httpError.parse(this.boot.error || (this.loginState.error as HttpErrorResponse))
          : this.loginState.error.message || null;
    }
    return this._error;
  }

  private _text = '';
  get text(): string {
    return this._text;
  }

  get isStarting(): boolean {
    return !this.hasError && this.text !== `MyEquipmentState.${MyEquipmentState[MyEquipmentState.ready]}`;
  }

  get hasError(): boolean {
    return this.boot.error !== null || this.loginState.error !== null;
  }

  constructor(private loginState: LoginStateService, private boot: BootService, private httpError: HttpErrorParsingService) {}

  ngOnInit() {
    this.boot.setup().subscribe();
    this.loginState.text.subscribe(t => (this._text = t));
  }

  refresh(): void {
    window.location.reload();
  }
}
