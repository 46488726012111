<ion-card>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <h1>{{ uploadItem.file.name }}</h1>
      </ion-row>
      <ion-row>
        <ion-col class="item-icon-col" sizeXs="3" sizeMd="2">
          <ion-icon [name]="iconName"></ion-icon>
        </ion-col>
        <ion-col class="info-icon-col" sizeXs="9" sizeMd="6">
          <ion-row>
            <ion-col size="7">
              <ion-label>{{ fileSizeInMb }}</ion-label>
            </ion-col>
            <ion-col style="text-align: end;" size="5">
              <ion-label>{{ getProgress() }}%</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-progress-bar color="primary" [value]="getProgress() / 100"></ion-progress-bar>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col class="buttons-icon-col" sizeXs="12" sizeMd="4">
          <ion-button
            size="medium"
            fill="outline"
            [disabled]="!canStartOrPause()"
            [color]="uploadItem.isUploading() ? 'warning' : 'primary'"
            (click)="onStartPause()"
          >
            <ion-icon *ngIf="!uploadItem.isUploading()" slot="icon-only" name="cloud-upload"></ion-icon>
            <ion-icon *ngIf="uploadItem.isUploading()" slot="icon-only" name="pause"></ion-icon>
          </ion-button>
          <ion-button class="delete-button" size="medium" color="danger" (click)="onDelete()">
            <ion-icon slot="icon-only" name="trash"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
