import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({ selector: '[translatePlaceholder]' })
export class TranslatePlaceholderDirective implements OnInit {
  @Input('translatePlaceholder') key: string;
  constructor(private el: ElementRef, private translate: TranslateService) {}

  updatePlaceholder() {
    this.el.nativeElement.setAttribute('placeholder', this.translate.instant(this.key));
  }

  ngOnInit() {
    this.updatePlaceholder();
  }
}
