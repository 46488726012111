// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class UnitActivityResource_abstract extends SagaObject {
  static $t: string = 'SagaSchema.UnitActivityResource';
  $t: string = 'SagaSchema.UnitActivityResource';

  public static GetMembers(): string[] {
    return ['ActivityUid', 'ResourceUId', 'ResType', 'ParentResourceUId', 'ServiceUid', 'StepUid', 'Ungrouped'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return UnitActivityResource_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public ActivityUid: Guid = GuidTools.Empty;
  public ResourceUId: Guid = GuidTools.Empty;
  public ResType: SagaBase.ResourceType = SagaBase.ResourceType.Undefined;
  public ParentResourceUId: Guid = GuidTools.Empty;
  public ServiceUid: Guid = GuidTools.Empty;
  public StepUid: Guid = GuidTools.Empty;
  public Ungrouped: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.ActivityUid;
  }
  set ParentObjId(value: any) {
    this.ActivityUid = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
