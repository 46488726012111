import { ALERT_PROVIDER_TOKEN, IAlertProvider } from '@addins/core/core';
import { UnitSelectorService } from '@addins/core/unit-activity';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@services/modal/modal.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { UnitActivity } from '@techwan/ionic-core/lib/schema/imported/SagaSchema/UnitActivity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  private _canCreateAlert: boolean = false;
  get canCreateAlert(): boolean {
    return this._canCreateAlert;
  }

  private _loadingText: string;
  get loadingText(): string {
    return this._loadingText;
  }

  get canCreateUnit(): boolean {
    return this._unitSelectorService.canCreateUnit;
  }

  private _subs: Subscription[] = [];
  private _loading: Promise<void> = null;

  constructor(
    @Inject(ALERT_PROVIDER_TOKEN)
    private _alertProvider: IAlertProvider,
    private _myEquipment: MyEquipmentService,
    private _unitSelectorService: UnitSelectorService,
    private _modal: ModalService
  ) {}

  ngOnInit() {
    this._subs.push(this._myEquipment.myUnit.subscribe(unitActivity => this.onUnitActivityChanged(unitActivity)));
    this._subs.push(this._alertProvider.canCreate().subscribe(canCreateAlert => (this._canCreateAlert = canCreateAlert)));
  }

  ngOnDestroy() {
    if (this._loading) {
      this._loading.then(() => this._modal.dismissLoading());
    }

    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  private onUnitActivityChanged(unitActivity: UnitActivity) {
    const noUnit: boolean = unitActivity === null;
    this._loadingText = noUnit ? 'SagaMobile.WaitingForAUnit' : 'loading...';
  }

  onCreateUnit() {
    this._unitSelectorService.onCreate().then(isUnitCreated => {
      if (isUnitCreated) {
        this.onPresentLoading();
      }
    });
  }

  private onPresentLoading() {
    this._loading = this._modal.presentLoading('static.login.device.initializing');
  }
}
