import * as tinycolor from 'tinycolor2';

export class Color {
  tinycolor: tinycolor;

  constructor(options: any) {
    if (parseInt(options, 10) == options) {
      options = this.toColor(parseInt(options, 10));
    }
    this.tinycolor = tinycolor(options);
    if (!this.tinycolor.isValid()) {
      console.warn(`Color '${options}' is not a valid color name`);
    }
  }

  private toColor(num) {
    num >>>= 0;
    const b = num & 0xff,
      g = (num & 0xff00) >>> 8,
      r = (num & 0xff0000) >>> 16,
      a = ((num & 0xff000000) >>> 24) / 255;
    return 'rgba(' + [r, g, b, a].join(',') + ')';
  }

  toArray(): number[] {
    return [this.tinycolor.toRgb().r, this.tinycolor.toRgb().g, this.tinycolor.toRgb().b];
  }

  toRgbString() {
    return this.tinycolor.toRgbString();
  }
}
