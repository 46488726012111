// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { SagaObject } from '../../SagaBase/SagaObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class CUnmanagedResourceActivityBase_abstract extends SagaObject {
  static $t: string = 'SagaSchema.CUnmanagedResourceActivityBase';
  $t: string = 'SagaSchema.CUnmanagedResourceActivityBase';

  public static GetMembers(): string[] {
    return ['CallCardId', 'StampType', 'ServiceObjId', 'ProcStepUid', 'ServiceName', 'ResourceName', 'Text', 'ElementId'].concat(
      SagaObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return CUnmanagedResourceActivityBase_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public CallCardId: Guid = GuidTools.Empty;
  public StampType: number = 0;
  public ServiceObjId: Guid = GuidTools.Empty;
  public ProcStepUid: Guid = GuidTools.Empty;
  public ServiceName: string = '';
  public ResourceName: string;
  public Text: string = '';
  public ElementId: Guid = GuidTools.Empty;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  get ParentObjId(): any {
    return this.CallCardId;
  }
  set ParentObjId(value: any) {
    this.CallCardId = value;
  }

  /*</AUTO_IMPLEMENTATION>*/
}
