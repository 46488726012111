import { FileInfo } from '../interfaces/file-info';

const _extensionToMimeTypeMapping = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  mp4: 'video/mp4'
};

export function getFileInfo(url: string): FileInfo {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];

  const filenamePart = fileName.split('.');
  const extension = filenamePart[filenamePart.length - 1];
  const mimeType = _extensionToMimeTypeMapping[extension] || 'application/binary';

  return { filename: fileName, extension, mimeType, url };
}
