import { Component, ElementRef, Output } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelDismissTool } from '../../schema/interfaces/panel-dismiss-tool';
import { PanelPinTool } from '../../schema/panel-tools/panel-pin-tool';

@Component({
  selector: 'app-panel-pin',
  templateUrl: './panel-pin.component.html',
  styleUrls: ['./panel-pin.component.scss']
})
export class PanelPinComponent implements PanelDismissTool {
  private readonly _tool = new PanelPinTool();
  get isUnpinned(): boolean {
    return this._tool.isUnpinned();
  }

  setUnpinned(unpinned: boolean) {
    this._tool.setUnpinned(unpinned);
  }

  @Output()
  get toggle(): Observable<PanelPinTool> {
    return this._tool.$click.pipe(map(() => this._tool));
  }

  constructor(private readonly element: ElementRef) {
    fromEvent(this.element.nativeElement, 'click').subscribe((e: MouseEvent | TouchEvent) => this._tool.click(e));
  }

  reset(): void {
    this._tool.reset();
  }
}
