import Group from 'ol/layer/group';
import { GroupName, SagaLayerManager } from '@techwan/ionic-core/map';
import { Map as SagaMap } from '@techwan/mapping';
import { DeploymentPointsLayer } from '@addins/core/map';
import { DeploymentGroup } from './deployment-group';
import { IDeploymentDataProvider } from './interfaces/IDeploymentDataProvider';
import { DeploymentTool } from './deployment-tool';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDeploymentOperation } from './interfaces/IDeploymentOperation';
import { DeploymentOperation } from './deployment-operation';
import { IDeploymentConfiguration } from './interfaces/IDeploymentConfiguration';

export class DeploymentManager implements IDeploymentConfiguration {
  private _deploymentPointsLayer: DeploymentPointsLayer = null;
  get deploymentPointsLayer(): DeploymentPointsLayer {
    if (this._deploymentPointsLayer === null) {
      this._deploymentPointsLayer = this._deploymentDataProvider.createLayer();
    }
    return this._deploymentPointsLayer;
  }

  private _deploymentPointsGroup: Group = null;

  private _deploymentOperation: DeploymentOperation = null;
  get $event(): Observable<IDeploymentOperation> {
    return this._deploymentOperation.$event;
  }

  private readonly _edition = new BehaviorSubject(false);
  get $edition(): Observable<boolean> {
    return this._edition.asObservable();
  }

  constructor(
    private _sagaMap: SagaMap,
    private _deplyomentGroup: DeploymentGroup,
    private _deploymentDataProvider: IDeploymentDataProvider
  ) {}

  destroy() {
    if (this._deploymentOperation !== null) {
      this._deploymentOperation.destroy();
      this._deploymentOperation = null;
    }
  }

  setup() {
    this._deploymentOperation = new DeploymentOperation(this._sagaMap, this._deploymentDataProvider, this);
    this.configure();
    this._deplyomentGroup.tools.forEach((deploymentTool: DeploymentTool) =>
      deploymentTool.$event.subscribe((event: DeploymentTool) => this.onToolActiveChange(event))
    );
  }

  configure() {
    const sagaLayerManager = this._sagaMap.getLayerManager() as SagaLayerManager;
    const layersGroup = sagaLayerManager.getRoot() as Group;
    if (this._deploymentPointsGroup === null) {
      if (sagaLayerManager.getOverlayLayers().filter(group => group.get('name') == GroupName.editDeployment).length === 0) {
        this._deploymentPointsGroup = new Group();
        this._deploymentPointsGroup.set('name', GroupName.editDeployment);
        sagaLayerManager.setAutohide(this._deploymentPointsGroup);
        this._deploymentPointsGroup.getLayers().insertAt(0, this.deploymentPointsLayer);
        layersGroup.getLayers().push(this._deploymentPointsGroup);
      } else {
        this._deploymentPointsGroup = sagaLayerManager.getOverlayLayers().filter(group => group.get('name') == GroupName.editDeployment)[0];
      }
    }
  }

  private onToolActiveChange(deploymentTool: DeploymentTool) {
    this._deploymentOperation.onToolActiveChanged(deploymentTool);

    this._edition.next(deploymentTool.isActive);
  }
}
