<ion-header>
    <ion-toolbar color="primary" hideBackButton="true">
        <ion-buttons slot="start">
            <ion-button (click)="close()"><ion-icon slot="icon-only"  name="close"></ion-icon></ion-button>
        </ion-buttons>
        <ion-title>
            <span [translate]="title"></span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="padding">
    <ion-list>
        <ion-item *ngFor="let listName of listNames" (click)="close(listName)">
            {{getDisplayField(listName)}}
        </ion-item>
    </ion-list>
</ion-content>
