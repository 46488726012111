<ng-container *ngFor="let vehicleWrapper of cVehiclesWrapper">
  <ion-item>
    <ion-icon name="car" slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <div *ngIf="vehicleWrapper.wrappedParticular.Registration">
        <h2>
          <strong translate>Mobile.CVehicleRegistration</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Registration }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Country">
        <h2>
          <strong translate>Mobile.CVehicleCountry</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Country }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Make">
        <h2>
          <strong translate>Mobile.CVehicleMake</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Make }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Model">
        <h2>
          <strong translate>Mobile.CVehicleModel</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Model }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Color">
        <h2>
          <strong translate>Mobile.CVehicleColor</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Color }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Kind">
        <h2>
          <strong translate>Mobile.CVehicleKind</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Kind }}</h2>
      </div>
      <div *ngIf="vehicleWrapper.wrappedParticular.Remarks">
        <h2>
          <strong translate>Mobile.CVehicleRemarks</strong>
        </h2>
        <h2>{{ vehicleWrapper.wrappedParticular.Remarks }}</h2>
      </div>
    </ion-label>
  </ion-item>
</ng-container>
