import { Menu } from '@services/menu/menu';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SideMenuService } from '@services/menu/side-menu.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Device } from '@ionic-native/device/ngx';
import { environment } from '@environments/environment';
import { parseColor } from '@utils/color';

export class ApplicationBase {
  readonly mainMenu: Menu;

  constructor(
    platform: Platform,
    protected modalCtrl: ModalController,
    public menuService: SideMenuService,
    statusBar: StatusBar,
    splashScreen: SplashScreen,
    backgroundMode: BackgroundMode,
    protected _menu: MenuController,
    protected device: Device
  ) {
    this.mainMenu = menuService.get();
    if (!environment.production) {
      (window as any).openTranslate = async () => {
        const menu = this.mainMenu.menus.find(m => m.text === 'static.translations');
        if (menu) {
          // TODO: @deprecated please use ModalFactoryService instead
          this.modalCtrl.create({ component: menu.type }).then(m => m.present());
        }
      };
    }
    platform.ready().then(async () => {
      if (platform.is('cordova') && this.device.platform !== 'browser') {
        statusBar.overlaysWebView(false);
        statusBar.backgroundColorByHexString(parseColor());

        backgroundMode.setDefaults({
          title: 'SagaMobile',
          ticker: 'Application is in background',
          text: 'The application is style running !',
          silent: false,
          resume: true
        });
        backgroundMode.enable();

        splashScreen.hide();
      } else {
        this.setupBackButtonBehavior();
      }
    });
  }

  protected setupBackButtonBehavior() {
    window.onpopstate = () => {
      if (this._menu.isOpen()) {
        this._menu.close();
        return;
      }
    };
  }
}
