// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class Location_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Location';
  $t: string = 'SagaBase.Location';

  public static GetMembers(): string[] {
    return ['Id', 'ImportId', 'Name', 'GeoLocationTypeConst', 'ParentId', 'CoordX', 'CoordY', 'Deleted'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return Location_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Id: number = 0;
  public ImportId: string;
  public Name: string;
  public GeoLocationTypeConst: number = 0;
  public ParentId: number = 0;
  public CoordX: number = -1.79769313486232e308;
  public CoordY: number = -1.79769313486232e308;
  public Deleted: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
