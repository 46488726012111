// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @08.08.2019 07:18:41
import { PersistentObject } from '../../../SagaBase/PersistentObject';
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class AVLSTATUS_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Objects.AVLSTATUS';
  $t: string = 'SagaBase.Objects.AVLSTATUS';

  public static GetMembers(): string[] {
    return ['AvlStatusId', 'Status', 'Code_Radio', 'Created_Date'].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return AVLSTATUS_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    this.Created_Date = this.Created_Date && new Date(this.Created_Date);

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public AvlStatusId: number = 0;
  public Status: number = 0;
  public Code_Radio: string;
  public Created_Date: Date = new Date();

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
