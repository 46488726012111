import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class SagaMissingTranslationHandler implements MissingTranslationHandler {
  missingTranslations: {} = {};

  constructor() {}

  handle(params: MissingTranslationHandlerParams) {
    let lowerCased = params.key.toLowerCase();
    if (!this.missingTranslations[params.translateService.currentLang]) {
      this.missingTranslations[params.translateService.currentLang] = [];
    }

    if (this.missingTranslations[params.translateService.currentLang].indexOf(lowerCased) === -1) {
      this.missingTranslations[params.translateService.currentLang].push(lowerCased);
    }

    return params.key;
  }
}
