import { CustomComponent, ModalButton } from '@addins/core/core';
import { Component, ComponentRef, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { EquipmentListComponent } from '../equipment-list/equipment-list.component';
import { InternalResourceListComponent } from '../internal-resource-list/internal-resource-list.component';
import { UnitSummaryComponent } from '../unit-summary/unit-summary.component';
import { VehicleListComponent } from '../vehicle-list/vehicle-list.component';

enum ContentType {
  vehicles,
  internalResource,
  equipment,
  summary
}

@Component({
  selector: 'app-custom-creation-modal',
  templateUrl: './custom-creation-modal.component.html',
  styleUrls: ['./custom-creation-modal.component.scss']
})
export class CustomCreationModalComponent implements OnInit, CustomComponent<any> {
  useCustomData: boolean;

  readonly vehicles = ContentType.vehicles;
  readonly resource = ContentType.internalResource;
  readonly equipment = ContentType.equipment;
  readonly summary = ContentType.summary;

  readonly tabButtonsArray = [
    { itemType: this.vehicles, itemName: 'Mobile.Vehicle' },
    { itemType: this.resource, itemName: 'Mobile.ResInternal' },
    { itemType: this.equipment, itemName: 'Mobile.Equipment' },
    { itemType: this.summary, itemName: 'Mobile.UnitFinaliseConfiguration' }
  ];

  selection: ContentType;

  private _component: ComponentRef<any>;

  @ViewChild('content', { read: ViewContainerRef, static: true })
  readonly content: ViewContainerRef;

  constructor() {}

  ngOnInit() {
    this.setContent(this.vehicles);
  }

  getResult(button: ModalButton): any {
    return undefined;
  }

  setContent(item: ContentType) {
    if (this.selection === item) {
      return;
    }

    this.selection = item;
    switch (item) {
      case this.vehicles:
        this.load<VehicleListComponent>(VehicleListComponent);
        break;

      case this.resource:
        this.load<InternalResourceListComponent>(InternalResourceListComponent);
        break;

      case this.equipment:
        this.load<EquipmentListComponent>(EquipmentListComponent);
        break;

      case this.summary:
        this.load<UnitSummaryComponent>(UnitSummaryComponent);
        break;
    }
  }

  private load<T>(component: Type<T>) {
    this.content.clear();
    this._component = this.content.createComponent<T>(component);
  }
}
