// Automatically generated from TW.Saga.Core.Schema, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:37
import { PersistentObject } from '../../SagaBase/PersistentObject';
import * as SagaBase from '../../SagaBase';
import { Guid, GuidTools } from '@models/guid';

export abstract class IntruderCandidate_abstract extends PersistentObject {
  static $t: string = 'SagaSchema.IntruderCandidate';
  $t: string = 'SagaSchema.IntruderCandidate';

  public static GetMembers(): string[] {
    return ['MapX', 'MapY', 'ResourceUid', 'ResourceName', 'ResourceDescription', 'ResourceType', 'Owner', 'OwnerName'].concat(
      PersistentObject.GetMembers()
    );
  }

  public GetMembers(): string[] {
    return IntruderCandidate_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public MapX: number = 0;
  public MapY: number = 0;
  public ResourceUid: Guid = GuidTools.Empty;
  public ResourceName: string;
  public ResourceDescription: string;
  public ResourceType: number = 0;
  public Owner: number = 0;
  public OwnerName: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
