import { Component, Input, OnInit } from '@angular/core';
import { ExtendedLayer } from '@techwan/mapping';
import Group from 'ol/layer/group';
import Tile from 'ol/layer/tile';

export enum LayerType {
  group,
  layer,
  merge
}

@Component({
  selector: 'app-overlay-group-select',
  templateUrl: './overlay-group-select.component.html',
  styleUrls: ['./overlay-group-select.component.scss']
})
export class OverlayGroupSelectComponent implements OnInit {
  @Input()
  layers: Group[] | Tile[];

  readonly group = LayerType.group;
  readonly layer = LayerType.layer;
  readonly merged = LayerType.merge;

  constructor() {}

  ngOnInit(): void {}

  getType(item: Group | ExtendedLayer | Tile): LayerType {
    return item instanceof Group ? (item.get('mergeLayers') === true ? LayerType.merge : LayerType.group) : LayerType.layer;
  }

  getLayers(item: Group): Tile[] | Group[] {
    return item.getLayers().getArray() as Tile[];
  }

  shouldShowGroup(group: Group): boolean {
    if (this.hasAutohide(group)) {
      return !this.isGroupEmpty(group);
    }

    return true;
  }

  private hasAutohide(item: Group | ExtendedLayer) {
    return item.get('autohide') === true;
  }

  private isGroupEmpty(group: Group): boolean {
    let groupIsEmpty = true;
    group
      .getLayers()
      .getArray()
      .forEach((item: Group | ExtendedLayer | Tile) => {
        const itemType = this.getType(item);
        if (itemType === LayerType.layer) {
          if (!this.isLayerEmpty(item as ExtendedLayer)) {
            groupIsEmpty = false;
          }
        } else {
          const innerGroupIsEmpty = this.isGroupEmpty(item as Group);
          if (!innerGroupIsEmpty) {
            groupIsEmpty = false;
          }
        }
      });

    return groupIsEmpty;
  }

  private isLayerEmpty(layer: ExtendedLayer): boolean {
    return layer.getSource().getFeatures().length === 0;
  }

  shouldShowMergedGroup(group: Group) {
    if (this.hasAutohide(group)) {
      return !this.isGroupEmpty(group);
    }

    return group.getLayers().getLength() > 0;
  }

  shouldShowLayer(layer): boolean {
    if (this.hasAutohide(layer)) {
      return !this.isLayerEmpty(layer);
    }

    return true;
  }
}
