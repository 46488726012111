// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:08:15
import * as SagaBase from '../../../SagaBase';
import { Guid, GuidTools } from '@models/guid';
import { BaseObject } from '@models/BaseObject';

export abstract class CallcardCreateArgs_abstract extends BaseObject {
  static $t: string = 'SagaBase.Defines.CallcardCreateArgs';
  $t: string = 'SagaBase.Defines.CallcardCreateArgs';

  public static GetMembers(): string[] {
    return ['Type', 'CallSourceId', 'PhoneNumber', 'Components', 'User', 'Sector', 'IsTest', 'ByPassDefaults', 'CreateBlankCaller'];
  }

  public GetMembers(): string[] {
    return CallcardCreateArgs_abstract.GetMembers();
  }

  public _init(): void {
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/
    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public Type: SagaBase.AlarmType;
  public CallSourceId: number = 0;
  public PhoneNumber: string;
  public Components: any[];
  public User: any;
  public Sector: any;
  public IsTest: boolean = false;
  public ByPassDefaults: boolean = false;
  public CreateBlankCaller: boolean = false;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
