<ion-accordion-group [multiple]="true" [value]="openedItems">
  <ng-container *ngFor="let item of items">
    <ion-accordion *ngIf="item.isVisible()" toggleIconSlot="start" [value]="item.title">
      <ion-item slot="header" color="light" (click)="item.collapsed = !item.collapsed">
        <ion-label>
          <strong translate>{{ item.title }}</strong>
        </ion-label>
      </ion-item>
      <div slot="content">
        <lib-addin-view [name]="item.viewName"></lib-addin-view>
      </div>
    </ion-accordion>
  </ng-container>
</ion-accordion-group>
