// Automatically generated from TW.Saga.Core.Base, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null @30.07.2019 15:26:36
import { geoPoint } from '@models/imported/SagaBase/geoPoint';
import { PersistentObject } from '../../SagaBase/PersistentObject';

export abstract class Address_abstract extends PersistentObject {
  static $t: string = 'SagaBase.Address';
  $t: string = 'SagaBase.Address';

  public static GetMembers(): string[] {
    return [
      'AliasName',
      'Area1Name',
      'Area2Name',
      'Area3Name',
      'TypeId',
      'CityName',
      'Complement',
      'PlaceName',
      'StreetNumber',
      'GeoPoint',
      'GeoLocationId',
      'GeoLocationText',
      'GeoLocationXML',
      'GeoLocationSearchLabel',
      'LocationMiscData',
      'GeoLocationType',
      'Normalized',
      'GeoSectorId',
      'GeoSectorName',
      'Zip',
      'District',
      'Country',
      'CoordEpsgCode'
    ].concat(PersistentObject.GetMembers());
  }

  public GetMembers(): string[] {
    return Address_abstract.GetMembers();
  }

  public _init(): void {
    super._init();
    //// Add "NO-" before "AUTO_INIT_CODE" if don't want to re-generate at each compilation
    ///*<AUTO_INIT_CODE>*/

    ///*</AUTO_INIT_CODE>*/
  }

  // Add "NO-" before "INSTANCE_MEMBERS" if don't want to re-generate at each compilation
  /*<INSTANCE_MEMBERS>*/

  public AliasName: string;
  public Area1Name: string;
  public Area2Name: string;
  public Area3Name: string;
  public TypeId: number = -1;
  public CityName: string;
  public Complement: string;
  public PlaceName: string;
  public StreetNumber: string;
  public GeoPoint: geoPoint;
  public GeoLocationId: number;
  public GeoLocationText: string;
  public GeoLocationXML: string;
  public GeoLocationSearchLabel: string;
  public LocationMiscData: string;
  public GeoLocationType: number;
  public Normalized: number = 0;
  public GeoSectorId: number;
  public GeoSectorName: string;
  public Zip: string;
  public District: string;
  public Country: string;
  public CoordEpsgCode: string;

  /*</INSTANCE_MEMBERS>*/

  // Add "NO-" before "AUTO_IMPLEMENTATION" if don't want to re-generate at each compilation
  /*<AUTO_IMPLEMENTATION>*/

  /*</AUTO_IMPLEMENTATION>*/
}
