import { Component, Input, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

@Component({
  selector: 'app-callcard-detail-info',
  templateUrl: './callcard-detail-info.component.html',
  styleUrls: ['./callcard-detail-info.component.scss']
})
export class CallcardDetailInfoComponent implements OnInit {
  @Input()
  callcard: CallCard;

  constructor() {}

  ngOnInit() {}
}
